import { useMutation, useQuery } from '@apollo/client'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { usePrompt } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { CenteredLoader, Message, PageHeader } from 'components'
import {
  CreateEditProjectModal,
  CreateEditProjectModalResolve,
} from 'modules/projects/CreateEditProjectModal'
import { CREATE_PROJECT_MUTATION } from 'modules/projects/mutations'
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
} from 'modules/projects/types.graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { GOOGLE_MAPS_API_KEY } from 'util/api'
import { useCompany, useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CustomerProjectCard } from './components'
import {
  CustomerInfoCard,
  CustomerInfoField,
} from './components/CustomerInfoCard'
import { CustomerMobile } from './CustomerMobile'
import { CUSTOMER_QUERY } from './queries'
import { CustomerQuery } from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  ${props => props.theme.media.desktop} {
    max-width: 1400px;
  }
`
const Information = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'info         map'
    'billing    contact'
    'projects   .'
    'ONGOING    PLANNING'
    'BILLING    FINISHED';
  gap: 2rem;
`
const ProjectsHeader = styled(PageHeader)`
  grid-area: projects;
  margin-bottom: -1rem;
`
const MapCard = styled.div`
  grid-area: map;

  height: 100%;
  width: 100%;

  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

export interface CustomerInfoFields {
  info: CustomerInfoField[]
  billing: CustomerInfoField[]
  contact: CustomerInfoField[]
}

interface CustomerProps {}

const CustomerInner: React.FC<CustomerProps> = () => {
  const translations = useTranslate({
    billing: 'common.billing',
    contactPerson: 'customers.contact-person',
    generalInformation: 'customers.general-information',
    information: 'common.information',

    address: 'common.address',
    organizationNumber: 'customers.org-nr',
    postalArea: 'customers.postal-area',

    defaultDiscount: 'common.default-discount',
    defaultHourlyRate: 'common.default-hourly-rate',
    email: 'common.email',

    name: 'common.name',
    phoneNumber: 'common.phone-number',

    projects: 'common.projects',
    createProject: 'projects.create',

    hide: 'common.hide',
    show: 'common.show',

    results: {
      queryError: 'server.general-error-try-again-later',

      createError: 'projects.toasts.create-error',
      createSuccess: 'projects.toasts.create-success',
    },

    stages: {
      billing: 'projects.stage-billing',
      finished: 'projects.stage-finished',
      ongoing: 'projects.stage-ongoing',
      planning: 'projects.stage-planning',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const { id: customerId } = useParams<{ id: string }>()
  const history = useHistory()

  const addPrompt = usePrompt()
  const addToast = useToast()
  const companyDefaultHourlyRate = useCompany()?.defaultHourlyRate

  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const [{ lat, lng }, setLatLon] = useState({ lat: 63.43, lng: 10.39 })

  const canCreateProject = usePermission(PERMISSIONS.projects.add.project)

  const { loading, error, data } = useQuery<CustomerQuery, IdVariable>(
    CUSTOMER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: customerId },
      onCompleted(data) {
        setOverrides(v => ({
          [customerId]: data?.customer.name,
          ...v,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const [createProjectMutation, { loading: createLoading }] = useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION, {
    onCompleted(data) {
      addToast('success', translations.results.createSuccess)
      history.push(`/projects/${data.createProject.project.id}/info`)
      mixpanel.track('Create Project', {
        Context: 'Customer',
      })
    },
    onError: onErrorAuto(translations.results.createError),
  })

  const customer = data?.customer
  const projects = useMemo(
    () => data?.customer.projects.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  }

  useEffect(() => {
    if (!data || !google) return

    const geoCoder = new google.maps.Geocoder()

    geoCoder
      .geocode(
        {
          address: `${data.customer.address}, ${data.customer.postalCode} ${data.customer.postalArea}`,
        },
        (results, status) => {
          if (status === 'OK') {
            const location = results && results[0].geometry.location
            return location
          }

          console.error(
            'Geocode was unsuccessful for the following reason: ' + status
          )
        }
      )
      .then(coordinates => {
        setLatLon({
          lat: coordinates.results[0].geometry.location.lat(),
          lng: coordinates.results[0].geometry.location.lng(),
        })
      })
  }, [data])

  const fields = useMemo<CustomerInfoFields>(
    () => ({
      info: !customer
        ? []
        : [
            {
              label: translations.organizationNumber,
              text: customer.organizationNumber ?? '',
              type: 'brreg',
            },
            {
              label: translations.address,
              text: customer.address,
              type: 'map',
              hrefInput: `${customer.address} ${customer.postalCode} ${customer.postalArea}`,
            },
            {
              label: translations.postalArea,
              text: customer.postalCode + ' ' + customer.postalArea,
              type: 'map',
              hrefInput: `${customer.address} ${customer.postalCode} ${customer.postalArea}`,
            },
          ] ?? [],
      billing: !customer
        ? []
        : [
            {
              label: translations.email,
              text: customer.billingEmail ?? '',
              type: 'email',
            },
            {
              label: translations.defaultHourlyRate,
              text: customer.defaultHourlyRate + ' kr',
            },
            {
              label: translations.defaultDiscount,
              text: customer.defaultMaterialDiscount + ' %',
            },
          ],
      contact: !customer
        ? []
        : [
            {
              label: translations.name,
              text: customer.contactPersonName ?? '',
            },
            {
              label: translations.email,
              text: customer.contactPersonEmail ?? '',
              type: 'email',
            },
            {
              label: translations.phoneNumber,
              text: customer.contactPersonPhone ?? '',
              type: 'tel',
            },
          ],
    }),
    [customer, translations]
  )

  async function handleCreateProject(customerId: string) {
    if (!canCreateProject) return

    const { data } = await addPrompt<CreateEditProjectModalResolve | null>(
      resolve => (
        <CreateEditProjectModal
          customerId={customerId}
          companyDefaultHourlyRate={companyDefaultHourlyRate}
          onSubmit={resolve}
        />
      )
    )

    if (!data) return

    createProjectMutation({
      variables: { input: data },
    })
  }

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (loading || !customer) return <CenteredLoader />

  return (
    <Wrapper>
      {isMobileOnly ? (
        <CustomerMobile
          customer={customer}
          projects={projects}
          fields={fields}
          latlng={{ lat, lng }}
          onCreateClick={() => handleCreateProject(customerId)}
        />
      ) : (
        <>
          <PageHeader
            title={data?.customer.name ?? ''}
            loading={loading || createLoading}
            largeMargin
            editPermissions={PERMISSIONS.customers.change.customer}
            onEdit={() => history.push(`/customers/${customerId}/edit`)}
          />

          <Information>
            <CustomerInfoCard area="info" fields={fields.info} />

            <MapCard>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat,
                  lng,
                }}
                zoom={15}
              >
                {
                  <Marker
                    position={{
                      lat: lat,
                      lng: lng,
                    }}
                  />
                }
              </GoogleMap>
            </MapCard>

            <CustomerInfoCard area="billing" fields={fields.billing} />

            <CustomerInfoCard area="contact" fields={fields.contact} />

            <ProjectsHeader
              title={translations.projects}
              loading={loading || createLoading}
              buttons={[
                {
                  text: translations.createProject,
                  icon: 'plus',
                  onClick: () => handleCreateProject(customerId),
                },
              ]}
            />

            <CustomerProjectCard stage="PLANNING" projects={projects} />
            <CustomerProjectCard stage="BILLING" projects={projects} />
            <CustomerProjectCard stage="ONGOING" projects={projects} />
            <CustomerProjectCard stage="FINISHED" projects={projects} />
          </Information>
        </>
      )}
    </Wrapper>
  )
}

export const Customer: React.FC = () => {
  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <CustomerInner />
    </LoadScript>
  )
}

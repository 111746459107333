import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  ImageViewer,
  Message,
  PageHeader as BasePageHeader,
  PageHeaderButton,
  PageHeaderIconButton,
} from 'components'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { RecentFiles } from './components'
import { FolderTable } from './components/FolderTable'
import { ProjectFilesMobile } from './ProjectFilesMobile'
import { useFilesUtils, useProjectFilesQueries } from './util'

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 0 1rem 1rem;

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
const PageHeader = styled(BasePageHeader)`
  margin-bottom: 0;
  padding-top: 0;
`

interface ProjectFilesProps {
  projectId: string
  isChecklistFolders?: boolean
  isChecklistImages?: boolean

  onOverrideBreadcrumbs?: (ids: string[]) => void
}

export const ProjectFiles: React.FC<ProjectFilesProps> = ({
  projectId,
  isChecklistFolders = false,
  isChecklistImages = false,

  onOverrideBreadcrumbs,
}) => {
  const translations = useTranslate({
    search: 'files.search-for-files',
    newFolder: 'files.new-folder',
    uploadFiles: 'files.upload-files',
    root: 'common.root',
    checklistImages: 'common.checklist-images',

    columns: {
      name: 'common.name',
      updatedAt: 'common.last-updated',
      numberOfFolders: 'files.number-of-folders',
      numberOfFiles: 'files.number-of-files',
    },

    errors: {
      query: 'errors.something-went-wrong',
    },
  })

  const { folderId } = useParams<{ folderId?: string }>()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [sort, setSort] = useState('name')
  const [viewImage, setViewImage] = useState<string | null>(null)

  const orderFilesBy = useMemo(
    () =>
      !projectId
        ? ''
        : sort
            .split(',')
            .reduce(
              (acc, cur, idx) =>
                cur.includes('name') || cur.includes('updatedAt')
                  ? (acc += (idx > 0 ? ',' : '') + cur)
                  : acc,
              ''
            ),
    [projectId, sort]
  )

  const {
    folderData,
    parentFolder,
    folders,
    files,
    checklists,
    checklistImages,
    imageFiles,
    loading: queryLoading,
    error,
  } = useProjectFilesQueries({
    projectId,
    folderId,
    debouncedSearch,
    sort,
    orderFilesBy,
    isChecklistFolders,
    isChecklistImages,
    onOverrideBreadcrumbs,
  })

  const {
    handleCreateFolder,
    handleUploadFiles,
    makeFolderTableMenu,
    makeFileTableMenu,
    loading: mutationLoading,
  } = useFilesUtils({
    projectId,
    folderId: folderId ?? null,
    refetchQueries: !!folderId
      ? ['Folder', 'FolderTree']
      : ['RootFolders', 'FolderTree'],
    isSearch: !!debouncedSearch,
  })

  const buttons = useMemo<PageHeaderButton[]>(() => {
    if (!!debouncedSearch) return []

    const buttons: PageHeaderButton[] = [
      {
        text: translations.newFolder,
        icon: 'plus',
        onClick: handleCreateFolder,
      },
    ]

    if (!!folderId)
      buttons.push({
        text: translations.uploadFiles,
        icon: {
          icon: 'upload',
          type: 'solid',
        },
        onClick: () => handleUploadFiles(),
      })

    return buttons
  }, [
    debouncedSearch,
    folderId,
    handleCreateFolder,
    handleUploadFiles,
    translations.newFolder,
    translations.uploadFiles,
  ])

  const iconButtons = useMemo<PageHeaderIconButton[]>(() => {
    if (
      (!isChecklistFolders && !isChecklistImages && !folderData) ||
      !!debouncedSearch
    )
      return []

    return [
      {
        icon: 'level-up-alt',
        side: 'left',
        text: isChecklistImages
          ? translations.checklistImages
          : parentFolder?.name ?? translations.root,
        href: isChecklistImages
          ? `/projects/${projectId}/files/checklists`
          : !!parentFolder
          ? `/projects/${projectId}/files/${parentFolder.id}`
          : `/projects/${projectId}/files`,
        mirror: true,
      },
    ]
  }, [
    isChecklistFolders,
    isChecklistImages,
    folderData,
    debouncedSearch,
    translations.checklistImages,
    translations.root,
    parentFolder,
    projectId,
  ])

  const sharedProps = useMemo(
    () => ({
      projectId,
      folders,
      files,
      checklists,
      checklistImages,
      withChecklistFiles:
        !debouncedSearch &&
        !isChecklistFolders &&
        !isChecklistImages &&
        !folderData,
      isChecklistFolders: !debouncedSearch && isChecklistFolders,
      isChecklistImages: !debouncedSearch && isChecklistImages,
      makeFolderTableMenu,
      makeFileTableMenu,
      onImageClick: setViewImage,
    }),
    [
      checklistImages,
      checklists,
      debouncedSearch,
      files,
      folderData,
      folders,
      isChecklistFolders,
      isChecklistImages,
      makeFileTableMenu,
      makeFolderTableMenu,
      projectId,
    ]
  )

  const isLoading = queryLoading || mutationLoading

  if (error)
    return <Message.Error show centered text={translations.errors.query} />

  return (
    <Wrapper>
      <ImageViewer
        popup
        open={viewImage !== null}
        images={imageFiles}
        initialImage={viewImage ?? 0}
        onClose={() => setViewImage(null)}
      />

      {isMobileOnly ? (
        <ProjectFilesMobile
          {...sharedProps}
          parentFolder={parentFolder}
          loading={isLoading}
          search={search}
          root={!folderId}
          onSearchChange={setSearch}
          onCreateFolder={handleCreateFolder}
          onUploadFiles={handleUploadFiles}
        />
      ) : (
        <>
          <PageHeader
            loading={isLoading}
            search={{
              value: search,
              placeholder: translations.search,
              left: true,
              onChange: setSearch,
            }}
            buttons={buttons}
            iconButtons={iconButtons}
          />

          {!debouncedSearch && <RecentFiles projectId={projectId} />}

          <FolderTable
            {...sharedProps}
            sort={sort}
            isSearch={!!debouncedSearch}
            disableDrop={!folderId}
            onDrop={handleUploadFiles}
            onSortChange={setSort}
            onResetSearch={() => setSearch('')}
          />
        </>
      )}
    </Wrapper>
  )
}

import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  useBreadcrumbOverrides,
  useOnErrorAuto,
  useToast,
  useUser,
} from 'util/hooks'
import { AbsenceForm } from './components/AbsenceForm'
import { CREATE_ABSENCE_MUTATION } from './mutations'
import {
  CreateAbsenceMutation,
  CreateAbsenceMutationVariables,
} from './types.graphql'
import { isCreateAbsenceMutationVariables } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 1rem 1rem 2rem;
  }
`

interface CreateAbsenceProps {}

export const CreateAbsence: React.FC<CreateAbsenceProps> = () => {
  const translations = useTranslate({
    createAbsenceFor: ['timesheets.register-absence-for-name', { name: '' }],
    createAbsence: 'timesheets.register-absence',
    createSuccess: 'timesheets.toasts.create-absence-success',
  })

  const name = useUser().fullName

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  useBreadcrumbOverrides({
    create: translations.createAbsence,
  })

  const [createAbsence, { loading }] = useMutation<
    CreateAbsenceMutation,
    CreateAbsenceMutationVariables
  >(CREATE_ABSENCE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Bootstrap'],
    onCompleted() {
      addToast('success', translations.createSuccess)
      history.push(`/absences/`)
      mixpanel.track('Create Absence')
    },
    onError: onErrorAuto(),
  })

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader
          title={translations.createAbsenceFor({ name: name })}
          loading={loading}
        />
      )}

      <AbsenceForm
        loading={loading}
        onSubmit={variables =>
          isCreateAbsenceMutationVariables(variables) &&
          createAbsence({ variables })
        }
      />
    </Wrapper>
  )
}

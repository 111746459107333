import { useGlobal } from '@ur/react-hooks'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { NavLink, useHistory } from 'react-router-dom'
import { HeaderTab } from 'store'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

const Tabs = styled.nav`
  position: relative;
  display: inline-flex;

  background-color: #fafafa;
  border-radius: 8px 8px 0 0;
  border: 1px solid ${props => props.theme.colors.gray6};
  border-width: 1px 1px 0 1px;

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    padding: 0 2rem;

    color: ${props => props.theme.colors.darkGrayText};
    font-size: 0.8rem;
    font-weight: 600;
    border-width: 0 1px;
    border-radius: 8px 8px 0 0;

    &.active {
      top: -1px;
      z-index: 1;

      padding-top: 1px;
      background-color: white;
      color: ${props => props.theme.colors.primary};
      border: 1px solid white;
      box-shadow: 4px 0 6px -6px rgba(0, 0, 0, 0.1),
        -4px 0 6px -6px rgba(0, 0, 0, 0.1);

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: calc(100% + 1px);
        left: -1px;
        right: -1px;
        height: 1px;
        background-color: white;
      }
    }
    &:not(.active) {
      background-color: #fafafa;
    }
    &:first-child {
      &.active {
        left: -1px;
        padding-left: calc(2rem + 1px);
        box-shadow: 4px 0 6px -6px rgba(0, 0, 0, 0.1);
      }
    }
    &:last-child {
      &.active {
        padding-right: calc(2rem + 1px);
        box-shadow: -4px 0 6px -6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr 1fr;

    a {
      height: 56px;
      border-radius: 0;
    }
  }
`
const Body = styled.div`
  ${props => props.theme.media.desktop} {
    padding: 1.2rem 0.5rem;

    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px;
  }
`

export interface Tab {
  href: string
  text: string
  hide?: boolean | (() => boolean)
}

interface TabbedPageProps {
  tabs: Tab[]
}

export const TabbedPage: React.CFC<TabbedPageProps> = ({ children, tabs }) => {
  const prevTabs = useRef<HeaderTab[]>([])

  const history = useHistory()
  const [, setHeaderTabs] = useGlobal('header.tabs')

  const visibleTabs = useMemo(
    () =>
      tabs.filter(tab =>
        typeof tab.hide === 'undefined'
          ? true
          : typeof tab.hide === 'boolean'
          ? !tab.hide
          : !tab.hide()
      ),
    [tabs]
  )

  useEffect(() => {
    if (!isMobileOnly || isEqual(prevTabs.current, visibleTabs)) return
    prevTabs.current = visibleTabs
    if (visibleTabs.length < 2) return

    setHeaderTabs(visibleTabs)
  }, [setHeaderTabs, visibleTabs])

  useEffect(() => {
    if (!isMobileOnly) return

    const unregister = history.listen(loc => {
      if (tabs.some(tab => loc.pathname === tab.href)) return
      setHeaderTabs([])
    })

    return unregister
  }, [history, setHeaderTabs, tabs])

  return (
    <Wrapper>
      {!isMobileOnly && (
        <Tabs>
          {visibleTabs.map(tab => (
            <NavLink key={tab.href} exact to={tab.href}>
              {tab.text}
            </NavLink>
          ))}
        </Tabs>
      )}

      <Body>{children}</Body>
    </Wrapper>
  )
}

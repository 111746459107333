import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  ButtonIconProps,
  Loader,
} from '@ur/react-components'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { overloadColor } from 'util/style'

type ButtonVariant = 'primary' | 'cancel'

const getButtonColor = (props: StyledButtonProps) =>
  ({
    primary: 'white',
    cancel: 'white',
  }[props.variant])

interface StyledButtonProps {
  variant: ButtonVariant
}
const StyledButton = styled(BaseButton)<StyledButtonProps>`
  color: ${props => (props.color ? props.color : getButtonColor(props))};

  &:not(:disabled):hover {
    background-color: ${props =>
      props.background && overloadColor(props.background) + 'ba'};

    .--button-icon-left,
    .--button-icon-right {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &:not(:disabled):focus {
    outline: 2px solid #323232;
  }
`

interface ButtonProps extends BaseButtonProps {
  href?: string
  variant?: ButtonVariant
  loading?: boolean
}

export const Button: React.CFC<ButtonProps> = ({
  children,
  href,
  variant = 'primary',
  loading = false,
  disabled = false,
  onClick,
  ...props
}) => {
  const theme = useTheme()
  const history = useHistory()

  function handleClick(evt: React.MouseEvent<HTMLButtonElement>) {
    onClick?.(evt)
    if (evt.defaultPrevented || !href) return
    if (href.startsWith('http')) window.location.href = href
    else history.push(href)
  }

  const overrideBackground = useMemo(
    () =>
      props.background ??
      (variant === 'cancel' ? theme.colors.gray3 : theme.colors.primary),
    [variant, theme, props.background]
  )

  const [iconLeftProps, iconRightProps] = useMemo<
    [ButtonIconProps | undefined, ButtonIconProps | undefined]
  >(() => {
    const common: Partial<ButtonIconProps> = {
      background: props.background ?? overrideBackground,
    }
    return [
      props.iconLeftProps
        ? {
            ...common,
            ...props.iconLeftProps,
          }
        : undefined,
      props.iconRightProps
        ? {
            ...common,
            ...props.iconRightProps,
          }
        : undefined,
    ]
  }, [
    overrideBackground,
    props.background,
    props.iconLeftProps,
    props.iconRightProps,
  ])

  return (
    <StyledButton
      role={props.role ?? (!!href ? 'link' : undefined)}
      variant={variant}
      height={props.height ?? '46px'}
      borderRadius={props.borderRadius ?? theme.sizes.defaultBorderRadius}
      centerContent={props.centerContent ?? true}
      {...props}
      background={overrideBackground}
      disabled={disabled || loading}
      iconLeftProps={iconLeftProps}
      iconRightProps={iconRightProps}
      onClick={handleClick}
    >
      {loading ? <Loader.Dots size={32} colorPrimary="white" /> : children}
    </StyledButton>
  )
}

import en from './en'

const nb: typeof en = {
  'absence.hours': '{value} timer',
  'absences.sum': 'Fravær',
  'activities.day-end': 'Dag slutt',
  'activities.day-start': 'Dag start',
  'activities.driving-grade': 'Kjørekarakter',
  'activities.fuel-usage': 'Drivstoffforbruk',
  'activities.working-hours': 'Arbeidstimer',
  'admin.create-company': 'Opprett firma',
  'admin.create-company.success': 'Firmaet {name} ble opprettet',
  'admin.dashboard': 'Admin-panel',
  'admin.dashboard.create-new-company': 'Opprett nytt firma',
  'checklist.completed': 'Fullførte punkter',
  'checklist.edit_checklist': 'Endre sjekkliste',
  'checklist.new_checklist': 'Ny sjekkliste',
  'checklist.search': 'Søk i sjekklister',
  'checklists.add-checklist': 'Ny sjekkliste',
  'checklists.add-checklist-item': 'Legg til sjekklistepunkt',
  'checklists.add-checklist-template': 'Opprett mal',
  'checklists.checked-by-at': 'Krysset av av <a></a>, <span></span>',
  'checklists.checklist-item': 'Sjekklistepunkt',
  'checklists.checklist-item-comment': 'Sjekklistepunktkommentar',
  'checklists.checklist-item-image': 'Sjekklistepunktbilde',
  'checklists.checklist-items': 'Sjekklistepunkter',
  'checklists.checklist-template': 'Sjekklistemal',
  'checklists.checklist-template-item': 'Sjekklistemalpunkt',
  'checklists.checklist-templates': 'Sjekklistemaler',
  'checklists.close-checklist': 'Lukk sjekkliste',
  'checklists.closed': 'Lukket',
  'checklists.create': 'Opprett sjekkliste',
  'checklists.create-checklist': 'Opprett sjekkliste',
  'checklists.create-checklist-template': 'Opprett sjekklistemal',
  'checklists.delete-checklist-template': 'Slett sjekklistemal',
  'checklists.delete-prompt-text':
    'Er du sikker på at du vil slette sjekklisten?',
  'checklists.deleted': 'Sjekklisten ble slettet',
  'checklists.deleting-checklist': 'Slette sjekkliste',
  'checklists.deleting-checklist-prompt':
    'Er du sikker på at du ønsker å slette denne sjekklisten?',
  'checklists.deleting-template': 'Sletter mal',
  'checklists.deleting-template-prompt':
    'Er du sikker på at du ønsker å slette denne sjekklistemalen?',
  'checklists.description-altered': 'Beskrivelse endret',
  'checklists.download-pdf': 'Last ned PDF',
  'checklists.edit-item': 'Redigér punkt',
  'checklists.error-item-must-provide-title':
    'Sjekklistepunktet må ha en tittel',
  'checklists.errors.checklist-template-item-title-must-be-unique':
    'Punkttitlene må være unike innen en sjekklistemal',
  'checklists.errors.checklist-template-title-must-be-unique':
    'Det finnes allerede en sjekklistemal med dette navnet. Tittelen må være unik',
  'checklists.errors.patch-purpose': 'Kunne ikke oppdatere hensikt',
  'checklists.errors.send-email':
    'Kunne ikke sende sjekklisten via epost - har du oppgitt en gyldig epost-adresse?',
  'checklists.items': 'Punkter',
  'checklists.must-select-template':
    'Du kan ikke endre dette direkte, vennligst velg en mal.',
  'checklists.n-checklist-templates':
    '{n, plural, one {1 sjekklistemal} other {{n} sjekklistemaler}}',
  'checklists.n-checklists':
    '{n, plural, one {1 sjekkliste} other {{n} sjekklister}}',
  'checklists.n-items': '{n, plural, one {1 punkt} other {{n} punkter}}',
  'checklists.no-checklist-items': 'Ingen sjekklistepunkter',
  'checklists.no-checklist-templates-found': 'Ingen sjekklistemaler funnet',
  'checklists.no-checklists-found': 'Ingen sjekklister funnet',
  'checklists.no-purpose': 'Intet formål',
  'checklists.ongoing-checklists': 'Pågående sjekklister',
  'checklists.prompt-remove-comment-from-checklist-item':
    'Er du sikker på at du ønsker å fjerne denne kommentaren?',
  'checklists.prompt-remove-image-from-checklist-item':
    'Er du sikker på at du ønsker å fjerne dette bildet fra sjekklistepunktet?',
  'checklists.prompts.delete-template-item':
    'Er du sikker på at du vil slette dette sjekklistemalpunktet?',
  'checklists.prompts.delete-template-item-title': 'Slett sjekklistemalpunkt',
  'checklists.prompts.send-email': 'Angi mottakerens epost-adresse',
  'checklists.prompts.send-email-title': 'Send sjekkliste på epost',
  'checklists.purpose': 'Formål',
  'checklists.purpose-altered': 'Formål endret',
  'checklists.purpose-placeholder':
    'Rom, leilighetsnr eller formål med denne sjekklisten',
  'checklists.query-title-description-user': 'Tittel, beskrivelse eller bruker',
  'checklists.query-title-or-such': 'Tittel, formål eller bruker',
  'checklists.ready-to-submit': 'Klar til innsendelse',
  'checklists.removing-item': 'Fjerner punkt',
  'checklists.removing-item-confirm':
    'Er du sikker på at du vil fjerne dette sjekklistepunktet?',
  'checklists.reopen-checklist': 'Gjenåpne sjekkliste',
  'checklists.search-for-templates': 'Søk etter sjekklistemal',
  'checklists.select-template': 'Velg mal',
  'checklists.status-done': 'Ferdig',
  'checklists.status-irrelevant': 'Irrelevant',
  'checklists.status-not-done': 'Ikke ferdig',
  'checklists.submit-checklist': 'Send inn sjekkliste',
  'checklists.success.patch-purpose': 'Hensikt oppdatert',
  'checklists.success.send-email': 'Sjekklisten ble sendt via epost',
  'checklists.template-create-unique-error':
    'En sjekklistemal med denne tittelen eksisterer allerede',
  'checklists.template-created': 'Sjekklistemalen ble opprettet',
  'checklists.template-deleted': 'Sjekklistemalen ble slettet',
  'checklists.template-does-not-exist-error': 'Sjekklistemalen eksisterer ikke',
  'checklists.template-updated': 'Sjekklistemalen ble oppdatert',
  'checklists.toasts.checklist-close-error':
    'Sjekklisten kunne ikke bli lukket',
  'checklists.toasts.checklist-delete-error':
    'Sjekklisten kunne ikke bli slettet',
  'checklists.toasts.checklist-deleted': 'Sjekklisten ble slettet',
  'checklists.toasts.checklist-item-updated': 'Sjekklistepunktet ble oppdatert',
  'checklists.toasts.checklist-reopened': 'Sjekklisten ble gjenåpnet',
  'checklists.toasts.checklist-sent': 'Sjekklisten ble sendt',
  'checklists.toasts.checklist-updated': 'Sjekklisten ble oppdatert',
  'checklists.toasts.closed-but-no-cigar':
    'Sjekklisten ble lukket, men eposten kunne ikke bli sent. Du kan prøve igjen manuelt.',
  'checklists.toasts.could-not-delete': 'Kunne ikke slette sjekklisten',
  'checklists.toasts.delete-success': 'Sjekkliste slettet',
  'checklists.use-template': 'Bruk mal',
  'checklists.view-checklist': 'Åpne sjekkliste',
  'checklists.view-template': 'Vis mal',
  'common.absence': 'Fravær',
  'common.absences': 'Fravær',
  'common.active': 'Aktiv',
  'common.active-alt': 'Aktive',
  'common.activities': 'Aktiviteter',
  'common.add': 'Legg til',
  'common.add-a-comment': 'Legg til en kommentar...',
  'common.add-comment': 'Legg til kommentar',
  'common.add-emergency-contact': 'Legg til pårørende',
  'common.address': 'Adresse',
  'common.address-not-found': 'Addresse ikke funnet',
  'common.administrator': 'Administrator',
  'common.all': 'Alle',
  'common.all-months': 'Alle måneder',
  'common.all-time': 'All tid',
  'common.all-years': 'Alle år',
  'common.archive': 'Arkivér',
  'common.archived': 'Arkivert',
  'common.attachments': 'Vedlegg',
  'common.availability': 'Tilgjengelighet',
  'common.back': 'Tilbake',
  'common.back-to-overview': 'Tilbake til oversikten',
  'common.back-to-x': 'Tilbake til {x}',
  'common.balance': 'Saldo',
  'common.billed': 'Fakturert',
  'common.billing': 'Fakturering',
  'common.businessOverview': 'Firmaoversikt',
  'common.call': 'Ring',
  'common.cancel': 'Avbryt',
  'common.category': 'Kategori',
  'common.change': 'Endre',
  'common.change-company': 'Bytt firma',
  'common.change-password': 'Endre passord',
  'common.changes-saved': 'Endringer lagret',
  'common.checklist': 'Sjekkliste',
  'common.checklist-files': 'Sjekklistefiler',
  'common.checklist-images': 'Sjekklistebilder',
  'common.checklists': 'Sjekklister',
  'common.choices': 'Valg',
  'common.choose': 'Velg',
  'common.choose-file': 'Velg fil',
  'common.choose-files': 'Velg filer',
  'common.choose-images': 'Velg bilder',
  'common.city': 'Poststed',
  'common.click-to-edit': 'Klikk for å endre',
  'common.close': 'Lukk',
  'common.closed': 'Lukket',
  'common.codes': 'Koder',
  'common.color': 'Farge',
  'common.comment': 'Kommenter',
  'common.comments': 'Kommentarer',
  'common.companies': 'Bedrifter',
  'common.company': 'Bedrift',
  'common.company-name': 'Firmanavn',
  'common.company-settings': 'Firmainnstillinger',
  'common.contact': 'Kontakt',
  'common.content': 'Innhold',
  'common.create': 'Opprett',
  'common.create-entry': 'Lag nytt innlegg',
  'common.create-folder': 'Lag ny mappe',
  'common.created-at': 'Opprettet',
  'common.created-by': 'Opprettet av',
  'common.created-by-who-at-when': 'Opprettet av <a>{who}</a> {when}',
  'common.created-t-alt': 'Opprettet for {t}',
  'common.ctrl-enter-to-save': 'Ctrl+Enter for å lagre',
  'common.ctrl-enter-to-submit': 'Ctrl+Enter for å poste',
  'common.custom': 'Tilpasset',
  'common.customer': 'Kunde',
  'common.customer-select-placeholder': 'Velg kunde',
  'common.customers': 'Kunder',
  'common.dashboard': 'Kontrollpanel',
  'common.date': 'Dato',
  'common.day-end': 'Dag slutt',
  'common.day-start': 'Dag start',
  'common.default-discount': 'Standard rabatt',
  'common.default-hourly-rate': 'Standard timepris',
  'common.default-material-discount': 'Standard rabatt på materiell',
  'common.delete': 'Slett',
  'common.deleted': 'Slettet',
  'common.deleting-image': 'Slette bilde',
  'common.deleting-image-prompt':
    'Er du sikker på at du ønsker å slette dette bildet?',
  'common.deleting-images': 'Slette bilder',
  'common.deleting-images-prompt':
    'Er du sikker på at du ønsker å slette disse bildene?',
  'common.description': 'Beskrivelse',
  'common.detail': 'Detalje',
  'common.details': 'Detaljer',
  'common.deviation': 'Avvik',
  'common.deviation-categories': 'Avvikskategorier',
  'common.deviations': 'Avvik',
  'common.distance': 'Distanse',
  'common.documentation': 'Dokumentasjon',
  'common.documentationIssues': 'Dokumentasjonsavvik',
  'common.done': 'Ferdig',
  'common.download': 'Last ned',
  'common.driving': 'Kjøring',
  'common.drop-file-here': 'Dra og slipp en fil her',
  'common.drop-files-here': 'Dra og slipp filer her',
  'common.drop-images-here': 'Dra og slipp bilder her',
  'common.edit': 'Endre',
  'common.edit-emergency-contact': 'Redigér pårørende',
  'common.edit-profile': 'Rediger profil',
  'common.edited': 'Redigert',
  'common.email': 'Epost',
  'common.emergency-contacts': 'Pårørende',
  'common.employee': 'Ansatt',
  'common.end': 'Slutt',
  'common.entry': 'Innlegg',
  'common.error': 'Det oppstod en feil',
  'common.estimate': 'Estimat',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.existing-files': 'Eksisterende filer',
  'common.file': 'Fil',
  'common.files': 'Filer',
  'common.filter': 'Filtrér',
  'common.first-name': 'Fornavn',
  'common.firstName': 'Fornavn',
  'common.folder': 'Mappe',
  'common.folders': 'Mapper',
  'common.forum': 'Forum',
  'common.from-date-alt': 'Fra',
  'common.general': 'Generelt',
  'common.general-info': 'Generell info',
  'common.go-to-checklists': 'Gå til sjekklister',
  'common.go-to-deviations': 'Gå til avvik',
  'common.go-to-files': 'Gå til filer',
  'common.go-to-timesheets': 'Gå til timelister',
  'common.handbook': 'Håndbok',
  'common.hide': 'Skjul',
  'common.hide-images': 'Skjul bilder',
  'common.home': 'Hjem',
  'common.hourlist': 'Timeliste',
  'common.hours': 'Timer',
  'common.hours-lowercase': 'timer',
  'common.hours-registered': 'Timer registrert',
  'common.image-deleted': 'Bilde slettet',
  'common.image-selected': 'Bilde valgt',
  'common.image-uploaded': 'Bilde lastet opp',
  'common.images': 'Bilder',
  'common.images-deleted': 'Bilder slettet',
  'common.images-selected': 'Bilder valgt',
  'common.imported': 'Importert',
  'common.inactive': 'Inaktiv',
  'common.info': 'Info',
  'common.information': 'Informasjon',
  'common.internal': 'Internt',
  'common.invalid': 'Ugyldig',
  'common.issue': 'Avvik',
  'common.issues': 'Avvik',
  'common.item': 'Punkt',
  'common.km': 'km',
  'common.language': 'Språk',
  'common.last-month': 'Forrige måned',
  'common.last-name': 'Etternavn',
  'common.last-six-months': 'Siste 6 måneder',
  'common.last-updated': 'Sist endret',
  'common.last-week': 'Forrige uke',
  'common.last-year': 'I fjor',
  'common.lastName': 'Etternavn',
  'common.link-months': 'Koble månedene',
  'common.load-more': 'Last mer',
  'common.loading': 'Laster',
  'common.loading-project-name': 'Laster prosjekt-navn',
  'common.logout': 'Logg ut',
  'common.modified': 'Sist endret',
  'common.module': 'Modul',
  'common.month': 'Måned',
  'common.move': 'Flytt',
  'common.my-projects': 'Mine prosjekter',
  'common.my-site': 'Min side',
  'common.n-elements-selected':
    '{n, plural, one {# element valgt} other {# elementer valgt}}',
  'common.n-hours': '{n, plural, one {1 time} other {{n} timer}}',
  'common.n-hours-span':
    '{n, plural, one {1 <span>time</span>} other {{n} <span>timer</span>}}',
  'common.n-images': '{n, plural, one {1 bilde} other {{n} bilder}}',
  'common.n-km': '{n} km',
  'common.n-results':
    '{n, plural, =0 {Ingen resultater} one {1 resultat} other {{n} resultater}}',
  'common.n-rows': '{n, plural, one {1 rad} other {{n} rader}}',
  'common.name': 'Navn',
  'common.new-files': 'Nye filer',
  'common.next': 'Neste',
  'common.next-page': 'Neste side',
  'common.no': 'Nei',
  'common.no-attachments': 'Ingen vedlegg',
  'common.no-comments': 'Ingen kommentarer',
  'common.no-content': 'Intet innhold',
  'common.no-data': 'Ingen data',
  'common.no-description': 'Ingen beskrivelse',
  'common.no-deviations': 'Ingen avvik',
  'common.no-emergency-contacts': 'Ingen pårørendre registrert',
  'common.no-files': 'Ingen filer',
  'common.no-images': 'Ingen bilder',
  'common.no-images-selected': 'Ingen bilder valgt',
  'common.no-more-data': 'Ikke mer data',
  'common.no-more-options': 'Ingen flere valg',
  'common.no-results': 'Ingen resultater',
  'common.none': 'Ingen',
  'common.none-selected': 'Ingen valgt',
  'common.not-implemented': 'Ikke implementert',
  'common.offers': 'Tilbud',
  'common.office': 'Kontor',
  'common.ok': 'OK',
  'common.open': 'Åpne',
  'common.open-alt': 'Åpne',
  'common.options': 'Valg',
  'common.or': 'eller',
  'common.or-drag-and-drop-images-here': 'eller dra og slipp bilder her',
  'common.other': 'Annet',
  'common.other-work': 'Annet arbeid',
  'common.overtime': 'Overtid',
  'common.overview': 'Oversikten',
  'common.path': 'Sti',
  'common.pause': 'Pause',
  'common.period': 'Periode',
  'common.phone': 'Telefon',
  'common.phone-number': 'Telefonnummer',
  'common.picture': 'Bilde',
  'common.playground': 'Lekeplass',
  'common.postal-code': 'Postnummer',
  'common.primary': 'Primær',
  'common.print': 'Skriv ut',
  'common.products': 'Produkter',
  'common.profile': 'Profil',
  'common.progress': 'Fremdrift',
  'common.project': 'Prosjekt',
  'common.project-select-placeholder': 'Velg prosjekt',
  'common.projects': 'Prosjekter',
  'common.prompts.unsaved-changes-prompt':
    'Du har ulagrede endringer. Er du sikker på at du ønsker å forlate denne siden?',
  'common.prompts.unsaved-changes-title': 'Ulagrede endringer',
  'common.purpose': 'Hensikt',
  'common.reason': 'Årsak',
  'common.recent-files': 'Siste filer',
  'common.reg-nr': 'Reg.nr.',
  'common.registered-by': 'Registrert av',
  'common.relation': 'Relasjon',
  'common.remove': 'Fjern',
  'common.remove-comment': 'Fjern kommentar',
  'common.remove-image': 'Fjern bilde',
  'common.required': 'Obligatorisk',
  'common.required-fields-missing': 'Påkrevde felter mangler',
  'common.required-fields-valid': 'Alle påkrevde felter er utfylt',
  'common.rest': 'Hvile',
  'common.role': 'Rolle',
  'common.root': 'Rot',
  'common.save': 'Lagre',
  'common.save_changes': 'Lagre endringer',
  'common.search': 'Søk',
  'common.segment': 'Segment',
  'common.select-image': 'Velg bilde',
  'common.select-placeholder': 'Gjør et valg…',
  'common.select-placeholder-user-type': 'Velg brukertype...',
  'common.select-placeholder-user-types': 'Velg brukertyper...',
  'common.selected': 'Valgt',
  'common.selected-images': 'Valgte bilder',
  'common.send': 'Send',
  'common.send-email': 'Send epost',
  'common.send-to-email': 'Send til epost',
  'common.set-primary': 'Sett primær',
  'common.settings': 'Innstillinger',
  'common.shortcuts': 'Snarveier',
  'common.show': 'Vis',
  'common.show-all': 'Vis alle',
  'common.show-images': 'Vis bilder',
  'common.show-profile': 'Vis profil',
  'common.sidebar-collapse': 'Minimér meny',
  'common.sidebar-expand': 'Utvid meny',
  'common.something-went-wrong': 'Noe gikk galt',
  'common.sorting': 'Sortering',
  'common.start': 'Start',
  'common.status': 'Status',
  'common.sub-folders': 'Undermapper',
  'common.submit': 'Send inn',
  'common.subtype': 'Undertype',
  'common.sum': 'Sum',
  'common.support': 'Support',
  'common.templates': 'Maler',
  'common.terminal': 'Terminal',
  'common.this-month': 'Denne måneden',
  'common.this-week': 'Denne uken',
  'common.this-year': 'I år',
  'common.time-entry': 'Timeoppføring',
  'common.time-period-all-time': 'All tid',
  'common.time-unknown': 'Tidspunkt ukjent',
  'common.timesheets': 'Timelister',
  'common.title': 'Tittel',
  'common.to-date-alt': 'Til',
  'common.today': 'I dag',
  'common.toggle-sidebar': 'Toggle sidebar',
  'common.total': 'Total',
  'common.total-alt': 'Totalt',
  'common.treated': 'Behandlet',
  'common.try-again': 'Prøv igjen',
  'common.type': 'Type',
  'common.unarchive': 'Dearkivér',
  'common.under-treatment': 'Under behandling',
  'common.unfinished': 'Ikke fullført',
  'common.unlink-months': 'Avkoble månedene',
  'common.untreated': 'Ubehandlet',
  'common.update': 'Oppdater',
  'common.update-entry': 'Endre innlegg',
  'common.update-folder': 'Endre mappe',
  'common.updated': 'Oppdatert',
  'common.updated-t-alt': 'Oppdatert for {t}',
  'common.updating': 'Oppdaterer',
  'common.upload': 'Last opp',
  'common.upload-files': 'Last opp filer',
  'common.upload-images': 'Last opp bilder',
  'common.uploaded-by': 'Lastet opp av',
  'common.user': 'Bruker',
  'common.user-guide': 'Brukerveiledning',
  'common.user-profile': 'Brukerprofil',
  'common.user-settings': 'Brukerinnstillinger',
  'common.user-type': 'Brukertype',
  'common.user-types': 'Brukertyper',
  'common.userType': 'Brukertype',
  'common.username': 'Brukernavn',
  'common.users': 'Brukere',
  'common.view': 'Se',
  'common.work': 'Arbeid',
  'common.written-by-x': 'Skrevet av <a>{x}</a>',
  'common.written-t': 'Skrevet for {t}',
  'common.year': 'År',
  'common.yes': 'Ja',
  'common.yesterday': 'I går',
  'common.zip': 'Postnr',
  'companies.add-folder': 'Legg til mappe',
  'companies.affiliated-not': 'Du er ikke tilknyttet en bedrift',
  'companies.company-folder-settings': 'Bedriftsmappeinnstilling',
  'companies.company-information': 'Firmainformasjon',
  'companies.company-name-error': 'Firmaet må ha et navn',
  'companies.company-settings': 'Firmainnstillinger',
  'companies.couldnt-load': 'Kunne ikke laste firmaer',
  'companies.deleting-folder': 'Slette mappe',
  'companies.deleting-folder-prompt':
    'Er du sikker på at du ønsker å slette denne standardmappen? Dette vil også slette alle undermapper og filer, og er ikke reverserbart.',
  'companies.errors.could-not-create-company': 'Kunne ikke opprette selskap',
  'companies.errors.email-address-already-in-use':
    'Brukerens epost-adresse er allerede i bruk',
  'companies.errors.short-name-is-already-in-use':
    'Det forkortede navnet er allerede i bruk',
  'companies.errors.short-name-taken': 'Navn opptatt',
  'companies.folder-name-unique-error': 'Mappenavn må være unike',
  'companies.i-agree-to-digitechs': 'Jeg godtar Digitech sine',
  'companies.loading': 'Laster dine firmaer',
  'companies.manual-entry': 'Manualinnlegg',
  'companies.manual-entry-attachment': 'Manualinnleggsvedlegg',
  'companies.manual-folder': 'Manualmappe',
  'companies.number-of-licences': 'Antall brukere/lisenser',
  'companies.only-admins-project-leader':
    'Kun administratorer kan være prosjekt-leder',
  'companies.privacy-policy': 'Personvern',
  'companies.prompts.delete-folder-setting':
    'Er du sikker på at du vil fjerne denne mappen? De eksisterende prosjekt-mappene vil ikke bli slettet.',
  'companies.prompts.delete-folder-setting-title': 'Slett mappe',
  'companies.select': 'Velg firma',
  'companies.short-name': 'Forkortet navn (maks 16 tegn)',
  'companies.terms-and-conditions': 'vilkår og betingelser',
  'companies.terms-and-conditions-header': 'Vilkår & Betingelser',
  'companies.toasts.company-was-edited': 'Bedriftsinformasjonen ble oppdatert',
  'company.company-folders': 'Firmamapper',
  'company.company-folders-help-text':
    'Når du oppretter et nytt prosjekt vil mappene definert her bli rot-mappene i mappestrukturen til det nye prosjektet. Du kan også aktivere/deaktivere mapper ved å presse ikonet i den aktuelle mappens rad. Deaktiverte mapper vil ikke bli lagt til i nye prosjekter.',
  'company.companyLogo': 'Firmalogo',
  'company.enabled': 'Aktivert',
  'company.folder-name': 'Mappenavn',
  'customers.add-customer': 'Opprett kunde',
  'customers.address': 'Adresse',
  'customers.billing-email': 'E-post fakturering',
  'customers.billing-email-label': 'E-post fakturering',
  'customers.billing-email-label-paren':
    'Kontaktpersonens e-post brukes ved tomt felt',
  'customers.billing-settings': 'Fakturering',
  'customers.column-address': 'Adresse',
  'customers.column-contact': 'Kontakt',
  'customers.column-name': 'Navn',
  'customers.column-type': 'Type',
  'customers.contact-person': 'Kontaktperson',
  'customers.create-customer': 'Opprett kunde',
  'customers.create-error': 'Feil ved opprettelse av kunde',
  'customers.create-success': 'Kunde opprettet',
  'customers.created': 'Opprettet',
  'customers.customer-created-header': 'Kunde opprettet',
  'customers.customer-created-message':
    'Kunden «{name}» ble opprettet. Tar deg til kundens informasjon om {time}',
  'customers.customer-types.company': 'Bedrift',
  'customers.customer-types.private': 'Privat',
  'customers.delete-error': 'Kunden kunne ikke bli slettet.',
  'customers.delete-success': 'Kunden ble slettet.',
  'customers.deleting-customer-project-list':
    'Disse prosjektene er tilknyttet denne kunden, og vil bli fjernet fra prosjektoversikten om kunden slettes:',
  'customers.edit-customer': 'Endre kunde',
  'customers.edit-success': 'Kundeinformasjon endret',
  'customers.errors.could-not-find-organization':
    'Kunne ikke finne organisasjonen',
  'customers.errors.customer-does-not-exist': 'Kunden eksisterer ikke',
  'customers.general-information': 'Generell informasjon',
  'customers.information': 'Informasjon',
  'customers.jump-to-page': 'hopp til side',
  'customers.loading': 'Laster...',
  'customers.map': 'Kart',
  'customers.no-customer': 'Ingen kunde',
  'customers.no-data': 'Ingen data',
  'customers.of': 'av',
  'customers.org-nr': 'Org. nr.',
  'customers.organization-number': 'Organisasjonsnummer',
  'customers.page': 'Side',
  'customers.pagination-next': 'Neste side',
  'customers.pagination-previous': 'Forrige side',
  'customers.postal-area': 'Poststed',
  'customers.postal-code': 'Postnummer',
  'customers.postal-info': 'Postinfo',
  'customers.prompts.delete-customer':
    'Er du sikker på at du ønsker å slette denne kunden?',
  'customers.prompts.delete-customer-title': 'Slett kunde',
  'customers.prompts.n-deleting-customer-project-list':
    '{n, plural, one {Dette prosjektet er tilknyttet denne kunden, og vil bli fjernet fra prosjektoversikten om kunden slettes:} other {Disse {n} prosjektene er tilknyttet denne kunden, og vil bli fjernet fra prosjektoversikten om kunden slettes:}}',
  'customers.retrieve': 'Hent',
  'customers.rows': 'rader',
  'customers.rows-per-page': 'rader per side',
  'customers.search': 'Søk etter kunde',
  'customers.select-a-customer': 'Velg en kunde',
  'customers.select-customer': 'Velg kunde',
  'customers.show-customer': 'Vis kunde',
  'customers.type': 'Type',
  'dashboard.all-sub-modules-disabled': 'Alle submoduler deaktivert',
  'dashboard.configure-modules': 'Konfigurér moduler',
  'dashboard.lastForumPosts': 'Siste innlegg',
  'dashboard.page-description':
    'Du befinner deg nå på «Min Side». Her vil nyttig informasjon visualiseres etter nødvendig data er fylt inn. Et prosjekt må ha en kunde, som kan opprettes under «Kunder» i navigasjonen til venstre. Hvis du bare vil komme i gang, kan du opprette et prosjekt med den inkluderte testkunden.',
  'dashboard.registerHours': 'Registrer timer',
  'dashboard.registered-elements': 'Registrerte elementer',
  'dashboard.sub-modules.checklists': 'Sjekklister',
  'dashboard.sub-modules.companyStats': 'Firmastatistikk',
  'dashboard.sub-modules.deviations': 'Avvik',
  'dashboard.sub-modules.files': 'Filer',
  'dashboard.sub-modules.keyFigures': 'Nøkkeltall',
  'dashboard.sub-modules.keyFigures.timesheets.no-data-for-period':
    'Ingen registrerte timer for valgte filter og periode',
  'dashboard.sub-modules.keyFigures.users.user-type-distribution.description':
    'Dette diagrammet viser distribusjonen av brukertyper innenfor dette firmaet.',
  'dashboard.sub-modules.projects': 'Prosjekter',
  'dashboard.sub-modules.timesheets': 'Timelister',
  'dashboard.sub-modules.userStats': 'Brukerstatistikk',
  'deviations.action': 'Tiltak',
  'deviations.actualAction': 'Faktisk tiltak',
  'deviations.actualActionPlaceholder': 'Fyll inn faktisk tiltak',
  'deviations.add-deviation': 'Legg til avvik',
  'deviations.add-deviation-category': 'Ny avvikskategori',
  'deviations.add-performed-action': 'Legg til tiltak',
  'deviations.archive': 'Arkivér',
  'deviations.categories': 'Kategorier',
  'deviations.category': 'Kategori',
  'deviations.close': 'Lukk',
  'deviations.create-deviation': 'Opprett avvik',
  'deviations.created-at-t': 'Opprettet {t}',
  'deviations.delete-category-prompt':
    'Er du sikker på at du ønsker å slette denne kategorien?',
  'deviations.delete-category-title': 'Slett kategori',
  'deviations.delete-deviation': 'Slett avvik',
  'deviations.delete-deviation-prompt':
    'Er du sikker på at du ønsker å slette dette avviket? Denne handlingen kan ikke bli reversert.',
  'deviations.delete-deviation-success': 'Avviket ble slettet',
  'deviations.deleted': 'Avviket ble slettet',
  'deviations.deleting-deviation': 'Slette avvik',
  'deviations.deleting-deviation-prompt':
    'Er du sikker på at du ønsker å slette dette avviket?',
  'deviations.deviation-archive-error-toast': 'Avviket kunne ikke arkiveres.',
  'deviations.deviation-archive-success-toast': 'Avviket ble arkivert.',
  'deviations.deviation-categories': 'Avvikskategorier',
  'deviations.deviation-create-error-toast': 'Avviket ble ikke opprettet.',
  'deviations.deviation-create-success-toast': 'Avviket ble opprettet.',
  'deviations.deviation-delete-error-toast': 'Avviket kunne ikke slettes.',
  'deviations.deviation-delete-success-toast': 'Avviket ble slettet.',
  'deviations.deviation-edit-error-toast': 'Avviket kunne ikke bli oppdatert',
  'deviations.deviation-edit-success-toast': 'Avviket ble oppdatert',
  'deviations.deviation-image': 'Avviksbilde',
  'deviations.deviation-not-found-error': 'Fant ikke avviket du leter etter',
  'deviations.deviation-performed-action': 'Utførte tiltak på avvik',
  'deviations.deviation-treated-error-toast':
    'Avviket kunne ikke bli behandlet.',
  'deviations.deviation-treated-success-toast': 'Avviket ble lukket.',
  'deviations.edit-deviation': 'Endre avvik',
  'deviations.error-could-not-create': 'Kunne ikke opprette avvik.',
  'deviations.errors.could-not-load-categories': 'Kunne ikke laste kategorier',
  'deviations.errors.create-category-error':
    'Kunne ikke opprette avvikskategori',
  'deviations.errors.delete-category-error': 'Kunne ikke slette avvikskategori',
  'deviations.errors.send-email':
    'Kunne ikke sende avviket via epost - har du oppgitt en gyldig epost-adresse?',
  'deviations.errors.update-category-error':
    'Kunne ikke redigere avvikskategori',
  'deviations.images-of-deviation': 'Bilder av avvik',
  'deviations.internal-deviation': 'Internt avvik',
  'deviations.internal-deviations': 'Interne avvik',
  'deviations.modified-at-t': 'Endret {t}',
  'deviations.n-categories':
    '{n, plural, one {1 kategori} other {{n} kategorier}}',
  'deviations.n-deviations': '{n} avvik',
  'deviations.no-categories': 'Ingen kategorier',
  'deviations.no-deviations-found': 'Ingen avvik funnet',
  'deviations.no-performed-action': 'Tiltak ikke utført',
  'deviations.open-deviation': 'Åpne avvik',
  'deviations.performed-action': 'Utført tiltak',
  'deviations.performed-action-added': 'Utført handling lagt til',
  'deviations.performed-action-updated': 'Faktisk tiltak oppdatert',
  'deviations.performed-actions': 'Utførte tiltak',
  'deviations.project-deviations': 'Prosjekt-avvik',
  'deviations.prompt-remove-from-checklist-item':
    'Er du sikker på at du ønsker å fjerne dette avviket fra sjekklistepunktet? Avviket vil ikke bli slettet.',
  'deviations.prompts.close-deviation-prompt':
    'Ved å lukke avviket bekrefter du at det er ferdigbehandlet og ikke lenger kan endres på. Det vil bli markert som lukket. Ønsker du å lukke avviket?',
  'deviations.prompts.close-deviation-title': 'Lukk avvik',
  'deviations.prompts.could-not-delete': 'Kunne ikke slette avvik',
  'deviations.prompts.delete-performed-action-prompt':
    'Er du sikker på at du ønsker å slette dette utførte tiltaket?',
  'deviations.prompts.delete-performed-action-title': 'Slett utført tiltak',
  'deviations.prompts.send-email': 'Angi mottakerens epost-adresse',
  'deviations.prompts.send-email-title': 'Send avvik på epost',
  'deviations.provide-performed-action': 'Fyll inn faktisk tiltak',
  'deviations.provide-suggested-action': 'Fyll inn foreslått tiltak',
  'deviations.query-title-or-such': 'Tittel eller beskrivelse',
  'deviations.register-deviation': 'Registrér avvik',
  'deviations.remove-deviation': 'Fjern avvik',
  'deviations.reopen': 'Gjenåpne',
  'deviations.restore': 'Gjenopprett',
  'deviations.search': 'Søk i avvik',
  'deviations.show-suggested-actions': 'Vis tiltak',
  'deviations.success.send-email': 'Avviket ble sendt via epost',
  'deviations.suggested-action': 'Foreslått tiltak',
  'deviations.suggested-action-updated': 'Foreslått tiltak oppdatert',
  'deviations.supply-performed-action':
    'Du må oppgi et utført tiltak i tekstboksen',
  'deviations.toasts.create-category-success': 'Avvikskategorien ble opprettet',
  'deviations.toasts.create-performed-action-error':
    'Kunne ikke opprette tiltaket',
  'deviations.toasts.create-performed-action-success': 'Tiltaket ble opprettet',
  'deviations.toasts.delete-category-success': 'Avvikskategorien ble slettet',
  'deviations.toasts.delete-performed-action-error':
    'Kunne ikke slette tiltaket',
  'deviations.toasts.delete-performed-action-success': 'Tiltaket ble slettet',
  'deviations.toasts.edit-category-success': 'Avvikskategorien ble endret',
  'deviations.toasts.edit-performed-action-error': 'Kunne inne endre tiltaket',
  'deviations.toasts.edit-performed-action-success': 'Tiltaket ble endret',
  'deviations.treated': 'Behandlet',
  'deviations.treated-by-who-at-when': 'Behandlet av <a>{who}</a> {when}',
  'deviations.under-treatment': 'Avvik under behandling',
  'deviations.warnings.deviation-archived': 'Dette avviket er arkivert',
  'deviations.warnings.deviation-closed': 'Dette avviket er lukket',
  'deviations.what-action-was-performed': 'Hvilket tiltak ble utført?',
  'empty-pages.absences.button': 'Registrer',
  'empty-pages.absences.description':
    'Registrer fraværstimene dine her. Når du har registrert et fravær vil alle fraværene dine vises i en oversikt.',
  'empty-pages.absences.header': 'Fravær',
  'empty-pages.checklistTemplates.button': 'Opprett sjekklistemal',
  'empty-pages.checklistTemplates.description':
    'Her kan du opprette og ha oversikt over alle sjekklistemalene dine. Disse blir brukt under opprettelse av sjekklister.',
  'empty-pages.checklistTemplates.header': 'Sjekklistemaler',
  'empty-pages.checklists.button': 'Opprett sjekkliste',
  'empty-pages.checklists.description':
    'Her kan du se en oversikt over sjekklistene dine og opprette nye. For å opprette en sjekkliste trenger du et prosjekt og en sjekklistemal. Sjekklistemaler finner du i fanen i toppen av oversikten.',
  'empty-pages.checklists.header': 'Sjekklister',
  'empty-pages.dashboard.button': 'Opprett prosjekt',
  'empty-pages.dashboard.description':
    'Velkommen til Digitech, du er nå på «Min Side». Her vil nyttig informasjon presenteres når du har begynt å bruke programmet. Start med å opprette et prosjekt med knappen nedenfor med den inkluderte testkunden. Et prosjekt må ha en kunde, men du kan opprette en kunde ved å gå på kunder i menyen for så å opprette din egen.',
  'empty-pages.dashboard.header': 'Min side',
  'empty-pages.deviations.button': 'Opprett avvik',
  'empty-pages.deviations.description':
    'Kom i gang med å registrere avvik ved å trykke på knappen under. For å opprette avvik trenger du et prosjekt. Deretter vil du få en oversikt over alle avvik som er registrert i ditt selskap.',
  'empty-pages.deviations.header': 'Avvik',
  'empty-pages.handbook.button': 'Opprett mappe',
  'empty-pages.handbook.description':
    'Trykk på knappen under for å begynne å lage din Håndbok. Start med å opprette mapper for så å opprette innlegg.',
  'empty-pages.handbook.header': 'Håndbok',
  'empty-pages.importedProducts.button': 'Importer produkter',
  'empty-pages.importedProducts.description':
    'Her kan du laste opp varelister med pris som du har fått av din leverandør. Trykk på knappen for å begynne og fullfør så opplastningsguiden. Når du har importert din første produktliste vil alle produktene vises i en oversikt.',
  'empty-pages.importedProducts.header': 'Importerte produkter',
  'empty-pages.internalDeviations.button': 'Opprett internt avvik',
  'empty-pages.internalDeviations.description':
    'Kom i gang med å opprette et internt avvik ved å trykke på knappen under. Deretter vil du få en oversikt over alle avvik som er registrert i ditt selskap.',
  'empty-pages.internalDeviations.header': 'Interne avvik',
  'empty-pages.products.button': 'Opprett produkt',
  'empty-pages.products.description':
    'Her vil du se en oversikt over opprettede produkter når det er blitt lagt til produkter i ditt selskap. Trykk på knappen under for å opprette ditt første produkt. Hvis du ønsker å importere produkter fra en leverandør, gå til fanen «Importerte».',
  'empty-pages.products.header': 'Produkter',
  'empty-pages.projects.button': 'Opprett prosjekt',
  'empty-pages.projects.description':
    'Velkommen til prosjektsiden. Her vil du få en oversikt med nyttig informasjon og funksjoner tilknyttet dine prosjekter. Et prosjekt må ha en kunde, og denne kan opprettes under "Kunder" i navigasjonen til venstre. Hvis du vil komme i gang, kan du opprette et prosjekt med den inkluderte testkunden.',
  'empty-pages.projects.header': 'Prosjekter',
  'empty-pages.timesheets.button': 'Registrer timer',
  'empty-pages.timesheets.description':
    'Registrer timene dine her ved å benytte knappen nedenfor. Du må ha et prosjekt for å kunne registrere timer. Når du har registrert timer på et prosjekt vil timene dine vises i en oversikt.',
  'empty-pages.timesheets.header': 'Timelister',
  'errors.defaults.does-not-exist': 'Objektet eksisterer ikke',
  'errors.defaults.foreign-key-constraint':
    'Objektet refererer til noe som ikke eksisterer',
  'errors.defaults.invalid-value': 'En av de oppgitte verdiene var ugyldig',
  'errors.defaults.not-permitted':
    'Brukeren din har ikke tilgang til å gjøre dette',
  'errors.defaults.unique-constraint': 'Objektet må være unikt.',
  'errors.image-upload': 'Kunne ikke laste opp bilde',
  'errors.invalid': 'Ugyldig',
  'errors.invalid-color': 'Ugyldig farge',
  'errors.invalid-date': 'Ugyldig dato',
  'errors.invalid-email-address': 'Ugyldig epost-adresse',
  'errors.invalid-number-must-be-positive': 'Må være et positivt tall',
  'errors.invalid-phone-number': 'Ugyldig telefonnummer',
  'errors.name-exists': 'Navnet finnes allerede',
  'errors.name-must-be-unique': 'Navnet må være unikt',
  'errors.not-permitted-to-view': 'Du har ikke tillatelse til å se dette',
  'errors.pages.401': 'Du er ikke logget inn',
  'errors.pages.403': 'Du har ikke tilgang til å se denne siden',
  'errors.pages.404': 'Side ikke funnet',
  'errors.server-error': 'Serverfeil',
  'errors.something-went-wrong': 'Noe gikk galt',
  'errors.title-must-be-unique': 'Tittelen må være unik',
  'file-upload.error-message-no-file-input': 'Ingen filer er valgt.',
  'files.check-files-help':
    'Klikk på fil-ikonene for å velge mange filer på en gang',
  'files.delete-file': 'Slett fil',
  'files.delete-folder': 'Slett mappe',
  'files.edit-file-name': 'Endre filnavn',
  'files.edit-folder': 'Redigér mappe',
  'files.errors.create-folder': 'Kunne ikke opprette mappe',
  'files.errors.delete-file': 'Kunne ikke slette fil',
  'files.errors.delete-folder': 'Kunne ikke slette mappe',
  'files.errors.folder-does-not-exist': 'Mappen eksisterer ikke',
  'files.errors.move-file': 'Kunne ikke flytte filen',
  'files.errors.move-folder': 'Kunne ikke flytte mappe',
  'files.errors.patch-file': 'Kunne ikke oppdatere fildetaljer',
  'files.errors.patch-folder': 'Kunne ikke endre mappe',
  'files.errors.query-root-folders': 'Kunne ikke laste rotmapper',
  'files.errors.upload-files': 'Kunne ikke laste opp filer',
  'files.file-chosen-success': 'Fil: {fileName}',
  'files.file-size': 'Filstørrelse',
  'files.file-type': 'Filtype',
  'files.file-types.archive': 'Arkiv',
  'files.file-types.audio': 'Lyd',
  'files.file-types.csv': 'CSV',
  'files.file-types.excel': 'Excel-dokument',
  'files.file-types.image': 'Bilde',
  'files.file-types.other': 'Annen',
  'files.file-types.pdf': 'PDF',
  'files.file-types.powerpoint': 'PowerPoint-presentasjon',
  'files.file-types.video': 'Video',
  'files.file-types.word': 'Word-dokument',
  'files.file-upload-error-toast': 'Filen "{fileName}" ble ikke lastet opp.',
  'files.folder-empty': 'Mappen er tom',
  'files.folder-name': 'Mappenavn',
  'files.loading-recent-files': 'Laster siste filer',
  'files.loading-recent-files-error': 'Kunne ikke laste siste filer',
  'files.move-file': 'Flytt fil',
  'files.move-file-choose-folder':
    'Velg hvilken mappe du ønsker å flytte "{name}" til',
  'files.move-folder': 'Flytt mappe',
  'files.multiple-files-upload-error-toast': 'Filene ble ikke lastet opp.',
  'files.multiple-files-upload-success-toast': 'Filene ble lastet opp.',
  'files.n-files': '{n, plural, one {1 fil} other {{n} filer}}',
  'files.n-files-selected':
    '{n, plural, one {# fil valgt} other {# filer valgt}}',
  'files.n-folders': '{n, plural, one {1 mappe} other {{n} mapper}}',
  'files.new-folder': 'Ny mappe',
  'files.number-of-files': 'Antall filer',
  'files.number-of-folders': 'Antall mapper',
  'files.original-name': 'Originalt navn',
  'files.prompts-delete-multiple-files-text':
    'Er du sikker på at du ønsker å slette disse filene?',
  'files.prompts.create-folder': 'Gi mappen et navn',
  'files.prompts.create-folder-title': 'Opprett mappe',
  'files.prompts.delete-file':
    'Er du sikker på at du ønsker å slette filen "{name}"?',
  'files.prompts.delete-file-title': 'Slett fil',
  'files.prompts.delete-folder':
    'Er du sikker på at du vil slette mappen "{name}"? Alt innhold vil også bli slettet.',
  'files.prompts.delete-folder-title': 'Slette mappe',
  'files.prompts.delete-multiple-files-title': 'Slett filer',
  'files.prompts.patch-folder': 'Angi nytt mappenavn',
  'files.prompts.patch-folder-title': 'Endre mappe',
  'files.recent': 'Siste filer',
  'files.recent-file-uploaded-at': 'Lagt til for {when}',
  'files.recent-files': 'Siste filer',
  'files.rename-folder': 'Endre mappenavn',
  'files.search': 'Søk i filer',
  'files.search-for-files': 'Søk etter filer...',
  'files.search-in-folder': 'Søk i mappe',
  'files.success.create-folder': 'Mappe opprettet',
  'files.success.delete-file': 'Filen ble slettet',
  'files.success.delete-folder': 'Mappe slettet',
  'files.success.move-file': 'Filen ble flyttet',
  'files.success.move-folder': 'Mappen ble flyttet',
  'files.success.patch-file': 'Fildetaljer oppdatert',
  'files.success.patch-folder': 'Mappe endret',
  'files.success.upload-files':
    '{n, plural, one {1 fil lastet opp} other {{n} filer lastet opp}}',
  'files.up-one-level': 'Opp et nivå',
  'files.upload': 'Last opp',
  'files.upload-file': 'Last opp fil',
  'files.upload-files': 'Last opp filer',
  'files.upload-files-modal-hint':
    'PS: Du kan også slippe filer direkte på fil-tabellen',
  'forgotten-password.back': 'Tilbake til innlogging',
  'forgotten-password.change-password-description':
    'Av sikkerhetsgrunner må du bytte passordet ditt før du logger inn på nytt.',
  'forgotten-password.email-error':
    'Kunne ikke sende epost. Kontakt din administrator for å tilbakestille passordet.',
  'forgotten-password.error': 'Fant ikke brukernavnet i databasen',
  'forgotten-password.incorrect-password': 'Feil passord',
  'forgotten-password.password-reset': 'Passord tilbakestilt',
  'forgotten-password.reset': 'Tilbakestill passord',
  'forgotten-password.success':
    'Hvis det finnes en bruker tilknyttet denne epost-adressen, er passordet blitt tilbakestilt. Vennligst sjekk innboksen din og følg instruksjonene.',
  'forgotten-password.title': 'Glemt passord',
  'forum.lastPosts': 'Siste kommenterte innlegg',
  'forum.lastThreads': 'Siste tråder',
  'forum.new': 'Ny tråd',
  'handbook.create-entry': 'Nytt innlegg',
  'handbook.create-folder': 'Ny mappe',
  'handbook.create-sub-folder': 'Opprett undermappe',
  'handbook.edit-entry': 'Rediger innlegg',
  'handbook.edit-folder': 'Rediger mappe',
  'handbook.edit-sub-folder': 'Rediger undermappe',
  'handbook.entries': 'Innlegg',
  'handbook.errors.create-entry': 'Kunne ikke opprette innlegg',
  'handbook.errors.delete-attachment': 'Kunne ikke slette vedlegg',
  'handbook.errors.entry-not-found': 'Innlegg ikke funnet',
  'handbook.errors.folder-delete': 'Feil ved sletting av mappe',
  'handbook.errors.folder-not-found': 'Mappen ble ikke funnet',
  'handbook.errors.folder-shift': 'Kunne ikke endre rekkefølge på mapper',
  'handbook.errors.patch-entry': 'Kunne ikke endre innlegg',
  'handbook.folders': 'Mapper',
  'handbook.n-entries': '{n, plural, one {1 innlegg} other {{n} innlegg}}',
  'handbook.n-sub-folders':
    '{n, plural, one {1 undermappe} other {{n} undermapper}}',
  'handbook.new-entry': 'Nytt innlegg',
  'handbook.no-content': 'Intet innhold',
  'handbook.no-folders': 'Ingen mapper',
  'handbook.prompts.delete-entry-attachment':
    'Er du sikker på at du ønsker å slette dette vedlegget?',
  'handbook.prompts.delete-entry-attachment-title': 'Slett vedlegg',
  'handbook.prompts.delete-entry-prompt':
    'Er du helt sikker på at du ønsker å slette dette innlegget?',
  'handbook.prompts.delete-entry-title': 'Slett innlegg',
  'handbook.prompts.delete-file-prompt':
    'Er du helt sikker på at du ønsker å slette filen "{fileName}"?',
  'handbook.prompts.delete-file-title': 'Slett fil',
  'handbook.prompts.delete-folder-prompt':
    'Er du helt sikker på at du ønsker å slette denne mappen? Alt innhold vil også bli slettet.',
  'handbook.prompts.delete-folder-title': 'Slett mappe',
  'handbook.search-for-content': 'Søk etter innhold',
  'handbook.search-for-folders': 'Søk etter mapper',
  'handbook.toasts.entry-created': 'Innlegg opprettet',
  'handbook.toasts.entry-deleted': 'Innlegget ble slettet',
  'handbook.toasts.entry-updated': 'Innlegget oppdatert',
  'handbook.toasts.folder-created': 'Mappen ble opprettet',
  'handbook.toasts.folder-deleted': 'Mappen ble slettet',
  'handbook.toasts.folder-updated': 'Mappen ble endret',
  'handbook.updated-when': 'Oppdatert for {when}',
  'issues.cost-estimation': 'Estimer kostnaden',
  'issues.damage-description-long':
    'Hva har blitt skadet — Fyll inn både direkte og indirekte objekter',
  'issues.issue-description-about': 'Om avviket',
  'issues.solution-suggestion-about': 'Forslag til tiltak',
  'issues.success-creation': 'Opprettet nytt avvik',
  'login.all-rights-reserved-part-1': 'Digitech er en programvare levert av ',
  'login.all-rights-reserved-part-2': ' Alle rettigheter reservert.',
  'login.disclaimer':
    'Dette er en test-side. Om du ser etter den offisielle nettsiden så finnes den på <a>app.digitech.no</a>.',
  'login.forgot-your-password': 'Har du glemt passordet?',
  'login.logging-out': 'Logger ut',
  'login.password': 'Passord',
  'login.register-company-and-user': 'Registrer firma og bruker',
  'login.stay-logged-in': 'Forbli pålogget',
  'login.submit': 'Logg inn',
  'login.title': 'Logg inn',
  'login.username': 'Brukernavn',
  'login.username-email': 'Brukernavn/e-post',
  'login.wrong-email-password': 'Feil e-post/passord',
  'offers.contact-person-email-missing': 'Kunden mangler kontaktperson-epost',
  'offers.contribution-margin': 'DB-materiell',
  'offers.contribution-percentage': 'Påslag prosentsats',
  'offers.cost': 'Pris',
  'offers.create': 'Opprett tilbud',
  'offers.created-offers': 'Opprettede tilbud',
  'offers.customer-email': 'Kundens epost',
  'offers.edit-offer': 'Endre tilbud',
  'offers.errors.could-not-send':
    'Kunne ikke sende tilbudet via epost - har kunden en gyldig epost-adresse?',
  'offers.errors.send-offer': 'Kunne ikke sende tilbud via epost',
  'offers.excluding-vat': 'eks. mva.',
  'offers.installation-equipment-cost': 'Kostpris montasjemateriell',
  'offers.installation-time': 'Monteringstimer',
  'offers.offer': 'Tilbud',
  'offers.offer-sent': 'Tilbudet har blitt sendt til',
  'offers.offers': 'Tilbud',
  'offers.prompts.delete-offer':
    'Er du sikker på at du vil slette dette tilbudet?',
  'offers.prompts.delete-offer-title': 'Slett tilbud',
  'offers.recipient': 'Mottaker',
  'offers.save-and-set-status-to-created':
    'Lagre og sett tilbudsstatus til "opprettet"',
  'offers.search': 'Søk i tilbud',
  'offers.search-for-offer': 'Søk etter tilbud...',
  'offers.sent-and-created-offers': 'Sendte og opprettede tilbud',
  'offers.sent-offers': 'Sendte tilbud',
  'offers.status.accepted': 'Godkjent',
  'offers.status.created': 'Opprettet',
  'offers.status.declined': 'Avslått',
  'offers.status.finished': 'Fullført',
  'offers.status.sent': 'Sendt',
  'offers.success.offer-was-sent': 'Tilbudet ble sendt via epost',
  'offers.toasts.create-offer-success': 'Tilbudet ble opprettet',
  'offers.toasts.delete-offer-success': 'Tilbudet ble slettet',
  'offers.toasts.edit-offer-success': 'Tilbudet ble oppdatert',
  'offers.total-cost': 'Totalpris tilbud',
  'permissions.no-access-to-page':
    'Brukeren din har ikke tilgang til denne siden',
  'products.article-number': 'Artikkelnummer',
  'products.cost-price': 'Kostpris',
  'products.create-product': 'Opprett produkt',
  'products.create-product-list': 'Opprett produktliste',
  'products.edit-product': 'Endre produkt',
  'products.errors.fields-missing':
    'Kunne ikke importere produktliste ettersom disse kolonnene mangler: {fields}',
  'products.errors.import-product-list': 'Kunne ikke importere produktlisten',
  'products.import-products': 'Importer produkter',
  'products.imported': 'Importerte',
  'products.imported-products': 'Importerte produkter',
  'products.manual': 'Manuelle',
  'products.manually-created-products': 'Manuelt opprettede produkter',
  'products.merchant': 'Leverandør',
  'products.name-of-merchant': 'Navn på leverandør',
  'products.name-of-product': 'Navn på produkt',
  'products.price': 'Utsalgspris',
  'products.product': 'Produkt',
  'products.product-image': 'Produktbilde',
  'products.product-list-imported': 'Produktlisten ble importert',
  'products.prompts.delete-product':
    'Er du sikker på at du ønsker å slette produktet?',
  'products.prompts.delete-product-title': 'Slett produkt',
  'products.search-for-products': 'Søk etter produkter...',
  'products.toasts.create-success': 'Produktet ble opprettet',
  'products.toasts.patch-success': 'Produktet ble oppdatert',
  'products.toasts.product-deleted-success': 'Produktet ble slettet',
  'products.unit': 'Enhet',
  'products.units.litre': 'Liter',
  'products.units.meter': 'Meter',
  'products.units.parcel': 'Pakke',
  'products.units.pcs': 'Stykk',
  'products.units.set': 'Sett',
  'products.units.square-meters': 'Kvadratmeter',
  'projects.address': 'Prosjektaddresse',
  'projects.billing-type': 'Faktureringstype',
  'projects.billing-type.fixed': 'Anbud',
  'projects.billing-type.hourly': 'Regning',
  'projects.billing-type.internal': 'Intern',
  'projects.create': 'Opprett prosjekt',
  'projects.create-project': 'Opprett prosjekt',
  'projects.create-project-with-test-customer':
    'Opprett prosjekt med testkunde',
  'projects.create-success': 'Opprettet prosjekt',
  'projects.creation-of-projects': 'Opprettelse av prosjekter',
  'projects.delete-error': 'Prosjektet kunne ikke bli slettet.',
  'projects.delete-success': 'Prosjektet ble slettet.',
  'projects.deleting-project': 'Slette prosjekt',
  'projects.edit-project': 'Endre prosjekt',
  'projects.edit-success': 'Prosjektinformasjonen ble endret.',
  'projects.edit-title': 'Rediger prosjekt',
  'projects.errors.edit': 'Prosjektinformasjonen kunne ikke endres',
  'projects.estimated-completion-time': 'Estimert timebruk',
  'projects.hourly-rate': 'Timepris',
  'projects.hours-spent': 'Timeforbruk',
  'projects.material-discount': 'Rabatt på materiell',
  'projects.name': 'Prosjektnavn',
  'projects.no-files': 'Ingen filer',
  'projects.no-folders': 'Ingen mapper',
  'projects.no-more-projects': 'Ingen flere prosjekter',
  'projects.no-project': 'Intet prosjekt',
  'projects.no-projects': 'Ingen prosjekter',
  'projects.no-projects-matching-search-query':
    'Ingen prosjekter treffer søkekriteriet',
  'projects.page-description':
    'Velkommen til prosjektsiden. Her vil du få en tabell med nyttig informasjon og funksjoner tilknyttet dine prosjekter. Et prosjekt må ha en kunde, og denne kan opprettes under "Kunder" i navigasjonen til venstre. Hvis du vil komme i gang, kan du opprette et prosjekt med den inkluderte testkunden.',
  'projects.project-id': 'Prosjekt ID',
  'projects.project-leader': 'Prosjektleder',
  'projects.prompts.delete':
    'Er du sikker på at du ønsker å slette dette prosjektet? Det kan bli gjenopprettet senere av en administrator.',
  'projects.prompts.delete-not-permitted':
    'Du har ikke tilgang til å slette dette prosjektet',
  'projects.prompts.delete-title': 'Slett prosjekt',
  'projects.prompts.edit-not-permitted':
    'Du har ikke tilgang til å redigére dette prosjektet',
  'projects.search': 'Søk etter prosjekter...',
  'projects.select-billing-type': 'Velg faktureringstype',
  'projects.select-project': 'Velg prosjekt',
  'projects.stage-billing': 'Fakturering',
  'projects.stage-finished': 'Fullført',
  'projects.stage-ongoing': 'Pågående',
  'projects.stage-planning': 'Planlegging',
  'projects.status': 'Status',
  'projects.toasts.create-error': 'Kunne ikke opprette prosjekt',
  'projects.toasts.create-success': 'Prosjektet ble opprettet',
  'projects.your-projects': 'Dine prosjekter',
  'select-company.select': 'Velg firma',
  'server.general-error-try-again-later':
    'Feil på tjeneren. Vennligst prøv igjen senere.',
  'server.general-error-update':
    'Feil på tjeneren. Vennligst prøv å laste inn siden på nytt.',
  'timesheets.absence': 'Fravær',
  'timesheets.absence-illness': 'Sykdom',
  'timesheets.absence-paid-leave': 'Betalt fravær',
  'timesheets.absence-self-declaration': 'Egenmelding',
  'timesheets.absence-sick-leave': 'Sykemelding',
  'timesheets.absence-timebank-withdrawal': 'Uttak timebank',
  'timesheets.absence-type': 'Fraværstype',
  'timesheets.absence-type-illness-self-declaraion': 'Sykdom med selverklæring',
  'timesheets.absence-type-illness-sick-leave': 'Sykemelding',
  'timesheets.absence-type-paid-leave': 'Betalt fravær',
  'timesheets.absence-type-timebank-withdrawal': 'Uttak timebank',
  'timesheets.absence-type-unpaid-leave': 'Ubetalt fravær',
  'timesheets.absence-type-vacation': 'Ferie',
  'timesheets.absence-unpaid-leave': 'Permisjon uten lønn',
  'timesheets.absence-vacation': 'Ferie',
  'timesheets.create-time-entry-success': 'Timer har blitt registrert',
  'timesheets.date-mode-month-year': 'Måned/år',
  'timesheets.delete-absence-prompt-text':
    'Er du sikker på at du vil slette dette fraværet?',
  'timesheets.edit-absence': 'Redigér fravær',
  'timesheets.edit-absence-for-name': 'Redigér fravær for {name}',
  'timesheets.edit-time-entry': 'Redigér timer',
  'timesheets.errors.absence-does-not-exist': 'Fraværet eksisterer ikke',
  'timesheets.errors.delete-absence': 'Kunne ikke slette fravær',
  'timesheets.errors.delete-time-entry': 'Kunne ikke slette timer',
  'timesheets.errors.hours-range': 'Må være mer enn 0 og maks 24',
  'timesheets.errors.send-email':
    'Kunne ikke sende timelisten via email - har du oppgitt en gyldig epost-adresse?',
  'timesheets.errors.time-entry-does-not-exist':
    'Timeoppføringen eksisterer ikke',
  'timesheets.hour': 'Time',
  'timesheets.hours': 'Timer',
  'timesheets.hours-ordinary': 'Vanlig',
  'timesheets.hours-overtime-fifty': 'Overtid 50',
  'timesheets.hours-overtime-hundred': 'Overtid 100',
  'timesheets.hours-time-bank-fifty': 'Timebank 50',
  'timesheets.hours-time-bank-hundred': 'Timebank 100',
  'timesheets.hours-time-bank-ordinary': 'Timebank vanlig',
  'timesheets.kilometers-driven': 'Kilometer kjørt',
  'timesheets.kilometers-driven-alt': 'KM kjørt',
  'timesheets.no-entries': 'Ingen oppføringer',
  'timesheets.number-of-hours': 'Antall timer',
  'timesheets.open': 'Åpne timer',
  'timesheets.ordinary-hours': 'Vanlige timer',
  'timesheets.prompts.delete-absence-title': 'Slett fravær',
  'timesheets.prompts.delete-time-entry':
    'Er du sikker på at du vil slette denne timeoppføringen?',
  'timesheets.prompts.delete-time-entry-title': 'Slett timeoppføring',
  'timesheets.prompts.include-absences': 'Ønsker du å inkludere fravær?',
  'timesheets.prompts.include-absences-title': 'Skriv ut timelister',
  'timesheets.prompts.send-email': 'Angi mottakerens epost-adresse',
  'timesheets.prompts.send-email-title': 'Send timeliste på epost',
  'timesheets.register-absence': 'Registér fravær',
  'timesheets.register-absence-alt': '+ fravær',
  'timesheets.register-absence-for-name': 'Registrer fravær for {name}',
  'timesheets.register-hours': 'Registrér timer',
  'timesheets.register-time-entry': 'Registrér timer',
  'timesheets.register-time-entry-alt': '+ timer',
  'timesheets.registered-by': 'Registrert av',
  'timesheets.registered-hours': 'Registrerte timer',
  'timesheets.retrieve-hours': 'Hent timelister',
  'timesheets.self-declaration-types.personal-illness': 'Personlig sykdom',
  'timesheets.self-declaration-types.second-party-illness':
    'Sykdom hos barn/barnepasser',
  'timesheets.show-absence': 'Vis fravær',
  'timesheets.show-spent-hours': 'Vis timeforbruk',
  'timesheets.spent-hours': 'Timeforbruk',
  'timesheets.success.delete-absence': 'Fraværet ble slettet',
  'timesheets.success.delete-time-entry': 'Timene ble slettet',
  'timesheets.success.send-email': 'Timelisten ble sent via epost',
  'timesheets.this-week': 'Denne uken',
  'timesheets.time-bank-balance': 'Timebank saldo',
  'timesheets.time-bank-ordinary-shorthand': 'Timebank ord.',
  'timesheets.time-entry-type': 'Timetype',
  'timesheets.toasts.create-absence-error':
    'En ukjent feil oppsto mens fraværet ble registrert',
  'timesheets.toasts.create-absence-success': 'Fravær har blitt registrert',
  'timesheets.toasts.edit-absence-error':
    'En ukjent feil oppsto mens fraværet ble oppdatert',
  'timesheets.toasts.edit-absence-success': 'Fraværet ble endret',
  'timesheets.toasts.edit-time-entry-success': 'Timeoppføringen ble endret',
  'timesheets.toasts.no-delete-absence-permission':
    'Du har ikke tilgang til å slette dette fraværet',
  'timesheets.toasts.no-delete-time-entry-permission':
    'Du har ikke tilgang til å slette denne timeoppføringen',
  'timesheets.toasts.no-edit-absence-permission':
    'Du har ikke tilgang til å redigere dette fraværet',
  'timesheets.toasts.no-edit-time-entry-permission':
    'Du har ikke tilgang til å redigere denne timeoppføringen',
  'timesheets.total-n-hours':
    '{n, plural, one {<span>Totalt:</span> 1 time} other {<span>Totalt:</span> {n} timer}}',
  'timesheets.visible-columns': 'Synlige kolonner',
  'timesheets.withdrawn': 'Trekk',
  'timsheets.time-bank': 'Timebank',
  'toasts.error': 'Feil',
  'toasts.info': 'Info',
  'toasts.success': 'Suksess',
  'toasts.warning': 'Advarsel',
  'user-guide.introduction': 'Introduksjon',
  'users.add-user': 'Legg til bruker',
  'users.add-user-type': 'Legg til brukertype',
  'users.administrator-warning':
    'Denne brukertypen er en administrator, og har derfor alle tilganger automatisk. Endringer gjort her vil ikke ha noen effekt.',
  'users.all-permissions': 'Alle tilganger',
  'users.all-permissions-help-text':
    'Denne brukertypen vil regnes som en administrator, og vil få alle tilganger uavhengig av valgte tilganger i brukertype-oversikten',
  'users.change-password': 'Endre passord',
  'users.change-user-type': 'Endre brukertype',
  'users.city': 'Poststed',
  'users.create-success': 'Opprettet bruker',
  'users.create-user': 'Opprett bruker',
  'users.create-user-type': 'Opprett brukertype',
  'users.current-password': 'Nåværende passord',
  'users.delete-error': 'Brukeren kunne ikke bli slettet.',
  'users.delete-success': 'Brukeren ble slettet.',
  'users.edit-name': 'Rediger {firstName}',
  'users.edit-user': 'Redigér bruker',
  'users.edit-user-profile': 'Redigér brukerprofil',
  'users.edit-user-type': 'Rediger brukertype',
  'users.errors.change-password':
    'Kunne ikke endre passord, vennligst prøv igjen',
  'users.errors.change-password-check-current':
    'Det nåværende passordet du oppga var feil',
  'users.errors.change-password-weak': 'Passordet er for svakt',
  'users.errors.create-user': 'Kunne ikke opprette bruker',
  'users.errors.edit-user':
    'Kunne ikke endre brukerinformasjon, vennligst prøv igjen',
  'users.errors.email-must-be-unique': 'E-postadressen er allerede i bruk',
  'users.errors.email-taken': 'Epost-adresse opptatt',
  'users.errors.emergency-contact-name-exists':
    'Du har allerede en pårørende med dette navnet.',
  'users.errors.not-your-user': 'Du kan kun redigere din egen bruker',
  'users.is-active': 'Aktiv',
  'users.is-staff': 'Ansatt',
  'users.is-superuser': 'Superbruker',
  'users.last-login': 'Sist innlogget',
  'users.needs-password-change': 'Må endre passord',
  'users.new': 'Ny bruker',
  'users.new-password': 'Nytt passord',
  'users.password': 'Passord',
  'users.password-change-required':
    'Passordet ditt har blitt tilbakestilt, vennligst angi et nytt et.',
  'users.password-confirm': 'Bekreft passord',
  'users.password-incorrect': 'Feil passord',
  'users.passwords-do-not-match': 'Passordene er ulike',
  'users.postal-code': 'Postnummer',
  'users.primary-contact': 'Primærkontakt',
  'users.prompt.delete-user':
    'Er du sikker på at du ønsker å slette denne brukeren? Den kan bli gjenopprettet av en administrator.',
  'users.prompt.delete-user-title': 'Slett bruker',
  'users.prompts.delete-emergency-contact':
    'Er du sikker på at du ønsker å slette den pårørende?',
  'users.prompts.delete-emergency-contact-title': 'Slett pårørende',
  'users.prompts.remove-user-type': 'Fjern brukertype',
  'users.prompts.remove-user-type-text':
    'Er du sikker på at du ønsker å fjerne brukertypen "{name}" fra denne brukeren?',
  'users.register': 'Registrér',
  'users.register-message':
    'Vennligst fyll inn følgende informasjon for å aktivere brukeren din.',
  'users.register-user': 'Registrer bruker',
  'users.registered': 'Registrert',
  'users.relations.child': 'Barn',
  'users.relations.friend': 'Venn',
  'users.relations.other': 'Annet',
  'users.relations.parent': 'Forelder',
  'users.relations.partner': 'Partner',
  'users.relations.select-relation': 'Velg relasjon...',
  'users.relations.sibling': 'Søsken',
  'users.relations.spouse': 'Ektefelle',
  'users.remember': 'Husk meg',
  'users.search': 'Søk etter bruker',
  'users.select-a-user': 'Velg en bruker',
  'users.select-a-user-type': 'Velg en brukertype',
  'users.select-user': 'Velg bruker',
  'users.select-user-type': 'Velg brukertype',
  'users.select-user-types': 'Velg brukertyper',
  'users.settings.change-user-setting-error':
    'Noe gikk galt mens vi oppdaterte brukerinnstillingen',
  'users.special-permissions': 'Spesialtilganger',
  'users.special-permissions.users.project_leader': 'Kan være prosjektleder',
  'users.to-my-profile': 'Til min profil',
  'users.toasts.cannot-delete-emergency-contact':
    'Du har ikke tilgang til å slette denne pårørende',
  'users.toasts.cannot-edit-emergency-contact':
    'Du har ikke tilgang til å redigere denne pårørende',
  'users.toasts.change-password-success': 'Passord endret',
  'users.toasts.create-emergency-contact-error':
    'Kunne ikke opprette pårørende',
  'users.toasts.create-emergency-contact-success':
    'Den pårørende ble opprettet',
  'users.toasts.create-user-success': 'Bruker opprettet',
  'users.toasts.delete-emergency-contact-error':
    'Kunne ikke slette den pårørende',
  'users.toasts.delete-emergency-contact-success': 'Den pårørende ble slettet',
  'users.toasts.edit-emergency-contact-error': 'Kunne ikke endre pårørende',
  'users.toasts.edit-emergency-contact-success': 'Den pårørende ble endret',
  'users.toasts.edit-user-success': 'Brukerinformasjon endret',
  'users.toasts.error-changing-primary-contact':
    'Kunne ikke endre primærkontakt',
  'users.toasts.primary-contact-changed': 'Primærkontakten ble endret',
  'users.toasts.user-type-create-error': 'Kunne ikke opprette brukertype',
  'users.toasts.user-type-create-success': 'Brukertype opprettet',
  'users.toasts.user-type-update-error': 'Kunne ikke endre brukertype',
  'users.toasts.user-type-update-success': 'Brukertype endret',
  'users.user': 'Bruker',
  'users.user-not-registered':
    'Denne brukeren har ikke ennå registrert profilen sin',
  'users.user-type': 'Brukertype',
  'users.user-type-administrator-help-text':
    'En brukertype med administrator-flagget har implisitt alle tilganger.',
  'users.user-types': 'Brukertyper',
  'users.username': 'Brukernavn',
}
export default nb

import { DeltaStatic, Sources } from 'quill'
import React from 'react'
import BaseReactQuill, { UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

interface WrapperProps {
  renderer: boolean
}
const Wrapper = styled.div<WrapperProps>`
  div.quill {
    div.ql-toolbar {
      border-color: ${props => props.theme.colors.gray5};
      border-top-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
      border-top-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }
    div.ql-container {
      border-color: ${props => props.theme.colors.gray5};
      border-bottom-left-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
      border-bottom-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};

      div.ql-editor {
        padding: ${props => props.renderer && '0'};
        min-height: 100px;

        font-size: 1rem;
      }
    }
  }
`

const ReactQuill =
  BaseReactQuill as unknown as React.FC<BaseReactQuill.ReactQuillProps>

interface QuillProps {
  className?: string
  id?: string

  defaultValue?: BaseReactQuill.Value
  renderer?: boolean

  onChange?: (delta: DeltaStatic) => void
}

export const Quill: React.FC<QuillProps> = ({
  className,
  id,

  defaultValue,
  renderer = false,

  onChange,
}) => {
  function handleChange(
    _: string,
    _1: DeltaStatic,
    _2: Sources,
    editor: UnprivilegedEditor
  ) {
    onChange?.(editor.getContents())
  }

  return (
    <Wrapper className={className} renderer={renderer}>
      <ReactQuill
        id={id}
        defaultValue={defaultValue}
        readOnly={renderer}
        theme={renderer ? 'bubble' : 'snow'}
        onChange={handleChange}
      />
    </Wrapper>
  )
}

import { Icon, PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { FormField, Input, RegularModal as BaseRegularModal } from 'components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { toLocaleStringFixed } from 'util/formatting'
import { validateEmail, validateNonEmpty } from 'util/validation'
import { Offer } from '../types.graphql'

const RegularModal = styled(BaseRegularModal)`
  border: 0rem;

  div.content {
    padding: 0 0 1rem 0;
  }

  ${props => props.theme.media.desktop} {
    & > header.modal-header {
      & > header.modal-header {
        padding: 3rem 2.5rem 1rem;

        i.close {
          top: 2rem;
          right: 2rem;
        }
      }
    }
  }
  ${props => props.theme.media.mobile} {
    div.content {
      padding: 2.5rem 0 0 0;
    }
    div.buttons {
      padding-top: 0;
    }
  }
`

const InputSection = styled.section`
  padding: 0rem 2.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.gray2};
`

const Section = styled(InputSection)`
  border-bottom: 1px solid ${props => props.theme.colors.gray5};

  & > div {
    span.title {
      font-weight: 600;
    }
    span.blue {
      font-weight: 600;
      color: ${props => props.theme.colors.primary};
    }
  }

  p.description {
    margin: 0 0 2rem 0;
    white-space: pre-wrap;
  }
  ${props => props.theme.media.desktop} {
    & > div {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
    }
  }
  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;

    padding: 0 1rem;

    & > div {
      padding-bottom: 1rem;

      span.title {
        display: block;
        padding-bottom: 0.5rem;
      }
    }
  }
`
interface ConfirmedProps {
  error: boolean
}
const Confirmed = styled.div<ConfirmedProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  margin: 0 2.5rem 1rem;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1px solid
    ${props =>
      !props.error ? props.theme.colors.matteGreen : props.theme.colors.danger};

  font-weight: 600;

  span.confirm {
    color: ${props =>
      !props.error ? props.theme.colors.matteGreen : props.theme.colors.danger};
  }
  a {
    color: currentColor;
    text-decoration: none;
  }
  ${props => props.theme.media.desktop} {
    height: 300px;
  }
  ${props => props.theme.media.mobile} {
    margin: -1rem 1rem 1rem;
    padding: 3rem 0;
  }
`

interface SendOfferModalProps {
  offer: Offer
  onEdit: () => void
  onSend: (recipient: string) => Promise<boolean>
  onSubmit: PromptResolve<null>
}
export const SendOfferModal: React.FC<SendOfferModalProps> = ({
  offer,
  onEdit,
  onSend,
  onSubmit,
}) => {
  const translations = useTranslate({
    close: 'common.close',
    edit: 'common.edit',
    save: 'common.save',
    send: 'common.send-to-email',

    hours: ['common.n-hours', { n: '0' }],
    recipient: 'offers.recipient',
    hourlyRate: 'projects.hourly-rate',
    installationTime: 'offers.installation-time',
    installationEquipmentCost: 'offers.installation-equipment-cost',
    emailMissing: 'offers.contact-person-email-missing',

    contributionMargin: 'offers.contribution-margin',
    totalCost: 'offers.total-cost',
    excludingVAT: 'offers.excluding-vat',

    results: {
      offerSent: 'offers.offer-sent',

      error: 'errors.something-went-wrong',
      couldNotSend: 'offers.errors.send-offer',
    },

    validation: {
      invalidEmail: 'errors.invalid-email-address',
      required: 'common.required',
    },
  })

  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
  } = useForm<{ recipient: string }>({
    values: {
      recipient: offer.project.customer.contactPersonEmail ?? '',
    },
    validators: {
      recipient: [
        validateNonEmpty(translations.validation.required),
        validateEmail(translations.validation.invalidEmail),
      ],
    },
  })

  async function handleSend() {
    setLoading(true)

    const success = await onSend(form.recipient)
      .catch(() => {
        setError(true)
        return false
      })
      .finally(() => setLoading(false))

    setSent(success)
  }

  return (
    <RegularModal
      title={offer.title}
      width="500px"
      overflowY="auto"
      cancelOnClickOutside
      loading={loading}
      cancelText={translations.edit}
      submitText={!sent ? translations.send : translations.close}
      cancelDisabled={loading}
      submitDisabled={loading || !formValid}
      onClose={() => onSubmit(null)}
      onCancel={!sent ? onEdit : undefined}
      onSubmit={!sent ? handleSend : () => onSubmit(null)}
    >
      {!sent && !error ? (
        <>
          <Section>
            <p className="description">{offer.description}</p>

            <div>
              <span className="title">{translations.hourlyRate}</span>

              <span>{'NOK ' + toLocaleStringFixed(offer.hourlyRate)}</span>
            </div>

            <div>
              <span className="title">{translations.installationTime}</span>

              <span>
                {translations.hours({
                  n: toLocaleStringFixed(offer.installationTime),
                })}
              </span>
            </div>

            <div>
              <span className="title">
                {translations.installationEquipmentCost}
              </span>

              <span>
                {'NOK ' + toLocaleStringFixed(offer.installationEquipmentCost)}
              </span>
            </div>
          </Section>

          <Section>
            <div>
              <span className="title">{translations.contributionMargin}</span>

              <span className="blue">
                {'NOK ' +
                  toLocaleStringFixed(offer.contributionMargin) +
                  ' ' +
                  translations.excludingVAT}
              </span>
            </div>
          </Section>

          <Section>
            <div>
              <span className="title">{translations.totalCost}</span>

              <span className="blue">
                {'NOK ' +
                  toLocaleStringFixed(offer.totalCost) +
                  ' ' +
                  translations.excludingVAT}
              </span>
            </div>
          </Section>

          <InputSection>
            <FormField required error={!!errors.recipient}>
              <label>{translations.recipient}</label>

              <Input
                value={form.recipient}
                error={errors.recipient}
                fullWidth
                onChange={handler('recipient')}
              />
            </FormField>
          </InputSection>
        </>
      ) : (
        <Confirmed error={error}>
          <span className="confirm">
            {!error
              ? translations.results.offerSent
              : translations.results.error}
          </span>

          {!error && !!form.recipient ? (
            <a href={`mailto:${form.recipient}`}>{form.recipient}</a>
          ) : (
            <span>{translations.results.couldNotSend}</span>
          )}

          <Icon
            icon={!error ? 'check-circle' : 'times-circle'}
            type="light"
            color={!error ? 'matteGreen' : 'danger'}
            size="3rem"
            margin="1rem 0 0 0"
          />
        </Confirmed>
      )}
    </RegularModal>
  )
}

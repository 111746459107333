import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { useForm, useTranslate } from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import { LogoIconOnly } from 'assets/images/LogoIconOnly'
import { Button, FormField as BaseFormField, Input, Message } from 'components'
import { RESET_PASSWORD_MUTATION } from 'modules/users/mutations'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { validateNonEmpty } from 'util/validation'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  height: 100%;
`
const FormField = styled(BaseFormField)`
  & + div {
    margin-top: 1.5rem;

    ${props => props.theme.media.mobile} {
      margin-top: 2rem;
    }
  }
`
const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  margin-top: 1rem;
  margin-bottom: 3rem;

  ${props => props.theme.media.mobile} {
    align-items: center;
    margin-bottom: 2rem;
    font-size: 0.8rem;

    label {
      white-space: nowrap;
    }

    a {
      text-align: right;
    }
  }
`
const Layout = styled.div`
  display: grid;
  grid-template-columns: 640px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.media.mobile} {
    display: block;
  }
`
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5.5rem;

  div.message > div {
    grid-template-columns: auto 1fr;
  }

  ${props => props.theme.media.mobile} {
    padding: 1.1rem;
    padding-top: 2.2rem;
  }
`
const Logo = styled.img`
  width: 60%;
`

interface HeaderProps {
  hasMessage: boolean
}

const Header = styled.h1<HeaderProps>`
  margin: ${props => (props.hasMessage ? ' 3rem 0 2rem' : '3rem 0')};

  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 2rem;
  }
`
const RightPanel = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
`
const bgPan = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const LogoWrapper = styled.div`
  animation: ${bgPan} 420s linear infinite;
  position: absolute;
  left: -1800px;
  top: -1900px;

  svg {
    width: 3500px;
  }
`

type Status = 'success' | 'failed' | 'error' | 'idle'

export const ForgottenPassword: React.FC = () => {
  const translations = useTranslate({
    back: 'forgotten-password.back',
    reset: 'forgotten-password.reset',

    forgottenPassword: 'forgotten-password.title',
    email: 'common.email',
    required: 'common.required',

    results: {
      emailError: 'forgotten-password.email-error',
      generalError: 'server.general-error-try-again-later',
      success: 'forgotten-password.success',

      passwordResetTitle: 'forgotten-password.password-reset',
      errorTitle: 'errors.something-went-wrong',
    },
  })

  const [status, setStatus] = useState<Status>('idle')

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    submitHandler,
  } = useForm({
    values: {
      username: '',
    },
    validators: {
      username: validateNonEmpty(translations.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const renderStatus = useMemo(() => {
    const iconProps = (
      icon: FontAwesomeIcon = 'times',
      color: string = 'danger'
    ) => ({
      icon,
      color,
      margin: '0 1rem 0 0',
    })

    switch (status) {
      case 'idle':
        return null
      case 'success':
        return (
          <div className="message">
            <Message
              show
              text={translations.results.success}
              iconProps={iconProps('check', 'success')}
            />
          </div>
        )
      case 'failed':
        return (
          <div className="message">
            <Message.Error
              show
              text={translations.results.emailError}
              iconProps={iconProps()}
            />
          </div>
        )
      case 'error':
        return (
          <div className="message">
            <Message.Error
              show
              text={translations.results.generalError}
              iconProps={iconProps()}
            />
          </div>
        )
    }
  }, [
    status,
    translations.results.emailError,
    translations.results.generalError,
    translations.results.success,
  ])

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: {
      username: form.username,
    },
    onCompleted(data) {
      const { ok, reason } = data.resetPassword
      const status: Status = !ok
        ? reason === 'EMAIL_SENDING_FAILED'
          ? 'failed'
          : 'error'
        : 'success'

      mixpanel.track('Reset Password', {
        Status: status,
      })
      setStatus(status)
    },
    onError() {
      setStatus('error')
    },
  })

  function handleKeyDown({ key }: React.KeyboardEvent) {
    if (key === 'Enter') handleSubmit()
  }

  function handleSubmit() {
    resetPassword()
  }

  return (
    <Wrapper>
      <Layout>
        <LeftPanel>
          <Logo src={logo} />
          <Header hasMessage={!!renderStatus}>
            {status === 'idle'
              ? translations.forgottenPassword
              : status === 'success'
              ? translations.results.passwordResetTitle
              : translations.results.errorTitle}
          </Header>

          {renderStatus ?? (
            <FormField error={!!errors.username}>
              <label>{translations.email}</label>
              <Input
                name="email"
                inputMode="email"
                value={form.username}
                error={errors.username}
                disabled={loading}
                autoFocus
                fullWidth
                onKeyDown={handleKeyDown}
                onChange={handler('username')}
              />
            </FormField>
          )}

          <FormFooter>
            <Link to="/login">{translations.back}</Link>
          </FormFooter>

          <Button
            fullWidth
            disabled={!formValid || status !== 'idle'}
            loading={loading}
            onClick={submitHandler(handleSubmit)}
          >
            {translations.reset}
          </Button>
        </LeftPanel>

        {!isMobileOnly && (
          <RightPanel>
            <LogoWrapper>
              <LogoIconOnly />
            </LogoWrapper>
          </RightPanel>
        )}
      </Layout>
    </Wrapper>
  )
}

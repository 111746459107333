/* Environment */

export const STAGE = isLocal() ? 'local' : process.env.REACT_APP_STAGE

const API_URLS = {
  local: 'http://localhost:8000',
  development: 'https://api-digitech-dev.ur-internal.com',
  staging: 'https://api-digitech-staging.ur-internal.com',
  production: 'https://api.digitech.no',
}

export const API_URL = API_URLS[STAGE]

export function isLocal() {
  return process.env.NODE_ENV !== 'production'
}

/* Keys */

export const MAPBOX_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk.eyJ1IjoidG9ybW9kaCIsImEiOiJjazM0MGtvemIwdm03M2xwOTNsMGQ4ZXV1In0.cm4S-qMVaz2siwlP2R7QOA'
    : 'pk.eyJ1IjoidG9ybW9kaCIsImEiOiJjazFxcGV2dnAxYWF1M25wazA2aDByMXRyIn0.bLTGUrujW6pPFpHcX_er4w'

export const GOOGLE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyB2OAqGkxjlFV_gYoBJsYGvhDcXNfD-M0I'
    : 'AIzaSyB2OAqGkxjlFV_gYoBJsYGvhDcXNfD-M0I'

/* Util */

export function getCompanyShortName() {
  const companyMatch = window.location.pathname.match(/^\/c\/([\w-]+)/)
  const companyShortName = companyMatch?.pop()

  return companyShortName ?? null
}

export function createBackendUrl(path: string) {
  const companyShortName = getCompanyShortName()

  let url = `${API_URL}${path}`
  if (!!companyShortName) {
    url += url.includes('?') ? '&' : '?'
    url += `digitech_company=${companyShortName}`
  }

  return url
}

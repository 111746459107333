import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { getLoginToken } from 'util/auth'
import { API_URL } from 'util/env'

const NO_SUCH_COMPANY = 'This user is not a member of this company'
const NO_USER_IN_REQUEST = 'No user was attached to the request'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLoginToken()

  const companyMatch = window.location.pathname.match(/^\/c\/([\w-]+)/)
  const companyShortName = companyMatch?.pop()
  const companyHeader = !!companyShortName
    ? { 'X-Digitech-Company': companyShortName }
    : {}

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...companyHeader,
    },
  }
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    'bodyText' in networkError &&
    networkError.statusCode === 403
  ) {
    const { bodyText } = networkError
    switch (bodyText) {
      case NO_SUCH_COMPANY:
        window.location.href = '/'
        return
      case NO_USER_IN_REQUEST:
        window.location.href = '/login'
        return
      default:
        window.location.href = '/'
        return
    }
  }

  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message))
})

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
})

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    uploadLink as unknown as ApolloLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allAbsences: relayStylePagination(),
          allChecklists: relayStylePagination(),
          allChecklistTemplates: relayStylePagination(),
          allCustomers: relayStylePagination(),
          allDeviations: relayStylePagination(),
          allInternalDeviations: relayStylePagination(),
          allOffers: relayStylePagination(),
          allProducts: relayStylePagination(),
          allProjects: relayStylePagination(),
          allProjectsSelect: relayStylePagination(),
          allRootManualFolders: relayStylePagination(),
          allTimeEntries: relayStylePagination(),
          allUsers: relayStylePagination(),
          dashboardProjectsTable: relayStylePagination(),
        },
      },
    },
  }),
})

export default client

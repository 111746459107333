import { gql } from '@apollo/client'

// ManualFolders

export const CREATE_MANUAL_FOLDER_MUTATION = gql`
  mutation CreateManualFolder($input: CreateManualFolderInput!) {
    createManualFolder(input: $input) {
      manualFolder {
        id
      }
    }
  }
`

export const PATCH_MANUAL_FOLDER_MUTATION = gql`
  mutation PatchManualFolder($id: ID!, $input: PatchManualFolderInput!) {
    patchManualFolder(id: $id, input: $input) {
      manualFolder {
        id
      }
    }
  }
`

export const DELETE_MANUAL_FOLDER_MUTATION = gql`
  mutation DeleteManualFolder($id: ID!) {
    deleteManualFolder(id: $id) {
      found
      deletedId
    }
  }
`

export const SHIFT_MANUAL_FOLDER_INDEX_MUTATION = gql`
  mutation ShiftManualFolderIndex($id: ID!, $delta: Int!) {
    shiftManualFolderIndex(folder: $id, delta: $delta) {
      manualFolder {
        id
      }
    }
  }
`

// ManualEntries

export const CREATE_MANUAL_ENTRY_MUTATION = gql`
  mutation CreateManualEntry($input: CreateManualEntryInput!) {
    createManualEntry(input: $input) {
      manualEntry {
        id
      }
    }
  }
`

export const PATCH_MANUAL_ENTRY_MUTATION = gql`
  mutation PatchManualEntry($id: ID!, $input: PatchManualEntryInput!) {
    patchManualEntry(id: $id, input: $input) {
      manualEntry {
        id
        slug
      }
    }
  }
`

export const DELETE_MANUAL_ENTRY_MUTATION = gql`
  mutation DeleteManualEntry($id: ID!) {
    deleteManualEntry(id: $id) {
      found
      deletedId
    }
  }
`

export const SHIFT_MANUAL_ENTRY_INDEX_MUTATION = gql`
  mutation ShiftManualEntryIndex($id: ID!, $delta: Int!) {
    shiftManualEntryIndex(entry: $id, delta: $delta) {
      manualEntry {
        id
      }
    }
  }
`

// ManualEntryAttachments

export const DELETE_MANUAL_ENTRY_ATTACHMENT_MUTATION = gql`
  mutation DeleteManualEntryAttachment($id: ID!) {
    deleteManualEntryAttachment(id: $id) {
      found
      deletedId
    }
  }
`

import { Icon } from '@ur/react-components'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { isDesktop } from 'react-device-detect'
import { useConfirm } from 'util/hooks'
import { useTranslate } from '@ur/react-hooks'

interface WrapperProps {
  size: number
  full: boolean
  clickable: boolean
  noPicture: boolean
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;

  display: inline-block;
  width: ${props => (props.full ? 'auto' : `${props.size}px`)};
  min-width: 25%;
  height: ${props => props.size}px;
  overflow: hidden;

  border-radius: ${props => (props.full ? '2px' : '50%')};
  box-shadow: ${props => !props.full && '0 4px 15px rgba(0, 0, 0, 0.05)'};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  img {
    width: ${props => props.full && 'auto'};
    max-width: 100%;
    transition: opacity 0.1s linear;

    object-fit: cover;
  }

  div.remove {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 0.3rem;

    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.1s linear;

    &:hover i {
      color: ${props => props.theme.colors.error};
    }
  }

  &::before {
    content: '\f03e';

    position: absolute;
    top: calc(50% + 2px);
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);

    color: ${props => props.theme.colors.gray3};
    font-family: 'Font Awesome 5 Pro';
    font-size: ${props => `calc(${props.size}px/2)`};
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.noPicture
      ? css`
          border: 1px dashed ${props => props.theme.colors.gray4};

          &::before {
            opacity: 1;
            color: ${props => props.theme.colors.gray4};
            transition: color 0.1s linear, opacity 0.1s linear;
          }
          &:hover::before {
            color: ${props => props.theme.colors.gray3};
          }
        `
      : css`
          &:hover {
            img {
              opacity: 0.3;
            }
            div.remove {
              opacity: 1;
            }
            &::before {
              opacity: 1;
            }
          }
        `};
`

interface ProfilePictureProps {
  src: File | string | null
  alt: string
  size?: number
  /** Display the entire, rectangular image. "size" will be used as height */
  full?: boolean

  onRemove?: () => void
  onRequestChange?: () => void
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  src,
  alt,
  size = 100,
  full = false,

  onRemove,
  onRequestChange,
}) => {
  const translations = useTranslate({
    change: 'common.change',
    remove: 'common.remove-image',
  })

  const confirm = useConfirm({
    confirmText: translations.change,
    denyText: translations.remove,

    denyColor: 'white',
    denyBackground: 'error',

    promptOptions: {
      modalProps: {
        placement: 'bottom-center',
        direction: 'from-bottom',
        shadeOpacity: 0.3,
        closeOnClickOutside: true,
      },
    },
  })

  const [parsedSrc, setParsedSrc] = useState<string | null>(null)

  useEffect(() => {
    if (!(src instanceof File)) setParsedSrc(src)
    else {
      const reader = new FileReader()
      reader.onload = evt => {
        const result = evt.target?.result
        if (!result) return

        setParsedSrc(result.toString())
      }
      reader.readAsDataURL(src)
    }
  }, [src])

  function handleRemove(evt: React.MouseEvent<HTMLDivElement>) {
    evt.stopPropagation()
    onRemove?.()
  }
  async function handleClick() {
    if (isDesktop) {
      onRequestChange?.()
      return
    }

    try {
      const answer = parsedSrc === null ? true : await confirm(null)
      answer ? onRequestChange?.() : onRemove?.()
    } catch {
      // The user closed the prompt by clicking outside modal
    }
  }

  return (
    <Wrapper
      size={size}
      full={full}
      clickable={typeof onRequestChange !== 'undefined'}
      noPicture={parsedSrc === null}
      onClick={handleClick}
    >
      {!!parsedSrc ? (
        <>
          <img src={parsedSrc} height="100%" width="100%" alt={alt} />

          {isDesktop && typeof onRemove !== 'undefined' && (
            <div className="remove" onClick={handleRemove}>
              <Icon icon="times" color="white" />
            </div>
          )}
        </>
      ) : null}
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { Message, PageHeader } from 'components'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import {
  CREATE_CHECKLIST_TEMPLATE_ITEM_MUTATION,
  CREATE_CHECKLIST_TEMPLATE_MUTATION,
  DELETE_CHECKLIST_TEMPLATE_ITEM_MUTATION,
  PATCH_CHECKLIST_TEMPLATE_ITEM_MUTATION,
  PATCH_CHECKLIST_TEMPLATE_MUTATION,
  SHIFT_CHECKLIST_TEMPLATE_ITEM_INDEX_MUTATION,
} from '../mutations'
import { CHECKLIST_TEMPLATE } from '../queries'
import {
  ChecklistTemplate as ChecklistTemplateType,
  ChecklistTemplateQuery,
  CreateChecklistTemplateItemMutation,
  CreateChecklistTemplateItemMutationVariables,
  CreateChecklistTemplateMutation,
  CreateChecklistTemplateMutationVariables,
  DeleteChecklistTemplateItemMutation,
  PatchChecklistTemplateItemMutation,
  PatchChecklistTemplateItemMutationVariables,
  PatchChecklistTemplateMutation,
  PatchChecklistTemplateMutationVariables,
  ShiftChecklistTemplateItemIndexMutation,
  ShiftChecklistTemplateItemIndexMutationVariables,
} from '../types.graphql'
import { isPatchChecklistTemplateMutationVariables } from '../util'
import { ChecklistTemplateForm } from './ChecklistTemplateForm'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`

interface ChecklistTemplateProps {}

export const ChecklistTemplate: React.FC<ChecklistTemplateProps> = () => {
  const translations = useTranslate({
    createTemplate: 'checklists.create-checklist-template',

    results: {
      queryError: 'server.general-error-try-again-later',

      createSuccess: 'checklists.template-created',
      editSuccess: 'checklists.template-updated',

      uniqueItemTitleConstraint:
        'checklists.errors.checklist-template-item-title-must-be-unique',
      uniqueTemplateTitleConstraint:
        'checklists.errors.checklist-template-title-must-be-unique',
    },
  })

  const { checklistTemplateId } = useParams<{ checklistTemplateId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const {
    data,
    error,
    loading: queryLoading,
  } = useQuery<ChecklistTemplateQuery, IdVariable>(CHECKLIST_TEMPLATE, {
    skip: !checklistTemplateId,
    variables: { id: checklistTemplateId },
    onCompleted({ checklistTemplate }) {
      setOverrides({
        [checklistTemplateId]: checklistTemplate.title,
      })
    },
    onError: onErrorAuto(),
  })

  const checklistTemplate = useMemo<ChecklistTemplateType | undefined>(
    () => data?.checklistTemplate,
    [data]
  )

  const [createChecklistTemplate, { loading: createLoading }] = useMutation<
    CreateChecklistTemplateMutation,
    CreateChecklistTemplateMutationVariables
  >(CREATE_CHECKLIST_TEMPLATE_MUTATION, {
    onCompleted({ createChecklistTemplate }) {
      addToast('success', translations.results.createSuccess)
      history.push(
        `/checklists/templates/${createChecklistTemplate.checklistTemplate.id}`
      )
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueTemplateTitleConstraint,
    }),
  })

  const [patchChecklistTemplate, { loading: patchLoading }] = useMutation<
    PatchChecklistTemplateMutation,
    PatchChecklistTemplateMutationVariables
  >(PATCH_CHECKLIST_TEMPLATE_MUTATION, {
    refetchQueries: ['ChecklistTemplate'],
    onCompleted() {
      addToast('success', translations.results.editSuccess)
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueTemplateTitleConstraint,
    }),
  })

  const [createTemplateItem, { loading: createItemLoading }] = useMutation<
    CreateChecklistTemplateItemMutation,
    CreateChecklistTemplateItemMutationVariables
  >(CREATE_CHECKLIST_TEMPLATE_ITEM_MUTATION, {
    refetchQueries: ['ChecklistTemplate'],
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueItemTitleConstraint,
    }),
  })

  const [patchTemplateItem, { loading: patchItemLoading }] = useMutation<
    PatchChecklistTemplateItemMutation,
    PatchChecklistTemplateItemMutationVariables
  >(PATCH_CHECKLIST_TEMPLATE_ITEM_MUTATION, {
    refetchQueries: ['ChecklistTemplate'],
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueItemTitleConstraint,
    }),
  })

  const [shiftTemplateItemIndex, { loading: shiftIndexLoading }] = useMutation<
    ShiftChecklistTemplateItemIndexMutation,
    ShiftChecklistTemplateItemIndexMutationVariables
  >(SHIFT_CHECKLIST_TEMPLATE_ITEM_INDEX_MUTATION, {
    refetchQueries: ['ChecklistTemplate'],
    onError: onErrorAuto(),
  })

  const [deleteTemplateItem, { loading: deleteItemLoading }] = useMutation<
    DeleteChecklistTemplateItemMutation,
    IdVariable
  >(DELETE_CHECKLIST_TEMPLATE_ITEM_MUTATION, {
    refetchQueries: ['ChecklistTemplate'],
    onError: onErrorAuto(),
  })

  const loading =
    queryLoading ||
    createLoading ||
    patchLoading ||
    createItemLoading ||
    patchItemLoading ||
    shiftIndexLoading ||
    deleteItemLoading

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader
          title={checklistTemplate?.title ?? translations.createTemplate}
          loading={loading}
        />
      )}

      <ChecklistTemplateForm
        template={checklistTemplate}
        loading={loading}
        onCreateItem={variables => createTemplateItem({ variables })}
        onPatchItem={variables => patchTemplateItem({ variables })}
        onShiftIndex={variables => shiftTemplateItemIndex({ variables })}
        onDeleteItem={variables => deleteTemplateItem({ variables })}
        onSubmit={variables =>
          isPatchChecklistTemplateMutationVariables(variables)
            ? patchChecklistTemplate({ variables })
            : createChecklistTemplate({ variables })
        }
      />
    </Wrapper>
  )
}

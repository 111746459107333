import { useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { PROJECT_NAME_QUERY } from 'modules/projects/queries'
import { ProjectNameQuery } from 'modules/projects/types.graphql'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { Redirect } from 'containers/Routing'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { useOnErrorAuto } from 'util/hooks'
import { DeviationForm } from './components/DeviationForm'
import { isCreateDeviationMutationVariables, useCreateDeviation } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const CreateDeviation: React.FC = () => {
  const translations = useTranslate({
    createDeviation: 'deviations.create-deviation',
    createSuccess: 'deviations.deviation-create-success-toast',
  })

  const { projectId } = useParams<{ projectId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()

  const { loading } = useQuery<ProjectNameQuery, IdVariable>(
    PROJECT_NAME_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !projectId,
      variables: {
        id: projectId,
      },
      onCompleted(data) {
        setOverrides(v => ({
          ...v,
          [projectId]: data.project.name,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const {
    createDeviation: handleCreateDeviation,
    loading: createDeviationLoading,
  } = useCreateDeviation()

  if (isMobileOnly)
    return (
      <Redirect
        to={{
          pathname: !projectId
            ? 'deviations/internal'
            : `/projects/${projectId}/deviations`,
          state: {
            create: true,
          },
        }}
      />
    )

  const isLoading = loading || createDeviationLoading

  return (
    <Wrapper>
      <PageHeader title={translations.createDeviation} loading={loading} />

      <DeviationForm
        projectId={projectId}
        loading={isLoading}
        onSubmit={variables =>
          isCreateDeviationMutationVariables(variables) &&
          handleCreateDeviation({ variables })
        }
      />
    </Wrapper>
  )
}

import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'
import { buttonUnset } from 'util/style'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  button {
    ${buttonUnset}

    padding: 8px 16px;

    border-radius: 12px;
    cursor: pointer;
    user-select: none;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.primaryHover};
    }
  }
`

interface FetchMoreLoaderProps {
  loading: boolean
  hide: boolean
  onFetchMore: () => void
}

export const FetchMoreLoader: React.FC<FetchMoreLoaderProps> = ({
  loading,
  hide,
  onFetchMore,
}) => {
  const translations = useTranslate({
    loadMore: 'common.load-more',
  })

  return hide ? null : (
    <Wrapper>
      {loading ? (
        <Loader.Dots colorPrimary="primaryHover" />
      ) : (
        <button onClick={() => onFetchMore()}>{translations.loadMore}</button>
      )}
    </Wrapper>
  )
}

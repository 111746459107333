import { Icon } from '@ur/react-components'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TableFolder } from '../graphql'

const Wrapper = styled(Link)`
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;

  i {
    margin-right: 1ch;
    transform: scale(1.2);
  }
  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`

interface FolderCellProps {
  projectId: string
  folder: Pick<TableFolder, 'id' | 'name'>

  checklistImages?: boolean
  checklist?: boolean

  onClick: (folder: Pick<TableFolder, 'id' | 'name'>) => void
}

export const FolderCell: React.FC<FolderCellProps> = ({
  projectId,
  folder,

  checklistImages = false,
  checklist = false,

  onClick,
}) => {
  return (
    <Wrapper
      to={
        checklist
          ? `/projects/${projectId}/files/checklists/${folder.id}`
          : `/projects/${projectId}/files/${folder.id}`
      }
      onClick={() => onClick(folder)}
    >
      <Icon
        type="solid"
        icon={
          checklistImages
            ? 'check-double'
            : checklist
            ? 'file-check'
            : 'folder-open'
        }
        fixedWidth
      />

      {folder.name}
    </Wrapper>
  )
}

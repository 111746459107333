import React from 'react'
import styled, { css } from 'styled-components'

interface WrapperProps {
  error: boolean
  required: boolean
  area?: string
}
const Wrapper = styled.div<WrapperProps>`
  grid-area: ${props => props.area};

  & > label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    color: ${props =>
      props.error ? props.theme.colors.error : props.theme.colors.gray2};

    ${props =>
      props.required &&
      css`
        &::after {
          content: '*';
          color: ${props.theme.colors.error};
          padding-left: 5px;
        }
      `};
  }
  &:focus-within {
    label {
      color: ${props =>
        props.error ? props.theme.colors.error : props.theme.colors.primary};
    }
  }
`

interface FormFieldProps {
  className?: string

  error?: boolean
  required?: boolean
  area?: string
}

export const FormField: React.CFC<FormFieldProps> = ({
  children,
  className,
  area,
  error = false,
  required = false,
}) => {
  return (
    <Wrapper
      className={className}
      error={error}
      required={required}
      area={area}
    >
      {children}
    </Wrapper>
  )
}

import styled from 'styled-components'

interface CardProps {
  area: 'info' | 'contact'
}
export const ProfileCard = styled.div<CardProps>`
  grid-area: ${props => props.area};
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  color: ${props => props.theme.colors.gray2};
  font-size: 14px;

  h2 {
    padding: 0.5rem 3rem 1rem;
    margin-bottom: 0;
    border-bottom: 2px solid ${props => props.theme.colors.gray6};

    font-size: 18px;
    font-weight: 400;
    color: ${props => props.theme.colors.gray1};
  }

  ${props => props.theme.media.desktop} {
    border-radius: 8px;
  }
  ${props => props.theme.media.mobile} {
    h2 {
      padding: 0.5rem 1rem 1rem;
    }
    &:last-child {
      margin-bottom: 1rem;
    }
  }
`

import { ObservableQueryFields, useMutation } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IdVariable, RelayPageInfo } from 'types/graphql'
import {
  useCompany,
  useConfirm,
  useLazyQuery,
  useOnErrorAuto,
  usePermission,
  useToast,
  useUser,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { getTimeZone } from 'util/time'
import { openPrint } from 'util/api'
import { SendOfferModal } from './components/SendOfferModal'
import {
  DELETE_OFFER_MUTATION,
  PATCH_OFFER_MUTATION,
  SEND_OFFER_EMAIL_MUTATION,
} from './mutations'
import { OFFER_QUERY } from './queries'
import {
  ChangeOfferStatusMutationVariables,
  CreateOfferMutationVariables,
  DeleteOfferMutation,
  OfferQuery,
  OffersShallowQuery,
  OffersShallowQueryVariables,
  OfferStatusType,
  PatchOfferMutation,
  PatchOfferMutationVariables,
  SendOfferEmailMutation,
  SendOfferEmailMutationVariables,
  ShallowOffer,
} from './types.graphql'
import { TableFilteringChecklistOptions, TableMenu } from 'components'

export function useOffersPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreOffers: ObservableQueryFields<
    OffersShallowQuery,
    OffersShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreOffers({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreOffers])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

interface UseOfferUtilsConfig {
  selectedStatuses?: string[]
}

export function useOfferUtils({ selectedStatuses = [] }: UseOfferUtilsConfig) {
  const translations = useTranslate({
    statuses: {
      created: 'offers.status.created',
      sent: 'offers.status.sent',
      approved: 'offers.status.accepted',
      declined: 'offers.status.declined',
      finished: 'offers.status.finished',
    },

    prompt: {
      deleteOffer: 'offers.prompts.delete-offer',
      deleteOfferTitle: 'offers.prompts.delete-offer-title',
    },

    results: {
      editSuccess: 'offers.toasts.edit-offer-success',
      deleteSuccess: 'offers.toasts.delete-offer-success',
      sendEmailError: 'offers.errors.could-not-send',
      sendEmailSuccess: 'offers.success.offer-was-sent',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const addPrompt = usePrompt()
  const confirm = useConfirm()

  const history = useHistory()
  const { shortName: companyShortName } = useCompany()
  const { id: myId } = useUser()

  const canCreateOffer = usePermission(PERMISSIONS.offers.add.offer)
  const canEditOffer = usePermission(PERMISSIONS.offers.change.offer)
  const canDeleteOffer = usePermission(PERMISSIONS.offers.delete.offer)

  const [fetch] = useLazyQuery<OfferQuery, IdVariable>(OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const [patchOfferMutation, { loading: patchLoading }] = useMutation<
    PatchOfferMutation,
    ChangeOfferStatusMutationVariables
  >(PATCH_OFFER_MUTATION, {
    refetchQueries: ['OffersShallow', 'ProjectNameAndAmount'],
    onError: onErrorAuto(),
  })

  const [sendEmail, { loading: sendEmailLoading }] = useMutation<
    SendOfferEmailMutation,
    SendOfferEmailMutationVariables
  >(SEND_OFFER_EMAIL_MUTATION, {
    onCompleted({ sendOfferEmail: { emailSent } }) {
      if (emailSent) addToast('success', translations.results.sendEmailSuccess)
      else addToast('error', translations.results.sendEmailError)
    },
    onError: onErrorAuto(translations.results.sendEmailError),
  })

  const [deleteOfferMutation, { loading: deleteLoading }] = useMutation<
    DeleteOfferMutation,
    IdVariable
  >(DELETE_OFFER_MUTATION, {
    refetchQueries: ['OffersShallow'],
    onCompleted() {
      addToast('success', translations.results.deleteSuccess)
    },
    onError: onErrorAuto(),
  })

  const handleChangeStatus = useCallback(
    (offer: Pick<ShallowOffer, 'id'>, newStatus: OfferStatusType) => {
      if (!canEditOffer) return

      patchOfferMutation({
        variables: {
          id: offer.id,
          input: {
            status: newStatus,
          },
        },
      })
    },
    [canEditOffer, patchOfferMutation]
  )

  const handlePrint = useCallback(
    async (offerId: string) => {
      let url = `/projects/offers/print-offer?company=${companyShortName}&offer_id=${offerId}&creator_id=${myId}`
      openPrint(url)
    },
    [companyShortName, myId]
  )

  const handleSendOffer = useCallback(
    async (offerId: string, projectId: string) => {
      const { data: offerData, error } = await fetch({
        variables: { id: offerId },
      })

      if (error || !offerData) return

      const sendOffer = async (recipient: string) => {
        if (!offerData || !projectId) return false

        const sent = await sendEmail({
          variables: {
            offerId,
            projectId,
            recipient,
            tz: getTimeZone(),
          },
        })

        if (!sent) return false

        const data = await patchOfferMutation({
          variables: { id: offerData.offer.id, input: { status: 'SENT' } },
        }).catch(() => false)

        return !!data
      }

      await addPrompt<null>(resolve => (
        <SendOfferModal
          offer={offerData.offer}
          onEdit={() =>
            history.push(
              `/projects/${offerData.offer.project.id}/offers/${offerData.offer.id}/edit`
            )
          }
          onSend={sendOffer}
          onSubmit={resolve}
        />
      ))
    },
    [addPrompt, fetch, history, patchOfferMutation, sendEmail]
  )

  const handleDeleteOffer = useCallback(
    async (offer: ShallowOffer) => {
      if (!canDeleteOffer) return

      const answer = await confirm(
        translations.prompt.deleteOffer,
        translations.prompt.deleteOfferTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteOfferMutation({
          variables: {
            id: offer.id,
          },
        })
      }
    },
    [
      canDeleteOffer,
      confirm,
      translations.prompt.deleteOffer,
      translations.prompt.deleteOfferTitle,
      deleteOfferMutation,
    ]
  )

  const statusFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      items: (
        [
          ['created', 'matteLilac'],
          ['sent', 'information'],
          ['approved', 'success'],
          ['declined', 'warning2'],
          ['finished', 'gray2'],
        ] as const
      ).map(([status, dotColor]) => ({
        id: status,
        text: translations.statuses[status],
        dotColor,
        initUnchecked:
          !!selectedStatuses.length && !selectedStatuses.includes(status),
      })),
    }),
    [selectedStatuses, translations.statuses]
  )

  const makeStatusMenu = useCallback(
    (offer: Pick<ShallowOffer, 'id' | 'status'>): TableMenu => ({
      items: (
        [
          ['CREATED', 'matteLilac'],
          ['SENT', 'information'],
          ['APPROVED', 'success'],
          ['DECLINED', 'warning2'],
          ['FINISHED', 'gray2'],
        ] as const
      ).map(([status, color]) => ({
        icon:
          offer.status === status
            ? { icon: 'check-square', type: 'solid' }
            : { icon: 'square', type: 'light' },
        text: translations.statuses[
          status.toLowerCase() as Lowercase<typeof status>
        ],
        color,
        onClick: () => handleChangeStatus(offer, status),
      })),
    }),
    [handleChangeStatus, translations.statuses]
  )

  return {
    patchLoading,
    sendEmailLoading,
    deleteLoading,

    canCreateOffer,
    canEditOffer,
    canDeleteOffer,

    statusFiltering,
    makeStatusMenu,

    handleChangeStatus,
    handlePrint,
    handleSendOffer,
    handleDeleteOffer,
  }
}

export function isCreateOfferMutationVariables(
  arg: CreateOfferMutationVariables | PatchOfferMutationVariables
): arg is CreateOfferMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchOfferMutationVariables(
  arg: CreateOfferMutationVariables | PatchOfferMutationVariables
): arg is PatchOfferMutationVariables {
  return arg.hasOwnProperty('id')
}

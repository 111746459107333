import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  TextArea as BaseTextArea,
  TextAreaProps as BaseTextAreaProps,
  TextAreaSubmitConfig,
} from '@ur/react-components'
import merge from 'lodash/merge'
import { useTranslate } from '@ur/react-hooks'

const StyledTextArea = styled(BaseTextArea)`
  textarea:focus {
    border-color: ${props => !!props.error && props.theme.colors.red};
  }
  .--submit-hint {
    color: ${props => props.theme.colors.gray4};
  }
`

interface TextAreaProps extends BaseTextAreaProps {}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, submitConfig, ...props }, ref) => {
    const translations = useTranslate({
      ctrlEnterToSubmit: 'common.ctrl-enter-to-submit',
    })

    const mergedSubmitConfig = useMemo<TextAreaSubmitConfig>(
      () =>
        merge(
          {
            icon: {
              icon: 'paper-plane',
              type: 'solid',
              color: 'primary',
              hoverColor: 'primaryHover',
              cursor: 'pointer',
            },
            hint: translations.ctrlEnterToSubmit,
          },
          submitConfig ?? {}
        ),
      [submitConfig, translations.ctrlEnterToSubmit]
    )

    return (
      <StyledTextArea
        ref={ref}
        borderColor={!!error ? 'error' : 'gray5'}
        submitConfig={mergedSubmitConfig}
        {...props}
      />
    )
  }
)

import { gql } from '@apollo/client'

// ManualFolders

export const ALL_ROOT_MANUAL_FOLDERS_QUERY = gql`
  query AllRootManualFolders($q: String, $first: Int, $after: String) {
    allRootManualFolders(q: $q, first: $first, after: $after) {
      edges {
        node {
          id
          index
          name
          numSubFolders
          numEntries
          updatedAt
          description
          slug
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const MANUAL_FOLDER_BY_SLUG_QUERY = gql`
  query ManualFolderBySlug($slug: String!, $parentFolder: String) {
    manualFolder: manualFolderBySlug(slug: $slug, parentFolder: $parentFolder) {
      id
      name
      path {
        id
        slug
        name
      }
      folders {
        edges {
          node {
            id
            index
            name
            numSubFolders
            numEntries
            updatedAt
            description
            slug
          }
        }
      }
      entries {
        edges {
          node {
            id
            index
            name
            slug
            description
            folder {
              id
              slug
            }
            updatedAt
            createdBy {
              id
              initials
              profilePicture
              fullName
            }
          }
        }
      }
    }
  }
`

export const MANUAL_FOLDER_ID_BY_SLUG_QUERY = gql`
  query ManualFolderIdBySlug($slug: String!) {
    manualFolder: manualFolderBySlug(slug: $slug) {
      id
    }
  }
`

export const SEARCH_MANUAL_QUERY = gql`
  query SearchManual($q: String!) {
    allManualFolders(q: $q) {
      edges {
        node {
          id
          index
          name
          numSubFolders
          numEntries
          updatedAt
          description
          slug
          path {
            id
            slug
            name
          }
        }
      }
    }
    allManualEntries(q: $q) {
      edges {
        node {
          id
          index
          name
          slug
          description
          updatedAt
          folder {
            id
            slug
          }
          path {
            id
            slug
            name
          }
          createdBy {
            id
            initials
            profilePicture
            fullName
          }
        }
      }
    }
  }
`

// ManualEntries

export const MANUAL_ENTRY_BY_SLUG_QUERY = gql`
  query ManualEntryBySlug($entrySlug: String!, $folderSlug: String!) {
    manualEntry: manualEntryBySlug(slug: $entrySlug, folderSlug: $folderSlug) {
      id
      slug

      name
      description
      richtext
      attachments {
        id
        name
        file
      }

      createdBy {
        id
        fullName
        initials
        profilePicture
      }
      updatedAt

      folder {
        id
        slug
        name
      }
    }
  }
`

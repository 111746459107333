import { Icon } from '@ur/react-components'
import { Card } from 'components'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { ChecklistItemStatus } from '.'
import { Checklist, ListChecklistItem } from '../types.graphql'
import { ChecklistItem } from './ChecklistItem'

const Wrapper = styled(Card)`
  grid-area: items;
  height: max-content;
  padding: 1rem;

  ${props => props.theme.media.mobile} {
    padding: 0;

    background-color: inherit;
    box-shadow: none;
    border-radius: 0;
  }
`
const Item = styled.div`
  display: grid;
  grid-template-columns: 2rem 2rem 1fr;
  gap: 0.5rem;
  align-items: center;

  padding: 0.5rem;

  ${props => props.theme.media.desktop} {
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};

    &:nth-child(even)  {
      background-color: #fafafa;
    }
  }
  ${props => props.theme.media.mobile} {
    grid-template-columns: 5rem 1fr;
    padding: 0;

    background-color: white;
    box-shadow: ${props => props.theme.layout.defaultCardShadow};

    & + div {
      margin-top: 0.5rem;
    }

    & > div.info {
      grid-column: 1 / -1;
    }
  }
`
const Info = styled.div.attrs({
  role: 'button',
})`
  display: grid;
  grid-template-columns: 1fr 3rem 3rem;

  cursor: pointer;

  div.name {
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 600;
  }
  div.detail {
    white-space: nowrap;

    i {
      margin-right: 6px;
    }
  }

  ${props => props.theme.media.desktop} {
    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr 3rem;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
      'name comments'
      'name images';

    padding: 0.3rem;

    div.name {
      grid-area: name;
      margin: auto 0.5rem auto 0;
      white-space: normal;
      padding: 1px;
    }

    div.detail {
      margin: 0.3em 10px 0.3em auto;
      color: ${props => props.theme.colors.gray3};
    }
  }
`
interface ChecklistItemsProps {
  checklist: Checklist
  selectedItem: ListChecklistItem | null

  onItemClick: (item: ListChecklistItem) => void
}

export const ChecklistItems: React.FC<ChecklistItemsProps> = ({
  checklist,
  selectedItem,

  onItemClick,
}) => {
  const items = useMemo(
    () => checklist.items.edges.map(edge => edge.node),
    [checklist]
  )

  return (
    <Wrapper>
      {items.map(item => (
        <Item key={item.id}>
          <ChecklistItemStatus item={item} />

          {!isMobileOnly && <ChecklistItemStatus item={item} irrelevant />}

          <Info onClick={() => onItemClick(item)}>
            <div className="name">{item.title}</div>

            <div className="detail">
              <Icon
                icon="comment-alt"
                size={isMobileOnly ? '1.2rem' : '1rem'}
                translateY={isMobileOnly ? '3px' : '1px'}
              />
              {item.comments.edges.length}
            </div>

            <div className="detail">
              <Icon
                icon="image"
                size="1.2rem"
                translateY={isMobileOnly ? '2px' : '1px'}
              />
              {item.images.edges.length}
            </div>
          </Info>

          {isMobileOnly && !!selectedItem && selectedItem.id === item.id && (
            <div className="info">
              <ChecklistItem item={selectedItem} />
            </div>
          )}
        </Item>
      ))}
    </Wrapper>
  )
}

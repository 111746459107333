import gql from 'graphql-tag'

export const PROJECT_NAME_AND_AMOUNT_QUERY = gql`
  query ProjectNameAndAmount($id: ID!) {
    project(id: $id) {
      id
      name
      totalFiles
      totalHours
      numChecklists
      numDeviations
      numOffers
    }
  }
`

export const PROJECT_NAME_QUERY = gql`
  query ProjectName($id: ID!) {
    project(id: $id) {
      id
      name
      hourlyRate
    }
  }
`

export const PROJECT_INFO_QUERY = gql`
  query ProjectInfo($id: ID!) {
    project(id: $id) {
      id
      name
      internalId
      address
      projectLeader {
        id
        fullName
      }
      createdBy {
        id
        fullName
      }
      description
      stage
      createdAt
      customer {
        id
        name
        contactPersonName
        contactPersonPhone
        contactPersonEmail
        address
        postalCode
        postalArea
        organizationNumber
      }
      billed
      billingType
      hourlyRate
      materialDiscount
      estimatedCompletionTime
    }
  }
`

export const PROJECTS_SHALLOW_QUERY = gql`
  query ProjectsShallow(
    $q: String
    $stages: [String]
    $orderBy: String
    $first: Int
    $after: String
    $projectLeader: ID
  ) {
    hasProjects
    allProjects(
      q: $q
      stages: $stages
      orderBy: $orderBy
      first: $first
      after: $after
      projectLeader: $projectLeader
    ) {
      edges {
        node {
          id
          name
          customer {
            id
            name
          }
          estimatedCompletionTime
          createdAt
          stage
          totalHours
          totalFiles
          numChecklists
          numDeviations
          numOffers
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const PROJECT_SELECT_QUERY = gql`
  query ProjectSelect(
    $q: String
    $stages: [String]
    $first: Int
    $after: String
  ) {
    allProjectsSelect: allProjects(
      q: $q
      stages: $stages
      orderBy: "name"
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name
          customer {
            id
            name
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const DASHBOARD_PROJECTS_TABLE_QUERY = gql`
  query DashboardProjects(
    $q: String
    $stages: [String]
    $orderBy: String
    $first: Int
    $after: String
  ) {
    dashboardProjectsTable(
      q: $q
      stages: $stages
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name
          customer {
            id
            name
          }
          estimatedCompletionTime
          createdAt
          stage
          totalHours
          totalFiles
          numChecklists
          numDeviations
          numOffers
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const EDIT_PROJECT_QUERY = gql`
  query EditProject($id: ID!) {
    project(id: $id) {
      id
      name
      billingType
      customer {
        id
        name
      }
      projectLeader {
        id
        fullName
      }
      description
      address
      hourlyRate
      materialDiscount
      estimatedCompletionTime
    }
  }
`

export const PROJECT_DASHBOARD_QUERY = gql`
  query ProjectDashboard(
    $offersStatusFilter: ProjectDashboardOfferStatusFilter
    $checklistsTimeFilter: ProjectDashboardTimeFilter
    $deviationsTimeFilter: ProjectDashboardTimeFilter
    $hoursTimeFilter: ProjectDashboardTimeFilter
    $offersTimeFilter: ProjectDashboardTimeFilter
    $projectsTimeFilter: ProjectDashboardTimeFilter
    $registeredElementsTimeFilter: ProjectDashboardTimeFilter
  ) {
    projectDashboard(
      offersStatusFilter: $offersStatusFilter
      checklistsTimeFilter: $checklistsTimeFilter
      deviationsTimeFilter: $deviationsTimeFilter
      hoursTimeFilter: $hoursTimeFilter
      offersTimeFilter: $offersTimeFilter
      projectsTimeFilter: $projectsTimeFilter
      registeredElementsTimeFilter: $registeredElementsTimeFilter
    ) {
      numberOfDeviations
      numberOfProjects
      numberOfOffers
      numberOfChecklists

      isProjectLeader

      totalHoursWorkedInTimePeriod

      deviationsUnderTreatment {
        id
        title
        project {
          id
          name
        }
      }
      offers {
        id
        title
        status
        project {
          id
          name

          customer {
            id
            name
          }
        }
      }
      ongoingChecklists {
        id
        title
        project {
          id
          name
        }
      }
    }
  }
`

export const HAS_PROJECTS_QUERY = gql`
  query HasProjects {
    hasProjects
  }
`

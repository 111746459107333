import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  DateRange,
  DateRangePicker as BaseDateRangePicker,
  IconButton,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { TimeEntryCard } from './TimeEntryCard'
import { TableTimeEntry } from './types.graphql'

const Wrapper = styled.div`
  div.no-entries {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    font-weight: 600;
    color: ${props => props.theme.colors.gray3};
  }
`
const Toolbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0.5rem 1rem 0;

  h2 {
    margin: 0;
    font-size: 1rem;
    color: ${props => props.theme.colors.gray3};

    span {
      color: ${props => props.theme.colors.gray1};
    }
  }

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`
const DateFilter = styled.div`
  position: relative;
`
const DateRangePicker = styled(BaseDateRangePicker)`
  position: fixed;
  left: 2vw;
  top: calc(${props => props.theme.sizes.headerHeightMobile} + 2vw);

  width: 96vw;
`

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
`

interface TimesheetMobileProps {
  entries: TableTimeEntry[]
  loading: boolean

  dateRange: DateRange
  projectFilter: TableFilteringChecklistOptions
  userFilter: TableFilteringChecklistOptions | null
  makeMenu: (timesheet: TableTimeEntry) => TableMenu
  hideProject?: boolean

  onCreateTimeEntry: () => void
  onFilterChange: (value: TableFiltering) => void
  onUpdateDateRange: (range: DateRange) => void
}

export const TimesheetMobile: React.FC<TimesheetMobileProps> = ({
  entries,
  loading,

  dateRange,
  projectFilter,
  userFilter,
  makeMenu,
  hideProject = false,

  onCreateTimeEntry,
  onFilterChange,
  onUpdateDateRange,
}) => {
  const translations = useTranslate({
    totalHours: [
      'timesheets.total-n-hours',
      { n: 0, span: (s: string) => <span>{s}</span> },
    ],
    noEntries: 'timesheets.no-entries',
  })

  const canAddTimeEntry = usePermission(PERMISSIONS.timesheets.add.timeentry)

  const [datePickerOpen, setDatePickerOpen] = useState(false)

  const totalHours = useMemo(
    () => entries.reduce((acc, cur) => acc + (cur.sum ?? 0), 0),
    [entries]
  )

  return (
    <Wrapper>
      {canAddTimeEntry && <ActionButton onClick={onCreateTimeEntry} />}

      <Toolbar>
        <h2>
          {loading ? (
            <Loader.Spinner size={28} thickness={3} />
          ) : (
            translations.totalHours({ n: totalHours })
          )}
        </h2>

        <FilterWrapper>
          <DateFilter>
            <IconButton
              icon={{ icon: 'calendar-alt', size: '1.1rem' }}
              boxSize="34px"
              onClick={() => setDatePickerOpen(true)}
            />

            <DateRangePicker
              value={dateRange}
              show={datePickerOpen}
              vertical
              noBind
              singleMonth
              showClose
              onClose={() => setDatePickerOpen(false)}
              onChange={onUpdateDateRange}
            />
          </DateFilter>

          {!!projectFilter && (
            <Filtering
              id="projectName"
              config={projectFilter}
              icon={{
                icon: 'folder-open',
                size: '1rem',
              }}
              onFilterChange={onFilterChange}
            />
          )}

          {!!userFilter && (
            <Filtering
              id="user"
              config={userFilter}
              icon={{
                icon: 'user',
                size: '1rem',
              }}
              onFilterChange={onFilterChange}
            />
          )}
        </FilterWrapper>
      </Toolbar>

      {entries.map(timesheet => (
        <TimeEntryCard
          key={timesheet.id}
          entry={timesheet}
          editDeleteMenu={makeMenu(timesheet)}
          hideProject={hideProject}
        />
      ))}

      {!entries.length && (
        <div className="no-entries">{translations.noEntries}</div>
      )}
    </Wrapper>
  )
}

import { ObservableQueryFields, useMutation } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  TableFilteringChecklistOptions,
  TableFilteringItem,
  TableMenu,
} from 'components'
import { ShallowProject } from 'modules/projects/types.graphql'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IdVariable, RelayPageInfo } from 'types/graphql'
import { useConfirm, useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CreateDeviationModal } from './mobile/CreateDeviationModal'
import { EditDeviationModal } from './mobile/EditDeviationModal'
import {
  CREATE_DEVIATION_MUTATION,
  DELETE_DEVIATION_MUTATION,
  PATCH_DEVIATION_MUTATION,
} from './mutations'
import { DeviationPromptState } from './types'
import {
  CreateDeviationMutation,
  CreateDeviationMutationVariables,
  DeviationsShallowQuery,
  DeviationsShallowQueryVariables,
  InternalDeviationsShallowQuery,
  InternalDeviationsShallowQueryVariables,
  PatchDeviationMutation,
  PatchDeviationMutationVariables,
  ShallowDeviation,
  ShallowDeviationCategory,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

export function getProjectsFiltering(
  projects: ShallowProject[]
): TableFilteringChecklistOptions {
  return {
    type: 'checklist',
    exclusive: true,
    items: projects.map(project => ({
      id: project.id,
      text: project.name,
      initUnchecked: true,
    })),
  }
}

export function getCategoriesFiltering(
  categories: ShallowDeviationCategory[]
): TableFilteringChecklistOptions {
  return {
    type: 'checklist',
    items: categories.map(category => ({
      id: category.id,
      text: category.title,
      initUnchecked: true,
    })),
  }
}

interface StatusTranslations {
  archived: string
  closed: string
  underTreatment: string
}

export function getStatusFiltering(
  translations: StatusTranslations,
  initial: (keyof StatusTranslations)[] = ['underTreatment']
): TableFilteringChecklistOptions {
  const items = (
    [
      ['underTreatment', 'warning2'],
      ['closed', 'information'],
      ['archived', 'success'],
    ] as const
  ).map<TableFilteringItem>(([status, dotColor]) => ({
    id: status,
    text: translations[status],
    dotColor,
    initUnchecked: !initial.includes(status),
  }))

  items.splice(2, 0, {
    separator: true,
  })

  return {
    type: 'checklist',
    items,
  }
}

export function useDeviationsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreEntries: ObservableQueryFields<
    DeviationsShallowQuery,
    DeviationsShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreEntries({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreEntries])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useInternalDeviationsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreEntries: ObservableQueryFields<
    InternalDeviationsShallowQuery,
    InternalDeviationsShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreEntries({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreEntries])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useCreateDeviation() {
  const translations = useTranslate({
    createSuccess: 'deviations.deviation-create-success-toast',
  })

  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const { projectId } = useParams<{ projectId?: string }>()
  const addToast = useToast()
  const addPrompt = usePrompt<DeviationPromptState>({
    closeOnResolve: false,
    initialState: {
      loading: false,
    },
  })

  const canCreateDeviation = usePermission(PERMISSIONS.deviations.add.deviation)

  const [createDeviationMutation, { loading }] = useMutation<
    CreateDeviationMutation,
    CreateDeviationMutationVariables
  >(CREATE_DEVIATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Deviation'],
    onCompleted(data) {
      addToast('success', translations.createSuccess)
      mixpanel.track('Create Deviation')
      history.push(
        !projectId
          ? `/deviations/internal/${data.createDeviation.deviation.id}`
          : `/projects/${data.createDeviation.deviation.project?.id}/deviations/${data.createDeviation.deviation.id}`
      )
    },
    onError: onErrorAuto(),
  })

  const createDeviation = useCallback(
    async (createOptions?: {
      projectId?: string
      variables?: CreateDeviationMutationVariables
    }) => {
      if (!canCreateDeviation) return null

      if (typeof createOptions?.variables === 'undefined') {
        const { data, close, update } =
          await addPrompt<CreateDeviationMutationVariables | null>(
            (resolve, _, { id }) => (
              <CreateDeviationModal
                id={id}
                projectId={createOptions?.projectId ?? projectId}
                onSubmit={resolve}
              />
            )
          )
        if (!data) {
          close()
          return
        }

        update({ loading: true })
        createDeviationMutation({ variables: data })
      } else {
        return createDeviationMutation({ variables: createOptions.variables })
      }
    },
    [addPrompt, canCreateDeviation, createDeviationMutation, projectId]
  )

  return {
    createDeviation,
    loading,
  }
}

interface UseEditDeviationOptions {
  id?: string
  afterCompleted?: () => void
}

export function useEditDeviation(options: UseEditDeviationOptions = {}) {
  const translations = useTranslate({
    editSuccess: 'deviations.deviation-edit-success-toast',
  })

  const addToast = useToast()
  const addPrompt = usePrompt<DeviationPromptState>({
    closeOnResolve: false,
    initialState: {
      loading: false,
    },
  })
  const onErrorAuto = useOnErrorAuto()

  const [patchDeviationMutation, { loading }] = useMutation<
    PatchDeviationMutation,
    PatchDeviationMutationVariables
  >(PATCH_DEVIATION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Deviation'],
    onCompleted() {
      mixpanel.track('Edit Deviation')
      addToast('success', translations.editSuccess)
      options.afterCompleted?.()
    },
    onError: onErrorAuto(),
  })

  const patchDeviation = useCallback(
    async (patchOptions?: {
      id?: string
      variables?: PatchDeviationMutationVariables
    }) => {
      const deviationId = patchOptions?.id ?? options.id
      if (!deviationId)
        throw new Error(
          'Deviation ID must be defined either by hook of function'
        )

      if (typeof patchOptions?.variables === 'undefined') {
        const { data, close, update } =
          await addPrompt<PatchDeviationMutationVariables | null>(
            (resolve, _, { id }) => (
              <EditDeviationModal
                id={id}
                deviationId={deviationId}
                onSubmit={resolve}
              />
            )
          )
        if (!data) {
          close()
          return
        }

        update({ loading: true })
        patchDeviationMutation({
          variables: {
            ...data,
            id: deviationId,
          },
        })
          .then(() => close())
          .finally(() => update({ loading: false }))
      } else {
        patchDeviationMutation({
          variables: patchOptions.variables,
        })
      }
    },
    [addPrompt, options.id, patchDeviationMutation]
  )

  return {
    patchDeviation,
    loading,
  }
}

export function useDeleteDeviation(
  refetchQueries: string[],
  afterCompleted?: () => void
) {
  const translations = useTranslate({
    delete: 'common.delete',

    deviationDeleted: 'deviations.delete-deviation-success',
    deviationDeleteError: 'deviations.prompts.could-not-delete',

    deleteDeviation: 'deviations.delete-deviation-prompt',
    deleteDeviationTitle: 'deviations.delete-deviation',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const confirm = useConfirm()

  const canDeleteDeviation = usePermission(
    PERMISSIONS.deviations.delete.deviation
  )

  const [deleteDeviationMutation, { loading }] = useMutation<never, IdVariable>(
    DELETE_DEVIATION_MUTATION,
    {
      awaitRefetchQueries: !!refetchQueries.length,
      refetchQueries,
      onCompleted() {
        addToast('success', translations.deviationDeleted)
        afterCompleted?.()
      },
      onError: onErrorAuto(translations.deviationDeleteError),
    }
  )

  const deleteDeviation = useCallback(
    async (deviationId: string) => {
      if (!canDeleteDeviation) return

      const answer = await confirm(
        translations.deleteDeviation,
        translations.deleteDeviationTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteDeviationMutation({
          variables: {
            id: deviationId,
          },
        })
      }
    },
    [
      canDeleteDeviation,
      confirm,
      deleteDeviationMutation,
      translations.deleteDeviation,
      translations.deleteDeviationTitle,
    ]
  )

  return {
    deleteDeviation,
    loading,
  }
}

export function useDeviationMenu(
  deleteDeviation: (id: string) => void,
  actions: {
    open: (deviation: ShallowDeviation) => string
    edit: (deviation: ShallowDeviation) => string | Promise<void>
  }
) {
  const translations = useTranslate({
    openDeviation: 'deviations.open-deviation',
    delete: 'common.delete',
    edit: 'common.edit',
  })

  const history = useHistory()

  const canEditDeviation = usePermission(
    PERMISSIONS.deviations.change.deviation
  )
  const canDeleteDeviation = usePermission(
    PERMISSIONS.deviations.delete.deviation
  )

  const makeMenu = useCallback(
    (deviation: ShallowDeviation): TableMenu => ({
      items: [
        {
          icon: 'external-link',
          text: translations.openDeviation,
          onClick() {
            history.push(actions.open(deviation))
          },
        },
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditDeviation,
          onClick() {
            const res = actions.edit(deviation)
            if (typeof res === 'string') history.push(res)
          },
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hide: !canDeleteDeviation,
          hoverColor: '#e38080',
          onClick() {
            deleteDeviation(deviation.id)
          },
        },
      ],
    }),
    [
      canDeleteDeviation,
      canEditDeviation,
      deleteDeviation,
      history,
      actions,
      translations.delete,
      translations.edit,
      translations.openDeviation,
    ]
  )

  return makeMenu
}

export function isCreateDeviationMutationVariables(
  arg: CreateDeviationMutationVariables | PatchDeviationMutationVariables
): arg is CreateDeviationMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchDeviationMutationVariables(
  arg: CreateDeviationMutationVariables | PatchDeviationMutationVariables
): arg is PatchDeviationMutationVariables {
  return arg.hasOwnProperty('id')
}

import { useTranslate } from '@ur/react-hooks'
import { DateRange, MonthConfig, QuickRange, Side, Week } from 'components'
import {
  addDays,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfYear,
  isMonday,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import range from 'lodash/range'
import { useMemo } from 'react'
import { useDateFns } from 'util/hooks'

export function useDateRangeQuickie(
  value: DateRange
): [QuickRange | null, Record<QuickRange, [Date, Date]>, string] {
  const translations = useTranslate({
    today: 'common.today',
    yesterday: 'common.yesterday',
    thisWeek: 'common.this-week',
    lastWeek: 'common.last-week',
    thisMonth: 'common.this-month',
    lastMonth: 'common.last-month',
    thisYear: 'common.this-year',
    lastYear: 'common.last-year',
    allTime: 'common.all-time',
  })

  const { format, startOfWeek, endOfWeek } = useDateFns()

  const [quickie, ranges] = useMemo<
    [QuickRange | null, Record<QuickRange, [Date, Date]>]
  >(() => {
    let retValue: QuickRange | null = null

    const now = new Date()
    const yesterday = addDays(now, -1)
    const [thisWeekStart, thisWeekEnd] = [startOfWeek(now), endOfWeek(now)]
    const [lastWeekStart, lastWeekEnd] = [
      addWeeks(thisWeekStart, -1),
      addWeeks(thisWeekEnd, -1),
    ]
    const [thisMonthStart, thisMonthEnd] = [startOfMonth(now), endOfMonth(now)]
    const [lastMonthStart, lastMonthEnd] = [
      startOfMonth(addDays(thisMonthStart, -10)),
      endOfMonth(addDays(thisMonthStart, -10)),
    ]
    const [thisYearStart, thisYearEnd] = [startOfYear(now), endOfYear(now)]
    const [lastYearStart, lastYearEnd] = [
      startOfYear(addDays(thisYearStart, -10)),
      endOfYear(addDays(thisYearStart, -10)),
    ]
    const [beginningOfTime, endOfTime] = [new Date(1970, 0, 1), endOfDay(now)]

    if (isSameDay(now, value.from) && isSameDay(now, value.to))
      retValue = 'today'
    else if (isSameDay(yesterday, value.from) && isSameDay(yesterday, value.to))
      retValue = 'yesterday'
    else if (
      isSameDay(value.from, thisWeekStart) &&
      isSameDay(value.to, thisWeekEnd)
    )
      retValue = 'thisWeek'
    else if (
      isSameDay(value.from, lastWeekStart) &&
      isSameDay(value.to, lastWeekEnd)
    )
      retValue = 'lastWeek'
    else if (
      isSameDay(value.from, thisMonthStart) &&
      isSameDay(value.to, thisMonthEnd)
    )
      retValue = 'thisMonth'
    else if (
      isSameDay(value.from, lastMonthStart) &&
      isSameDay(value.to, lastMonthEnd)
    )
      retValue = 'lastMonth'
    else if (
      isSameDay(value.from, thisYearStart) &&
      isSameDay(value.to, thisYearEnd)
    )
      retValue = 'thisYear'
    else if (
      isSameDay(value.from, lastYearStart) &&
      isSameDay(value.to, lastYearEnd)
    )
      retValue = 'lastYear'
    else if (
      isSameDay(value.from, beginningOfTime) &&
      isSameDay(value.to, endOfTime)
    )
      retValue = 'allTime'

    return [
      retValue,
      {
        today: [startOfDay(now), endOfDay(now)],
        yesterday: [startOfDay(yesterday), endOfDay(yesterday)],
        thisWeek: [thisWeekStart, thisWeekEnd],
        lastWeek: [lastWeekStart, lastWeekEnd],
        thisMonth: [thisMonthStart, thisMonthEnd],
        lastMonth: [lastMonthStart, lastMonthEnd],
        thisYear: [thisYearStart, thisYearEnd],
        lastYear: [lastYearStart, lastYearEnd],
        allTime: [beginningOfTime, endOfTime],
      },
    ]
  }, [endOfWeek, startOfWeek, value.from, value.to])

  const translation = useMemo(() => {
    if (!!quickie) return translations[quickie]

    if (isSameDay(value.from, value.to)) return format(value.from, 'PP')

    const from = format(value.from, 'PP')
    const to = format(value.to, 'PP')
    return `${from} - ${to}`
  }, [format, quickie, translations, value.from, value.to])

  return [quickie, ranges, translation]
}

export function getInitialMonth(value: DateRange, side: Side) {
  if (isSameMonth(value.from, value.to)) {
    if (side === 'left') return addMonths(startOfMonth(value.from), -1)
    else return startOfMonth(value.from)
  }

  if (side === 'left') return startOfMonth(value.from)
  else return startOfMonth(value.to)
}

export function useCalendar(month: Date, value: DateRange) {
  const { startOfWeek, endOfWeek, getWeek, format } = useDateFns()

  const calendar = useMemo<MonthConfig>(() => {
    const monthStart = startOfMonth(month)
    const monthEnd = endOfMonth(month)
    const startDate = startOfWeek(monthStart)
    const endDate = endOfWeek(monthEnd)

    const weeks: Week[] = []
    let weekIdx = 0

    const days = eachDayOfInterval({
      start: startDate,
      end: endDate,
    })

    for (const day of days) {
      if (weeks.length > 0 && isMonday(day)) weekIdx++

      if (typeof weeks[weekIdx] === 'undefined')
        weeks.push({
          num: getWeek(day),
          days: [],
        })

      const isCurrentMonth = isSameMonth(day, month)
      const isSelected =
        isCurrentMonth &&
        (isSameDay(day, value.from) || isSameDay(day, value.to))
      const isInRange =
        isCurrentMonth &&
        isWithinInterval(day, {
          start: value.from,
          end: value.to,
        })

      const week = weeks[weekIdx]
      week.days.push({
        date: day,
        isToday: isSameDay(day, new Date()),
        isCurrentMonth,
        isSelected,
        isInRange,
        isStartOfRange: isSameDay(day, value.from),
        isEndOfRange: isSameDay(day, value.to),
      })
    }

    const dayNames = range(7).map(num =>
      format(addDays(startDate, num), 'EEEEEE')
    )

    return {
      month: monthStart,
      dayNames,
      weeks,
    }
  }, [value.from, value.to, month, startOfWeek, endOfWeek, getWeek, format])

  return calendar
}

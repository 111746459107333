import { useTranslate } from '@ur/react-hooks'
import {
  Table,
  TableColumn,
  TableFiltering,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import omit from 'lodash/omit'
import { ShallowProject } from 'modules/projects/types.graphql'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { DeviationCategories, DeviationStatus } from '.'
import { ShallowDeviation, ShallowDeviationCategory } from '../types.graphql'
import {
  getCategoriesFiltering,
  getProjectsFiltering,
  getStatusFiltering,
} from '../util'
import { DeviationStatusType } from './DeviationStatus'

type DeviationsTableColumn =
  | 'title'
  | 'categories'
  | 'customerName'
  | 'projectName'
  | 'status'
  | 'date'

interface DeviationsTableProps {
  deviations: ShallowDeviation[]
  projects?: ShallowProject[]
  categories: ShallowDeviationCategory[]

  excludeColumns?: DeviationsTableColumn[]
  maxCategories?: number
  initialStatuses?: DeviationStatusType[]
  search: string
  makeMenu: (deviation: ShallowDeviation) => TableMenu

  onFilterChange: (value: TableFiltering) => void
  onSortingChange: (value: string) => void
}

export const DeviationsTable: React.FC<DeviationsTableProps> = ({
  deviations,
  projects,
  categories,

  excludeColumns = [],
  maxCategories = 1,
  initialStatuses = ['underTreatment'],
  search,
  makeMenu,

  onFilterChange,
  onSortingChange,
}) => {
  const translations = useTranslate({
    title: 'common.title',
    customer: 'common.customer',
    project: 'common.project',
    categories: 'deviations.categories',
    status: 'common.status',
    date: 'common.date',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    statuses: {
      archived: 'common.archived',
      closed: 'common.closed',
      underTreatment: 'common.under-treatment',
    },
  })

  const { format } = useDateFns()
  const theme = useTheme()

  const projectsFilter = useMemo(
    () => (!!projects ? getProjectsFiltering(projects) : null),
    [projects]
  )
  const categoriesFilter = useMemo(
    () => (!!categories ? getCategoriesFiltering(categories) : null),
    [categories]
  )
  const statusFilter = useMemo(
    () => getStatusFiltering(translations.statuses, initialStatuses),
    [translations.statuses, initialStatuses]
  )

  const columns = useMemo<TableColumn[]>(() => {
    const columns: TableColumn[] = [
      {
        id: 'title',
        label: translations.title,
        sortable: true,
      },
      {
        id: 'customerName',
        label: translations.customer,
        sortable: true,
      },
      {
        id: 'projectName',
        label: translations.project,
        sortable: true,
        filtering: projectsFilter ?? undefined,
      },
      {
        id: 'categories',
        label: translations.categories,
        filtering: categoriesFilter ?? undefined,
      },
      {
        id: 'status',
        label: translations.status,
        filtering: statusFilter,
      },
      {
        id: 'date',
        label: translations.date,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ]

    return columns.filter(
      ({ id }) => !excludeColumns.includes(id as DeviationsTableColumn)
    )
  }, [
    categoriesFilter,
    excludeColumns,
    projectsFilter,
    statusFilter,
    translations.categories,
    translations.customer,
    translations.date,
    translations.project,
    translations.status,
    translations.title,
  ])

  const rows = useMemo<TableRow[]>(
    () =>
      deviations.map(deviation => {
        const date = deviation.date
          ? format(new Date(deviation.date), 'PPP')
          : ''
        const project = deviation.project
        const customer = project?.customer
        const categories = deviation.categories.edges.map(edge => edge.node)

        const row: TableRow = {
          title: {
            content: (
              <Link
                to={
                  !!project
                    ? `/projects/${project.id}/deviations/${deviation.id}`
                    : `/deviations/internal/${deviation.id}`
                }
              >
                {deviation.title}
              </Link>
            ),
          },
          customerName: {
            content: !!customer ? (
              <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
            ) : (
              ''
            ),
          },
          projectName: {
            content: !!project ? (
              <Link to={`/projects/${project.id}`}>{project.name}</Link>
            ) : (
              ''
            ),
          },
          categories: {
            content: (
              <DeviationCategories
                categories={categories}
                max={maxCategories}
              />
            ),
          },
          status: {
            content: (
              <DeviationStatus
                isActive={deviation.active}
                isTreated={deviation.treated}
              />
            ),
          },
          date: { content: date },
          menu: { content: '', menu: makeMenu(deviation) },
        }

        return omit(row, excludeColumns)
      }),
    [deviations, excludeColumns, format, makeMenu, maxCategories]
  )

  const options = useMemo<TableOptions>(
    () => ({
      fillHorizontal: true,
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color: cell.columnId === 'date' ? theme.colors.gray3 : 'currentColor',
          fontWeight: cell.columnId === 'title' ? 600 : 'inherit',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      options={options}
      noRowsText={!!search ? translations.noResults : translations.noData}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
    />
  )
}

import { Icon, IconProps, Loader } from '@ur/react-components'
import { BaseProps } from '@ur/react-components/build/types/props'
import { useTranslate } from '@ur/react-hooks'
import { DetectBlur } from 'containers/DetectBlur'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CSSOverflow } from 'types/style'
import { summarizeBooleans } from 'util/helpers'
import { useScrollBlock } from 'util/hooks'
import { buttonUnset } from 'util/style'

interface WrapperProps {
  hasTitle: boolean
  overflowY?: CSSOverflow
  width?: string
  background?: string
}
const Wrapper = styled.div<WrapperProps>`
  width: ${props => props.width};
  max-height: 92vh;
  overflow-y: ${props => props.overflowY};

  background: ${props => props.background ?? 'white'};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1rem solid white;

  & > header.modal-header {
    position: relative;
    padding: 2rem 2.5rem;

    h1 {
      display: flex;

      margin: 0;
      font-size: 1.2rem;

      div.loader {
        margin-left: 6px;
      }
    }
    i.close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
  div.content {
    padding: ${props => (props.hasTitle ? '0 2.5rem 2rem' : '2.5rem 2rem')};
  }

  ${props => props.theme.media.mobile} {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;

    padding-bottom: 8rem;

    border-radius: 0;
    border: 0;
    background: ${props => props.background ?? 'white'};

    & > header.modal-header {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      background: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

      i.close {
        position: static;
      }
    }
    div.content {
      padding: ${props => (props.hasTitle ? '2rem 1rem 1rem' : '1rem')};
    }
  }
`
interface ButtonsProps {
  columns: number
}
const Buttons = styled.div<ButtonsProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: 1rem;
  padding: 0 2.5rem 2rem;

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`
const ConfirmButton = styled.button`
  ${buttonUnset}

  min-width: 8rem;
  padding: 1rem 1.2rem;

  background: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  i {
    margin-right: 6px;
  }

  &.deny {
    color: white;
    background-color: ${props => props.theme.colors.gray3};

    &:hover {
      background-color: ${props => props.theme.colors.gray3}ba;
    }
  }
  &.confirm {
    color: white;
    background-color: ${props => props.theme.colors.primary};

    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
    }
  }

  &:focus {
    text-decoration: underline;
  }

  &:disabled {
    pointer-events: none;
    touch-action: none;

    color: ${props => props.theme.colors.gray4};
    background: ${props => props.theme.colors.gray6};
  }
`

interface RegularModalProps extends BaseProps {
  title?: string | JSX.Element

  background?: string

  cancelText?: string
  submitText?: string
  cancelIcon?: IconProps
  submitIcon?: IconProps
  footerLeft?: JSX.Element

  cancelOnClickOutside?: boolean
  cancelOnEscape?: boolean
  cancelDisabled?: boolean
  submitDisabled?: boolean
  loading?: boolean

  overflowY?: CSSOverflow
  width?: string

  /** If defined, shows a close icon in top right and invokes this function on click */
  onClose?: () => void
  onCancel?: () => void
  onSubmit?: () => void
}

export const RegularModal: React.CFC<RegularModalProps> = ({
  children,
  className,
  id,

  title,
  background,
  cancelText,
  submitText,
  cancelIcon,
  submitIcon,
  footerLeft,

  cancelOnClickOutside = false,
  cancelOnEscape = false,
  cancelDisabled = false,
  submitDisabled = false,
  loading = false,

  overflowY,
  width,

  onClose,
  onCancel,
  onSubmit,
}) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    ok: 'common.ok',
  })

  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    blockScroll()
    return () => allowScroll()
  }, [allowScroll, blockScroll])

  const hasCloseButton = typeof onClose !== 'undefined'
  const hasCancelButton = typeof onCancel !== 'undefined'
  const hasSubmitButton = typeof onSubmit !== 'undefined'
  const hasFooterLeft = typeof footerLeft !== 'undefined'

  const hasButtons = hasCancelButton || hasSubmitButton
  const hasFooter = hasFooterLeft || hasButtons
  const numColumns = summarizeBooleans(
    hasCancelButton,
    hasSubmitButton,
    hasFooterLeft
  )

  const wrapButtons = (buttons: JSX.Element) =>
    !hasFooterLeft ? buttons : <div>{buttons}</div>

  return (
    <DetectBlur
      active={cancelOnClickOutside}
      detectEscape={cancelOnEscape}
      onClickOutside={() => (hasCloseButton ? onClose() : onCancel?.())}
    >
      <Wrapper
        className={className}
        id={id}
        hasTitle={!!title}
        overflowY={overflowY}
        width={width}
        background={background}
      >
        {!!title && (
          <header className="modal-header">
            <h1>
              {title}
              {loading && (
                <div className="loader">
                  <Loader.Spinner size={20} thickness={2} />
                </div>
              )}
            </h1>

            {hasCloseButton && (
              <Icon
                className="close"
                icon="times"
                type="light"
                size="2rem"
                color="gray4"
                hoverColor="error"
                cursor="pointer"
                onClick={() => onClose?.()}
              />
            )}
          </header>
        )}

        <div className="content">{children}</div>

        {hasFooter && (
          <Buttons className="buttons" columns={numColumns}>
            {footerLeft}

            {hasButtons &&
              wrapButtons(
                <>
                  {hasCancelButton && (
                    <ConfirmButton
                      className="deny"
                      disabled={cancelDisabled}
                      onClick={() => onCancel?.()}
                    >
                      {!!cancelIcon && <Icon {...cancelIcon} />}
                      {cancelText ?? translations.cancel}
                    </ConfirmButton>
                  )}

                  {hasSubmitButton && (
                    <ConfirmButton
                      className="confirm"
                      disabled={submitDisabled}
                      onClick={() => onSubmit?.()}
                    >
                      {!!submitIcon && <Icon {...submitIcon} />}
                      {submitText ?? translations.ok}
                    </ConfirmButton>
                  )}
                </>
              )}
          </Buttons>
        )}
      </Wrapper>
    </DetectBlur>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { PROJECT_NAME_QUERY } from 'modules/projects/queries'
import { ProjectNameQuery } from 'modules/projects/types.graphql'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { TimeEntryForm } from './components/TimeEntryForm'
import { CREATE_TIME_ENTRY_MUTATION } from './mutations'
import {
  CreateTimeEntryMutation,
  CreateTimeEntryMutationVariables,
} from './types.graphql'
import { isCreateTimeEntryMutationVariables } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 0 1rem 2rem;
  }
`

interface CreateTimeEntryProps {}

export const CreateTimeEntry: React.FC<CreateTimeEntryProps> = () => {
  const translations = useTranslate({
    createTimeEntry: 'timesheets.register-time-entry',
    createSuccess: 'timesheets.create-time-entry-success',
  })

  const { projectId } = useParams<{ projectId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const { loading } = useQuery<ProjectNameQuery, IdVariable>(
    PROJECT_NAME_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: projectId,
      },
      onCompleted(data) {
        setOverrides({
          [projectId]: data.project.name,
        })
      },
      onError: onErrorAuto(),
    }
  )

  const [createTimeEntry, { loading: createLoading }] = useMutation<
    CreateTimeEntryMutation,
    CreateTimeEntryMutationVariables
  >(CREATE_TIME_ENTRY_MUTATION, {
    onCompleted({ createTimeEntry: { timeEntry } }) {
      addToast('success', translations.createSuccess)
      history.push(`/projects/${timeEntry.project.id}/timesheets/`)
      mixpanel.track('Create Time Entry')
    },
    onError: onErrorAuto(),
  })

  const isLoading = loading || createLoading

  return (
    <Wrapper>
      <PageHeader title={translations.createTimeEntry} loading={loading} />

      <TimeEntryForm
        loading={isLoading}
        projectId={projectId}
        onSubmit={variables =>
          isCreateTimeEntryMutationVariables(variables) &&
          createTimeEntry({ variables })
        }
      />
    </Wrapper>
  )
}

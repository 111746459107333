import styled from 'styled-components'
import { Button } from '../../components'
import { Link } from 'react-router-dom'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default function AdminDashboard() {
  const translations = useTranslate({
    adminDashboard: 'admin.dashboard',
    createNewCompany: 'admin.dashboard.create-new-company',
  })

  return (
    <Wrapper>
      <h1>{translations.adminDashboard}</h1>
      <StyledLink to={'/admin/create-company'}>
        <Button>{translations.createNewCompany}</Button>
      </StyledLink>
    </Wrapper>
  )
}

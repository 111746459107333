import { Icon } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ShallowDeviationCategory } from '../types.graphql'
import { DeviationCategory } from './DeviationCategory'

const Wrapper = styled.div`
  position: relative;
`
const Categories = styled.div`
  display: flex;
  gap: 2px 8px;
`
const Limited = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;

  font-size: 0.7rem;
  cursor: pointer;

  span.text {
    font-weight: bold;
    color: ${props => props.theme.colors.gray3};
    margin-right: -2px;
  }
`
const Dropdown = styled.aside`
  position: absolute;
  top: calc(100% + 6px);
  left: -1rem;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 1rem;

  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  & > div {
    display: flex;
    gap: 8px;
  }
`
const Unlimited = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

interface DeviationCategoriesProps {
  categories: ShallowDeviationCategory[]
  selectedCategory?: string | null
  disabledCategories?: ShallowDeviationCategory[]
  unmarkedCategories?: ShallowDeviationCategory[]
  max?: number
  big?: boolean

  onCategoryClick?: (category: ShallowDeviationCategory) => void
}

export const DeviationCategories: React.FC<DeviationCategoriesProps> = ({
  categories,
  selectedCategory = null,
  disabledCategories = [],
  unmarkedCategories = [],
  max,
  big = false,

  onCategoryClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const translations = useTranslate({
    showAll: 'common.show-all',
  })

  const [open, setOpen] = useState(false)

  useClickOutside(wrapperRef, () => setOpen(false), true)

  function toggleOpen(evt: React.MouseEvent) {
    evt.stopPropagation()
    setOpen(v => !v)
  }

  function isDisabled(category: ShallowDeviationCategory) {
    return !!disabledCategories.some(cat => cat.id === category.id)
  }

  function isUnmarked(category: ShallowDeviationCategory) {
    return !!unmarkedCategories.some(cat => cat.id === category.id)
  }

  function handleClick(category: ShallowDeviationCategory) {
    return () => onCategoryClick?.(category)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Categories>
        {typeof max !== 'undefined' && categories.length > max ? (
          <Limited onClick={toggleOpen}>
            {categories.slice(0, max).map(category => (
              <DeviationCategory
                key={category.id}
                category={category}
                selected={selectedCategory === category.id}
                big={big}
                disabled={isDisabled(category)}
                unmarked={isUnmarked(category)}
                onClick={handleClick(category)}
              />
            ))}

            <span className="text">{translations.showAll}</span>

            <Icon
              type="solid"
              icon={open ? 'chevron-up' : 'chevron-down'}
              color="gray3"
            />
          </Limited>
        ) : (
          <Unlimited>
            {categories.map(category => (
              <DeviationCategory
                key={category.id}
                category={category}
                selected={selectedCategory === category.id}
                big={big}
                disabled={isDisabled(category)}
                unmarked={isUnmarked(category)}
                onClick={handleClick(category)}
              />
            ))}
          </Unlimited>
        )}
      </Categories>

      {open && (
        <Dropdown>
          {categories.reduce<JSX.Element[]>((acc, cur, idx, arr) => {
            if (idx % 3 !== 0) return acc

            const next = arr[idx + 1]
            const last = arr[idx + 2]

            acc.push(
              <div key={cur.id}>
                <DeviationCategory
                  key={cur.id}
                  category={cur}
                  selected={selectedCategory === cur.id}
                  big={big}
                  disabled={isDisabled(cur)}
                  unmarked={isUnmarked(cur)}
                  onClick={handleClick(cur)}
                />

                {next && (
                  <DeviationCategory
                    key={next.id}
                    category={next}
                    selected={selectedCategory === next.id}
                    big={big}
                    disabled={isDisabled(next)}
                    unmarked={isUnmarked(next)}
                    onClick={handleClick(next)}
                  />
                )}

                {last && (
                  <DeviationCategory
                    key={last.id}
                    category={last}
                    selected={selectedCategory === last.id}
                    big={big}
                    disabled={isDisabled(last)}
                    unmarked={isUnmarked(last)}
                    onClick={handleClick(last)}
                  />
                )}
              </div>
            )

            return acc
          }, [])}
        </Dropdown>
      )}
    </Wrapper>
  )
}

import React from 'react'
import styled from 'styled-components'
import { IconProps, Loader } from '@ur/react-components'
import { Button } from 'components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'

interface WrapperProps {
  largeMargin?: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${props => (props.largeMargin ? ' 1rem' : '0.5rem')};
  padding: 0 10px 1rem;

  .left {
    display: flex;

    gap: 40px;
  }

  .search {
    input {
      border-right-width: 0px;
    }
    .--input-icon-right {
      transition: border-color 0.1s linear;
    }
    &:focus-within {
      .--input-icon-right {
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`

interface PageHeaderButton {
  text: string
  icon?: FontAwesomeIcon | IconProps
  background?: string

  hide?: boolean
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void
}

interface TimesheetHeaderProps {
  loading: boolean
  buttons: PageHeaderButton[]
  datePicker?: boolean

  largeMargin?: boolean
}

export const TimesheetHeader: React.FC<TimesheetHeaderProps> = ({
  loading = false,
  datePicker = false,

  buttons,

  largeMargin = false,
}) => {
  return (
    <Wrapper largeMargin={largeMargin}>
      <div className="left">{loading && <Loader.Spinner size={24} />}</div>

      {typeof buttons !== undefined &&
        buttons.map(btn => {
          if (!!btn.hide) return null

          const icon: IconProps | undefined =
            typeof btn.icon === 'string'
              ? {
                  icon: btn.icon,
                }
              : typeof btn.icon === 'object'
              ? btn.icon
              : undefined

          return (
            <Button
              key={btn.text}
              iconRightProps={icon}
              background={btn.background ?? 'primary'}
              height="40px"
              onClick={btn.onClick}
            >
              {btn.text}
            </Button>
          )
        })}
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { QueryVariable } from 'types/graphql'
import {
  CompanyManualsMobile,
  EntriesMobile,
  ManualEntriesTable,
  ManualFoldersTable,
} from '.'
import { SEARCH_MANUAL_QUERY } from './queries'
import { SearchManualQuery } from './types.graphql'

const Wrapper = styled.div`
  ${props => props.theme.media.mobile} {
    div.no-results {
      display: flex;
      justify-content: center;
      padding: 3rem 0;
    }
  }
`
const Section = styled.section`
  h2 {
    font-weight: 600;
    font-size: 1.1rem;
  }

  ${props => props.theme.media.mobile} {
    h2 {
      margin-left: 1rem;
    }
  }
`

interface ManualSearchProps {
  query: string

  onCreateClick: () => Promise<void>
  onResetQuery: () => void
}

export const ManualSearch: React.FC<ManualSearchProps> = ({
  query,
  onCreateClick,
  onResetQuery,
}) => {
  const translations = useTranslate({
    folders: 'handbook.folders',
    entries: 'handbook.entries',
    noResults: 'common.no-results',
  })

  const { data } = useQuery<SearchManualQuery, QueryVariable>(
    SEARCH_MANUAL_QUERY,
    {
      variables: {
        q: query,
      },
    }
  )

  const folders = useMemo(
    () => data?.allManualFolders.edges.map(edge => edge.node) ?? [],
    [data]
  )
  const entries = useMemo(
    () => data?.allManualEntries.edges.map(edge => edge.node) ?? [],
    [data]
  )

  return (
    <Wrapper>
      <Section>
        {(!isMobileOnly || !!folders.length) && <h2>{translations.folders}</h2>}

        {!isMobileOnly ? (
          <ManualFoldersTable
            folders={folders}
            noShift
            showPath
            onResetSearch={onResetQuery}
          />
        ) : (
          <CompanyManualsMobile
            folders={folders}
            noShift
            showPath
            onResetSearch={onResetQuery}
            onCreateClick={() => onCreateClick()}
          />
        )}
      </Section>

      <Section>
        {(!isMobileOnly || !!entries.length) && <h2>{translations.entries}</h2>}

        {!isMobileOnly ? (
          <ManualEntriesTable
            entries={entries}
            noShift
            showPath
            onResetSearch={onResetQuery}
          />
        ) : (
          <EntriesMobile
            entries={entries}
            noShift
            showPath
            onResetSearch={onResetQuery}
            onCreateFolderClick={() => onCreateClick()}
          />
        )}
      </Section>

      {isMobileOnly && !folders.length && !entries.length && (
        <div className="no-results">{translations.noResults}</div>
      )}
    </Wrapper>
  )
}

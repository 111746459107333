import {
  DatePicker as BaseDatePicker,
  DatePickerProps as BaseDatePickerProps,
  DatePickerRef,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { useTheme } from 'styled-components'

const StyledDatePicker = styled(BaseDatePicker)``

interface DatePickerProps extends BaseDatePickerProps {}

export const DatePicker = React.forwardRef<DatePickerRef, DatePickerProps>(
  ({ ...props }, ref) => {
    const theme = useTheme()
    const translations = useTranslate({
      dateError: 'errors.invalid-date',
    })

    return (
      <StyledDatePicker
        ref={ref}
        border="1px solid"
        borderColor="gray5"
        height={isMobileOnly ? '56px' : '40px'}
        borderRadius={props.borderRadius ?? theme.sizes.defaultBorderRadius}
        dateErrorText={translations.dateError}
        closeOnDateChange
        {...props}
      />
    )
  }
)

import { useMutation, useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card, TextArea } from 'components'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useDateFns, useOnErrorAuto } from 'util/hooks'
import { ChecklistItemComments } from '.'
import { PATCH_CHECKLIST_ITEM_MUTATION } from '../mutations'
import { CHECKLIST_ITEM_QUERY } from '../queries'
import {
  ChecklistItemQuery,
  ListChecklistItem,
  PatchChecklistItemInputAddChecklistItemComments,
  PatchChecklistItemInputUpdateChecklistItemComments,
  PatchChecklistItemMutation,
  PatchChecklistItemMutationVariables,
} from '../types.graphql'
import { ChecklistItemImages } from './ChecklistItemImages'

const Wrapper = styled(Card)`
  grid-area: checklist-item;
  height: max-content;
  padding: 2rem 0 1rem;

  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 3rem 0;
  }

  ${props => props.theme.media.mobile} {
    padding: 0 0 0.5rem;
    box-shadow: none;
  }
`
const Checked = styled.div`
  padding: 0.5rem 3rem 0;

  font-size: 0.8rem;
  color: ${props => props.theme.colors.gray3};

  span {
    color: ${props => props.theme.colors.gray1};
  }

  ${props => props.theme.media.mobile} {
    padding: 0rem 0.5rem;
  }
`
interface DescriptionProps {
  editing: boolean
  noDescription: boolean
}
const Description = styled.div<DescriptionProps>`
  margin: 2rem 3rem;

  p {
    margin: 0;

    ${props =>
      props.noDescription &&
      css`
        color: ${props => props.theme.colors.gray3};
        font-style: italic;
      `};

    i {
      margin-left: 1ch;
    }
  }

  ${props => props.theme.media.mobile} {
    margin: 1rem 0.5rem;
  }
`

export interface ChecklistItemData {
  commentsAdd: PatchChecklistItemInputAddChecklistItemComments[]
  commentsUpdate: PatchChecklistItemInputUpdateChecklistItemComments[]
  commentsRemove: string[]
}

interface ChecklistItemProps {
  item: ListChecklistItem
  descriptionEditable?: boolean
}

export const ChecklistItem: React.FC<ChecklistItemProps> = ({
  item: tempItem,
  descriptionEditable = false,
}) => {
  const translations = useTranslate({
    images: 'common.images',
    comments: 'common.comments',
    save: 'common.save',

    noDescription: 'common.no-description',
    checked: [
      'checklists.checked-by-at',
      {
        a: () => <Link to="#"></Link>,
        span: () => <span></span>,
      },
    ],
    ctrlEnterToSave: 'common.ctrl-enter-to-save',

    edit: 'common.edit',
    delete: 'common.delete',
  })

  const { format } = useDateFns()
  const onErrorAuto = useOnErrorAuto()

  const [newDescription, setNewDescription] = useState<string | null>(null)

  const { data, loading: queryLoading } = useQuery<
    ChecklistItemQuery,
    IdVariable
  >(CHECKLIST_ITEM_QUERY, {
    variables: { id: tempItem.id },
    onError: onErrorAuto(),
  })

  const item = useMemo(() => data?.checklistItem ?? tempItem, [data, tempItem])

  const [patchChecklistItem, { loading: patchItemLoading }] = useMutation<
    PatchChecklistItemMutation,
    PatchChecklistItemMutationVariables
  >(PATCH_CHECKLIST_ITEM_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onError: onErrorAuto(),
  })

  function handleSubmitDescription(description: string) {
    patchChecklistItem({
      variables: {
        id: item.id,
        input: { description },
      },
    }).finally(() => setNewDescription(null))
  }

  const checked = useMemo(
    () =>
      !item.checkedBy || !item.checkedAt
        ? null
        : translations.checked({
            a: () => (
              <Link to={`/users/${item.checkedBy!.id}`}>
                {item.checkedBy!.fullName}
              </Link>
            ),
            span: () => <span>{format(new Date(item.checkedAt!), 'PPp')}</span>,
          }),
    [format, item.checkedAt, item.checkedBy, translations]
  )

  const isLoading = queryLoading || patchItemLoading

  return (
    <Wrapper>
      {!isMobileOnly && (
        <h2>
          {item.title}

          {isLoading && <Loader.Spinner size={22} />}
        </h2>
      )}

      {!!checked && <Checked>{checked}</Checked>}

      <Description
        editing={newDescription !== null}
        noDescription={!item.description}
      >
        {newDescription === null ? (
          <p>
            {item.description || translations.noDescription}

            {descriptionEditable && (
              <Icon
                icon="edit"
                color="gray3"
                hoverColor="primaryHover"
                cursor="pointer"
                onClick={() => setNewDescription(item.description || '')}
              />
            )}
          </p>
        ) : (
          <TextArea
            value={newDescription}
            height="10ex"
            autoFocus
            fullWidth
            submitConfig={{
              hide: !newDescription,
              hint: translations.ctrlEnterToSave,
            }}
            onKeyDown={evt => evt.key === 'Escape' && setNewDescription(null)}
            onSubmit={handleSubmitDescription}
            onChange={setNewDescription}
          />
        )}
      </Description>

      <ChecklistItemImages item={item} />

      <ChecklistItemComments item={item} />
    </Wrapper>
  )
}

import {
  isSameDay,
  addDays,
  addMonths,
  startOfMonth,
  startOfYear,
  getDaysInMonth,
  isWeekend,
} from 'date-fns'
import { startOfWeek } from './hooks/useDateFns/functions'
/**
 * Find which date of the year Easter Sunday falls on
 * @param year Year to check
 * @returns Date of Easter Sunday
 * @see https://no.wikipedia.org/wiki/P%C3%A5skeformelen#Juliansk_p%C3%A5skeformel
 */
function easterSunday(year: number) {
  const a = year % 19
  const b = Math.floor(year / 100)
  const c = year % 100
  const d = Math.floor(b / 4)
  const e = b % 4
  const f = Math.floor((b + 8) / 25)
  const g = Math.floor((b - f + 1) / 3)
  const h = (19 * a + b - d - g + 15) % 30
  const i = Math.floor(c / 4)
  const k = c % 4
  const l = (32 + 2 * e + 2 * i - h - k) % 7
  const m = Math.floor((a + 11 * h + 22 * l) / 451)
  const n = Math.floor((h + l - 7 * m + 114) / 31) - 1
  const p = ((h + l - 7 * m + 114) % 31) + 1

  return new Date(year, n, p)
}

function getHolidays(year: number) {
  const es = easterSunday(year)
  const special = [
    addDays(es, -3), // Maundy Thursday
    addDays(es, -2), // Long Friday
    es, // Easter Sunday
    addDays(es, 1), // Easter Monday
    addDays(es, 2), // Easter Tuesday
    addDays(es, 40), // Ascension Day
    addDays(es, 50), // Whitsun
    addDays(es, 51), // Whitsun Monday
  ]

  return [
    new Date(year, 0, 1), // New year's day
    new Date(year, 4, 1), // Labour day
    new Date(year, 4, 17), // Consitution day
    new Date(year, 11, 25), // Christmas day
    new Date(year, 11, 26), // Boxing day
    ...special,
  ]
}

/**
 * Checks whether given date is a (Norwegian) holiday
 * @param date Date to check
 * @returns Whether date is a Norwegian holiday
 */
export function isHoliday(date: Date) {
  const year = date.getFullYear()
  return getHolidays(year).some(day => isSameDay(day, date))
}

function getWorkingHoursInWeek(date: Date) {
  const monday = startOfWeek({ code: 'no' })(date)
  let counter = 0
  let numberOfWorkingDays = 0

  while (counter <= 4) {
    if (!isHoliday(addDays(monday, counter))) {
      numberOfWorkingDays += 1
    }
    counter = counter + 1
  }

  const workingHours = numberOfWorkingDays * 7.5
  return workingHours
}

function getWorkingHoursInMonth(date: Date) {
  const firstDayOfMonth = startOfMonth(date)
  const daysInMonth = getDaysInMonth(date)
  let counter = 0
  let numberOfWorkingDays = 0

  while (counter <= daysInMonth - 1) {
    if (
      !isHoliday(addDays(firstDayOfMonth, counter)) &&
      !isWeekend(addDays(firstDayOfMonth, counter))
    ) {
      numberOfWorkingDays += 1
    }
    counter = counter + 1
  }

  const workingHours = numberOfWorkingDays * 7.5
  return workingHours
}

function getWorkingHoursInNumberOfMonths(date: Date, numberOfMonths: number) {
  let monthCounter = 0
  let workingHours = 0

  while (monthCounter < numberOfMonths) {
    const month = addMonths(date, monthCounter)
    workingHours = workingHours + getWorkingHoursInMonth(month)
    monthCounter = monthCounter + 1
  }

  return workingHours
}

export function getWorkingHours(
  interval:
    | 'THIS_WEEK'
    | 'LAST_WEEK'
    | 'THIS_MONTH'
    | 'LAST_MONTH'
    | 'LAST_SIX_MONTHS'
    | 'THIS_YEAR'
    | 'ALL'
) {
  const today = new Date()

  switch (interval) {
    case 'THIS_WEEK':
      return getWorkingHoursInWeek(today)
    case 'LAST_WEEK':
      return getWorkingHoursInWeek(addDays(today, -7))
    case 'THIS_MONTH':
      return getWorkingHoursInMonth(today)
    case 'LAST_MONTH':
      return getWorkingHoursInMonth(addMonths(today, -1))
    case 'LAST_SIX_MONTHS':
      return getWorkingHoursInNumberOfMonths(addMonths(today, -5), 6)
    case 'THIS_YEAR':
      return getWorkingHoursInNumberOfMonths(startOfYear(today), 12)
    default:
      return 0
  }
}

import { Icon } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { useClickOutside } from '@ur/react-hooks'
import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { overloadColor } from 'util/style'
import { TableMenuSeparator } from '.'
import { TableMenu, TableMenuItem } from './types'

const Wrapper = styled.div`
  position: relative;

  div.cell-content {
    cursor: pointer;

    div.icon {
      padding: 0 0.5rem;
    }
  }
`
const Dropdown = styled.aside`
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;

  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};
`
interface ItemProps {
  color: string
  hoverColor: string
}
const Item = styled.div<ItemProps>`
  min-width: 210px;
  padding: 0.6rem 3rem 0.6rem 1.8rem;

  border-radius: 0 999px 999px 0;
  font-weight: 600;
  color: ${props => overloadColor(props.color)};
  cursor: pointer;

  white-space: nowrap;

  &:hover {
    color: ${props => overloadColor(props.hoverColor)};
    background-color: ${props => props.theme.colors.hover};
  }

  span.text {
    margin-left: 0.5rem;
    white-space: nowrap;
  }
`
interface SeparatorProps {
  color: string
}
const Separator = styled.div<SeparatorProps>`
  border-top: 1px solid ${props => overloadColor(props.color)};
`

function isSeparator(
  item: TableMenuItem | TableMenuSeparator
): item is TableMenuSeparator {
  return 'separator' in item && item.separator
}

interface MenuProps {
  className?: string
  icon: string | JSX.Element
  config: TableMenu
}

export const Menu: React.FC<MenuProps> = ({ className, icon, config }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  useClickOutside(
    wrapperRef,
    () => setOpen(false),
    config.closeOnEscape !== false
  )

  const hidden = useMemo(
    () => config.items.length === 0 || config.items.every(item => item.hide),
    [config.items]
  )

  if (hidden) return <div></div>

  function handleItemClick(option: TableMenuItem) {
    return (evt: React.MouseEvent<HTMLDivElement>) => {
      option.onClick(evt)
      setOpen(option.closeOnClick === false)
    }
  }

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <div className="cell-content" onClick={() => setOpen(v => !v)}>
        {typeof icon !== 'string' ? (
          icon
        ) : (
          <div className="icon">
            <Icon
              icon={(icon as FontAwesomeIcon) || 'ellipsis-v'}
              size="1.6rem"
            />
          </div>
        )}
      </div>

      {open && (
        <Dropdown>
          {config.items.map((option, idx) =>
            option.hide ? null : isSeparator(option) ? (
              <Separator key={idx} color={option.color ?? 'gray5'} />
            ) : (
              <Item
                key={option.text + '' + idx}
                color={option.color ?? 'gray2'}
                hoverColor={option.hoverColor ?? option.color ?? 'gray1'}
                onClick={handleItemClick(option)}
              >
                {!!option.icon &&
                  (typeof option.icon === 'string' ? (
                    <Icon fixedWidth type="solid" icon={option.icon} />
                  ) : (
                    <Icon {...option.icon} />
                  ))}

                <span className="text">{option.text}</span>
              </Item>
            )
          )}
        </Dropdown>
      )}
    </Wrapper>
  )
}

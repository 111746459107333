import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { CustomerPromptState } from '.'
import { CustomerForm } from './CustomerForm'
import { CreateCustomerMutationVariables } from './types.graphql'

interface CreateNewCustomerModalProps {
  id: string
  defaultHourlyRate: number
  onSubmit: PromptResolve<CreateCustomerMutationVariables | null>
}
export const CreateCustomerModal: React.FC<CreateNewCustomerModalProps> = ({
  id,
  defaultHourlyRate,
  onSubmit,
}) => {
  const translations = useTranslate({
    createCustomer: 'customers.create-customer',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const state = usePromptState<CustomerPromptState>(id)

  function handleSubmit(values: CreateCustomerMutationVariables) {
    onSubmit({
      ...values,
    })
  }

  return (
    <RegularModal
      title={translations.createCustomer}
      width="100%"
      onClose={() => onSubmit(null)}
    >
      <CustomerForm
        loading={state.loading}
        defaultHourlyRate={defaultHourlyRate}
        onSubmit={handleSubmit}
      />
    </RegularModal>
  )
}

import { Loader } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  Input,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import React from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ProductCard } from './ProductCard'
import { Product } from './types.graphql'

const Wrapper = styled.div`
  div.no-products {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`
interface ToolbarProps {
  noSearch?: boolean
}
const Toolbar = styled.div<ToolbarProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 28px;
  align-items: center;

  padding: ${props => (props.noSearch ? '0 1rem' : '0.5rem 1rem 0.5rem')};

  h1 {
    display: flex;
    gap: 0.5rem;

    font-size: 1.4rem;
    font-weight: 500;
  }

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

interface ProductsMobileProps {
  products: Product[]
  search?: string | undefined
  isImport: boolean
  loading?: boolean
  filter: TableFilteringChecklistOptions

  makeEditDeleteMenu: (productId: string) => TableMenu

  onSearchChange?: (value: string) => void
  onFilterChange: (value: TableFiltering) => void
  onCreateClick: () => void
}

export const ProductsMobile: React.FC<ProductsMobileProps> = ({
  products,
  search,
  isImport,
  loading = false,
  filter,

  makeEditDeleteMenu,

  onFilterChange,
  onSearchChange,
  onCreateClick,
}) => {
  const translations = useTranslate({
    products: 'common.products',
    search: 'products.search-for-products',
    noData: 'common.no-data',
    noResults: 'common.no-results',
  })

  const canAddProduct = usePermission(PERMISSIONS.products.add.product)
  const debouncedSearch = useDebounce(search)

  return (
    <Wrapper>
      {canAddProduct && (
        <ActionButton
          iconProps={{ icon: isImport ? 'upload' : 'plus', type: 'solid' }}
          onClick={onCreateClick}
        />
      )}

      <Toolbar noSearch={!onSearchChange}>
        {!!onSearchChange ? (
          <Input
            value={search ?? ''}
            placeholder={translations.search}
            iconRightProps={{
              icon: 'search',
              type: 'solid',
              color: 'gray4',
              size: '18px',
              translateX: '-2px',
            }}
            height="56px"
            onChange={onSearchChange}
          />
        ) : (
          <h1>
            {translations.products}
            {loading && <Loader.Spinner size={24} />}
          </h1>
        )}

        <Filtering
          id="status"
          config={filter}
          icon={{
            size: '1rem',
          }}
          onFilterChange={value => onFilterChange(value)}
        />
      </Toolbar>

      {products.map(product => (
        <ProductCard
          key={product.id}
          product={product}
          editDeleteMenu={makeEditDeleteMenu(product.id)}
        />
      ))}

      {!products.length && (
        <div className="no-products">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import { DeviationForm } from '../components/DeviationForm'
import { DEVIATION_QUERY } from '../queries'
import { DeviationPromptState } from '../types'
import {
  DeviationQuery,
  PatchDeviationMutationVariables,
} from '../types.graphql'
import { isPatchDeviationMutationVariables } from '../util'

interface EditDeviationModalProps {
  id: string
  deviationId: string
  projectId?: string
  onSubmit: PromptResolve<PatchDeviationMutationVariables | null>
}
export const EditDeviationModal: React.FC<EditDeviationModalProps> = ({
  id,
  deviationId,
  projectId,
  onSubmit,
}) => {
  const onErrorAuto = useOnErrorAuto()

  const translations = useTranslate({
    editDeviation: 'deviations.edit-deviation',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const state = usePromptState<DeviationPromptState>(id)

  const { data, loading: queryLoading } = useQuery<DeviationQuery, IdVariable>(
    DEVIATION_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { id: deviationId },
      onError: onErrorAuto(translations.results.queryError),
    }
  )

  function handleSubmit(values: PatchDeviationMutationVariables) {
    onSubmit(values)
  }

  return (
    <RegularModal
      title={translations.editDeviation}
      width="100%"
      onClose={() => onSubmit(null)}
    >
      <DeviationForm
        deviation={data?.deviation}
        projectId={projectId}
        loading={state.loading || queryLoading}
        onSubmit={variables =>
          isPatchDeviationMutationVariables(variables) &&
          handleSubmit(variables)
        }
      />
    </RegularModal>
  )
}

import { Icon } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { useLanguage } from 'locale/TranslationProvider'
import { renderLanguage } from 'modules/header/util'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { changeCompany } from 'util/auth'
import { useCompany, useUser } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { SidebarNavItem } from '.'

interface WrapperProps {
  open: boolean
}
const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${props => props.theme.sizes.headerHeight};
  left: 0;

  display: flex;
  flex-direction: column;
  width: ${props =>
    props.open
      ? props.theme.sizes.sidebarWidthExpanded
      : props.theme.sizes.sidebarWidth};

  background: ${props => props.theme.colors.body};

  ${props => props.theme.media.desktop} {
    justify-content: space-between;
    height: calc(100vh - ${props => props.theme.sizes.headerHeight});
  }
  ${props => props.theme.media.mobile} {
    position: absolute;
    z-index: 2;
    top: ${props => props.theme.sizes.headerHeightMobile};

    flex: 1;
    width: 100%;

    background: white;
  }
`
const DocumentationDivider = styled.div`
  margin: 32px 0 24px 24px;

  color: ${props => props.theme.colors.gray3};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`
const ContentWrapper = styled.div`
  ${props => props.theme.media.desktop} {
    margin-top: 45px;
  }
  ${props => props.theme.media.mobile} {
    padding-right: 10px;
    height: calc(100vh - ${props => props.theme.sizes.headerHeightMobile});
    overflow: scroll;
  }
`
const SidebarToggler = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 43px;

  border-top: 1px solid ${props => props.theme.colors.gray5};
`
const IconWrapper = styled.div`
  padding: 10px 15px;

  cursor: pointer;
`
interface SubMenuItemProps {
  active: boolean
}
const SubMenuItem = styled.div<SubMenuItemProps>`
  position: relative;
  padding: 10px 0 10px 64px;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  border: solid ${props => props.theme.colors.gray6};

  border-width: 0 0 1px 0;

  ${props =>
    props.active &&
    css`
      &::before {
        content: '\f111';
        position: absolute;
        left: 58px;
        top: 50%;
        transform: translate(-100%, -50%);

        font-family: 'Font Awesome 5 Pro';
        font-size: 0.4rem;
        font-weight: 900;
      }
    `};
`

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const translations = useTranslate({
    changeCompany: 'common.change-company',
    changePassword: 'users.change-password',
    checklists: 'common.checklists',
    collapse: 'common.sidebar-collapse',
    companySettings: 'common.company-settings',
    customers: 'common.customers',
    deviations: 'common.deviations',
    documentation: 'common.documentation',
    editProfile: 'common.edit-profile',
    expand: 'common.sidebar-expand',
    handbook: 'common.handbook',
    language: 'common.language',
    logOut: 'common.logout',
    mySite: 'common.my-site',
    products: 'common.products',
    projects: 'common.projects',
    showProfile: 'common.show-profile',
    support: 'common.support',
    timesheets: 'common.timesheets',
    userGuide: 'common.user-guide',
    userSettings: 'common.user-settings',
    users: 'common.users',
  })

  const user = useUser()
  const company = useCompany()
  const companies = useMemo(
    () => user.companies.edges.map(edge => edge.node),
    [user]
  )

  const [sidebarOpen, setSidebarOpen] = useGlobal('sidebar.open')
  const [userMenuOpen, setUserMenuOpen] = useGlobal('sidebar.userMenuOpen')
  const [language, setLanguage] = useLanguage()

  const [languageMenuOpen, setLanguageMenuOpen] = useState(false)
  const [companyMenuOpen, setCompanyMenuOpen] = useState(false)

  function closeMenus() {
    setUserMenuOpen(false)
    setSidebarOpen(false)
  }

  return (
    <Wrapper open={sidebarOpen}>
      <div>
        {isMobileOnly && userMenuOpen && (
          <ContentWrapper>
            <SidebarNavItem
              icon="external-link-alt"
              to={`/users/${user.id}`}
              text={translations.showProfile}
              iconType="solid"
              inactive
              onClick={closeMenus}
            />

            <SidebarNavItem
              icon="user-cog"
              to={`/users/${user.id}/edit`}
              state={{ tab: 'info' }}
              text={translations.editProfile}
              iconType="solid"
              inactive
              onClick={closeMenus}
            />

            <SidebarNavItem
              icon="key"
              to={`/users/${user.id}/edit`}
              state={{ tab: 'password' }}
              text={translations.changePassword}
              iconType="solid"
              inactive
              onClick={closeMenus}
            />

            <SidebarNavItem
              icon="globe"
              text={translations.language + ': ' + renderLanguage(language)}
              iconType="solid"
              inactive
              menuOpen={languageMenuOpen}
              onClick={() => setLanguageMenuOpen(v => !v)}
            />

            {languageMenuOpen && (
              <div>
                <SubMenuItem
                  active={language === 'en'}
                  onClick={() => setLanguage('en')}
                >
                  {renderLanguage('en')}
                </SubMenuItem>

                <SubMenuItem
                  active={language === 'nb'}
                  onClick={() => setLanguage('nb')}
                >
                  {renderLanguage('nb')}
                </SubMenuItem>
              </div>
            )}

            {!!companies.length && (
              <SidebarNavItem
                icon="building"
                text={translations.changeCompany}
                iconType="solid"
                inactive
                menuOpen={companyMenuOpen}
                onClick={() => setCompanyMenuOpen(v => !v)}
              />
            )}

            {companyMenuOpen && (
              <div>
                {companies.map(comp => (
                  <SubMenuItem
                    key={comp.id}
                    active={comp.id === company.id}
                    onClick={() => changeCompany(comp.shortName)}
                  >
                    {comp.fullName}
                  </SubMenuItem>
                ))}
              </div>
            )}
          </ContentWrapper>
        )}

        {(!isMobileOnly || (sidebarOpen && !userMenuOpen)) && (
          <ContentWrapper>
            <SidebarNavItem
              open={sidebarOpen}
              icon="home"
              to="/dashboard"
              text={translations.mySite}
              iconType="solid"
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="folder-open"
              to="/projects"
              text={translations.projects}
              permissions={PERMISSIONS.projects.view.project}
              iconType="solid"
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="shopping-cart"
              to="/customers"
              text={translations.customers}
              iconType="solid"
              permissions={PERMISSIONS.customers.view.customer}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="users"
              to="/users"
              text={translations.users}
              iconType="solid"
              permissions={PERMISSIONS.users.view.user}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <DocumentationDivider>
              {sidebarOpen && translations.documentation}
            </DocumentationDivider>

            <SidebarNavItem
              open={sidebarOpen}
              icon="box-open"
              to="/products"
              text={translations.products}
              iconType="solid"
              permissions={PERMISSIONS.products.view.product}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="business-time"
              to="/timesheets"
              text={translations.timesheets}
              iconType="solid"
              permissions={[
                'OR',
                PERMISSIONS.timesheets.view.timeentry,
                PERMISSIONS.timesheets.view.absence,
              ]}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="clipboard-list"
              to="/checklists"
              text={translations.checklists}
              iconType="solid"
              permissions={PERMISSIONS.checklists.view.checklist}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="exclamation-triangle"
              to="/deviations"
              text={translations.deviations}
              iconType="solid"
              permissions={PERMISSIONS.deviations.view.deviation}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            <SidebarNavItem
              open={sidebarOpen}
              icon="book"
              to="/handbook"
              text={translations.handbook}
              iconType="solid"
              permissions={[
                PERMISSIONS.companymanuals.view.manualfolder,
                PERMISSIONS.companymanuals.view.manualentry,
              ]}
              onClick={() => (isMobileOnly ? setSidebarOpen(false) : void 0)}
            />

            {isMobileOnly && !userMenuOpen && (
              <>
                <DocumentationDivider />

                <SidebarNavItem
                  icon="user-cog"
                  text={translations.userSettings}
                  iconType="solid"
                  inactive
                  onClick={() => setUserMenuOpen(true)}
                />

                <SidebarNavItem
                  icon="info-circle"
                  to="/user-guide"
                  text={translations.userGuide}
                  iconType="solid"
                  onClick={() => setSidebarOpen(false)}
                />

                <SidebarNavItem
                  icon="cogs"
                  to="/settings/company"
                  text={translations.companySettings}
                  iconType="solid"
                  permissions={[
                    PERMISSIONS.companies.view.companysetting,
                    PERMISSIONS.companies.change.companysetting,
                  ]}
                  onClick={() =>
                    isMobileOnly ? setSidebarOpen(false) : void 0
                  }
                />

                <SidebarNavItem
                  open={sidebarOpen}
                  icon="question-circle"
                  text={translations.support}
                  iconType="solid"
                  onClick={() => window.open('mailto:support@digitech.no')}
                />

                <SidebarNavItem
                  icon="sign-out"
                  to="/logout"
                  text={translations.logOut}
                  iconType="solid"
                  isRed
                />
              </>
            )}
          </ContentWrapper>
        )}
      </div>

      {!isMobileOnly && (
        <div>
          <SidebarNavItem
            open={sidebarOpen}
            icon="cogs"
            to="/settings/company"
            text={translations.companySettings}
            iconType="solid"
            permissions={[
              PERMISSIONS.companies.view.companysetting,
              PERMISSIONS.companies.change.companysetting,
            ]}
          />

          <SidebarNavItem
            open={sidebarOpen}
            icon="info-circle"
            to="/user-guide"
            text={translations.userGuide}
            iconType="solid"
          />

          <SidebarNavItem
            open={sidebarOpen}
            icon="question-circle"
            text={translations.support}
            iconType="solid"
            onClick={() => window.open('mailto:support@digitech.no')}
          />

          <SidebarToggler>
            <IconWrapper onClick={() => setSidebarOpen(v => !v)}>
              <Icon icon={!sidebarOpen ? 'chevron-right' : 'chevron-left'} />
            </IconWrapper>
          </SidebarToggler>
        </div>
      )}
    </Wrapper>
  )
}

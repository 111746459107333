import gql from 'graphql-tag'

export const ALL_PRODUCTS_QUERY = gql`
  query AllProductsQuery(
    $q: String
    $first: Int
    $after: String
    $orderBy: String
    $merchant: [String]
    $isImported: Boolean
  ) {
    allProducts(
      q: $q
      first: $first
      after: $after
      orderBy: $orderBy
      merchant: $merchant
      isImported: $isImported
    ) {
      edges {
        node {
          id
          name
          merchant
          priceExcludingVat
          grossPriceExcludingVat
          unit
          sku
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const PRODUCT_QUERY = gql`
  query ProductQuery($id: ID!) {
    product(id: $id) {
      id
      name
      merchant
      priceExcludingVat
      grossPriceExcludingVat
      unit
      sku
    }
  }
`

export const ALL_PRODUCT_MERCHANTS_QUERY = gql`
  query AllProductMerchantsQuery($isImported: Boolean) {
    allProductMerchants(isImported: $isImported)
  }
`

import { useQuery } from '@apollo/client'
import { SelectOption } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import { Select, SelectProps } from 'components'
import {
  SelectCustomer,
  SelectCustomerQuery,
  SelectCustomerQueryVariables,
  SELECT_CUSTOMER_QUERY,
} from 'modules/customers'
import React, { useMemo, useState } from 'react'

const PAGE_SIZE = 25

interface CustomerSelectProps
  extends Omit<
    SelectProps<string, SelectCustomer>,
    'options' | 'searchable' | 'onFilterChange'
  > {}

export const CustomerSelect: React.FC<CustomerSelectProps> = ({
  value,
  ...props
}) => {
  const translations = useTranslate({
    selectCustomer: 'customers.select-customer',
    loadingCommon: 'common.loading',
    noOptions: 'common.no-more-options',
  })

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { data, previousData, loading } = useQuery<
    SelectCustomerQuery,
    SelectCustomerQueryVariables
  >(SELECT_CUSTOMER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      q: debouncedSearch,
      orderBy: '-createdAt',
      includeIds: !value ? [] : [value],
      first: PAGE_SIZE,
    },
  })

  const customers = useMemo(
    () => (data ?? previousData)?.allCustomers.edges.map(edge => edge.node),
    [data, previousData]
  )

  const options = useMemo<SelectOption<string, SelectCustomer>[]>(
    () =>
      customers?.map(customer => ({
        value: customer.id,
        label: customer.name,
        extra: customer,
      })) ?? [],
    [customers]
  )

  return (
    <Select
      value={value}
      options={options}
      noOptionsText={
        loading ? translations.loadingCommon : translations.noOptions
      }
      loading={loading}
      placeholder={translations.selectCustomer}
      searchable
      onFilterChange={setSearch}
      {...props}
    />
  )
}

import { formatDistanceStrict as formatDistanceStrictBase } from 'date-fns'

interface FormatDistanceStrictOptions {
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  roundingMethod?: 'floor' | 'ceil' | 'round'
  addSuffix?: boolean
}

export function formatDistanceStrict(locale: Locale) {
  return (
    firstDate: Date,
    secondDate: Date,
    options: FormatDistanceStrictOptions = {}
  ) =>
    formatDistanceStrictBase(firstDate, secondDate, {
      locale,
      ...options,
    })
}

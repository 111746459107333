import { useQuery } from '@apollo/client'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader as BasePageHeader,
  TableFiltering,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import { PROJECTS_SHALLOW_QUERY } from 'modules/projects/queries'
import {
  ProjectsShallowQuery,
  ProjectsShallowQueryVariables,
} from 'modules/projects/types.graphql'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useOnErrorAuto, usePermission } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { ChecklistsTable } from './ChecklistsTable'
import { ChecklistsMobile } from './mobile'
import { CHECKLISTS_SHALLOW_QUERY } from './queries'
import { ChecklistStatusType } from './types'
import {
  ChecklistsShallowQuery,
  ChecklistsShallowQueryVariables,
} from './types.graphql'
import {
  useChecklistMenu,
  useChecklistPagination,
  useCreateChecklist,
  useDeleteChecklist,
} from './util'

interface WrapperProps {
  isProjectView: boolean
}
const Wrapper = styled.div<WrapperProps>`
  padding: ${props => props.isProjectView && '0 1rem 1rem'};

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
interface PageHeaderProps {
  isProjectView: boolean
}
const PageHeader = styled(BasePageHeader)<PageHeaderProps>`
  padding-top: 0;
  margin: ${props => props.isProjectView && '0'};
`

const PAGE_SIZE = 25

interface ChecklistsTabProps {
  projectId?: string
}

export const ChecklistsTab: React.FC<ChecklistsTabProps> = ({ projectId }) => {
  const translations = useTranslate({
    checklists: 'common.checklists',
    createChecklist: 'checklists.create-checklist',
    delete: 'common.delete',
    edit: 'common.edit',
    search: 'checklist.search',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const isProjectView = !!projectId
  const canViewProjects = usePermission(PERMISSIONS.projects.view.project)

  const onErrorAuto = useOnErrorAuto()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')

  const [selectedProject, setSelectedProject] = useState<string | null>()
  const [selectedStatuses, setSelectedStatuses] = useState<
    ChecklistStatusType[]
  >(isProjectView ? ['ongoing', 'finished'] : ['ongoing'])

  const debouncedSearch = useDebounce(search)

  const filterClosed = useMemo(() => {
    if (!selectedStatuses.length) return null
    if (selectedStatuses.length === 2) return null
    if (selectedStatuses.includes('ongoing')) return false
    return true
  }, [selectedStatuses])

  const { data, loading, error, fetchMore } = useQuery<
    ChecklistsShallowQuery,
    ChecklistsShallowQueryVariables
  >(CHECKLISTS_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      project: projectId ?? selectedProject,
      closed: filterClosed,
      orderBy: sort,
    },
    onCompleted() {
      setHasLoadedOnce(true)
    },
    onError: onErrorAuto(),
  })

  const checklists = useMemo(
    () => data?.allChecklists.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const hasChecklists = useMemo(() => !!data?.hasChecklists, [data])

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<ProjectsShallowQuery, ProjectsShallowQueryVariables>(
    PROJECTS_SHALLOW_QUERY,
    {
      skip: !!projectId || !canViewProjects,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        orderBy: 'name',
      },
      onError: onErrorAuto(),
    }
  )

  const projects = useMemo(
    () => projectsData?.allProjects.edges.map(({ node }) => node) ?? [],
    [projectsData]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useChecklistPagination(data?.allChecklists.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore)

  const {
    createChecklist: handleCreateChecklist,
    loading: createChecklistLoading,
  } = useCreateChecklist()

  const {
    deleteChecklist: handleDeleteChecklist,
    loading: deleteChecklistLoading,
  } = useDeleteChecklist()

  function handleFilterChange(value: TableFiltering) {
    value.column === 'projectName'
      ? setSelectedProject(value.checked[0])
      : setSelectedStatuses(value.checked as ChecklistStatusType[])
  }

  const makeMenu = useChecklistMenu(handleDeleteChecklist, {
    open: checklist =>
      `/projects/${checklist.project?.id}/checklists/${checklist.id}/`,
    edit: checklist =>
      `/projects/${checklist.project?.id}/checklists/${checklist.id}/`,
  })

  if (error || projectsError)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading =
    loading ||
    createChecklistLoading ||
    deleteChecklistLoading ||
    projectsLoading

  return (
    <EmptyPage
      module="checklists"
      empty={!hasChecklists && !isProjectView}
      loading={!hasLoadedOnce}
      isTabView
      buttonPermissions={[
        PERMISSIONS.checklists.add.checklist,
        PERMISSIONS.checklists.view.checklisttemplate,
        PERMISSIONS.projects.view.project,
      ]}
      onButtonClick={() => handleCreateChecklist(projectId)}
    >
      <Wrapper isProjectView={isProjectView}>
        {isMobileOnly ? (
          <ChecklistsMobile
            checklists={checklists}
            search={search}
            projects={projects.length > 0 ? projects : undefined}
            selectedStatuses={selectedStatuses}
            makeMenu={makeMenu}
            onCreateNewChecklist={() => handleCreateChecklist(projectId)}
            onSearchChange={setSearch}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <>
            <PageHeader
              isProjectView={isProjectView}
              loading={isLoading}
              tabMargin={!isProjectView}
              search={{
                value: search,
                placeholder: translations.search,
                left: true,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.createChecklist,
                  icon: 'plus',
                  permissions: [
                    PERMISSIONS.checklists.add.checklist,
                    PERMISSIONS.checklists.view.checklisttemplate,
                    PERMISSIONS.projects.view.project,
                  ],
                  onClick: () => handleCreateChecklist(projectId),
                },
              ]}
            />

            <ChecklistsTable
              checklists={checklists}
              projects={projects}
              search={debouncedSearch}
              selectedStatuses={selectedStatuses}
              excludeColumns={
                !!projectId ? ['projectName', 'customerName'] : ['modifiedDate']
              }
              makeMenu={makeMenu}
              onFilterChange={handleFilterChange}
              onSortingChange={setSort}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { ProductForm } from './components/ProductForm'
import { CREATE_PRODUCT_MUTATION } from './mutations'
import {
  CreateProductMutation,
  CreateProductMutationVariables,
} from './types.graphql'
import { isCreateProductMutationVariables } from './util/util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

interface CreateProductProps {}

export const CreateProduct: React.FC<CreateProductProps> = () => {
  const translations = useTranslate({
    createProduct: 'products.create-product',

    results: {
      createSuccess: 'products.toasts.create-success',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const [createProductMutation, { loading }] = useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CREATE_PRODUCT_MUTATION, {
    refetchQueries: ['AllProductsQuery'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.createSuccess)
      history.push('/products')
    },
    onError: onErrorAuto(),
  })

  function handleSubmit(form: CreateProductMutationVariables) {
    createProductMutation({
      variables: {
        ...form,
      },
    })
  }

  return (
    <Wrapper>
      <PageHeader title={translations.createProduct} loading={loading} />
      <ProductForm
        loading={loading}
        onSubmit={variables =>
          isCreateProductMutationVariables(variables) && handleSubmit(variables)
        }
      />
    </Wrapper>
  )
}

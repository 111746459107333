import { Loader } from '@ur/react-components'
import { Card } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

const Wrapper = styled(Card)`
  padding: 2.2rem 3rem 3rem;

  h2 {
    display: flex;
    gap: 0.5rem;
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    font-weight: 400;
  }
  button.submit {
    margin-top: 0.5rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    background: inherit;
    box-shadow: none;
  }
`

interface InfoCardProps {
  className?: string
  title: string
  loading: boolean
}

export const InfoCard: React.CFC<InfoCardProps> = ({
  children,
  className,
  title,
  loading,
}) => {
  return (
    <Wrapper className={className}>
      {!isMobileOnly && (
        <h2>
          {title}
          {loading && <Loader.Spinner size={18} thickness={2} />}
        </h2>
      )}
      {children}
    </Wrapper>
  )
}

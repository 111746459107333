import { Icon, IconProps, Loader } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import React, { AriaRole, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface WrapperProps {
  disabled: boolean
  boxSize: string
  mirror: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  color: ${props => props.theme.colors.gray3};
  user-select: none;

  i {
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${props => props.boxSize};
    width: ${props => props.boxSize};

    background-color: ${props => props.theme.colors.gray4};
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    transform: ${props => props.mirror && 'scale(-1, 1)'};
  }
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};

        i {
          color: white;
          background-color: ${props => props.theme.colors.primaryHover};
        }
      }
    `};
`

interface IconButtonProps {
  className?: string
  icon: FontAwesomeIcon | IconProps

  href?: string
  newTab?: boolean
  role?: AriaRole

  loading?: boolean
  disabled?: boolean
  boxSize?: string
  mirror?: boolean

  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
}

export const IconButton: React.CFC<IconButtonProps> = ({
  children,
  className,

  icon,
  href,
  newTab,
  role = 'button',

  loading = false,
  disabled = false,
  boxSize = '40px',
  mirror = false,

  onClick,
}) => {
  const history = useHistory()

  function handleClick(evt: React.MouseEvent<HTMLDivElement>) {
    if (loading || disabled) return

    onClick?.(evt)
    if (evt.defaultPrevented || !href) return

    if (newTab) window.open(href)
    else history.push(href)
  }

  const iconProps = useMemo<IconProps>(
    () => ({
      type: 'solid',
      size: '20px',
      color: 'white',
      fixedWidth: true,
      ...(() =>
        typeof icon === 'string'
          ? {
              icon,
            }
          : icon)(),
    }),
    [icon]
  )

  return (
    <Wrapper
      className={className}
      role={role}
      disabled={disabled || loading}
      boxSize={boxSize}
      mirror={mirror}
      onClick={handleClick}
    >
      {loading ? (
        <i>
          <Loader.Spinner size={22} colorSecondary="primaryHover" />
        </i>
      ) : (
        <Icon {...iconProps} />
      )}
      {children}
    </Wrapper>
  )
}

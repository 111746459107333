import { useMutation, useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { Message } from 'components'
import capitalize from 'lodash/capitalize'
import React, { useMemo, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { formatPhoneNumber } from 'util/formatting'
import { usePermissions, useToast } from 'util/hooks'
import { useOnErrorAuto } from 'util/hooks/useOnErrorAuto'
import { PERMISSIONS } from 'util/permissions'
import { EmergencyContacts, ProfileCard, ProfilePicture } from './components'
import { PATCH_USER_MUTATION } from './mutations'
import { USER_SHALLOW_QUERY } from './queries'
import { UserShallowQuery } from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
  }
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'thumb    base'
    'info     info'
    'contact  contact';

  div.base {
    grid-area: base;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: 100%;
    gap: 11px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;

      i {
        padding-left: 1rem;
      }
    }

    span {
      font-size: 14px;
      color: ${props => props.theme.colors.gray3};
    }
  }

  ${props => props.theme.media.desktop} {
    grid-gap: 1rem;
    width: 400px;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    div.base {
      padding-left: 1rem;
    }
  }
`
const InfoCard = styled(ProfileCard).attrs({
  area: 'info',
})`
  address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 3rem;

    font-style: normal;

    a {
      color: ${props => props.theme.colors.gray2};
      text-decoration: none;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  ${props => props.theme.media.mobile} {
    h2 {
      padding: 0.5rem 1rem 1rem;
    }

    address {
      padding: 1rem;
    }
  }
`
const UploadProfilePicture = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.theme.media.mobile} {
    padding-left: 1rem;
  }
`

interface UserProfileProps {}

export const UserProfile: React.FC<UserProfileProps> = () => {
  const translations = useTranslate({
    information: 'common.information',

    results: {
      pictureDeleteSuccess: 'common.image-deleted',
      pictureError: 'errors.image-upload',
      pictureSuccess: 'common.image-uploaded',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const wasDeletedRef = useRef(false)

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()
  const { id: userId } = useParams<{ id: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')

  const { hasPermissionsOrMe } = usePermissions()
  const canEdit = useMemo(
    () => hasPermissionsOrMe({ id: userId }, PERMISSIONS.users.change.user),
    [hasPermissionsOrMe, userId]
  )

  const { loading, data, error } = useQuery<UserShallowQuery, IdVariable>(
    USER_SHALLOW_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { id: userId },
      onCompleted({ user }) {
        setOverrides(v => ({
          [userId]: user.fullName,
          ...v,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const [patchUserMutation] = useMutation(PATCH_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: USER_SHALLOW_QUERY, variables: { id: data?.user.id } },
    ],
    onCompleted() {
      mixpanel.track('Edit User')
      if (wasDeletedRef.current === true) {
        addToast('success', translations.results.pictureDeleteSuccess)
        wasDeletedRef.current = false
      } else addToast('success', translations.results.pictureSuccess)
    },
    onError: onErrorAuto(translations.results.pictureError),
  })

  function handleUploadImage(evt: React.ChangeEvent<HTMLInputElement> | null) {
    if (!data) return

    let file = null
    if (!!evt) file = Array.from(evt.target.files ?? [])[0]
    else wasDeletedRef.current = true

    patchUserMutation({
      variables: {
        id: data.user.id,
        input: {
          profilePicture: file,
        },
      },
    })

    if (!!evt) evt.target.value = ''
  }

  const googleMapsLink = !!data
    ? encodeURI(
        `https://www.google.com/maps/place/${data.user.address}+${data.user.postalCode}+${data.user.city}`
      )
    : '#'

  if (!!error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {loading || !data ? (
        <Centered>
          <Loader.Spinner />
        </Centered>
      ) : (
        <Content>
          <UploadProfilePicture>
            <ProfilePicture
              src={data.user.profilePicture ?? null}
              alt={data.user.fullName ?? ''}
              onRemove={() => handleUploadImage(null)}
              onRequestChange={() => fileInputRef.current?.click()}
            />

            <input
              ref={fileInputRef}
              hidden
              type="file"
              accept="image/*"
              onChange={handleUploadImage}
            />
          </UploadProfilePicture>

          <div className="base">
            <h1>
              {data.user.fullName}

              {canEdit && (
                <Icon
                  role="link"
                  icon="edit"
                  type="solid"
                  color="gray3"
                  hoverColor="primaryHover"
                  cursor="pointer"
                  size="27px"
                  onClick={() => history.push(`/users/${data.user.id}/edit`)}
                />
              )}
            </h1>

            <span>{data.user.userTypes.map(type => type.name).join(', ')}</span>
          </div>

          <InfoCard>
            <h2>{translations.information}</h2>

            <address>
              <a href={`mailto:${data.user.email}`}>{data.user.email}</a>

              <a href={`tel:${data.user.phoneNumber}`}>
                {formatPhoneNumber(data.user.phoneNumber)}
              </a>

              {data.user.address && (
                <a
                  href={googleMapsLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {data.user.address}, {data.user.postalCode}&nbsp;
                  {capitalize(data.user.city)}
                </a>
              )}
            </address>
          </InfoCard>

          <EmergencyContacts user={data.user} loading={loading} />
        </Content>
      )}
    </Wrapper>
  )
}

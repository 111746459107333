import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import { FetchMoreLoader, Input, Message, PageHeader } from 'components'
import { EmptyPage } from 'components/EmptyPage'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { PaginationVariables } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { CompanyManualsMobile } from './CompanyManualsMobile'
import { CreateManualFolderModal } from './CreateManualFolder'
import { ManualFoldersTable } from './ManualFoldersTable'
import { ManualSearch } from './ManualSearch'
import { CREATE_MANUAL_FOLDER_MUTATION } from './mutations'
import { ALL_ROOT_MANUAL_FOLDERS_QUERY } from './queries'
import {
  AllRootManualFoldersQuery,
  CreateManualFolderMutation,
  CreateManualFolderMutationVariables,
} from './types.graphql'
import { useCompanyManualsPagination } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
    font-weight: 600;
  }
  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
const Toolbar = styled.div`
  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

const PAGE_SIZE = 25

export const CompanyManuals: React.FC = () => {
  const translations = useTranslate({
    handbook: 'common.handbook',
    search: 'handbook.search-for-content',
    addFolder: 'handbook.create-folder',

    title: 'common.title',
    description: 'common.description',
    lastUpdated: 'common.last-updated',
    numEntries: 'handbook.entries',
    numFolders: 'handbook.folders',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
    toasts: {
      folderCreateSuccess: 'handbook.toasts.folder-created',
    },
  })

  const onErrorAuto = useOnErrorAuto()

  const addPrompt = usePrompt()

  const addToast = useToast()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { data, loading, error, fetchMore } = useQuery<
    AllRootManualFoldersQuery,
    PaginationVariables
  >(ALL_ROOT_MANUAL_FOLDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { first: PAGE_SIZE },
    onCompleted() {
      setHasLoadedOnce(true)
    },
    onError: onErrorAuto(),
  })

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useCompanyManualsPagination(
    data?.allRootManualFolders.pageInfo,
    fetchMore
  )

  useInfiniteScroll(handleFetchMore)

  const [createManualFolderMutation] = useMutation<
    CreateManualFolderMutation,
    CreateManualFolderMutationVariables
  >(CREATE_MANUAL_FOLDER_MUTATION, {
    refetchQueries: ['AllRootManualFolders'],
    onCompleted() {
      addToast('success', translations.toasts.folderCreateSuccess)
    },
    onError: onErrorAuto(),
  })

  const handleCreateManualFolder = useCallback(async () => {
    const { data } =
      await addPrompt<CreateManualFolderMutationVariables | null>(
        (resolve, _, { id }) => (
          <CreateManualFolderModal id={id} root onSubmit={resolve} />
        )
      )
    if (!data) return

    createManualFolderMutation({
      variables: data,
    })
  }, [addPrompt, createManualFolderMutation])

  const folders = useMemo(
    () => data?.allRootManualFolders.edges.map(edge => edge.node) ?? [],
    [data]
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <EmptyPage
      module="handbook"
      empty={!folders.length && !debouncedSearch && !loading}
      loading={!hasLoadedOnce}
      buttonPermissions={PERMISSIONS.companymanuals.add.manualfolder}
      onButtonClick={() => handleCreateManualFolder()}
    >
      <Wrapper>
        {isMobileOnly ? (
          <>
            <Toolbar>
              <Input
                value={search}
                placeholder={translations.search}
                iconRightProps={{
                  icon: 'search',
                  type: 'solid',
                  color: 'gray4',
                  size: '18px',
                  translateX: '-2px',
                }}
                fullWidth
                onChange={setSearch}
              />
            </Toolbar>

            {!debouncedSearch ? (
              <CompanyManualsMobile
                root
                folders={folders}
                onCreateClick={() => handleCreateManualFolder()}
              />
            ) : (
              <ManualSearch
                query={debouncedSearch}
                onCreateClick={() => handleCreateManualFolder()}
                onResetQuery={() => setSearch('')}
              />
            )}
          </>
        ) : (
          <>
            <PageHeader
              title={translations.handbook}
              loading={loading}
              search={{
                value: search,
                placeholder: translations.search,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.addFolder,
                  icon: 'plus',
                  permissions: PERMISSIONS.companymanuals.add.manualfolder,
                  onClick: handleCreateManualFolder,
                },
              ]}
            />

            {!debouncedSearch ? (
              <ManualFoldersTable
                root
                folders={folders}
                search={debouncedSearch}
              />
            ) : (
              <ManualSearch
                query={debouncedSearch}
                onCreateClick={() => handleCreateManualFolder()}
                onResetQuery={() => setSearch('')}
              />
            )}
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

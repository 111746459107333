import { Icon, Image } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ImageViewer } from 'components'
import omit from 'lodash/omit'
import uniqueId from 'lodash/uniqueId'
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import {
  checkIsImage,
  fileIsAllowed,
  getFileIcon,
  getFileIconColor,
  isGlobalAccept,
} from 'util/files'
import {
  FilePickerFile,
  FilePickerFileFile,
  FilePickerOnChangeValue,
  FilePickerProps,
  FilePickerRef,
  FilePickerUrlFile,
} from './types'

export const FILE_PICKER_ID_PREFIX = '[file-picker-file]'

const Wrapper = styled.div``
const Files = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 4rem));
  gap: 0.5rem;

  width: 100%;
  margin-bottom: 0.5rem;
`
interface FileItemProps {
  img?: string | null
}
const FileItem = styled.div<FileItemProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3rem;

  border-radius: 4px;

  ${props =>
    !!props.img &&
    css`
      transition: transform ease-out 0.1s;
      will-change: transform;
      cursor: pointer;

      background-image: url('${props.img}');
      background-size: cover;

      &:hover {
        transform: scale(1.05);
      }
    `};

  div.--file-picker-files-delete {
    position: absolute;
    z-index: 1;
    top: -6px;
    right: -6px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.2rem;
    height: 1.2rem;

    border-radius: 50%;
    background-color: ${props => props.theme.colors.danger};
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    i {
      font-size: 0.8rem;
      transform: translateY(1px);
    }
    &:hover {
      background-color: #c92323;

      i {
        color: white;
      }
    }
  }
`

interface DropperProps {
  width: string
  height: string
  isHolding: boolean
  isAllowed: boolean
}
const Dropper = styled.div<DropperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  width: ${props => props.width};
  height: ${props => props.height};

  border: 2px dashed ${props => props.theme.colors.gray4};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  span.--file-picker-selected-file {
    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
  }
  i.--file-picker-cloud {
    transition: transform 0.1s ease-out;
    pointer-events: none;
    touch-action: none;
  }
  div.--file-picker-hints {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;

    font-size: 0.8rem;
    font-weight: 600;
    color: ${props => props.theme.colors.gray4};

    pointer-events: none;
    touch-action: none;
    user-select: none;

    span.--file-picker-choose-file {
      color: ${props => props.theme.colors.primary};
      cursor: pointer;
      pointer-events: auto;
      touch-action: auto;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  ${props =>
    props.isHolding &&
    css`
      border-color: ${props => props.theme.colors.primaryHover};

      i.--file-picker-cloud {
        transform: translateY(-0.5rem);
        color: ${props => props.theme.colors.primaryHover};
      }
      div.--file-picker-hints {
        color: ${props => props.theme.colors.primaryHover};
      }
    `};

  ${props =>
    !props.isAllowed &&
    css`
      border-color: ${props => props.theme.colors.danger};

      i.--file-picker-cloud {
        transform: none;
        color: ${props => props.theme.colors.danger};
      }
      div.--file-picker-hints {
        color: ${props => props.theme.colors.danger};
      }
    `};
`

interface URLFiles {
  [id: string]: FilePickerUrlFile
}

export const FilePicker = React.forwardRef<FilePickerRef, FilePickerProps>(
  (
    {
      className,

      initialFiles = [],

      accept: initialAccept,
      multiple: initialMultiple = false,
      showSelectedFileText = false,
      images,

      width = '100%',
      fullWidth,
      height = '150px',

      dropHintTop,
      dropHintBottom,
      chooseFileText,

      onDragEnter,
      onDragOver,
      onDragLeave,
      onDrop,
      onChange,
    },
    ref
  ) => {
    const translations = useTranslate({
      dropHintTopSingular: 'common.drop-file-here',
      dropHintTopPlural: 'common.drop-files-here',
      dropHintBottom: 'common.or',
      chooseFileText: 'common.choose-file',
      chooseFilesText: 'common.choose-files',

      fileAdded: ['files.file-chosen-success', { fileName: '' }],

      dropHintTopImages: 'common.drop-images-here',
      chooseImagesText: 'common.choose-images',
    })

    const existingAlreadyUpdated = useRef(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const [value, setValue] = useState<FilePickerOnChangeValue>({
      new: [],
      existing: initialFiles,
      deleted: [],
    })
    const [isHolding, setIsHolding] = useState(false)
    const [isAllowed, setIsAllowed] = useState(true)
    const [urlFiles, setUrlFiles] = useState<URLFiles>({})
    const [viewFile, setViewFile] = useState<FilePickerUrlFile | null>(null)

    useImperativeHandle(ref, () => ({
      updateInternalValue: setValue,
    }))

    useEffect(() => {
      if (existingAlreadyUpdated.current) {
        existingAlreadyUpdated.current = false
        return
      }

      const files: URLFiles = {}

      for (const file of value.existing) {
        const fileObj = file.thumbnail ?? file.file
        const fileName =
          typeof fileObj !== 'string' ? fileObj.name : file.name ?? ''
        const url =
          typeof fileObj === 'string' ? fileObj : URL.createObjectURL(fileObj)

        const urlFile: FilePickerUrlFile = {
          id: file.id ?? uniqueId(FILE_PICKER_ID_PREFIX),
          name: file.name ?? '',
          url,
          image: checkIsImage(fileName),
        }
        files[urlFile.id] = urlFile
      }

      setUrlFiles(files)
    }, [value.existing])

    function createUrlFiles(files: FilePickerFile[]) {
      const urlFiles: URLFiles = {}

      for (const file of files) {
        const urlFile: FilePickerUrlFile =
          typeof file.file === 'string'
            ? {
                id: file.id ?? uniqueId(FILE_PICKER_ID_PREFIX),
                name: file.name ?? '',
                url: file.file,
                image: checkIsImage(file.file),
              }
            : {
                id: file.id ?? uniqueId(FILE_PICKER_ID_PREFIX),
                name: file.name ?? file.file.name,
                url: URL.createObjectURL(file.file),
                image: checkIsImage(file.file.name),
              }
        urlFiles[urlFile.id] = urlFile
      }

      setUrlFiles(v => ({
        ...v,
        ...urlFiles,
      }))
    }

    const accept = useMemo(
      () => (images ? 'image/*' : initialAccept),
      [images, initialAccept]
    )
    const multiple = useMemo(
      () => (images ? true : initialMultiple),
      [images, initialMultiple]
    )

    const hints = useMemo(
      () => ({
        top: isMobileOnly
          ? null
          : dropHintTop ??
            (images
              ? translations.dropHintTopImages
              : multiple
              ? translations.dropHintTopPlural
              : translations.dropHintTopSingular),
        bottom: isMobileOnly
          ? null
          : dropHintBottom ?? translations.dropHintBottom,
        choose:
          chooseFileText ??
          (images
            ? translations.chooseImagesText
            : multiple
            ? translations.chooseFilesText
            : translations.chooseFileText),
      }),
      [
        dropHintTop,
        images,
        translations.dropHintTopImages,
        translations.dropHintTopPlural,
        translations.dropHintTopSingular,
        translations.dropHintBottom,
        translations.chooseImagesText,
        translations.chooseFilesText,
        translations.chooseFileText,
        multiple,
        dropHintBottom,
        chooseFileText,
      ]
    )

    const imageFiles = useMemo<Image[]>(() => {
      const reducer = (acc: Image[], cur: FilePickerFile): Image[] =>
        !checkIsImage(cur.file instanceof File ? cur.file.name : cur.file)
          ? acc
          : [
              ...acc,
              {
                id: cur.id,
                file: cur.file,
                name: cur.name,
              },
            ]

      const newFiles = value.new.reduce(reducer, [])
      const existingFiles = value.existing.reduce(reducer, [])

      return [...newFiles, ...existingFiles]
    }, [value.new, value.existing])

    /* Drag-drop */

    function handleDragEnter(evt: React.DragEvent<HTMLDivElement>) {
      onDragEnter?.(evt)
      if (evt.defaultPrevented) return
      evt.preventDefault()

      setIsHolding(true)
    }

    function handleDragOver(evt: React.DragEvent<HTMLDivElement>) {
      onDragOver?.(evt)
      if (evt.defaultPrevented) return
      evt.preventDefault()

      setIsHolding(true)
      if (!isAllowed || isGlobalAccept(accept)) return

      const { items } = evt.dataTransfer
      if (items.length > 1 && !multiple) {
        setIsAllowed(false)
        return
      }

      for (const item of Array.from(items)) {
        const allowed = !!item.type && fileIsAllowed(item.type, accept)
        if (!allowed) {
          setIsAllowed(false)
          return
        }
      }
      setIsAllowed(true)
    }

    function handleDragLeave(evt: React.DragEvent<HTMLDivElement>) {
      onDragLeave?.(evt)
      if (evt.defaultPrevented) return
      evt.preventDefault()

      setIsHolding(false)
      setIsAllowed(true)
    }

    function handleDrop(evt: React.DragEvent<HTMLDivElement>) {
      onDrop?.(evt)
      if (evt.defaultPrevented) return
      evt.preventDefault()

      const { files } = evt.dataTransfer
      const fileArray = Array.from(files)
      if (
        !!files.length &&
        fileArray.every(file => fileIsAllowed(file.type, accept))
      )
        handleAddFiles(fileArray)

      setIsHolding(false)
      setIsAllowed(true)
    }

    /* Other handlers */

    function handleAddFiles(files: File[]) {
      if (!files.length || (!multiple && files.length > 1)) return

      const newFiles = files.map<FilePickerFileFile>(file => ({
        id: uniqueId(FILE_PICKER_ID_PREFIX),
        name: file.name,
        file,
      }))

      const newValue: FilePickerOnChangeValue = {
        new: !multiple ? newFiles : [...value.new, ...newFiles],
        existing: value.existing,
        deleted: value.deleted,
      }

      createUrlFiles(newFiles)
      setValue(newValue)
      onChange(newValue, 'add', newFiles)
    }

    function handleDeleteFile(file: FilePickerUrlFile) {
      return (evt: React.MouseEvent) => {
        evt.stopPropagation()

        const deletedFile: FilePickerFile = {
          id: file.id,
          name: file.name,
          file: file.url,
        }

        const newValue = { ...value }
        if (file.id.startsWith(FILE_PICKER_ID_PREFIX))
          newValue.new = newValue.new.filter(fi => fi.id !== file.id)
        else {
          existingAlreadyUpdated.current = true
          newValue.existing = newValue.existing.filter(fi => fi.id !== file.id)
          newValue.deleted = [...newValue.deleted, deletedFile]
        }

        setUrlFiles(v => omit(v, file.id))
        setValue(newValue)
        onChange(newValue, 'delete', [deletedFile])
      }
    }

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
      handleAddFiles(Array.from(evt.target.files ?? []))
      evt.target.value = ''
    }

    return (
      <Wrapper className={className}>
        <ImageViewer
          popup
          open={viewFile !== null}
          images={imageFiles}
          initialImage={viewFile?.id ?? 0}
          onClose={() => setViewFile(null)}
        />

        {!!Object.keys(urlFiles).length && multiple && (
          <Files className="--file-picker-files">
            {Object.values(urlFiles).map(file => (
              <FileItem
                key={file.id}
                title={file.name}
                img={file.image ? file.url : null}
                onClick={() => file.image && setViewFile(file)}
              >
                <div
                  className="--file-picker-files-delete"
                  onClick={handleDeleteFile(file)}
                >
                  <Icon icon="times" />
                </div>

                {!file.image && (
                  <Icon
                    icon={getFileIcon(file.url)}
                    color={getFileIconColor(file.url)}
                    size="2rem"
                  />
                )}
              </FileItem>
            ))}
          </Files>
        )}

        <Dropper
          width={fullWidth ? '100%' : width}
          height={height}
          isHolding={isHolding}
          isAllowed={isAllowed}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => isMobileOnly && inputRef.current?.click()}
        >
          <input
            ref={inputRef}
            hidden
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={handleChange}
          />

          {showSelectedFileText && !multiple && !!value.new.length ? (
            <span className="--file-picker-selected-file">
              {translations.fileAdded({
                fileName: value.new[0].file.name,
              })}
            </span>
          ) : (
            <Icon
              className="--file-picker-cloud"
              icon={isAllowed ? 'cloud-upload' : 'times'}
              type="light"
              size="2rem"
              color="gray4"
            />
          )}

          <div className="--file-picker-hints">
            {!!hints.top && (
              <span className="--file-picker-drop-hint-top">{hints.top}</span>
            )}

            {!!hints.bottom && (
              <span className="--file-picker-drop-hint-bottom">
                {hints.bottom}
              </span>
            )}

            <span
              className="--file-picker-choose-file"
              role="button"
              onClick={evt => {
                evt.stopPropagation()
                inputRef.current?.click()
              }}
            >
              {hints.choose}
            </span>
          </div>
        </Dropper>
      </Wrapper>
    )
  }
)

import { useMutation } from '@apollo/client'
import { Loader, PromptResolve } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { overloadColor } from 'util/style'
import { PATCH_OFFER_MUTATION } from '../mutations'
import {
  ChangeOfferStatusMutationVariables,
  OfferNode,
  OfferStatusType,
  PatchOfferMutation,
} from '../types.graphql'

const Wrapper = styled.aside`
  min-width: 200px;

  border: 0;
  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  div.content {
    position: relative;
    padding: 0;
  }
`
interface StatusProps {
  color: string
}
const Status = styled.div.attrs({
  role: 'button',
})<StatusProps>`
  padding: 0.8rem 1.8rem;

  cursor: pointer;
  user-select: none;
  text-align: center;
  color: ${props => overloadColor(props.color)};

  &:hover {
    background-color: ${props => props.theme.colors.gray7};
  }
  & + div {
    border-top: 1px solid ${props => props.theme.colors.gray6};
  }
  &:first-of-type {
    border-top-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
    border-top-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
  }
  &:last-of-type {
    border-bottom-left-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
    border-bottom-right-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
  }
`
const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
`

interface ChangeOfferModalProps {
  offer: Pick<OfferNode, 'id' | 'status'>
  refetchQueries?: string[]

  onResolve: PromptResolve<boolean>
}

export const ChangeOfferModal: React.FC<ChangeOfferModalProps> = ({
  offer,
  refetchQueries,

  onResolve,
}) => {
  const translations = useTranslate({
    CREATED: 'offers.status.created',
    SENT: 'offers.status.sent',
    APPROVED: 'offers.status.accepted',
    DECLINED: 'offers.status.declined',
    FINISHED: 'offers.status.finished',
  })

  const wrapperRef = useRef<HTMLElement>(null)
  const onErrorAuto = useOnErrorAuto()

  useClickOutside(wrapperRef, () => onResolve(false), true)

  const statuses = useMemo(
    () =>
      [
        ['CREATED', 'matteLilac'],
        ['SENT', 'information'],
        ['APPROVED', 'success'],
        ['DECLINED', 'warning2'],
        ['FINISHED', 'gray2'],
      ] as const,
    []
  )

  const [patchOfferMutation, { loading }] = useMutation<
    PatchOfferMutation,
    ChangeOfferStatusMutationVariables
  >(PATCH_OFFER_MUTATION, {
    refetchQueries,
    onError: onErrorAuto(),
  })

  function handleStatusClick(status: OfferStatusType) {
    return () => {
      if (status === offer.status) {
        onResolve(true)
        return
      }

      patchOfferMutation({
        variables: { id: offer.id, input: { status } },
      })
        .then(() => onResolve(true))
        .catch(() => onResolve(false))
    }
  }

  return (
    <Wrapper ref={wrapperRef}>
      {loading && (
        <Loading>
          <Loader.Spinner thickness={4} />
        </Loading>
      )}

      {statuses.map(([status, color]) => (
        <Status key={status} color={color} onClick={handleStatusClick(status)}>
          {translations[status]}
        </Status>
      ))}
    </Wrapper>
  )
}

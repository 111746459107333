import {
  ToastOptions,
  ToastType,
  useToast as useToastBase,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useCallback } from 'react'
import styled from 'styled-components'

interface ToastBodyProps {
  type: ToastType
}
const ToastBody = styled.div<ToastBodyProps>`
  h4  {
    margin: 0 0 0.2rem 0;
    font-size: 0.9rem;
  }
  p {
    margin: 0 0 6px 0;
    font-size: 0.8rem !important;
  }
`

export function useToast() {
  const translations = useTranslate({
    success: 'toasts.success',
    info: 'toasts.info',
    warning: 'toasts.warning',
    error: 'toasts.error',
  })

  const addToastBase = useToastBase()

  const addToast = useCallback(
    (type: ToastType, message: string, options?: ToastOptions) => {
      const title = translations[type]
      addToastBase(
        type,
        <ToastBody type={type}>
          <h4>{title}</h4>
          <p>{message}</p>
        </ToastBody>,
        options
      )
    },
    [addToastBase, translations]
  )

  return addToast
}

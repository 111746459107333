import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { Card as BaseCard, CenteredLoader, Message } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { EntryForm, EntryFormSubmitData } from './components'
import { PATCH_MANUAL_ENTRY_MUTATION } from './mutations'
import { MANUAL_ENTRY_BY_SLUG_QUERY } from './queries'
import {
  ManualEntryBySlugQuery,
  ManualEntryBySlugQueryVariables,
  PatchManualEntryMutation,
  PatchManualEntryMutationVariables,
} from './types.graphql'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  h1 {
    margin-left: 0.25rem;

    font-size: 1.2rem;
    font-weight: 600;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
const Card = styled(BaseCard)`
  max-width: 600px;

  ${props => props.theme.media.mobile} {
    width: 100vw;
    max-width: auto;
    padding: 1rem 1rem 6rem;

    border-radius: 0;
  }
`

interface EditManualEntryProps {}

export const EditManualEntry: React.FC<EditManualEntryProps> = () => {
  const translations = useTranslate({
    editEntry: 'handbook.edit-entry',

    results: {
      updated: 'handbook.toasts.entry-updated',

      entryNotFound: 'handbook.errors.entry-not-found',
      couldNotUpdate: 'handbook.errors.patch-entry',
    },
  })

  const { entrySlug, folderSlug } =
    useParams<{ entrySlug: string; folderSlug: string }>()
  const { state } = useLocation<{ next?: string }>()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')

  function getNextUrl(slug?: string) {
    return typeof state?.next === 'undefined'
      ? `/handbook/${folderSlug}`
      : `${state.next}${slug ?? entrySlug}`
  }

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useQuery<ManualEntryBySlugQuery, ManualEntryBySlugQueryVariables>(
    MANUAL_ENTRY_BY_SLUG_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        entrySlug,
        folderSlug,
      },
      onCompleted(data) {
        setOverrides({
          [data.manualEntry.folder.slug]: data.manualEntry.folder.name,
          [data.manualEntry.slug]: data.manualEntry.name,
        })
      },
    }
  )

  const [patchEntry, { loading: patchLoading }] = useMutation<
    PatchManualEntryMutation,
    PatchManualEntryMutationVariables
  >(PATCH_MANUAL_ENTRY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ManualFolderBySlug'],
    onCompleted(data) {
      addToast('success', translations.results.updated)
      history.push(getNextUrl(data.patchManualEntry.manualEntry.slug))
    },
    onError: onErrorAuto(translations.results.couldNotUpdate),
  })

  function handleSubmit(form: EntryFormSubmitData) {
    if (!data) return

    patchEntry({
      variables: {
        id: data.manualEntry.id,
        input: {
          name: form.name,
          description: form.description,
          richtext: form.richtext,
          attachmentsAdd: form.attachmentsAdd,
          attachmentsRemove: form.attachmentsRemove,
        },
      },
    })
  }

  if (!!queryError)
    return (
      <Message.Error show centered text={translations.results.entryNotFound} />
    )

  return (
    <Wrapper>
      {!isMobileOnly && <h1>{translations.editEntry}</h1>}

      <Card>
        {!data || queryLoading ? (
          <CenteredLoader top="0" />
        ) : (
          <EntryForm
            folderId={data.manualEntry.folder.id}
            loading={patchLoading}
            initialData={data.manualEntry}
            onCancel={() => history.push(getNextUrl())}
            onSubmit={handleSubmit}
          />
        )}
      </Card>
    </Wrapper>
  )
}

import { ObservableQueryFields, useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { OrderArrows, TableMenu } from 'components'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IdVariable, PaginationVariables, RelayPageInfo } from 'types/graphql'
import { useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { useConfirm } from 'util/hooks/useConfirm'
import { PERMISSIONS } from 'util/permissions'
import { EditManualFolderModal } from '.'
import {
  DELETE_MANUAL_ENTRY_MUTATION,
  DELETE_MANUAL_FOLDER_MUTATION,
  PATCH_MANUAL_FOLDER_MUTATION,
  SHIFT_MANUAL_ENTRY_INDEX_MUTATION,
  SHIFT_MANUAL_FOLDER_INDEX_MUTATION,
} from './mutations'
import { MANUAL_FOLDER_ID_BY_SLUG_QUERY } from './queries'
import {
  AllRootManualFoldersQuery,
  CreateManualFolderMutationVariables,
  DeleteManualEntryMutation,
  DeleteManualFolderMutation,
  ManualEntryNode,
  ManualFolderIdBySlugQuery,
  ManualFolderIdBySlugQueryVariables,
  ManualFolderNode,
  PatchManualFolderMutation,
  PatchManualFolderMutationVariables,
  ShallowManualEntry,
  ShallowManualFolder,
  ShiftManualEntryIndexMutation,
  ShiftManualEntryIndexMutationVariables,
  ShiftManualFolderIndexMutation,
  ShiftManualFolderIndexMutationVariables,
} from './types.graphql'

interface UseManualUtilsOptions {
  /**
   * What refetchQueries to use for shift and delete mutations
   * @default ['ManualFolderBySlug']
   */
  refetchQueries?: string[]
  /** Is root folder */
  root?: boolean
}

export function useManualUtils(
  folders: ShallowManualFolder[],
  options?: UseManualUtilsOptions
) {
  const translations = useTranslate({
    edit: 'common.edit',
    delete: 'common.delete',

    prompt: {
      deleteManual: 'handbook.prompts.delete-folder-prompt',
      deleteManualTitle: 'handbook.prompts.delete-folder-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',

      deleteFolderSuccess: 'handbook.toasts.folder-deleted',
      deleteFolderError: 'handbook.errors.folder-delete',

      editFolderSuccess: 'handbook.toasts.folder-updated',

      shiftError: 'handbook.errors.folder-shift',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()
  const addToast = useToast()
  const addPrompt = usePrompt()

  const canEditManualFolder = usePermission(
    PERMISSIONS.companymanuals.change.manualfolder
  )
  const canDeleteManualFolder = usePermission(
    PERMISSIONS.companymanuals.delete.manualfolder
  )

  const [shiftManualFolderIndex] = useMutation<
    ShiftManualFolderIndexMutation,
    ShiftManualFolderIndexMutationVariables
  >(SHIFT_MANUAL_FOLDER_INDEX_MUTATION, {
    refetchQueries: options?.refetchQueries ?? ['ManualFolderBySlug'],
    onError: onErrorAuto(translations.results.shiftError),
  })
  const [deleteFolderMutation] = useMutation<
    DeleteManualFolderMutation,
    IdVariable
  >(DELETE_MANUAL_FOLDER_MUTATION, {
    refetchQueries: options?.refetchQueries ?? ['ManualFolderBySlug'],
    onCompleted() {
      addToast('success', translations.results.deleteFolderSuccess)
    },
    onError: onErrorAuto(translations.results.deleteFolderError),
  })

  const [patchManualFolderMutation] = useMutation<
    PatchManualFolderMutation,
    PatchManualFolderMutationVariables
  >(PATCH_MANUAL_FOLDER_MUTATION, {
    refetchQueries: options?.refetchQueries ?? ['ManualFolderBySlug'],
    onCompleted() {
      addToast('success', translations.results.editFolderSuccess)
    },
    onError: onErrorAuto(),
  })

  const handleEditManualFolder = useCallback(
    async (manualFolder: ShallowManualFolder) => {
      const { data } =
        await addPrompt<PatchManualFolderMutationVariables | null>(
          (resolve, _, { id }) => (
            <EditManualFolderModal
              id={id}
              manualFolder={manualFolder}
              onSubmit={resolve}
              root={options?.root}
            />
          )
        )
      if (!data) return

      patchManualFolderMutation({
        variables: data,
      })
    },
    [addPrompt, patchManualFolderMutation, options]
  )

  const handleDeleteFolder = useCallback(
    async (folder: ShallowManualFolder) => {
      if (!canDeleteManualFolder) return

      const answer = await confirm(
        translations.prompt.deleteManual,
        translations.prompt.deleteManualTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteFolderMutation({
          variables: {
            id: folder.id,
          },
        })
      }
    },
    [
      canDeleteManualFolder,
      confirm,
      deleteFolderMutation,
      translations.prompt.deleteManual,
      translations.prompt.deleteManualTitle,
    ]
  )

  const makeEditDeleteMenu = useCallback(
    (folder: ShallowManualFolder): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditManualFolder,
          onClick: () => handleEditManualFolder(folder),
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: 'logoutRed',
          hide: !canDeleteManualFolder,
          hoverColor: 'logoutRedHover',
          onClick: () => handleDeleteFolder(folder),
        },
      ],
    }),
    [
      canDeleteManualFolder,
      canEditManualFolder,
      handleDeleteFolder,
      handleEditManualFolder,
      translations.delete,
      translations.edit,
    ]
  )

  const makeOrderArrows = useCallback(
    (folder: ShallowManualFolder, index: number) => (
      <OrderArrows
        isFirst={index === 0}
        isLast={index === folders.length - 1}
        onClick={delta =>
          shiftManualFolderIndex({
            variables: {
              id: folder.id,
              delta,
            },
          })
        }
      />
    ),
    [folders.length, shiftManualFolderIndex]
  )

  return {
    makeEditDeleteMenu,
    makeOrderArrows,
  }
}

type HookEntry = Pick<ManualEntryNode, 'id' | 'slug'> & {
  folder: Pick<ManualFolderNode, 'id' | 'slug'>
}

interface UseEntryUtilsOptions {
  /**
   * What refetchQueries to use for shift and delete mutations
   * @default ['ManualFolderBySlug']
   */
  refetchQueries?: string[]
  editState?: { next: string }
  afterDelete?: () => void
}
export function useEntryUtils(
  entries: ShallowManualEntry[],
  options?: UseEntryUtilsOptions
) {
  const translations = useTranslate({
    edit: 'common.edit',
    delete: 'common.delete',

    prompt: {
      deleteEntry: 'handbook.prompts.delete-entry-prompt',
      deleteEntryTitle: 'handbook.prompts.delete-entry-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',

      deleteEntrySuccess: 'handbook.toasts.entry-deleted',
      deleteEntryError: 'handbook.errors.folder-delete',

      shiftError: 'handbook.errors.folder-shift',
    },
  })

  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()
  const addToast = useToast()

  const canEditManualEntry = usePermission(
    PERMISSIONS.companymanuals.change.manualentry
  )
  const canDeleteManualEntry = usePermission(
    PERMISSIONS.companymanuals.delete.manualentry
  )

  const [shiftManualEntryIndex] = useMutation<
    ShiftManualEntryIndexMutation,
    ShiftManualEntryIndexMutationVariables
  >(SHIFT_MANUAL_ENTRY_INDEX_MUTATION, {
    refetchQueries: options?.refetchQueries ?? ['ManualFolderBySlug'],
    onError: onErrorAuto(translations.results.shiftError),
  })

  const [deleteEntryMutation] = useMutation<
    DeleteManualEntryMutation,
    IdVariable
  >(DELETE_MANUAL_ENTRY_MUTATION, {
    refetchQueries: options?.refetchQueries ?? ['ManualFolderBySlug'],
    onCompleted() {
      addToast('success', translations.results.deleteEntrySuccess)
      options?.afterDelete?.()
    },
    onError: onErrorAuto(translations.results.deleteEntryError),
  })

  const handleDeleteEntry = useCallback(
    async (entry: HookEntry) => {
      if (!canDeleteManualEntry) return

      const answer = await confirm(
        translations.prompt.deleteEntry,
        translations.prompt.deleteEntryTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteEntryMutation({
          variables: {
            id: entry.id,
          },
        })
      }
    },
    [
      canDeleteManualEntry,
      confirm,
      deleteEntryMutation,
      translations.prompt.deleteEntry,
      translations.prompt.deleteEntryTitle,
    ]
  )

  const makeEditDeleteMenu = useCallback(
    (entry: HookEntry): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditManualEntry,
          onClick: () =>
            history.push({
              pathname: `/handbook/${entry.folder.slug}/${entry.slug}/edit`,
              state: options?.editState,
            }),
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: 'logoutRed',
          hide: !canDeleteManualEntry,
          hoverColor: 'logoutRedHover',
          onClick: () => handleDeleteEntry(entry),
        },
      ],
    }),
    [
      canDeleteManualEntry,
      canEditManualEntry,
      handleDeleteEntry,
      history,
      options?.editState,
      translations.delete,
      translations.edit,
    ]
  )
  const makeOrderArrows = useCallback(
    (entry: ShallowManualEntry, index: number) => (
      <OrderArrows
        isFirst={index === 0}
        isLast={index === entries.length - 1}
        onClick={delta =>
          shiftManualEntryIndex({
            variables: {
              id: entry.id,
              delta,
            },
          })
        }
      />
    ),
    [entries.length, shiftManualEntryIndex]
  )

  return {
    makeEditDeleteMenu,
    makeOrderArrows,
  }
}

export function useCompanyManualsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreCompanyManuals: ObservableQueryFields<
    AllRootManualFoldersQuery,
    PaginationVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreCompanyManuals({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreCompanyManuals])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function isCreateManualFolderMutationVariables(
  arg: CreateManualFolderMutationVariables | PatchManualFolderMutationVariables
): arg is CreateManualFolderMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchManualFolderMutationVariables(
  arg: CreateManualFolderMutationVariables | PatchManualFolderMutationVariables
): arg is PatchManualFolderMutationVariables {
  return arg.hasOwnProperty('id')
}

export function useFolderId(slug: string) {
  const onErrorAuto = useOnErrorAuto()

  const { data, loading, error } = useQuery<
    ManualFolderIdBySlugQuery,
    ManualFolderIdBySlugQueryVariables
  >(MANUAL_FOLDER_ID_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { slug },
    onError: onErrorAuto(),
  })

  return {
    folderId: data?.manualFolder.id,
    loading,
    error,
  }
}

import { useMutation, useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { Collapsible as BaseCollapsible, Message, TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import xor from 'lodash/xor'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { formatPhoneNumber } from 'util/formatting'
import { useDateFns, useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ProjectStage } from '../components'
import { PATCH_PROJECT_MUTATION } from '../mutations'
import { ProjectBillingType } from '../ProjectBillingType'
import { PROJECT_INFO_QUERY } from '../queries'
import {
  PatchProjectMutation,
  PatchProjectMutationVariables,
  ProjectInfo as Project,
  ProjectInfoQuery,
  ProjectStage as Stage,
} from '../types.graphql'

const Wrapper = styled.div`
  display: flex;

  a:hover {
    color: ${props => props.theme.colors.primaryHover};
  }

  ${props => props.theme.media.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`
interface CollapsibleProps {
  bordered?: boolean
}
const Collapsible = styled(BaseCollapsible)<CollapsibleProps>`
  h3 {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: ${props => props.theme.colors.gray1};
  }

  ${props => props.theme.media.desktop} {
    box-shadow: none;
    background-color: inherit;
    border-radius: 0;

    ${props =>
      props.bordered &&
      css`
        border: solid ${props => props.theme.colors.gray6};
        border-width: 0 1px;
      `};
  }
`
interface FieldProps {
  noBorder?: boolean
}
const Field = styled.div<FieldProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;

  color: ${props => props.theme.colors.gray1};
  font-size: 0.8rem;

  border-top: ${props =>
    !props.noBorder && `1px solid ${props.theme.colors.gray6}`};

  i {
    color: ${props => props.theme.colors.gray2};
    size: 20px;
  }

  ${props => props.theme.media.mobile} {
    margin: 0;
    padding: 1rem;
  }
`
const Description = styled.div`
  flex-direction: column;
  padding: 1rem 2rem;

  color: ${props => props.theme.colors.gray1};
  font-size: 0.8rem;
  border-top: 1px solid ${props => props.theme.colors.gray6};

  h3 {
    margin-bottom: 0.5rem;
  }
  span.none {
    font-style: italic;
    color: ${props => props.theme.colors.gray3};
  }

  ${props => props.theme.media.mobile} {
    margin: 0;
    padding: 1rem;
  }
`
const DateStage = styled.div`
  display: flex;
  justify-content: space-between;

  color: ${props => props.theme.colors.gray3};
  padding: 1rem 2rem;
  font-size: 0.8rem;

  span.date {
    font-weight: 600;
  }

  ${props => props.theme.media.mobile} {
    margin: 0;
    padding: 1rem;
  }
`

type CardType = 'information' | 'customer' | 'billing'

interface ProjectInfoProps {
  onLoading: (loading: boolean) => void
}

export const ProjectInfo: React.FC<ProjectInfoProps> = ({ onLoading }) => {
  const translations = useTranslate({
    generalInformation: 'customers.general-information',
    customer: 'common.customer',
    billing: 'common.billing',
    address: 'common.address',

    yes: 'common.yes',
    no: 'common.no',
    none: 'common.none',

    show: 'common.show-profile',
    hide: 'handbook.edit-folder',

    information: {
      projectId: 'projects.project-id',
      projectLeader: 'projects.project-leader',
      createdBy: 'common.created-by',
      description: 'common.description',
    },

    customerInfo: {
      name: 'common.name',
      contactPerson: 'customers.contact-person',
      phone: 'common.phone',
      email: 'common.email',
      postalCode: 'users.postal-code',
      city: 'common.city',
      orgnr: 'customers.org-nr',
    },

    billingInfo: {
      billed: 'common.billed',
      billingType: 'projects.billing-type',
      hourlyRate: 'projects.hourly-rate',
      discount: 'projects.material-discount',
      estimatedTime: 'projects.estimated-completion-time',
    },

    stage: {
      billing: 'projects.stage-billing',
      finished: 'projects.stage-finished',
      ongoing: 'projects.stage-ongoing',
      planning: 'projects.stage-planning',
    },

    results: {
      queryError: 'server.general-error-try-again-later',

      editError: 'projects.errors.edit',
      editSuccess: 'projects.edit-success',
    },
  })

  const { projectId } = useParams<{ projectId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const { format } = useDateFns()

  const [cardsOpen, setCardsOpen] = useState<CardType[]>([
    'information',
    'customer',
    'billing',
  ])

  function toggleCard(card: CardType) {
    setCardsOpen(v => xor(v, [card]))
  }

  const canEditProject = usePermission(PERMISSIONS.projects.change.project)

  const { data, loading, error } = useQuery<ProjectInfoQuery, IdVariable>(
    PROJECT_INFO_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: projectId,
      },
      onError: onErrorAuto(),
    }
  )

  useEffect(() => {
    onLoading(loading)
  }, [loading, onLoading])

  const [patchProjectMutation] = useMutation<
    PatchProjectMutation,
    PatchProjectMutationVariables
  >(PATCH_PROJECT_MUTATION, {
    refetchQueries: ['ProjectInfo'],
    onCompleted() {
      addToast('success', translations.results.editSuccess)
    },
    onError: onErrorAuto(translations.results.editError),
  })

  const handleChangeStage = useCallback(
    (project: Project, newStage: Stage) => {
      if (!canEditProject) return

      patchProjectMutation({
        variables: {
          id: project.id,
          input: {
            stage: newStage,
          },
        },
      })
    },
    [canEditProject, patchProjectMutation]
  )

  const stageMenu = useCallback(
    (project: Project): TableMenu => ({
      items: (
        [
          ['PLANNING', 'matteTeal'],
          ['ONGOING', 'matteOrange'],
          ['BILLING', 'matteGreen'],
          ['FINISHED', 'inherit'],
        ] as const
      ).map(([stage, color]) => ({
        icon:
          project.stage === stage
            ? { icon: 'check-square', type: 'solid' }
            : { icon: 'square', type: 'light' },
        text: translations.stage[
          stage.toLowerCase() as Lowercase<typeof stage>
        ],
        color,
        onClick: () => handleChangeStage(project, stage),
      })),
    }),
    [handleChangeStage, translations.stage]
  )

  const linkOrNothing = (link: string, pivot?: string | number | null) =>
    !pivot ? '#' : encodeURI(link)

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const customerGoogleMapsURI = linkOrNothing(
    `https://www.google.com/maps/search/?api=1&query=${data?.project.customer.address}, ${data?.project.customer.postalCode} ${data?.project.customer.postalArea}`,
    data?.project.customer.address
  )

  const projectGoogleMapsURI = linkOrNothing(
    `https://www.google.com/maps/search/?api=1&query=${data?.project.address}`,
    data?.project.address
  )

  return (
    <>
      <Wrapper>
        <Collapsible
          area="information"
          title={translations.generalInformation}
          closed={!cardsOpen.includes('information')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('information')}
        >
          <DateStage>
            <span className="date">
              {!!data && format(new Date(data.project.createdAt), 'PPP')}
            </span>

            <div>
              {!!data && (
                <Menu
                  icon={<ProjectStage circle project={data.project} />}
                  config={stageMenu(data.project)}
                />
              )}
            </div>
          </DateStage>

          <Field>
            <h3>{translations.information.projectId}</h3>

            <div>{data?.project.internalId}</div>
          </Field>

          <Field>
            <h3>{translations.address}</h3>

            <a
              href={projectGoogleMapsURI}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.project.address}
            </a>
          </Field>

          <Field>
            <h3>{translations.information.projectLeader}</h3>

            <Link
              to={linkOrNothing(
                `/users/${data?.project.projectLeader?.id}`,
                data?.project.projectLeader?.id
              )}
            >
              {data?.project.projectLeader?.fullName}
            </Link>
          </Field>

          <Field>
            <h3>{translations.information.createdBy}</h3>

            <Link
              to={linkOrNothing(
                `/users/${data?.project.createdBy?.id}`,
                data?.project.createdBy?.id
              )}
            >
              {data?.project.createdBy?.fullName}
            </Link>
          </Field>

          <Description>
            <h3>{translations.information.description}</h3>

            <span className={!data?.project.description ? 'none' : ''}>
              {data?.project.description || translations.none}
            </span>
          </Description>
        </Collapsible>

        <Collapsible
          area="customer"
          title={translations.customer}
          closed={!cardsOpen.includes('customer')}
          noCollapse={!isMobileOnly}
          bordered
          onToggleOpen={() => toggleCard('customer')}
        >
          <Field noBorder>
            <h3>{translations.customerInfo.name}</h3>

            <Link
              to={linkOrNothing(
                `/customers/${data?.project.customer.id}`,
                data?.project.customer.id
              )}
            >
              {data?.project.customer.name}
            </Link>
          </Field>

          <Field>
            <h3>{translations.customerInfo.contactPerson}</h3>

            <div>{data?.project.customer.contactPersonName}</div>
          </Field>

          <Field>
            <h3>{translations.customerInfo.phone}</h3>

            <a
              href={linkOrNothing(
                `tel:${data?.project.customer.contactPersonEmail}`,
                data?.project.customer.contactPersonEmail
              )}
            >
              {data?.project.customer.contactPersonPhone &&
                formatPhoneNumber(data?.project.customer.contactPersonPhone)}
            </a>
          </Field>

          <Field>
            <h3>{translations.customerInfo.email}</h3>

            <a
              href={linkOrNothing(
                `mailto:${data?.project.customer.contactPersonEmail}`,
                data?.project.customer.contactPersonEmail
              )}
            >
              {data?.project.customer.contactPersonEmail}
            </a>
          </Field>

          <Field>
            <h3>{translations.address}</h3>

            <a
              href={customerGoogleMapsURI}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.project.customer.address}
            </a>
          </Field>

          <Field>
            <h3>{translations.customerInfo.postalCode}</h3>

            <a
              href={customerGoogleMapsURI}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.project.customer.postalCode}
            </a>
          </Field>

          <Field>
            <h3>{translations.customerInfo.city}</h3>

            <a
              href={customerGoogleMapsURI}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.project.customer.postalArea}
            </a>
          </Field>

          <Field>
            <h3>{translations.customerInfo.orgnr}</h3>

            <a
              href={linkOrNothing(
                `https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${data?.project.customer.organizationNumber}`,
                data?.project.customer.organizationNumber
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.project.customer.organizationNumber}
            </a>
          </Field>
        </Collapsible>

        <Collapsible
          area="billing"
          title={translations.billing}
          closed={!cardsOpen.includes('billing')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('billing')}
        >
          <Field noBorder>
            <h3>{translations.billingInfo.billed}</h3>

            <div>
              {data?.project.billed ? translations.yes : translations.no}
            </div>
          </Field>

          <Field>
            <h3>{translations.billingInfo.billingType}</h3>

            {!!data && (
              <ProjectBillingType billingType={data.project.billingType} />
            )}
          </Field>

          <Field>
            <h3>{translations.billingInfo.hourlyRate}</h3>

            <div>{(data?.project.hourlyRate ?? 0) + ' kr'}</div>
          </Field>

          <Field>
            <h3>{translations.billingInfo.discount}</h3>

            <div>{data?.project.materialDiscount + ' %'}</div>
          </Field>

          {data?.project.billingType === 'FIXED' && (
            <Field>
              <h3>{translations.billingInfo.estimatedTime}</h3>

              <div>{data?.project.estimatedCompletionTime ?? '-'}</div>
            </Field>
          )}
        </Collapsible>
      </Wrapper>
    </>
  )
}

import { gql } from '@apollo/client'

export const CompanyFragments = {
  info: gql`
    fragment CompanyInfo on CompanyNode {
      id
      shortName
      fullName
      address
      postalCode
      postalArea
      phoneNumber
      email
      companyLogo
    }
  `,
  users: gql`
    fragment CompanyUsers on CompanyNode {
      users {
        edges {
          node {
            id
            username
          }
        }
      }
    }
  `,
  userTypes: gql`
    fragment CompanyUserTypes on CompanyNode {
      userTypes {
        id
        name
      }
    }
  `,
  settings: gql`
    fragment CompanySettings on CompanyNode {
      settings {
        id
        key
        value
      }
    }
  `,
  folderSettings: gql`
    fragment CompanyFolderSettings on CompanyNode {
      folderSettings {
        edges {
          node {
            id
            folderName
            enabled
          }
        }
      }
    }
  `,
}

export const CompanyFolderSettingFragments = {
  info: gql`
    fragment CompanyFolderSettingInfo on CompanyFolderSettingNode {
      id
      folderName
      enabled
    }
  `,
}

export const SELECT_COMPANIES_QUERY = gql`
  query SelectCompanies {
    me {
      id
      companies {
        edges {
          node {
            id
            fullName
            shortName
            companyLogo
          }
        }
      }
    }
  }
`

export const CURRENT_COMPANY_QUERY = gql`
  query CurrentCompany {
    currentCompany {
      ...CompanyInfo
      ...CompanyUserTypes
      ...CompanySettings
      ...CompanyFolderSettings
    }
  }
  ${CompanyFragments.info}
  ${CompanyFragments.userTypes}
  ${CompanyFragments.settings}
  ${CompanyFragments.folderSettings}
`

import { TreeItem } from './types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Branch } from './Branch'
import xor from 'lodash/xor'

const Wrapper = styled.div``

interface TreeProps {
  items: TreeItem[]
  selectedItem?: string | null

  onItemClick: (item: TreeItem) => void
}

export const Tree: React.FC<TreeProps> = ({
  items,
  selectedItem = null,

  onItemClick,
}) => {
  const [openItems, setOpenItems] = useState<string[]>([])

  function handleItemClick(item: TreeItem, evt: React.MouseEvent) {
    evt.stopPropagation()
    setOpenItems(v => xor(v, [item.value]))
    onItemClick(item)
  }

  return (
    <Wrapper>
      {items.map(item => (
        <Branch
          key={item.value}
          item={item}
          level={0}
          openItems={openItems}
          selectedItem={selectedItem}
          onClick={handleItemClick}
        />
      ))}
    </Wrapper>
  )
}

import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { FormField, Input, RegularModal, TextArea } from 'components'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getFileSizeString, getFileType } from 'util/files'
import { validateNonEmpty } from 'util/validation'
import { TableFile } from '../graphql'

const Wrapper = styled.div`
  min-width: 400px;
`
const Details = styled.section`
  & > div {
    display: flex;
    gap: 0.5ch;

    label {
      font-weight: 600;
    }
  }
`
const Form = styled.section`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${props => props.theme.colors.gray6};

  & > div + div {
    margin-top: 0.5rem;
  }
  p {
    margin: 0;

    &.none {
      font-style: italic;
      color: ${props => props.theme.colors.gray3};
    }
  }
`

export interface FileDetailsModalResolve {
  name: string
  description: string
}

interface FileDetailsModalProps {
  file: TableFile
  canEditFile: boolean

  onResolve: PromptResolve<FileDetailsModalResolve | null>
}

export const FileDetailsModal: React.FC<FileDetailsModalProps> = ({
  file,
  canEditFile,

  onResolve,
}) => {
  const translations = useTranslate({
    details: 'common.details',
    close: 'common.close',
    save: 'common.save',

    originalName: 'files.original-name',
    fileType: 'files.file-type',
    fileSize: 'files.file-size',

    name: 'common.name',
    description: 'common.description',
    required: 'common.required',
    none: 'common.none',

    fileTypes: {
      other: 'files.file-types.other',
      image: 'files.file-types.image',
      audio: 'files.file-types.audio',
      video: 'files.file-types.video',
      pdf: 'files.file-types.pdf',
      csv: 'files.file-types.csv',
      word: 'files.file-types.word',
      excel: 'files.file-types.excel',
      powerpoint: 'files.file-types.powerpoint',
      archive: 'files.file-types.archive',
    },
  })

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<FileDetailsModalResolve>({
    values: {
      name: file.name,
      description: file.description,
    },
    validators: {
      name: validateNonEmpty(translations.required),
    },
  })

  const fileType = useMemo(() => {
    const type = getFileType(file.originalName)
    return translations.fileTypes[type]
  }, [file.originalName, translations.fileTypes])

  function handleSubmit(values: typeof form) {
    onResolve(values)
  }

  return (
    <RegularModal
      title={translations.details}
      submitDisabled={!formValid || !formEdited}
      cancelText={translations.close}
      submitText={translations.save}
      onCancel={() => onResolve(null)}
      onSubmit={!canEditFile ? undefined : submit(handleSubmit)}
    >
      <Wrapper>
        <Details>
          <div>
            <label>{translations.originalName}:</label>
            {file.originalName}
          </div>

          <div>
            <label>{translations.fileType}:</label>
            {fileType}
          </div>

          <div>
            <label>{translations.fileSize}:</label>
            {getFileSizeString(file.size)}
          </div>
        </Details>

        <Form>
          <FormField error={!!errors.name}>
            <label>{translations.name}</label>

            {canEditFile ? (
              <Input
                value={form.name}
                error={errors.name}
                autoFocus
                fullWidth
                onChange={handler('name')}
              />
            ) : (
              <p>{file.name}</p>
            )}
          </FormField>

          <FormField>
            <label>{translations.description}</label>

            {canEditFile ? (
              <TextArea
                value={form.description}
                fullWidth
                onChange={handler('description')}
              />
            ) : !!file.description ? (
              <p>{file.description}</p>
            ) : (
              <p className="none">{translations.none}</p>
            )}
          </FormField>
        </Form>
      </Wrapper>
    </RegularModal>
  )
}

import { useGlobal } from '@ur/react-hooks'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Location } from 'history'
import { CrumbOverrides } from 'store'
import isEqual from 'lodash/isEqual'

export function useBreadcrumbOverrides(
  overrides: CrumbOverrides,
  ignoreLocationChange?: string | ((location: Location) => boolean)
) {
  const history = useHistory()
  const [override, setOverrides] = useGlobal('breadcrumbs.overrides')

  useEffect(() => {
    if (isEqual(overrides, override)) return
    setOverrides(overrides)
  }, [override, overrides, setOverrides])

  useEffect(() => {
    const unregister = history.listen(loc => {
      const ignore =
        typeof ignoreLocationChange === 'undefined'
          ? false
          : typeof ignoreLocationChange === 'function'
          ? ignoreLocationChange(loc)
          : loc.pathname.startsWith(ignoreLocationChange)
      if (ignore) return

      setOverrides({})
    })

    return unregister
  }, [history, setOverrides, ignoreLocationChange])
}

import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader,
  Table,
  TableColumn,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import capitalize from 'lodash/capitalize'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  useConfirm,
  useInfiniteScroll,
  useOnErrorAuto,
  usePermission,
  usePermissions,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CreateUserModal, CreateUserModalResolve } from './CreateUserModal'
import { CREATE_USER_MUTATION, PATCH_USER_MUTATION } from './mutations'
import { USERS_TABLE_QUERY } from './queries'
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  PatchUserMutation,
  PatchUserMutationVariables,
  UsersTableQuery,
  UsersTableQueryVariables,
} from './types.graphql'
import { UsersMobile } from './UsersMobile'
import { useUsersPagination } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    text-decoration: none;
  }

  ${props => props.theme.media.desktop} {
    color: ${props => props.theme.colors.gray1};
  }

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

const PAGE_SIZE = 25

interface UsersProps {}

export const Users: React.FC<UsersProps> = () => {
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const translations = useTranslate({
    users: 'common.users',
    createUser: 'users.create-user',
    searchForUser: 'users.search',

    name: 'common.name',
    email: 'common.email',
    phoneNumber: 'common.phone-number',
    address: 'common.address',

    print: 'common.print',
    sendToEmail: 'common.send-to-email',
    edit: 'users.edit-user',
    show: 'common.show-profile',
    delete: 'common.delete',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    prompt: {
      deleteUser: 'users.prompt.delete-user',
      deleteUserTitle: 'users.prompt.delete-user-title',
    },

    results: {
      queryError: 'server.general-error-try-again-later',

      deleteError: 'users.delete-error',
      deleteSuccess: 'users.delete-success',

      uniqueConstraint: 'users.errors.email-must-be-unique',
    },

    toasts: {
      userCreateSuccess: 'users.toasts.create-user-success',
      userCreateError: 'users.errors.create-user',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt()
  const addToast = useToast()
  const confirm = useConfirm()
  const history = useHistory()

  const { hasPermissionsOrMe } = usePermissions(true)
  const canDeleteUser = usePermission(PERMISSIONS.users.delete.user)

  const queryVariables = useMemo(
    () => ({ q: debouncedSearch, orderBy: sort, first: PAGE_SIZE }),
    [debouncedSearch, sort]
  )

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore: fetchMoreUsers,
  } = useQuery<UsersTableQuery, UsersTableQueryVariables>(USERS_TABLE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: queryVariables,
    onError: onErrorAuto(),
  })

  const users = useMemo(
    () => data?.allUsers.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useUsersPagination(data?.allUsers.pageInfo, fetchMoreUsers)

  useInfiniteScroll(handleFetchMore)

  const [createUserMutation, { loading }] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER_MUTATION, {
    refetchQueries: [
      {
        query: USERS_TABLE_QUERY,
        variables: queryVariables,
      },
    ],
    onCompleted() {
      addToast('success', translations.toasts.userCreateSuccess)
      mixpanel.track('Create User')
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueConstraint,
    }),
  })

  const [patchUserMutation, { loading: patchLoading }] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    refetchQueries: [
      {
        query: USERS_TABLE_QUERY,
        variables: queryVariables,
      },
    ],
    onCompleted() {
      addToast('success', translations.results.deleteSuccess)
      mixpanel.track('Edit User')
    },
    onError: onErrorAuto(translations.results.deleteError),
  })

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'name',
        label: translations.name,
        sortable: true,
      },
      {
        id: 'email',
        label: translations.email,
        sortable: true,
      },
      {
        id: 'phoneNumber',
        label: translations.phoneNumber,
      },
      {
        id: 'address',
        label: translations.address,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [
      translations.address,
      translations.email,
      translations.name,
      translations.phoneNumber,
    ]
  )

  const handleDeleteUser = useCallback(
    async (userId: string) => {
      if (!canDeleteUser) return

      const answer = await confirm(
        translations.prompt.deleteUser,
        translations.prompt.deleteUserTitle
      )

      if (answer) {
        patchUserMutation({
          variables: {
            id: userId,
            input: {
              isActive: false,
            },
          },
        })
      }
    },
    [
      canDeleteUser,
      confirm,
      patchUserMutation,
      translations.prompt.deleteUser,
      translations.prompt.deleteUserTitle,
    ]
  )

  const makeEditDeleteMenu = useCallback(
    (userId: string) => {
      const items: TableMenu = {
        items: [
          {
            icon: 'file',
            text: translations.show,
            onClick: () => history.push(`/users/${userId}`),
          },
          {
            icon: 'edit',
            text: translations.edit,
            hide: !hasPermissionsOrMe(
              { id: userId },
              PERMISSIONS.users.change.user
            ),
            onClick: () => history.push(`/users/${userId}/edit`),
          },
          {
            icon: 'trash',
            text: translations.delete,
            color: '#f39b9b',
            hide: !canDeleteUser,
            hoverColor: '#e38080',
            onClick: () => handleDeleteUser(userId),
          },
        ],
      }

      return items
    },
    [
      canDeleteUser,
      handleDeleteUser,
      hasPermissionsOrMe,
      history,
      translations.delete,
      translations.edit,
      translations.show,
    ]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      users.map(user => {
        return {
          name: {
            content: <Link to={`/users/${user.id}`}>{user.fullName}</Link>,
          },
          email: {
            content: !!user.email && (
              <a href={`mailto:${user.email}`}>{user.email}</a>
            ),
          },
          phoneNumber: {
            content: !!user.phoneNumber && (
              <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
            ),
          },
          address: {
            content: user.address
              ? user.address + ', ' + capitalize(user.city)
              : '',
          },
          menu: { content: '', menu: makeEditDeleteMenu(user.id) },
        }
      }) ?? [],
    [users, makeEditDeleteMenu]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          fontWeight: cell.columnId === 'name' ? 600 : 'inherit',
        },
      }),
    }),
    []
  )

  async function handleCreateUser() {
    const { data } = await addPrompt<CreateUserModalResolve | null>(resolve => (
      <CreateUserModal onSubmit={resolve} />
    ))
    if (!data) return

    createUserMutation({
      variables: data,
    })
  }

  const isLoading = loading || queryLoading || fetchMoreLoading || patchLoading

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {isMobileOnly ? (
        <UsersMobile
          users={users}
          search={search}
          makeEditDeleteMenu={makeEditDeleteMenu}
          onSearchChange={setSearch}
          onCreateUser={handleCreateUser}
        />
      ) : (
        <>
          <PageHeader
            title={translations.users}
            loading={isLoading}
            search={{
              value: search,
              placeholder: translations.searchForUser,
              onChange: setSearch,
            }}
            buttons={[
              {
                text: translations.createUser,
                icon: 'plus',
                permissions: PERMISSIONS.users.add.user,
                onClick: handleCreateUser,
              },
            ]}
          />

          <Table
            columns={columns}
            rows={rows}
            options={tableOptions}
            noRowsText={
              !!debouncedSearch ? translations.noResults : translations.noData
            }
            onSortingChange={setSort}
          />
        </>
      )}

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </Wrapper>
  )
}

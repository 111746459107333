import { useMutation } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  FilePicker,
  FilePickerFile,
  FilePickerOnChangeValue,
  FilePickerRef,
  FormField,
} from 'components'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { IdsVariable } from 'types/graphql'
import { Single } from 'types/util'
import { useOnErrorAuto } from 'util/hooks'
import { isStringArray } from 'util/typecheck'
import {
  BATCH_CREATE_CHECKLIST_ITEM_IMAGE_MUTATION,
  BATCH_DELETE_CHECKLIST_ITEM_IMAGE_MUTATION,
} from '../mutations'
import {
  BatchCreateChecklistItemImageMutation,
  BatchCreateChecklistItemImageMutationVariables,
  BatchDeleteChecklistItemImageMutation,
  ChecklistItemImage,
  ShallowChecklistItem,
} from '../types.graphql'

const Wrapper = styled(FormField)`
  margin: 0 3rem 1rem;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  ${props => props.theme.media.mobile} {
    margin: 0 0.5rem 0.5rem;

    label {
      margin: 0 0.5rem 0.5rem;
    }
  }
`
const SubmitButton = styled.div.attrs({
  role: 'button',
})`
  display: inline-block;
  padding: 4px 6px;

  border: 0;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`

interface ImagesForm {
  add: BatchCreateChecklistItemImageMutationVariables['input']
  remove: string[]
}

interface ChecklistItemImagesProps {
  item: ShallowChecklistItem
}

export const ChecklistItemImages: React.FC<ChecklistItemImagesProps> = ({
  item,
}) => {
  const translations = useTranslate({
    images: 'common.images',
    save: 'common.save',
  })

  const filePickerRef = useRef<FilePickerRef>(null)

  const onErrorAuto = useOnErrorAuto()

  const initialImages = useRef(
    item.images.edges.map<FilePickerFile>(({ node: image }) => ({
      id: image.id,
      name: image.title,
      file: image.image,
      thumbnail: image.imageCompressed ?? image.image,
    }))
  )

  const {
    formValues: form,
    formEdited,
    updateForm,
    updateInitialValues,
    submitHandler: submit,
  } = useForm<ImagesForm>({
    values: {
      add: [],
      remove: [],
    },
  })

  const [uploadImages, { loading: uploadLoading }] = useMutation<
    BatchCreateChecklistItemImageMutation,
    BatchCreateChecklistItemImageMutationVariables
  >(BATCH_CREATE_CHECKLIST_ITEM_IMAGE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onCompleted(data) {
      updateForm({ add: [], remove: [] })
      updateInitialValues({ add: [], remove: [] })

      resetPicker(data.batchCreateChecklistItemImage.checklistItemImages)
    },
    onError: onErrorAuto(),
  })
  const [deleteImages, { loading: deleteLoading }] = useMutation<
    BatchDeleteChecklistItemImageMutation,
    IdsVariable
  >(BATCH_DELETE_CHECKLIST_ITEM_IMAGE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onCompleted(data) {
      updateForm({ add: [], remove: [] })
      updateInitialValues({ add: [], remove: [] })

      resetPicker(data.batchDeleteChecklistItemImage.deletedIds)
    },
    onError: onErrorAuto(),
  })

  function resetPicker(images: ChecklistItemImage[]): void
  function resetPicker(images: string[]): void
  function resetPicker(images: ChecklistItemImage[] | string[]) {
    if (!images.length) return

    let newImages: FilePickerFile[] = []
    if (isStringArray(images)) {
      newImages = initialImages.current.filter(
        image => !!image.id && !images.includes(image.id)
      )
    } else {
      newImages = [
        ...initialImages.current,
        ...images.map(image => ({
          id: image.id,
          name: image.title,
          file: image.image,
        })),
      ]
    }

    initialImages.current = newImages
    filePickerRef.current?.updateInternalValue({
      new: [],
      existing: newImages,
      deleted: [],
    })
  }

  function handleFilePickerChange(value: FilePickerOnChangeValue) {
    const add = value.new.map<Single<typeof form['add']>>(file => ({
      checklistItem: item.id,
      image: file.file,
      title: file.name,
    }))
    const remove = value.deleted.reduce<string[]>(
      (acc, cur) => (typeof cur.id === 'undefined' ? acc : [...acc, cur.id]),
      []
    )

    updateForm({
      add,
      remove,
    })
  }

  async function handleSubmit(values: ImagesForm) {
    !!values.add.length &&
      (await uploadImages({ variables: { input: values.add } }))
    !!values.remove.length &&
      (await deleteImages({ variables: { ids: values.remove } }))
  }

  const isLoading = useMemo(
    () => uploadLoading || deleteLoading,
    [deleteLoading, uploadLoading]
  )

  return (
    <Wrapper>
      <label>
        {translations.images}

        {isLoading && <Loader.Spinner size={14} thickness={2} />}

        {!isLoading && formEdited && (
          <SubmitButton onClick={submit(handleSubmit)}>
            {translations.save}
          </SubmitButton>
        )}
      </label>

      <FilePicker
        ref={filePickerRef}
        initialFiles={initialImages.current}
        images
        onChange={handleFilePickerChange}
      />
    </Wrapper>
  )
}

import { gql } from '@apollo/client'

export const ADMIN_CREATE_COMPANY_MUTATION = gql`
  mutation AdminCreateCompany($name: String!) {
    adminCreateCompany(name: $name) {
      company {
        id
        fullName
      }
    }
  }
`

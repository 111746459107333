import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ProjectStage } from 'modules/projects/types.graphql'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ShallowCustomerProject } from '../types.graphql'
import { CustomerElementCard } from './CustomerElementCard'

const ProjectList = styled.div.attrs({
  role: 'list',
})`
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: ${props => props.theme.colors.gray1};

    span.no-projects {
      font-style: italic;
      color: ${props => props.theme.colors.gray3};
    }
  }

  ${props => props.theme.media.mobile} {
    & > div {
      padding: 1rem;
    }
  }
`

interface ProjectCardProps {
  stage: ProjectStage
  projects: ShallowCustomerProject[]
  closed?: boolean

  onToggleOpen?: () => void
}

export const CustomerProjectCard: React.FC<ProjectCardProps> = ({
  stage,
  projects,
  closed = false,

  onToggleOpen = () => void 0,
}) => {
  const translations = useTranslate({
    noProjects: 'projects.no-projects',

    PLANNING: 'projects.stage-planning',
    ONGOING: 'projects.stage-ongoing',
    BILLING: 'projects.stage-billing',
    FINISHED: 'projects.stage-finished',
  })

  const colors = {
    PLANNING: 'matteTeal',
    ONGOING: 'matteOrange',
    BILLING: 'matteGreen',
    FINISHED: '#333333',
  }

  const history = useHistory()

  const stageProjects = useMemo(
    () => projects.filter(project => project.stage === stage),
    [projects, stage]
  )

  return (
    <CustomerElementCard
      area={stage}
      title={translations[stage]}
      headerColor={colors[stage]}
      closed={closed}
      onToggleOpen={onToggleOpen}
    >
      {!closed && (
        <ProjectList>
          {!stageProjects.length ? (
            <div>
              <span className="no-projects">{translations.noProjects}</span>
            </div>
          ) : (
            stageProjects.map(project => (
              <div key={project.id}>
                <span>{project.name}</span>

                <Icon
                  icon="external-link"
                  type="solid"
                  cursor="pointer"
                  hoverColor="primaryHover"
                  onClick={() => history.push(`/projects/${project.id}`)}
                />
              </div>
            ))
          )}
        </ProjectList>
      )}
    </CustomerElementCard>
  )
}

import { Thumbnail } from '@ur/react-components'
import { UserNode } from 'modules/users'
import React from 'react'
import styled from 'styled-components'
import { CSSCursor } from '@ur/react-components/build/types/css'
import { overloadColorProp } from 'util/style'
import { useHistory } from 'react-router-dom'

interface WrapperProps {
  size: string
}
const Wrapper = styled.div<WrapperProps>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  background: white;
`

interface CircleProps {
  textColor?: string
  color?: string
  size?: number
  inline?: boolean
  border?: string
  fontSize?: string
  fontWeight?: string | number
  cursor?: CSSCursor
}
const Circle = styled.div<CircleProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;

  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => (props.textColor ? props.textColor : 'white')};
  background-color: ${overloadColorProp};
  cursor: ${props => props.cursor};

  ${props =>
    props.border &&
    `
    border: ${props.border};
  `}
  border-radius: ${props => props.size}px;
`

export type ThumbnailUser = Pick<
  UserNode,
  'id' | 'fullName' | 'profilePicture' | 'initials'
>

interface UserThumbOrInitialsProps {
  className?: string
  user: ThumbnailUser

  size?: number
  cursor?: CSSCursor
  fontSize?: string
  fontWeight?: string | number
  color?: string
  backgroundColor?: string
  title?: string
  link?: boolean | string

  onClick?: (event?: React.MouseEvent) => void
}

export const UserThumbOrInitials: React.FC<UserThumbOrInitialsProps> = ({
  className,

  user,

  size = 35,
  cursor = 'default',
  fontSize,
  fontWeight,
  color,
  backgroundColor = 'primary',
  title,
  link = false,

  onClick,
}) => {
  const history = useHistory()

  function handleClick(evt: React.MouseEvent) {
    onClick?.(evt)
    if (evt.defaultPrevented || !link) return

    history.push(typeof link === 'string' ? link : `/users/${user.id}`)
  }

  const pxSize = `${size}px`

  return (
    <Wrapper
      className={className}
      size={pxSize}
      title={title ?? user.fullName ?? undefined}
    >
      {user.profilePicture ? (
        <Thumbnail
          size={pxSize}
          src={user.profilePicture}
          cursor={cursor}
          onClick={handleClick}
        />
      ) : (
        <Circle
          color={backgroundColor}
          size={size}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textColor={color}
          cursor={cursor}
          onClick={handleClick}
        >
          {user.initials}
        </Circle>
      )}
    </Wrapper>
  )
}

import { Icon } from '@ur/react-components'
import {
  ActionButton,
  PageHeaderUserSelectOptions,
  UserTypeSelect,
} from 'components'
import { Permission, UserType } from 'modules/users'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { AdminWarning } from '..'
import { SpecialUserTypePermissions } from '../SpecialUserTypePermissions'
import { PermissionRow, PermissionsLoading, PermissionStatus } from '../types'
import { UserTypePermissionCard } from './UserTypePermissionCard'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 6rem;

  div.user-type-select {
    display: grid;
    grid-template-columns: auto 56px;
    padding: 0 1rem;
  }
  div.no-data {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`

const AddUserType = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;

  border: 1px solid ${props => props.theme.colors.gray5};
  border-left: 0px;
  border-radius: 0 8px 8px 0;

  background-color: ${props => props.theme.colors.gray7};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`

interface UserTypesMobileProps {
  permissions: PermissionRow[]
  userTypeSelect: PageHeaderUserSelectOptions
  isAdmin: boolean
  loading: boolean
  translations: {
    loading: string
    administratorWarning: string

    view: string
    add: string
    edit: string
    delete: string

    results: {
      noData: string
    }
  }

  selectedUserType: UserType | null
  allPermissions: Permission[]
  permissionsLoading: PermissionsLoading

  onTogglePermission: (
    permission: Pick<PermissionStatus, 'id' | 'checked'>
  ) => void
}

export const UserTypesMobile: React.FC<UserTypesMobileProps> = ({
  permissions,
  userTypeSelect,
  isAdmin,
  loading,
  translations,

  selectedUserType,
  allPermissions,
  permissionsLoading,

  onTogglePermission,
}) => {
  const canCreateUserType = usePermission(PERMISSIONS.users.add.usertype)

  const history = useHistory()

  return (
    <Wrapper>
      {canCreateUserType && (
        <ActionButton
          onClick={() => history.push(`/settings/company/user-types/create`)}
        />
      )}

      <div className="user-type-select">
        <UserTypeSelect
          height="56px"
          fullWidth
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          {...userTypeSelect}
        />

        <AddUserType
          role="link"
          icon="pencil-alt"
          size="14px"
          type="solid"
          color="gray3"
          onClick={() =>
            history.push(
              `/settings/company/user-types/${userTypeSelect.value}/edit`
            )
          }
        />
      </div>

      {isAdmin && (
        <AdminWarning>
          <Icon
            icon="exclamation-circle"
            type="solid"
            size="1.7rem"
            color="matteOrange"
          />

          <p>{translations.administratorWarning}</p>
        </AdminWarning>
      )}

      {!!selectedUserType && (
        <SpecialUserTypePermissions
          userType={selectedUserType}
          allPermissions={allPermissions}
          permissionsLoading={permissionsLoading}
          onTogglePermission={onTogglePermission}
        />
      )}

      {permissions.map(perm => (
        <UserTypePermissionCard
          key={`${perm.module}${perm.segment}`}
          permission={perm}
          translations={translations}
        />
      ))}

      {!permissions.length && (
        <div className="no-data">
          {loading ? translations.loading : translations.results.noData}
        </div>
      )}
    </Wrapper>
  )
}

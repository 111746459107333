import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ProjectBillingType as BillingType } from './types.graphql'

const Wrapper = styled.div``

interface ProjectBillingTypeProps {
  billingType: BillingType
}

export const ProjectBillingType: React.FC<ProjectBillingTypeProps> = ({
  billingType,
}) => {
  const translations = useTranslate({
    fixed: 'projects.billing-type.fixed',
    hourly: 'projects.billing-type.hourly',
    internal: 'projects.billing-type.internal',
  })

  const translatedBillingType = useMemo(
    () => translations[billingType.toLowerCase() as Lowercase<BillingType>],
    [billingType, translations]
  )
  return <Wrapper>{translatedBillingType}</Wrapper>
}

import { useQuery } from '@apollo/client'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader,
  TableFiltering,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import xor from 'lodash/xor'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { DeviationsTable, DeviationStatusType } from './components'
import { MobileDeviations } from './mobile/MobileDeviations'
import {
  ALL_CATEGORIES_QUERY,
  INTERNAL_DEVIATIONS_SHALLOW_QUERY,
} from './queries'
import {
  AllCategoriesQuery,
  InternalDeviationsShallowQuery,
  InternalDeviationsShallowQueryVariables,
  ShallowDeviation,
} from './types.graphql'
import {
  useCreateDeviation,
  useDeleteDeviation,
  useDeviationMenu,
  useEditDeviation,
  useInternalDeviationsPagination,
} from './util'

const Wrapper = styled.div``

const PAGE_SIZE = 25

interface InternalDeviationsProps {}

export const InternalDeviations: React.FC<InternalDeviationsProps> = () => {
  const translations = useTranslate({
    createDeviation: 'deviations.create-deviation',
    delete: 'common.delete',
    edit: 'common.edit',
    search: 'deviations.search',
    openDeviation: 'deviations.open-deviation',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [selectedCategories, setSelectedCategories] = useState<string[]>([])

  const [selectedStatuses, setSelectedStatuses] = useState<
    DeviationStatusType[]
  >(['underTreatment'])

  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreInternalDeviations,
  } = useQuery<
    InternalDeviationsShallowQuery,
    InternalDeviationsShallowQueryVariables
  >(INTERNAL_DEVIATIONS_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      categories: selectedCategories,
      orderBy: sort,
      status: selectedStatuses,
    },
    onCompleted() {
      setHasLoadedOnce(true)
    },
    onError: onErrorAuto(translations.results.queryError),
  })

  const deviations = useMemo(
    () => data?.allInternalDeviations.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const hasInternalDeviations = useMemo(
    () => data?.hasInternalDeviations,
    [data]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useInternalDeviationsPagination(
    data?.allInternalDeviations.pageInfo,
    fetchMoreInternalDeviations
  )

  useInfiniteScroll(handleFetchMore)

  const {
    createDeviation: handleCreateDeviation,
    loading: createDeviationLoading,
  } = useCreateDeviation()

  const { patchDeviation: handleEditDeviation, loading: editDeviationLoading } =
    useEditDeviation()

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery<AllCategoriesQuery>(ALL_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const categories = useMemo(
    () =>
      categoriesData?.allDeviationCategories.edges.map(({ node }) => node) ??
      [],
    [categoriesData]
  )

  const {
    deleteDeviation: handleDeleteDeviation,
    loading: deleteDeviationLoading,
  } = useDeleteDeviation(['InternalDeviationsShallow'])

  function handleFilterChange(value: TableFiltering) {
    const first = value.checked[0]

    value.column === 'status'
      ? setSelectedStatuses(value.checked as DeviationStatusType[])
      : setSelectedCategories(xor(first, value.checked))
  }

  const makeMenu = useDeviationMenu(handleDeleteDeviation, {
    open: deviation => `/deviations/internal/${deviation.id}`,
    edit: deviation =>
      isMobileOnly
        ? handleEditDeviation({
            id: deviation.id,
          })
        : `/deviations/internal/${deviation.id}/edit`,
  })

  if (error || categoriesError)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading =
    loading ||
    categoriesLoading ||
    createDeviationLoading ||
    editDeviationLoading ||
    deleteDeviationLoading ||
    fetchMoreLoading

  return (
    <EmptyPage
      module="internalDeviations"
      empty={!hasInternalDeviations}
      loading={!hasLoadedOnce}
      isTabView
      buttonPermissions={[
        PERMISSIONS.deviations.add.deviation,
        PERMISSIONS.projects.view.project,
      ]}
      onButtonClick={() => handleCreateDeviation()}
    >
      <Wrapper>
        {isMobileOnly ? (
          <MobileDeviations
            deviations={deviations as ShallowDeviation[]}
            categories={categories}
            isInternal
            search={search}
            makeMenu={makeMenu}
            onCreateNewDeviation={() => handleCreateDeviation()}
            onSearchChange={setSearch}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <>
            <PageHeader
              loading={isLoading}
              tabMargin
              search={{
                value: search,
                placeholder: translations.search,
                left: true,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.createDeviation,
                  icon: 'plus',
                  permissions: [
                    PERMISSIONS.deviations.add.deviation,
                    PERMISSIONS.projects.view.project,
                  ],
                  onClick: () => {
                    history.push(`/deviations/internal/create/`)
                  },
                },
              ]}
            />

            <DeviationsTable
              deviations={deviations}
              categories={categories}
              search={debouncedSearch}
              excludeColumns={['customerName', 'projectName']}
              makeMenu={makeMenu}
              onFilterChange={handleFilterChange}
              onSortingChange={setSort}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

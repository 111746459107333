import { useQuery } from '@apollo/client'
import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  DropArea,
  Table,
  TableColumn,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { FileCell, FolderCell } from '.'
import {
  ChecklistFilesCountsQuery,
  ChecklistFilesCountsQueryVariables,
  ChecklistFolder,
  ChecklistImage,
  CHECKLIST_FILES_COUNTS_QUERY,
  TableFile,
  TableFolder,
} from '../graphql'

interface FolderTableProps {
  projectId: string
  folders: TableFolder[]
  files: TableFile[]
  checklists: ChecklistFolder[]
  checklistImages: ChecklistImage[]

  sort: string
  isSearch: boolean
  withChecklistFiles: boolean
  isChecklistFolders: boolean
  isChecklistImages: boolean
  disableDrop: boolean
  makeFolderTableMenu: (folder: TableFolder) => TableMenu
  makeFileTableMenu: (file: TableFile) => TableMenu

  onDrop?: (files: File[]) => void
  onSortChange: (sort: string) => void
  onResetSearch: () => void
  onImageClick: (id: string) => void
}

export const FolderTable: React.FC<FolderTableProps> = ({
  projectId,
  folders,
  files,
  checklists,
  checklistImages,

  sort,
  isSearch,
  withChecklistFiles,
  isChecklistFolders,
  isChecklistImages,
  disableDrop,
  makeFolderTableMenu,
  makeFileTableMenu,

  onDrop,
  onSortChange,
  onResetSearch,
  onImageClick,
}) => {
  const translations = useTranslate({
    search: 'files.search-for-files',
    newFolder: 'files.new-folder',
    folderIsEmpty: 'files.folder-empty',
    noResults: 'common.no-results',

    checklistImages: 'common.checklist-images',

    columns: {
      name: 'common.name',
      purpose: 'common.purpose',
      item: 'common.item',
      updatedAt: 'common.last-updated',
      numberOfFolders: 'files.number-of-folders',
      numberOfFiles: 'files.number-of-files',
    },

    errors: {
      query: 'files.errors.query-root-folders',
    },
  })

  const theme = useTheme()
  const { format } = useDateFns()

  const { data: checklistCounts } = useQuery<
    ChecklistFilesCountsQuery,
    ChecklistFilesCountsQueryVariables
  >(CHECKLIST_FILES_COUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !withChecklistFiles,
    variables: {
      projectId,
    },
  })

  function handleDrop(evt: React.DragEvent) {
    onDrop?.(Array.from(evt.dataTransfer.files))
  }

  const checklistStats = useMemo(
    () => ({
      checklists: checklistCounts?.counts.checklists.hasImages ?? 0,
      checklistImages: checklistCounts?.counts.checklists.images ?? 0,
      lastUpdatedImage: !!checklistCounts?.counts.checklists.lastUpdatedImage
        ? new Date(checklistCounts.counts.checklists.lastUpdatedImage)
        : null,
    }),
    [checklistCounts]
  )

  const columns = useMemo<TableColumn[]>(() => {
    const cols: TableColumn[] = [
      {
        id: 'name',
        label: translations.columns.name,
        sortable: true,
      },
      {
        id: 'purpose',
        label: translations.columns.purpose,
        skip: !isChecklistFolders,
      },
      {
        id: 'item',
        label: translations.columns.item,
        skip: !isChecklistImages,
      },
      {
        id: 'updatedAt',
        label: translations.columns.updatedAt,
        shrink: isChecklistImages,
        sortable: true,
      },
      {
        id: 'numFolders',
        label: translations.columns.numberOfFolders,
        alignHorizontal: 'center',
        sortable: true,
        skip: isChecklistFolders || isChecklistImages,
      },
      {
        id: 'numFiles',
        label: translations.columns.numberOfFiles,
        alignHorizontal: 'center',
        sortable: !isChecklistFolders,
        skip: isChecklistImages,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
        skip: isChecklistImages,
      },
    ]

    return cols
  }, [
    isChecklistFolders,
    isChecklistImages,
    translations.columns.item,
    translations.columns.name,
    translations.columns.numberOfFiles,
    translations.columns.numberOfFolders,
    translations.columns.purpose,
    translations.columns.updatedAt,
  ])

  const rows = useMemo<TableRow[]>(() => {
    const folderRows = isChecklistFolders
      ? []
      : folders.map<TableRow>(folder => ({
          name: {
            content: (
              <FolderCell
                projectId={projectId}
                folder={folder}
                onClick={() => onResetSearch()}
              />
            ),
          },
          updatedAt: {
            content: format(new Date(folder.updatedAt), 'PP'),
          },
          numFolders: {
            content: folder.numberOfFolders + '',
            alignHorizontal: 'center',
          },
          numFiles: {
            content: folder.numberOfFiles + '',
            alignHorizontal: 'center',
          },
          menu: {
            content: '',
            menu: makeFolderTableMenu(folder),
          },
        }))

    const fileRows = isChecklistFolders
      ? []
      : files.map<TableRow>(file => ({
          name: {
            content: <FileCell file={file} onImageClick={onImageClick} />,
          },
          updatedAt: {
            content: format(new Date(file.updatedAt), 'PP'),
          },
          numFolders: {
            content: '',
            alignHorizontal: 'center',
          },
          numFiles: {
            content: '',
            alignHorizontal: 'center',
          },
          menu: {
            content: '',
            menu: makeFileTableMenu(file),
          },
        }))

    if (withChecklistFiles)
      folderRows.unshift({
        name: {
          content: (
            <FolderCell
              projectId={projectId}
              folder={{ id: 'checklists', name: translations.checklistImages }}
              checklistImages
              onClick={() => onResetSearch()}
            />
          ),
        },
        updatedAt: {
          content: !!checklistStats.lastUpdatedImage
            ? format(checklistStats.lastUpdatedImage, 'PP')
            : '',
        },
        numFolders: {
          content: checklistStats.checklists + '',
          alignHorizontal: 'center',
        },
        numFiles: {
          content: checklistStats.checklistImages + '',
          alignHorizontal: 'center',
        },
        menu: {
          content: '',
        },
      })

    const checklistRows = !isChecklistFolders
      ? []
      : checklists.map<TableRow>(checklist => ({
          name: {
            content: (
              <FolderCell
                projectId={projectId}
                folder={{ id: checklist.id, name: checklist.title }}
                checklist
                onClick={() => onResetSearch()}
              />
            ),
          },
          purpose: {
            content: checklist.purpose,
          },
          updatedAt: {
            content: !!checklist.lastUpdatedImage
              ? format(new Date(checklist.lastUpdatedImage), 'PP')
              : '',
          },
          numFiles: {
            content: checklist.imageCount + '',
            alignHorizontal: 'center',
          },
          menu: {
            content: (
              <Link to={`/projects/${projectId}/checklists/${checklist.id}`}>
                <Icon
                  icon="external-link"
                  color="gray3"
                  hoverColor="primaryHover"
                  cursor="pointer"
                />
              </Link>
            ),
          },
        }))

    const checklistImageRows = !isChecklistImages
      ? []
      : checklistImages.map<TableRow>(image => ({
          name: {
            content: (
              <FileCell
                file={{
                  id: image.id,
                  name: image.title,
                  originalName: image.originalName,
                  url: image.image,
                }}
                onImageClick={onImageClick}
              />
            ),
          },
          item: {
            content: image.checklistItem.title,
          },
          updatedAt: {
            content: format(new Date(image.updatedAt), 'PP'),
          },
        }))

    return [...folderRows, ...fileRows, ...checklistRows, ...checklistImageRows]
  }, [
    checklistImages,
    checklistStats.checklistImages,
    checklistStats.checklists,
    checklistStats.lastUpdatedImage,
    checklists,
    files,
    folders,
    format,
    isChecklistFolders,
    isChecklistImages,
    makeFileTableMenu,
    makeFolderTableMenu,
    onImageClick,
    onResetSearch,
    projectId,
    translations.checklistImages,
    withChecklistFiles,
  ])

  const tableOptions = useMemo<TableOptions>(
    () => ({
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color: cell.columnId === 'updatedAt' ? theme.colors.gray3 : undefined,
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <DropArea disabled={disableDrop} onDrop={handleDrop}>
      <Table
        columns={columns}
        rows={rows}
        options={tableOptions}
        initialSort={sort}
        noRowsText={
          isSearch ? translations.noResults : translations.folderIsEmpty
        }
        onSortingChange={onSortChange}
      />
    </DropArea>
  )
}

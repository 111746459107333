import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { overloadColor } from 'util/style'
import { SlideMenuItem } from '.'

interface ItemProps {
  color?: string
  hoverColor?: string
  background?: string
  hoverBackground?: string
}
const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;

  white-space: nowrap;
  user-select: none;
  cursor: pointer;

  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;

  color: ${props => overloadColor(props.color ?? 'inherit')};
  background: ${props => overloadColor(props.background ?? 'inherit')};

  &:hover {
    color: ${props =>
      overloadColor(props.hoverColor ?? props.color ?? 'inherit')};
    background: ${props =>
      overloadColor(props.hoverBackground ?? props.background ?? 'inherit')};
  }
  &:active {
    filter: brightness(1.1);
  }
`

interface MenuItemProps {
  item: SlideMenuItem
  onClick: () => void
}

export const MenuItem: React.FC<MenuItemProps> = ({ item, onClick }) => {
  const history = useHistory()

  function handleClick(item: SlideMenuItem) {
    return (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()

      item.onClick?.(item, evt)
      if (evt.defaultPrevented) return

      onClick()

      if (!!item.href) history.push(item.href)
    }
  }

  return (
    <Item
      role={item.role ?? !!item.href ? 'link' : 'button'}
      color={item.color}
      hoverColor={item.hoverColor}
      background={item.background}
      hoverBackground={item.hoverBackground}
      onClick={handleClick(item)}
    >
      {item.text}
    </Item>
  )
}

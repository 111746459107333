import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { Card as BaseCard, CenteredLoader, Message } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { EntryForm, EntryFormSubmitData } from './components'
import { CREATE_MANUAL_ENTRY_MUTATION } from './mutations'
import {
  CreateManualEntryMutation,
  CreateManualEntryMutationVariables,
} from './types.graphql'
import { useFolderId } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  h1 {
    margin-left: 0.25rem;

    font-size: 1.2rem;
    font-weight: 600;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
const Card = styled(BaseCard)`
  max-width: 600px;

  ${props => props.theme.media.mobile} {
    width: 100vw;
    max-width: auto;
    padding: 1rem 1rem 6rem;

    border-radius: 0;
  }
`

interface CreateManualEntryProps {}

export const CreateManualEntry: React.FC<CreateManualEntryProps> = () => {
  const translations = useTranslate({
    newEntry: 'handbook.new-entry',

    results: {
      created: 'handbook.toasts.entry-created',

      entryNotFound: 'handbook.errors.folder-not-found',
      couldNotCreate: 'handbook.errors.create-entry',
    },
  })

  const { folderSlug } = useParams<{ folderSlug: string }>()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const { folderId, error: folderIdError } = useFolderId(folderSlug)

  const [createEntry, { loading }] = useMutation<
    CreateManualEntryMutation,
    CreateManualEntryMutationVariables
  >(CREATE_MANUAL_ENTRY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ManualFolderBySlug'],
    onCompleted() {
      addToast('success', translations.results.created)
      history.push(`/handbook/${folderSlug}`)
    },
    onError: onErrorAuto(translations.results.couldNotCreate),
  })

  function handleSubmit(form: EntryFormSubmitData) {
    if (!folderId) return

    createEntry({
      variables: {
        input: {
          folder: folderId,
          name: form.name,
          description: form.description,
          richtext: form.richtext,
          attachmentsAdd: form.attachmentsAdd,
        },
      },
    })
  }

  if (!!folderIdError)
    return (
      <Message.Error show centered text={translations.results.entryNotFound} />
    )

  return (
    <Wrapper>
      {!isMobileOnly && <h1>{translations.newEntry}</h1>}

      <Card>
        {!folderId ? (
          <CenteredLoader top="0" />
        ) : (
          <EntryForm
            folderId={folderId}
            loading={loading}
            onCancel={() => history.push(`/handbook/${folderSlug}`)}
            onSubmit={handleSubmit}
          />
        )}
      </Card>
    </Wrapper>
  )
}

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Table,
  TableColumn,
  TableOptions,
  TableRow,
  UserThumbOrInitials,
} from 'components'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useDateFns, usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { FolderPath } from './components/FolderPath'
import { ShallowManualEntry } from './types.graphql'
import { useEntryUtils } from './util'

const UserCell = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400 !important;
`
const None = styled.span`
  font-style: italic;
  color: ${props => props.theme.colors.gray3};
`

interface ManualEntriesTableProps {
  entries: ShallowManualEntry[]

  noShift?: boolean
  showPath?: boolean

  onResetSearch?: () => void
}

export const ManualEntriesTable: React.FC<ManualEntriesTableProps> = ({
  entries,

  noShift = false,
  showPath = false,

  onResetSearch = () => void 0,
}) => {
  const translations = useTranslate({
    title: 'common.title',
    path: 'common.path',
    description: 'common.description',
    lastUpdated: 'common.last-updated',
    user: 'common.user',

    none: 'common.none',
    noData: 'common.no-data',
    noResults: 'common.no-results',
  })

  const theme = useTheme()
  const { format } = useDateFns()
  const canShiftManualEntry = usePermission(
    PERMISSIONS.companymanuals.change.manualentry
  )
  const { makeEditDeleteMenu, makeOrderArrows } = useEntryUtils(entries)

  const columns = useMemo<TableColumn[]>(() => {
    let columns = [
      {
        id: 'name',
        label: translations.title,
        shrink: true,
      },
      {
        id: 'path',
        label: translations.path,
        shrink: true,
        skip: !showPath,
      },
      {
        id: 'description',
        label: translations.description || <None>{translations.none}</None>,
      },
      {
        id: 'user',
        label: translations.user,
        shrink: true,
      },
      {
        id: 'updatedAt',
        label: translations.lastUpdated,
        shrink: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
      {
        id: 'index',
        label: '',
        shrink: true,
        skip: noShift || !canShiftManualEntry,
      },
    ]

    return columns
  }, [
    translations.title,
    translations.path,
    translations.description,
    translations.none,
    translations.user,
    translations.lastUpdated,
    showPath,
    noShift,
    canShiftManualEntry,
  ])

  const rows = useMemo<TableRow[]>(
    () =>
      entries.map((entry, index) => {
        const date = format(new Date(entry.updatedAt), 'PPP')

        const row: TableRow = {
          name: {
            content: (
              <Link to={`/handbook/${entry.folder.slug}/${entry.slug}`}>
                <Icon icon="file" type="solid" margin="0 0.5rem 0 0" />

                {entry.name}
              </Link>
            ),
          },
          path: {
            content: (
              <FolderPath
                folders={entry.path ?? []}
                onGoToRoot={onResetSearch}
              />
            ),
          },
          description: { content: entry.description },
          user: {
            content: !entry.createdBy ? (
              ''
            ) : (
              <UserCell to={`/users/${entry.createdBy.id}`}>
                <UserThumbOrInitials user={entry.createdBy} />
                <span>{entry.createdBy.fullName}</span>
              </UserCell>
            ),
          },
          updatedAt: { content: date },
          menu: {
            content: '',
            menu: makeEditDeleteMenu(entry),
          },
          index: {
            content: makeOrderArrows(entry, index),
          },
        }

        return row
      }),
    [entries, format, makeEditDeleteMenu, makeOrderArrows, onResetSearch]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          whiteSpace: 'nowrap',
          textOverflow: cell.columnId === 'description' ? 'ellipsis' : 'clip',
          overflow: cell.columnId === 'description' ? 'hidden' : 'visible',
          color:
            cell.columnId === 'updatedAt' ? theme.colors.gray3 : 'currentColor',
          padding: cell.columnId === 'index' ? '4px 1rem' : undefined,
        },
        labelStyle: {
          overflow: cell.columnId === 'description' ? 'hidden' : 'visible',
          textOverflow: cell.columnId === 'description' ? 'ellipsis' : 'clip',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      options={tableOptions}
      noRowsText={translations.noData}
    />
  )
}

import { useClickOutside, useTranslate } from '@ur/react-hooks'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { TableTimeEntry } from '../types.graphql'
import { Icon } from '@ur/react-components'
import { HoursDropdown } from './HoursDropdown'
import { isMobileOnly } from 'react-device-detect'

const Wrapper = styled.div`
  position: relative;
  min-width: 6rem;
`
const TimeType = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2px 8px;
`

interface HoursCellProps {
  entry: TableTimeEntry
  caret?: boolean
}

export const HoursCell: React.FC<HoursCellProps> = ({
  entry,
  caret = false,
}) => {
  const translations = useTranslate({
    nHours: ['common.n-hours', { n: 0 }],
  })

  const wrapperRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  useClickOutside(wrapperRef, () => setOpen(false), true)

  function toggleOpen(evt: React.MouseEvent) {
    evt.stopPropagation()
    setOpen(v => !v)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <TimeType onClick={toggleOpen}>
        {translations.nHours({ n: entry.sum ?? 0 })}

        <Icon
          icon={!caret ? 'info-circle' : open ? 'chevron-up' : 'chevron-down'}
          type="solid"
          color="gray3"
          hoverColor={!isMobileOnly ? 'primaryHover' : undefined}
          translateY={!caret ? '1px' : '3px'}
          cursor="pointer"
        />
      </TimeType>

      {open && <HoursDropdown timesheet={entry} />}
    </Wrapper>
  )
}

import merge from 'lodash/merge'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getPostalArea } from 'util/api'

interface UsePostalCodeOptions {
  /**
   * Minimum length of postal code to attempt fetch.
   * If length of given code is lower than this, city will be null.
   * @default 4
   */
  minLength?: number
  /**
   * Maximum length of postal code to attempt fetch.
   * If length of given code is higher than this, city will be null.
   * @default 4
   */
  maxLength?: number
  /**
   * Called when city has been found, or set to null
   * @param city Name of city found, or null
   */
  onCityChange?: (city: string | null) => void
}

export function usePostalCode(
  postalCode: string,
  options?: UsePostalCodeOptions
) {
  const defaultOptions = useMemo<Required<UsePostalCodeOptions>>(
    () => ({
      minLength: 4,
      maxLength: 4,
      onCityChange: () => void 0,
    }),
    []
  )
  const mergedOptions = useMemo<Required<UsePostalCodeOptions>>(
    () => merge(defaultOptions, options),
    [defaultOptions, options]
  )

  const prevPostalCode = useRef<string | null>(null)
  const cache = useRef<{ [zip: string]: string | null }>({})

  const [loading, setLoading] = useState(false)
  const [city, setCity] = useState<string | null>(null)

  const updateCity = useCallback(
    (city: string | null, postalCode?: string) => {
      setCity(city)
      mergedOptions.onCityChange(city)

      if (
        !!postalCode &&
        postalCode.length >= mergedOptions.minLength &&
        postalCode.length <= mergedOptions.maxLength
      )
        cache.current[postalCode] = city
    },
    [mergedOptions]
  )

  useEffect(() => {
    if (prevPostalCode.current === postalCode) return
    prevPostalCode.current = postalCode

    if (
      postalCode.length < mergedOptions.minLength ||
      postalCode.length > mergedOptions.maxLength
    ) {
      updateCity(null)
      return
    }

    if (cache.current.hasOwnProperty(postalCode)) {
      updateCity(cache.current[postalCode])
      return
    }

    const getCity = async () => {
      setLoading(true)
      const city = await getPostalArea(postalCode)
      updateCity(city, postalCode)
      setLoading(false)
    }
    getCity()
  }, [
    city,
    mergedOptions,
    mergedOptions.maxLength,
    mergedOptions.minLength,
    postalCode,
    updateCity,
  ])

  return {
    city,
    loading,
  }
}

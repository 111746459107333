import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { Message, PageHeader } from 'components'
import React, { useCallback, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  useBreadcrumbInserts,
  useBreadcrumbOverrides,
  useOnErrorAuto,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CreateManualFolderModal } from '.'
import { CompanyManualsMobile } from './CompanyManualsMobile'
import { EntriesMobile } from './EntriesMobile'
import { ManualEntriesTable } from './ManualEntriesTable'
import { ManualFoldersTable } from './ManualFoldersTable'
import { CREATE_MANUAL_FOLDER_MUTATION } from './mutations'
import { MANUAL_FOLDER_BY_SLUG_QUERY } from './queries'
import {
  CreateManualFolderMutation,
  CreateManualFolderMutationVariables,
  ManualFolderBySlugQuery,
  ManualFolderBySlugQueryVariables,
} from './types.graphql'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
    font-weight: 600;
  }
  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;

    div.no-content {
      display: flex;
      justify-content: center;
      padding: 3rem 0;
    }
  }
`
const EntriesHeader = styled(PageHeader)`
  margin: 2rem 0;
`

export const ManualFoldersAndEntries: React.FC = () => {
  const translations = useTranslate({
    loading: 'common.loading',
    addFolder: 'handbook.create-folder',
    addEntry: 'handbook.create-entry',

    title: 'common.title',
    description: 'common.description',
    lastUpdated: 'common.last-updated',
    numEntries: 'handbook.entries',
    numFolders: 'handbook.folders',
    entries: 'handbook.entries',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
    toasts: {
      folderCreateSuccess: 'handbook.toasts.folder-created',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const { folderSlug } = useParams<{ folderSlug: string }>()
  const addToast = useToast()
  const addPrompt = usePrompt()
  const [, setInserts] = useGlobal('breadcrumbs.inserts')

  const { data, loading, error } = useQuery<
    ManualFolderBySlugQuery,
    ManualFolderBySlugQueryVariables
  >(MANUAL_FOLDER_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      slug: folderSlug,
    },
    //TODO - Fix href
    onCompleted() {
      setInserts([
        {
          index: 0,
          crumb: {
            href: 'vg.no',
            text: 'Nanna',
          },
        },
      ])
    },
    onError: onErrorAuto(),
  })

  const folders = useMemo(
    () => data?.manualFolder.folders.edges.map(edge => edge.node) ?? [],
    [data]
  )
  const entries = useMemo(
    () => data?.manualFolder.entries.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const title = data?.manualFolder.name ?? translations.loading
  useBreadcrumbOverrides({
    [folderSlug]: title,
    folder: null,
  })
  useBreadcrumbInserts(
    !data
      ? []
      : data.manualFolder.path.map(folder => ({
          index: 0,
          crumb: {
            text: folder.name,
            href: `/handbook/${folder.slug}`,
          },
        }))
  )

  const [createManualFolderMutation] = useMutation<
    CreateManualFolderMutation,
    CreateManualFolderMutationVariables
  >(CREATE_MANUAL_FOLDER_MUTATION, {
    refetchQueries: ['ManualFolderBySlug'],
    onCompleted() {
      addToast('success', translations.toasts.folderCreateSuccess)
    },
    onError: onErrorAuto(),
  })

  const handleCreateManualFolder = useCallback(async () => {
    const { data: newFolder } =
      await addPrompt<CreateManualFolderMutationVariables | null>(
        (resolve, _, { id }) => (
          <CreateManualFolderModal id={id} onSubmit={resolve} />
        )
      )
    if (!newFolder) return

    createManualFolderMutation({
      variables: {
        input: { ...newFolder.input, parentFolder: data?.manualFolder.id },
      },
    })
  }, [addPrompt, createManualFolderMutation, data?.manualFolder.id])

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {isMobileOnly ? (
        <>
          <CompanyManualsMobile
            folders={folders}
            onCreateClick={() => handleCreateManualFolder()}
          />

          <EntriesMobile
            slug={folderSlug}
            entries={entries}
            onCreateFolderClick={() => handleCreateManualFolder()}
          />

          {folders.length + entries.length === 0 && (
            <div className="no-content">No content</div>
          )}
        </>
      ) : (
        <>
          <PageHeader
            title={title}
            loading={loading}
            buttons={[
              {
                text: translations.addFolder,
                icon: 'plus',
                permissions: PERMISSIONS.companymanuals.add.manualfolder,
                onClick: handleCreateManualFolder,
              },
              {
                text: translations.addEntry,
                icon: 'plus',
                permissions: PERMISSIONS.companymanuals.add.manualentry,
                onClick: () =>
                  history.push(`/handbook/${folderSlug}/create-entry`),
              },
            ]}
          />

          <ManualFoldersTable folders={folders} />

          <EntriesHeader title={translations.entries} loading={loading} />

          <ManualEntriesTable entries={entries} />
        </>
      )}
    </Wrapper>
  )
}

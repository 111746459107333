import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'

interface CardProps {
  area?: string
  closed: boolean
  height?: string
}

const Wrapper = styled.div<CardProps>`
  grid-area: ${props => props.area};
  height: ${props => props.height ?? '100%'};
  width: 100%;

  background-color: white;
  color: ${props => props.theme.colors.gray2};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  header {
    padding: 1rem 2rem;
    margin-bottom: 0;
    border-bottom: ${props =>
      !props.closed && `2px solid ${props.theme.colors.gray6}`};

    font-size: 18px;
    font-weight: 400;
  }

  ${props => props.theme.media.mobile} {
    border-radius: 0px;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 1rem;

      font-weight: 600;

      div {
        color: ${props => props.theme.colors.gray3};
        font-weight: 400;

        i {
          margin-left: 4px;
        }
      }
    }
  }
`

interface CollapsibleProps {
  className?: string

  title: string
  closed?: boolean
  noCollapse?: boolean
  height?: string
  area?: string

  onToggleOpen?: () => void
}

export const Collapsible: React.CFC<CollapsibleProps> = ({
  children,
  className,

  title,
  closed = false,
  area,
  height,
  noCollapse = false,

  onToggleOpen,
}) => {
  const translations = useTranslate({
    show: 'common.show',
    hide: 'common.hide',
  })

  return (
    <Wrapper className={className} area={area} closed={closed} height={height}>
      <header>
        {title}

        {!noCollapse && (
          <div>
            {!closed ? translations.hide : translations.show}

            <Icon
              icon={!closed ? 'eye-slash' : 'eye'}
              type="solid"
              onClick={onToggleOpen}
            />
          </div>
        )}
      </header>

      {!closed && children}
    </Wrapper>
  )
}

import { TableOptions } from './types'

export const getDefaultTableOptions = (
  config: Required<Pick<TableOptions, 'fillHorizontal'>>
): TableOptions => ({
  style: {
    backgroundColor: 'white',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
  },

  header: cell => ({
    style: {
      padding: '1rem',
      paddingLeft:
        !config.fillHorizontal && cell.isFirstCell ? '1.5rem' : '1rem',

      userSelect: 'none',
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#828282',
      backgroundColor: '#f6f6f6',

      borderTopLeftRadius: cell.isFirstCell ? '8px' : undefined,
      borderTopRightRadius: cell.isLastCell ? '8px' : undefined,
      borderBottomLeftRadius: cell.isFirstCell ? '8px' : undefined,
      borderBottomRightRadius: cell.isLastCell ? '8px' : undefined,
    },
  }),
  cell: cell => ({
    style: {
      padding: '1rem',
      marginLeft: !config.fillHorizontal && cell.isFirstCell ? '0.5rem' : 0,
      marginRight: !config.fillHorizontal && cell.isLastCell ? '0.5rem' : 0,

      color: '#333',
      backgroundColor: cell.rowIndex % 2 === 0 ? 'white' : '#fafafa',

      borderTopLeftRadius: cell.isFirstCell ? '8px' : undefined,
      borderTopRightRadius: cell.isLastCell ? '8px' : undefined,
      borderBottomLeftRadius: cell.isFirstCell ? '8px' : undefined,
      borderBottomRightRadius: cell.isLastCell ? '8px' : undefined,
    },
  }),

  fillHorizontal: false,
  rowHoverBackground: null,
  singleSort: false,
})

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { CenteredLoader, Message, PageHeader } from 'components'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { ProductForm } from './components/ProductForm'
import { PATCH_PRODUCT_MUTATION } from './mutations'
import { PRODUCT_QUERY } from './queries'
import {
  PatchProductMutation,
  PatchProductMutationVariables,
  ProductQuery,
} from './types.graphql'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

interface EditProductProps {}

export const EditProduct: React.FC<EditProductProps> = () => {
  const translations = useTranslate({
    editProduct: 'products.edit-product',

    results: {
      queryError: 'server.general-error-try-again-later',
      patchSuccess: 'products.toasts.patch-success',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const { productId } = useParams<{ productId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const history = useHistory()

  const { data, loading, error } = useQuery<ProductQuery, IdVariable>(
    PRODUCT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: productId },
      onCompleted(data) {
        setOverrides(v => ({
          [productId]: data?.product.name,
          ...v,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const [patchProductMutation, { loading: patchLoading }] = useMutation<
    PatchProductMutation,
    PatchProductMutationVariables
  >(PATCH_PRODUCT_MUTATION, {
    refetchQueries: ['AllProductsQuery'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.patchSuccess)
      history.push('/products')
    },
    onError: onErrorAuto(),
  })

  const product = useMemo(() => data?.product, [data])

  function handleSubmit(form: PatchProductMutationVariables) {
    patchProductMutation({
      variables: {
        ...form,
      },
    })
  }

  const isLoading = loading || patchLoading

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (!product) return <CenteredLoader />

  return (
    <Wrapper>
      <PageHeader title={translations.editProduct} loading={isLoading} />
      <ProductForm
        loading={isLoading}
        product={product}
        onSubmit={variables => handleSubmit({ id: productId, ...variables })}
      />
    </Wrapper>
  )
}

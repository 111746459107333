import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { ProjectStage as BaseProjectStage } from 'modules/projects/components'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useDateFns } from 'util/hooks'
import { HoursCell } from './components'
import { TableTimeEntry } from './types.graphql'

const Wrapper = styled.section`
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`

const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`

const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`

const UserField = styled.div`
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
`

interface ContentProps {
  hideProject: boolean
}

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;

  border-bottom: 1px solid ${props => props.theme.colors.gray6};

  a {
    font-size: 18px;
    font-weight: 600;
  }
  div.description {
    margin-top: ${props => (props.hideProject ? '0' : '0.4rem')};
    font-size: 14px;
  }
`

const ProjectStage = styled(BaseProjectStage)`
  span {
    font-size: 1.1rem;
    font-weight: 600;
  }
  i {
    margin-right: 0.8rem;
    transform: translateY(2px);
    font-size: 0.6rem;
  }
`

const Customer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  border-bottom: 1px solid ${props => props.theme.colors.gray6};
  color: ${props => props.theme.colors.gray3};

  padding: 0rem 1rem;
  font-size: 14px;

  a {
    color: ${props => props.theme.colors.gray3};
  }
`

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 50% 50%;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0.8rem 1rem;

    color: ${props => props.theme.colors.gray3};
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;

    & + div {
      border-left: 1px solid ${props => props.theme.colors.gray6};
    }
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`

interface TimeEntryCardProps {
  entry: TableTimeEntry
  editDeleteMenu: TableMenu
  hideProject?: boolean
}

export const TimeEntryCard: React.FC<TimeEntryCardProps> = ({
  entry,
  editDeleteMenu,
  hideProject = false,
}) => {
  const translations = useTranslate({
    hours: 'common.hours',
    nKm: ['common.n-km', { n: 0 }],

    customer: 'common.customer',
    noCustomer: 'customers.no-customer',
  })
  const { format } = useDateFns()

  const showContent = !hideProject || !!entry.description

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(entry.date), 'PPP')}</DateField>

        <UserField>
          <Link to={`/users/${entry.employee.id}`}>
            {entry.employee.fullName}
          </Link>
        </UserField>

        <Menu className="menu" icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      {showContent && (
        <Content hideProject={hideProject}>
          {!hideProject && (
            <ProjectStage project={entry.project} circle link named />
          )}

          {!!entry.description && (
            <div className="description">{entry.description}</div>
          )}
        </Content>
      )}

      <Customer>
        <strong>{translations.customer + ':'}&nbsp;</strong>
        {!!entry.project?.customer.id ? (
          <Link to={`/customers/${entry.project?.customer.id}`}>
            {entry.project.customer.name}
          </Link>
        ) : (
          <span>{translations.noCustomer}</span>
        )}
      </Customer>

      <Footer>
        <HoursCell entry={entry} caret />

        <div>
          {translations.nKm({ n: entry.kilometersDriven ?? 0 })}

          <Icon icon="car-side" size="16px" type="solid" translateY="1px" />
        </div>
      </Footer>
    </Wrapper>
  )
}

import { TreeItem } from './types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Icon, IconProps } from '@ur/react-components'

interface WrapperProps {
  level: number
}
const Wrapper = styled.div<WrapperProps>`
  margin-left: ${props => props.level > 0 && '1rem'};
  padding: 0.25rem 0;
`
interface ContentProps {
  active: boolean
}
const Content = styled.div<ContentProps>`
  padding: 0.25rem;

  border-radius: 4px;
  background-color: ${props =>
    props.active && props.theme.colors.primaryHover}5a;

  i {
    margin-right: 0.5ch;
  }
`

interface BranchProps {
  item: TreeItem
  level: number

  openItems: string[]
  selectedItem: string | null

  onClick: (item: TreeItem, evt: React.MouseEvent) => void
}

export const Branch: React.FC<BranchProps> = ({
  item,
  level,

  openItems,
  selectedItem,

  onClick,
}) => {
  const isOpen = useMemo(
    () => openItems.includes(item.value),
    [openItems, item]
  )

  const icon = useMemo<IconProps | null>(() => {
    if (!item.icon) return null

    if (typeof item.icon === 'string')
      return {
        icon: item.icon,
      }

    return item.icon
  }, [item.icon])

  function handleClick(item: TreeItem, evt: React.MouseEvent) {
    evt.stopPropagation()
    onClick(item, evt)
  }

  return (
    <Wrapper level={level}>
      <Content
        active={selectedItem === item.value}
        onClick={evt => handleClick(item, evt)}
      >
        {!!icon && <Icon {...icon} />}
        <span>{item.text}</span>
      </Content>

      {isOpen &&
        item.items?.map(item => (
          <Branch
            key={item.value}
            item={item}
            level={level + 1}
            openItems={openItems}
            selectedItem={selectedItem}
            onClick={handleClick}
          />
        ))}
    </Wrapper>
  )
}

import { findBestMatch } from 'string-similarity'
import deburr from 'lodash/deburr'
import { createBackendUrl } from './env'
import { getLoginToken } from './auth'
import { getTimeZone } from './time'

export interface BringResult {
  result: string
  valid: boolean
  postalCodeType: string
}

export async function getPostalArea(pnr: string): Promise<string | null> {
  const response = await fetch(
    `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=https://app.digitech.no&pnr=${pnr}`,
    {
      headers: {
        Accept: 'application/json',
      },
    }
  )
  const data: BringResult = await response.json()
  return data.valid ? data.result : null
}

export async function getOrganizationNumber(name: string) {
  const urlifyName = deburr(name).toLowerCase().trim().replace(' ', '+')
  const response = await fetch(
    `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${urlifyName}`,
    {
      headers: {
        Accept: 'application/json',
      },
    }
  )
  if (!response) return null

  const json = await response.json()
  if (!json._embedded) return null

  try {
    const names = json._embedded.enheter.map((e: any) => e.navn.toLowerCase())
    const matches = findBestMatch(name.toLowerCase(), names)
    const bestMatch = json._embedded.enheter[matches.bestMatchIndex]

    return {
      organizationNumber: bestMatch.organisasjonsnummer,
      address: bestMatch.forretningsadresse.adresse[0],
      postalCode: bestMatch.forretningsadresse.postnummer,
      postalArea: bestMatch.forretningsadresse.poststed,
    }
  } catch (error) {
    return null
  }
}

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCeTU0dccL7MvY0bsHSY0VlkFxfEK1aXuM'

export function openPrint(url: string) {
  let uri = createBackendUrl(url)

  const tz = getTimeZone()
  uri += `&tz=${tz}`

  const token = getLoginToken()
  if (token) uri += `&bearer=${token}`

  uri = encodeURI(uri)
  window.open(uri, '_blank')
}

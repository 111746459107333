import { Icon, IconType } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import React, { useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ centered: boolean }>`
  position: relative;
  display: flex;
  z-index: 1;
  flex-direction: column;

  ${props =>
    props.centered &&
    css`
      display: flex;
      width: 100vw;
      align-items: center;
    `}
`

const Item = styled(NavLink)<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 56px;
  width: calc(100vw - 2rem);

  padding: 0 1.5rem;
  font-weight: 600;

  &.active {
    box-shadow: ${props => props.theme.layout.defaultShadow};

    border: 1px solid ${props => props.theme.colors.gray5};
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};

    color: ${props => props.theme.colors.gray2};
    background-color: white;

    ${props =>
      !props.open &&
      css`
        background-color: ${props => props.theme.colors.primary};
        color: white;

        border: 0;
      `}
  }

  &:not(.active) {
    color: ${props => props.theme.colors.gray2};
  }

  i:first-child {
    width: 2rem;
  }
`

const ItemList = styled.div`
  position: absolute;

  top: 100%;
  z-index: 1;

  background-color: white;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};

  a {
    border-top: 1px solid ${props => props.theme.colors.gray6};
  }
`

export interface NavMenuItem {
  label: string
  icon?: FontAwesomeIcon
  /** Default: 'solid' */
  iconType?: IconType
  href: string
  /** Use exact route. Default: false */
  exact?: boolean
}

interface NavMenuProps {
  items: NavMenuItem[]
  centered?: boolean
}

export const NavMenu: React.FC<NavMenuProps> = ({
  items,
  centered = false,
}) => {
  const location = useLocation()

  const [menuOpen, setMenuOpen] = useState(false)

  const [selected, unselected] = useMemo(() => {
    return items.reduce<[NavMenuItem, NavMenuItem[]]>(
      (acc, item) => {
        const matched = location.pathname.startsWith(item.href)

        if (matched) acc[0] = item
        else acc[1].push(item)
        return acc
      },
      [items[0], []]
    )
  }, [items, location.pathname])

  return (
    <Wrapper centered={centered}>
      <Item
        to="#"
        exact={selected.exact}
        open={menuOpen}
        onClick={() => setMenuOpen(v => !v)}
      >
        <div>
          {selected.icon && (
            <Icon icon={selected.icon} type={selected.iconType ?? 'solid'} />
          )}
          {selected.label}
        </div>
        <Icon icon={menuOpen ? 'chevron-up' : 'chevron-down'} />
      </Item>
      <ItemList>
        <div className="list">
          {menuOpen &&
            unselected.map(item => (
              <span key={item.href}>
                <Item
                  to={item.href}
                  exact={item.exact}
                  onClick={() => setMenuOpen(false)}
                >
                  <div>
                    {item.icon && (
                      <Icon icon={item.icon} type={item.iconType ?? 'solid'} />
                    )}
                    {item.label}
                  </div>
                </Item>
              </span>
            ))}
        </div>
      </ItemList>
    </Wrapper>
  )
}

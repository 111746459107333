import { gql } from '@apollo/client'

export const CREATE_COMPANY_SETTING_MUTATION = gql`
  mutation CreateCompanySetting($key: String!, $value: String!) {
    createCompanySetting(input: { key: $key, value: $value }) {
      companySetting {
        id
        key
        value
      }
    }
  }
`

export const PATCH_COMPANY_MUTATION = gql`
  mutation PatchCompany($id: ID!, $input: PatchCompanyInput!) {
    patchCompany(id: $id, input: $input) {
      company {
        id
      }
    }
  }
`

export const UPDATE_COMPANY_SETTING_MUTATION = gql`
  mutation UpdateCompanySetting($id: ID!, $key: String!, $value: String!) {
    updateCompanySetting(
      id: $id
      input: { company: $id, key: $key, value: $value }
    ) {
      companySetting {
        id
        key
        value
      }
    }
  }
`

export const CREATE_OR_UPDATE_COMPANY_SETTING = gql`
  mutation CreateOrUpdateCompanySetting($key: String!, $value: String!) {
    createOrUpdateCompanySetting(key: $key, value: $value) {
      companySetting {
        id
        key
        value
      }
    }
  }
`

export const CREATE_OR_UPDATE_MULTIPLE_COMPANY_SETTINGS = gql`
  mutation CreateOrUpdateMultipleCompanySettings($settings: [KeyValuePair]!) {
    createOrUpdateMultipleCompanySettings(settings: $settings) {
      ok
    }
  }
`

export const CREATE_COMPANY_FOLDER_SETTING_MUTATION = gql`
  mutation CreateCompanyFolderSetting(
    $input: CreateCompanyFolderSettingInput!
  ) {
    createCompanyFolderSetting(input: $input) {
      companyFolderSetting {
        id
      }
    }
  }
`

export const PATCH_COMPANY_FOLDER_SETTING_MUTATION = gql`
  mutation PatchCompanyFolderSetting(
    $id: ID!
    $input: PatchCompanyFolderSettingInput!
  ) {
    patchCompanyFolderSetting(id: $id, input: $input) {
      companyFolderSetting {
        id
      }
    }
  }
`

export const DELETE_COMPANY_FOLDER_SETTING_MUTATION = gql`
  mutation DeleteCompanyFolderSetting($id: ID!) {
    deleteCompanyFolderSetting(id: $id) {
      found
      deletedId
    }
  }
`

import { useDebounce } from '@ur/react-hooks'
import { ActionButton, Input, TableMenu } from 'components'
import React from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CustomerCard } from './CustomerCard'
import { ShallowCustomer } from './types.graphql'

const Wrapper = styled.div`
  div.no-customers {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`

const Toolbar = styled.div`
  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

interface CustomersMobileProps {
  customers: ShallowCustomer[]
  search: string
  translations: {
    noData: string
    noResults: string
    search: string
  }

  makeEditDeleteMenu: (customer: ShallowCustomer) => TableMenu

  onSearchChange: (value: string) => void
  onCreate: () => void
}

export const CustomersMobile: React.FC<CustomersMobileProps> = ({
  customers,
  search,
  translations,

  makeEditDeleteMenu,

  onSearchChange,
  onCreate,
}) => {
  const debouncedSearch = useDebounce(search)

  const canCreateCustomer = usePermission(PERMISSIONS.customers.add.customer)

  return (
    <Wrapper>
      {canCreateCustomer && <ActionButton onClick={onCreate} />}

      <Toolbar>
        <Input
          value={search}
          placeholder={translations.search}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          width="100%"
          onChange={onSearchChange}
        />
      </Toolbar>

      {customers.map(customer => {
        return (
          <CustomerCard
            key={customer.id}
            customer={customer}
            editDeleteMenu={makeEditDeleteMenu(customer)}
          />
        )
      })}

      {!customers.length && (
        <div className="no-customers">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { Icon } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  ActionButtonMenu,
  CenteredLoader,
  Input,
  TableMenu,
} from 'components'
import { Menu } from 'components/Table/TableMenu'
import React, { useCallback, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { checkIsImage, getFileIcon, getFileIconColor } from 'util/files'
import { useDateFns } from 'util/hooks'
import { RecentFiles } from './components'
import {
  ChecklistFilesCountsQuery,
  ChecklistFilesCountsQueryVariables,
  ChecklistFolder,
  ChecklistImage,
  CHECKLIST_FILES_COUNTS_QUERY,
  TableFile,
  TableFolder,
} from './graphql'

const Wrapper = styled.div`
  width: 100vw;

  div.recent {
    max-width: 100%;
    margin-right: 0.5rem;
    margin-bottom: -1rem;
    padding: 0.5rem 0.5rem 0;
  }
  a.link-back {
    display: block;
    margin: 0 0 1rem 1rem;
    font-weight: 600;

    i {
      margin-right: 0.5ch;
      transform: scale(-1, 1);
    }
  }
  div.no-content {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
`
interface ToolbarProps {
  isSearch: boolean
}
const Toolbar = styled.div<ToolbarProps>`
  margin-bottom: ${props => props.isSearch && '1rem'};
  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`
interface FileCardProps {
  hasFooter?: boolean
}
const FileCard = styled.div<FileCardProps>`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  row-gap: 1rem;

  padding: ${props => (props.hasFooter ? '1rem 0 0' : '1rem 0')};

  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  & + div {
    margin-top: 1rem;
  }
  .name {
    margin-left: 1rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 600;

    i {
      margin-right: 1ch;
      transform: scale(1.2);
    }
  }
  span.date {
    margin: 0 0.5rem;
    color: ${props => props.theme.colors.gray3};
    font-size: 0.9rem;
  }
  a,
  & > span {
    line-height: 125%;
  }
  .menu {
    margin-right: 1rem;
  }
  footer {
    grid-column: 1 / -1;
    grid-row: 2;

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    border-top: 1px solid ${props => props.theme.colors.gray6};
    font-size: 0.8rem;
    font-weight: 600;

    span {
      color: ${props => props.theme.colors.gray3};
    }
    div {
      display: flex;
      justify-content: center;

      padding: 0.5rem 1rem 0.7rem;

      & + div {
        border-left: 1px solid ${props => props.theme.colors.gray6};
      }
    }
    &.checklist {
      grid-template-columns: 1fr auto;

      div {
        align-items: center;

        &:first-child {
          display: block;
        }
      }
    }
  }
`

interface ProjectFilesMobileProps {
  projectId: string
  folders: TableFolder[]
  files: TableFile[]
  parentFolder: { id: string; name: string } | null
  checklists: ChecklistFolder[]
  checklistImages: ChecklistImage[]

  loading: boolean
  search: string
  root: boolean
  withChecklistFiles: boolean
  isChecklistFolders: boolean
  isChecklistImages: boolean
  makeFolderTableMenu: (folder: TableFolder) => TableMenu
  makeFileTableMenu: (file: TableFile) => TableMenu

  onSearchChange: (value: string) => void
  onCreateFolder: () => void
  onUploadFiles: (files: File[]) => void
  onImageClick: (id: string) => void
}

export const ProjectFilesMobile: React.FC<ProjectFilesMobileProps> = ({
  projectId,
  folders,
  files,
  parentFolder,
  checklists,
  checklistImages,

  loading,
  search,
  root,
  withChecklistFiles,
  isChecklistFolders,
  isChecklistImages,
  makeFolderTableMenu,
  makeFileTableMenu,

  onSearchChange,
  onCreateFolder,
  onUploadFiles,
  onImageClick,
}) => {
  const translations = useTranslate({
    search: 'files.search-for-files',
    newFolder: 'files.new-folder',
    uploadFiles: 'files.upload-files',
    root: 'common.root',
    checklistImages: 'common.checklist-images',

    subFolders: 'common.sub-folders',
    files: 'common.files',
    checklists: 'common.checklists',
    purpose: 'common.purpose',
    images: 'common.images',

    noContent: 'common.no-content',
    noResults: 'common.no-results',
  })

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { format } = useDateFns()
  const debouncedSearch = useDebounce(search)

  const { data: checklistCounts } = useQuery<
    ChecklistFilesCountsQuery,
    ChecklistFilesCountsQueryVariables
  >(CHECKLIST_FILES_COUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !withChecklistFiles,
    variables: {
      projectId,
    },
  })

  function handleFileClick(id: string, url: string) {
    checkIsImage(url) ? onImageClick(id) : window.open(url, '_blank')
  }

  const checklistStats = useMemo(
    () => ({
      checklists: checklistCounts?.counts.checklists.hasImages ?? 0,
      checklistImages: checklistCounts?.counts.checklists.images ?? 0,
      lastUpdatedImage: !!checklistCounts?.counts.checklists.lastUpdatedImage
        ? format(
            new Date(checklistCounts.counts.checklists.lastUpdatedImage),
            'PP'
          )
        : null,
    }),
    [checklistCounts, format]
  )

  const handleUploadFiles = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(evt.target.files ?? [])
      if (!files.length) return

      onUploadFiles(files)

      evt.target.value = ''
    },
    [onUploadFiles]
  )

  const actionMenu = useMemo<ActionButtonMenu>(
    () => ({
      items: [
        {
          text: translations.newFolder,
          onClick: onCreateFolder,
        },
        {
          text: translations.uploadFiles,
          hide: root,
          onClick: () => fileInputRef.current?.click(),
        },
      ],
    }),
    [onCreateFolder, root, translations.newFolder, translations.uploadFiles]
  )

  const parentUrl = useMemo(
    () =>
      isChecklistImages
        ? `/projects/${projectId}/files/checklists`
        : !!parentFolder
        ? `/projects/${projectId}/files/${parentFolder.id}`
        : `/projects/${projectId}/files`,
    [isChecklistImages, parentFolder, projectId]
  )

  return (
    <Wrapper>
      <input
        ref={fileInputRef}
        hidden
        type="file"
        multiple
        onChange={handleUploadFiles}
      />

      {loading && <CenteredLoader page />}

      {!search && <ActionButton menu={actionMenu} />}

      <Toolbar isSearch={!!search}>
        <Input
          value={search}
          placeholder={translations.search}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          height="56px"
          fullWidth
          onChange={onSearchChange}
        />
      </Toolbar>

      {!search && (
        <div className="recent">
          <RecentFiles projectId={projectId} />
        </div>
      )}

      {(!root || isChecklistFolders || isChecklistImages) && (
        <Link className="link-back" to={parentUrl}>
          <Icon icon="level-up-alt" />

          {isChecklistImages
            ? translations.checklistImages
            : parentFolder?.name ?? translations.root}
        </Link>
      )}

      {withChecklistFiles && (
        <FileCard hasFooter>
          <Link className="name" to={`/projects/${projectId}/files/checklists`}>
            <Icon icon="check-double" type="solid" />
            {translations.checklistImages}
          </Link>

          <span className="date">{checklistStats.lastUpdatedImage}</span>

          <div className="menu" />

          <footer>
            <div>
              <span>{translations.checklists}:</span>&nbsp;
              {checklistStats.checklists}
            </div>

            <div>
              <span>{translations.images}:</span>&nbsp;
              {checklistStats.checklistImages}
            </div>
          </footer>
        </FileCard>
      )}

      {folders.map(folder => (
        <FileCard key={folder.id} hasFooter>
          <Link
            className="name"
            to={`/projects/${projectId}/files/${folder.id}`}
          >
            <Icon icon="folder-open" type="solid" />
            {folder.name}
          </Link>

          <span className="date">
            {format(new Date(folder.updatedAt), 'PP')}
          </span>

          <Menu
            className="menu"
            icon="ellipsis-h"
            config={makeFolderTableMenu(folder)}
          />

          <footer>
            <div>
              <span>{translations.subFolders}:</span>&nbsp;
              {folder.numberOfFolders}
            </div>

            <div>
              <span>{translations.files}:</span>&nbsp;{folder.numberOfFiles}
            </div>
          </footer>
        </FileCard>
      ))}

      {files.map(file => (
        <FileCard key={file.id}>
          <span
            className="name"
            role="button"
            onClick={() => handleFileClick(file.id, file.url)}
          >
            <Icon icon={getFileIcon(file.originalName)} type="solid" />
            {file.name}
          </span>

          <span className="date">{format(new Date(file.updatedAt), 'PP')}</span>

          <Menu
            className="menu"
            icon="ellipsis-h"
            config={makeFileTableMenu(file)}
          />
        </FileCard>
      ))}

      {checklists.map(checklist => (
        <FileCard key={checklist.id} hasFooter>
          <Link
            className="name"
            to={`/projects/${projectId}/files/checklists/${checklist.id}`}
          >
            <Icon icon="file-check" type="solid" />
            {checklist.title}
          </Link>

          <span className="date">
            {!checklist.lastUpdatedImage
              ? ''
              : format(new Date(checklist.lastUpdatedImage), 'PP')}
          </span>

          <Link
            className="menu"
            to={`/projects/${projectId}/checklists/${checklist.id}`}
          >
            <Icon
              icon="external-link"
              color="gray3"
              hoverColor="primaryHover"
              cursor="pointer"
            />
          </Link>

          <footer className="checklist">
            <div>
              <span>{translations.purpose}:</span>&nbsp;
              {checklist.purpose}
            </div>

            <div>
              <span>{translations.images}:</span>&nbsp;
              {checklist.imageCount}
            </div>
          </footer>
        </FileCard>
      ))}

      {checklistImages.map(image => (
        <FileCard key={image.id}>
          <span
            className="name"
            onClick={() => handleFileClick(image.id, image.image)}
          >
            <Icon
              icon={getFileIcon(image.originalName)}
              color={getFileIconColor(image.originalName)}
            />
            {image.title}
          </span>

          <span className="date">
            {format(new Date(image.updatedAt), 'PP')}
          </span>

          <div className="menu" />
        </FileCard>
      ))}

      {!(
        folders.length +
        checklists.length +
        files.length +
        checklistImages.length
      ) && (
        <div className="no-content">
          {!!debouncedSearch ? translations.noResults : translations.noContent}
        </div>
      )}
    </Wrapper>
  )
}

import { useTranslate } from '@ur/react-hooks'
import { TabbedPage } from 'components/TabbedPage'
import { Route, Switch } from 'containers/Routing'
import React from 'react'
import { ProductsTab } from '.'

interface ProductsProps {}

export const Products: React.FC<ProductsProps> = () => {
  const translations = useTranslate({
    manual: 'products.manual',
    imported: 'products.imported',
  })

  return (
    <TabbedPage
      tabs={[
        {
          href: '/products',
          text: translations.manual,
        },
        {
          href: '/products/imported',
          text: translations.imported,
        },
      ]}
    >
      <Switch>
        <Route exact path="/products" render={() => <ProductsTab />} />

        <Route
          exact
          path="/products/imported"
          render={() => <ProductsTab isImported />}
        />
      </Switch>
    </TabbedPage>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import {
  Card as BaseCard,
  CenteredLoader,
  Message,
  Quill,
  UserThumbOrInitials,
} from 'components'
import { Menu } from 'components/Table/TableMenu'
import capitalize from 'lodash/capitalize'
import { DeltaStatic } from 'quill'
import Delta from 'quill-delta'
import React, { useMemo } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { getFileIcon, getFileIconColor } from 'util/files'
import { safeParseJson } from 'util/helpers'
import { useConfirm, useDateFns, useOnErrorAuto } from 'util/hooks'
import { DELETE_MANUAL_ENTRY_ATTACHMENT_MUTATION } from './mutations'
import { MANUAL_ENTRY_BY_SLUG_QUERY } from './queries'
import {
  DeleteManualEntryAttachmentMutation,
  ManualEntryBySlugQuery,
  ManualEntryBySlugQueryVariables,
} from './types.graphql'
import { useEntryUtils } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;

  max-width: 1200px;

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    gap: 1rem;

    width: 100vw;
    max-width: 100vw;
    padding-bottom: 6rem;
  }
`
const Card = styled(BaseCard)`
  width: 600px;
  padding: 0;

  header {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 1rem;

    padding: 2rem 3rem;

    border-bottom: 1px solid ${props => props.theme.colors.gray4};

    h1 {
      grid-column: 1 / 3;
      margin: 0;

      font-size: 1.2rem;
      font-weight: 600;
    }
    div.menu {
      grid-column: 3;
      justify-self: end;

      & > div {
        margin-right: -0.5rem;
      }
    }
    a.user {
      grid-column: 1;
      grid-row: 2;

      display: flex;
      align-items: center;
      gap: 0.5rem;

      text-decoration: none;

      span {
        font-size: 0.8rem;
        color: ${props => props.theme.colors.gray3};
        white-space: nowrap;
      }
      &:hover span {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
    div.updated {
      grid-column: 2 / -1;
      grid-row: 2;

      display: flex;
      align-items: center;

      font-size: 0.8rem;
      color: ${props => props.theme.colors.gray3};
      text-align: right;
    }
    p.description {
      grid-column: 1 / -1;
      grid-row: 3;
      margin: 0;

      font-weight: 600;
    }
  }
  article {
    padding: 2rem 3rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;

    header {
      padding: 1rem;
    }
    article {
      padding: 1rem;
    }
  }
`
const Attachments = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 2rem;

  max-width: 600px;
  height: max-content;

  h2 {
    grid-column: 1 / -1;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0.5rem 0;

    font-size: 1.2rem;
    font-weight: 600;
  }
  div.attachment {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    padding: 1.5rem;
    overflow: hidden;

    background-color: white;
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    box-shadow: ${props => props.theme.layout.defaultCardShadow};

    span {
      max-width: 100%;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-weight: 600;
      user-select: none;
    }
    i.delete {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  }
  div.no-attachments {
    margin-left: 0.5rem;
    color: ${props => props.theme.colors.gray3};
    font-style: italic;
  }

  ${props => props.theme.media.desktop} {
    div.attachment {
      cursor: pointer;
      transition: transform ease-out 0.1s, box-shadow ease-out 0.1s;

      &:hover {
        transform: scale(1.025);
        box-shadow: 0 4px 16px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
  ${props => props.theme.media.mobile} {
    gap: 1rem;
    max-width: 100vw;
  }
`

interface ManualEntryProps {}

export const ManualEntry: React.FC<ManualEntryProps> = () => {
  const translations = useTranslate({
    updatedAt: ['handbook.updated-when', { when: '' }],
    edit: 'common.edit',
    delete: 'common.delete',

    attachments: 'common.attachments',
    noAttachments: 'common.no-attachments',

    prompts: {
      deleteAttachment: 'handbook.prompts.delete-entry-attachment',
      deleteAttachmentTitle: 'handbook.prompts.delete-entry-attachment-title',
    },
    results: {
      entryNotFound: 'handbook.errors.entry-not-found',
      couldNotDeleteAttachment: 'handbook.errors.delete-attachment',
    },
  })

  const { entrySlug, folderSlug } =
    useParams<{ entrySlug: string; folderSlug: string }>()
  const history = useHistory()
  const { formatDistanceToNow } = useDateFns()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()

  const { data, loading, error } = useQuery<
    ManualEntryBySlugQuery,
    ManualEntryBySlugQueryVariables
  >(MANUAL_ENTRY_BY_SLUG_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      entrySlug,
      folderSlug,
    },
    onCompleted(data) {
      setOverrides({
        [data.manualEntry.folder.slug]: data.manualEntry.folder.name,
        [data.manualEntry.slug]: data.manualEntry.name,
      })
    },
  })

  const [deleteAttachment, { loading: deleteAttachmentLoading }] = useMutation<
    DeleteManualEntryAttachmentMutation,
    IdVariable
  >(DELETE_MANUAL_ENTRY_ATTACHMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['ManualEntryBySlug'],
    onError: onErrorAuto(translations.results.couldNotDeleteAttachment),
  })

  const updatedAt = useMemo(
    () =>
      capitalize(
        translations.updatedAt({
          when: !data
            ? ''
            : formatDistanceToNow(new Date(data.manualEntry.updatedAt), {
                addSuffix: true,
              }),
        })
      ),
    [data, formatDistanceToNow, translations]
  )

  const richtext = useMemo<DeltaStatic>(() => {
    if (!data) return new Delta() as unknown as DeltaStatic

    const parsed = safeParseJson<Delta>(data.manualEntry.richtext, new Delta())
    return new Delta(parsed.ops) as unknown as DeltaStatic
  }, [data])

  const { makeEditDeleteMenu } = useEntryUtils([], {
    editState: {
      next: `/handbook/${folderSlug}/`,
    },
    afterDelete: () => history.push(`/handbook/${folderSlug}`),
  })

  function handleDeleteFile(id: string) {
    return async (evt: React.MouseEvent) => {
      evt.stopPropagation()

      const answer = await confirm(
        translations.prompts.deleteAttachment,
        translations.prompts.deleteAttachmentTitle,
        {
          variant: 'delete',
        }
      )
      if (!answer) return

      deleteAttachment({ variables: { id } })
    }
  }

  if (!!error)
    return (
      <Message.Error show centered text={translations.results.entryNotFound} />
    )

  if (loading || !data) return <CenteredLoader />

  const entry = data.manualEntry

  return (
    <Wrapper>
      <Card>
        <header>
          <h1>{entry.name}</h1>

          <div className="menu">
            <Menu icon="ellipsis-v" config={makeEditDeleteMenu(entry)} />
          </div>

          {entry.createdBy && (
            <Link className="user" to={`/users/${entry.createdBy.id}`}>
              <UserThumbOrInitials
                user={entry.createdBy}
                size={28}
                cursor="pointer"
              />

              <span>{entry.createdBy.fullName}</span>
            </Link>
          )}

          <div className="updated">{updatedAt}</div>

          {!!entry.description && (
            <p className="description">{entry.description}</p>
          )}
        </header>

        <article>
          <Quill defaultValue={richtext} renderer />
        </article>
      </Card>

      <Attachments>
        <h2>
          {translations.attachments}

          {deleteAttachmentLoading && <Loader.Spinner size={18} />}
        </h2>

        {entry.attachments.map(file => (
          <div
            className="attachment"
            role="link"
            onClick={() => window.open(file.file, '_blank')}
          >
            <Icon
              className="delete"
              icon="trash"
              type="solid"
              size="1.5rem"
              color="gray5"
              hoverColor="danger"
              cursor="pointer"
              onClick={handleDeleteFile(file.id)}
            />

            <Icon
              icon={getFileIcon(file.file)}
              color={getFileIconColor(file.file)}
              type="light"
              size="3rem"
            />

            <span>{file.name}</span>
          </div>
        ))}

        {!entry.attachments.length && (
          <div className="no-attachments">{translations.noAttachments}</div>
        )}
      </Attachments>
    </Wrapper>
  )
}

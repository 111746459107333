import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { Icon } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { usePermissions } from 'util/hooks'

interface ItemProps {
  open: boolean
  isRed?: boolean
  inactive?: boolean
  menuOpen?: boolean
}
const Item = styled.div<ItemProps>`
  margin: 8px 0;
  color: ${props =>
    props.isRed ? props.theme.colors.logoutRed : props.theme.colors.gray2};
  font-size: 14px;

  a {
    position: relative;
    display: inline-flex;
    height: 100%;
    width: 100%;
    padding: 10px 0 10px 24px;

    text-decoration: none;
    color: inherit;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }

    ${props =>
      !props.open &&
      css`
        padding-left: 0;
        padding-right: 0;
        justify-content: center;

        i {
          margin-right: 0;
        }
      `}

    &:not(.active) i {
      color: ${props =>
        props.isRed ? props.theme.colors.logoutRed : props.theme.colors.gray2};
    }

    ${props =>
      !props.inactive &&
      css`
        &.active {
          color: ${props.theme.colors.primary};
          background-color: ${props.theme.colors.navBackground};
          border-radius: 0 18px 18px 0;
        }
      `}
  }

  ${props =>
    props.menuOpen &&
    css`
      color: ${props.theme.colors.primary};
    `}

  &:hover {
    a {
      text-decoration: none;
      color: ${props => props.theme.colors.primaryHover};

      &.active,
      i {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }
`
const InactiveWrapper = styled(Link)`
  padding: 10px 0 10px 24px;
`
const ChevronIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  margin-top: 4px;
`
const NavItemText = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: inherit;

  hover: {
    color: ${props => props.theme.colors.primaryHover};
  }
`

interface SidebarNavItemProps {
  to?: string
  state?: { [key: string]: any }
  text: string

  open?: boolean
  menuOpen?: boolean | undefined
  exact?: boolean
  isRed?: boolean
  inactive?: boolean
  /**
   * Permission needed to see this sidebar item, admins always allowed.
   * To use OR logic instead of AND, let first item in array be 'OR'
   */
  permissions?: string | string[]

  icon?: FontAwesomeIcon
  iconType?: 'light' | 'regular' | 'brand' | 'solid'

  onClick?: () => void
}
export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  icon,
  to = '',
  state,
  text,

  open = true,
  menuOpen,
  exact,
  iconType,
  isRed = false,
  inactive = false,

  permissions,

  onClick,
}) => {
  const { hasPermissions, hasPermissionsOr } = usePermissions()
  const show = useMemo(
    () =>
      !permissions?.length
        ? true
        : typeof permissions === 'string'
        ? hasPermissions(permissions, true)
        : permissions[0].toUpperCase() === 'OR'
        ? hasPermissionsOr(permissions.slice(1), true)
        : hasPermissions(permissions, true),
    [hasPermissions, hasPermissionsOr, permissions]
  )

  return !show ? null : (
    <Item
      open={open}
      isRed={isRed}
      inactive={inactive}
      menuOpen={menuOpen}
      onClick={onClick}
    >
      {inactive ? (
        <InactiveWrapper
          to={{
            pathname: to,
            state,
          }}
        >
          {icon && (
            <Icon
              icon={icon}
              size="20px"
              margin="0 1rem 0 0"
              fixedWidth
              type={iconType}
            />
          )}

          {open && <NavItemText>{text}</NavItemText>}

          {typeof menuOpen === 'boolean' && (
            <>
              {menuOpen ? (
                <ChevronIcon icon="chevron-up" />
              ) : (
                <ChevronIcon icon="chevron-down" />
              )}
            </>
          )}
        </InactiveWrapper>
      ) : (
        <NavLink
          exact={exact}
          to={{
            pathname: to,
            state,
          }}
        >
          {icon && (
            <Icon
              icon={icon}
              size="20px"
              margin="0 1rem 0 0"
              fixedWidth
              type={iconType}
            />
          )}

          {open && <NavItemText>{text}</NavItemText>}
        </NavLink>
      )}
    </Item>
  )
}

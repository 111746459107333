import { Loader } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndexRange.Overlays};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
`

interface OverlayLoaderProps {
  show: boolean
}

export const OverlayLoader: React.FC<OverlayLoaderProps> = ({ show }) => {
  return !show ? null : (
    <Wrapper>
      <Loader.Spinner size={68} thickness={8} />
    </Wrapper>
  )
}

import { ToastType } from '@ur/react-components'
import styled from 'styled-components'
import { useToast } from 'util/hooks'

const Wrapper = styled.div`
  padding: 3rem;
`

export const Playground: React.FC = () => {
  const addToast = useToast()

  function toast(type: ToastType) {
    return () =>
      addToast(type, 'Lorem ipsum domet in diago dele res ipsan legos')
  }

  return (
    <Wrapper>
      <button onClick={toast('success')}>Success</button>
      <button onClick={toast('info')}>Info</button>
      <button onClick={toast('warning')}>Warning</button>
      <button onClick={toast('error')}>Error</button>
    </Wrapper>
  )
}

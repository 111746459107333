import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { useForm, useTranslate } from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import { LogoIconOnly } from 'assets/images/LogoIconOnly'
import { Button, FormField, Input, Message } from 'components'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { keyframes } from 'styled-components'
import { useToast } from 'util/hooks'
import { validatePassword } from 'util/validation'
import { CHANGE_PASSWORD_MUTATION } from './mutations'
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  height: 100%;
`
const Layout = styled.div`
  display: grid;
  grid-template-columns: 640px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.media.mobile} {
    display: block;
  }
`
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5.5rem;

  p {
    margin-bottom: 2rem;
  }

  div.message {
    display: flex;
    margin-bottom: 2rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 1.1rem;
    padding-top: 2.2rem;
  }
`
const Logo = styled.img`
  width: 60%;
`
const Header = styled.h1`
  margin: 3rem 0 1rem;

  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 2rem;
  }
`
const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  button {
    margin-top: 0.5rem;
  }

  ${props => props.theme.media.mobile} {
    gap: 2rem;
  }
`
const RightPanel = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
`
const bgPan = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const LogoWrapper = styled.div`
  animation: ${bgPan} 420s linear infinite;
  position: absolute;
  left: -1800px;
  top: -1900px;

  svg {
    width: 3500px;
  }
`

type Status = 'success' | 'error' | 'idle'

interface NeedsPasswordChangeProps {}

export const NeedsPasswordChange: React.FC<NeedsPasswordChangeProps> = () => {
  const translations = useTranslate({
    changePassword: 'common.change-password',
    description: 'forgotten-password.change-password-description',

    newPassword: 'users.new-password',
    confirmPassword: 'users.password-confirm',

    passwordUnequal: 'users.passwords-do-not-match',
    weakPassword: 'users.errors.change-password-weak',

    save: 'common.save',

    results: {
      error: 'common.error',
      errorText: 'users.errors.change-password',
    },

    toasts: {
      passwordSuccess: 'users.toasts.change-password-success',
    },
  })

  useEffect(() => {
    mixpanel.track('Forced Password Change Page Loaded')
  }, [])

  const addToast = useToast()

  const [status, setStatus] = useState<Status>('idle')

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
    enableValidation,
    submitHandler,
  } = useForm({
    values: {
      newPassword: '',
      confirmPassword: '',
    },
    validators: {
      newPassword: validatePassword(translations.weakPassword),
      confirmPassword: validatePassword(
        translations.weakPassword,
        'newPassword',
        translations.passwordUnequal
      ),
    },
    config: {
      initAsInvalid: true,
      onlyValidateAfterFirstUpdate: true,
      disableValidationInitially: ['newPassword', 'confirmPassword'],
    },
  })

  const renderStatus = useMemo(() => {
    const iconProps = (
      icon: FontAwesomeIcon = 'times',
      color: string = 'danger'
    ) => ({
      icon,
      color,
      margin: '0 1rem 0 0',
    })

    switch (status) {
      case 'idle':
      case 'success':
        return <p>{translations.description}</p>
      case 'error':
        return (
          <div className="message">
            <Message.Error
              show
              text={translations.results.errorText}
              iconProps={iconProps()}
            />
          </div>
        )
    }
  }, [status, translations.description, translations.results.errorText])

  const [changePassword, { loading: changePasswordLoading }] = useMutation<
    ChangePasswordMutation,
    Pick<ChangePasswordMutationVariables, 'newPassword'>
  >(CHANGE_PASSWORD_MUTATION, {
    refetchQueries: ['Bootstrap'],
    onCompleted(data) {
      const { ok } = data.changePassword

      const status: Status = !ok ? 'error' : 'success'

      setStatus(status)
      if (status === 'success')
        addToast('success', translations.toasts.passwordSuccess)

      mixpanel.track('Password Changed Success', {
        Success: data.changePassword.ok,
        Status: status,
      })
    },
    onError() {
      setStatus('error')
    },
  })

  function handleChangePasswordSubmit() {
    changePassword({
      variables: {
        newPassword: form.newPassword,
      },
    })
  }

  function handleKeyDown({ key }: React.KeyboardEvent) {
    if (key === 'Enter') submitHandler(handleChangePasswordSubmit)()
  }

  return (
    <Wrapper>
      <Layout>
        <LeftPanel>
          <Logo src={logo} />

          <Header>
            {status === 'idle' || status === 'success'
              ? translations.changePassword
              : translations.results.error}
          </Header>

          {renderStatus}

          <Form>
            <FormField>
              <label>{translations.newPassword}</label>
              <Input
                value={form.newPassword}
                error={errors.newPassword}
                type="password"
                autoFocus
                fullWidth
                onBlur={() => enableValidation('newPassword')}
                onChange={handler('newPassword')}
              />
            </FormField>

            <FormField>
              <label>{translations.confirmPassword}</label>
              <Input
                value={form.confirmPassword}
                error={errors.confirmPassword}
                type="password"
                fullWidth
                onBlur={() => enableValidation('confirmPassword')}
                onKeyDown={handleKeyDown}
                onChange={handler('confirmPassword')}
              />
            </FormField>

            <Button
              fullWidth
              loading={changePasswordLoading}
              disabled={!formValid}
              onClick={submitHandler(handleChangePasswordSubmit)}
            >
              {translations.save}
            </Button>
          </Form>
        </LeftPanel>

        {!isMobileOnly && (
          <RightPanel>
            <LogoWrapper>
              <LogoIconOnly />
            </LogoWrapper>
          </RightPanel>
        )}
      </Layout>
    </Wrapper>
  )
}

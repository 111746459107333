import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  DateRange,
  DateRangePicker as BaseDateRangePicker,
  IconButton,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import { TableAbsences } from './types.graphql'
import { AbsenceCard } from './AbsenceCard'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  div.no-entries {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    font-weight: 600;
    color: ${props => props.theme.colors.gray3};
  }
`
const Toolbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0.5rem 1rem 0;

  h2 {
    margin: 0;
    font-size: 1rem;
    color: ${props => props.theme.colors.gray3};

    span {
      color: ${props => props.theme.colors.gray1};
    }
  }

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`
const DateFilter = styled.div`
  position: relative;
`
const DateRangePicker = styled(BaseDateRangePicker)`
  position: fixed;
  left: 2vw;
  top: calc(${props => props.theme.sizes.headerHeightMobile} + 2vw);

  width: 96vw;
`

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
`

interface AbsencesMobileProps {
  absences: TableAbsences[]
  loading: boolean
  userFilter: TableFilteringChecklistOptions

  dateRange: DateRange

  makeMenu: (absence: TableAbsences) => TableMenu

  onFilterChange: (value: TableFiltering) => void
  onUpdateDateRange: (range: DateRange) => void
}

export const AbsencesMobile: React.FC<AbsencesMobileProps> = ({
  absences,
  loading,
  userFilter,

  dateRange,

  makeMenu,

  onFilterChange,
  onUpdateDateRange,
}) => {
  const translations = useTranslate({
    totalHours: [
      'timesheets.total-n-hours',
      { n: 0, span: (s: string) => <span>{s}</span> },
    ],
    noEntries: 'timesheets.no-entries',
  })
  const history = useHistory()
  const [datePickerOpen, setDatePickerOpen] = useState(false)

  const totalHours = useMemo(
    () => absences.reduce((acc, cur) => acc + parseFloat(cur.hours ?? '0'), 0),
    [absences]
  )

  return (
    <Wrapper>
      <ActionButton onClick={() => history.push('/absences/create')} />

      <Toolbar>
        <h2>
          {loading ? (
            <Loader.Spinner size={28} thickness={3} />
          ) : (
            translations.totalHours({ n: totalHours })
          )}
        </h2>

        <FilterWrapper>
          <DateFilter>
            <IconButton
              icon={{ icon: 'calendar-alt', size: '1.1rem' }}
              boxSize="34px"
              onClick={() => setDatePickerOpen(true)}
            />

            <DateRangePicker
              value={dateRange}
              show={datePickerOpen}
              vertical
              noBind
              singleMonth
              showClose
              onClose={() => setDatePickerOpen(false)}
              onChange={onUpdateDateRange}
            />
          </DateFilter>

          {!!userFilter && (
            <Filtering
              id="user"
              config={userFilter}
              icon={{
                icon: 'user',
                size: '1rem',
              }}
              onFilterChange={onFilterChange}
            />
          )}
        </FilterWrapper>
      </Toolbar>

      {absences.map(absence => (
        <AbsenceCard
          key={absence.id}
          absence={absence}
          editDeleteMenu={makeMenu(absence)}
        />
      ))}

      {!absences.length && (
        <div className="no-entries">{translations.noEntries}</div>
      )}
    </Wrapper>
  )
}

import { AllowedLanguage } from 'locale/util'

export function isUndefined(arg: any): arg is undefined {
  return typeof arg === 'undefined'
}

export function isNullOrUndefined(arg: any): arg is null | undefined {
  return typeof arg === 'undefined' || arg === null
}

export function isFunction(arg: any): arg is (...args: any[]) => any {
  return typeof arg === 'function'
}

export function isString(arg: any): arg is string {
  return typeof arg === 'string'
}

export function isArray(arg: any): arg is Array<any> {
  return Array.isArray(arg)
}

export function isStringArray(arg: any): arg is Array<string> {
  return isArray(arg) && arg.every(a => typeof a === 'string')
}

export function isAllowedLanguage(lang: string): lang is AllowedLanguage {
  return ['en', 'nb'].includes(lang)
}

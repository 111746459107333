import React, { useMemo } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { useUser } from '../util/hooks'

export const SuperuserRoute: React.FC<RouteProps> = props => {
  const { children, render, component: Component, ...rest } = props

  const user = useUser()
  const isSuperuser = user.isSuperuser

  const finalRender = useMemo(() => {
    if (!isSuperuser) return () => <>Unauthorized</>

    if (typeof children !== 'undefined') return () => <>{children}</>
    if (typeof render !== 'undefined') return render
    if (typeof Component !== 'undefined' && Component !== null)
      return (props: RouteComponentProps<any>) => {
        const Comp = Component as any
        return <Comp {...props} />
      }

    throw new Error('Either children, render or component must be defined')
  }, [Component, children, isSuperuser, render])

  const R = Route as any
  return <R {...rest} render={finalRender} />
}

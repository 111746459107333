import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ProjectNode, ProjectStage as Stage } from '../types.graphql'

interface WrapperProps {
  stage: Stage
  circle: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  cursor: pointer;
  font-weight: 400;
  color: ${props =>
    props.stage === 'PLANNING'
      ? props.theme.colors.matteTeal
      : props.stage === 'ONGOING'
      ? props.theme.colors.matteOrange
      : props.stage === 'BILLING'
      ? props.theme.colors.matteGreen
      : props.theme.colors.gray2};

  ${props => props.theme.media.mobile} {
    font-size: 14px;

    i {
      margin-right: 6px;
    }
    .icon-right {
      margin: 0 0 0 6px;
      color: ${props => props.theme.colors.gray3};
    }
  }
  ${props => props.theme.media.desktop} {
    i {
      ${props =>
        !props.circle
          ? css`
              margin-left: 10px;
              color: ${props.theme.colors.gray3};
            `
          : css`
              margin-right: 6px;
            `}
    }
  }
`

interface ProjectStageProps {
  className?: string

  project: Pick<ProjectNode, 'id' | 'name' | 'stage'>

  /** Show dot */
  circle?: boolean
  /** When clicked, link to project */
  link?: boolean | string
  /** Show project name instead of stage */
  named?: boolean
}
export const ProjectStage: React.FC<ProjectStageProps> = ({
  className,

  project,

  circle = false,
  link = false,
  named = false,
}) => {
  const translations = useTranslate({
    billing: 'projects.stage-billing',
    finished: 'projects.stage-finished',
    ongoing: 'projects.stage-ongoing',
    planning: 'projects.stage-planning',
  })

  const history = useHistory()

  const translatedStage = useMemo(
    () => translations[project.stage.toLowerCase() as Lowercase<Stage>],
    [project.stage, translations]
  )

  function handleClick() {
    if (!link) return
    history.push(typeof link === 'string' ? link : `/projects/${project.id}`)
  }

  return (
    <Wrapper
      className={className}
      role={link ? 'link' : undefined}
      stage={project.stage}
      circle={circle}
      onClick={handleClick}
    >
      {(isMobileOnly || circle) && (
        <Icon icon="circle" size="6px" type="solid" translateY="1px" />
      )}

      <span>{named ? project.name : translatedStage}</span>

      {!circle && (
        <Icon className="icon-right" icon="chevron-down" size="10px" />
      )}
    </Wrapper>
  )
}

import React from 'react'
import styled, { css } from 'styled-components'
import { TableMenu, UserThumbOrInitials } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useTranslate } from '@ur/react-hooks'
import { Icon } from '@ur/react-components'
import { TableUser } from './types.graphql'

const Wrapper = styled.div`
  margin-top: 1rem;
  background-color: white;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.5rem;
  align-items: center;

  padding: 1rem 0.5rem 1rem 1rem;

  font-size: 18px;
  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  a {
    color: ${props => props.theme.colors.gray1};
    font-weight: 600;
  }
`

const Content = styled.address`
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;

  font-weight: 600;
  font-style: normal;
`

interface ContactProps {
  disabled?: boolean
}
const Contact = styled.a<ContactProps>`
  color: ${props => props.theme.colors.gray3};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.gray5};
      touch-action: none;
      pointer-events: none;
    `};
`

interface UserCardProps {
  user: TableUser
  editDeleteMenu: TableMenu
}

export const UserCard: React.FC<UserCardProps> = ({ user, editDeleteMenu }) => {
  const translations = useTranslate({
    address: 'common.address',
    call: 'common.call',
    email: 'common.email',

    types: {
      company: 'customers.customer-types.company',
      private: 'customers.customer-types.private',
    },
  })

  const googleMapsURI = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
    `${user.address}, ${user.postalCode} ${user.city}`
  )}`

  return (
    <Wrapper>
      <Header>
        <UserThumbOrInitials user={user} size={36} />
        <Link to={`/users/${user.id}`}>{user.fullName}</Link>

        <Menu icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      <Content>
        <Contact href={`tel:${user.phoneNumber}`} disabled={!user.phoneNumber}>
          <Icon icon="phone" margin="0 8px 0 0" />
          {translations.call}
        </Contact>

        <Contact href={`mailto:${user.email}`} disabled={!user.email}>
          <Icon icon="envelope" margin="0 8px 0 0" />
          {translations.email}
        </Contact>

        <Contact
          href={googleMapsURI}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!user.address}
        >
          <Icon icon="crosshairs" margin="0 8px 0 0" />
          {translations.address}
        </Contact>
      </Content>
    </Wrapper>
  )
}

import { useDebounce, useTranslate } from '@ur/react-hooks'
import { ActionButton, Input, TableFiltering, TableMenu } from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import { getProjectsFiltering } from 'modules/deviations/util'
import { ShallowProject } from 'modules/projects/types.graphql'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ShallowChecklist } from '../types.graphql'
import { getChecklistStatusFiltering } from '../util'
import { ChecklistCard } from '.'
import { ChecklistStatusType } from '../types'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'

const Wrapper = styled.div`
  div.no-checklists {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`

const Toolbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

const FilterWrapper = styled.div`
  display: flex;
  gap: 24px;
`

interface ChecklistsMobileProps {
  checklists: ShallowChecklist[]
  projects?: ShallowProject[]
  search: string
  selectedStatuses: ChecklistStatusType[]

  makeMenu: (deviation: ShallowChecklist) => TableMenu

  onCreateNewChecklist: () => void
  onSearchChange: (value: string) => void
  onFilterChange: (value: TableFiltering) => void
}

export const ChecklistsMobile: React.FC<ChecklistsMobileProps> = ({
  checklists,
  projects,
  search,
  selectedStatuses,

  makeMenu,

  onCreateNewChecklist,
  onSearchChange,
  onFilterChange,
}) => {
  const translations = useTranslate({
    search: 'checklist.search',
    noData: 'common.no-data',
    noResults: 'common.no-results',

    statuses: {
      ongoing: 'projects.stage-ongoing',
      finished: 'projects.stage-finished',
    },
  })

  const canAddChecklist = usePermission(
    PERMISSIONS.checklists.add.checklist,
    PERMISSIONS.checklists.view.checklisttemplate,
    PERMISSIONS.projects.view.project
  )

  const debouncedSearch = useDebounce(search)

  const projectsFilter = useMemo(
    () => (!!projects ? getProjectsFiltering(projects) : null),
    [projects]
  )
  const statusFilter = useMemo(
    () => getChecklistStatusFiltering(translations.statuses, selectedStatuses),
    [selectedStatuses, translations.statuses]
  )

  return (
    <Wrapper>
      {canAddChecklist && <ActionButton onClick={onCreateNewChecklist} />}

      <Toolbar>
        <Input
          value={search}
          placeholder={translations.search}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          height="56px"
          onChange={onSearchChange}
        />

        <FilterWrapper>
          {!!projectsFilter && (
            <Filtering
              id="projectName"
              config={projectsFilter}
              icon={{
                icon: 'folder-open',
                size: '1rem',
              }}
              onFilterChange={value => onFilterChange(value)}
            />
          )}

          <Filtering
            id="status"
            config={statusFilter}
            icon={{
              size: '1rem',
            }}
            onFilterChange={value => onFilterChange(value)}
          />
        </FilterWrapper>
      </Toolbar>

      {checklists.map(checklist => (
        <ChecklistCard
          key={checklist.id}
          checklist={checklist}
          editDeleteMenu={makeMenu(checklist)}
        />
      ))}

      {!checklists.length && (
        <div className="no-checklists">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

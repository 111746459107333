import { useMutation } from '@apollo/client'
import { Loader } from '@ur/react-components'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { overloadColor } from 'util/style'
import { TOGGLE_CHECKLIST_ITEM_STATUS_MUTATION } from '../mutations'
import {
  ChecklistItemStatus as ChecklistItemStatusType,
  ListChecklistItem,
  ToggleChecklistItemStatusMutation,
  ToggleChecklistItemStatusMutationVariables,
} from '../types.graphql'
import mixpanel from 'mixpanel-browser'

function getBackgroundColor(status: ChecklistItemStatusType) {
  switch (status) {
    case 'DONE':
      return overloadColor('matteGreen')
    case 'NOT_DONE':
      return 'white'
    case 'IRRELEVANT':
      return overloadColor('primary')
  }
}

function getBorderColor(status: ChecklistItemStatusType) {
  switch (status) {
    case 'DONE':
      return overloadColor('matteGreen')
    case 'NOT_DONE':
      return overloadColor('gray4')
    case 'IRRELEVANT':
      return overloadColor('primary')
  }
}

function getContent(status: ChecklistItemStatusType, irrelevant: boolean) {
  if (irrelevant) return 'IR'

  switch (status) {
    case 'DONE':
      return '\f00c'
    case 'NOT_DONE':
      return 'OK'
    case 'IRRELEVANT':
      return 'IR'
  }
}

function getMobileContent(
  status: ChecklistItemStatusType,
  irrelevant: boolean
) {
  if (irrelevant) return 'IR'

  switch (status) {
    case 'DONE':
      return '\f00c'
    case 'NOT_DONE':
      return 'OK | IR'
    case 'IRRELEVANT':
      return 'IR'
  }
}

function getColor(status: ChecklistItemStatusType) {
  switch (status) {
    case 'DONE':
    case 'IRRELEVANT':
      return 'white'
    case 'NOT_DONE':
      return overloadColor('gray3')
  }
}

interface WrapperProps {
  status: ChecklistItemStatusType
  irrelevant: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;

  cursor: pointer;
  font-size: 0.8rem;
  background-color: ${props => getBackgroundColor(props.status)};
  border: 1px solid ${props => getBorderColor(props.status)};
  border-radius: 50%;

  &::after {
    content: '${props => getContent(props.status, props.irrelevant)}';

    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: ${props => getColor(props.status)};
    ${props =>
      props.status === 'DONE' &&
      css`
        top: calc(50% + 1px);

        font-family: 'Font Awesome 5 Pro';
        font-size: 1rem;
        font-weight: 900;
      `}
  }

  ${props => props.theme.media.mobile} {
    margin: 0.5rem;

    width: 4.5rem;
    height: 2rem;

    border-radius: 999px;
    font-size: 0.6rem;

    &::after {
      content: '${props => getMobileContent(props.status, props.irrelevant)}';
    }
  }
`
const Loading = styled.div`
  ${props => props.theme.media.mobile} {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
  }
`

interface ChecklistItemStatusProps {
  item: ListChecklistItem
  irrelevant?: boolean
}

export const ChecklistItemStatus: React.FC<ChecklistItemStatusProps> = ({
  item,
  irrelevant = false,
}) => {
  const onErrorAuto = useOnErrorAuto()

  const [toggleChecklistItemStatus, { loading }] = useMutation<
    ToggleChecklistItemStatusMutation,
    ToggleChecklistItemStatusMutationVariables
  >(TOGGLE_CHECKLIST_ITEM_STATUS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist'],
    onError: onErrorAuto(),
  })

  function handleToggle() {
    let status: ChecklistItemStatusType = item.status

    if (!isMobileOnly) {
      status = irrelevant
        ? item.status !== 'IRRELEVANT'
          ? 'IRRELEVANT'
          : 'NOT_DONE'
        : item.status === 'DONE'
        ? 'NOT_DONE'
        : 'DONE'
    } else {
      status =
        item.status === 'IRRELEVANT'
          ? 'NOT_DONE'
          : item.status === 'NOT_DONE'
          ? 'DONE'
          : 'IRRELEVANT'
    }

    mixpanel.track('Checklist Item Status Toggled', {
      'Checklist Item ID': item.id,
      'Checklist Item Status': status,
    })
    toggleChecklistItemStatus({
      variables: {
        id: item.id,
        input: { status },
      },
    })
  }

  const status = useMemo<ChecklistItemStatusType>(() => {
    if (isMobileOnly) return item.status

    if (irrelevant)
      return item.status === 'IRRELEVANT' ? 'IRRELEVANT' : 'NOT_DONE'

    return item.status === 'IRRELEVANT' ? 'NOT_DONE' : item.status
  }, [item.status, irrelevant])

  if (loading)
    return (
      <Loading>
        <Loader.Spinner size={32} thickness={4} />
      </Loading>
    )

  return (
    <Wrapper status={status} irrelevant={irrelevant} onClick={handleToggle} />
  )
}

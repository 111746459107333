import { Icon } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'
import { checkIsImage, getFileIcon } from 'util/files'
import { TableFile } from '../graphql'

const Wrapper = styled.span`
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;

  i {
    margin-right: 1ch;
    transform: scale(1.2);
  }
  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`

interface FileCellProps {
  file: Pick<TableFile, 'id' | 'name' | 'url' | 'originalName'>
  onImageClick: (id: string) => void
}

export const FileCell: React.FC<FileCellProps> = ({ file, onImageClick }) => {
  const icon = getFileIcon(file.originalName)

  function handleClick() {
    checkIsImage(file.url)
      ? onImageClick(file.id)
      : window.open(file.url, '_blank')
  }

  return (
    <Wrapper role="button" onClick={handleClick}>
      <Icon type="solid" icon={icon} fixedWidth />

      {file.name}
    </Wrapper>
  )
}

import { Icon } from '@ur/react-components'
import merge from 'lodash/merge'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { overloadColor } from 'util/style'
import { getDefaultTableOptions } from './consts'
import { Filtering } from './TableFiltering'
import { Menu } from './TableMenu'
import {
  CellAlignment,
  TableCell,
  TableFiltering,
  TableOptions,
  TableSorting,
} from './types'

const getAlignment = (align: CellAlignment) =>
  align === 'center' ? 'center' : `flex-${align}`

interface WrapperProps {
  row: number
  sortable: boolean

  alignVertical: CellAlignment
  alignHorizontal: CellAlignment
  overrideBackground: string | null
}
const Wrapper = styled.div<WrapperProps>`
  grid-row: ${props => props.row};

  display: flex;
  justify-content: ${props => getAlignment(props.alignHorizontal)};
  align-items: ${props => getAlignment(props.alignVertical)};

  white-space: nowrap;

  ${props =>
    !!props.overrideBackground &&
    css`
      background: ${overloadColor(props.overrideBackground)} !important;
    `};

  span.content {
    line-height: 1.2em;
  }
  span.label {
    line-height: 1.2em;

    ${props =>
      props.sortable &&
      css`
        cursor: pointer;

        span.content:hover {
          color: ${props.theme.colors.primary} !important;

          & ~ .sorting {
            color: ${props.theme.colors.primary} !important;
          }
        }
      `};
  }
  a {
    color: ${props => props.theme.colors.gray1};

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`

const Sorting = styled.div.attrs({
  role: 'button',
})`
  display: inline-block;
  margin-left: 0.5rem;
`

interface CellProps {
  cell: TableCell
  options: TableOptions

  hovering: boolean
  sorting: string[] | null

  onSortingChange: (value: TableSorting) => void
  onFilterChange: (value: TableFiltering) => void
  onMouseEnter?: (cell: TableCell) => void
  onMouseLeave?: (cell: TableCell) => void
}

export const Cell: React.FC<CellProps> = ({
  cell,
  options,

  hovering,
  sorting,

  onSortingChange,
  onFilterChange,
  onMouseEnter,
  onMouseLeave,
}) => {
  const defaultTableOptions = useMemo(
    () =>
      getDefaultTableOptions({
        fillHorizontal: options.fillHorizontal ?? false,
      }),
    [options.fillHorizontal]
  )

  // Merge together default options and custom options, using applicable option factories
  const [style, labelStyle] = useMemo<
    [React.CSSProperties, React.CSSProperties]
  >(() => {
    const type = cell.isHeader ? 'header' : 'cell'
    const defaultOptions = defaultTableOptions[type]
    const customOptions = options[type]

    const defaultParsed =
      typeof defaultOptions !== 'function' ? {} : defaultOptions(cell)

    const customParsed =
      typeof customOptions === 'function'
        ? customOptions?.(cell)
        : customOptions

    const style = merge(defaultParsed.style ?? {}, customParsed?.style ?? {})
    const labelStyle = merge(
      defaultParsed.labelStyle ?? {},
      customParsed?.labelStyle ?? {}
    )

    return [style, labelStyle]
  }, [cell, defaultTableOptions, options])

  const sortDirection = useMemo<TableSorting>(
    () =>
      !sorting
        ? 'off'
        : sorting.includes(cell.id)
        ? 'desc'
        : sorting.includes(`-${cell.id}`)
        ? 'asc'
        : 'off',
    [cell.id, sorting]
  )

  function handleSort() {
    const next =
      sortDirection === 'off'
        ? 'desc'
        : sortDirection === 'desc'
        ? 'asc'
        : 'off'
    onSortingChange(next)
  }

  function spanWrap(element: JSX.Element) {
    return typeof cell.content === 'string' ? (
      <span
        className="label"
        style={labelStyle}
        onClick={cell.sortable ? handleSort : () => void 0}
      >
        {element}
      </span>
    ) : (
      element
    )
  }

  return (
    <Wrapper
      className="--table-cell"
      row={cell.rowIndex}
      style={style}
      sortable={cell.sortable}
      alignVertical={cell.alignVertical}
      alignHorizontal={cell.alignHorizontal}
      overrideBackground={hovering ? cell.hoverBackground : null}
      onMouseEnter={() => onMouseEnter?.(cell)}
      onMouseLeave={() => onMouseLeave?.(cell)}
      onClick={
        cell.sortable && typeof cell.content !== 'string'
          ? handleSort
          : () => void 0
      }
    >
      {spanWrap(
        <>
          {!!cell.menu && cell.content !== false ? (
            <Menu icon={cell.content} config={cell.menu} />
          ) : (
            <span className="content">{cell.content}</span>
          )}

          {cell.isHeader && cell.sortable && (
            <Sorting className="sorting">
              <Icon
                type="solid"
                icon={
                  sortDirection === 'off'
                    ? 'sort'
                    : sortDirection === 'asc'
                    ? 'sort-up'
                    : 'sort-down'
                }
                hoverColor="primary"
              />
            </Sorting>
          )}

          {cell.isHeader && !!cell.filtering && (
            <Sorting className="filtering">
              <Filtering
                id={cell.id}
                config={cell.filtering}
                onFilterChange={onFilterChange}
              />
            </Sorting>
          )}
        </>
      )}
    </Wrapper>
  )
}

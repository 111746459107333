import { NoCompanies } from 'modules/companies/SelectCompany/NoCompanies'
import { ForgottenPassword, Login } from 'modules/login'
import { RegisterCompany } from 'modules/registercompany'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'containers/Routing'

interface NoCompanyRoutesProps {}

export const NoCompanyRoutes: React.FC<NoCompanyRoutesProps> = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/no-companies" component={NoCompanies} />
        <Route exact path="/register-company" component={RegisterCompany} />

        <Redirect from="" to="/login" />
      </Switch>
    </Router>
  )
}

import { RadioItem } from '@ur/react-components'
import { useClickOutside, useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  FormField as BaseFormField,
  Input,
  NumberInput,
  RadioGroup,
} from 'components'
import React, { useEffect, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { usePostalCode } from 'util/hooks/usePostalCode'
import {
  validateEmail,
  validateNonEmpty,
  validatePhoneNumber,
  validatePositiveNumber,
} from 'util/validation'
import {
  CreateCustomerMutationVariables,
  CustomerEditData,
  CustomerType,
} from './types.graphql'
import {
  OrganizationDetails,
  searchForOrganization,
  searchForOrganizationName,
} from './util'

const Card = styled.div`
  width: 450px;

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    margin-top: 1rem;
    padding: 0.5rem 0;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const Fields = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem 3rem;

  header {
    padding-bottom: 0.5rem;

    font-size: 1.2rem;
    font-weight: 400;
    color: ${props => props.theme.colors.gray1};
  }

  ${props => props.theme.media.desktop} {
    & + section {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }
  }
  ${props => props.theme.media.mobile} {
    padding: 1rem 0;
  }
`
interface GeneralInformationFieldsProps {
  type: CustomerType
}
const GeneralInformationFields = styled(Fields)<GeneralInformationFieldsProps>`
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-template-areas:
    'header  header'
    'type    type'
    'orgnr   orgnr'
    'name    name'
    'address address'
    'zip     city'
    ${props => props.type === 'PRIVATE' && "'email email'"};

  ${props => props.theme.media.mobile} {
    padding-top: 0;
  }

  header {
    grid-area: header;
  }
`
const Submit = styled(Fields)`
  padding: 0 3rem;
  border-top: 0 !important;

  ${props => props.theme.media.desktop} {
    margin: 0.5rem 0 1.5rem;
  }
  ${props => props.theme.media.mobile} {
    padding: 1rem 0;
  }
`
interface OrgNameInputProps {
  curtainOpen: boolean
}
const OrgNameInput = styled(Input)<OrgNameInputProps>`
  ${props =>
    props.curtainOpen &&
    css`
      input {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .--input-icon-right {
        border-bottom-right-radius: 0px;
      }
    `};
`
const CustomerTypeField = styled(BaseFormField)`
  label {
    transform: translateY(0.1rem);
    font-weight: 600;
    color: ${props => props.theme.colors.gray2};
  }
`
const OrganizationNumber = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`
const Curtain = styled.div`
  position: absolute;
  z-index: 2;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 150px;
  padding: 0.5rem;
  overflow-y: auto;

  border: 1px solid ${props => props.theme.colors.gray5};
  border-radius: 0 0 8px 8px;
  background-color: white;

  border-top: 0;

  & > div {
    margin: 0.5rem 0;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`
const FormField = styled(BaseFormField)`
  position: relative;
`

export interface CustomerData {
  customerType: CustomerType
  organizationNumber: string | null
  name: string
  address: string
  postalCode: string
  postalArea: string
  billingEmail: string
  defaultHourlyRate: string | undefined
  defaultMaterialDiscount: number
  contactPersonName: string
  contactPersonEmail: string
  contactPersonPhone: string
}

interface CustomerFormProps {
  customer?: CustomerEditData
  loading: boolean
  defaultHourlyRate: number

  onSubmit: (form: CreateCustomerMutationVariables) => void
}

export const CustomerForm: React.FC<CustomerFormProps> = ({
  customer,
  loading,
  defaultHourlyRate,

  onSubmit,
}) => {
  const translations = useTranslate({
    createCustomer: 'customers.create-customer',
    editCustomer: 'customers.edit-customer',

    billing: 'common.billing',
    contactPerson: 'customers.contact-person',
    general: 'customers.general-information',
    get: 'customers.retrieve',

    address: 'common.address',
    billingEmail: 'customers.billing-email-label',
    defaultHourlyRate: 'common.default-hourly-rate',
    defaultMaterialDiscount: 'common.default-material-discount',
    contactPersonEmail: 'common.email',
    name: 'common.name',
    organizationNumber: 'customers.organization-number',
    contactPersonPhone: 'common.phone',
    postalArea: 'common.city',
    postalCode: 'common.zip',

    customerTypes: {
      company: 'customers.customer-types.company',
      private: 'customers.customer-types.private',
    },

    results: {
      error: 'customers.create-error',
      formError: 'common.error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },

    validation: {
      invalidEmail: 'errors.invalid-email-address',
      invalidNumber: 'errors.invalid-number-must-be-positive',
      invalidPhoneNumber: 'errors.invalid-phone-number',
      noResult: 'common.no-results',
      required: 'common.required',
    },
  })

  const customerInserted = useRef(false)
  const curtainRef = useRef<HTMLDivElement>(null)

  const [brregResults, setBrregResults] = useState<OrganizationDetails[]>([])
  const [curtainOpen, setCurtainOpen] = useState(false)

  useClickOutside(curtainRef, () => setCurtainOpen(false), true)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    updateFormErrors,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<CustomerData>({
    values: {
      customerType: 'COMPANY',
      organizationNumber: null,
      name: '',
      address: '',
      postalCode: '',
      postalArea: '',
      billingEmail: '',
      defaultHourlyRate: defaultHourlyRate.toString(),
      defaultMaterialDiscount: 0,
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
    },
    validators: {
      name: validateNonEmpty(translations.validation.required),
      address: validateNonEmpty(translations.validation.required),
      postalCode: validateNonEmpty(' '),
      postalArea: validateNonEmpty(translations.validation.required),
      billingEmail: val =>
        !val ? null : validateEmail(translations.validation.invalidEmail)(val),
      defaultHourlyRate: val =>
        !val
          ? null
          : validatePositiveNumber(
              translations.validation.invalidNumber,
              true
            )(parseFloat(val)),
      contactPersonEmail: val =>
        !val ? null : validateEmail(translations.validation.invalidEmail)(val),
      contactPersonPhone: val =>
        !val
          ? null
          : validatePhoneNumber(translations.validation.invalidPhoneNumber)(
              val
            ),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const { loading: postalAreaLoading } = usePostalCode(form.postalCode, {
    onCityChange(postalArea) {
      !!postalArea && updateForm({ postalArea })
    },
  })

  useEffect(() => {
    if (!customer || customerInserted.current) return
    customerInserted.current = true

    const values: typeof form = {
      customerType: customer.customerType,
      organizationNumber: customer.organizationNumber ?? null,
      name: customer.name,
      address: customer.address,
      postalArea: customer.postalArea,
      postalCode: customer.postalCode,
      billingEmail: customer.billingEmail ?? '',
      defaultHourlyRate: customer.defaultHourlyRate.toString(),
      defaultMaterialDiscount: customer.defaultMaterialDiscount,
      contactPersonName: customer.contactPersonName ?? '',
      contactPersonEmail: customer.contactPersonEmail ?? '',
      contactPersonPhone: customer.contactPersonPhone ?? '',
    }

    updateForm(values)
    updateInitialValues(values)
  }, [customer, updateForm, updateInitialValues])

  function handleRetrieveOrganization() {
    if (form.organizationNumber?.length === 9) {
      searchForOrganization(form.organizationNumber)
        .then(data => {
          updateForm({
            ...data,
            organizationNumber: form.organizationNumber,
          })
          updateFormErrors({
            organizationNumber: null,
          })
        })
        .catch(() => {
          updateFormErrors({
            organizationNumber: translations.validation.noResult,
          })
        })
    }
  }

  function handleNameSearch(name: string) {
    if (name.length > 3) {
      searchForOrganizationName(name)
        .then(data => {
          setBrregResults(data ?? [])
          setCurtainOpen(!!data)
        })
        .catch(() => {
          updateFormErrors({
            name: translations.validation.noResult,
          })
        })
    }
    handler('name')(name)
  }

  function handleSubmit(values: typeof form) {
    onSubmit({
      input: {
        ...values,
        defaultHourlyRate: !!form.defaultHourlyRate
          ? parseFloat(form.defaultHourlyRate)
          : null,
        organizationNumber: !!values.organizationNumber
          ? values.organizationNumber
          : null,
      },
    })
  }

  const customerTypeRadios: RadioItem<CustomerType>[] = [
    {
      value: 'COMPANY',
      label: translations.customerTypes.company,
    },
    {
      value: 'PRIVATE',
      label: translations.customerTypes.private,
    },
  ]

  return (
    <Card>
      <GeneralInformationFields type={form.customerType}>
        <header>{translations.general}</header>

        <CustomerTypeField area="type">
          <RadioGroup
            value={form.customerType}
            radios={customerTypeRadios}
            layout={{ direction: 'horizontal', type: 'flex', gap: '2rem' }}
            checkboxProps={{
              labelPosition: 'left',
            }}
            onChange={value => updateForm({ customerType: value })}
          />
        </CustomerTypeField>

        {form.customerType === 'COMPANY' ? (
          <FormField error={!!errors.organizationNumber} area="orgnr">
            <label>{translations.organizationNumber}</label>

            <OrganizationNumber>
              <Input
                name="organizationNumber"
                value={form.organizationNumber ?? ''}
                error={errors.organizationNumber}
                disabled={loading}
                autoFocus
                fullWidth
                onEnter={handleRetrieveOrganization}
                onChange={handler('organizationNumber')}
              />

              <Button
                fullWidth
                height={isMobileOnly ? '56px' : '40px'}
                disabled={!(form.organizationNumber?.length === 9)}
                onClick={handleRetrieveOrganization}
              >
                {translations.get}
              </Button>
            </OrganizationNumber>
          </FormField>
        ) : (
          <FormField error={!!errors.contactPersonPhone} area="orgnr">
            <label>{translations.contactPersonPhone}</label>

            <Input
              name="contactPersonPhone"
              value={form.contactPersonPhone}
              error={errors.contactPersonPhone}
              inputMode="tel"
              disabled={loading}
              fullWidth
              onChange={handler('contactPersonPhone')}
            />
          </FormField>
        )}

        <FormField required error={!!errors.name} area="name">
          <label>{translations.name}</label>

          <div ref={curtainRef}>
            <OrgNameInput
              name="name"
              value={form.name}
              error={errors.name}
              disabled={loading}
              iconRightProps={{
                icon: curtainOpen ? 'chevron-up' : 'chevron-down',
                color: !brregResults.length ? 'gray5' : 'gray3',
                cursor: !brregResults.length ? 'default' : 'pointer',
                onClick: () => !!brregResults.length && setCurtainOpen(v => !v),
              }}
              fullWidth
              curtainOpen={curtainOpen}
              onChange={handleNameSearch}
            />

            {!!brregResults && curtainOpen && (
              <Curtain>
                {brregResults.map(result => (
                  <div
                    key={result.organizationNumber}
                    onClick={() => {
                      updateForm({
                        ...result,
                      })
                      setCurtainOpen(false)
                    }}
                  >
                    {result.name}
                  </div>
                ))}
              </Curtain>
            )}
          </div>
        </FormField>

        <FormField required error={!!errors.address} area="address">
          <label>{translations.address}</label>

          <Input
            name="address"
            value={form.address}
            error={errors.address}
            disabled={loading}
            fullWidth
            onChange={handler('address')}
          />
        </FormField>

        <FormField required error={!!errors.postalCode} area="zip">
          <label>{translations.postalCode}</label>

          <Input
            name="postalCode"
            autoComplete="postal-code"
            value={form.postalCode}
            error={errors.postalCode}
            disabled={loading}
            fullWidth
            onChange={handler('postalCode')}
          />
        </FormField>

        <FormField required error={!!errors.postalArea} area="city">
          <label>{translations.postalArea}</label>

          <Input
            name="postalArea"
            value={form.postalArea}
            error={errors.postalArea}
            disabled={loading}
            loading={postalAreaLoading}
            fullWidth
            onChange={handler('postalArea')}
          />
        </FormField>

        {form.customerType === 'PRIVATE' && (
          <FormField error={!!errors.contactPersonEmail} area="email">
            <label>{translations.contactPersonEmail}</label>

            <Input
              name="contactPersonEmail"
              value={form.contactPersonEmail}
              error={errors.contactPersonEmail}
              inputMode="email"
              disabled={loading}
              fullWidth
              onChange={handler('contactPersonEmail')}
            />
          </FormField>
        )}
      </GeneralInformationFields>

      <Fields>
        <header>{translations.billing}</header>

        <FormField error={!!errors.billingEmail}>
          <label>{translations.billingEmail}</label>

          <Input
            name="billingEmail"
            value={form.billingEmail}
            error={errors.billingEmail}
            inputMode="email"
            disabled={loading}
            fullWidth
            onChange={handler('billingEmail')}
          />
        </FormField>

        <FormField error={!!errors.defaultHourlyRate}>
          <label>{translations.defaultHourlyRate}</label>

          <Input
            name="defaultHourlyRate"
            type="number"
            value={form.defaultHourlyRate ?? ''}
            error={errors.defaultHourlyRate}
            disabled={loading}
            fullWidth
            onChange={handler('defaultHourlyRate')}
          />
        </FormField>

        <FormField error={!!errors.defaultMaterialDiscount}>
          <label>{translations.defaultMaterialDiscount}</label>

          <NumberInput
            name="defaultMaterialDiscount"
            value={form.defaultMaterialDiscount}
            error={errors.defaultMaterialDiscount}
            disabled={loading}
            min={0}
            fullWidth
            onChange={handler('defaultMaterialDiscount')}
          />
        </FormField>
      </Fields>

      {form.customerType === 'COMPANY' && (
        <Fields>
          <header>{translations.contactPerson}</header>

          <FormField error={!!errors.contactPersonName}>
            <label>{translations.name}</label>

            <Input
              name="contactPersonName"
              value={form.contactPersonName}
              error={errors.contactPersonName}
              disabled={loading}
              fullWidth
              onChange={handler('contactPersonName')}
            />
          </FormField>

          <FormField error={!!errors.contactPersonEmail}>
            <label>{translations.contactPersonEmail}</label>

            <Input
              name="contactPersonEmail"
              value={form.contactPersonEmail}
              error={errors.contactPersonEmail}
              inputMode="email"
              disabled={loading}
              fullWidth
              onChange={handler('contactPersonEmail')}
            />
          </FormField>

          <FormField error={!!errors.contactPersonPhone}>
            <label>{translations.contactPersonPhone}</label>

            <Input
              name="contactPersonPhone"
              value={form.contactPersonPhone}
              error={errors.contactPersonPhone}
              inputMode="tel"
              disabled={loading}
              fullWidth
              onChange={handler('contactPersonPhone')}
            />
          </FormField>
        </Fields>
      )}

      <Submit>
        <Button
          disabled={!formValid || !formEdited || loading}
          onClick={submitHandler(handleSubmit)}
        >
          {customer ? translations.editCustomer : translations.createCustomer}
        </Button>
      </Submit>
    </Card>
  )
}

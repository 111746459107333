import { gql } from '@apollo/client'

export const DELETE_CHECKLIST_MUTATION = gql`
  mutation DeleteChecklist($id: ID!) {
    deleteChecklist(id: $id) {
      found
      deletedId
    }
  }
`

// Checklists

export const CREATE_CHECKLIST_MUTATION = gql`
  mutation CreateChecklist($input: CreateChecklistInput!) {
    createChecklist(input: $input) {
      checklist {
        id
        project {
          id
        }
      }
    }
  }
`

export const PATCH_CHECKLIST_MUTATION = gql`
  mutation PatchChecklist($id: ID!, $input: PatchChecklistInput!) {
    patchChecklist(id: $id, input: $input) {
      checklist {
        id
      }
    }
  }
`

export const CLOSE_CHECKLIST_MUTATION = gql`
  mutation CloseChecklist($id: ID!, $sendEmail: Boolean, $tz: String) {
    closeChecklist(id: $id, sendEmail: $sendEmail, tz: $tz) {
      checklist {
        id
      }
      emailSent
    }
  }
`

export const SEND_CHECKLIST_EMAIL_MUTATION = gql`
  mutation SendChecklistEmail($id: ID!, $recipient: String!, $tz: String) {
    sendChecklistEmail(id: $id, recipient: $recipient, tz: $tz) {
      emailSent
    }
  }
`

// ChecklistItems

export const PATCH_CHECKLIST_ITEM_MUTATION = gql`
  mutation PatchChecklistItem($id: ID!, $input: PatchChecklistItemInput!) {
    patchChecklistItem(id: $id, input: $input) {
      checklistItem {
        id
        title
        description
        status
        index
        comments {
          edges {
            node {
              id
              content
              postedAt
              postedBy {
                id
                fullName
              }
            }
          }
        }
        images {
          edges {
            node {
              id
              image
              title
            }
          }
        }
      }
    }
  }
`

export const TOGGLE_CHECKLIST_ITEM_STATUS_MUTATION = gql`
  mutation ToggleChecklistItemStatus(
    $id: ID!
    $input: PatchChecklistItemInput!
  ) {
    patchChecklistItem(id: $id, input: $input) {
      checklistItem {
        id
      }
    }
  }
`

// ChecklistItemComments

export const CREATE_CHECKLIST_ITEM_COMMENT_MUTATION = gql`
  mutation CreateChecklistItemComment(
    $input: CreateChecklistItemCommentInput!
  ) {
    createChecklistItemComment(input: $input) {
      checklistItemComment {
        id
      }
    }
  }
`

export const PATCH_CHECKLIST_ITEM_COMMENT_MUTATION = gql`
  mutation PatchChecklistItemComment(
    $id: ID!
    $input: PatchChecklistItemCommentInput!
  ) {
    patchChecklistItemComment(id: $id, input: $input) {
      checklistItemComment {
        id
      }
    }
  }
`

export const DELETE_CHECKLIST_ITEM_COMMENT_MUTATION = gql`
  mutation DeleteChecklistItemComment($id: ID!) {
    deleteChecklistItemComment(id: $id) {
      found
      deletedId
    }
  }
`

// ChecklistItemImages

export const BATCH_CREATE_CHECKLIST_ITEM_IMAGE_MUTATION = gql`
  mutation BatchCreateChecklistItemImage(
    $input: [BatchCreateChecklistItemImageInput]!
  ) {
    batchCreateChecklistItemImage(input: $input) {
      checklistItemImages {
        id
        title
        image
        imageCompressed
      }
    }
  }
`

export const BATCH_DELETE_CHECKLIST_ITEM_IMAGE_MUTATION = gql`
  mutation BatchDeleteChecklistItemImage($ids: [ID]!) {
    batchDeleteChecklistItemImage(ids: $ids) {
      deletionCount
      deletedIds
    }
  }
`

// ChecklistTemplates

export const CREATE_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation CreateChecklistTemplate($input: CreateChecklistTemplateInput!) {
    createChecklistTemplate(input: $input) {
      checklistTemplate {
        id
      }
    }
  }
`

export const PATCH_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation PatchChecklistTemplate(
    $id: ID!
    $input: PatchChecklistTemplateInput!
  ) {
    patchChecklistTemplate(id: $id, input: $input) {
      checklistTemplate {
        id
      }
    }
  }
`

export const DELETE_CHECKLIST_TEMPLATE_MUTATION = gql`
  mutation DeleteChecklistTemplate($id: ID!) {
    deleteChecklistTemplate(id: $id) {
      found
      deletedId
    }
  }
`

// ChecklistTemplateItems

export const CREATE_CHECKLIST_TEMPLATE_ITEM_MUTATION = gql`
  mutation CreateChecklistTemplateItem(
    $input: CreateChecklistTemplateItemInput!
  ) {
    createChecklistTemplateItem(input: $input) {
      checklistTemplateItem {
        id
      }
    }
  }
`

export const PATCH_CHECKLIST_TEMPLATE_ITEM_MUTATION = gql`
  mutation PatchChecklistTemplateItem(
    $id: ID!
    $input: PatchChecklistTemplateItemInput!
  ) {
    patchChecklistTemplateItem(id: $id, input: $input) {
      checklistTemplateItem {
        id
      }
    }
  }
`

export const SHIFT_CHECKLIST_TEMPLATE_ITEM_INDEX_MUTATION = gql`
  mutation ShiftChecklistTemplateItemIndex($id: ID!, $delta: Int!) {
    shiftChecklistTemplateItemIndex(item: $id, delta: $delta) {
      checklistTemplateItem {
        id
      }
    }
  }
`

export const DELETE_CHECKLIST_TEMPLATE_ITEM_MUTATION = gql`
  mutation DeleteChecklistTemplateItem($id: ID!) {
    deleteChecklistTemplateItem(id: $id) {
      found
      deletedId
    }
  }
`

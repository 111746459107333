import { useQuery } from '@apollo/client'
import { SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  DatePicker,
  FormField as BaseFormField,
  QuickNumberInput,
  Select,
  TextArea,
} from 'components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useDateFns, useOnErrorAuto } from 'util/hooks'
import { validateInRange, validateNonEmpty } from 'util/validation'
import { MY_TIME_BANK_QUERY } from '../queries'
import {
  AbsenceFormType,
  AbsenceType,
  CreateAbsenceMutationVariables,
  MyTimeBankQuery,
  PatchAbsenceMutationVariables,
} from '../types.graphql'

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;

  ${props => props.theme.media.mobile} {
    flex-direction: column;
    gap: 0rem;
  }
`

const Card = styled.div`
  width: 450px;
  height: 100%;
  position: relative;

  header {
    font-size: 18px;
    border-bottom: 1px solid ${props => props.theme.colors.gray4};
    padding: 0 2.75rem 1rem;
  }

  span.field {
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.75rem 0;

    div:first-child {
      font-weight: 600;
    }
  }

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    margin-top: 1rem;

    padding: 2rem 0rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;

    header {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: ${props => props.theme.colors.gray2};
    }
    .button {
      margin-top: 1.5rem;
    }
    span.field {
      background-color: white;
      padding: 1rem;
    }
  }
`

const FormField = styled(BaseFormField)`
  margin-bottom: 1rem;
  padding: 0 2.75rem;
`

const Button = styled(BaseButton)`
  margin: 0 2.75rem;
  width: calc(100% - 2 * 2.75rem);
`

export interface AbsenceData {
  date: Date
  reason: string
  type: AbsenceType
  hours: number
}

interface AbsenceFormProps {
  absence?: AbsenceFormType
  loading: boolean

  onSubmit: (
    form: CreateAbsenceMutationVariables | PatchAbsenceMutationVariables
  ) => void
}

export const AbsenceForm: React.FC<AbsenceFormProps> = ({
  absence,
  loading,

  onSubmit,
}) => {
  const translations = useTranslate({
    registerAbsence: 'timesheets.register-absence',
    save: 'common.save',

    timebankSaldo: 'timesheets.time-bank-balance',
    total: 'common.total',
    withdrawn: 'timesheets.withdrawn',
    balance: 'common.balance',

    form: {
      date: 'common.date',
      absenceType: 'timesheets.absence-type',
      numberOfHours: 'timesheets.number-of-hours',
      reason: 'common.reason',
    },

    absenceTypes: {
      ABSENCE_VACATION: 'timesheets.absence-type-vacation',
      ABSENCE_PAID_LEAVE: 'timesheets.absence-type-paid-leave',
      ABSENCE_UNPAID_LEAVE: 'timesheets.absence-unpaid-leave',
      ABSENCE_ILLNESS_SELF_DECLARATION: 'timesheets.absence-self-declaration',
      ABSENCE_ILLNESS_SICK_LEAVE: 'timesheets.absence-type-illness-sick-leave',
      ABSENCE_TIMEBANK_WITHDRAWAL:
        'timesheets.absence-type-timebank-withdrawal',
    },

    validation: {
      required: 'common.required',
      outOfRange: 'timesheets.errors.hours-range',
    },
  })

  const absenceInserted = useRef(false)

  const onErrorAuto = useOnErrorAuto()
  const { format } = useDateFns()

  const [initialHours, setInitialHours] = useState<{
    type: AbsenceType
    hours: number
  } | null>(null)

  const {
    formValues: form,
    formErrors: errors,
    formEdited,
    formValid,
    updateForm,
    updateInitialValues,
    submitHandler,
  } = useForm<AbsenceData>({
    values: {
      date: new Date(),
      reason: '',
      type: 'ABSENCE_VACATION',
      hours: 0,
    },
    validators: {
      date: val =>
        validateNonEmpty(translations.validation.required)(val.toString()),
      type: validateNonEmpty(translations.validation.required),
      hours: validateInRange(translations.validation.outOfRange, 0.0001, 24),
      reason: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const { data: myTimebankData } = useQuery<MyTimeBankQuery, never>(
    MY_TIME_BANK_QUERY,
    {
      skip: !!absence,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      onError: onErrorAuto(),
    }
  )

  const timebank = useMemo(
    () => ({
      withdrawn:
        absence?.employee.timebankWithdrawal ??
        myTimebankData?.me.timebankWithdrawal ??
        0,
      total:
        absence?.employee.timebankTotal ??
        myTimebankData?.me.timebankTotal ??
        0,
    }),
    [
      absence?.employee.timebankTotal,
      absence?.employee.timebankWithdrawal,
      myTimebankData?.me.timebankTotal,
      myTimebankData?.me.timebankWithdrawal,
    ]
  )

  useEffect(() => {
    if (!absence || absenceInserted.current) return
    absenceInserted.current = true

    const values: typeof form = {
      date: absence.date ? new Date(absence.date) : new Date(),
      reason: absence.reason ?? '',
      type: absence.type,
      hours: parseFloat(absence.hours ?? '0'),
    }

    setInitialHours({
      type: absence.type,
      hours: parseFloat(absence.hours ?? '0'),
    })

    updateForm(values)
    updateInitialValues(values)
  }, [absence, updateForm, updateInitialValues])

  const absenceTypeOptions = useMemo<SelectOption<AbsenceType>[]>(
    () =>
      (
        [
          'ABSENCE_VACATION',
          'ABSENCE_PAID_LEAVE',
          'ABSENCE_UNPAID_LEAVE',
          'ABSENCE_ILLNESS_SELF_DECLARATION',
          'ABSENCE_ILLNESS_SICK_LEAVE',
          'ABSENCE_TIMEBANK_WITHDRAWAL',
        ] as const
      ).map(type => ({
        value: type,
        label: translations.absenceTypes[type],
      })) ?? [],
    [translations.absenceTypes]
  )

  function handleSubmit(values: typeof form) {
    const input = {
      date: format(values.date, 'yyyy-MM-dd'),
      reason: values.reason,
      type: values.type,
      hours: values.hours,
    }
    if (!!absence) onSubmit({ id: absence.id, input })
    else onSubmit({ input })
  }

  const disableSubmitButton = loading || !formEdited || !formValid

  const totalWithdrawn = useMemo(
    () =>
      initialHours?.type === 'ABSENCE_TIMEBANK_WITHDRAWAL'
        ? form.type === 'ABSENCE_TIMEBANK_WITHDRAWAL'
          ? timebank.withdrawn - initialHours.hours + form.hours
          : timebank.withdrawn - form.hours
        : form.type === 'ABSENCE_TIMEBANK_WITHDRAWAL'
        ? timebank.withdrawn + form.hours
        : timebank.withdrawn,
    [form, initialHours, timebank.withdrawn]
  )

  return (
    <Wrapper>
      <Card>
        <section>
          <FormField error={!!errors.date} required>
            <label>{translations.form.date}</label>

            <DatePicker
              value={form.date}
              fullWidth
              onChange={value => {
                updateForm({ date: value })
              }}
            />
          </FormField>

          <FormField error={!!errors.type} required>
            <label>{translations.form.absenceType}</label>

            <Select
              value={form.type}
              options={absenceTypeOptions}
              error={errors.type}
              disabled={loading}
              fullWidth
              onChange={value => !!value && updateForm({ type: value })}
            />
          </FormField>

          <FormField error={!!errors.hours} required>
            <label>{translations.form.numberOfHours}</label>

            <QuickNumberInput
              value={form.hours}
              disabled={loading}
              error={errors.hours}
              fullWidth
              onChange={value => updateForm({ hours: value })}
            />
          </FormField>

          <FormField error={!!errors.reason} required>
            <label>{translations.form.reason}</label>

            <TextArea
              value={form.reason}
              error={errors.reason}
              disabled={loading}
              fullWidth
              height="100px"
              onChange={value => {
                updateForm({ reason: value })
              }}
            />
          </FormField>

          {!isMobileOnly && (
            <Button
              disabled={disableSubmitButton}
              fullWidth
              loading={loading}
              onClick={submitHandler(handleSubmit)}
            >
              {absenceInserted.current
                ? translations.save
                : translations.registerAbsence}
            </Button>
          )}
        </section>
      </Card>

      <Card>
        <header>{translations.timebankSaldo}</header>

        <span className="field">
          <div>{translations.total}</div>
          <div>{timebank.total.toFixed(2)}</div>
        </span>

        <span className="field">
          <div>{translations.withdrawn}</div>
          <div>{totalWithdrawn.toFixed(2)}</div>
        </span>

        <span className="field">
          <div>{translations.balance}</div>
          <div>{(timebank.total - totalWithdrawn).toFixed(2)}</div>
        </span>

        {isMobileOnly && (
          <Button
            className="button"
            disabled={disableSubmitButton}
            loading={loading}
            onClick={submitHandler(handleSubmit)}
          >
            {absenceInserted.current
              ? translations.save
              : translations.registerAbsence}
          </Button>
        )}
      </Card>
    </Wrapper>
  )
}

import React, { useMemo } from 'react'
import styled from 'styled-components'
import deviations from 'assets/images/emptyPage/Deviations.svg'
import dashboard from 'assets/images/emptyPage/Dashboard.svg'
import checklists from 'assets/images/emptyPage/Checklists.svg'
import timesheets from 'assets/images/emptyPage/Timesheets.svg'
import products from 'assets/images/emptyPage/Products.svg'
import projects from 'assets/images/emptyPage/Projects.svg'
import handbook from 'assets/images/emptyPage/Handbook.svg'

interface EmptyPageImageWrapperProps {
  pictureHeight?: string
  pictureWidth?: string
}

const EmptyPageImageWrapper = styled.img<EmptyPageImageWrapperProps>`
  max-width: ${props => props.pictureWidth};
  max-height: ${props => props.pictureHeight};
`

export type PictureType =
  | 'projects'
  | 'timesheets'
  | 'absences'
  | 'deviations'
  | 'internalDeviations'
  | 'checklists'
  | 'checklistTemplates'
  | 'products'
  | 'importedProducts'
  | 'handbook'
  | 'dashboard'

interface EmptyPageImageProps extends EmptyPageImageWrapperProps {
  picture: PictureType
}

export const EmptyPageImage: React.FC<EmptyPageImageProps> = ({
  picture,
  pictureHeight = '100%',
  pictureWidth = '100%',
}) => {
  const image = useMemo(() => {
    switch (picture) {
      case 'projects':
        return projects
      case 'checklists':
      case 'checklistTemplates':
        return checklists
      case 'deviations':
      case 'internalDeviations':
        return deviations
      case 'handbook':
        return handbook
      case 'products':
      case 'importedProducts':
        return products
      case 'timesheets':
      case 'absences':
        return timesheets
      case 'dashboard':
      default:
        return dashboard
    }
  }, [picture])

  return (
    <EmptyPageImageWrapper
      src={image}
      pictureHeight={pictureHeight}
      pictureWidth={pictureWidth}
    />
  )
}

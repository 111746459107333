/* eslint react-hooks/exhaustive-deps: "off" */
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { enGB, nb } from 'date-fns/locale'
import {
  format as formatBase,
  formatDistance as formatDistanceBase,
  formatDistanceStrict as formatDistanceStrictBase,
  formatDistanceToNow as formatDistanceToNowBase,
  endOfWeek as endOfWeekBase,
  formatRelative as formatRelativeBase,
  getWeek as getWeekBase,
  startOfWeek as startOfWeekBase,
} from './functions'
import { USER_SETTING_LANGUAGE_DEFAULT } from 'modules/users/consts'

const locales: { [locale: string]: Locale } = { en: enGB, nb }

export function useDateFns() {
  const intl = useIntl()
  const locale = useMemo(
    () =>
      locales.hasOwnProperty(intl.locale)
        ? locales[intl.locale]
        : locales[USER_SETTING_LANGUAGE_DEFAULT],
    [intl.locale]
  )

  const endOfWeek = useCallback(endOfWeekBase(locale), [locale])
  const format = useCallback(formatBase(locale), [locale])
  const formatDistance = useCallback(formatDistanceBase(locale), [locale])
  const formatDistanceStrict = useCallback(formatDistanceStrictBase(locale), [
    locale,
  ])
  const formatDistanceToNow = useCallback(formatDistanceToNowBase(locale), [
    locale,
  ])
  const formatRelative = useCallback(formatRelativeBase(locale), [locale])
  const getWeek = useCallback(getWeekBase(locale), [locale])
  const startOfWeek = useCallback(startOfWeekBase(locale), [locale])

  return {
    endOfWeek,
    format,
    formatDistance,
    formatDistanceStrict,
    formatDistanceToNow,
    formatRelative,
    getWeek,
    startOfWeek,
  }
}

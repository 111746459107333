import {
  TimePicker as BaseTimePicker,
  TimePickerProps as BaseTimePickerProps,
} from '@ur/react-components'
import styled from 'styled-components'

const StyledTimePicker = styled(BaseTimePicker)``

interface TimePickerProps extends BaseTimePickerProps {}

export const TimePicker: React.FC<TimePickerProps> = ({ ...props }) => {
  return <StyledTimePicker {...props} />
}

import { gql } from '@apollo/client'

/* PROJECT TIME ENTRIES */
export const TIME_ENTRY_QUERY = gql`
  query TimeEntry($id: ID!) {
    timeEntry(id: $id) {
      id
      date
      hoursOrdinary
      hoursOvertimeFifty
      hoursOvertimeHundred
      hoursTimeBankOrdinary
      hoursTimeBankFifty
      hoursTimeBankHundred
      description
      kilometersDriven
      project {
        id
        name
      }
    }
  }
`

export const TIME_ENTRIES_QUERY = gql`
  query TimeEntries(
    $orderBy: String
    $project: ID
    $userId: ID
    $dateGte: Date
    $dateLte: Date
    $first: Int
    $after: String
  ) {
    hasTimeEntries
    allTimeEntries(
      orderBy: $orderBy
      project: $project
      userId: $userId
      dateGte: $dateGte
      dateLte: $dateLte
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          description
          date
          sum
          kilometersDriven
          hoursOrdinary
          hoursOvertimeFifty
          hoursOvertimeHundred
          hoursTimeBankFifty
          hoursTimeBankHundred
          hoursTimeBankOrdinary
          employee {
            id
            fullName
            profilePicture
            initials
          }

          project {
            id
            name
            billingType
            totalHours
            stage

            customer {
              id
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const TIME_ENTRY_FILTERS_QUERY = gql`
  query TimeEntryFilters {
    timeEntryFilters {
      projects {
        id
        name
      }
      users {
        id
        fullName
      }
    }
  }
`

export const MY_TIME_BANK_QUERY = gql`
  query MyTimeBank {
    me {
      id
      timebankWithdrawal
      timebankTotal
    }
  }
`

/* ABSCENCE */

export const ABSENCE_QUERY = gql`
  query AbsenceQuery($id: ID!) {
    absence(id: $id) {
      id
      date
      reason
      hours
      type
      selfDeclarationType
      employee {
        id
        fullName
        timebankTotal
        timebankWithdrawal
      }
    }
  }
`

export const ABSENCES_QUERY = gql`
  query Absences(
    $userId: ID
    $orderBy: String
    $dateGte: Date
    $dateLte: Date
    $first: Int
    $after: String
  ) {
    hasAbsences
    allAbsences(
      userId: $userId
      orderBy: $orderBy
      dateGte: $dateGte
      dateLte: $dateLte
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          employee {
            id
            fullName
            initials
            profilePicture
          }
          reason
          hours
          type
          date
          selfDeclarationType
        }
      }
    }
  }
`
export const ABSENCE_FILTERS_QUERY = gql`
  query AbsenceFilters {
    absenceFilters {
      users {
        id
        fullName
      }
    }
  }
`

import { isMobileOnly } from 'react-device-detect'

export interface SidebarStore {
  open: boolean
  userMenuOpen: boolean
}
export const sidebarStore: SidebarStore = {
  open: !isMobileOnly,
  userMenuOpen: false,
}

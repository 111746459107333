import React from 'react'
import styled from 'styled-components'
import { ShallowProject } from './types.graphql'
import { ProjectStage } from './components'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useDateFns } from 'util/hooks'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.div`
  margin: 1rem 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;

    &.menus {
      justify-content: space-between;
      padding: 0 0.5rem 0 1rem;
    }
  }
`
const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;

  a {
    font-size: 18px;
    font-weight: 600;
  }
`

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 1px 0 0 0;

  & > div {
    display: flex;
    padding: 0.8rem 1rem;

    color: ${props => props.theme.colors.gray3};
    font-size: 14px;
    white-space: nowrap;

    & + div {
      border-left: 1px solid ${props => props.theme.colors.gray6};
    }
  }
`

interface ProjectCardProps {
  project: ShallowProject
  stageMenu: TableMenu
  editDeleteMenu: TableMenu
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  stageMenu,
  editDeleteMenu,
}) => {
  const translations = useTranslate({
    hoursSpent: 'projects.hours-spent',
    estimate: 'common.estimate',
  })
  const { format } = useDateFns()

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(project.createdAt), 'PPP')}</DateField>

        <div className="menus">
          <Menu icon={<ProjectStage project={project} />} config={stageMenu} />

          <Menu icon="ellipsis-h" config={editDeleteMenu} />
        </div>
      </Header>

      <Content>
        <Link to={`/projects/${project.id}`}>{project.name}</Link>
        <div>{project.customer?.name}</div>
      </Content>

      <Footer>
        <div>
          <strong>{translations.hoursSpent + ':'}&nbsp;</strong>
          <span>{project.totalHours}</span>
        </div>

        <div>
          <strong>{translations.estimate + ':'}&nbsp;</strong>
          <span>{project.estimatedCompletionTime ?? '-'}</span>
        </div>
      </Footer>
    </Wrapper>
  )
}

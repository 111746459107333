import { useMutation, useQuery } from '@apollo/client'
import { Image, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableMenu, TreeItem } from 'components'
import { useCallback, useMemo } from 'react'
import { IdVariable } from 'types/graphql'
import { checkIsImage } from 'util/files'
import {
  useConfirm,
  useOnErrorAuto,
  usePermission,
  usePromptString,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import {
  MoveFileModal,
  UploadFileModalResolve,
  UploadFilesModal,
} from './components'
import {
  FileDetailsModal,
  FileDetailsModalResolve,
} from './components/FileDetailsModal'
import {
  BatchCreateFileMutation,
  BatchCreateFileMutationVariables,
  BATCH_CREATE_FILE_MUTATION,
  ChecklistFoldersQuery,
  ChecklistFoldersQueryVariables,
  ChecklistImage,
  ChecklistImagesQuery,
  ChecklistImagesQueryVariables,
  CHECKLIST_FOLDERS_QUERY,
  CHECKLIST_IMAGES_QUERY,
  CreateFolderMutation,
  CreateFolderMutationVariables,
  CREATE_FOLDER_MUTATION,
  DeleteFileMutation,
  DeleteFolderMutation,
  DELETE_FILE_MUTATION,
  DELETE_FOLDER_MUTATION,
  FolderQuery,
  FolderQueryVariables,
  FolderTreeQuery,
  FolderTreeQueryVariables,
  FOLDER_QUERY,
  FOLDER_TREE_QUERY,
  PatchFileMutation,
  PatchFileMutationVariables,
  PatchFolderMutation,
  PatchFolderMutationVariables,
  PATCH_FILE_MUTATION,
  PATCH_FOLDER_MUTATION,
  RootFoldersQuery,
  RootFoldersQueryVariables,
  ROOT_FOLDERS_QUERY,
  SearchFoldersAndFilesQuery,
  SearchFoldersAndFilesQueryVariables,
  SEARCH_FOLDERS_AND_FILES_QUERY,
  TableFile,
  TableFolder,
  TreeFolder,
} from './graphql'

export type FolderTree = TreeItem[]

function buildFolderTree(
  folders: TreeFolder[],
  exclude: string[] = []
): FolderTree {
  function traverseFolders(subset: TreeFolder[]) {
    const tree: FolderTree = []

    for (const folder of subset) {
      if (exclude.includes(folder.id)) continue

      const children = folders.filter(
        child => child.parentFolder?.id === folder.id
      )
      tree.push({
        value: folder.id,
        text: folder.name,
        icon: {
          icon: !children.length ? 'folder' : 'folder-open',
          type: 'solid',
        },
        items: traverseFolders(children),
      })
    }

    return tree
  }

  const root = folders.filter(folder => !folder.parentFolder)
  return traverseFolders(root)
}

interface UseFilesUtilsOptions {
  projectId: string
  folderId: string | null
  refetchQueries: string[]
  isSearch: boolean
}

export function useFilesUtils({
  projectId,
  folderId,
  refetchQueries,
  isSearch,
}: UseFilesUtilsOptions) {
  const translations = useTranslate({
    details: 'common.details',
    edit: 'common.edit',
    move: 'common.move',
    delete: 'common.delete',

    prompts: {
      createFolder: 'files.prompts.create-folder',
      createFolderTitle: 'files.prompts.create-folder-title',

      patchFolder: 'files.prompts.patch-folder',
      patchFolderTitle: 'files.prompts.patch-folder-title',

      deleteFolder: ['files.prompts.delete-folder', { name: '' }],
      deleteFolderTitle: 'files.prompts.delete-folder-title',

      deleteFile: ['files.prompts.delete-file', { name: '' }],
      deleteFileTitle: 'files.prompts.delete-file-title',
    },
    success: {
      createFolder: 'files.success.create-folder',
      patchFolder: 'files.success.patch-folder',
      moveFolder: 'files.success.move-folder',
      deleteFolder: 'files.success.delete-folder',

      uploadFiles: ['files.success.upload-files', { n: 0 }],
      patchFile: 'files.success.patch-file',
      moveFile: 'files.success.move-file',
      deleteFile: 'files.success.delete-file',
    },
    errors: {
      createFolder: 'files.errors.create-folder',
      patchFolder: 'files.errors.patch-folder',
      moveFolder: 'files.errors.move-folder',
      deleteFolder: 'files.errors.delete-folder',

      uploadFiles: 'files.errors.upload-files',
      patchFile: 'files.errors.patch-file',
      moveFile: 'files.errors.move-file',
      deleteFile: 'files.errors.delete-file',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const addPrompt = usePrompt()
  const promptString = usePromptString()
  const confirm = useConfirm()

  const canEditFolder = usePermission(PERMISSIONS.files.change.folder)
  const canDeleteFolder = usePermission(PERMISSIONS.files.delete.folder)
  const canEditFile = usePermission(PERMISSIONS.files.change.file)
  const canDeleteFile = usePermission(PERMISSIONS.files.delete.file)

  /* Queries */

  const { data: folderTreeData, loading: folderTreeLoading } = useQuery<
    FolderTreeQuery,
    FolderTreeQueryVariables
  >(FOLDER_TREE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      projectId,
    },
    onError: onErrorAuto(),
  })

  /* Mutations */

  const [createFolder, { loading: createFolderLoading }] = useMutation<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted() {
      addToast('success', translations.success.createFolder)
    },
    onError: onErrorAuto(translations.errors.createFolder),
  })

  const [patchFolder, { loading: patchFolderLoading }] = useMutation<
    PatchFolderMutation,
    PatchFolderMutationVariables
  >(PATCH_FOLDER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted() {
      addToast('success', translations.success.patchFolder)
    },
    onError: onErrorAuto(translations.errors.patchFolder),
  })

  const [moveFolder, { loading: moveFolderLoading }] = useMutation<
    PatchFolderMutation,
    PatchFolderMutationVariables
  >(PATCH_FOLDER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted() {
      addToast('success', translations.success.moveFolder)
    },
    onError: onErrorAuto(translations.errors.moveFolder),
  })

  const [deleteFolder, { loading: deleteFolderLoading }] = useMutation<
    DeleteFolderMutation,
    IdVariable
  >(DELETE_FOLDER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted() {
      addToast('success', translations.success.deleteFolder)
    },
    onError: onErrorAuto(translations.errors.deleteFolder),
  })

  const [uploadFiles, { loading: uploadFilesLoading }] = useMutation<
    BatchCreateFileMutation,
    BatchCreateFileMutationVariables
  >(BATCH_CREATE_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueries, 'RecentFiles', 'ProjectNameAndAmount'],
    onCompleted(data) {
      addToast(
        'success',
        translations.success.uploadFiles({
          n: data.batchCreateFile.files.length,
        })
      )
    },
    onError: onErrorAuto(translations.errors.uploadFiles),
  })

  const [patchFile, { loading: patchFileLoading }] = useMutation<
    PatchFileMutation,
    PatchFileMutationVariables
  >(PATCH_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueries, 'RecentFiles'],
    onCompleted() {
      addToast('success', translations.success.patchFile)
    },
    onError: onErrorAuto(translations.errors.patchFile),
  })

  const [moveFile, { loading: moveFileLoading }] = useMutation<
    PatchFileMutation,
    PatchFileMutationVariables
  >(PATCH_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueries, 'RecentFiles'],
    onCompleted() {
      addToast('success', translations.success.moveFile)
    },
    onError: onErrorAuto(translations.errors.moveFile),
  })

  const [deleteFile, { loading: deleteFileLoading }] = useMutation<
    DeleteFileMutation,
    IdVariable
  >(DELETE_FILE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueries, 'RecentFiles', 'ProjectNameAndAmount'],
    onCompleted() {
      addToast('success', translations.success.deleteFile)
    },
    onError: onErrorAuto(translations.errors.deleteFile),
  })

  /* Variables */

  const folders = useMemo<TreeFolder[]>(
    () => folderTreeData?.allFolders.edges.map(edge => edge.node) ?? [],
    [folderTreeData]
  )

  /* Handlers */

  const handleCreateFolder = useCallback(async () => {
    const name = await promptString(
      translations.prompts.createFolder,
      translations.prompts.createFolderTitle
    )
    if (!name) return

    return createFolder({
      variables: {
        input: {
          project: projectId,
          parentFolder: folderId,
          name,
        },
      },
    })
  }, [
    createFolder,
    folderId,
    projectId,
    promptString,
    translations.prompts.createFolder,
    translations.prompts.createFolderTitle,
  ])

  const handleEditFolder = useCallback(
    async (folder: TableFolder) => {
      const name = await promptString(
        translations.prompts.patchFolder,
        translations.prompts.patchFolderTitle
      )
      if (!name) return

      return patchFolder({
        variables: {
          id: folder.id,
          input: {
            name,
          },
        },
      })
    },
    [
      patchFolder,
      promptString,
      translations.prompts.patchFolder,
      translations.prompts.patchFolderTitle,
    ]
  )

  const handleDeleteFolder = useCallback(
    async (folder: TableFolder) => {
      const answer = await confirm(
        translations.prompts.deleteFolder({ name: folder.name }),
        translations.prompts.deleteFolderTitle,
        {
          variant: 'delete',
        }
      )
      if (!answer) return

      deleteFolder({
        variables: {
          id: folder.id,
        },
      })
    },
    [confirm, deleteFolder, translations.prompts]
  )

  const handleUploadFiles = useCallback(
    async (files?: File[]) => {
      if (!folderId || files?.length === 0) return

      if (typeof files === 'undefined') {
        const { data } = await addPrompt<UploadFileModalResolve>(resolve => (
          <UploadFilesModal onSubmit={resolve} />
        ))
        if (!data) return
        files = data
      }

      return uploadFiles({
        variables: {
          input: files.map(file => ({
            folder: folderId,
            name: file.name,
            fileData: file,
          })),
        },
      })
    },
    [addPrompt, folderId, uploadFiles]
  )

  const handleShowFileDetails = useCallback(
    async (file: TableFile) => {
      const { data } = await addPrompt<FileDetailsModalResolve | null>(
        resolve => (
          <FileDetailsModal
            file={file}
            canEditFile={canEditFile}
            onResolve={resolve}
          />
        )
      )
      if (!data) return

      patchFile({
        variables: {
          id: file.id,
          input: data,
        },
      })
    },
    [addPrompt, canEditFile, patchFile]
  )

  const handleMoveFile = useCallback(
    async (file: { id: string; name: string }, isFolder = false) => {
      const folderTree = buildFolderTree(folders, [file.id])
      if (!folderTree) return

      const { data } = await addPrompt<string | null>(resolve => (
        <MoveFileModal
          file={file}
          originalFolder={folderId}
          folderTree={folderTree}
          isFolder={isFolder}
          onResolve={resolve}
        />
      ))
      if (!data) return

      isFolder
        ? moveFolder({
            variables: {
              id: file.id,
              input: {
                parentFolder: data,
              },
            },
          })
        : moveFile({
            variables: {
              id: file.id,
              input: {
                folder: data,
              },
            },
          })
    },
    [addPrompt, folders, folderId, moveFile, moveFolder]
  )

  const handleDeleteFile = useCallback(
    async (file: TableFile) => {
      if (!canDeleteFile) return

      const answer = await confirm(
        translations.prompts.deleteFile({ name: file.name }),
        translations.prompts.deleteFileTitle,
        {
          variant: 'delete',
        }
      )
      if (!answer) return

      deleteFile({
        variables: {
          id: file.id,
        },
      })
    },
    [canDeleteFile, confirm, deleteFile, translations.prompts]
  )

  /* Utils */

  const makeFolderTableMenu = useCallback(
    (folder: TableFolder): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditFolder,
          onClick: () => handleEditFolder(folder),
        },
        {
          icon: 'random',
          text: translations.move,
          hide: isSearch || !canEditFolder,
          onClick: () => handleMoveFile(folder, true),
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: 'logoutRed',
          hide: !canDeleteFolder,
          hoverColor: 'logoutRedHover',
          onClick: () => handleDeleteFolder(folder),
        },
      ],
    }),
    [
      canDeleteFolder,
      canEditFolder,
      handleDeleteFolder,
      handleEditFolder,
      handleMoveFile,
      isSearch,
      translations.delete,
      translations.edit,
      translations.move,
    ]
  )

  const makeFileTableMenu = useCallback(
    (file: TableFile): TableMenu => ({
      items: [
        {
          icon: 'info-circle',
          text: translations.details,
          onClick: () => handleShowFileDetails(file),
        },
        {
          icon: 'random',
          text: translations.move,
          hide: isSearch || !canEditFile,
          onClick: () => handleMoveFile(file),
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: 'logoutRed',
          hide: !canDeleteFile,
          hoverColor: 'logoutRedHover',
          onClick: () => handleDeleteFile(file),
        },
      ],
    }),
    [
      translations.details,
      translations.move,
      translations.delete,
      isSearch,
      canEditFile,
      canDeleteFile,
      handleShowFileDetails,
      handleMoveFile,
      handleDeleteFile,
    ]
  )

  return {
    handleCreateFolder,
    handleUploadFiles,

    makeFolderTableMenu,
    makeFileTableMenu,

    loading:
      folderTreeLoading ||
      createFolderLoading ||
      patchFolderLoading ||
      moveFolderLoading ||
      deleteFolderLoading ||
      uploadFilesLoading ||
      patchFileLoading ||
      moveFileLoading ||
      deleteFileLoading,
  }
}

const convertChecklistsSort = (sort: string) =>
  sort.replace('name', 'title').replace('updatedAt', 'modifiedDate')
const convertChecklistImagesSort = (sort: string) =>
  sort.replace('name', 'title')

interface UseProjectFilesQueriesOptions {
  projectId: string
  folderId?: string

  debouncedSearch: string
  sort: string
  orderFilesBy: string
  isChecklistFolders: boolean
  isChecklistImages: boolean

  onOverrideBreadcrumbs?: (ids: string[]) => void
}

export function useProjectFilesQueries({
  projectId,
  folderId,

  debouncedSearch,
  sort,
  orderFilesBy,
  isChecklistFolders,
  isChecklistImages,

  onOverrideBreadcrumbs,
}: UseProjectFilesQueriesOptions) {
  const {
    data: rootData,
    loading: rootLoading,
    error: rootError,
  } = useQuery<RootFoldersQuery, RootFoldersQueryVariables>(
    ROOT_FOLDERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      skip:
        isChecklistFolders ||
        isChecklistImages ||
        !!folderId ||
        !!debouncedSearch,
      variables: {
        projectId,
        orderBy: sort,
      },
    }
  )

  const {
    data: folderData,
    loading: folderLoading,
    error: folderError,
  } = useQuery<FolderQuery, FolderQueryVariables>(FOLDER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip:
      isChecklistFolders || isChecklistImages || !folderId || !!debouncedSearch,
    variables: {
      id: folderId ?? '',
      orderFoldersBy: sort,
      orderFilesBy,
    },
    onCompleted(data) {
      onOverrideBreadcrumbs?.([data.folder.id])
    },
  })

  const {
    data: searchData,
    loading: searchLoading,
    error: searchError,
  } = useQuery<SearchFoldersAndFilesQuery, SearchFoldersAndFilesQueryVariables>(
    SEARCH_FOLDERS_AND_FILES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !debouncedSearch,
      variables: {
        projectId,
        q: debouncedSearch,
      },
    }
  )

  const {
    data: checklistsData,
    loading: checklistsLoading,
    error: checklistsError,
  } = useQuery<ChecklistFoldersQuery, ChecklistFoldersQueryVariables>(
    CHECKLIST_FOLDERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      skip: !isChecklistFolders || !!debouncedSearch,
      variables: {
        projectId,
        orderBy: convertChecklistsSort(sort),
      },
      onCompleted() {
        onOverrideBreadcrumbs?.(['checklists'])
      },
    }
  )

  const {
    data: checklistImagesData,
    loading: checklistImagesLoading,
    error: checklistImagesError,
  } = useQuery<ChecklistImagesQuery, ChecklistImagesQueryVariables>(
    CHECKLIST_IMAGES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      skip: !isChecklistImages || !folderId || !!debouncedSearch,
      variables: {
        checklistId: folderId + '',
        orderBy: convertChecklistImagesSort(sort),
      },
      onCompleted() {
        onOverrideBreadcrumbs?.(['checklists', folderId + ''])
      },
    }
  )

  const parentFolder = useMemo(
    () =>
      folderData?.folder.parentFolder
        ? {
            id: folderData.folder.parentFolder.id,
            name: folderData.folder.parentFolder.name,
          }
        : null,
    [folderData]
  )
  const folders = useMemo(
    () =>
      (!!debouncedSearch
        ? searchData?.searchFoldersAndFiles.folders
        : !folderId
        ? rootData?.allRootFolders.edges.map(edge => edge.node)
        : folderData?.folder.folders.edges.map(edge => edge.node)) ?? [],
    [
      debouncedSearch,
      folderData?.folder.folders.edges,
      folderId,
      rootData?.allRootFolders.edges,
      searchData?.searchFoldersAndFiles.folders,
    ]
  )
  const files = useMemo(
    () =>
      (!!debouncedSearch
        ? searchData?.searchFoldersAndFiles.files
        : !folderId
        ? []
        : folderData?.folder.files.edges.map(edge => edge.node)) ?? [],
    [
      debouncedSearch,
      folderData?.folder.files.edges,
      folderId,
      searchData?.searchFoldersAndFiles.files,
    ]
  )
  const checklists = useMemo(
    () =>
      !isChecklistFolders || !!debouncedSearch
        ? []
        : checklistsData?.allChecklists.edges.map(edge => edge.node) ?? [],
    [checklistsData?.allChecklists.edges, debouncedSearch, isChecklistFolders]
  )
  const checklistImages = useMemo(
    () =>
      !isChecklistImages || !folderId || !!debouncedSearch
        ? []
        : checklistImagesData?.allChecklistItemImages.edges.map(
            edge => edge.node
          ) ?? [],
    [
      checklistImagesData?.allChecklistItemImages.edges,
      debouncedSearch,
      folderId,
      isChecklistImages,
    ]
  )

  const imageFiles = useMemo<Image[]>(() => {
    const reducer = (acc: Image[], cur: TableFile | ChecklistImage): Image[] =>
      !checkIsImage(cur.originalName)
        ? acc
        : [
            ...acc,
            {
              id: cur.id,
              file: 'url' in cur ? cur.url : cur.image,
              name: 'url' in cur ? cur.name : cur.title,
            },
          ]

    const imgFiles = files.reduce(reducer, [])
    const imgChecklists = checklistImages.reduce(reducer, [])

    return [...imgFiles, ...imgChecklists]
  }, [checklistImages, files])

  return {
    folderData,
    parentFolder,

    folders,
    files,
    checklists,
    checklistImages,
    imageFiles,

    loading:
      rootLoading ||
      folderLoading ||
      searchLoading ||
      checklistsLoading ||
      checklistImagesLoading,
    error:
      !!rootError ||
      !!folderError ||
      !!searchError ||
      !!checklistsError ||
      !!checklistImagesError,
  }
}

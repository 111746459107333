import { useTranslate } from '@ur/react-hooks'
import { Tab, TabbedPage } from 'components/TabbedPage'
import { Route, Switch } from 'containers/Routing'
import React, { useMemo } from 'react'
import { usePermissionOr } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ChecklistsTab, ChecklistTemplatesTab } from '.'

interface ChecklistsProps {}

export const Checklists: React.FC<ChecklistsProps> = () => {
  const translations = useTranslate({
    allChecklists: 'common.checklists',
    checklistsTemplates: 'checklists.checklist-templates',
  })

  const canViewTemplates = usePermissionOr(
    PERMISSIONS.checklists.add.checklisttemplate,
    PERMISSIONS.checklists.change.checklisttemplateitem
  )

  const tabs = useMemo<Tab[]>(
    () => [
      {
        href: '/checklists',
        text: translations.allChecklists,
      },
      {
        href: '/checklists/templates',
        hide: !canViewTemplates,
        text: translations.checklistsTemplates,
      },
    ],
    [
      canViewTemplates,
      translations.allChecklists,
      translations.checklistsTemplates,
    ]
  )

  return (
    <TabbedPage tabs={tabs}>
      <Switch>
        <Route exact path="/checklists" component={ChecklistsTab} />

        <Route
          exact
          path="/checklists/templates"
          component={ChecklistTemplatesTab}
        />
      </Switch>
    </TabbedPage>
  )
}

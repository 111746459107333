import {
  SelectMultiple as BaseSelectMultiple,
  SelectMultipleProps as BaseSelectMultipleProps,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import styled, { css } from 'styled-components'

interface SelectMultipleWrapperProps {
  listVertically: boolean
}

const SelectMultipleWrapper = styled.div<SelectMultipleWrapperProps>`
  .--select-display {
    height: 40px;
    padding-left: 0.2rem;
    padding-right: 0.5rem;

    .--select-display-selected {
      border-radius: 4px;
    }
  }
  .--select-curtain-option {
    padding: 0.5rem 1rem 0.5rem 0.7rem;
  }

  ${props =>
    props.listVertically &&
    css`
      .--select-display {
        min-height: 40px;
        height: auto;
        padding-top: 4px;
        padding-bottom: 4px;

        .--select-display-inner {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          height: 100%;
        }
      }
    `}

  ${props => props.theme.media.mobile} {
    .--select-display {
      height: 56px;
    }
  }
`

export interface SelectMultipleProps<V extends string | number, E = any>
  extends BaseSelectMultipleProps<V, E> {
  listVertically?: boolean
}

export const SelectMultiple = <V extends string | number, E = any>({
  listVertically = false,
  ...props
}: SelectMultipleProps<V, E>) => {
  const { noMoreOptions } = useTranslate({
    noMoreOptions: 'common.no-more-options',
  })

  return (
    <SelectMultipleWrapper listVertically={listVertically}>
      <BaseSelectMultiple
        noOptionsText={noMoreOptions}
        {...props}
        iconProps={{
          color: 'gray4',
          type: 'solid',
          size: '1.2rem !important',
          translateY: '2px',
          ...props.iconProps,
        }}
      />
    </SelectMultipleWrapper>
  )
}

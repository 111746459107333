import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { EmptyPageImage, PictureType } from './EmptyPageImage'
import {
  Card as BaseCard,
  CenteredLoader,
  Button as BaseButton,
} from 'components'
import { useTranslate } from '@ur/react-hooks'
import { isMobileOnly } from 'react-device-detect'
import { usePermissions } from 'util/hooks'

interface CardProps {
  isTabView?: boolean
}
const Wrapper = styled.div<CardProps>`
  width: 100%;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  ${props => props.theme.media.desktop} {
    margin-top: ${props => (props.isTabView ? '0' : '10vh')};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const Card = styled(BaseCard)<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 600px;
  text-align: center;

  gap: 1rem;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
  }

  p {
    color: ${props => props.theme.colors.gray2};
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  ${props =>
    props.isTabView &&
    css`
      background: none;
      box-shadow: none;
    `};

  ${props => props.theme.media.mobile} {
    width: 100%;
    background: white;

    border-radius: 0;
    box-shadow: none;
    height: ${props => `calc(100vh - ${props.theme.sizes.headerHeightMobile})`};

    gap: 0.5rem;

    p {
      line-height: 1.2;
      font-size: 12;
    }
  }
`

const Button = styled(BaseButton)`
  margin-top: 1rem;
`

interface EmptyPageProps {
  module: PictureType
  empty: boolean
  loading: boolean
  isTabView?: boolean
  /** Permissions needed to show button, admin always allowed */
  buttonPermissions?: string | string[]

  onButtonClick?: () => void
}

export const EmptyPage: React.CFC<EmptyPageProps> = ({
  children,

  module,
  empty,
  loading,
  isTabView = false,
  buttonPermissions,

  onButtonClick,
}) => {
  const translations = useTranslate({
    absences: {
      header: 'empty-pages.absences.header',
      description: 'empty-pages.absences.description',
      button: 'empty-pages.absences.button',
    },

    checklists: {
      header: 'empty-pages.checklists.header',
      description: 'empty-pages.checklists.description',
      button: 'empty-pages.checklists.button',
    },

    checklistTemplates: {
      header: 'empty-pages.checklistTemplates.header',
      description: 'empty-pages.checklistTemplates.description',
      button: 'empty-pages.checklistTemplates.button',
    },

    dashboard: {
      header: 'empty-pages.dashboard.header',
      description: 'empty-pages.dashboard.description',
      button: 'empty-pages.dashboard.button',
    },

    deviations: {
      header: 'empty-pages.deviations.header',
      description: 'empty-pages.deviations.description',
      button: 'empty-pages.deviations.button',
    },

    handbook: {
      header: 'empty-pages.handbook.header',
      description: 'empty-pages.handbook.description',
      button: 'empty-pages.handbook.button',
    },

    importedProducts: {
      header: 'empty-pages.importedProducts.header',
      description: 'empty-pages.importedProducts.description',
      button: 'empty-pages.importedProducts.button',
    },

    internalDeviations: {
      header: 'empty-pages.internalDeviations.header',
      description: 'empty-pages.internalDeviations.description',
      button: 'empty-pages.internalDeviations.button',
    },

    products: {
      header: 'empty-pages.products.header',
      description: 'empty-pages.products.description',
      button: 'empty-pages.products.button',
    },

    projects: {
      header: 'empty-pages.projects.header',
      description: 'empty-pages.projects.description',
      button: 'empty-pages.projects.button',
    },

    timesheets: {
      header: 'empty-pages.timesheets.header',
      description: 'empty-pages.timesheets.description',
      button: 'empty-pages.timesheets.button',
    },
  })

  const content = useMemo(() => translations[module], [module, translations])

  const { hasPermissions } = usePermissions()

  const hideButton = useMemo(
    () =>
      !buttonPermissions ? false : !hasPermissions(buttonPermissions, true),
    [buttonPermissions, hasPermissions]
  )

  if (loading)
    return (
      <Wrapper isTabView={isTabView}>
        <CenteredLoader />
      </Wrapper>
    )

  if (!empty) return <>{children}</>

  return (
    <Wrapper isTabView={isTabView}>
      <Card isTabView={isTabView}>
        <EmptyPageImage picture={module} />

        {!!content.header && <h1>{content.header}</h1>}

        {!!content.description && <p>{content.description}</p>}

        {!!content.button && !hideButton && (
          <Button
            role="button"
            fullWidth={isMobileOnly}
            background="primary"
            height="40px"
            onClick={onButtonClick}
          >
            {content.button}
          </Button>
        )}
      </Card>
    </Wrapper>
  )
}

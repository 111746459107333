import { useTranslate } from '@ur/react-hooks'
import { SlideMenu, SlideMenuItem } from 'components'
import { DeviationNode } from 'modules/deviations/types.graphql'
import {
  ProjectDashboardData,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useTimeFilterOptions } from '../utils'
import {
  Card,
  CardHeader,
  CardItem,
  Content,
  ContentWrapper,
  FooterButton,
  FooterSelect,
  NoData,
  Project,
  Title,
} from './components'
import { DashboardCardProps } from './types'
import mixpanel from 'mixpanel-browser'

export const DeviationsCard: React.FC<
  DashboardCardProps<ProjectDashboardData['deviationsUnderTreatment']>
> = ({ data, loading, timeFilter, onUpdateTimeFilter }) => {
  const translations = useTranslate({
    registerDeviation: 'deviations.register-deviation',
    open: 'common.open',
    edit: 'common.edit',
    deviationsUnderTreatment: 'deviations.under-treatment',
  })

  const { hasPermissions } = usePermissions(true)
  const canCreateDeviation = useMemo(
    () => hasPermissions(PERMISSIONS.deviations.add.deviation),
    [hasPermissions]
  )

  const history = useHistory()
  const timeFilterOptions = useTimeFilterOptions()

  const createMenuItems = useCallback(
    (
      deviation: Pick<DeviationNode, 'id' | 'title' | 'project'>
    ): SlideMenuItem[] => {
      const projectId = deviation.project?.id
      const deviationId = deviation.id

      if (!projectId) return []

      return [
        {
          text: translations.edit,
          href: `/projects/${projectId}/deviations/${deviationId}/edit`,
          color: 'white',
          background: 'secondary',
          hoverBackground: 'secondaryHover',
          hide: !hasPermissions(PERMISSIONS.deviations.change.deviation),
        },
        {
          text: translations.open,
          href: `/projects/${projectId}/deviations/${deviationId}`,
          color: 'white',
          background: 'primary',
          hoverBackground: 'primaryHover',
          hide: !hasPermissions(PERMISSIONS.deviations.view.deviation),
        },
      ]
    },
    [hasPermissions, translations.edit, translations.open]
  )

  return (
    <Card hasFooterButton={canCreateDeviation}>
      <CardHeader
        text={translations.deviationsUnderTreatment}
        loading={loading}
      />

      <ContentWrapper>
        {data.map(deviation => (
          <CardItem key={deviation.id}>
            <Content>
              <Title>{deviation.title}</Title>

              <Project>{deviation.project?.name}</Project>
            </Content>

            <SlideMenu items={createMenuItems(deviation)} />
          </CardItem>
        ))}

        {!data.length && <NoData />}
      </ContentWrapper>

      <footer>
        <FooterSelect
          value={timeFilter}
          options={timeFilterOptions}
          borderBottomRightRadius="0px"
          borderTopRightRadius="0px"
          borderTopLeftRadius="0px"
          height="49px"
          border="none"
          fullWidth
          onChange={value =>
            !!value && onUpdateTimeFilter(value as TimeFilterType)
          }
        />

        {canCreateDeviation && (
          <FooterButton
            role="link"
            height="100%"
            width="100%"
            iconRightProps={{ icon: 'plus' }}
            background="primary"
            onClick={() => {
              mixpanel.track('Create Deviation From Dashboard')
              history.push({ pathname: '/deviations', state: { create: true } })
            }}
          >
            {translations.registerDeviation}
          </FooterButton>
        )}
      </footer>
    </Card>
  )
}

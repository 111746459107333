import { useDebounce, useTranslate } from '@ur/react-hooks'
import { FetchMoreLoader, Message, PageHeader, Table } from 'components'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'
import { ProjectsMobile } from '.'
import { useProjectsUtils } from './util/useProjectsUtils'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

const PAGE_SIZE = 25

interface ProjectsProps {}

export const Projects: React.FC<ProjectsProps> = () => {
  const translations = useTranslate({
    projects: 'common.projects',
    searchForProjects: 'projects.search',
    createProject: 'projects.create',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const hasLaunchedModalByRouteStateRef = useRef(false)
  const location = useLocation<{ create?: boolean } | undefined>()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [selectedStages, setSelectedStages] = useState([
    'planning',
    'ongoing',
    'billing',
  ])
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const {
    hasProjects,
    projects,
    columns,
    rows,
    tableOptions,

    handleCreateProject,
    handleFetchMore,

    statusFiltering,
    makeStageMenu,
    makeEditDeleteMenu,

    error,
    loading,
    fetchMoreLoading,
    hasMore,
  } = useProjectsUtils({
    queryVariables: {
      first: PAGE_SIZE,
      orderBy: sort,
      q: debouncedSearch,
      stages: selectedStages,
    },
    onQueryCompleted: () => setHasLoadedOnce(true),
  })

  useLayoutEffect(() => {
    if (hasLaunchedModalByRouteStateRef.current || !location.state) return
    hasLaunchedModalByRouteStateRef.current = true

    if (!!location.state.create) handleCreateProject()
  }, [handleCreateProject, location.state])

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <EmptyPage
      module="projects"
      empty={!hasProjects}
      loading={!hasLoadedOnce}
      buttonPermissions={PERMISSIONS.projects.add.project}
      onButtonClick={handleCreateProject}
    >
      <Wrapper>
        {isMobileOnly ? (
          <ProjectsMobile
            projects={projects}
            search={search}
            filter={statusFiltering}
            makeStageMenu={makeStageMenu}
            makeEditDeleteMenu={makeEditDeleteMenu}
            onSearchChange={setSearch}
            onFilterChange={value => setSelectedStages(value.checked)}
            onCreateClick={handleCreateProject}
          />
        ) : (
          <>
            <PageHeader
              title={translations.projects}
              loading={loading}
              search={{
                value: search,
                placeholder: translations.searchForProjects,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.createProject,
                  icon: 'plus',
                  permissions: PERMISSIONS.projects.add.project,
                  onClick: handleCreateProject,
                },
              ]}
            />

            <Table
              columns={columns}
              rows={rows}
              options={tableOptions}
              noRowsText={
                !!debouncedSearch ? translations.noResults : translations.noData
              }
              onFilterChange={value => setSelectedStages(value.checked)}
              onSortingChange={setSort}
            />

            <FetchMoreLoader
              loading={fetchMoreLoading}
              hide={!hasMore}
              onFetchMore={handleFetchMore}
            />
          </>
        )}
      </Wrapper>
    </EmptyPage>
  )
}

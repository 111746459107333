import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import {
  CreateProductMutationVariables,
  PatchProductMutationVariables,
  Product,
} from '../types.graphql'
import { ProductForm } from './ProductForm'

interface CreateEditProductModalProps {
  product?: Product
  onSubmit: PromptResolve<
    CreateProductMutationVariables | PatchProductMutationVariables | null
  >
}

export const CreateEditProductModal: React.FC<CreateEditProductModalProps> = ({
  product,
  onSubmit,
}) => {
  const translations = useTranslate({
    createProduct: 'products.create-product',
    editProduct: 'products.edit-product',
    save: 'common.save',
  })

  function handleSubmit(
    values: CreateProductMutationVariables | PatchProductMutationVariables
  ) {
    onSubmit(values)
  }

  return (
    <RegularModal
      title={!!product ? translations.editProduct : translations.createProduct}
      width="100%"
      overflowY="auto"
      onClose={() => onSubmit(null)}
    >
      <ProductForm
        loading={false}
        product={product}
        onSubmit={variables => variables !== null && handleSubmit(variables)}
      />
    </RegularModal>
  )
}

export const summarizeBooleans = (...values: boolean[]) =>
  values.reduce((sum, cur) => (sum += Number(cur)), 0)

/**
 * Tries to parse json, and if the passed in string is not
 * legal json, it returns the empty object
 */
export function safeParseJson<T>(jsonString: string, fallback: T): T
export function safeParseJson(jsonString: string): any
export function safeParseJson<T = any>(jsonString: string, fallback?: T) {
  try {
    const parsed: T = JSON.parse(jsonString)
    return parsed
  } catch {
    return fallback ?? ({} as any)
  }
}

import { gql } from '@apollo/client'

const TableFolder = gql`
  fragment TableFolder on FolderNode {
    id
    name

    updatedAt
    numberOfFolders
    numberOfFiles
  }
`
const TableFile = gql`
  fragment TableFile on FileNode {
    id
    name
    description

    updatedAt
    url
    originalName
    size
  }
`

export const ROOT_FOLDERS_QUERY = gql`
  query RootFolders($projectId: ID!, $q: String, $orderBy: String) {
    allRootFolders(project: $projectId, q: $q, orderBy: $orderBy) {
      edges {
        node {
          ...TableFolder
        }
      }
    }
  }
  ${TableFolder}
`

export const FOLDER_TREE_QUERY = gql`
  query FolderTree($projectId: ID!) {
    allFolders(project: $projectId, orderBy: "name") {
      edges {
        node {
          id
          name
          parentFolder {
            id
          }
        }
      }
    }
  }
`

export const FOLDER_QUERY = gql`
  query Folder(
    $id: ID!
    $q: String
    $orderFoldersBy: String
    $orderFilesBy: String
  ) {
    folder(id: $id) {
      id
      name

      parentFolder {
        id
        name
      }

      folders(q: $q, orderBy: $orderFoldersBy) {
        edges {
          node {
            ...TableFolder
          }
        }
      }
      files(q: $q, orderBy: $orderFilesBy) {
        edges {
          node {
            ...TableFile
          }
        }
      }
    }
  }
  ${TableFolder}
  ${TableFile}
`

export const SEARCH_FOLDERS_AND_FILES_QUERY = gql`
  query SearchFoldersAndFiles($projectId: ID!, $q: String!) {
    searchFoldersAndFiles(project: $projectId, q: $q) {
      folders {
        ...TableFolder
      }
      files {
        ...TableFile
      }
    }
  }
  ${TableFolder}
  ${TableFile}
`

export const RECENT_FILES_QUERY = gql`
  query RecentFiles($projectId: ID!, $first: Int = 10) {
    recentFiles(project: $projectId, first: $first) {
      edges {
        node {
          id
          name
          url
          originalName
          updatedAt
        }
      }
    }
  }
`

export const CHECKLIST_FILES_COUNTS_QUERY = gql`
  query ChecklistFilesCounts($projectId: ID!) {
    counts {
      checklists {
        hasImages(projectId: $projectId)
        images(projectId: $projectId)
        lastUpdatedImage(projectId: $projectId)
      }
    }
  }
`

export const CHECKLIST_FOLDERS_QUERY = gql`
  query ChecklistFolders($projectId: ID!, $orderBy: String) {
    allChecklists(project: $projectId, orderBy: $orderBy, hasImages: true) {
      edges {
        node {
          id
          title
          purpose
          imageCount
          lastUpdatedImage
        }
      }
    }
  }
`

export const CHECKLIST_IMAGES_QUERY = gql`
  query ChecklistImages($checklistId: ID!, $orderBy: String) {
    allChecklistItemImages(checklist: $checklistId, orderBy: $orderBy) {
      edges {
        node {
          id
          title
          image
          originalName

          updatedAt
          checklistItem {
            id
            title
          }
        }
      }
    }
  }
`

import { useTranslate } from '@ur/react-hooks'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useDateFns } from 'util/hooks'
import { AbsenceType, TableAbsences } from './types.graphql'

const Wrapper = styled.section`
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`
const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`
const UserField = styled.div`
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;

  border-bottom: 1px solid ${props => props.theme.colors.gray6};

  strong {
    font-weight: 600;
  }
  a {
    font-size: 18px;
    font-weight: 600;
  }
  div.description {
    margin-top: 0.4rem;
    font-size: 14px;
  }
`
const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.8rem 1rem;

  color: ${props => props.theme.colors.gray1};
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
`

interface AbsenceCardProps {
  absence: TableAbsences
  editDeleteMenu: TableMenu
}

export const AbsenceCard: React.FC<AbsenceCardProps> = ({
  absence,
  editDeleteMenu,
}) => {
  const translations = useTranslate({
    nHours: ['common.n-hours', { n: 0 }],

    absenceTypes: {
      absence_vacation: 'timesheets.absence-type-vacation',
      absence_illness_self_declaration:
        'timesheets.absence-type-illness-self-declaraion',
      absence_illness_sick_leave: 'timesheets.absence-type-illness-sick-leave',
      absence_paid_leave: 'timesheets.absence-type-paid-leave',
      absence_timebank_withdrawal:
        'timesheets.absence-type-timebank-withdrawal',
      absence_unpaid_leave: 'timesheets.absence-unpaid-leave',
    },
  })

  const translatedType = useMemo(
    () =>
      translations.absenceTypes[
        absence.type.toLowerCase() as Lowercase<AbsenceType>
      ],
    [absence.type, translations]
  )

  const { format } = useDateFns()

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(absence.date), 'PPP')}</DateField>

        <UserField>
          <Link to={`users/${absence.employee.id}`}>
            {absence.employee.fullName}
          </Link>
        </UserField>

        <Menu className="menu" icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      <Content>
        <strong>{translatedType}</strong>

        {!!absence.reason && (
          <div className="description">{absence.reason}</div>
        )}
      </Content>

      <Footer>
        {translations.nHours({ n: parseFloat(absence.hours ?? '0') })}
      </Footer>
    </Wrapper>
  )
}

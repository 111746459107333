import { useQuery } from '@apollo/client'
import { SelectOption } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import { Select, SelectProps, UserThumbOrInitials } from 'components'
import {
  SelectUser,
  SelectUserQuery,
  SelectUserQueryVariables,
  SELECT_USER_QUERY,
  useSelectUserPagination,
} from 'modules/users'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

const UserElement = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span.full-name {
    margin-left: 6px;
    color: ${props => props.theme.colors.gray3};

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const PAGE_SIZE = 25

interface UserSelectProps
  extends Omit<
    SelectProps<string>,
    'options' | 'searchable' | 'onFilterChange'
  > {
  onlyProjectLeaders?: boolean
}

export const UserSelect: React.FC<UserSelectProps> = ({
  value,
  onlyProjectLeaders = false,
  ...props
}) => {
  const translations = useTranslate({
    selectUser: 'users.select-user',
    noOptions: 'common.no-more-options',
  })

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { data, previousData, loading, fetchMore } = useQuery<
    SelectUserQuery,
    SelectUserQueryVariables
  >(SELECT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      q: debouncedSearch,
      includeIds: !value ? [] : [value],
      first: PAGE_SIZE,
      onlyProjectLeaders,
    },
  })

  const { fetchMore: handleFetchMore, loading: fetchMoreLoading } =
    useSelectUserPagination(data?.allUsers.pageInfo, fetchMore)

  const users = useMemo(
    () => (data ?? previousData)?.allUsers.edges.map(edge => edge.node),
    [data, previousData]
  )

  const userOptions = useMemo<SelectOption<string, SelectUser>[]>(
    () =>
      users?.map(user => ({
        value: user.id,
        label: (
          <UserElement>
            <UserThumbOrInitials user={user} size={25} />
            <span className="full-name">{user.fullName}</span>
          </UserElement>
        ),
        extra: user,
      })) ?? [],
    [users]
  )

  const isLoading = loading || fetchMoreLoading

  return (
    <Select
      value={value}
      options={userOptions}
      noOptionsText={translations.noOptions}
      placeholder={translations.selectUser}
      loading={isLoading}
      searchable={(opt, query) =>
        !!opt.extra?.fullName &&
        opt.extra.fullName.toLowerCase().includes(query.toLowerCase())
      }
      onLoadMore={handleFetchMore}
      onFilterChange={setSearch}
      {...props}
    />
  )
}

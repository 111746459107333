import { ObservableQueryFields, useMutation } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TableFilteringChecklistOptions, TableMenu } from 'components'
import { ProjectSelectModal } from 'modules/projects/components'
import { ShallowProject } from 'modules/projects/types.graphql'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IdVariable, RelayPageInfo } from 'types/graphql/common'
import { useConfirm, useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ChecklistTemplateSelectModal } from './components'
import {
  CREATE_CHECKLIST_MUTATION,
  DELETE_CHECKLIST_MUTATION,
} from './mutations'
import {
  ChecklistsShallowQuery,
  ChecklistsShallowQueryVariables,
  ChecklistTemplateSelectQuery,
  ChecklistTemplateSelectQueryVariables,
  ChecklistTemplatesShallowQuery,
  ChecklistTemplatesShallowQueryVariables,
  CreateChecklistMutation,
  CreateChecklistMutationVariables,
  CreateChecklistTemplateMutationVariables,
  PatchChecklistTemplateMutationVariables,
  SelectChecklistTemplate,
  ShallowChecklist,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

export function getProjectsFiltering(
  projects: ShallowProject[]
): TableFilteringChecklistOptions {
  return {
    type: 'checklist',
    exclusive: true,
    items: projects.map(project => ({
      id: project.id,
      text: project.name,
      initUnchecked: true,
    })),
  }
}

interface StatusTranslations {
  ongoing: string
  finished: string
}

export function getChecklistStatusFiltering(
  translations: StatusTranslations,
  initial: string[] = []
): TableFilteringChecklistOptions {
  return {
    type: 'checklist',
    items: (
      [
        ['ongoing', 'matteOrange'],
        ['finished', 'matteGreen'],
      ] as const
    ).map(([status, dotColor]) => ({
      id: status,
      text: translations[status],
      dotColor,
      initUnchecked: !initial.includes(status),
    })),
  }
}

export function useCreateChecklist() {
  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt()
  const history = useHistory()

  const canCreateChecklist = usePermission(PERMISSIONS.checklists.add.checklist)

  const [createChecklistMutation, { loading }] = useMutation<
    CreateChecklistMutation,
    CreateChecklistMutationVariables
  >(CREATE_CHECKLIST_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted({ createChecklist: { checklist } }) {
      history.push(
        `/projects/${checklist.project.id}/checklists/${checklist.id}`
      )
    },
    onError: onErrorAuto(),
  })

  const createChecklist = useCallback(
    async (id?: string) => {
      if (!canCreateChecklist) return

      let projectId = id
      if (!projectId) {
        const { data: project } = await addPrompt<string | null>(resolve => (
          <ProjectSelectModal onSubmit={resolve} />
        ))

        mixpanel.track('Select Project', {
          Context: 'Create Checklist',
        })
        if (!project) return
        projectId = project
      }
      if (!projectId) return

      const { data: template } =
        await addPrompt<SelectChecklistTemplate | null>(resolve => (
          <ChecklistTemplateSelectModal onSubmit={resolve} />
        ))
      if (!template) return

      createChecklistMutation({
        variables: {
          input: {
            title: template.title,
            project: projectId,
            purpose: '',
            itemsAdd: template.items.edges.map(({ node: item }) => ({
              title: item.title,
              description: item.description || null,
              index: item.index,
            })),
          },
        },
      })
    },
    [addPrompt, canCreateChecklist, createChecklistMutation]
  )

  return {
    createChecklist,
    loading,
  }
}

export function useChecklistPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreChecklists: ObservableQueryFields<
    ChecklistsShallowQuery,
    ChecklistsShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreChecklists({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreChecklists])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useChecklistTemplatePagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreTemplates: ObservableQueryFields<
    ChecklistTemplatesShallowQuery | ChecklistTemplateSelectQuery,
    | ChecklistTemplatesShallowQueryVariables
    | ChecklistTemplateSelectQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreTemplates({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreTemplates])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useDeleteChecklist() {
  const translations = useTranslate({
    checklistDeleted: 'checklists.deleted',
    checklistDeleteError: 'checklists.toasts.could-not-delete',

    deleteChecklist: 'checklists.deleting-checklist-prompt',
    deleteChecklistTitle: 'checklists.deleting-checklist',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const confirm = useConfirm()

  const canDeleteChecklist = usePermission(
    PERMISSIONS.checklists.delete.checklist
  )

  const [deleteChecklistMutation, { loading }] = useMutation<never, IdVariable>(
    DELETE_CHECKLIST_MUTATION,
    {
      refetchQueries: ['ChecklistsShallow'],
      awaitRefetchQueries: true,
      onCompleted() {
        addToast('success', translations.checklistDeleted)
      },
      onError: onErrorAuto(),
    }
  )

  const deleteChecklist = useCallback(
    async (id: string) => {
      if (!canDeleteChecklist) return

      const answer = await confirm(
        translations.deleteChecklist,
        translations.deleteChecklistTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteChecklistMutation({
          variables: {
            id: id,
          },
        })
      }
    },
    [
      canDeleteChecklist,
      confirm,
      deleteChecklistMutation,
      translations.deleteChecklist,
      translations.deleteChecklistTitle,
    ]
  )

  return {
    deleteChecklist,
    loading,
  }
}

export function useChecklistMenu(
  deleteChecklist: (id: string) => void,
  hrefs: {
    open: (
      deviation: Pick<ShallowChecklist, 'id' | 'title' | 'project'>
    ) => string
    edit: (
      deviation: Pick<ShallowChecklist, 'id' | 'title' | 'project'>
    ) => string
  }
) {
  const translations = useTranslate({
    delete: 'common.delete',
    edit: 'common.edit',
    open: 'common.open',
  })

  const history = useHistory()

  const canEditChecklist = usePermission(
    PERMISSIONS.checklists.change.checklist
  )
  const canDeleteChecklist = usePermission(
    PERMISSIONS.checklists.delete.checklist
  )

  const makeMenu = useCallback(
    (
      checklist: Pick<ShallowChecklist, 'id' | 'title' | 'project'>
    ): TableMenu => ({
      items: [
        {
          icon: 'external-link',
          text: translations.open,
          hide: canEditChecklist,
          onClick: () => {
            history.push(hrefs.open(checklist))
          },
        },
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditChecklist,
          onClick: () => {
            history.push(hrefs.edit(checklist))
          },
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hide: !canDeleteChecklist,
          hoverColor: '#e38080',
          onClick: () => deleteChecklist(checklist.id),
        },
      ],
    }),
    [
      canDeleteChecklist,
      canEditChecklist,
      deleteChecklist,
      history,
      hrefs,
      translations,
    ]
  )

  return makeMenu
}

export function isCreateChecklistTemplateMutationVariables(
  arg:
    | CreateChecklistTemplateMutationVariables
    | PatchChecklistTemplateMutationVariables
): arg is CreateChecklistTemplateMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchChecklistTemplateMutationVariables(
  arg:
    | CreateChecklistTemplateMutationVariables
    | PatchChecklistTemplateMutationVariables
): arg is PatchChecklistTemplateMutationVariables {
  return arg.hasOwnProperty('id')
}

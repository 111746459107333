import { useTranslate } from '@ur/react-hooks'
import {
  Table,
  TableColumn,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
  TableOptions,
  TableRow,
  UserThumbOrInitials,
} from 'components'
import { ProjectStage } from 'modules/projects/components'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { HoursCell } from './components'
import { TableTimeEntry } from './types.graphql'

const Description = styled.div`
  white-space: nowrap;
  overflow: hidden;

  span.none {
    font-style: italic;
    color: ${props => props.theme.colors.gray3};
  }
`

type TimesheetTableColumn =
  | 'description'
  | 'projectName'
  | 'customerName'
  | 'user'
  | 'date'
  | 'hours'
  | 'distance'
  | 'menu'

interface TimesheetTableProps {
  entries: TableTimeEntry[]

  excludeColumns?: TimesheetTableColumn[]
  projectFilter: TableFilteringChecklistOptions
  userFilter: TableFilteringChecklistOptions | null
  makeMenu: (timesheet: TableTimeEntry) => TableMenu

  onSortingChange: (value: string) => void
  onFilterChange: (value: TableFiltering) => void
}

export const TimesheetTable: React.FC<TimesheetTableProps> = ({
  entries,

  excludeColumns = [],
  projectFilter,
  userFilter,
  makeMenu,

  onSortingChange,
  onFilterChange,
}) => {
  const translations = useTranslate({
    timesheets: 'common.timesheets',

    description: 'common.description',
    project: 'common.project',
    customer: 'common.customer',
    user: 'common.user',
    date: 'common.date',
    hours: 'common.hours',
    distance: 'common.distance',

    none: 'common.none',
    nHours: ['common.n-hours', { n: 0 }],
    nKm: ['common.n-km', { n: 0 }],
    total: 'common.total-alt',
  })

  const { format } = useDateFns()
  const theme = useTheme()

  const totalHours = useMemo(
    () => entries.reduce((acc, cur) => acc + (cur.sum ?? 0), 0),
    [entries]
  )
  const totalDistance = useMemo(
    () => entries.reduce((acc, cur) => acc + (cur.kilometersDriven ?? 0), 0),
    [entries]
  )

  const columns = useMemo<TableColumn[]>(
    () =>
      [
        {
          id: 'description',
          label: translations.description,
        },
        {
          id: 'projectName',
          label: translations.project,
          sortable: true,
          filtering: projectFilter,
        },
        {
          id: 'customerName',
          label: translations.customer,
          sortable: true,
        },
        {
          id: 'user',
          label: translations.user,
          sortable: true,
          filtering: userFilter ?? undefined,
        },
        {
          id: 'date',
          label: translations.date,
          sortable: true,
        },
        {
          id: 'hours',
          label: translations.hours,
          sortable: true,
          shrink: true,
        },
        {
          id: 'distance',
          label: translations.distance,
          sortable: true,
          shrink: true,
        },
        {
          id: 'menu',
          label: '',
          shrink: true,
        },
      ].filter(col => !excludeColumns.includes(col.id as TimesheetTableColumn)),
    [
      translations.description,
      translations.project,
      translations.customer,
      translations.user,
      translations.date,
      translations.hours,
      translations.distance,
      projectFilter,
      userFilter,
      excludeColumns,
    ]
  )

  const renderDescription = useCallback(
    (desc: string | null) => {
      if (!desc) return <span className="none">{translations.none}</span>
      if (desc.length <= 36) return desc
      return desc.substring(0, 33) + '...'
    },
    [translations.none]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      entries.map(timesheet => {
        return {
          description: {
            content: (
              <Description>
                {renderDescription(timesheet.description)}
              </Description>
            ),
          },

          projectName: {
            content: (
              <ProjectStage project={timesheet.project} circle link named />
            ),
          },
          customerName: {
            content: (
              <Link to={`/customers/${timesheet.project.customer.id}`}>
                {timesheet.project.customer.name}
              </Link>
            ),
          },
          user: {
            content: (
              <UserThumbOrInitials
                user={timesheet.employee}
                cursor="pointer"
                link
              />
            ),
          },
          date: {
            content: format(new Date(timesheet.date), 'PPP'),
          },
          hours: {
            content: <HoursCell entry={timesheet} />,
          },
          distance: {
            content: translations.nKm({ n: timesheet.kilometersDriven ?? 0 }),
          },
          menu: { content: '', menu: makeMenu(timesheet) },
        }
      }) ?? [],
    [entries, renderDescription, format, translations, makeMenu]
  ).concat({
    description: {
      content: translations.total,
    },
    projectName: {
      content: '',
    },
    customerName: {
      content: '',
    },
    user: {
      content: '',
    },
    date: {
      content: '',
    },
    hours: {
      content: translations.nHours({ n: totalHours }),
    },
    distance: {
      content: translations.nKm({ n: totalDistance }),
    },
    menu: {
      content: '',
    },
  })

  const options = useMemo<TableOptions>(
    () => ({
      fillHorizontal: true,
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color: ['date', 'timeSum', 'distance'].includes(cell.columnId)
            ? theme.colors.gray3
            : 'currentColor',
          fontWeight: cell.isLastRow ? 600 : 'inherit',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      options={options}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
    />
  )
}

import { useQuery } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  DatePicker,
  FilePicker,
  FormField as BaseFormField,
  Input,
  TextArea,
} from 'components'
import {
  FilePickerFile,
  FilePickerOnChangeValue,
  FilePickerRef,
} from 'components/FilePicker/types'
import xor from 'lodash/xor'
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Single } from 'types/util'
import { useDateFns, useOnErrorAuto, useUser } from 'util/hooks'
import { validateNonEmpty } from 'util/validation'
import { DeviationCategories } from '.'
import { ALL_CATEGORIES_QUERY } from '../queries'
import {
  AllCategoriesQuery,
  CreateDeviationInputExactDeviationImages,
  CreateDeviationMutationVariables,
  Deviation,
  PatchDeviationMutationVariables,
  ShallowDeviationCategory,
} from '../types.graphql'

interface CardProps {
  noCard: boolean
}
const Card = styled.div<CardProps>`
  width: 450px;

  ${props =>
    !props.noCard &&
    css`
      ${props => props.theme.media.desktop} {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        background-color: white;

        margin-top: 1rem;
        padding: 2rem 2.75rem;
      }

      ${props => props.theme.media.mobile} {
        width: 100%;
      }
    `};
`

const FormField = styled(BaseFormField)`
  margin-bottom: 1rem;
`

export interface DeviationData {
  title: string
  date: Date
  project: string | null
  description: string
  suggestedAction: string
  categories: string[]
  imagesAdd: CreateDeviationInputExactDeviationImages[]
  imagesRemove: string[]
}

interface DeviationFormProps {
  deviation?: Deviation
  projectId?: string
  loading: boolean
  noCard?: boolean

  onSubmit: (
    form: CreateDeviationMutationVariables | PatchDeviationMutationVariables
  ) => void
}

export const DeviationForm: React.FC<DeviationFormProps> = ({
  deviation,
  projectId,
  loading: deviationLoading,
  noCard = false,

  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      title: 'common.title',
      date: 'common.date',
      description: 'common.description',
      suggestedAction: 'deviations.suggested-action',
      categories: 'deviations.categories',
      images: 'deviations.images-of-deviation',

      registerDeviation: 'deviations.register-deviation',
      save: 'common.save',
    },

    validation: {
      required: 'common.required',
    },
  })

  const deviationInserted = useRef(false)
  const initialCategories = useRef<string[]>([])
  const filePickerRef = useRef<FilePickerRef>(null)

  const { format } = useDateFns()
  const onErrorAuto = useOnErrorAuto()
  const me = useUser()

  const { loading: categoriesLoading, data } = useQuery<AllCategoriesQuery>(
    ALL_CATEGORIES_QUERY,
    {
      onError: onErrorAuto(),
    }
  )

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formValuesEdited,
    updateForm,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<DeviationData>({
    values: {
      title: '',
      date: new Date(),
      description: '',
      suggestedAction: '',
      project: projectId ?? null,
      categories: [],
      imagesAdd: [],
      imagesRemove: [],
    },
    validators: {
      title: validateNonEmpty(translations.validation.required),
      description: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  useLayoutEffect(() => {
    if (!deviation || deviationInserted.current) return
    deviationInserted.current = true

    const categories = deviation.categories.edges.map(({ node }) => node.id)
    const values: typeof form = {
      title: deviation.title,
      date: deviation.date ? new Date(deviation.date) : new Date(),
      description: deviation.description ?? '',
      project: deviation.project?.id ?? undefined,
      suggestedAction: deviation.suggestedAction ?? '',
      categories,
      imagesAdd: [],
      imagesRemove: [],
    }

    if (filePickerRef.current) {
      const existing: FilePickerFile[] = deviation.images.edges.map(
        ({ node }) => ({
          id: node.id,
          name: node.title,
          file: node.image,
        })
      )
      filePickerRef.current.updateInternalValue({
        new: [],
        existing,
        deleted: [],
      })
    }

    updateForm(values)
    updateInitialValues(values)

    initialCategories.current = categories
  }, [deviation, updateForm, updateInitialValues])

  const categories = useMemo(
    () => data?.allDeviationCategories.edges.map(({ node }) => node) ?? [],
    [data]
  )
  const unmarkedCategories = useMemo(
    () => categories.filter(cat => !form.categories.some(c => c === cat.id)),
    [categories, form.categories]
  )

  const handlePatchDeviation = useCallback(
    async (values: typeof form, deviationId: string) => {
      onSubmit({
        id: deviationId,
        input: {
          ...values,
          date: format(values.date, 'yyyy-MM-dd'),
          project: values.project ?? undefined,
        },
      })
    },
    [format, onSubmit]
  )

  function handleCreateDeviation(values: typeof form) {
    const { imagesAdd, imagesRemove, ...rest } = values
    onSubmit({
      input: {
        ...rest,
        images: imagesAdd,
        date: format(values.date, 'yyyy-MM-dd'),
        project: values.project ?? undefined,
      },
    })
  }

  function handleSubmit(values: typeof form) {
    if (deviation?.id) {
      handlePatchDeviation(values, deviation.id)
    } else handleCreateDeviation(values)
  }

  function handleToggleCategory(category: ShallowDeviationCategory) {
    updateForm({
      categories: xor(form.categories, [category.id]),
    })
  }

  function handleFilePickerChange(value: FilePickerOnChangeValue) {
    const imagesAdd = value.new.map<Single<typeof form['imagesAdd']>>(file => ({
      image: file.file,
      title: file.name,
      uploadedBy: me.id,
    }))
    const imagesRemove = value.deleted.reduce<string[]>(
      (acc, cur) => (typeof cur.id === 'undefined' ? acc : [...acc, cur.id]),
      []
    )

    updateForm({
      imagesAdd,
      imagesRemove,
    })
  }

  const categoriesEdited = useMemo(() => {
    if (form.categories.length !== initialCategories.current.length) return true

    for (const category of form.categories)
      if (!initialCategories.current.includes(category)) return true

    return false
  }, [form.categories])

  const formEdited = useMemo(
    () =>
      Object.entries(formValuesEdited).reduce(
        (acc, [key, value]) =>
          acc || (key === 'categories' ? categoriesEdited : value),
        false
      ),
    [categoriesEdited, formValuesEdited]
  )

  const loading = deviationLoading || categoriesLoading
  const disableSubmitButton = loading || !formValid || !formEdited

  return (
    <Card noCard={noCard}>
      <FormField error={!!errors.title} required>
        <label>{translations.form.title}</label>

        <Input
          name="title"
          value={form.title}
          error={errors.title}
          disabled={loading}
          fullWidth
          autoFocus
          onChange={handler('title')}
        />
      </FormField>

      <FormField error={!!errors.date} required>
        <label>{translations.form.date}</label>

        <DatePicker
          value={form.date}
          fullWidth
          onChange={value => {
            updateForm({ date: value })
          }}
        />
      </FormField>

      <FormField error={!!errors.description} required>
        <label>{translations.form.description}</label>

        <TextArea
          value={form.description ?? ''}
          error={errors.description}
          disabled={loading}
          fullWidth
          height="100px"
          onChange={value => {
            updateForm({ description: value })
          }}
        />
      </FormField>

      <FormField error={!!errors.suggestedAction}>
        <label>{translations.form.suggestedAction}</label>

        <TextArea
          value={form.suggestedAction ?? ''}
          error={errors.suggestedAction}
          disabled={loading}
          fullWidth
          height="100px"
          onChange={value => {
            updateForm({ suggestedAction: value })
          }}
        />
      </FormField>

      <FormField error={!!errors.categories}>
        <label>{translations.form.categories}</label>

        <DeviationCategories
          categories={categories}
          unmarkedCategories={unmarkedCategories}
          onCategoryClick={handleToggleCategory}
        />
      </FormField>

      <FormField>
        <label>{translations.form.images}</label>

        <FilePicker
          ref={filePickerRef}
          images
          onChange={handleFilePickerChange}
        />
      </FormField>

      <Button
        disabled={disableSubmitButton}
        fullWidth
        loading={loading}
        onClick={submitHandler(handleSubmit)}
      >
        {deviationInserted.current
          ? translations.form.save
          : translations.form.registerDeviation}
      </Button>
    </Card>
  )
}

import { ObservableQueryFields } from '@apollo/client'
import { useCallback, useState } from 'react'
import { RelayPageInfo } from 'types/graphql'
import {
  CustomersShallowQuery,
  CustomersShallowQueryVariables,
} from './types.graphql'

export function useCustomersPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreCustomers: ObservableQueryFields<
    CustomersShallowQuery,
    CustomersShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreCustomers({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreCustomers])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export interface OrganizationDetails {
  name: string
  organizationNumber?: string
  address: string
  postalCode: string
  postalArea: string
}

interface DataAddress {
  adresse: string[]
  kommune: string
  kommunenummer: string
  land: string
  landkode: string
  postnummer: string
  poststed: string
}

export const organizationTypes = ['ASA', 'AS', 'ENK', 'ANS', 'DA', 'NUF', 'SA']

export async function searchForOrganization(
  organizationNumber: string
): Promise<OrganizationDetails | null> {
  const response = await fetch(
    `https://data.brreg.no/enhetsregisteret/api/enheter/${organizationNumber}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  )
  if (!response || !response.ok)
    return Promise.reject('No organization found with this number.')

  const data = await response.json()

  try {
    const dataAddress: DataAddress | undefined =
      data.forretningsadresse ?? data.postadresse
    if (!dataAddress) return null

    return {
      name: data.navn ?? '',
      address: dataAddress.adresse[0] ?? '',
      postalCode: dataAddress.postnummer ?? '',
      postalArea: dataAddress.poststed ?? '',
    }
  } catch {
    return null
  }
}

export async function searchForOrganizationName(
  name: string
): Promise<OrganizationDetails[] | null> {
  const urlifyName = encodeURI(name)
  const response = await fetch(
    `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${urlifyName}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  )
  if (!response || !response.ok)
    return Promise.reject('No organizations found with this name.')

  const json = await response.json()
  if (!json._embedded) return null

  try {
    const data: OrganizationDetails[] = json._embedded.enheter.reduce(
      (acc: OrganizationDetails[], match: any) => {
        const dataAddress: DataAddress | undefined =
          match.forretningsadresse ?? match.postadresse
        if (!dataAddress) return acc

        const unit: OrganizationDetails = {
          name: match.navn ?? '',
          organizationNumber: match.organisasjonsnummer,
          address: dataAddress.adresse[0] ?? '',
          postalCode: dataAddress.postnummer ?? '',
          postalArea: dataAddress.poststed ?? '',
        }
        return [...acc, unit]
      },
      []
    )

    return data
  } catch {
    return null
  }
}

import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import {
  ColorPicker as BaseColorPicker,
  ColorPickerProps as BaseColorPickerProps,
} from '@ur/react-components'
import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

interface StyledColorPickerProps {
  error?: string | null
}

const Wrapper = styled.div<StyledColorPickerProps>`
  position: relative;

  border: 1px solid ${props => props.theme.colors.gray5};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  &:focus-within {
    border-color: ${props =>
      !!props.error ? props.theme.colors.error : props.theme.colors.dark};
  }
`

const StyledColorPicker = styled(BaseColorPicker)`
  & > div {
    width: ${props => props.height};
  }
`

const BoringErrorWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 0;
  will-change: transform;

  color: ${props => props.theme.colors.error};
  font-size: 0.7rem;
  white-space: nowrap;
`
export const ErrorWrapper = animated(BoringErrorWrapper)

interface ColorPickerProps extends BaseColorPickerProps {
  error?: string | null
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  error,
  ...props
}) => {
  const [internalError, setInternalError] = useState(error)

  useEffect(() => {
    setInternalError(error)
  }, [error])

  const errorAnimation = useTransition(internalError, {
    from: {
      transform: 'translateY(-50%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateY(-100%)',
      opacity: 1,
    },
    leave: {
      transform: 'translateY(-50%)',
      opacity: 0,
    },
  })
  return (
    <Wrapper error={error}>
      {!!error &&
        errorAnimation(
          (vals, err) => err && <ErrorWrapper style={vals}>{err}</ErrorWrapper>
        )}

      <StyledColorPicker
        border="0px"
        height={isMobileOnly ? '56px' : '40px'}
        {...props}
      />
    </Wrapper>
  )
}

import { useMutation } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import {
  Button as BaseButton,
  Card,
  FormField as BaseFormField,
  Input,
} from 'components'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, useUser } from 'util/hooks'
import { usePostalCode } from 'util/hooks/usePostalCode'
import {
  validateNonEmpty,
  validatePassword,
  validatePhoneNumber,
} from 'util/validation'
import { REGISTER_USER_MUTATION } from './mutations'
import {
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  ${props => props.theme.media.mobile} {
    height: auto;
  }
`
const Layout = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo      logo'
    'header    header'
    'firstName lastName'
    'phone     phone'
    'address   address'
    'zip       city'
    'password  confirm'
    '.         submit';
  gap: 1.5rem;

  width: clamp(600px, 25%, 800px);

  background-color: ${props => props.theme.colors.white};

  ${props => props.theme.media.mobile}  {
    grid-template-areas:
      'logo logo'
      'header header'
      'firstName firstName'
      'lastName lastName'
      'phone phone'
      'address address'
      'zip zip'
      'city city'
      'password password'
      'confirm confirm'
      '.       submit';
    gap: 2rem;

    width: 100%;
    padding: 1.5rem;

    box-shadow: none;
    background-color: ${props => props.theme.colors.body};
  }
`
const FormField = styled(BaseFormField)`
  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
  }
`
const Logo = styled.img<{ area: string }>`
  margin-top: 1rem;
  width: 55%;
  grid-area: ${props => props.area};

  ${props => props.theme.media.mobile}  {
    width: 45%;
  }
`
const Header = styled.h1<{ area: string }>`
  padding-top: 1rem;
  font-size: 24px;
  font-weight: 600;
  min-width: 100%;
  white-space: nowrap;

  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 1rem;
  }
`
const CenteredLoading = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 15%;

  display: flex;
  flex-direction: column;
  align-items: center;
`
interface ButtonProps {
  area: string
}
const Button = styled(BaseButton)<ButtonProps>`
  grid-area: ${props => props.area};
  margin-top: 1rem;
`

type RegisterUserForm = RegisterUserMutationVariables & {
  newPasswordAgain: string
}

export const RegisterUser: React.FC = () => {
  const translations = useTranslate({
    registerUser: 'users.register-user',
    firstName: 'common.first-name',
    lastName: 'common.last-name',
    phone: 'common.phone',
    post: 'common.postal-code',
    city: 'common.city',
    newPassword: 'users.new-password',
    confirmPassword: 'users.password-confirm',
    address: 'common.address',
    cancel: 'common.cancel',
    next: 'common.next',
    formError: 'common.error',

    validation: {
      required: 'common.required-fields-missing',
      passwordsUnequal: 'users.passwords-do-not-match',
      weakPassword: 'users.errors.change-password-weak',
      invalidPhoneNumber: 'errors.invalid-phone-number',
    },
  })

  const me = useUser()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()

  const {
    formValues: form,
    formErrors: errors,
    formValuesEdited,
    formValid,
    enableValidation,
    formChangeHandler: handler,
    updateForm,
    submitHandler,
  } = useForm<RegisterUserForm>({
    values: {
      firstName: me.firstName || '',
      lastName: me.lastName || '',
      phoneNumber: me.phoneNumber || '',
      address: me.address || '',
      postalCode: me.postalCode || '',
      city: me.city || '',
      password: '',
      newPasswordAgain: '',
    },
    validators: {
      firstName: validateNonEmpty(translations.validation.required),
      lastName: validateNonEmpty(translations.validation.required),
      phoneNumber: [
        validateNonEmpty(translations.validation.required),
        validatePhoneNumber(translations.validation.invalidPhoneNumber),
      ],
      address: validateNonEmpty(translations.validation.required),
      postalCode: validateNonEmpty(translations.validation.required),
      city: validateNonEmpty(translations.validation.required),
      password: validatePassword(translations.validation.weakPassword),
      newPasswordAgain: validatePassword(
        translations.validation.weakPassword,
        'password',
        translations.validation.passwordsUnequal
      ),
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['phoneNumber'],
    },
  })

  const { loading: cityLoading } = usePostalCode(form.postalCode, {
    onCityChange(city) {
      !!city && updateForm({ city: city ?? '' })
    },
  })

  const [registerUser, { loading }] = useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(REGISTER_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Bootstrap'],
    variables: {
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: form.phoneNumber,
      address: form.address,
      postalCode: form.postalCode,
      city: form.city,
      password: form.newPasswordAgain,
    },
    onCompleted() {
      history.push('/projects')
      mixpanel.track('User Registered')
    },
    onError: onErrorAuto(),
  })

  function handleSubmit(variables: typeof form) {
    registerUser({ variables })
  }

  return (
    <Wrapper>
      <Layout>
        <Logo src={logo} area="logo" />

        <Header area="header">{translations.registerUser}</Header>

        {loading ? (
          <CenteredLoading>
            <Loader.Spinner type="stroke" />
          </CenteredLoading>
        ) : (
          <>
            <FormField required area="firstName">
              <label>{translations.firstName}</label>
              <Input
                id="firstName"
                value={form.firstName}
                error={errors.firstName}
                fullWidth
                autoFocus
                onChange={handler('firstName')}
              />
            </FormField>

            <FormField required area="lastName">
              <label>{translations.lastName}</label>
              <Input
                id="lastName"
                value={form.lastName}
                error={errors.lastName}
                fullWidth
                onChange={handler('lastName')}
              />
            </FormField>

            <FormField required area="phone">
              <label>{translations.phone}</label>
              <Input
                id="phoneNumber"
                value={form.phoneNumber}
                error={errors.phoneNumber}
                inputMode="tel"
                fullWidth
                onBlur={() =>
                  formValuesEdited.phoneNumber &&
                  enableValidation('phoneNumber')
                }
                onChange={handler('phoneNumber')}
              />
            </FormField>

            <FormField required area="address">
              <label>{translations.address}</label>
              <Input
                id="address"
                value={form.address}
                error={errors.address}
                fullWidth
                onChange={handler('address')}
              />
            </FormField>

            <FormField required area="zip">
              <label>{translations.post}</label>
              <Input
                id="postalCode"
                value={form.postalCode}
                error={errors.postalCode}
                autoComplete="postal-code"
                fullWidth
                onChange={handler('postalCode')}
              />
            </FormField>

            <FormField required area="city">
              <label>{translations.city}</label>
              <Input
                id="city"
                value={form.city}
                error={errors.city}
                loading={cityLoading}
                fullWidth
                onChange={handler('city')}
              />
            </FormField>

            <FormField area="password">
              <label>{translations.newPassword}</label>
              <Input
                id="newPassword"
                type="password"
                value={form.password}
                error={errors.password}
                fullWidth
                onChange={handler('password')}
              />
            </FormField>

            <FormField area="confirm">
              <label>{translations.confirmPassword}</label>
              <Input
                id="newPasswordAgain"
                type="password"
                value={form.newPasswordAgain}
                error={errors.newPasswordAgain}
                fullWidth
                onChange={handler('newPasswordAgain')}
              />
            </FormField>

            <Button
              area="submit"
              variant="primary"
              fullWidth
              disabled={!formValid || loading}
              onClick={submitHandler(handleSubmit)}
            >
              {translations.next}
            </Button>
          </>
        )}
      </Layout>
    </Wrapper>
  )
}

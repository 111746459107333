import { IconProps } from '@ur/react-components'
import { Button, PageHeaderButton } from 'components'
import { useMemo } from 'react'
import styled from 'styled-components'

interface WrapperProps {
  numberOfButtons: number
  showDate: boolean
}

const Wrapper = styled.footer<WrapperProps>`
  position: fixed;
  z-index: 1;
  bottom: 0;

  display: grid;
  grid-template-columns: repeat(${props => props.numberOfButtons}, 1fr);
  grid-template-rows: ${props => (props.showDate ? 'auto 1fr' : '1fr')};
  gap: 0.5rem;

  width: 100vw;
  height: ${props =>
    props.numberOfButtons === 0 ? '2.5rem' : props.showDate ? '6rem' : '4rem'};
  padding: 0.7rem 1rem;

  background-color: white;
  box-shadow: 0 0 8px rgba(156, 106, 106, 0.1);
`

interface PageFooterProps {
  className?: string

  buttons: PageHeaderButton[]
  modifiedAt?: JSX.Element
}

export const PageFooter: React.FC<PageFooterProps> = ({
  className,
  buttons,
  modifiedAt,
}) => {
  const shownButtons = useMemo(
    () => buttons.filter(button => !button.hide),
    [buttons]
  )

  return (
    <Wrapper
      className={className}
      numberOfButtons={shownButtons.length}
      showDate={!!modifiedAt}
    >
      {!!modifiedAt && modifiedAt}
      {shownButtons.map(btn => {
        const icon: IconProps | undefined =
          typeof btn.icon === 'string'
            ? {
                icon: btn.icon,
              }
            : btn.icon

        return (
          <Button
            key={btn.text}
            iconRightProps={icon}
            disabled={btn.disabled}
            background={btn.background ?? 'primary'}
            height="100%"
            onClick={btn.onClick}
          >
            {btn.text}
          </Button>
        )
      })}
    </Wrapper>
  )
}

import { SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  DatePicker,
  FormField as BaseFormField,
  NumberInput,
  Select,
  TextArea,
  QuickNumberInput,
} from 'components'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDateFns } from 'util/hooks'
import {
  CreateTimeEntryMutationVariables,
  PatchTimeEntryMutationVariables,
  TimeEntryOption,
  TimeEntryType,
} from '../types.graphql'
import { getTimeEntrySum, updateTimeEntryType } from '../util'

const Card = styled.div`
  width: 450px;

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    margin-top: 1rem;
    padding: 2rem 2.75rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const FormField = styled(BaseFormField)`
  margin-bottom: 1rem;
`

const TimeEntryTypeLabel = styled.span`
  display: flex;
  .hours {
    color: ${props => props.theme.colors.gray3};
  }
`

export interface TimeEntryData {
  date: Date
  description: string
  kilometersDriven: number | null
}

export type Entries = {
  hoursOrdinary: number
  hoursOvertimeFifty: number
  hoursOvertimeHundred: number
  hoursTimeBankOrdinary: number
  hoursTimeBankFifty: number
  hoursTimeBankHundred: number
}

interface TimeEntryFormProps {
  timeEntry?: TimeEntryType
  projectId?: string
  loading: boolean

  onSubmit: (
    form: CreateTimeEntryMutationVariables | PatchTimeEntryMutationVariables
  ) => void
}

export const TimeEntryForm: React.FC<TimeEntryFormProps> = ({
  timeEntry,
  projectId,
  loading,

  onSubmit,
}) => {
  const translations = useTranslate({
    form: {
      date: 'common.date',
      timeEntryType: 'timesheets.time-entry-type',
      numberOfHours: 'timesheets.number-of-hours',
      description: 'common.description',
      kilometersDriven: 'timesheets.kilometers-driven',

      registerTimeEntry: 'timesheets.register-hours',
      nHours: ['common.n-hours', { n: 0 }],
      save: 'common.save',
    },

    timeTypes: {
      hoursOrdinary: 'timesheets.hours-ordinary',
      hoursOvertimeFifty: 'timesheets.hours-overtime-fifty',
      hoursOvertimeHundred: 'timesheets.hours-overtime-hundred',
      hoursTimeBankOrdinary: 'timesheets.hours-time-bank-ordinary',
      hoursTimeBankFifty: 'timesheets.hours-time-bank-fifty',
      hoursTimeBankHundred: 'timesheets.hours-time-bank-hundred',
    },
  })

  const timeEntryInserted = useRef(false)

  const { format } = useDateFns()

  const hours: Entries = {
    hoursOrdinary: 0,
    hoursOvertimeFifty: 0,
    hoursOvertimeHundred: 0,
    hoursTimeBankOrdinary: 0,
    hoursTimeBankFifty: 0,
    hoursTimeBankHundred: 0,
  }
  const [hourType, setHourType] = useState<TimeEntryOption>('hoursOrdinary')
  const [entries, setEntries] = useState(hours)
  const [initialEntries, setInitialEntries] = useState(hours)

  const {
    formValues: form,
    formErrors: errors,
    formEdited,
    updateForm,
    updateInitialValues,
  } = useForm<TimeEntryData>({
    values: {
      date: new Date(),
      description: '',
      kilometersDriven: null,
    },
    config: {
      initAsInvalid: true,
    },
  })

  useEffect(() => {
    if (!timeEntry || timeEntryInserted.current) return
    timeEntryInserted.current = true

    const values: typeof form = {
      date: timeEntry.date ? new Date(timeEntry.date) : new Date(),
      description: timeEntry.description ?? '',
      kilometersDriven: timeEntry.kilometersDriven,
    }

    const newHours = {
      hoursOrdinary: parseFloat(timeEntry.hoursOrdinary) || 0,
      hoursOvertimeFifty: parseFloat(timeEntry.hoursOvertimeFifty) || 0,
      hoursOvertimeHundred: parseFloat(timeEntry.hoursOvertimeHundred) || 0,
      hoursTimeBankOrdinary: parseFloat(timeEntry.hoursTimeBankOrdinary) || 0,
      hoursTimeBankFifty: parseFloat(timeEntry.hoursTimeBankFifty) || 0,
      hoursTimeBankHundred: parseFloat(timeEntry.hoursTimeBankHundred) || 0,
    }

    setEntries(newHours)
    setInitialEntries(newHours)

    updateForm(values)
    updateInitialValues(values)
  }, [timeEntry, updateForm, updateInitialValues])

  const timeEntryOptions = useMemo<TimeEntryOption[]>(
    () => [
      'hoursOrdinary',
      'hoursOvertimeHundred',
      'hoursOvertimeFifty',
      'hoursTimeBankFifty',
      'hoursTimeBankHundred',
      'hoursTimeBankOrdinary',
    ],
    []
  )

  const timeEntryTypes = useMemo<SelectOption<TimeEntryOption, JSX.Element>[]>(
    () =>
      timeEntryOptions.map(type => ({
        value: type as TimeEntryOption,
        label: (
          <TimeEntryTypeLabel>
            {translations.timeTypes[type]}&nbsp;
            <div className="hours">
              {'(' + translations.form.nHours({ n: entries[type] }) + ')'}
            </div>
          </TimeEntryTypeLabel>
        ),
      })) ?? [],
    [entries, timeEntryOptions, translations.form, translations.timeTypes]
  )

  function handleSubmit() {
    const input = {
      date: format(form.date, 'yyyy-MM-dd'),
      description: form.description,
      kilometersDriven: form.kilometersDriven ?? undefined,
      hoursOrdinary: entries['hoursOrdinary'].toString(),
      hoursOvertimeFifty: entries['hoursOvertimeFifty'].toString(),
      hoursOvertimeHundred: entries['hoursOvertimeHundred'].toString(),
      hoursTimeBankOrdinary: entries['hoursTimeBankOrdinary'].toString(),
      hoursTimeBankFifty: entries['hoursTimeBankFifty'].toString(),
      hoursTimeBankHundred: entries['hoursTimeBankHundred'].toString(),
    }

    if (!!timeEntry) onSubmit({ id: timeEntry.id, input })
    else
      onSubmit({
        input: {
          ...input,
          project: projectId ?? '',
        },
      })
  }

  const disableSubmitButton = useMemo(
    () =>
      loading ||
      getTimeEntrySum(entries) === 0 ||
      (!formEdited && isEqual(entries, initialEntries)),
    [entries, formEdited, initialEntries, loading]
  )

  return (
    <Card>
      <FormField error={!!errors.date} required>
        <label>{translations.form.date}</label>

        <DatePicker
          value={form.date}
          fullWidth
          onChange={value => {
            updateForm({ date: value })
          }}
        />
      </FormField>

      <FormField required>
        <label>{translations.form.timeEntryType}</label>

        <Select
          value={hourType}
          options={timeEntryTypes}
          disabled={loading}
          fullWidth
          onChange={value => !!value && setHourType(value)}
        />
      </FormField>

      <FormField required>
        <label>{translations.form.numberOfHours}</label>

        <QuickNumberInput
          value={entries[hourType]}
          disabled={loading}
          fullWidth
          onChange={value =>
            setEntries(updateTimeEntryType(hourType, value, entries))
          }
        />
      </FormField>

      <FormField error={!!errors.description}>
        <label>{translations.form.description}</label>

        <TextArea
          value={form.description ?? ''}
          error={errors.description}
          disabled={loading}
          fullWidth
          height="100px"
          onChange={value => updateForm({ description: value })}
        />
      </FormField>

      <FormField error={!!errors.kilometersDriven}>
        <label>{translations.form.kilometersDriven}</label>

        <NumberInput
          value={form.kilometersDriven ?? 0}
          error={errors.kilometersDriven}
          disabled={loading}
          fullWidth
          min={0}
          onChange={value => updateForm({ kilometersDriven: value })}
        />
      </FormField>

      <Button
        disabled={disableSubmitButton}
        fullWidth
        loading={loading}
        onClick={handleSubmit}
      >
        {timeEntryInserted.current
          ? translations.form.save
          : translations.form.registerTimeEntry}
      </Button>
    </Card>
  )
}

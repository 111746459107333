import React from 'react'
import styled, { css } from 'styled-components'
import { contrastColorNicely, overloadColor } from 'util/style'
import {
  DeviationCategoryNode,
  ShallowDeviationCategory,
} from '../types.graphql'

interface WrapperProps {
  backgroundColor: string
  color: string
  selected: boolean
  unmarked: boolean
  big: boolean
  clickable: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  padding: ${props => (props.big ? '8px 18px' : '4px 10px')};
  border-radius: 999px;

  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};

  font-size: 12px;
  font-weight: 600;
  user-select: none;

  ${props =>
    props.unmarked &&
    css`
      color: ${props => props.theme.colors.gray2};
      background-color: ${props => props.theme.colors.gray7};
    `};

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${overloadColor(props.backgroundColor) + 'ba'};
      }
    `}

  outline: ${props => props.selected && '2px solid #323232'};
`

interface DeviationCategoryProps {
  category: Pick<DeviationCategoryNode, 'id' | 'title' | 'color'>
  selected: boolean
  big?: boolean
  disabled?: boolean
  unmarked?: boolean

  onClick?: (handledCategory: ShallowDeviationCategory) => void
}

export const DeviationCategory: React.FC<DeviationCategoryProps> = ({
  category,

  selected,
  big = false,
  disabled = false,
  unmarked = false,

  onClick,
}) => {
  return (
    <Wrapper
      color={contrastColorNicely(category.color)}
      backgroundColor={category.color}
      big={big}
      selected={selected}
      unmarked={unmarked}
      clickable={typeof onClick !== 'undefined' && !disabled}
      onClick={() => !disabled && onClick?.(category)}
    >
      {category.title}
    </Wrapper>
  )
}

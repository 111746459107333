import React from 'react'
import styled from 'styled-components'
import { ShallowManualFolder } from '../types.graphql'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useDateFns } from 'util/hooks'
import { Icon } from '@ur/react-components'
import { FolderPath } from './FolderPath'

const Wrapper = styled.section`
  margin-top: 1rem;

  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;

  border-bottom: 1px ${props => props.theme.colors.gray6} solid;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;

    & + div {
      border-left: 1px solid ${props => props.theme.colors.gray6};
    }
    &.menus {
      justify-content: flex-end;

      i {
        transform: translateY(1px);
      }
    }
  }
`
const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
`
const FoldersAndEntries = styled.div`
  gap: 0.8rem;
  color: ${props => props.theme.colors.gray3};

  font-size: 14px;
  font-weight: 600;
`
const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;

  width: 100%;
  padding: 1rem;

  a {
    display: flex;
    align-items: center;
    gap: 1rem;

    font-size: 18px;
    font-weight: 600;
  }
`
const Description = styled.div`
  padding: 1rem;

  color: ${props => props.theme.colors.gray1};
  font-size: 0.9rem;

  border-top: 1px ${props => props.theme.colors.gray6} solid;
`
interface FolderCardProps {
  folder: ShallowManualFolder

  editDeleteMenu: TableMenu
  orderArrows: JSX.Element | null
  showPath?: boolean

  onResetSearch?: () => void
}

export const FolderCard: React.FC<FolderCardProps> = ({
  folder,

  editDeleteMenu,
  orderArrows,
  showPath = false,

  onResetSearch = () => void 0,
}) => {
  const { format } = useDateFns()

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(folder.updatedAt), 'PPP')}</DateField>

        <FoldersAndEntries>
          <Icon icon="folder-open" type="solid" />
          {folder.numSubFolders}
        </FoldersAndEntries>

        <FoldersAndEntries>
          <Icon icon="file" type="solid" />
          {folder.numEntries}
        </FoldersAndEntries>

        <div className="menus">
          <Menu icon="ellipsis-h" config={editDeleteMenu} />
        </div>
      </Header>

      <Title>
        <Link to={`/handbook/${folder.slug}`}>
          <Icon icon="folder-open" type="solid" size="25px" />
          {folder.name}
        </Link>

        {orderArrows}
      </Title>

      {!!folder.description && <Description>{folder.description}</Description>}

      {showPath && !!folder.path && (
        <Description>
          <FolderPath folders={folder.path} onGoToRoot={onResetSearch} />
        </Description>
      )}
    </Wrapper>
  )
}

import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { ManualFolderForm } from './ManualFolderForm'
import { ManualFoldersPromptState } from './types'
import {
  PatchManualFolderMutationVariables,
  ShallowManualFolder,
} from './types.graphql'
import { isPatchManualFolderMutationVariables } from './util'

interface EditManualFolderProps {
  id: string
  manualFolder: ShallowManualFolder

  root?: boolean

  onSubmit: PromptResolve<PatchManualFolderMutationVariables | null>
}
export const EditManualFolderModal: React.FC<EditManualFolderProps> = ({
  id,
  manualFolder,

  root,

  onSubmit,
}) => {
  const translations = useTranslate({
    editFolder: 'handbook.edit-folder',
    editSubFolder: 'handbook.edit-sub-folder',
  })

  const state = usePromptState<ManualFoldersPromptState>(id)

  function handleSubmit(values: PatchManualFolderMutationVariables) {
    onSubmit(values)
  }

  return (
    <RegularModal
      title={root ? translations.editFolder : translations.editSubFolder}
      width="480px"
      onClose={() => onSubmit(null)}
    >
      <ManualFolderForm
        manualFolder={manualFolder}
        loading={state.loading}
        onSubmit={variables =>
          isPatchManualFolderMutationVariables(variables) &&
          handleSubmit(variables)
        }
        onCancel={() => onSubmit(null)}
      />
    </RegularModal>
  )
}

import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal, Tree } from 'components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { FolderTree } from '../util'

const Wrapper = styled.div`
  p {
    margin: 0 0 1rem;
  }
`

interface MoveFileModalProps {
  file: { id: string; name: string }
  originalFolder: string | null
  folderTree: FolderTree

  isFolder?: boolean

  onResolve: PromptResolve<string | null>
}

export const MoveFileModal: React.FC<MoveFileModalProps> = ({
  file,
  originalFolder,
  folderTree,

  isFolder = false,

  onResolve,
}) => {
  const translations = useTranslate({
    moveFile: 'files.move-file',
    moveFolder: 'files.move-folder',
    move: 'common.move',
    chooseFolder: ['files.move-file-choose-folder', { name: '' }],
  })

  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)

  return (
    <RegularModal
      title={isFolder ? translations.moveFolder : translations.moveFile}
      submitDisabled={
        selectedFolder === null || selectedFolder === originalFolder
      }
      submitText={translations.move}
      onCancel={() => onResolve(null)}
      onSubmit={() => onResolve(selectedFolder)}
    >
      <Wrapper>
        <p>{translations.chooseFolder({ name: file.name })}</p>

        <Tree
          items={folderTree}
          selectedItem={selectedFolder}
          onItemClick={item => setSelectedFolder(item.value)}
        />
      </Wrapper>
    </RegularModal>
  )
}

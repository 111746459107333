import { ObservableQueryFields } from '@apollo/client'
import { useCallback, useState } from 'react'
import { RelayPageInfo } from 'types/graphql'
import { AllProductsQuery, AllProductsQueryVariables } from '../types.graphql'

export function useProductsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreProducts: ObservableQueryFields<
    AllProductsQuery,
    AllProductsQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreProducts({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreProducts])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

import {
  NumberInput as BaseInput,
  NumberInputProps as BaseInputProps,
} from '@ur/react-components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css, useTheme } from 'styled-components'

interface StyledInputProps {
  center: boolean
}
const StyledInput = styled(BaseInput)<StyledInputProps>`
  input {
    height: 100%;
    padding: 0 10px;

    text-align: ${props => props.center && 'center'};

    &:focus {
      border-color: ${props => props.theme.colors.dark};
    }
    &:disabled {
      background-color: ${props => props.theme.colors.gray7};
    }
    &::placeholder {
      color: ${props => props.theme.colors.gray4};
    }
  }

  ${props =>
    !!props.error &&
    css`
      input {
        border-color: ${props.theme.colors.error};
        color: ${props.theme.colors.error} !important;

        &:focus {
          border-color: ${props.theme.colors.error} !important;
        }
      }
    `}
`

interface NumberInputProps extends BaseInputProps {
  center?: boolean
  noScroll?: boolean
  maxDecimals?: number
}
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ center = false, noScroll = false, maxDecimals, ...props }, ref) => {
    const theme = useTheme()

    return (
      <StyledInput
        ref={ref}
        border="1px solid"
        borderColor="gray5"
        height={isMobileOnly ? '56px' : '40px'}
        borderRadius={theme.sizes.defaultBorderRadius}
        center={center}
        onWheel={noScroll ? e => e.preventDefault() : undefined}
        {...props}
        onChange={value =>
          typeof maxDecimals !== 'undefined'
            ? props.onChange(parseFloat(value.toFixed(maxDecimals)))
            : props.onChange(value)
        }
      />
    )
  }
)

import { gql } from '@apollo/client'

/* QUERIES */
export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      fullName
      profilePicture
      profilePicture256
      address
      postalCode
      city
      phoneNumber
      initials
      username
      email
      needsPasswordChange
      hasRegistered
      isStaff
      isSuperuser
      isActive
      timebankTotal
      timebankWithdrawal
      permissions
      emergencyContacts {
        edges {
          node {
            id
            name
            relation
            phoneNumber
            primary
          }
        }
      }
      settings {
        edges {
          node {
            id
            key
            value
          }
        }
      }
    }
  }
`

export const USER_SHALLOW_QUERY = gql`
  query UserShallow($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      fullName
      profilePicture
      address
      postalCode
      city
      phoneNumber
      email
      emergencyContacts {
        edges {
          node {
            id
            name
            relation
            phoneNumber
            primary
          }
        }
      }
      userTypes {
        id
        name
      }
    }
  }
`

export const SELECT_USER_QUERY = gql`
  query SelectUser(
    $q: String
    $includeIds: [ID]
    $onlyProjectLeaders: Boolean
    $first: Int
    $after: String
  ) {
    allUsers(
      q: $q
      includeIds: $includeIds
      onlyProjectLeaders: $onlyProjectLeaders
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          fullName
          initials
          profilePicture
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const USER_TYPE_QUERY = gql`
  query UserTypeQuery($id: ID!) {
    userType(id: $id) {
      id
      name
      color
      isAdministrator
      permissions
    }
  }
`

export const USER_TYPES_SHALLOW_QUERY = gql`
  query UserTypesShallow {
    allUserTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const USER_TYPES_QUERY = gql`
  query UserTypes {
    allUserTypes {
      edges {
        node {
          id
          name
          color
          isAdministrator
          permissions
        }
      }
    }
  }
`

export const USERS_TABLE_QUERY = gql`
  query UsersTable($q: String, $orderBy: String, $first: Int, $after: String) {
    allUsers(q: $q, orderBy: $orderBy, first: $first, after: $after) {
      edges {
        node {
          id
          fullName
          initials
          email
          phoneNumber
          address
          postalCode
          city
          profilePicture
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const USERS_SHALLOW_QUERY = gql`
  query UsersShallow($q: String, $orderBy: String) {
    allUsers(q: $q, orderBy: $orderBy) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`

export const PERMISSIONS_QUERY = gql`
  query PermissionsQuery {
    allPermissions {
      edges {
        node {
          id
          fullName
          name
        }
      }
    }
  }
`

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { ErrorPage } from 'containers/ErrorPage'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, usePermissions, useToast } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  PatchUserMutation,
  PatchUserMutationVariables,
  UserShallowQuery,
} from '.'
import { ChangeInformation, ChangeUserInfoForm } from './ChangeInformation'
import { ChangePassword, ChangePasswordForm } from './ChangePassword'
import { EditUserMobile } from './EditUserMobile'
import { CHANGE_PASSWORD_MUTATION, PATCH_USER_MUTATION } from './mutations'
import { USER_SHALLOW_QUERY } from './queries'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`
const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
`

interface EditUserProps {}

export const EditUser: React.FC<EditUserProps> = () => {
  const translations = useTranslate({
    userSettings: 'common.user-settings',
    toasts: {
      editSuccess: 'users.toasts.edit-user-success',
      editError: 'users.errors.edit-user',

      passwordSuccess: 'users.toasts.change-password-success',
      passwordError: 'users.errors.change-password',
      passwordErrorCurrent: 'users.errors.change-password-check-current',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const { id } = useParams<{ id: string }>()

  const { hasPermissionsOrMe } = usePermissions(true)
  const notAuthorized = useMemo(
    () => !hasPermissionsOrMe({ id }, PERMISSIONS.users.change.user),
    [hasPermissionsOrMe, id]
  )

  const [, setOverrides] = useGlobal('breadcrumbs.overrides')

  const { data, loading } = useQuery<UserShallowQuery, IdVariable>(
    USER_SHALLOW_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { id },
      onCompleted(data) {
        setOverrides(v => ({
          [id]: data.user.fullName,
          ...v,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const [patchUser, { loading: patchLoading }] = useMutation<
    PatchUserMutation,
    PatchUserMutationVariables
  >(PATCH_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserShallow'],
    onCompleted() {
      addToast('success', translations.toasts.editSuccess)
      mixpanel.track('Edit User')
    },
    onError: onErrorAuto(translations.toasts.editError),
  })

  const [changePassword, { loading: changePasswordLoading }] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION, {
    onCompleted(data) {
      mixpanel.track('Password Changed', {
        Success: data.changePassword.ok,
      })
      data.changePassword.ok
        ? addToast('success', translations.toasts.passwordSuccess)
        : data.changePassword.reason === 'INCORRECT_PASSWORD'
        ? addToast('error', translations.toasts.passwordErrorCurrent)
        : addToast('error', translations.toasts.passwordError)
    },
    onError: onErrorAuto(translations.toasts.passwordError),
  })

  async function handleEditSubmit(values: Partial<ChangeUserInfoForm>) {
    const { profilePicture, ...input } = values

    const picture =
      profilePicture instanceof File || profilePicture === null
        ? { profilePicture }
        : {}

    const result = await patchUser({
      variables: {
        id,
        input: {
          ...input,
          ...picture,
        },
      },
    })
    return result.data ?? null
  }

  async function handleChangePasswordSubmit(values: ChangePasswordForm) {
    const result = await changePassword({
      variables: {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
    })

    return result.data ?? null
  }

  if (notAuthorized) return <ErrorPage code="403" />

  return (
    <Wrapper>
      {isMobileOnly ? (
        <EditUserMobile
          user={data?.user}
          editLoading={loading || patchLoading}
          passwordLoading={changePasswordLoading}
          onChangePasswordSubmit={handleChangePasswordSubmit}
          onEditSubmit={handleEditSubmit}
        />
      ) : (
        <>
          <PageHeader
            title={translations.userSettings}
            loading={loading}
            largeMargin
          />

          <Cards>
            <ChangeInformation
              user={data?.user}
              loading={loading || patchLoading}
              onSubmit={handleEditSubmit}
            />
            <ChangePassword
              loading={changePasswordLoading}
              onSubmit={handleChangePasswordSubmit}
            />
          </Cards>
        </>
      )}
    </Wrapper>
  )
}

import { useMemo } from 'react'
import { useUser } from './useUser'

export function useAdmin() {
  const user = useUser()
  return useMemo(
    () => user.isSuperuser || user.userTypes.some(type => type.isAdministrator),
    [user.isSuperuser, user.userTypes]
  )
}

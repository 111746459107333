import styled from 'styled-components'
import { Card } from 'components'
import { Icon } from '@ur/react-components'
import { SelectCompany } from '../types.graphql'

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.gray8};
  }

  div.logo {
    height: 4rem;
  }
`
const Logo = styled.img`
  height: 4rem;
  max-width: 100%;
`
const Name = styled.h1`
  padding-top: 1rem;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 1rem;
  }
`

interface SelectCompanyTileInterface {
  company: SelectCompany
}

export const SelectCompanyTile: React.FC<SelectCompanyTileInterface> = ({
  company,
}) => {
  function handleNavigate(evt: React.MouseEvent<HTMLDivElement>) {
    const url = `/c/${company.shortName}/`
    evt.ctrlKey ? window.open(url) : (window.location.href = url)
  }

  return (
    <Wrapper role="link" onClick={handleNavigate}>
      <div className="logo">
        {!!company.companyLogo ? (
          <Logo src={company.companyLogo} alt={company.fullName} />
        ) : (
          <Icon icon="truck" size="4rem" />
        )}
      </div>

      <Name>{company.fullName}</Name>
    </Wrapper>
  )
}

import { Icon, PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  FilePicker as BaseFilePicker,
  FilePickerFile,
  FormField,
  Input,
  RegularModal as BaseRegularModal,
} from 'components'
import {
  ImportProductsFileType,
  ImportProductsMutationVariables,
} from '../types.graphql'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { validateNonEmpty } from 'util/validation'
import { getFileExtension } from 'util/files'
import { RequiredColumns } from '../types'

const RegularModal = styled(BaseRegularModal)`
  border: 0rem;

  div.content {
    padding: 0 0 1rem 0;
  }

  ${props => props.theme.media.mobile} {
    div.content {
      padding: 2.5rem 0 0 0;
    }
    div.buttons {
      padding-top: 0;
    }
  }
`
const InputWrapper = styled.div`
  padding: 0 2.5rem 1rem;
`
const FilePicker = styled(BaseFilePicker)`
  margin-top: 2rem;
`
interface ConfirmedProps {
  error: boolean
}
const Confirmed = styled.div<ConfirmedProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  margin: 0 2.5rem 1rem;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1px solid
    ${props =>
      !props.error ? props.theme.colors.matteGreen : props.theme.colors.danger};

  font-weight: 600;

  span.confirm {
    margin: 0 2rem;

    text-align: center;
    color: ${props =>
      !props.error ? props.theme.colors.matteGreen : props.theme.colors.danger};
  }
  a {
    color: currentColor;
    text-decoration: none;
  }
  ${props => props.theme.media.desktop} {
    height: 300px;
  }
  ${props => props.theme.media.mobile} {
    margin: -1rem 1rem 1rem;
    padding: 3rem 0;
  }
  span.go-back {
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`

const columnNames = {
  name: 'Varetekst',
  sku: 'Varenr',
  gross_price_excluding_vat: 'Bruttopris',
  price_excluding_vat: 'Nettopris',
  unit: 'Enhet',
}

interface ImportModalProps {
  onImport: (
    variables: ImportProductsMutationVariables
  ) => Promise<boolean | RequiredColumns[]>
  onSubmit: PromptResolve<null>
}
export const ImportModal: React.FC<ImportModalProps> = ({
  onImport,
  onSubmit,
}) => {
  const translations = useTranslate({
    merchant: 'products.merchant',
    import: 'products.import-products',

    close: 'common.close',
    save: 'common.save',
    create: 'products.create-product-list',

    tryAgain: 'common.try-again',

    results: {
      productsImported: 'products.product-list-imported',

      genericError: 'errors.something-went-wrong',
      fieldsMissing: ['products.errors.fields-missing', { fields: '' }],
    },
    validation: {
      required: 'common.required',
    },
  })

  const [loading, setLoading] = useState(false)
  const [imported, setImported] = useState(false)
  const [error, setError] = useState(false)
  const [missingFields, setMissingFields] = useState<RequiredColumns[]>([])

  const missingFieldsTranslated = useMemo(
    () =>
      !missingFields.length
        ? null
        : missingFields.map(field => columnNames[field]).join(', '),
    [missingFields]
  )

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formChangeHandler: handler,
  } = useForm<{ merchant: string; file: FilePickerFile | null }>({
    values: {
      merchant: '',
      file: null,
    },
    validators: {
      merchant: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  async function handleImport() {
    if (!form.file || typeof form.file.file === 'string') return

    const ext = getFileExtension(form.file.file.name)
    const fileType =
      ext === '.csv'
        ? ImportProductsFileType.CSV
        : ext === '.xlsx'
        ? ImportProductsFileType.XLSX
        : null
    if (!fileType) return

    try {
      setLoading(true)
      const imported = await onImport({
        merchant: form.merchant,
        file: form.file.file,
        fileType,
        overwriteAllExistingFromMerchant: true,
      })

      if (Array.isArray(imported)) {
        setImported(false)
        setError(true)
        setMissingFields(imported)
      } else {
        setImported(imported)
        setError(!imported)
      }
    } catch (e) {
      setError(true)
      setImported(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <RegularModal
      title={translations.import}
      width="480px"
      overflowY="auto"
      cancelOnClickOutside
      loading={loading}
      submitText={
        !imported && !error ? translations.create : translations.close
      }
      submitDisabled={loading || !formValid}
      onClose={() => onSubmit(null)}
      onSubmit={!imported && !error ? handleImport : () => onSubmit(null)}
    >
      {!imported && !error ? (
        <InputWrapper>
          <FormField error={!!errors.merchant} required>
            <label>{translations.merchant}</label>
            <Input
              value={form.merchant}
              error={errors.merchant}
              fullWidth
              onChange={handler('merchant')}
            />
          </FormField>

          <FilePicker
            accept=".xlsx,.csv"
            showSelectedFileText
            onChange={file => !!file.new.length && handler('file')(file.new[0])}
          />
        </InputWrapper>
      ) : (
        <Confirmed error={error}>
          <span className="confirm">
            {!error
              ? translations.results.productsImported
              : !!missingFieldsTranslated
              ? translations.results.fieldsMissing({
                  fields: missingFieldsTranslated,
                })
              : translations.results.genericError}
          </span>

          <Icon
            icon={!error ? 'check-circle' : 'times-circle'}
            type="light"
            color={!error ? 'matteGreen' : 'danger'}
            size="3rem"
            margin="1rem 0 0 0"
          />

          {error && (
            <span className="go-back" onClick={() => setError(false)}>
              {translations.tryAgain}
            </span>
          )}
        </Confirmed>
      )}
    </RegularModal>
  )
}

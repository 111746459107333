import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  FormField as BaseFormField,
  Input,
  NumberInput,
  TextArea,
  QuickNumberInput,
} from 'components'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { toLocaleStringFixed } from 'util/formatting'
import { validateNonEmpty } from 'util/validation'
import {
  CreateOfferMutationVariables,
  Offer,
  PatchOfferMutationVariables,
} from '../types.graphql'

const Card = styled.div`
  width: 450px;
  margin-top: 1rem;

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    padding: 2rem 0;
    padding-bottom: 1rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    padding-bottom: 2rem;
  }
`

const FormField = styled(BaseFormField)<{ withMarginBottom?: boolean }>`
  position: relative;
  margin-bottom: ${props =>
    props.withMarginBottom ? 'calc(1rem - 10px)' : '1rem'};

  z-index: 0;

  ${props => props.theme.media.desktop} {
    padding: 0 2.75rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 0 1rem;
  }
`

const TotalHourWrapper = styled.div`
  position: relative;
  z-index: -1;
  top: -10px;

  display: flex;
  justify-content: center;

  width: 100%;
  padding: calc(1rem + 10px) 1rem 1rem;

  background-color: ${props => props.theme.colors.gray3};
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-bottom-right-radius: ${props => props.theme.sizes.defaultBorderRadius};

  color: white;
  font-weight: 600;
`

const Section = styled.div`
  display: flex;

  border-top: 1px solid ${props => props.theme.colors.gray5};
  font-weight: 600;

  ${props => props.theme.media.desktop} {
    padding: 1rem 2.75rem;
  }
  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }

  div.result {
    color: ${props => props.theme.colors.primary};
  }
`

const Button = styled(BaseButton)`
  ${props => props.theme.media.desktop} {
    margin-top: 1rem;
  }
`

export interface OfferData {
  title: string
  project: string
  description: string
  hourlyRate: number
  installationTime: number
  installationEquipmentCost: number
  contributionPercentage: number
}

interface OfferFormProps {
  offer?: Offer
  projectId: string
  defaultHourlyRate: number
  loading: boolean

  onSubmit: (
    form: CreateOfferMutationVariables | PatchOfferMutationVariables
  ) => void
}

export const OfferForm: React.FC<OfferFormProps> = ({
  offer,
  projectId,
  defaultHourlyRate,
  loading,
  onSubmit,
}) => {
  const translations = useTranslate({
    createOffer: 'offers.create',
    saveOffer: 'offers.save-and-set-status-to-created',

    contributionMargin: 'offers.contribution-margin',
    totalCost: 'offers.total-cost',
    excludingVAT: 'offers.excluding-vat',

    form: {
      title: 'common.title',
      description: 'common.description',
      hourlyRate: 'projects.hourly-rate',
      installationTime: 'offers.installation-time',
      installationEquipmentCost: 'offers.installation-equipment-cost',
      contributionPercentage: 'offers.contribution-percentage',
    },

    validation: {
      required: 'common.required',
    },
  })

  const offerInserted = useRef(false)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<OfferData>({
    values: {
      title: '',
      description: '',
      project: projectId,
      hourlyRate: defaultHourlyRate,
      installationTime: 0,
      installationEquipmentCost: 0,
      contributionPercentage: 0,
    },
    validators: {
      title: validateNonEmpty(translations.validation.required),
      description: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  useEffect(() => {
    if (!offer || offerInserted.current) return
    offerInserted.current = true

    const values: typeof form = {
      title: offer.title,
      description: offer.description ?? '',
      project: projectId ?? null,
      hourlyRate: offer.hourlyRate ?? defaultHourlyRate ?? 0,
      installationTime: offer.installationTime ?? 0,
      installationEquipmentCost: offer.installationEquipmentCost ?? 0,
      contributionPercentage: offer.contributionPercentage ?? 0,
    }

    updateForm(values)
    updateInitialValues(values)
  }, [defaultHourlyRate, offer, projectId, updateForm, updateInitialValues])

  function handleSubmit(values: typeof form) {
    if (!!offer?.id) {
      onSubmit({ input: { ...values, status: 'CREATED' }, id: offer.id })
    } else onSubmit({ input: values })
  }

  const disableSubmitButton = useMemo(
    () => loading || !formValid || !formEdited,
    [formEdited, formValid, loading]
  )

  const costOfHours = useMemo(
    () => toLocaleStringFixed(form.installationTime * (form.hourlyRate ?? 0)),
    [form.hourlyRate, form.installationTime]
  )

  const contributionMargin = useMemo(
    () =>
      toLocaleStringFixed(
        (form.contributionPercentage / 100) * form.installationEquipmentCost
      ),
    [form.contributionPercentage, form.installationEquipmentCost]
  )

  const totalCost = useMemo(
    () =>
      toLocaleStringFixed(
        form.installationTime * (form.hourlyRate ?? 0) +
          (1 + form.contributionPercentage / 100) *
            form.installationEquipmentCost
      ),
    [
      form.contributionPercentage,
      form.hourlyRate,
      form.installationEquipmentCost,
      form.installationTime,
    ]
  )

  return (
    <Card>
      <FormField error={!!errors.title} required>
        <label>{translations.form.title}</label>

        <Input
          name="title"
          value={form.title}
          error={errors.title}
          disabled={loading}
          fullWidth
          autoFocus
          onChange={handler('title')}
        />
      </FormField>

      <FormField error={!!errors.description} required>
        <label>{translations.form.description}</label>

        <TextArea
          value={form.description ?? ''}
          error={errors.description}
          disabled={loading}
          fullWidth
          height="100px"
          onChange={value => {
            updateForm({ description: value })
          }}
        />
      </FormField>

      <FormField error={!!errors.hourlyRate}>
        <label>{translations.form.hourlyRate}</label>

        <NumberInput
          name="hourlyRate"
          value={form.hourlyRate}
          error={errors.hourlyRate}
          disabled={loading}
          min={0}
          fullWidth
          onChange={handler('hourlyRate')}
        />
      </FormField>

      <FormField error={!!errors.installationTime} withMarginBottom>
        <label>{translations.form.installationTime}</label>

        <QuickNumberInput
          value={form.installationTime}
          disabled={loading}
          interval={1}
          noQuickChoices
          noMax
          fullWidth
          onChange={handler('installationTime')}
        />
        <TotalHourWrapper>{'NOK: ' + costOfHours}</TotalHourWrapper>
      </FormField>

      <FormField error={!!errors.installationEquipmentCost}>
        <label>{translations.form.installationEquipmentCost}</label>

        <NumberInput
          name="installationEquipmentCost"
          value={form.installationEquipmentCost}
          error={errors.installationEquipmentCost}
          disabled={loading}
          min={0}
          fullWidth
          onChange={handler('installationEquipmentCost')}
        />
      </FormField>

      <FormField error={!!errors.contributionPercentage}>
        <label>{translations.form.contributionPercentage}</label>

        <NumberInput
          name="contributionPercentage"
          value={form.contributionPercentage}
          error={errors.contributionPercentage}
          min={0}
          max={100}
          disabled={loading}
          fullWidth
          onChange={handler('contributionPercentage')}
        />
      </FormField>

      <Section>
        {translations.contributionMargin + ':'}&nbsp;
        <div className="result">
          {'NOK ' + contributionMargin + ' ' + translations.excludingVAT}
        </div>
      </Section>

      <Section>
        {translations.totalCost + ':'}&nbsp;
        <div className="result">
          {'NOK ' + totalCost + ' ' + translations.excludingVAT}
        </div>
      </Section>

      <Section>
        <Button
          disabled={disableSubmitButton}
          loading={loading}
          fullWidth
          onClick={submitHandler(handleSubmit)}
        >
          {offerInserted.current
            ? translations.saveOffer
            : translations.createOffer}
        </Button>
      </Section>
    </Card>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { Redirect } from 'containers/Routing'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useCompany, useOnErrorAuto, useToast } from 'util/hooks'
import { CustomerForm } from './CustomerForm'
import { PATCH_CUSTOMER_MUTATION } from './mutations'
import { CUSTOMER_EDIT_QUERY } from './queries'
import {
  CreateCustomerMutationVariables,
  CustomerEditQuery,
  PatchCustomerMutation,
  PatchCustomerMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

interface EditCustomerProps {}

export const EditCustomer: React.FC<EditCustomerProps> = () => {
  const translations = useTranslate({
    editCustomer: 'customers.edit-customer',

    results: {
      success: 'customers.edit-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const { defaultHourlyRate } = useCompany()
  const { id: customerId } = useParams<{ id: string }>()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')

  const history = useHistory()

  const { data, loading: queryLoading } = useQuery<
    CustomerEditQuery,
    IdVariable
  >(CUSTOMER_EDIT_QUERY, {
    skip: isMobileOnly,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: customerId },
    onCompleted({ customer }) {
      setOverrides(v => ({
        ...v,
        [customerId]: customer.name,
      }))
    },
    onError: onErrorAuto(translations.results.queryError),
  })

  const [patchCustomerMutation, { loading: patchLoading }] = useMutation<
    PatchCustomerMutation,
    PatchCustomerMutationVariables
  >(PATCH_CUSTOMER_MUTATION, {
    refetchQueries: ['Customers'],
    onCompleted() {
      mixpanel.track('Edit Customer')
      addToast('success', translations.results.success)
      history.push(`/customers/${customerId}`)
    },
    onError: onErrorAuto(),
  })

  function handleSubmit(form: CreateCustomerMutationVariables) {
    patchCustomerMutation({
      variables: {
        id: customerId,
        ...form,
      },
    })
  }

  if (isMobileOnly)
    return (
      <Redirect
        to={{
          pathname: '/customers',
          state: {
            edit: customerId,
          },
        }}
      />
    )

  const loading = patchLoading || queryLoading

  return (
    <Wrapper>
      <PageHeader title={translations.editCustomer} loading={loading} />
      <CustomerForm
        customer={data?.customer}
        loading={loading}
        defaultHourlyRate={defaultHourlyRate}
        onSubmit={handleSubmit}
      />
    </Wrapper>
  )
}

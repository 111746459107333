import React from 'react'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledHeader = styled.h1`
  padding-top: 1rem;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.gray1};
`

export const NoCompanies: React.FC = () => {
  const translations = useTranslate({
    affiliated: 'companies.affiliated-not',
  })

  return (
    <Layout>
      <StyledHeader>{translations.affiliated}</StyledHeader>
    </Layout>
  )
}

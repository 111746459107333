import { useMutation } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { FormField, TableMenu, TextArea } from 'components'
import { Menu } from 'components/Table/TableMenu'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useDateFns, useOnErrorAuto } from 'util/hooks'
import {
  CREATE_CHECKLIST_ITEM_COMMENT_MUTATION,
  DELETE_CHECKLIST_ITEM_COMMENT_MUTATION,
  PATCH_CHECKLIST_ITEM_COMMENT_MUTATION,
} from '../mutations'
import {
  ChecklistItemComment,
  CreateChecklistItemCommentMutation,
  CreateChecklistItemCommentMutationVariables,
  DeleteChecklistItemCommentMutation,
  PatchChecklistItemCommentMutation,
  PatchChecklistItemCommentMutationVariables,
  ShallowChecklistItem,
} from '../types.graphql'

const Wrapper = styled(FormField)`
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 3rem 1rem;
  }

  div.no-comments {
    margin: 0 3rem 1rem;
    color: ${props => props.theme.colors.gray3};
    font-style: italic;
  }

  ${props => props.theme.media.mobile} {
    label {
      margin: 0 0.5rem 0.5rem;
    }
    div.no-comments {
      margin: 0 0.5rem 1rem;
    }
  }
`
const Comments = styled.div`
  max-height: 35vh;
  overflow-y: auto;

  ${props => props.theme.media.mobile} {
    max-height: 100vh;
  }
`
const Comment = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;

  header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: 40px;

    border: solid ${props => props.theme.colors.gray6};
    border-width: 1px 0 1px 0;

    div.posted-at {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 0.5rem 0 3rem;

      color: ${props => props.theme.colors.gray3};
      font-weight: 600;

      border-right: 1px solid ${props => props.theme.colors.gray6};
    }
    div.posted-by {
      padding-left: 0.5rem;

      a {
        cursor: pointer;
        color: ${props => props.theme.colors.gray1};

        &:hover {
          color: ${props => props.theme.colors.primaryHover};
        }
      }
    }
    div.menu {
      padding-right: 3rem;
    }
  }
  div.content {
    margin: 1rem 3rem;

    p {
      margin: 0;
    }
  }

  ${props => props.theme.media.mobile} {
    header {
      div.posted-at {
        padding: 0 0.5rem;
      }
      div.menu {
        padding-right: 0.5rem;
      }
    }
    div.content {
      margin: 0.75rem 0.5rem;
    }
  }
`
const AddComment = styled.div`
  padding: 1rem 3rem 0;
  border-top: 1px solid ${props => props.theme.colors.gray6};

  ${props => props.theme.media.mobile} {
    padding: 0.75rem 0.5rem 0;
  }
`

interface ChecklistItemCommentsProps {
  item: ShallowChecklistItem
}

export const ChecklistItemComments: React.FC<ChecklistItemCommentsProps> = ({
  item,
}) => {
  const translations = useTranslate({
    comments: 'common.comments',
    noComments: 'common.no-comments',
    addComment: 'common.add-a-comment',

    cancel: 'common.cancel',
    edit: 'common.edit',
    delete: 'common.delete',
  })

  const didFocusTextArea = useRef<string | null>(null)

  const onErrorAuto = useOnErrorAuto()
  const { format } = useDateFns()

  const [updateComment, setUpdateComment] =
    useState<ChecklistItemComment | null>(null)
  const [newComment, setNewComment] = useState<string>('')

  const comments = useMemo(
    () => item.comments.edges.map(edge => edge.node),
    [item]
  )

  const [createComment, { loading: createLoading }] = useMutation<
    CreateChecklistItemCommentMutation,
    CreateChecklistItemCommentMutationVariables
  >(CREATE_CHECKLIST_ITEM_COMMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onError: onErrorAuto(),
  })

  const [patchComment, { loading: patchLoading }] = useMutation<
    PatchChecklistItemCommentMutation,
    PatchChecklistItemCommentMutationVariables
  >(PATCH_CHECKLIST_ITEM_COMMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onError: onErrorAuto(),
  })

  const [deleteComment, { loading: deleteLoading }] = useMutation<
    DeleteChecklistItemCommentMutation,
    IdVariable
  >(DELETE_CHECKLIST_ITEM_COMMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Checklist', 'ChecklistItem'],
    onError: onErrorAuto(),
  })

  const handleCreateComment = useCallback(
    (value: string) =>
      createComment({
        variables: {
          input: {
            checklistItem: item.id,
            content: value.trim(),
          },
        },
      }).then(() => setNewComment('')),
    [createComment, item.id]
  )

  const handlePatchComment = useCallback(
    (value: string) =>
      !!updateComment &&
      patchComment({
        variables: {
          id: updateComment.id,
          input: {
            content: value.trim(),
          },
        },
      }).then(() => {
        setUpdateComment(null)
        setNewComment('')
      }),
    [patchComment, updateComment]
  )

  const handleDeleteComment = useCallback(
    (comment: ChecklistItemComment) =>
      deleteComment({
        variables: {
          id: comment.id,
        },
      }),
    [deleteComment]
  )

  const handleCancel = useCallback(() => {
    setUpdateComment(null)
    setNewComment('')
    didFocusTextArea.current = null
  }, [])

  function focusTextArea(comment: ChecklistItemComment) {
    return (el: HTMLTextAreaElement | null) => {
      if (!el || didFocusTextArea.current === comment.id) return
      didFocusTextArea.current = comment.id

      el.selectionStart = comment.content.length
    }
  }

  const createMenu = useCallback(
    (comment: ChecklistItemComment) => {
      const menu: TableMenu = {
        items: [
          {
            icon: updateComment?.id === comment.id ? 'times' : 'edit',
            text:
              updateComment?.id === comment.id
                ? translations.cancel
                : translations.edit,
            onClick() {
              if (updateComment?.id === comment.id) handleCancel()
              else {
                setNewComment(comment.content)
                setUpdateComment(comment)
              }
            },
          },
        ],
      }

      if (updateComment?.id !== comment.id)
        menu.items.push({
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hoverColor: '#e38080',
          onClick: () => handleDeleteComment(comment),
        })

      return menu
    },
    [
      updateComment?.id,
      translations.cancel,
      translations.edit,
      translations.delete,
      handleCancel,
      handleDeleteComment,
    ]
  )

  const isLoading = createLoading || patchLoading || deleteLoading

  return (
    <Wrapper>
      <label>
        {translations.comments}

        {isLoading && <Loader.Spinner size={14} thickness={2} />}
      </label>

      {!!comments.length ? (
        <Comments>
          {comments.map(comment => (
            <Comment key={comment.id}>
              <header>
                <div className="posted-at">
                  {format(new Date(comment.postedAt), 'PPp')}
                </div>

                <div className="posted-by">
                  <Link to={`/users/${comment.postedBy.id}`}>
                    {comment.postedBy.fullName}
                  </Link>
                </div>

                <div className="menu">
                  <Menu icon="ellipsis-h" config={createMenu(comment)} />
                </div>
              </header>

              <div className="content">
                {updateComment?.id === comment.id ? (
                  <TextArea
                    ref={focusTextArea(comment)}
                    value={newComment}
                    height="5rem"
                    placeholder={comment.content}
                    fullWidth
                    autoFocus
                    submitConfig={{
                      hide: !newComment,
                    }}
                    onKeyDown={evt => evt.key === 'Escape' && handleCancel()}
                    onSubmit={handlePatchComment}
                    onChange={setNewComment}
                  />
                ) : (
                  <p className="content">{comment.content}</p>
                )}
              </div>
            </Comment>
          ))}
        </Comments>
      ) : (
        <div className="no-comments">{translations.noComments}</div>
      )}

      {!updateComment && (
        <AddComment>
          <TextArea
            value={newComment}
            placeholder={translations.addComment}
            fullWidth
            height="5rem"
            submitConfig={{
              hide: !newComment,
            }}
            onKeyDown={evt => evt.key === 'Escape' && handleCancel()}
            onSubmit={handleCreateComment}
            onChange={setNewComment}
          />
        </AddComment>
      )}
    </Wrapper>
  )
}

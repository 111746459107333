import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie'
import { useTranslate } from '@ur/react-hooks'
import {
  ProjectDashboardData,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { theme } from 'theme'
import { getWorkingHours } from 'util/holidays'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { getOvertime, useTimeFilterOptions } from '../utils'
import {
  Card,
  BarWrapper,
  FooterButton,
  FooterSelect,
  CardHeader,
} from './components'
import { DashboardCardProps } from './types'
import mixpanel from 'mixpanel-browser'

interface LayerProps
  extends PieCustomLayerProps<{
    id: string
    label: string
    value: number | undefined
    color: string
  }> {
  timeFilter: TimeFilterType
  totalHoursWorkedInTimePeriod: number
  totalExpectedHoursInTimePeriod: number
}

const Layer: React.FC<LayerProps> = ({
  centerX,
  centerY,
  timeFilter,
  totalHoursWorkedInTimePeriod,
  totalExpectedHoursInTimePeriod,
}) => {
  const translations = useTranslate({
    nHours: ['common.n-hours', { n: 0 }],
  })

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '14px',
        fontWeight: '600',
        fill: theme.colors.gray3,
      }}
    >
      {timeFilter === 'ALL'
        ? translations.nHours({ n: totalHoursWorkedInTimePeriod })
        : translations.nHours({
            n: totalHoursWorkedInTimePeriod,
          }) +
          '/' +
          totalExpectedHoursInTimePeriod}
    </text>
  )
}

export const HoursCard: React.FC<DashboardCardProps<ProjectDashboardData>> = ({
  data,
  loading,
  timeFilter,
  onUpdateTimeFilter,
}) => {
  const translations = useTranslate({
    registerHours: 'dashboard.registerHours',

    projects: 'common.projects',
    deviations: 'common.deviations',
    offers: 'common.offers',
    checklists: 'common.checklists',
  })

  const canCreateTimeEntry = usePermission(PERMISSIONS.timesheets.add.timeentry)

  const history = useHistory()
  const timeFilterOptions = useTimeFilterOptions()

  const { totalExpectedHoursInTimePeriod, overtime, expectedHours } =
    useMemo(() => {
      const totalExpectedHoursInTimePeriod = getWorkingHours(timeFilter)

      const overtime = getOvertime(
        totalExpectedHoursInTimePeriod,
        data.totalHoursWorkedInTimePeriod
      )

      const expectedHours =
        totalExpectedHoursInTimePeriod - data.totalHoursWorkedInTimePeriod

      return {
        totalExpectedHoursInTimePeriod,
        overtime,
        expectedHours,
      }
    }, [data.totalHoursWorkedInTimePeriod, timeFilter])

  const pieData = useMemo(() => {
    const ret = [
      {
        id: 'workedHours',
        label: 'worked hours',
        value: data.totalHoursWorkedInTimePeriod,
        color: theme.colors.matteGreen2,
      },
    ]

    if (expectedHours > 0 && timeFilter !== 'ALL') {
      ret.push({
        id: 'expectedHours',
        label: 'expected hours',
        value: expectedHours,
        color: 'purple',
      })
    }

    if (overtime > 0) {
      ret.unshift({
        id: 'overtime',
        label: 'overtime',
        value: overtime,
        color: 'red',
      })
    }

    return ret
  }, [data.totalHoursWorkedInTimePeriod, expectedHours, overtime, timeFilter])

  return (
    <Card hasFooterButton={canCreateTimeEntry}>
      <CardHeader text={translations.registerHours} loading={loading} />

      <BarWrapper>
        <ResponsivePie
          data={pieData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          innerRadius={0.75}
          padAngle={0}
          cornerRadius={0}
          colors={[
            overtime ? theme.colors.matteOrange : theme.colors.matteGreen2,
            overtime ? theme.colors.matteGreen2 : theme.colors.gray5,
          ]}
          borderWidth={0}
          arcLabelsSkipAngle={10}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          layers={[
            'arcs',
            props => (
              <Layer
                {...props}
                timeFilter={timeFilter}
                totalHoursWorkedInTimePeriod={data.totalHoursWorkedInTimePeriod}
                totalExpectedHoursInTimePeriod={totalExpectedHoursInTimePeriod}
              />
            ),
          ]}
        />
      </BarWrapper>

      <footer>
        <FooterSelect
          value={timeFilter}
          options={timeFilterOptions}
          borderBottomRightRadius="0px"
          borderTopRightRadius="0px"
          borderTopLeftRadius="0px"
          height="49px"
          border="none"
          fullWidth
          onChange={value =>
            !!value && onUpdateTimeFilter(value as TimeFilterType)
          }
        />

        {canCreateTimeEntry && (
          <FooterButton
            role="link"
            width="100%"
            height="100%"
            iconRightProps={{ icon: 'plus' }}
            background="primary"
            onClick={() => {
              mixpanel.track('Create Time Entry From Dashboard')
              history.push({ pathname: '/timesheets', state: { create: true } })
            }}
          >
            {translations.registerHours}
          </FooterButton>
        )}
      </footer>
    </Card>
  )
}

import { useCallback, useMemo } from 'react'
import { UserNode } from 'modules/users'
import { useUser } from './useUser'
import { useAdmin } from './useAdmin'

export function usePermissions(overrideAllowAdmin?: boolean) {
  const me = useUser()
  const admin = useAdmin()

  const getAllowAdmin = useCallback(
    (allow: boolean) =>
      typeof overrideAllowAdmin === 'undefined' ? allow : overrideAllowAdmin,
    [overrideAllowAdmin]
  )

  const hasPermissions = useCallback(
    (permissions: string | string[], allowAdmin: boolean = false) =>
      me.isSuperuser ||
      (getAllowAdmin(allowAdmin) && admin) ||
      ([] as string[])
        .concat(permissions)
        .every(perm => me.permissions.includes(perm)),
    [me.isSuperuser, me.permissions, getAllowAdmin, admin]
  )

  const hasPermissionsOr = useCallback(
    (permissions: string | string[], allowAdmin: boolean = false) =>
      me.isSuperuser ||
      (getAllowAdmin(allowAdmin) && admin) ||
      ([] as string[])
        .concat(permissions)
        .some(perm => me.permissions.includes(perm)),
    [admin, getAllowAdmin, me.isSuperuser, me.permissions]
  )

  const hasPermissionsOrMe = useCallback(
    (user: Pick<UserNode, 'id'>, ...permissions: string[]) =>
      me.isSuperuser ||
      (admin && getAllowAdmin(false)) ||
      hasPermissions(permissions) ||
      user.id === me.id,
    [admin, getAllowAdmin, hasPermissions, me.id, me.isSuperuser]
  )

  const hasPermissionsAndMe = useCallback(
    (user: Pick<UserNode, 'id'>, ...permissions: string[]) =>
      me.isSuperuser ||
      (admin && getAllowAdmin(false)) ||
      (hasPermissions(permissions) && user.id === me.id),
    [admin, getAllowAdmin, hasPermissions, me.id, me.isSuperuser]
  )

  const perms = useMemo(
    () => ({
      permissions: me.permissions,
      hasPermissions,
      hasPermissionsOr,
      hasPermissionsOrMe,
      hasPermissionsAndMe,
    }),
    [
      hasPermissions,
      hasPermissionsOr,
      hasPermissionsOrMe,
      hasPermissionsAndMe,
      me.permissions,
    ]
  )

  return perms
}

import { Icon, Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Card } from 'components'
import capitalize from 'lodash/capitalize'
import { Permission, UserType } from 'modules/users'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { PermissionsLoading, PermissionStatus } from './types'
import { useSpecialPermissions } from './util'

const Wrapper = styled(Card)`
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;

  h2 {
    margin: 0;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.gray2};
  }

  ${props => props.theme.media.mobile} {
    border-radius: 0;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;

  margin-top: 1rem;
`

interface SpecialUserTypePermissionsProps {
  userType: UserType
  allPermissions: Permission[]
  permissionsLoading: PermissionsLoading

  onTogglePermission: (
    permission: Pick<PermissionStatus, 'id' | 'checked'>
  ) => void
}

export const SpecialUserTypePermissions: React.FC<
  SpecialUserTypePermissionsProps
> = ({ userType, allPermissions, permissionsLoading, onTogglePermission }) => {
  const translations = useTranslate({
    header: 'users.special-permissions',
  })
  const intl = useIntl()

  function getTranslation(fullName: string) {
    return intl.formatMessage({
      id: `users.special-permissions.${fullName}`,
      defaultMessage: capitalize(
        fullName.split('.').slice(-1)[0].replace(/_/, ' ')
      ),
    })
  }

  const permissions = useSpecialPermissions(userType, allPermissions)

  return (
    <Wrapper>
      <h2>{translations.header}</h2>

      <Grid>
        {permissions.map(permission => (
          <React.Fragment key={permission.id}>
            <span>{getTranslation(permission.fullName)}</span>

            <div>
              {permissionsLoading[permission.id] ? (
                <Loader.Spinner size={24} thickness={4} />
              ) : (
                <Icon
                  icon="check-circle"
                  type="solid"
                  size="24px"
                  color={permission.checked ? 'matteGreen' : 'gray4'}
                  cursor="pointer"
                  onClick={() => onTogglePermission(permission)}
                />
              )}
            </div>
          </React.Fragment>
        ))}
      </Grid>
    </Wrapper>
  )
}

import client from 'apollo'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { removeLoginToken } from 'util/auth'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-size: 4rem;
  font-weight: 600;
  text-transform: uppercase;
`

export const Logout: React.FC = () => {
  removeLoginToken()

  useEffect(() => {
    mixpanel.track('Logout')
    client.clearStore().then(() => (window.location.href = '/'))
  }, [])

  return <Wrapper>Goodbye</Wrapper>
}

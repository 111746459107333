import { IconProps } from '@ur/react-components'

export interface HeaderTab {
  href: string
  text: string
}

export interface HeaderStore {
  tabs: HeaderTab[]
  icon: IconProps | null
}

export const headerStore: HeaderStore = {
  tabs: [],
  icon: null,
}

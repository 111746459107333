import { ComponentSwitch as Switch } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ChangePasswordMutation, PatchUserMutation, ShallowUser } from '.'
import { ChangeInformation, ChangeUserInfoForm } from './ChangeInformation'
import { ChangePassword, ChangePasswordForm } from './ChangePassword'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto;
  gap: 2rem;

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;

    & > div + div {
      margin-top: 1rem;
    }
  }
`
const Tabs = styled.nav`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  font-weight: 600;
  color: ${props => props.theme.colors.gray3};
  background-color: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`
interface TabProps {
  active: boolean
}
const Tab = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  padding: 1.2rem 0;

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.primary};
      background-color: white;
    `};
`

type EditUserTab = 'info' | 'password'

interface EditUserMobileProps {
  user?: ShallowUser
  editLoading: boolean
  passwordLoading: boolean

  onEditSubmit: (
    data: Partial<ChangeUserInfoForm>
  ) => Promise<PatchUserMutation | null>
  onChangePasswordSubmit: (
    data: ChangePasswordForm
  ) => Promise<ChangePasswordMutation | null>
}

export const EditUserMobile: React.FC<EditUserMobileProps> = ({
  user,
  editLoading,
  passwordLoading,

  onEditSubmit,
  onChangePasswordSubmit,
}) => {
  const translations = useTranslate({
    info: 'common.info',
    password: 'users.password',
    editName: ['users.edit-name', { firstName: '' }],
  })

  const history = useHistory<{ tab?: string }>()
  const startAtPassword = history.location.state?.tab === 'password'

  const [tab, setTab] = useState<EditUserTab>(
    startAtPassword ? 'password' : 'info'
  )
  const [overrides, setOverrides] = useGlobal('breadcrumbs.overrides')

  function updateTab(tab: EditUserTab) {
    history.replace({
      ...history.location,
      state: { tab },
    })
  }

  useEffect(() => {
    const unlisten = history.listen(({ state }) => {
      if (!state) return
      if (
        (state.tab === 'info' || state.tab === 'password') &&
        state.tab !== tab
      )
        setTab(state.tab)
    })

    return unlisten
  }, [history, tab])

  useEffect(() => {
    if (!user?.firstName) return

    const override = translations.editName({ firstName: user.firstName })
    if (overrides['edit'] === override) return

    setOverrides(v => ({
      ...v,
      edit: override,
    }))
  }, [overrides, setOverrides, translations, user])

  return (
    <Wrapper>
      <Tabs>
        <Tab active={tab === 'info'} onClick={() => updateTab('info')}>
          {translations.info}
        </Tab>

        <Tab active={tab === 'password'} onClick={() => updateTab('password')}>
          {translations.password}
        </Tab>
      </Tabs>

      <Switch>
        <Switch.Case case={tab === 'info'}>
          <ChangeInformation
            user={user}
            loading={editLoading}
            onSubmit={onEditSubmit}
          />
        </Switch.Case>

        <Switch.Case case={tab === 'password'}>
          <ChangePassword
            loading={passwordLoading}
            onSubmit={onChangePasswordSubmit}
          />
        </Switch.Case>
      </Switch>
    </Wrapper>
  )
}

import { Icon, IconProps, Loader } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import {
  Button,
  IconButton,
  Input,
  UserTypeSelect,
  UserTypeSelectProps,
} from 'components'
import React, { AriaRole, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { usePermissions } from 'util/hooks'

interface WrapperProps {
  largeMargin: boolean
  tabMargin: boolean
  noBottomMargin: boolean
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  margin-bottom: ${props => (props.largeMargin ? ' 1rem' : '0.5rem')};
  margin: ${props => props.tabMargin && '0 1rem 1rem'};
  padding: ${props =>
    props.tabMargin ? '0' : props.noBottomMargin ? '1rem 0 0' : '1rem 0'};

  h1 {
    display: flex;
    gap: 0.6rem;

    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${props => props.theme.colors.gray1};
    margin-left: 0.2rem;

    i {
      margin-left: 0.5rem;
    }
  }

  div.left {
    display: flex;
    gap: 1rem;

    i.edit {
      font-size: 1.2rem;
    }
    div.loader {
      align-self: center;
    }
  }
  div.right {
    display: flex;
    gap: 2rem;

    div.search {
      input {
        border-right-width: 0px;
      }
      .--input-icon-right {
        transition: border-color 0.1s linear;
      }
      &:focus-within {
        .--input-icon-right {
          border-color: ${props => props.theme.colors.primary};
        }
      }
    }
    div.user-type-select {
      display: flex;
      flex-direction: row;
    }
  }
`
const Buttons = styled.div`
  display: flex;
  gap: 1rem;

  button {
    white-space: nowrap;
  }
`
const IconButtons = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    position: relative;
  }
`
const AddUserType = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => props.theme.colors.gray7};
  width: 40px;

  border: 1px solid ${props => props.theme.colors.gray5};
  border-left: 0px;
  border-radius: 0 8px 8px 0;

  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`

interface SearchOptions {
  value: string
  placeholder: string
  left?: boolean

  onChange: (value: string, evt?: React.ChangeEvent<HTMLInputElement>) => void
}

export type PageHeaderUserSelectOptions = Pick<
  UserTypeSelectProps,
  'value' | 'onChange'
>

export interface PageHeaderButton {
  text: string
  icon?: FontAwesomeIcon | IconProps
  background?: string
  role?: AriaRole
  side?: 'left' | 'right'

  hide?: boolean
  /** Permissions needed to show this button, admins always allowed */
  permissions?: string | string[]
  disabled?: boolean
  loading?: boolean

  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void
}

export interface PageHeaderIconButton {
  side: 'left' | 'right'
  icon: FontAwesomeIcon | IconProps
  text?: string
  children?: JSX.Element

  href?: string
  role?: AriaRole
  mirror?: boolean

  hide?: boolean
  /** Permissions needed to show this button, admins always allowed */
  permissions?: string | string[]

  onClick?: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

interface PageHeaderProps {
  className?: string

  title?: string | JSX.Element
  loading?: boolean
  search?: SearchOptions
  userTypeSelect?: PageHeaderUserSelectOptions
  /**
   * Required permissions to show edit button. Does nothing if onEdit is undefined.
   * Admins always allowed.
   */
  editPermissions?: string | string[]

  buttons?: PageHeaderButton[]
  iconButtons?: PageHeaderIconButton[]

  largeMargin?: boolean
  tabMargin?: boolean
  noBottomMargin?: boolean

  onEdit?: () => void
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  className,

  title,
  loading = false,
  search,
  userTypeSelect,
  editPermissions,

  buttons = [],
  iconButtons = [],

  largeMargin = false,
  tabMargin = false,
  noBottomMargin = false,

  onEdit,
}) => {
  const history = useHistory()

  const { hasPermissions } = usePermissions()

  const hasSearch = typeof search !== 'undefined'
  const hasSearchLeft = hasSearch && search.left
  const hasSearchRight = hasSearch && !search.left
  const hasButtons = useMemo(
    () => typeof buttons !== 'undefined' && buttons.some(btn => !btn.hide),
    [buttons]
  )

  const showEdit = useMemo(
    () =>
      !onEdit
        ? false
        : !editPermissions?.length
        ? true
        : hasPermissions(editPermissions, true),
    [editPermissions, hasPermissions, onEdit]
  )

  const [iconButtonsLeft, iconButtonsRight] = useMemo<
    [PageHeaderIconButton[], PageHeaderIconButton[]]
  >(
    () =>
      iconButtons.reduce<[PageHeaderIconButton[], PageHeaderIconButton[]]>(
        (acc, cur) => {
          if (
            !!cur.hide ||
            (!!cur.permissions && !hasPermissions(cur.permissions, true))
          )
            return acc

          acc[cur.side === 'left' ? 0 : 1].push(cur)
          return acc
        },
        [[], []]
      ),
    [hasPermissions, iconButtons]
  )

  const [buttonsLeft, buttonsRight] = useMemo<
    [PageHeaderButton[], PageHeaderButton[]]
  >(
    () =>
      buttons.reduce<[PageHeaderButton[], PageHeaderButton[]]>(
        (acc, cur) => {
          if (
            !!cur.hide ||
            (!!cur.permissions && !hasPermissions(cur.permissions, true))
          )
            return acc

          acc[cur.side === 'left' ? 0 : 1].push(cur)
          return acc
        },
        [[], []]
      ),
    [hasPermissions, buttons]
  )

  return (
    <Wrapper
      className={className}
      largeMargin={largeMargin}
      tabMargin={tabMargin}
      noBottomMargin={noBottomMargin}
    >
      <div className="left">
        {!!title && <h1>{title}</h1>}

        {!!onEdit && showEdit && (
          <Icon
            className="edit"
            icon="edit"
            type="solid"
            color="gray3"
            translateY="1px"
            hoverColor="primaryHover"
            cursor="pointer"
            onClick={onEdit}
          />
        )}

        {hasSearchLeft && (
          <div className="search">
            <Input
              height="40px"
              iconRightProps={{ icon: 'search', color: 'gray4' }}
              {...search}
            />
          </div>
        )}

        {!!buttonsLeft.length && (
          <Buttons>
            {buttonsLeft.map(btn => {
              const icon: IconProps | undefined =
                typeof btn.icon === 'string'
                  ? {
                      icon: btn.icon,
                    }
                  : typeof btn.icon === 'object'
                  ? btn.icon
                  : undefined

              return (
                <Button
                  key={btn.text}
                  role={btn.role}
                  iconRightProps={icon}
                  disabled={btn.disabled || btn.loading}
                  loading={btn.loading}
                  background={btn.background ?? 'primary'}
                  height="40px"
                  onClick={btn.onClick}
                >
                  {btn.text}
                </Button>
              )
            })}
          </Buttons>
        )}

        {!!iconButtonsLeft.length && (
          <IconButtons>
            {iconButtonsLeft.map(btn => (
              <div key={btn.side + btn.icon}>
                <IconButton {...btn}>{btn.text}</IconButton>

                {btn.children}
              </div>
            ))}
          </IconButtons>
        )}

        {loading && (
          <div className="loader">
            <Loader.Spinner size={24} />
          </div>
        )}
      </div>

      {(hasSearchRight || hasButtons || !!iconButtonsRight.length) && (
        <div className="right">
          {!!iconButtonsRight.length && (
            <IconButtons>
              {iconButtonsRight.map(btn => (
                <div key={btn.side + btn.icon}>
                  <IconButton {...btn}>{btn.text}</IconButton>

                  {btn.children}
                </div>
              ))}
            </IconButtons>
          )}

          {typeof userTypeSelect !== 'undefined' && (
            <div className="user-type-select">
              <UserTypeSelect
                height="40px"
                borderTopRightRadius="0"
                borderBottomRightRadius="0"
                {...userTypeSelect}
              />

              <AddUserType
                icon="pencil-alt"
                size="14px"
                type="solid"
                color="gray3"
                onClick={() =>
                  history.push(
                    `/settings/company/user-types/${userTypeSelect.value}/edit`
                  )
                }
              />
            </div>
          )}

          {hasSearchRight && (
            <div className="search">
              <Input
                height="40px"
                iconRightProps={{ icon: 'search', color: 'gray4' }}
                {...search}
              />
            </div>
          )}

          {!!buttonsRight.length && (
            <Buttons>
              {buttonsRight.map(btn => {
                const icon: IconProps | undefined =
                  typeof btn.icon === 'string'
                    ? {
                        icon: btn.icon,
                      }
                    : typeof btn.icon === 'object'
                    ? btn.icon
                    : undefined

                return (
                  <Button
                    key={btn.text}
                    role={btn.role}
                    iconRightProps={icon}
                    disabled={btn.disabled || btn.loading}
                    loading={btn.loading}
                    background={btn.background ?? 'primary'}
                    height="40px"
                    onClick={btn.onClick}
                  >
                    {btn.text}
                  </Button>
                )
              })}
            </Buttons>
          )}
        </div>
      )}
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  ColorPicker,
  FormField,
  Input,
  Message,
  PageHeader,
} from 'components'
import { DeviationCategories } from 'modules/deviations/components'
import {
  CREATE_DEVIATION_CATEGORY_MUTATION,
  DELETE_DEVIATION_CATEGORY_MUTATION,
  PATCH_DEVIATION_CATEGORY_MUTATION,
} from 'modules/deviations/mutations'
import { ALL_CATEGORIES_QUERY } from 'modules/deviations/queries'
import {
  AllCategoriesQuery,
  CreateDeviationCategoriesMutationVariables,
  CreateDeviationCategoryMutation,
  DeleteDeviationCategoryMutation,
  PatchDeviationCategoriesMutationVariables,
  PatchDeviationCategoryMutation,
  ShallowDeviationCategory,
} from 'modules/deviations/types.graphql'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useConfirm, useOnErrorAuto, useToast } from 'util/hooks'
import { randomHexColor } from 'util/style'
import { validateColor, validateNonEmpty } from 'util/validation'
import { Card } from './settings/CompanySettings'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`
const FormWrapper = styled.div`
  margin-top: 1rem;
`
const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 0.5rem;
  margin-top: 1rem;
`

export const CompanySettingsDeviationCategories: React.FC = () => {
  const translations = useTranslate({
    categories: 'deviations.deviation-categories',

    add: 'deviations.add-deviation-category',
    cancel: 'common.cancel',
    delete: 'common.delete',
    edit: 'common.edit',
    save: 'common.save',

    title: 'common.title',
    color: 'common.color',

    nCategories: ['deviations.n-categories', { n: 0 }],

    loading: 'common.loading',

    prompt: {
      deleteCategoryTitle: 'deviations.delete-category-title',
      deleteCategory: 'deviations.delete-category-prompt',
    },

    results: {
      createSuccess: 'deviations.toasts.create-category-success',
      deleteSuccess: 'deviations.toasts.delete-category-success',
      patchSuccess: 'deviations.toasts.edit-category-success',
      queryError: 'server.general-error-try-again-later',
    },

    validation: {
      invalidColor: 'errors.invalid-color',
      required: 'common.required',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    enableValidation,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
    updateForm,
  } = useForm<{ title: string; color: string }>({
    values: {
      title: '',
      color: randomHexColor(),
    },
    validators: {
      title: validateNonEmpty(translations.validation.required),
      color: [
        validateNonEmpty(translations.validation.required),
        validateColor(translations.validation.invalidColor),
      ],
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['title'],
    },
  })

  const [category, setCategory] = useState<ShallowDeviationCategory | null>(
    null
  )
  const [addEditCategory, setAddEditCategory] = useState<
    'add' | ShallowDeviationCategory | null
  >(null)

  const {
    data,
    loading: dataLoading,
    error,
  } = useQuery<AllCategoriesQuery>(ALL_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const categories = useMemo(
    () => data?.allDeviationCategories.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const disabledCategories = useMemo(
    () =>
      addEditCategory === null
        ? []
        : addEditCategory === 'add'
        ? categories
        : categories.filter(cat => cat.id !== addEditCategory.id),
    [addEditCategory, categories]
  )

  const [createDeviationCategory, { loading: createLoading }] = useMutation<
    CreateDeviationCategoryMutation,
    CreateDeviationCategoriesMutationVariables
  >(CREATE_DEVIATION_CATEGORY_MUTATION, {
    refetchQueries: ['AllCategoriesQuery'],
    onCompleted() {
      addToast('success', translations.results.createSuccess)
    },
    onError: onErrorAuto(),
  })

  const [patchDeviationCategory, { loading: patchLoading }] = useMutation<
    PatchDeviationCategoryMutation,
    PatchDeviationCategoriesMutationVariables
  >(PATCH_DEVIATION_CATEGORY_MUTATION, {
    refetchQueries: ['AllCategoriesQuery'],
    onCompleted() {
      addToast('success', translations.results.patchSuccess)
    },
    onError: onErrorAuto(),
  })

  const [deleteDeviationCategory, { loading: deleteLoading }] = useMutation<
    DeleteDeviationCategoryMutation,
    IdVariable
  >(DELETE_DEVIATION_CATEGORY_MUTATION, {
    refetchQueries: ['AllCategoriesQuery'],
    onCompleted() {
      addToast('success', translations.results.deleteSuccess)
    },
    onError: onErrorAuto(),
  })

  function handleSave(values: typeof form) {
    if (!formValid) return

    if (addEditCategory === 'add')
      createDeviationCategory({ variables: { input: { ...values } } })
    else if (!category) return
    else
      patchDeviationCategory({
        variables: { id: category.id, input: { ...values } },
      })

    handleCancel()
  }

  function handleCancel() {
    setAddEditCategory(null)
    setCategory(null)

    const values = {
      title: '',
      color: randomHexColor(),
    }
    updateForm(values)
    updateInitialValues(values)
    enableValidation('title', true, false)
  }

  function handleEditCategory(category: ShallowDeviationCategory) {
    setAddEditCategory(category)

    const values = {
      title: category.title,
      color: category.color,
    }
    updateForm(values)
    updateInitialValues(values)
  }

  const handleDeleteCategory = useCallback(async () => {
    if (!category) return
    const answer = await confirm(
      translations.prompt.deleteCategory,
      translations.prompt.deleteCategoryTitle,
      {
        variant: 'delete',
      }
    )

    if (answer) {
      deleteDeviationCategory({
        variables: {
          id: category.id,
        },
      })
    }
  }, [
    category,
    confirm,
    deleteDeviationCategory,
    translations.prompt.deleteCategory,
    translations.prompt.deleteCategoryTitle,
  ])

  const loading = patchLoading || dataLoading || createLoading || deleteLoading

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader title={translations.categories} loading={loading} />
      )}

      <Card>
        <header>
          {translations.nCategories({
            n: data?.allDeviationCategories.edges.length ?? 0,
          })}
        </header>

        <DeviationCategories
          categories={categories}
          selectedCategory={category?.id ?? null}
          disabledCategories={disabledCategories}
          big
          onCategoryClick={cat =>
            !addEditCategory && setCategory(cat === category ? null : cat)
          }
        />

        {addEditCategory && (
          <FormWrapper>
            <FormField required error={!!errors.title}>
              <label>{translations.title}</label>

              <Input
                value={form.title}
                error={errors.title}
                fullWidth
                onBlur={() => enableValidation('title')}
                onChange={handler('title')}
              />
            </FormField>

            <FormField required error={!!errors.color}>
              <label>{translations.color}</label>

              <ColorPicker
                value={form.color}
                error={errors.color}
                fullWidth
                onChange={handler('color')}
              />
            </FormField>
          </FormWrapper>
        )}

        <Buttons>
          <Button
            variant="cancel"
            disabled={!addEditCategory && !category}
            onClick={() =>
              addEditCategory || !category
                ? handleCancel()
                : handleEditCategory(category)
            }
          >
            {addEditCategory ? translations.cancel : translations.edit}
          </Button>

          <Button
            disabled={!!addEditCategory && (!formValid || !formEdited)}
            background={category && !addEditCategory ? 'red' : undefined}
            onClick={() =>
              addEditCategory
                ? submit(handleSave)()
                : category
                ? handleDeleteCategory()
                : setAddEditCategory('add')
            }
          >
            {addEditCategory
              ? translations.save
              : category
              ? translations.delete
              : translations.add}
          </Button>
        </Buttons>
      </Card>
    </Wrapper>
  )
}

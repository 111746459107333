import React from 'react'
import styled from 'styled-components'
import { Icon, IconProps } from '@ur/react-components'
import { isMobileOnly } from 'react-device-detect'

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface OrderArrowsProps {
  isFirst: boolean
  isLast: boolean
  iconSize?: string
  onClick: (delta: number) => void
}

export const OrderArrows: React.FC<OrderArrowsProps> = ({
  isFirst,
  isLast,
  iconSize = '25px',
  onClick,
}) => {
  function getSharedProps(
    disabled: boolean,
    delta: number
  ): Partial<IconProps> {
    return {
      type: 'light',
      size: iconSize,
      color: disabled ? 'gray5' : 'gray3',
      ...(isMobileOnly || disabled
        ? undefined
        : {
            hoverColor: 'primary',
            cursor: 'pointer',
          }),
      onClick(e) {
        e.stopPropagation()
        !disabled && onClick(delta)
      },
    }
  }

  return (
    <ArrowContainer>
      <Icon icon="chevron-up" {...getSharedProps(isFirst, -1)} />
      <Icon icon="chevron-down" {...getSharedProps(isLast, 1)} />
    </ArrowContainer>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import {
  CenteredLoader,
  Collapsible,
  IconButton,
  Message,
  PageHeader,
  PageHeaderButton,
} from 'components'
import { PageFooter } from 'components/PageFooter/PageFooter'
import xor from 'lodash/xor'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { openPrint } from 'util/api'
import {
  useBreadcrumbOverrides,
  useConfirm,
  useDateFns,
  useOnErrorAuto,
  usePermission,
  usePromptString,
  useToast,
  useUser,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { getTimeZone } from 'util/time'
import { validateEmail } from 'util/validation'
import { DeviationCategories, DeviationStatus } from './components'
import { DeviationImages } from './components/DeviationImages'
import PerformedActionContent from './components/PerformedActionContent'
import { SEND_DEVIATION_EMAIL_MUTATION } from './mutations'
import { DEVIATION_QUERY } from './queries'
import {
  DeviationQuery,
  SendDeviationEmailMutation,
  SendDeviationEmailMutationVariables,
  ShallowDeviationCategory,
} from './types.graphql'
import { useDeleteDeviation, useEditDeviation } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    margin-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
`

const SubHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    'created   print   send'
    'project   print   send';

  ${props => props.theme.media.desktop} {
    gap: 1rem 2rem;
    margin: 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid ${props => props.theme.colors.gray5};
  }

  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr auto auto auto;
    grid-template-areas:
      'status   edit    print   send'
      'created  created created created'
      'project  project project project';
    gap: 0.8rem 1.5rem;

    padding: 1rem;
    font-size: 14px;
  }
`

interface SubHeaderElementProps {
  area: 'created' | 'print' | 'send' | 'project' | 'status' | 'edit'
}

const SubHeaderElement = styled.div<SubHeaderElementProps>`
  grid-area: ${props => props.area};
  color: ${props => props.theme.colors.gray3};

  ${props => props.theme.media.mobile} {
    display: flex;
    align-items: center;
  }
`

const ContentWrapper = styled.div`
  ${props => props.theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto min-content 1fr;
    grid-template-areas:
      'description      categories'
      'suggestedAction  images'
      'performedActions images';
    grid-gap: 2rem;
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`

const Content = styled.div`
  padding: 2rem;

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`

type CardType =
  | 'description'
  | 'suggestedAction'
  | 'performedActions'
  | 'categories'
  | 'images'

interface DeviationProps {}

export const Deviation: React.FC<DeviationProps> = () => {
  const translations = useTranslate({
    loading: 'common.loading',
    createdBy: 'common.created-by',
    project: 'common.project',
    send: 'common.send',
    print: 'common.print',
    sendToEmail: 'common.send-to-email',

    description: 'common.description',
    suggestedAction: 'deviations.suggested-action',
    performedActions: 'deviations.performed-actions',
    categories: 'deviations.categories',
    images: 'common.images',

    closeDeviation: 'deviations.prompts.close-deviation-prompt',
    closeDeviationTitle: 'deviations.prompts.close-deviation-title',

    invalid: 'common.invalid',

    buttons: {
      open: 'common.open',
      close: 'deviations.close',
      archive: 'common.archive',
      restore: 'deviations.restore',
      delete: 'common.delete',
      edit: 'common.edit',
    },
    prompts: {
      sendEmail: 'deviations.prompts.send-email',
      sendEmailTitle: 'deviations.prompts.send-email-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',

      editError: 'deviations.deviation-edit-error-toast',
      editSuccess: 'deviations.deviation-edit-success-toast',

      sendEmailSuccess: 'deviations.success.send-email',
      sendEmailError: 'deviations.errors.send-email',
    },
  })

  const { projectId, deviationId } =
    useParams<{ projectId: string; deviationId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const theme = useTheme()
  const { format } = useDateFns()
  const history = useHistory()
  const me = useUser()
  const addToast = useToast()
  const confirm = useConfirm()
  const promptEmail = usePromptString({
    confirmText: translations.send,
    validate: validateEmail(translations.invalid),
  })

  const canEditDeviation = usePermission(
    PERMISSIONS.deviations.change.deviation
  )
  const canDeleteDeviation = usePermission(
    PERMISSIONS.deviations.delete.deviation
  )

  const [cardsOpen, setCardsOpen] = useState<CardType[]>([
    'description',
    'suggestedAction',
    'performedActions',
    'categories',
    'images',
  ])

  const { patchDeviation: handleEditDeviation } = useEditDeviation({
    id: deviationId,
  })
  const {
    deleteDeviation: handleDeleteDeviation,
    loading: deleteDeviationLoading,
  } = useDeleteDeviation([], () =>
    history.push(
      !projectId ? '/deviations/internal' : `/projects/${projectId}/deviations`
    )
  )

  const { loading, error, data } = useQuery<DeviationQuery, IdVariable>(
    DEVIATION_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: deviationId,
      },
      onError: onErrorAuto(translations.results.queryError),
    }
  )

  const [sendEmail, { loading: sendEmailLoading }] = useMutation<
    SendDeviationEmailMutation,
    SendDeviationEmailMutationVariables
  >(SEND_DEVIATION_EMAIL_MUTATION, {
    onCompleted({ sendDeviationEmail: { emailSent } }) {
      if (!emailSent) {
        addToast('error', translations.results.sendEmailError)
      } else {
        addToast('success', translations.results.sendEmailSuccess)
        mixpanel.track('Send Deviation Email')
      }
    },
    onError: onErrorAuto(translations.results.sendEmailError),
  })

  useBreadcrumbOverrides({
    [projectId]: !!data ? data.deviation.project?.name : translations.loading,
    [deviationId]: !!data ? data.deviation.title : translations.loading,
  })

  const handleCloseDeviation = useCallback(
    async (deviationId: string) => {
      const answer = await confirm(
        translations.closeDeviation,
        translations.closeDeviationTitle
      )

      if (answer) {
        handleEditDeviation({
          variables: {
            id: deviationId,
            input: { treated: true },
          },
        })
      }
    },
    [
      confirm,
      handleEditDeviation,
      translations.closeDeviation,
      translations.closeDeviationTitle,
    ]
  )

  const handlePrint = useCallback(
    () =>
      openPrint(
        `/deviations/print-deviation?deviation_id=${deviationId}&creator_id=${me.id}`
      ),
    [deviationId, me.id]
  )

  const handleSendEmail = useCallback(async () => {
    const recipient = await promptEmail(
      translations.prompts.sendEmail,
      translations.prompts.sendEmailTitle
    )
    if (!recipient) return

    sendEmail({
      variables: {
        id: deviationId,
        recipient,
        tz: getTimeZone(),
      },
    })
  }, [
    deviationId,
    promptEmail,
    sendEmail,
    translations.prompts.sendEmail,
    translations.prompts.sendEmailTitle,
  ])

  function toggleCard(card: CardType) {
    setCardsOpen(v => xor(v, [card]))
  }

  const categories = useMemo<ShallowDeviationCategory[]>(
    () => data?.deviation.categories.edges.map(({ node }) => node) ?? [],
    [data]
  )
  const images = useMemo(
    () => data?.deviation.images.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const commonButtons = useMemo<PageHeaderButton[]>(
    () => [
      {
        text: translations.buttons.close,
        background: theme.colors.gray4,
        hide: !canEditDeviation || data?.deviation.treated,
        onClick: () => handleCloseDeviation(deviationId),
      },
      {
        text: translations.buttons.open,
        background: theme.colors.gray4,
        hide: !canEditDeviation || !data?.deviation.treated,
        onClick: () =>
          handleEditDeviation({
            variables: {
              id: deviationId,
              input: { treated: false },
            },
          }),
      },
      {
        text: translations.buttons.archive,
        background: theme.colors.gray4,
        hide: !canEditDeviation || !data?.deviation.active,
        onClick: () =>
          handleEditDeviation({
            variables: {
              id: deviationId,
              input: { active: false },
            },
          }),
      },
      {
        text: translations.buttons.restore,
        background: theme.colors.gray4,
        hide: !canEditDeviation || data?.deviation.active,
        onClick: () =>
          handleEditDeviation({
            variables: {
              id: deviationId,
              input: { active: true },
            },
          }),
      },
      {
        text: translations.buttons.delete,
        background: theme.colors.danger,
        hide: !canDeleteDeviation,
        onClick: () => handleDeleteDeviation(deviationId),
      },
    ],
    [
      canDeleteDeviation,
      canEditDeviation,
      handleCloseDeviation,
      data?.deviation.active,
      data?.deviation.treated,
      deviationId,
      handleDeleteDeviation,
      handleEditDeviation,
      theme.colors.danger,
      theme.colors.gray4,
      translations.buttons.archive,
      translations.buttons.close,
      translations.buttons.delete,
      translations.buttons.open,
      translations.buttons.restore,
    ]
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (loading || !data) return <CenteredLoader />

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader
          title={
            <>
              {data.deviation.title}
              <DeviationStatus
                isActive={data.deviation.active}
                isTreated={data.deviation.treated}
              />
            </>
          }
          loading={loading || deleteDeviationLoading}
          noBottomMargin
          buttons={[
            ...commonButtons,
            {
              text: translations.buttons.edit,
              icon: { icon: 'pencil-alt', type: 'solid', color: 'white' },
              hide: !canEditDeviation,
              onClick: () =>
                history.push(
                  !projectId
                    ? `/deviations/internal/${deviationId}/edit`
                    : `/projects/${projectId}/deviations/${deviationId}/edit`
                ),
            },
          ]}
        />
      )}

      <SubHeader>
        {isMobileOnly && (
          <SubHeaderElement area="status">
            <DeviationStatus
              isActive={data.deviation.active}
              isTreated={data.deviation.treated}
            />
          </SubHeaderElement>
        )}

        <SubHeaderElement area="created">
          {translations.createdBy}:&nbsp;
          <Link to={`/users/${data.deviation.createdBy?.id}`}>
            {data.deviation.createdBy?.fullName}
          </Link>
          &nbsp;
          {format(
            new Date(data.deviation.createdAt),
            isMobileOnly ? 'Pp' : 'PPPp'
          )}
        </SubHeaderElement>

        {!!projectId && (
          <SubHeaderElement area="project">
            {translations.project}:&nbsp;
            <Link to={`/projects/${projectId}`}>
              {data.deviation.project?.name}
            </Link>
          </SubHeaderElement>
        )}

        {isMobileOnly && (
          <SubHeaderElement area="edit">
            <IconButton
              icon="pencil-alt"
              onClick={() => handleEditDeviation()}
            />
          </SubHeaderElement>
        )}

        <SubHeaderElement area="print">
          <IconButton icon="print" role="link" onClick={handlePrint}>
            {!isMobileOnly && translations.print}
          </IconButton>
        </SubHeaderElement>

        <SubHeaderElement area="send">
          <IconButton
            icon="envelope"
            loading={sendEmailLoading}
            onClick={handleSendEmail}
          >
            {!isMobileOnly && translations.sendToEmail}
          </IconButton>
        </SubHeaderElement>
      </SubHeader>

      <ContentWrapper>
        <Collapsible
          area="description"
          title={translations.description}
          closed={!cardsOpen.includes('description')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('description')}
          height="max-content"
        >
          <Content>{data.deviation.description}</Content>
        </Collapsible>

        <Collapsible
          area="suggestedAction"
          title={translations.suggestedAction}
          closed={!cardsOpen.includes('suggestedAction')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('suggestedAction')}
          height="max-content"
        >
          <Content>{data.deviation.suggestedAction}</Content>
        </Collapsible>

        <Collapsible
          area="performedActions"
          title={translations.performedActions}
          closed={!cardsOpen.includes('performedActions')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('performedActions')}
          height="max-content"
        >
          <Content>
            <PerformedActionContent deviationId={deviationId} />
          </Content>
        </Collapsible>

        <Collapsible
          area="categories"
          title={translations.categories}
          closed={!cardsOpen.includes('categories')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('categories')}
          height="max-content"
        >
          <Content>
            <DeviationCategories categories={categories} />
          </Content>
        </Collapsible>

        <Collapsible
          area="images"
          title={translations.images}
          closed={!cardsOpen.includes('images')}
          noCollapse={!isMobileOnly}
          onToggleOpen={() => toggleCard('images')}
        >
          <DeviationImages images={images} />
        </Collapsible>
      </ContentWrapper>

      {isMobileOnly && <PageFooter buttons={commonButtons} />}
    </Wrapper>
  )
}

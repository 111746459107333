import { useTranslate } from '@ur/react-hooks'
import { DropdownNav, NavItem, OverlayLoader } from 'components'
import { Redirect, Route, Switch } from 'containers/Routing'
import React, { useMemo } from 'react'
import { CrumbOverrides } from 'store'
import styled from 'styled-components'
import { useBreadcrumbOverrides } from 'util/hooks'
import { EditCompanyForm, Folders, Information, Other } from '.'
import {
  CompanySettingsDeviationCategories,
  CompanySettingsUserTypes,
} from '..'
import { Card } from './CompanySettings'

const Wrapper = styled.div`
  margin-bottom: 0.5rem;

  div.menu {
    margin: 0.5rem 1rem 1rem;
  }
  div.information {
    margin: 0 1rem;
  }
`
const FoldersCard = styled.div`
  width: 100%;
  background-color: white;
  margin: 1rem 0;

  color: ${props => props.theme.colors.gray2};
  font-size: 1rem;
`

interface CompanySettingsMobileProps {
  loading: boolean

  onUploadLogo: (evt: React.ChangeEvent<HTMLInputElement> | null) => void
  onFormSubmit: (form: EditCompanyForm) => Promise<void>

  onAddFolder: (name: string) => Promise<void>
  onEditFolder: (
    id: string,
    enabled: boolean,
    newName?: string
  ) => Promise<void>
  onDeleteFolder: (id: string) => void
}

export const CompanySettingsMobile: React.FC<CompanySettingsMobileProps> = ({
  loading,

  onUploadLogo,
  onFormSubmit,

  onAddFolder,
  onEditFolder,
  onDeleteFolder,
}) => {
  const translations = useTranslate({
    companySettings: 'companies.company-settings',
    folderDescription: 'company.company-folders-help-text',

    folders: 'company.company-folders',
    information: 'common.information',
    userTypes: 'users.user-types',
    deviationCategories: 'common.deviation-categories',
    other: 'common.other',
  })

  const navItems = useMemo<NavItem[]>(
    () => [
      {
        id: 'information',
        to: '/settings/company/information',
        text: translations.information,
        icon: {
          icon: 'info-circle',
          type: 'solid',
        },
      },
      {
        id: 'folders',
        to: '/settings/company/folders',
        text: translations.folders,
        icon: {
          icon: 'folder-open',
          type: 'solid',
        },
      },
      {
        id: 'user-types',
        to: '/settings/company/user-types',
        text: translations.userTypes,
        icon: {
          icon: 'user-tag',
          type: 'solid',
        },
      },
      {
        id: 'deviation-categories',
        to: '/settings/company/deviation-categories',
        text: translations.deviationCategories,
        icon: {
          icon: 'exclamation-triangle',
          type: 'solid',
        },
      },
      {
        id: 'other',
        to: '/settings/company/other',
        text: translations.other,
        icon: {
          icon: 'cog',
          type: 'solid',
        },
      },
    ],
    [
      translations.deviationCategories,
      translations.folders,
      translations.information,
      translations.other,
      translations.userTypes,
    ]
  )

  useBreadcrumbOverrides(
    navItems.reduce<CrumbOverrides>(
      (acc, cur) => ({
        ...acc,
        [cur.id]: translations.companySettings,
      }),
      {}
    ),
    '/settings/company'
  )

  return (
    <Wrapper>
      <OverlayLoader show={loading} />

      <div className="menu">
        <DropdownNav items={navItems} />
      </div>

      <Switch>
        <Redirect
          exact
          from="/settings/company"
          to="/settings/company/information"
        />

        <Route path="/settings/company/information">
          <div className="information">
            <Information
              onUploadLogo={onUploadLogo}
              onFormSubmit={onFormSubmit}
            />
          </div>
        </Route>

        <Route path="/settings/company/folders">
          <Card>
            <header>{translations.folders}</header>
            {translations.folderDescription}
          </Card>

          <FoldersCard>
            <Folders
              onAddFolder={onAddFolder}
              onEditFolder={onEditFolder}
              onDeleteFolder={onDeleteFolder}
            />
          </FoldersCard>
        </Route>

        <Route path="/settings/company/user-types">
          <CompanySettingsUserTypes />
        </Route>

        <Route path="/settings/company/deviation-categories">
          <CompanySettingsDeviationCategories />
        </Route>

        <Route path="/settings/company/other">
          <Other />
        </Route>
      </Switch>
    </Wrapper>
  )
}

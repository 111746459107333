import React from 'react'
import styled from 'styled-components'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useDateFns } from 'util/hooks'
import { ShallowChecklistTemplate } from '../types.graphql'

const Wrapper = styled.section`
  margin-top: 1rem;

  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  height: 40px;

  font-size: 14px;
  color: ${props => props.theme.colors.gray1};

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 2px 0;

  a {
    display: flex;
    padding-left: 1rem;
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`
const DateField = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-weight: 600;
  white-space: nowrap;
  border-right: 2px solid ${props => props.theme.colors.gray6};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 1rem 1.2rem;

  a {
    font-size: 18px;
    font-weight: 600;
  }
`

interface ChecklistTemplateCardProps {
  template: ShallowChecklistTemplate
  editDeleteMenu: TableMenu
}

export const ChecklistTemplateCard: React.FC<ChecklistTemplateCardProps> = ({
  template,
  editDeleteMenu,
}) => {
  const { format } = useDateFns()

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(template.createdAt), 'PPP')}</DateField>

        <Link to={`/users/${template.createdBy?.id}`}>
          {template.createdBy?.fullName ?? ''}
        </Link>

        <Menu className="menu" icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      <Content>
        <Link to={`/checklists/templates/${template.id}`}>
          {template.title}
        </Link>

        <span>{template.description}</span>
      </Content>
    </Wrapper>
  )
}

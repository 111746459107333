import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'

export function formatPhoneNumber(
  phone: string,
  country: CountryCode = 'NO',
  international = false
) {
  try {
    const parsed = parsePhoneNumber(phone, country)
    return international
      ? parsed.formatInternational()
      : parsed.formatNational()
  } catch {
    return phone
  }
}

export function shortenNumber(num: number) {
  const res =
    Math.abs(num) > 999
      ? Math.sign(num) * parseFloat((Math.abs(num) / 1000).toFixed(1)) + 'k'
      : Math.sign(num) * Math.abs(num)
  return typeof res === 'number' ? res.toFixed(1) : res
}

export function ellipsis(text: string, maxLength = 30) {
  if (maxLength < 4) throw new Error('maxLength must be at least 4')
  return text.length > maxLength
    ? text.substring(0, maxLength - 3).trim() + '...'
    : text
}

export function toLocaleStringFixed(
  num: number,
  decimals = 2,
  locale?: string
) {
  return num.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

export function stringToSlug(str: string, legalCharacters?: string) {
  const legal = legalCharacters ?? 'abcdefghijklmonpqrstuvwxyz-0123456789'
  return str
    .toLocaleLowerCase()
    .replace(/ /g, '-')
    .split('')
    .reduce((acc, cur) => (legal.includes(cur) ? acc + cur : acc), '')
}

import {
  ImageViewer as BaseImageViewer,
  ImageViewerProps as BaseImageViewerProps,
} from '@ur/react-components'
import styled from 'styled-components'
import { ZIndexRange } from 'types/style'

const StyledImageViewer = styled(BaseImageViewer)``

interface ImageViewerProps extends BaseImageViewerProps {
  noDownloadButton?: boolean
}

export const ImageViewer: React.FC<ImageViewerProps> = ({
  noDownloadButton = false,
  ...props
}) => {
  return (
    <StyledImageViewer
      showArrows
      imageSize="scale-down"
      background="rgba(0,0,0,0.9)"
      zIndex={ZIndexRange.Overlays}
      showDownloadButton={!noDownloadButton}
      {...props}
    />
  )
}

import { AllowedLanguage } from 'locale/util'
import { UserNode } from './types.graphql'

export const USER_PLACEHOLDER: UserNode = {
  __typename: 'UserNode',
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  birthNumber: '',
  address: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  internalNumber: '',
  unseenNotificationCount: 0,
  initials: '',
  username: '',
  email: '',
  position: '',
  isStaff: false,
  isSuperuser: false,
  isActive: false,
  needsPasswordChange: false,
  hasRegistered: false,
  profilePicture: null,
  profilePicture256: null,
  isAdministrator: false,
  timebankTotal: 0,
  timebankWithdrawal: 0,
  emergencyContacts: {
    __typename: 'UserEmergencyContactConnection',
    edges: [],
  },
  settings: {
    __typename: 'UserSettingConnection',
    edges: [],
  },
  userTypes: [],
  permissions: [],
  companies: {
    __typename: 'CompanyConnection',
    edges: [],
  },
}

/* User settings */
export const USER_SETTING_LANGUAGE_KEY = 'language'
export const USER_SETTING_LANGUAGE_DEFAULT: AllowedLanguage = 'nb'

import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled, { css } from 'styled-components'
import { DateRange, QuickRange } from './types'
import { useDateRangeQuickie } from './util'

interface WrapperProps {
  vertical: boolean
}
const Wrapper = styled.section<WrapperProps>`
  display: flex;
  flex-direction: column;

  padding: 1rem;

  border-right: ${props =>
    !props.vertical && `2px solid ${props.theme.colors.gray6}`};

  ${props =>
    props.vertical &&
    css`
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    `};
`
interface QuickieProps {
  active: boolean
}
const Quickie = styled.div<QuickieProps>`
  padding: 0.6rem 1rem;

  cursor: pointer;
  white-space: nowrap;
  color: ${props => props.active && props.theme.colors.primary};
  background-color: ${props => props.active && props.theme.colors.primary}20;
  border-radius: 10px;

  &:hover {
    color: ${props => !props.active && props.theme.colors.primaryHover};
  }
`

interface QuickSelectProps {
  value: DateRange
  vertical: boolean

  onChange: (value: DateRange) => void
}

export const QuickSelect: React.FC<QuickSelectProps> = ({
  value,
  vertical,

  onChange,
}) => {
  const translations = useTranslate({
    today: 'common.today',
    yesterday: 'common.yesterday',
    thisWeek: 'common.this-week',
    lastWeek: 'common.last-week',
    thisMonth: 'common.this-month',
    lastMonth: 'common.last-month',
    thisYear: 'common.this-year',
    lastYear: 'common.last-year',
    allTime: 'common.all-time',
  })

  const [quickie, ranges] = useDateRangeQuickie(value)

  function handleChange(range: QuickRange) {
    return () => {
      const selected = ranges[range]
      onChange({
        from: selected[0],
        to: selected[1],
      })
    }
  }

  return (
    <Wrapper vertical={vertical}>
      <Quickie active={quickie === 'today'} onClick={handleChange('today')}>
        {translations.today}
      </Quickie>

      <Quickie
        active={quickie === 'yesterday'}
        onClick={handleChange('yesterday')}
      >
        {translations.yesterday}
      </Quickie>

      <Quickie
        active={quickie === 'thisWeek'}
        onClick={handleChange('thisWeek')}
      >
        {translations.thisWeek}
      </Quickie>

      <Quickie
        active={quickie === 'lastWeek'}
        onClick={handleChange('lastWeek')}
      >
        {translations.lastWeek}
      </Quickie>

      <Quickie
        active={quickie === 'thisMonth'}
        onClick={handleChange('thisMonth')}
      >
        {translations.thisMonth}
      </Quickie>

      <Quickie
        active={quickie === 'lastMonth'}
        onClick={handleChange('lastMonth')}
      >
        {translations.lastMonth}
      </Quickie>

      <Quickie
        active={quickie === 'thisYear'}
        onClick={handleChange('thisYear')}
      >
        {translations.thisYear}
      </Quickie>

      <Quickie
        active={quickie === 'lastYear'}
        onClick={handleChange('lastYear')}
      >
        {translations.lastYear}
      </Quickie>

      <Quickie active={quickie === 'allTime'} onClick={handleChange('allTime')}>
        {translations.allTime}
      </Quickie>
    </Wrapper>
  )
}

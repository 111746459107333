import { NumberInputProps } from '@ur/react-components'
import React from 'react'
import styled from 'styled-components'
import { NumberInput as BaseNumberInput } from '.'

const Wrapper = styled.div``
const NumberInput = styled(BaseNumberInput)`
  .--input-icon-left,
  .--input-icon-right {
    cursor: pointer;

    &:hover i {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`
const QuickChoices = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 1rem;
  height: 30px;

  margin-top: 0.5rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    background-color: ${props => props.theme.colors.gray7};

    color: ${props => props.theme.colors.gray3};
    font-weight: 600;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    div {
      background-color: ${props => props.theme.colors.white};
    }
  }
`

interface QuickNumberInputProps extends NumberInputProps {
  quickChoices?: number[]
  interval?: number
  maxDecimals?: number
  noQuickChoices?: boolean
  noMax?: boolean
}

export const QuickNumberInput: React.FC<QuickNumberInputProps> = ({
  value,

  min = 0,
  max = 24,
  interval = 0.5,

  quickChoices = [2, 4, 6, 7.5],
  noQuickChoices = false,

  maxDecimals = 2,
  noMax = false,

  onChange,

  ...props
}) => {
  function handleIncrement(delta: number) {
    if (noMax) onChange(Math.max(min, value + delta))
    else onChange(Math.max(min, Math.min(max, value + delta)))
  }

  return (
    <Wrapper>
      <NumberInput
        value={value}
        min={min}
        max={!noMax ? max : undefined}
        center
        maxDecimals={maxDecimals}
        iconLeftProps={{
          icon: 'minus',
          cursor: 'pointer',
          color: 'gray3',
          boxBackground: 'gray7',
          onClick: () => handleIncrement(-interval),
        }}
        iconRightProps={{
          icon: 'plus',
          cursor: 'pointer',
          color: 'gray3',
          boxBackground: 'gray7',
          onClick: () => handleIncrement(interval),
        }}
        onChange={onChange}
        {...props}
      />

      {!!quickChoices.length && !noQuickChoices && (
        <QuickChoices>
          {quickChoices.map((choice, index) => (
            <div
              key={`${choice}+${index}`}
              role="button"
              onClick={() => onChange(choice)}
            >
              {choice}
            </div>
          ))}
        </QuickChoices>
      )}
    </Wrapper>
  )
}

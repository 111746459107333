import { theme } from 'theme'

export const projectSvg = (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    xmlns="http://www.w3.org/2000/svg"
    textAnchor="middle"
    fontSize="18px"
    fontWeight="700"
    transform="translate(10,20)"
  >
    <path
      d="M17.8967 8.76889L15.6334 13.1337C15.4573 13.4734 15.2051 13.7552 14.902 13.9511C14.5989 14.1469 14.2555 14.25 13.9059 14.25H1.40703C0.828188 14.25 0.467531 13.5436 0.759188 12.9811L3.02244 8.61628C3.19857 8.2766 3.45076 7.99479 3.75388 7.79892C4.05699 7.60306 4.40044 7.5 4.75 7.5H17.2489C17.8277 7.5 18.1884 8.20639 17.8967 8.76889ZM4.75 6.375H15V4.6875C15 3.75551 14.3284 3 13.5 3H8.5L6.5 0.75H1.5C0.671562 0.75 0 1.50551 0 2.4375V12.2126L2.15866 8.04942C2.69419 7.0166 3.68716 6.375 4.75 6.375Z"
      fill={theme.colors.lightPurple}
    />
  </svg>
)

export const deviationSvg = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    textAnchor="middle"
    fontSize="18px"
    fontWeight="700"
    transform="translate(10,20)"
  >
    <path
      d="M15.8199 14.2504C16.3327 15.2502 15.6891 16.5 14.665 16.5H1.33484C0.308814 16.5 -0.331798 15.2483 0.179925 14.2504L6.8451 1.24953C7.35807 0.24925 8.64288 0.251063 9.15494 1.24953L15.8199 14.2504ZM8.00002 11.5625C7.29432 11.5625 6.72224 12.2061 6.72224 13C6.72224 13.7939 7.29432 14.4375 8.00002 14.4375C8.70572 14.4375 9.2778 13.7939 9.2778 13C9.2778 12.2061 8.70572 11.5625 8.00002 11.5625ZM6.78688 6.39544L6.99294 10.6454C7.00257 10.8443 7.14874 11 7.32577 11H8.67427C8.8513 11 8.99747 10.8443 9.0071 10.6454L9.21316 6.39544C9.22358 6.18062 9.07155 6 8.88033 6H7.11969C6.92846 6 6.77646 6.18062 6.78688 6.39544Z"
      fill={theme.colors.lightPink}
    />
  </svg>
)

export const checklistsSvg = (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    textAnchor="middle"
    fontSize="18px"
    fontWeight="700"
    transform="translate(10,20)"
  >
    <path
      d="M12.25 2.75H9.33333C9.33333 1.50898 8.28698 0.5 7 0.5C5.71302 0.5 4.66667 1.50898 4.66667 2.75H1.75C0.783854 2.75 0 3.50586 0 4.4375V16.8125C0 17.7441 0.783854 18.5 1.75 18.5H12.25C13.2161 18.5 14 17.7441 14 16.8125V4.4375C14 3.50586 13.2161 2.75 12.25 2.75ZM3.5 15.4062C3.0151 15.4062 2.625 15.0301 2.625 14.5625C2.625 14.0949 3.0151 13.7188 3.5 13.7188C3.9849 13.7188 4.375 14.0949 4.375 14.5625C4.375 15.0301 3.9849 15.4062 3.5 15.4062ZM3.5 12.0312C3.0151 12.0312 2.625 11.6551 2.625 11.1875C2.625 10.7199 3.0151 10.3438 3.5 10.3438C3.9849 10.3438 4.375 10.7199 4.375 11.1875C4.375 11.6551 3.9849 12.0312 3.5 12.0312ZM3.5 8.65625C3.0151 8.65625 2.625 8.28008 2.625 7.8125C2.625 7.34492 3.0151 6.96875 3.5 6.96875C3.9849 6.96875 4.375 7.34492 4.375 7.8125C4.375 8.28008 3.9849 8.65625 3.5 8.65625ZM7 1.90625C7.4849 1.90625 7.875 2.28242 7.875 2.75C7.875 3.21758 7.4849 3.59375 7 3.59375C6.5151 3.59375 6.125 3.21758 6.125 2.75C6.125 2.28242 6.5151 1.90625 7 1.90625ZM11.6667 14.8438C11.6667 14.9984 11.5354 15.125 11.375 15.125H6.125C5.96458 15.125 5.83333 14.9984 5.83333 14.8438V14.2812C5.83333 14.1266 5.96458 14 6.125 14H11.375C11.5354 14 11.6667 14.1266 11.6667 14.2812V14.8438ZM11.6667 11.4688C11.6667 11.6234 11.5354 11.75 11.375 11.75H6.125C5.96458 11.75 5.83333 11.6234 5.83333 11.4688V10.9062C5.83333 10.7516 5.96458 10.625 6.125 10.625H11.375C11.5354 10.625 11.6667 10.7516 11.6667 10.9062V11.4688ZM11.6667 8.09375C11.6667 8.24844 11.5354 8.375 11.375 8.375H6.125C5.96458 8.375 5.83333 8.24844 5.83333 8.09375V7.53125C5.83333 7.37656 5.96458 7.25 6.125 7.25H11.375C11.5354 7.25 11.6667 7.37656 11.6667 7.53125V8.09375Z"
      fill={theme.colors.lightPurple}
    />
  </svg>
)

export const offerSvg = (
  <svg
    width="12"
    height="17"
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 4.75V0.5H0.75C0.334375 0.5 0 0.834375 0 1.25V15.75C0 16.1656 0.334375 16.5 0.75 16.5H11.25C11.6656 16.5 12 16.1656 12 15.75V5.5H7.75C7.3375 5.5 7 5.1625 7 4.75ZM12 4.30938V4.5H8V0.5H8.19063C8.39062 0.5 8.58125 0.578125 8.72188 0.71875L11.7812 3.78125C11.9219 3.92188 12 4.1125 12 4.30938Z"
      fill={theme.colors.lightPink}
    />
  </svg>
)

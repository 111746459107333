import React from 'react'
import styled from 'styled-components'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { useTranslate } from '@ur/react-hooks'
import { Product } from './types.graphql'
import { toLocaleStringFixed } from 'util/formatting'

const Wrapper = styled.div`
  margin: 1rem 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;

    &.menus {
      justify-content: space-between;
      padding: 0 0.5rem 0 1rem;
    }
  }
`
const SkuField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`
const Content = styled.div`
  padding: 1rem;
  font-weight: 600;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  div {
    padding: 0.8rem 1rem;

    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    color: ${props => props.theme.colors.gray2};

    border-top: 1px solid ${props => props.theme.colors.gray6};

    span {
      color: ${props => props.theme.colors.gray3};
    }
  }
`

interface ProductCardProps {
  product: Product
  editDeleteMenu: TableMenu
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  editDeleteMenu,
}) => {
  const translations = useTranslate({
    costPrice: 'products.cost-price',
    price: 'products.price',
  })

  return (
    <Wrapper>
      <Header>
        <SkuField>{product.sku}</SkuField>

        <div className="menus">
          {!!product.merchant ? product.merchant : <div />}

          <Menu icon="ellipsis-h" config={editDeleteMenu} />
        </div>
      </Header>

      <Content>
        <div>{product.name}</div>
      </Content>

      <Footer>
        <div>
          {translations.costPrice + ': '}

          <span>
            {'NOK ' +
              toLocaleStringFixed(Number(product.priceExcludingVat)) +
              '/' +
              product.unit}
          </span>
        </div>

        <div>
          {translations.price + ': '}

          <span>
            {'NOK ' +
              toLocaleStringFixed(Number(product.grossPriceExcludingVat)) +
              '/' +
              product.unit}
          </span>
        </div>
      </Footer>
    </Wrapper>
  )
}

import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField, Input, UserTypeSelectMultiple } from 'components'
import React, { useEffect, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { usePostalCode } from 'util/hooks/usePostalCode'
import { PERMISSIONS } from 'util/permissions'
import { validateNonEmpty, validatePhoneNumber } from 'util/validation'
import { InfoCard, ProfilePicture } from './components'
import {
  PatchUserMutation,
  PatchUserMutationInput,
  ShallowUser,
} from './types.graphql'

const Wrapper = styled(InfoCard)`
  display: grid;

  grid-template-columns: 120px 2fr;
  grid-template-areas:
    'header    header '
    'picture   firstName'
    'picture   lastName'
    'phone     phone'
    'address   address'
    'zip       city'
    'userTypes userTypes'
    'save      save';
  gap: 1rem;

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'picture    picture'
      'firstName  firstName'
      'lastName   lastName'
      'phone      phone'
      'address    address'
      'zip        city'
      'userTypes  userTypes'
      'save       save';
    gap: 1rem;
  }
`

const FormFieldPicture = styled(FormField).attrs({
  area: 'picture',
})`
  display: flex;
  align-items: center;

  ${props => props.theme.media.mobile} {
    justify-content: center;
  }
`

const SaveButton = styled(Button)`
  grid-area: save;
`

export type ChangeUserInfoForm = Required<
  Omit<PatchUserMutationInput, 'profilePicture' | 'isActive'>
> & {
  profilePicture: File | string | null
  userTypes: string[]
}

interface ChangeInformationProps {
  user?: ShallowUser
  loading: boolean

  onSubmit: (
    data: Partial<ChangeUserInfoForm>
  ) => Promise<PatchUserMutation | null>
}

export const ChangeInformation: React.FC<ChangeInformationProps> = ({
  user,
  loading,
  onSubmit,
}) => {
  const translations = useTranslate({
    information: 'common.information',

    firstName: 'common.firstName',
    lastName: 'common.lastName',
    phone: 'common.phone',
    address: 'customers.address',
    postalCode: 'customers.postal-code',
    zip: 'common.zip',
    city: 'users.city',
    userTypes: 'users.select-user-types',

    save: 'common.save',

    validation: {
      invalidPhoneNumber: 'errors.invalid-phone-number',
      required: 'common.required',
    },
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const hasSetUser = useRef(false)

  const canEditUserType = usePermission(PERMISSIONS.users.view.usertype)

  const isLoading = loading || typeof user === 'undefined'

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    formChangeHandler: handler,
    updateForm,
    updateInitialValues,
    submitHandler,
  } = useForm<ChangeUserInfoForm>({
    values: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      profilePicture: null,
      userTypes: [],
    },
    validators: {
      firstName: validateNonEmpty(translations.validation.required),
      lastName: validateNonEmpty(translations.validation.required),
      phoneNumber: [
        validateNonEmpty(translations.validation.required),
        validatePhoneNumber(translations.validation.invalidPhoneNumber),
      ],
      address: validateNonEmpty(translations.validation.required),
      postalCode: validateNonEmpty(translations.validation.required),
      city: validateNonEmpty(translations.validation.required),
      userTypes: validateNonEmpty(translations.validation.required),
    },
  })

  const { loading: cityLoading } = usePostalCode(form.postalCode, {
    onCityChange(city) {
      updateForm({ city: city ?? '' })
    },
  })

  useEffect(() => {
    if (!user || hasSetUser.current) return
    hasSetUser.current = true

    const values: typeof form = {
      firstName: user.firstName,
      lastName: user.lastName,
      address: user.address,
      city: user.city,
      postalCode: user.postalCode,
      phoneNumber: user.phoneNumber,
      profilePicture: user.profilePicture,
      userTypes: user.userTypes.map(type => type.id),
    }

    updateForm(values)
    updateInitialValues(values)
  }, [user, updateForm, updateInitialValues])

  function handleChangeImage(evt: React.ChangeEvent<HTMLInputElement>) {
    const file = Array.from(evt.target.files ?? [])[0]
    if (!file) return

    updateForm({ profilePicture: file })

    evt.target.value = ''
  }

  async function handleSubmit(values: typeof form) {
    await onSubmit(values)
    updateInitialValues()
  }

  return (
    <Wrapper title={translations.information} loading={isLoading}>
      <FormFieldPicture>
        <ProfilePicture
          src={form.profilePicture ?? null}
          alt={user?.fullName ?? ''}
          onRemove={() => updateForm({ profilePicture: null })}
          onRequestChange={() => fileInputRef.current?.click()}
        />

        <input
          ref={fileInputRef}
          hidden
          type="file"
          accept="image/*"
          onChange={handleChangeImage}
        />
      </FormFieldPicture>

      <FormField error={!!errors.firstName} required area="firstName">
        <label>{translations.firstName}</label>
        <Input
          value={form.firstName}
          error={errors.firstName}
          fullWidth={isMobileOnly}
          disabled={isLoading}
          onChange={handler('firstName')}
        />
      </FormField>

      <FormField error={!!errors.lastName} required area="lastName">
        <label>{translations.lastName}</label>
        <Input
          value={form.lastName}
          error={errors.lastName}
          fullWidth={isMobileOnly}
          disabled={isLoading}
          onChange={handler('lastName')}
        />
      </FormField>

      <FormField error={!!errors.phoneNumber} required area="phone">
        <label>{translations.phone}</label>
        <Input
          value={form.phoneNumber}
          error={errors.phoneNumber}
          fullWidth
          disabled={isLoading}
          onChange={handler('phoneNumber')}
        />
      </FormField>

      <FormField error={!!errors.address} required area="address">
        <label>{translations.address}</label>
        <Input
          value={form.address}
          error={errors.address}
          fullWidth
          disabled={isLoading}
          onChange={handler('address')}
        />
      </FormField>

      <FormField error={!!errors.postalCode} required area="zip">
        <label>
          {isMobileOnly ? translations.zip : translations.postalCode}
        </label>
        <Input
          value={form.postalCode}
          error={errors.postalCode}
          disabled={isLoading}
          onChange={handler('postalCode')}
        />
      </FormField>

      <FormField error={!!errors.city} required area="city">
        <label>{translations.city}</label>
        <Input
          value={form.city}
          error={errors.city}
          disabled={isLoading}
          loading={cityLoading}
          onChange={handler('city')}
        />
      </FormField>

      {canEditUserType && (
        <FormField error={!!errors.userTypes} required area="userTypes">
          <label>{translations.userTypes}</label>
          <UserTypeSelectMultiple
            selected={form.userTypes}
            error={errors.userTypes}
            fullWidth
            onChange={handler('userTypes')}
          />
        </FormField>
      )}

      <SaveButton
        className="submit"
        disabled={!formValid || !formEdited}
        onClick={submitHandler(handleSubmit)}
      >
        {translations.save}
      </SaveButton>
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { CenteredLoader, Message } from 'components'
import { PROJECT_DASHBOARD_QUERY } from 'modules/projects/queries'
import {
  DashboardOfferStatusType,
  ProjectDashboardQuery,
  ProjectDashboardQueryVariables,
  ProjectDashboardTimeFilters,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import {
  ChecklistsCard,
  DeviationsCard,
  HoursCard,
  OffersCard,
  RegisteredElementsCard,
} from './cards'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 4px;

  ${props => props.theme.media.mobile} {
    flex-direction: column;
  }
`

interface DashboardCardsProps {}

export const DashboardCards: React.FC<DashboardCardsProps> = () => {
  const translations = useTranslate({
    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [loadingModule, setLoadingModule] = useState<
    keyof ProjectDashboardTimeFilters | null
  >(null)
  const [timeFilters, setTimeFilters] = useState<ProjectDashboardTimeFilters>({
    checklistsTimeFilter: 'ALL',
    deviationsTimeFilter: 'ALL',
    hoursTimeFilter: 'THIS_WEEK',
    offersTimeFilter: 'ALL',
    projectsTimeFilter: 'ALL',
    registeredElementsTimeFilter: 'THIS_MONTH',
  })
  const [offersStatusFilter, setOffersStatusFilter] =
    useState<DashboardOfferStatusType>()

  const onErrorAuto = useOnErrorAuto()

  const {
    data: queryData,
    previousData,
    loading,
    error,
  } = useQuery<ProjectDashboardQuery, ProjectDashboardQueryVariables>(
    PROJECT_DASHBOARD_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        offersStatusFilter,
        ...timeFilters,
      },
      onCompleted() {
        setHasLoadedOnce(true)
        setLoadingModule(null)
      },
      onError: onErrorAuto(),
    }
  )

  const data = useMemo(
    () => (queryData ?? previousData)?.projectDashboard,
    [queryData, previousData]
  )

  function updateTimeFilter(filter: keyof ProjectDashboardTimeFilters) {
    return (value: TimeFilterType) => {
      setLoadingModule(filter)
      setTimeFilters(v => ({
        ...v,
        [filter]: value,
      }))
    }
  }

  if (!data || (!hasLoadedOnce && loading)) return <CenteredLoader />

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {data.isProjectLeader ? (
        <>
          <RegisteredElementsCard
            data={data}
            loading={loadingModule === 'registeredElementsTimeFilter'}
            timeFilter={timeFilters.registeredElementsTimeFilter}
            onUpdateTimeFilter={updateTimeFilter(
              'registeredElementsTimeFilter'
            )}
          />
          <OffersCard
            data={data.offers}
            loading={loadingModule === 'offersTimeFilter'}
            timeFilter={timeFilters.offersTimeFilter}
            statusFilter={offersStatusFilter}
            onUpdateTimeFilter={updateTimeFilter('offersTimeFilter')}
            onUpdateStatusFilter={setOffersStatusFilter}
          />
          <DeviationsCard
            data={data.deviationsUnderTreatment}
            loading={loadingModule === 'deviationsTimeFilter'}
            timeFilter={timeFilters.deviationsTimeFilter}
            onUpdateTimeFilter={updateTimeFilter('deviationsTimeFilter')}
          />
        </>
      ) : (
        <>
          <HoursCard
            data={data}
            loading={loadingModule === 'hoursTimeFilter'}
            timeFilter={timeFilters.hoursTimeFilter}
            onUpdateTimeFilter={updateTimeFilter('hoursTimeFilter')}
          />
          <ChecklistsCard
            data={data.ongoingChecklists}
            loading={loadingModule === 'checklistsTimeFilter'}
            timeFilter={timeFilters.checklistsTimeFilter}
            onUpdateTimeFilter={updateTimeFilter('checklistsTimeFilter')}
          />
          <DeviationsCard
            data={data.deviationsUnderTreatment}
            loading={loadingModule === 'deviationsTimeFilter'}
            timeFilter={timeFilters.deviationsTimeFilter}
            onUpdateTimeFilter={updateTimeFilter('deviationsTimeFilter')}
          />
        </>
      )}
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { Icon, Image } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ImageViewer } from 'components'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { checkIsImage, getFileIcon, getFileIconColor } from 'util/files'
import { useDateFns } from 'util/hooks'
import {
  RecentFile,
  RecentFilesQuery,
  RecentFilesQueryVariables,
  RECENT_FILES_QUERY,
} from '../graphql'

const Wrapper = styled.section`
  margin-bottom: 1.5rem;
  overflow: hidden;

  h2 {
    margin: 0 0 0.5rem 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  div.scroller-wrapper {
    position: relative;
  }
`
const Scroller = styled.div`
  display: flex;
  gap: 1.5rem;

  max-width: 100%;
  padding: 0.5rem;
  overflow-x: hidden;

  ${props => props.theme.media.mobile} {
    overflow-x: auto;
    gap: 1rem;
    padding-right: 4px;
  }
`
const FileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  min-width: 180px;
  width: 180px;
  max-width: 180px;
  padding: 1rem;
  overflow: hidden;

  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  user-select: none;

  span {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 0.8rem;
    text-align: center;

    &.name {
      font-weight: 600;
    }
    &.updated {
      color: ${props => props.theme.colors.gray3};
    }
  }

  ${props => props.theme.media.desktop} {
    cursor: pointer;
    transition: box-shadow ease-out 0.1s, transform linear 0.1s;

    &:hover {
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
      transform: scale(1.05);
    }
  }
  ${props => props.theme.media.mobile} {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
    padding: 1rem 0.5rem;

    span.updated {
      font-size: 0.6rem;
      white-space: pre-wrap;
    }
  }
`
interface ArrowProps {
  side: Side
}
const Arrow = styled.div<ArrowProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: ${props => (props.side === 'left' ? '-1px' : '100%')};

  display: flex;
  align-items: center;

  height: 100%;
  transform: ${props =>
    props.side === 'right' && 'translateX(calc(-100% + 1px))'};

  color: ${props => props.theme.colors.gray2};

  background: linear-gradient(
    to ${props => props.side},
    rgba(0, 0, 0, 0),
    white 50%
  );
  cursor: pointer;
  user-select: none;

  &:hover i {
    color: ${props => props.theme.colors.primaryHover};
  }
`

type Side = 'left' | 'right'

interface RecentFilesProps {
  projectId: string
}

export const RecentFiles: React.FC<RecentFilesProps> = ({ projectId }) => {
  const translations = useTranslate({
    recentFiles: 'files.recent-files',
  })

  const scrollerRef = useRef<HTMLDivElement>(null)

  const { formatDistanceToNow } = useDateFns()

  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [viewFile, setViewFile] = useState<string | null>(null)

  const { data, loading, error } = useQuery<
    RecentFilesQuery,
    RecentFilesQueryVariables
  >(RECENT_FILES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      projectId,
    },
    onCompleted() {
      updateScroll()
    },
  })

  const files = useMemo(
    () => data?.recentFiles.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const imageFiles = useMemo<Image[]>(
    () =>
      files.reduce<Image[]>(
        (acc, cur) =>
          !checkIsImage(cur.originalName)
            ? acc
            : [
                ...acc,
                {
                  id: cur.id,
                  file: cur.url,
                  name: cur.name,
                },
              ],
        []
      ),
    [files]
  )

  const updateScroll = useCallback((side?: Side) => {
    if (!scrollerRef.current || isMobile) return

    const { current: el } = scrollerRef
    const { scrollWidth, clientWidth, scrollLeft } = el

    if (scrollWidth <= clientWidth) {
      setShowLeftArrow(false)
      setShowRightArrow(false)
      return
    }

    setShowLeftArrow(scrollLeft > 0)
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth)

    if (!!side) {
      let newScrollLeft = scrollLeft + clientWidth * (side === 'left' ? -1 : 1)
      newScrollLeft = Math.max(Math.min(newScrollLeft, scrollWidth), 0)

      if (side === 'left' && newScrollLeft < 16) newScrollLeft = 0
      else if (
        side === 'right' &&
        newScrollLeft > scrollWidth - clientWidth - 16
      )
        newScrollLeft = scrollWidth

      el.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      })

      setShowLeftArrow(newScrollLeft > 0)
      setShowRightArrow(newScrollLeft + clientWidth < scrollWidth)
    }
  }, [])

  useLayoutEffect(() => {
    if (!scrollerRef.current || isMobile) return
    updateScroll()
  }, [updateScroll])

  function getUpdatedAtString(file: RecentFile) {
    const then = new Date(file.updatedAt)

    return formatDistanceToNow(then, {
      addSuffix: true,
      includeSeconds: false,
    })
  }

  function handleFileCardClick(file: RecentFile) {
    checkIsImage(file.originalName)
      ? setViewFile(file.id)
      : window.open(file.url, '_blank')
  }

  if (loading || !!error) return null

  return (
    <Wrapper>
      <ImageViewer
        popup
        open={viewFile !== null}
        images={imageFiles}
        initialImage={viewFile ?? 0}
        onClose={() => setViewFile(null)}
      />

      {!isMobileOnly && <h2>{translations.recentFiles}</h2>}

      <div className="scroller-wrapper">
        <Scroller ref={scrollerRef}>
          {files.map(file => (
            <FileCard key={file.id} onClick={() => handleFileCardClick(file)}>
              <Icon
                icon={getFileIcon(file.originalName)}
                color={getFileIconColor(file.originalName)}
                size="3rem"
              />

              <span className="name">{file.name}</span>
              <span className="updated">{getUpdatedAtString(file)}</span>
            </FileCard>
          ))}
        </Scroller>

        {showLeftArrow && (
          <Arrow side="left" onClick={() => updateScroll('left')}>
            <Icon icon="chevron-double-left" size="3rem" />
          </Arrow>
        )}
        {showRightArrow && (
          <Arrow side="right" onClick={() => updateScroll('right')}>
            <Icon icon="chevron-double-right" size="3rem" />
          </Arrow>
        )}
      </div>
    </Wrapper>
  )
}

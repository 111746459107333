import { useTranslate } from '@ur/react-hooks'
import { ActionButton, ActionButtonMenu } from 'components'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { EntryCard } from './components/EntryCard'
import { ShallowManualEntry } from './types.graphql'
import { useEntryUtils } from './util'

const Wrapper = styled.div``

interface EntriesMobileProps {
  entries: ShallowManualEntry[]
  slug?: string

  noShift?: boolean
  showPath?: boolean

  onCreateFolderClick: () => Promise<void>
  onResetSearch?: () => void
}

export const EntriesMobile: React.FC<EntriesMobileProps> = ({
  entries,

  slug,

  noShift = false,
  showPath = false,

  onCreateFolderClick,
  onResetSearch = () => void 0,
}) => {
  const translations = useTranslate({
    addFolder: 'handbook.create-folder',
    addEntry: 'handbook.create-entry',
  })

  const canCreateFolder = usePermission(
    PERMISSIONS.companymanuals.add.manualfolder
  )
  const canCreateEntry = usePermission(
    PERMISSIONS.companymanuals.add.manualentry
  )

  const history = useHistory()
  const { makeEditDeleteMenu, makeOrderArrows } = useEntryUtils(entries)

  const menu = useMemo<ActionButtonMenu | null>(
    () =>
      !slug
        ? null
        : {
            items: [
              {
                text: translations.addFolder,
                hide: !canCreateFolder,
                onClick: () => onCreateFolderClick(),
              },
              {
                text: translations.addEntry,
                hide: !canCreateEntry,
                onClick: () => history.push(`/handbook/${slug}/create-entry`),
              },
            ],

            openIcon: 'times',
            openBackground: 'gray3',
          },
    [
      canCreateEntry,
      canCreateFolder,
      history,
      onCreateFolderClick,
      slug,
      translations.addEntry,
      translations.addFolder,
    ]
  )

  return (
    <Wrapper>
      {!!menu && (canCreateFolder || canCreateEntry) && (
        <ActionButton menu={menu} iconProps={{ icon: 'plus' }} />
      )}

      {entries.map((entry, index) => (
        <EntryCard
          key={entry.id}
          entry={entry}
          editDeleteMenu={makeEditDeleteMenu(entry)}
          orderArrows={noShift ? null : makeOrderArrows(entry, index)}
          showPath={showPath}
          onResetSearch={onResetSearch}
        />
      ))}
    </Wrapper>
  )
}

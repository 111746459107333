import { PromptResolve } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { FilePicker, FilePickerOnChangeValue, RegularModal } from 'components'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${props => props.theme.media.desktop} {
    width: 350px;

    p {
      margin: 0 0 1rem;

      color: ${props => props.theme.colors.gray3};
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
`

export type UploadFileModalResolve = File[] | null

interface UploadFilesModalProps {
  onSubmit: PromptResolve<UploadFileModalResolve>
}

export const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  onSubmit,
}) => {
  const translations = useTranslate({
    uploadFiles: 'files.upload-files',
    upload: 'files.upload',
    hint: 'files.upload-files-modal-hint',
  })

  const [files, setFiles] = useState<File[]>([])

  function handleFilePickerChange(value: FilePickerOnChangeValue) {
    setFiles(value.new.map(file => file.file))
  }

  return (
    <RegularModal
      title={translations.uploadFiles}
      submitDisabled={!files.length}
      submitText={translations.upload}
      cancelOnEscape
      cancelOnClickOutside
      onCancel={() => onSubmit(null)}
      onSubmit={() => onSubmit(files)}
    >
      <Wrapper>
        {!isMobile && <p className="hint">{translations.hint}</p>}

        <FilePicker multiple onChange={handleFilePickerChange} />
      </Wrapper>
    </RegularModal>
  )
}

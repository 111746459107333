import { gql } from '@apollo/client'

export const CREATE_NEW_COMPANY_MUTATION = gql`
  mutation CreateNewCompany(
    $reCaptcha: String!
    # Company
    $shortName: String!
    $fullName: String!
    $billingEmail: String!
    $organizationNumber: String
    #User
    $username: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    createNewCompany(
      reCaptcha: $reCaptcha
      # Company
      shortName: $shortName
      fullName: $fullName
      billingEmail: $billingEmail
      organizationNumber: $organizationNumber
      # User
      username: $username
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      ok
      company {
        id
        shortName
      }
      token
    }
  }
`

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'

const Wrapper = styled.div``
const LinkRow = styled.div`
  a {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    width: calc(100% + 2rem);
    padding: 0.8rem 0;

    align-items: center;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    & + div {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }

    a {
      width: 100%;
      height: 4.5rem;

      padding: 0 1rem;
      background-color: white;
    }
  }
`

interface OtherProps {}

export const Other: React.FC<OtherProps> = () => {
  const translations = useTranslate({
    onlyAdministrators: 'companies.only-admins-project-leader',
    userTypes: 'users.user-types',
    deviationCategories: 'common.deviation-categories',
  })

  const canViewDeviationCategories = usePermission(
    PERMISSIONS.deviations.view.deviationcategory,
    PERMISSIONS.deviations.add.deviationcategory,
    PERMISSIONS.deviations.change.deviationcategory,
    PERMISSIONS.deviations.delete.deviationcategory
  )
  const canViewUserTypes = usePermission(
    PERMISSIONS.users.view.usertype,
    PERMISSIONS.users.change.usertype
  )

  if (isMobileOnly || (!canViewDeviationCategories && !canViewUserTypes))
    return null

  return (
    <Wrapper>
      <div className="divider" />

      <section>
        {canViewDeviationCategories && (
          <LinkRow>
            <Link to="/settings/company/user-types">
              <div>{translations.userTypes}</div>
              <Icon icon="external-link" size="1.1rem" type="solid" />
            </Link>
          </LinkRow>
        )}

        {canViewUserTypes && (
          <>
            <div className="divider" />

            <LinkRow>
              <Link to="/settings/company/deviation-categories">
                <div>{translations.deviationCategories}</div>
                <Icon icon="external-link" size="1.1rem" type="solid" />
              </Link>
            </LinkRow>
          </>
        )}
      </section>
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Icon, Tooltip } from '@ur/react-components'
import { useForm, useGlobal, useTranslate } from '@ur/react-hooks'
import {
  Button,
  CheckBox,
  ColorPicker,
  FormField,
  Input,
  Message,
  PageHeader,
} from 'components'
import {
  CreateUserTypeMutation,
  CreateUserTypeMutationVariables,
  PatchUserTypeMutation,
  PatchUserTypeMutationVariables,
  UserTypeQuery,
} from 'modules/users'
import {
  CREATE_USER_TYPE_MUTATION,
  PATCH_USER_TYPE_MUTATION,
} from 'modules/users/mutations'
import { USER_TYPE_QUERY } from 'modules/users/queries'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { randomHexColor } from 'util/style'
import { validateColor, validateNonEmpty } from 'util/validation'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding: 0 1rem;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 450px;

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    padding: 2rem 3rem;
  }

  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
    width: 100%;
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  position: relative;

  height: 40px;
  width: 100%;

  align-items: center;
  gap: 0.5rem;

  .info {
    transform: translate(0, 1px);
    cursor: pointer;
  }

  ${props => props.theme.media.mobile} {
    height: 56px;
  }
`

const SubmitButton = styled(Button)`
  ${props => props.theme.media.desktop} {
    margin-top: 1rem;
  }
`

export interface UserTypeForm {
  name: string
  isAdministrator: boolean
  color: string
}

interface AddEditUserTypeProps {}

export const AddEditUserType: React.FC<AddEditUserTypeProps> = () => {
  const translations = useTranslate({
    addUserType: 'users.add-user-type',
    editUserType: 'users.edit-user-type',

    cancel: 'common.cancel',
    edit: 'common.edit',
    save: 'common.save',

    title: 'common.title',
    allPermissions: 'users.all-permissions',
    color: 'common.color',

    allPermissionsHelpText: 'users.all-permissions-help-text',

    results: {
      createSuccess: 'users.toasts.user-type-create-success',
      editSuccess: 'users.toasts.user-type-update-success',
      queryError: 'server.general-error-try-again-later',
    },

    validation: {
      invalidColor: 'errors.invalid-color',
      required: 'common.required',
    },
  })

  const { userTypeId } = useParams<{ userTypeId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    enableValidation,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
    updateForm,
  } = useForm<UserTypeForm>({
    values: {
      name: '',
      isAdministrator: false,
      color: randomHexColor(),
    },
    validators: {
      name: validateNonEmpty(translations.validation.required),
      color: [
        validateNonEmpty(translations.validation.required),
        validateColor(translations.validation.invalidColor),
      ],
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['name'],
    },
  })

  const { loading: queryLoading, error } = useQuery<UserTypeQuery, IdVariable>(
    USER_TYPE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !userTypeId,
      variables: {
        id: userTypeId,
      },
      onCompleted({ userType }) {
        const { id, permissions, ...rest } = userType

        setOverrides(v => ({
          ...v,
          [userTypeId]: rest.name,
        }))

        const values: UserTypeForm = {
          name: rest.name,
          color: rest.color ?? randomHexColor(),
          isAdministrator: rest.isAdministrator,
        }

        updateForm(values)
        updateInitialValues(values)
        enableValidation('name', true, false)
      },
      onError: onErrorAuto(),
    }
  )

  const [createUserType, { loading: createLoading }] = useMutation<
    CreateUserTypeMutation,
    CreateUserTypeMutationVariables
  >(CREATE_USER_TYPE_MUTATION, {
    onCompleted() {
      addToast('success', translations.results.createSuccess)
      history.push('/settings/company/user-types')
    },
    onError: onErrorAuto(),
  })

  const [patchUserType, { loading: patchLoading }] = useMutation<
    PatchUserTypeMutation,
    PatchUserTypeMutationVariables
  >(PATCH_USER_TYPE_MUTATION, {
    onCompleted() {
      addToast('success', translations.results.editSuccess)
      history.push('/settings/company/user-types')
    },
    onError: onErrorAuto(),
  })

  function handleSubmit(values: typeof form) {
    if (userTypeId) {
      patchUserType({
        variables: { id: userTypeId, input: values },
      })
      return
    }

    createUserType({
      variables: {
        input: values,
      },
    })
  }

  const loading = queryLoading || createLoading || patchLoading

  if (error)
    return <Message.Error text={translations.results.queryError} show />

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader
          title={
            userTypeId ? translations.editUserType : translations.addUserType
          }
          loading={loading}
          largeMargin
        />
      )}

      <Card>
        <FormField error={!!errors.name} required>
          <label>{translations.title}</label>

          <Input
            value={form.name}
            fullWidth
            error={errors.name}
            disabled={loading}
            onChange={handler('name')}
            onBlur={() => enableValidation('name')}
          />
        </FormField>

        <FormField error={!!errors.color} required>
          <label>{translations.color}</label>

          <ColorPicker
            value={form.color ?? ''}
            fullWidth
            onChange={handler('color')}
          />
        </FormField>

        <FormField error={!!errors.isAdministrator}>
          <CheckboxWrapper>
            <CheckBox
              checked={form.isAdministrator}
              label={translations.allPermissions}
              disabled={loading}
              color="gray2"
              onChange={handler('isAdministrator')}
            />

            <div>
              <Icon
                className="info"
                icon="info-circle"
                type="solid"
                color="gray4"
              />

              <Tooltip placement="top-left">
                {translations.allPermissionsHelpText}
              </Tooltip>
            </div>
          </CheckboxWrapper>
        </FormField>

        <SubmitButton
          fullWidth
          disabled={!formValid || !formEdited || loading}
          loading={loading}
          onClick={submit(handleSubmit)}
        >
          {userTypeId ? translations.save : translations.addUserType}
        </SubmitButton>
      </Card>
    </Wrapper>
  )
}

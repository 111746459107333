import merge from 'lodash/merge'
import React, { useMemo, useReducer, useState } from 'react'
import styled from 'styled-components'
import { getDefaultTableOptions } from './consts'
import { Cell } from './TableCell'
import {
  TableCell,
  TableColumn,
  TableFiltering,
  TableOptions,
  TableRow,
  TableSorting,
} from './types'
import { tableSortingReducer } from './util'

interface WrapperProps {
  columns: TableColumn[]
}
const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: ${props =>
    props.columns
      .map(column => (column.shrink ? 'auto' : column.width ?? '1fr'))
      .join(' ')};

  div.--table-no-rows {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
  }
`

interface TableProps {
  className?: string

  columns: TableColumn[]
  rows: TableRow[]
  options?: TableOptions

  /** Initial sorting of table */
  initialSort?: string
  /** Text to deisplay if there's no rows in table */
  noRowsText?: string | JSX.Element

  noHeader?: boolean

  onSortingChange?: (value: string) => void
  onFilterChange?: (value: TableFiltering) => void
}

export const Table: React.FC<TableProps> = ({
  className,
  columns,
  rows,
  options = {},

  initialSort,
  noRowsText,
  noHeader = false,

  onSortingChange,
  onFilterChange,
}) => {
  const [hovering, setHovering] = useState(-1)

  const [sorting, dispatchSorting] = useReducer(
    tableSortingReducer(onSortingChange),
    !!initialSort ? initialSort.split(',') : []
  )

  const defaultTableOptions = useMemo(
    () =>
      getDefaultTableOptions({
        fillHorizontal: options.fillHorizontal ?? false,
      }),
    [options.fillHorizontal]
  )

  const mergedStyle = useMemo(
    () => merge(defaultTableOptions.style ?? {}, options.style ?? {}),
    [defaultTableOptions.style, options.style]
  )

  const noRowHover = useMemo(
    () =>
      (options.rowHoverBackground ?? defaultTableOptions.rowHoverBackground) ===
      null,
    [defaultTableOptions.rowHoverBackground, options.rowHoverBackground]
  )

  const relevantColumns = useMemo(
    () => columns.filter(col => !col.skip),
    [columns]
  )

  const cells = useMemo<TableCell[]>(() => {
    const headers: TableCell[] = noHeader
      ? []
      : relevantColumns.map((column, idx) => ({
          id: column.id,
          columnId: column.id,
          content: column.label,
          menu: null,

          rowIndex: 1,
          cellIndex: idx,
          isHeader: true,
          isFirstCell: idx === 0,
          isLastCell: idx === relevantColumns.length - 1,
          isLastRow: !rows.length,

          sortable: !!column.sortable,
          filtering: column.filtering ?? null,

          alignVertical: column.alignVertical ?? 'end',
          alignHorizontal: column.alignHorizontal ?? 'start',
          hoverBackground: null,
        }))

    const data: TableCell[] = rows
      .map((row, rowIdx) => {
        const cells = Object.entries(row).filter(
          ([key]) => !columns.find(col => col.id === key)?.skip
        )
        const finished: TableCell[] = []

        for (let c = 0; c < relevantColumns.length; c++) {
          const found = cells.find(([id]) => id === relevantColumns[c].id)
          if (!found) {
            console.error(
              `Table: cell for column with id "${relevantColumns[c].id}" not found`
            )
            continue
          }

          const [id, cell] = found

          finished.push({
            id: `${id}${rowIdx}`,
            columnId: id,
            content: cell.content,
            menu: cell.menu ?? null,

            rowIndex: rowIdx + 2,
            cellIndex: c,
            isHeader: false,
            isFirstCell: c === 0,
            isLastCell: c === cells.length - 1,
            isLastRow: rowIdx === rows.length - 1,

            sortable: false,
            filtering: null,

            alignVertical: cell.alignVertical ?? 'center',
            alignHorizontal: cell.alignHorizontal ?? 'start',
            hoverBackground:
              options.rowHoverBackground ??
              defaultTableOptions.rowHoverBackground ??
              null,
          })
        }

        return finished
      })
      .flat()

    return [...headers, ...data]
  }, [
    relevantColumns,
    rows,
    noHeader,
    columns,
    options.rowHoverBackground,
    defaultTableOptions.rowHoverBackground,
  ])

  function handleSortingChange(id: string) {
    return (sort: TableSorting) => {
      setTimeout(
        () => dispatchSorting({ sort, id, singleSort: !!options.singleSort }),
        1
      )
    }
  }

  return (
    <Wrapper
      className={className}
      role="table"
      style={mergedStyle}
      columns={relevantColumns}
    >
      {cells.map(cell => (
        <Cell
          key={cell.id}
          cell={cell}
          options={options}
          hovering={hovering === cell.rowIndex}
          sorting={cell.isHeader ? sorting : null}
          onSortingChange={
            cell.isHeader ? handleSortingChange(cell.id) : () => void 0
          }
          onFilterChange={
            cell.isHeader ? onFilterChange ?? (() => void 0) : () => void 0
          }
          onMouseEnter={
            noRowHover ? undefined : cell => setHovering(cell.rowIndex)
          }
          onMouseLeave={noRowHover ? undefined : () => setHovering(-1)}
        />
      ))}

      {!rows.length && <div className="--table-no-rows">{noRowsText}</div>}
    </Wrapper>
  )
}

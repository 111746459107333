import gql from 'graphql-tag'

export const CUSTOMER_QUERY = gql`
  query Customer($id: ID!) {
    customer(id: $id) {
      id
      name
      organizationNumber
      address
      postalCode
      postalArea
      billingEmail
      defaultHourlyRate
      defaultMaterialDiscount
      contactPersonName
      contactPersonEmail
      contactPersonPhone
      projects {
        edges {
          node {
            id
            name
            stage
          }
        }
      }
    }
  }
`

export const CUSTOMERS_SHALLOW_QUERY = gql`
  query CustomersShallow(
    $q: String
    $orderBy: String
    $first: Int
    $after: String
  ) {
    allCustomers(q: $q, orderBy: $orderBy, first: $first, after: $after) {
      edges {
        node {
          id
          name
          contactPersonName
          contactPersonEmail
          contactPersonPhone
          customerType
          address
          postalCode
          postalArea
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const SELECT_CUSTOMER_QUERY = gql`
  query SelectCustomer(
    $q: String
    $orderBy: String
    $includeIds: [ID]
    $first: Int
    $after: String
  ) {
    allCustomers(
      q: $q
      orderBy: $orderBy
      includeIds: $includeIds
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          name
          defaultHourlyRate
        }
      }
    }
  }
`

export const CUSTOMER_PROJECTS_QUERY = gql`
  query CustomerProjects($id: ID!) {
    allProjects(customer: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const CUSTOMER_EDIT_QUERY = gql`
  query CustomerEdit($id: ID!) {
    customer(id: $id) {
      id
      name
      customerNumber
      customerType
      organizationNumber
      contactPersonName
      contactPersonPhone
      contactPersonEmail
      address
      postalCode
      postalArea
      billingEmail
      defaultHourlyRate
      defaultMaterialDiscount
    }
  }
`

import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader,
  Table,
  TableOptions,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { PERMISSIONS } from 'util/permissions'
import { ProductsMobile } from './ProductsMobile'
import { useProductsUtils } from './util/useProductsUtils'

const Wrapper = styled.div``

const PAGE_SIZE = 25

interface ProductsTabProps {
  isImported?: boolean
}

export const ProductsTab: React.FC<ProductsTabProps> = ({
  isImported = false,
}) => {
  const translations = useTranslate({
    searchForProduct: 'products.search-for-products',
    importProduct: 'products.import-products',
    createProduct: 'products.create-product',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([])

  const {
    products,
    columns,
    rows,

    merchantFiltering,
    makeEditDeleteMenu,

    error,
    loading,
    fetchMoreLoading,
    hasMore,

    handleCreateProduct,
    handleFetchMore,
    handleImport,
  } = useProductsUtils({
    isImported,
    queryVariables: {
      first: PAGE_SIZE,
      orderBy: sort,
      q: debouncedSearch,
      merchant: selectedMerchants,
      isImported,
    },
    onQueryCompleted: () => setHasLoadedOnce(true),
  })

  const tableOptions = useMemo<TableOptions>(
    () => ({
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          fontWeight: cell.columnId === 'name' ? 600 : 'inherit',
        },
      }),
    }),
    []
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <EmptyPage
      module={isImported ? 'importedProducts' : 'products'}
      isTabView
      empty={!products.length && !debouncedSearch && hasLoadedOnce}
      loading={!hasLoadedOnce}
      buttonPermissions={PERMISSIONS.products.add.product}
      onButtonClick={() =>
        isImported ? handleImport() : handleCreateProduct()
      }
    >
      <Wrapper>
        {isMobileOnly ? (
          <ProductsMobile
            products={products}
            isImport={isImported}
            search={search}
            filter={merchantFiltering}
            makeEditDeleteMenu={makeEditDeleteMenu}
            onSearchChange={setSearch}
            onFilterChange={value => setSelectedMerchants(value.checked)}
            onCreateClick={() =>
              isImported ? handleImport() : handleCreateProduct()
            }
          />
        ) : (
          <>
            <PageHeader
              loading={loading}
              search={{
                value: search,
                placeholder: translations.searchForProduct,
                left: true,
                onChange: setSearch,
              }}
              tabMargin
              buttons={[
                {
                  text: isImported
                    ? translations.importProduct
                    : translations.createProduct,
                  icon: { icon: isImported ? 'upload' : 'plus', type: 'solid' },
                  permissions: PERMISSIONS.products.add.product,
                  onClick: () =>
                    isImported ? handleImport() : handleCreateProduct(),
                },
              ]}
            />

            <Table
              columns={columns}
              rows={rows}
              noRowsText={
                !!debouncedSearch ? translations.noResults : translations.noData
              }
              options={tableOptions}
              onFilterChange={value => setSelectedMerchants(value.checked)}
              onSortingChange={setSort}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

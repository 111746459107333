import { gql } from '@apollo/client'

export const BOOTSTRAP_QUERY = gql`
  query Bootstrap {
    me {
      id
      firstName
      lastName
      fullName
      profilePicture
      profilePicture256
      address
      postalCode
      city
      phoneNumber
      initials
      username
      email
      needsPasswordChange
      hasRegistered
      isStaff
      isSuperuser
      isActive
      timebankTotal
      timebankWithdrawal
      permissions
      emergencyContacts {
        edges {
          node {
            id
            name
            relation
            phoneNumber
            primary
          }
        }
      }
      settings {
        edges {
          node {
            id
            key
            value
          }
        }
      }
      userTypes {
        id
        name
        color
        isAdministrator
        permissions
      }
      companies {
        edges {
          node {
            id
            shortName
            fullName
          }
        }
      }
      permissions
    }
    currentCompany {
      id
      shortName
      fullName
      address
      postalCode
      postalArea
      phoneNumber
      email
      companyLogo
      defaultHourlyRate
      settings {
        id
        key
        value
      }
      folderSettings {
        edges {
          node {
            id
            folderName
            enabled
          }
        }
      }
    }
  }
`

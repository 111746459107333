import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField as BaseFormField, Input, TextArea } from 'components'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { validateNonEmpty } from 'util/validation'
import {
  CreateManualFolderMutationVariables,
  PatchManualFolderMutationVariables,
  ShallowManualFolder,
} from './types.graphql'

const Wrapper = styled.div``
const FormField = styled(BaseFormField)`
  margin-bottom: 1rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

interface ManualFolderData {
  name: string
  description: string
}

interface ManualFolderFormProps {
  manualFolder?: ShallowManualFolder
  loading: boolean

  onSubmit: (
    form:
      | CreateManualFolderMutationVariables
      | PatchManualFolderMutationVariables
  ) => void
  onCancel: () => void
}

export const ManualFolderForm: React.FC<ManualFolderFormProps> = ({
  manualFolder,
  loading,

  onSubmit,
  onCancel,
}) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    name: 'common.name',
    description: 'common.description',

    add: 'common.add',
    save: 'common.save',

    validation: {
      required: 'common.required',
    },
  })

  const manualFolderInserted = useRef(false)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<ManualFolderData>({
    values: {
      name: '',
      description: '',
    },
    validators: {
      name: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  useEffect(() => {
    if (!manualFolder || manualFolderInserted.current) return
    manualFolderInserted.current = true

    const values: typeof form = {
      name: manualFolder.name,
      description: manualFolder.description,
    }

    updateForm(values)
    updateInitialValues(values)
  }, [manualFolder, updateForm, updateInitialValues])

  function handleSubmit(values: typeof form) {
    if (manualFolder?.id) {
      onSubmit({ id: manualFolder.id, input: values })
    } else
      onSubmit({
        input: values,
      })
  }

  const disableSubmitButton = loading || !formValid || !formEdited

  return (
    <Wrapper>
      <FormField required>
        <label>{translations.name}</label>

        <Input
          value={form.name}
          error={errors.name}
          fullWidth
          autoFocus
          onChange={handler('name')}
        />
      </FormField>

      <FormField>
        <label>{translations.description}</label>

        <TextArea
          value={form.description}
          error={errors.description}
          fullWidth
          onChange={handler('description')}
        />
      </FormField>

      <ButtonWrapper>
        <Button fullWidth variant="cancel" onClick={onCancel}>
          {translations.cancel}
        </Button>

        <Button
          disabled={disableSubmitButton}
          fullWidth
          onClick={submit(handleSubmit)}
        >
          {manualFolderInserted.current ? translations.save : translations.add}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'
import { TableTimeEntry } from '../types.graphql'

const Wrapper = styled.aside`
  position: absolute;
  top: calc(100% + 6px);
  left: -1rem;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 15px;

  min-width: 15rem;
  padding: 1rem;

  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  & > div {
    display: flex;
    gap: 8px;
  }

  ${props => props.theme.media.mobile} {
    top: 100%;
    left: -1px;
    right: -1px;
    min-width: 0;

    border-radius: 0;
    border: solid ${props => props.theme.colors.gray6};
    border-width: 0 1px 1px;
    box-shadow: 0 4px 15px -8px rgba(0, 0, 0, 0.2);
  }
`
const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

interface HoursDropdownProps {
  timesheet: TableTimeEntry
}

export const HoursDropdown: React.FC<HoursDropdownProps> = ({ timesheet }) => {
  const translations = useTranslate({
    ordinary: 'timesheets.hours-ordinary',
    overtimeFifty: 'timesheets.hours-overtime-fifty',
    overtimeHundred: 'timesheets.hours-overtime-hundred',
    timebankOrdinary: 'timesheets.hours-time-bank-ordinary',
    timebankFifty: 'timesheets.hours-time-bank-fifty',
    timebankHundred: 'timesheets.hours-time-bank-hundred',
  })

  return (
    <Wrapper>
      {parseFloat(timesheet.hoursOrdinary) > 0 && (
        <TimeWrapper>
          <div>{translations.ordinary}</div>
          <div>{timesheet.hoursOrdinary}</div>
        </TimeWrapper>
      )}

      {parseFloat(timesheet.hoursOvertimeFifty) > 0 && (
        <TimeWrapper>
          <div>{translations.overtimeFifty}</div>
          <div>{timesheet.hoursOvertimeFifty}</div>
        </TimeWrapper>
      )}

      {parseFloat(timesheet.hoursOvertimeHundred) > 0 && (
        <TimeWrapper>
          <div>{translations.overtimeHundred}</div>
          <div>{timesheet.hoursOvertimeHundred}</div>
        </TimeWrapper>
      )}

      {parseFloat(timesheet.hoursTimeBankOrdinary) > 0 && (
        <TimeWrapper>
          <div>{translations.timebankOrdinary}</div>
          <div>{timesheet.hoursTimeBankOrdinary}</div>
        </TimeWrapper>
      )}

      {parseFloat(timesheet.hoursTimeBankFifty) > 0 && (
        <TimeWrapper>
          <div>{translations.timebankFifty}</div>
          <div>{timesheet.hoursTimeBankFifty}</div>
        </TimeWrapper>
      )}

      {parseFloat(timesheet.hoursTimeBankHundred) > 0 && (
        <TimeWrapper>
          <div>{translations.timebankHundred}</div>
          <div>{timesheet.hoursTimeBankHundred}</div>
        </TimeWrapper>
      )}
    </Wrapper>
  )
}

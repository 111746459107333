import { useQuery } from '@apollo/client'
import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { SelectMultiple, SelectMultipleProps } from 'components'
import { ShallowUserType, UserTypesShallowQuery } from 'modules/users'
import { USER_TYPES_SHALLOW_QUERY } from 'modules/users/queries'
import React, { useMemo } from 'react'
import { useOnErrorAuto } from 'util/hooks'

interface UserTypeSelectMultipleProps
  extends Omit<SelectMultipleProps<string, ShallowUserType>, 'options'> {}

export const UserTypeSelectMultiple: React.FC<UserTypeSelectMultipleProps> = ({
  ...props
}) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-placeholder-user-types',
  })

  const onErrorAuto = useOnErrorAuto()

  const { data } = useQuery<UserTypesShallowQuery, never>(
    USER_TYPES_SHALLOW_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: onErrorAuto(),
    }
  )

  const userTypes = useMemo<SelectOption<string, ShallowUserType>[]>(
    () =>
      data?.allUserTypes.edges.map(({ node }) => ({
        value: node.id,
        label: node.name,
        extra: node,
      })) ?? [],
    [data]
  )

  return (
    <SelectMultiple options={userTypes} placeholder={placeholder} {...props} />
  )
}

import CreateProject from './CreateProject.png'
import DashboardProjectManager from './DashboardProjectManager.png'
import DashboardStandard from './DashboardStandard.png'
import Handbook from './Handbook.png'
import Project from './Project.png'
import ProjectTable from './ProjectTable.png'

export const images = {
  CreateProject,
  DashboardProjectManager,
  DashboardStandard,
  Handbook,
  Project,
  ProjectTable,
}

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

export interface MixpanelTrackingProviderProps {
  children: React.ReactNode
}

export default function MixpanelTrackingProvider({
  children,
}: MixpanelTrackingProviderProps) {
  const history = useHistory()

  useEffect(() => {
    return history.listen(location => {
      mixpanel.track_pageview({
        path: location.pathname,
      })
    })
  }, [history])

  return <>{children}</>
}

import { useTranslate } from '@ur/react-hooks'
import { TabbedPage } from 'components/TabbedPage'
import { Route, Switch } from 'containers/Routing'
import React from 'react'
import { InternalDeviations, ProjectDeviations } from '.'

interface DeviationsProps {}

export const Deviations: React.FC<DeviationsProps> = () => {
  const translations = useTranslate({
    projectDeviations: 'deviations.project-deviations',
    internalDeviations: 'deviations.internal-deviations',
  })

  return (
    <TabbedPage
      tabs={[
        {
          href: '/deviations',
          text: translations.projectDeviations,
        },
        {
          href: '/deviations/internal',
          text: translations.internalDeviations,
        },
      ]}
    >
      <Switch>
        <Route exact path="/deviations" component={ProjectDeviations} />

        <Route
          exact
          path="/deviations/internal"
          component={InternalDeviations}
        />
      </Switch>
    </TabbedPage>
  )
}

import { Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, Select } from 'components'
import React from 'react'
import styled, { css } from 'styled-components'

const CARD_FULL_HEIGHT = '23rem'
const CARD_HEADER_HEIGHT = '60px'
const CARD_FOOTER_HEIGHT = '50px'
const CARD_ITEM_HEIGHT = '60px'

interface CardProps {
  hasFooterButton?: boolean
}
export const Card = styled.div<CardProps>`
  display: grid;
  grid-template-rows: ${CARD_HEADER_HEIGHT} 1fr ${CARD_FOOTER_HEIGHT};

  width: calc((100% - 4rem) / 3);
  min-height: ${CARD_FULL_HEIGHT};
  height: ${CARD_FULL_HEIGHT};
  max-height: ${CARD_FULL_HEIGHT};

  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  & > header {
    position: relative;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    width: 100%;
    height: 100%;
    padding: 0 24px;

    border-bottom: 2px solid #f6f8ff;

    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    .filter {
      position: absolute;
      right: 24px;
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }
  & > footer {
    width: 100%;
    height: ${CARD_FOOTER_HEIGHT};
    min-height: ${CARD_FOOTER_HEIGHT};
    max-height: ${CARD_FOOTER_HEIGHT};
    border-top: 2px solid #f6f8ff;

    ${props =>
      props.hasFooterButton &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: fit-content;
    height: fit-content;
    margin: 1rem 0;

    border-radius: 0;

    & > header {
      flex: 0 0 ${CARD_HEADER_HEIGHT};
    }
    & > footer {
      flex: 0 0 ${CARD_FOOTER_HEIGHT};
    }
  }
`

interface CardHeaderProps {
  text: string
  loading: boolean
  filter?: JSX.Element
}
export const CardHeader: React.FC<CardHeaderProps> = ({
  text,
  loading,
  filter,
}) => {
  return (
    <header>
      {text}

      {loading && <Loader.Spinner size={24} />}

      {filter}
    </header>
  )
}

export const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(23rem - ${CARD_HEADER_HEIGHT} - ${CARD_FOOTER_HEIGHT});
  min-height: calc(23rem - ${CARD_HEADER_HEIGHT} - ${CARD_FOOTER_HEIGHT});
  max-height: calc(23rem - ${CARD_HEADER_HEIGHT} - ${CARD_FOOTER_HEIGHT});
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  div.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${CARD_ITEM_HEIGHT};

    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.colors.gray3};
  }

  ${props => props.theme.media.mobile} {
    flex: 1 0 auto;
    min-height: ${CARD_ITEM_HEIGHT};
    max-height: calc(
      ${CARD_FULL_HEIGHT} - ${CARD_HEADER_HEIGHT} - ${CARD_FOOTER_HEIGHT}
    );
  }
`

export const CardItem = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 100%;
  height: ${CARD_ITEM_HEIGHT};
  min-height: ${CARD_ITEM_HEIGHT};
  max-height: ${CARD_ITEM_HEIGHT};
  overflow: hidden;

  border-bottom: 1px solid ${props => props.theme.colors.gray6};
`

export const Content = styled.div<{ hasStatus?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 4px;

  min-width: calc(100% - ${props => (props.hasStatus ? '140px' : '40px')});
  width: calc(100% - ${props => (props.hasStatus ? '140px' : '40px')});
  max-width: calc(100% - ${props => (props.hasStatus ? '140px' : '40px')});

  padding: 12px 24px;
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Project = styled.div`
  text-overflow: ellipsis;
  font-size: 12px;
  max-width: 250px;

  span.customer {
    margin-left: 0.5ch;
    color: ${props => props.theme.colors.gray3};
  }
`

export const NoData: React.FC = () => {
  const { noData } = useTranslate({
    noData: 'common.no-data',
  })

  return <div className="no-data">{noData}</div>
}

export const FooterSelect = styled(Select)`
  cursor: pointer;

  .--select-display-inner {
    color: ${props => props.theme.colors.gray1};
    font-weight: 600;
    font-size: 14px;
  }
  .--select-arrow-icon {
    color: ${props => props.theme.colors.gray1};
  }
  .--select-curtain-option {
    font-weight: 600;
    color: ${props => props.theme.colors.gray1};

    font-size: 14px;
  }
`
export const FooterButton = styled(Button)`
  height: 100%;

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;

  ${props => props.theme.media.mobile} {
    border-bottom-right-radius: 0px;
  }
`

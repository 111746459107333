import { Loader } from '@ur/react-components'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'

interface CenteredProps {
  top: string
  bottom: string
  page: boolean
  alpha: number
}
const Centered = styled.div<CenteredProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: ${props => props.top};
  margin-bottom: ${props => props.bottom};

  ${props =>
    props.page &&
    css`
      position: fixed;
      z-index: ${ZIndexRange.Overlays};
      top: 0;
      left: 0;

      width: 100vw;
      height: 100vh;
      margin-top: 0;
      margin-bottom: 0;

      background-color: rgba(255, 255, 255, ${props.alpha});
    `};
`

interface CenteredLoaderProps {
  top?: string
  bottom?: string
  page?: boolean
  alpha?: number
}
export const CenteredLoader: React.FC<CenteredLoaderProps> = ({
  top = '6rem',
  bottom = '6rem',
  page = false,
  alpha = 0.8,
}) => {
  return (
    <Centered top={top} bottom={bottom} page={page} alpha={alpha}>
      <Loader.Spinner size={68} thickness={6} />
    </Centered>
  )
}

import { gql } from '@apollo/client'

// Folders

export const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      folder {
        id
      }
    }
  }
`

export const PATCH_FOLDER_MUTATION = gql`
  mutation PatchFolder($id: ID!, $input: PatchFolderInput!) {
    patchFolder(id: $id, input: $input) {
      folder {
        id
      }
    }
  }
`

export const DELETE_FOLDER_MUTATION = gql`
  mutation DeleteFolder($id: ID!) {
    deleteFolder(id: $id) {
      found
      deletedId
    }
  }
`

// Files

export const BATCH_CREATE_FILE_MUTATION = gql`
  mutation BatchCreateFile($input: [BatchCreateFileInput]!) {
    batchCreateFile(input: $input) {
      files {
        id
      }
    }
  }
`

export const PATCH_FILE_MUTATION = gql`
  mutation PatchFile($id: ID!, $input: PatchFileInput!) {
    patchFile(id: $id, input: $input) {
      file {
        id
      }
    }
  }
`

export const DELETE_FILE_MUTATION = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      found
      deletedId
    }
  }
`

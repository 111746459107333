import { useAdmin, useUser } from '.'

export function usePermission(...permissions: (string | RegExp)[]) {
  const user = useUser()
  if (useAdmin()) return true

  const permitted = permissions.reduce(
    (acc, cur) =>
      acc &&
      (cur instanceof RegExp
        ? user.permissions.some(p => cur.test(p))
        : user.permissions.includes(cur)),
    true
  )
  return permitted
}

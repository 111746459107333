import React from 'react'
import styled from 'styled-components'
import logo from 'assets/images/logo.svg'

const LogoWrapper = styled.img<LogoProps>`
  max-width: ${props => props.logoWidth};
  max-height: ${props => props.logoHeight};
`

interface LogoProps {
  logoHeight?: string
  logoWidth?: string
}
export const Logo: React.FC<LogoProps> = ({
  logoHeight = '100%',
  logoWidth = '100%',
}) => {
  return (
    <LogoWrapper src={logo} logoHeight={logoHeight} logoWidth={logoWidth} />
  )
}

import { useDebounce, useTranslate } from '@ur/react-hooks'
import { ActionButton, Input, TableMenu } from 'components'
import { TableUser } from 'modules/users/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { UserCard } from './UserCard'

const Wrapper = styled.div`
  div.no-users {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`

const Toolbar = styled.div`
  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

interface UsersMobileProps {
  users: TableUser[]
  search: string

  makeEditDeleteMenu: (customerId: string) => TableMenu

  onSearchChange: (value: string) => void
  onCreateUser: () => void
}

export const UsersMobile: React.FC<UsersMobileProps> = ({
  users,
  search,

  makeEditDeleteMenu,

  onSearchChange,
  onCreateUser,
}) => {
  const debouncedSearch = useDebounce(search)

  const translations = useTranslate({
    createUser: 'users.create-user',
    searchForUser: 'users.search',

    print: 'common.print',
    send: 'common.send-to-email',

    noData: 'common.no-data',
    noResults: 'common.no-results',
  })

  const canCreateUser = usePermission(PERMISSIONS.users.add.user)

  return (
    <Wrapper>
      {canCreateUser && <ActionButton onClick={onCreateUser} />}

      <Toolbar>
        <Input
          value={search}
          placeholder={translations.searchForUser}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          width="100%"
          onChange={onSearchChange}
        />
      </Toolbar>

      {users.map(user => (
        <UserCard
          key={user.id}
          user={user}
          editDeleteMenu={makeEditDeleteMenu(user.id)}
        />
      ))}

      {!users.length && (
        <div className="no-users">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Icon, Loader } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Message,
  PageHeader,
  Table,
  TableColumn,
  TableOptions,
  TableRow,
} from 'components'
import {
  PermissionsQuery,
  ToggleUserTypePermissionMutation,
  ToggleUserTypePermissionMutationVariables,
  UserTypesQuery,
} from 'modules/users'
import { TOGGLE_USER_TYPE_PERMISSION_MUTATION } from 'modules/users/mutations'
import { PERMISSIONS_QUERY, USER_TYPES_QUERY } from 'modules/users/queries'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { UserTypesMobile } from './mobile/UserTypesMobile'
import { SpecialUserTypePermissions } from './SpecialUserTypePermissions'
import {
  Module,
  PermissionRow,
  PermissionsLoading,
  PermissionStatus,
  Segment,
} from './types'
import { useFilterPermissions, useTranslatePermission } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const AdminWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;

  p {
    margin: 0;
  }
`

interface CompanySettingsUserTypesProps {}

export const CompanySettingsUserTypes: React.FC<
  CompanySettingsUserTypesProps
> = () => {
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const { translateModule, translateSegment } = useTranslatePermission()

  const [selectedUserTypeId, setSelectedUserTypeId] = useState<string | null>(
    null
  )
  const [permissionsLoading, setPermissionsLoading] =
    useState<PermissionsLoading>({})

  const translations = useTranslate({
    loading: 'common.loading',
    userTypes: 'users.user-types',
    createUserType: 'users.create-user-type',

    administratorWarning: 'users.administrator-warning',

    segment: 'common.segment',
    module: 'common.module',
    view: 'common.view',
    add: 'common.add',
    edit: 'common.edit',
    delete: 'common.delete',

    results: {
      queryError: 'server.general-error-try-again-later',

      noData: 'common.no-data',
    },
  })

  const {
    data: userTypesQueryData,
    loading: userTypesQueryLoading,
    error: userTypesQueryError,
  } = useQuery<UserTypesQuery>(USER_TYPES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      !selectedUserTypeId &&
        setSelectedUserTypeId(data.allUserTypes.edges[0].node.id)
    },
    onError: onErrorAuto(),
  })

  const {
    data: permissionsQueryData,
    loading: permissionsQueryLoading,
    error: permissionsQueryError,
  } = useQuery<PermissionsQuery>(PERMISSIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const [
    toggleUserTypePermission,
    { loading: toggleUserTypePermissionLoading },
  ] = useMutation<
    ToggleUserTypePermissionMutation,
    ToggleUserTypePermissionMutationVariables
  >(TOGGLE_USER_TYPE_PERMISSION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['UserTypes'],
    onError: onErrorAuto(),
  })

  const allPermissions = useMemo(
    () =>
      permissionsQueryData?.allPermissions.edges.map(edge => edge.node) ?? [],
    [permissionsQueryData]
  )
  const allUserTypes = useMemo(
    () => userTypesQueryData?.allUserTypes.edges.map(edge => edge.node) ?? [],
    [userTypesQueryData]
  )
  const selectedUserType = useMemo(
    () =>
      allUserTypes.find(userType => userType.id === selectedUserTypeId) ?? null,
    [selectedUserTypeId, allUserTypes]
  )

  const filterPermissions = useFilterPermissions(selectedUserType)
  const filteredPermissions = useMemo(
    () => filterPermissions(allPermissions),
    [filterPermissions, allPermissions]
  )

  const handleTogglePermission = useCallback(
    (permission: Pick<PermissionStatus, 'id' | 'checked'>) => {
      if (!selectedUserTypeId) return

      setPermissionsLoading(v => ({
        ...v,
        [permission.id]: true,
      }))

      toggleUserTypePermission({
        variables: {
          userType: selectedUserTypeId,
          permission: permission.id,
          enabled: !permission.checked,
        },
      }).finally(() =>
        setPermissionsLoading(v => ({
          ...v,
          [permission.id]: false,
        }))
      )
    },
    [selectedUserTypeId, toggleUserTypePermission]
  )

  const userTypePermissions = useMemo<PermissionRow[]>(
    () =>
      Object.entries(filteredPermissions)
        .map(([module, models]) => {
          const obj = Object.entries(models).map(([segment, statuses]) => {
            const permissions = statuses.map(status => {
              if (!status) return <div />

              if (permissionsLoading[status.id])
                return <Loader.Spinner size={24} thickness={4} />

              return (
                <Icon
                  key={status.id}
                  icon="check-circle"
                  type="solid"
                  size="24px"
                  color={status.checked ? 'matteGreen' : 'gray4'}
                  cursor="pointer"
                  onClick={() => handleTogglePermission(status)}
                />
              )
            })

            return { segment, module, permissions }
          })

          return obj
        })
        .flat(),
    [handleTogglePermission, permissionsLoading, filteredPermissions]
  )

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'module',
        label: translations.module,
        shrink: true,
      },
      {
        id: 'segment',
        label: translations.segment,
      },
      {
        id: 'view',
        label: translations.view,
        width: '0.5fr',
      },
      {
        id: 'add',
        label: translations.add,
        width: '0.5fr',
      },
      {
        id: 'edit',
        label: translations.edit,
        width: '0.5fr',
      },
      {
        id: 'delete',
        label: translations.delete,
        width: '0.5fr',
      },
    ],
    [
      translations.segment,
      translations.module,
      translations.view,
      translations.add,
      translations.edit,
      translations.delete,
    ]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      userTypePermissions.map((row, idx, arr) => {
        const prev = arr[idx - 1]
        const prevIsSameModule = !prev ? false : prev.module === row.module
        const module = translateModule(row.module as Module)
        const segment = translateSegment(
          row.segment.replaceAll(' ', '').toLowerCase() as Segment
        )

        return {
          module: {
            content: prevIsSameModule ? '' : module,
          },
          segment: {
            content: segment,
          },
          view: {
            content: row.permissions[0],
          },
          add: {
            content: row.permissions[1],
          },
          edit: {
            content: row.permissions[2],
          },
          delete: {
            content: row.permissions[3],
          },
        }
      }),
    [translateModule, translateSegment, userTypePermissions]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          fontWeight: cell.columnId === 'module' ? 600 : undefined,
        },
      }),
    }),
    []
  )

  const isAdmin = useMemo(
    () =>
      userTypesQueryData?.allUserTypes.edges.find(
        edge => edge.node.id === selectedUserTypeId
      )?.node.isAdministrator ?? false,
    [userTypesQueryData, selectedUserTypeId]
  )

  if (userTypesQueryError || permissionsQueryError)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const queryLoading = userTypesQueryLoading || permissionsQueryLoading

  return (
    <Wrapper>
      {isMobileOnly ? (
        <UserTypesMobile
          permissions={userTypePermissions}
          translations={translations}
          isAdmin={isAdmin}
          loading={queryLoading}
          userTypeSelect={{
            value: selectedUserTypeId,
            onChange: v => setSelectedUserTypeId(v),
          }}
          selectedUserType={selectedUserType}
          allPermissions={allPermissions}
          permissionsLoading={permissionsLoading}
          onTogglePermission={handleTogglePermission}
        />
      ) : (
        <>
          <PageHeader
            title={translations.userTypes}
            loading={queryLoading || toggleUserTypePermissionLoading}
            buttons={[
              {
                text: translations.createUserType,
                icon: 'plus',
                role: 'link',
                permissions: PERMISSIONS.users.add.usertype,
                onClick: () =>
                  history.push('/settings/company/user-types/create'),
              },
            ]}
            userTypeSelect={{
              value: selectedUserTypeId,
              onChange: v => setSelectedUserTypeId(v),
            }}
          />

          {isAdmin && (
            <AdminWarning>
              <Icon
                icon="exclamation-circle"
                type="solid"
                size="1.7rem"
                color="matteOrange"
              />

              <p>{translations.administratorWarning}</p>
            </AdminWarning>
          )}

          {!!selectedUserType && (
            <SpecialUserTypePermissions
              userType={selectedUserType}
              allPermissions={allPermissions}
              permissionsLoading={permissionsLoading}
              onTogglePermission={handleTogglePermission}
            />
          )}

          <Table
            columns={columns}
            rows={rows}
            options={tableOptions}
            noRowsText={
              queryLoading ? translations.loading : translations.results.noData
            }
          />
        </>
      )}
    </Wrapper>
  )
}

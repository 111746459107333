import {
  Message as BaseMessage,
  MessageProps as BaseMessageProps,
} from '@ur/react-components'
import styled from 'styled-components'

const StyledMessage = styled(BaseMessage)``

interface MessageProps extends BaseMessageProps {}

export const Message: React.FC<MessageProps> = ({ ...props }) => {
  return <StyledMessage {...props} />
}

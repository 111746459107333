import {
  MessageError as BaseMessageError,
  MessageErrorProps as BaseMessageErrorProps,
} from '@ur/react-components'
import { useCallback } from 'react'
import styled from 'styled-components'

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin: 6rem 0;
`

const StyledMessageError = styled(BaseMessageError)``

interface MessageErrorProps extends BaseMessageErrorProps {
  centered?: boolean
}

export const MessageError: React.FC<MessageErrorProps> = ({
  centered = false,
  ...props
}) => {
  const wrap = useCallback(
    (comp: JSX.Element) => (!centered ? comp : <Center>{comp}</Center>),
    [centered]
  )

  return wrap(<StyledMessageError {...props} />)
}

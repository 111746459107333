import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  Input,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import React from 'react'
import styled from 'styled-components'
import { OfferCard } from './components/OfferCard'
import { ShallowOffer } from './types.graphql'

const Wrapper = styled.div`
  div.no-offers {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`

const Toolbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

const FilterWrapper = styled.div`
  display: flex;
  gap: 24px;
`

interface OffersMobileProps {
  offers: ShallowOffer[]
  search: string
  statusFilter: TableFilteringChecklistOptions

  makeStatusMenu: (project: ShallowOffer) => TableMenu
  makeEditDeleteMenu: (project: ShallowOffer) => TableMenu

  onTitleClick: (offerId: string) => Promise<void>
  onCreateNewOffer: () => void
  onSearchChange: (value: string) => void
  onFilterChange: (value: TableFiltering) => void
}

export const OffersMobile: React.FC<OffersMobileProps> = ({
  offers,
  search,
  statusFilter,

  makeStatusMenu,
  makeEditDeleteMenu,

  onTitleClick,
  onCreateNewOffer,
  onSearchChange,
  onFilterChange,
}) => {
  const translations = useTranslate({
    search: 'offers.search',
    noData: 'common.no-data',
    noResults: 'common.no-results',
  })

  const debouncedSearch = useDebounce(search)

  return (
    <Wrapper>
      <ActionButton onClick={onCreateNewOffer} />

      <Toolbar>
        <Input
          value={search}
          placeholder={translations.search}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          height="56px"
          onChange={onSearchChange}
        />

        <FilterWrapper>
          <Filtering
            id="status"
            config={statusFilter}
            icon={{
              size: '1rem',
            }}
            onFilterChange={value => onFilterChange(value)}
          />
        </FilterWrapper>
      </Toolbar>

      {offers.map(offer => (
        <OfferCard
          key={offer.id}
          offer={offer}
          statusMenu={makeStatusMenu(offer)}
          editDeleteMenu={makeEditDeleteMenu(offer)}
          onTitleClick={onTitleClick}
        />
      ))}

      {!offers.length && (
        <div className="no-offers">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

import { useTranslate } from '@ur/react-hooks'
import { TabbedPage } from 'components/TabbedPage'
import React from 'react'

import { Route, Switch } from 'containers/Routing'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { TimesheetTab, AbsencesTab } from '.'

interface TimesheetsProps {}

export const Timesheets: React.FC<TimesheetsProps> = () => {
  const translations = useTranslate({
    timesheets: 'common.timesheets',
    absence: 'common.absence',
  })

  const { hasPermissions } = usePermissions()

  return (
    <TabbedPage
      tabs={[
        {
          href: '/timesheets',
          hide: !hasPermissions(PERMISSIONS.timesheets.view.timeentry, true),
          text: translations.timesheets,
        },
        {
          href: '/absences',
          hide: !hasPermissions(PERMISSIONS.timesheets.view.absence, true),
          text: translations.absence,
        },
      ]}
    >
      <Switch>
        <Route exact path="/timesheets" component={TimesheetTab} />

        <Route exact path="/absences" component={AbsencesTab} />
      </Switch>
    </TabbedPage>
  )
}

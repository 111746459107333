import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { ManualFolderForm } from './ManualFolderForm'
import { ManualFoldersPromptState } from './types'
import { CreateManualFolderMutationVariables } from './types.graphql'
import { isCreateManualFolderMutationVariables } from './util'

interface CreateManualFolderProps {
  id: string
  root?: boolean

  onSubmit: PromptResolve<CreateManualFolderMutationVariables | null>
}
export const CreateManualFolderModal: React.FC<CreateManualFolderProps> = ({
  id,
  root = false,

  onSubmit,
}) => {
  const translations = useTranslate({
    createFolder: 'handbook.create-folder',
    createSubFolder: 'handbook.create-sub-folder',
  })

  const state = usePromptState<ManualFoldersPromptState>(id)

  function handleSubmit(values: CreateManualFolderMutationVariables) {
    onSubmit(values)
  }

  return (
    <RegularModal
      title={root ? translations.createFolder : translations.createSubFolder}
      width="480px"
      onClose={() => onSubmit(null)}
    >
      <ManualFolderForm
        loading={state.loading}
        onSubmit={variables =>
          isCreateManualFolderMutationVariables(variables) &&
          handleSubmit(variables)
        }
        onCancel={() => onSubmit(null)}
      />
    </RegularModal>
  )
}

const en = {
  'absence.hours': '{value} hours',
  'absences.sum': 'Absence',
  'activities.day-end': 'Day end',
  'activities.day-start': 'Day start',
  'activities.driving-grade': 'Driving grade',
  'activities.fuel-usage': 'Fuel usage',
  'activities.working-hours': 'Working hours',
  'admin.create-company': 'Create company',
  'admin.create-company.success': 'Company {name} created',
  'admin.dashboard': 'Admin Dashboard',
  'admin.dashboard.create-new-company': 'Create new company',
  'checklist.completed': 'Completed items',
  'checklist.edit_checklist': 'Edit checklist',
  'checklist.new_checklist': 'New checklist',
  'checklist.search': 'Search in checklists',
  'checklists.add-checklist': 'Add checklist',
  'checklists.add-checklist-item': 'Add checklist item',
  'checklists.add-checklist-template': 'Add template',
  'checklists.checked-by-at': 'Checked by <a></a> at <span></span>',
  'checklists.checklist-item': 'Checklist item',
  'checklists.checklist-item-comment': 'Checklist item comment',
  'checklists.checklist-item-image': 'Checklist item image',
  'checklists.checklist-items': 'Checklist items',
  'checklists.checklist-template': 'Checklist template',
  'checklists.checklist-template-item': 'Checklist template item',
  'checklists.checklist-templates': 'Checklist templates',
  'checklists.close-checklist': 'Close checklist',
  'checklists.closed': 'Closed',
  'checklists.create': 'Create checklist',
  'checklists.create-checklist': 'Create checklist',
  'checklists.create-checklist-template': 'Create checklist template',
  'checklists.delete-checklist-template': 'Delete checklist template',
  'checklists.delete-prompt-text':
    'Are you sure you want to delete this checklist?',
  'checklists.deleted': 'The checklist was deleted',
  'checklists.deleting-checklist': 'Deleting checklist',
  'checklists.deleting-checklist-prompt':
    'Are you sure you want to delete this checklist?',
  'checklists.deleting-template': 'Deleting template',
  'checklists.deleting-template-prompt':
    'Are you sure you want to delete this checklist template?',
  'checklists.description-altered': 'Description altered',
  'checklists.download-pdf': 'Download PDF',
  'checklists.edit-item': 'Edit item',
  'checklists.error-item-must-provide-title':
    'The checklist item must have a title',
  'checklists.errors.checklist-template-item-title-must-be-unique':
    'The checklist template item titles must be unique within a template',
  'checklists.errors.checklist-template-title-must-be-unique':
    "There's already a checklist template with this name. The title must be unique",
  'checklists.errors.patch-purpose': 'Could not update purpose',
  'checklists.errors.send-email':
    'Could not send the checklist by email - have you entered a valid email address?',
  'checklists.items': 'Items',
  'checklists.must-select-template':
    'You cannot edit this directly, please select a template.',
  'checklists.n-checklist-templates':
    '{n, plural, one {1 checklist template} other {{n} checklist templates}}',
  'checklists.n-checklists':
    '{n, plural, {one {1 checklist} other {{n} checklists}}',
  'checklists.n-items': '{n, plural, one {1 item} other {{n} items}}',
  'checklists.no-checklist-items': 'No checklist items',
  'checklists.no-checklist-templates-found': 'No checklist templates found',
  'checklists.no-checklists-found': 'No checklists found',
  'checklists.no-purpose': 'No purpose',
  'checklists.ongoing-checklists': 'Ongoing checklists',
  'checklists.prompt-remove-comment-from-checklist-item':
    'Are you sure you want to remove this comment?',
  'checklists.prompt-remove-image-from-checklist-item':
    'Are you sure you want to remove this image from this checklist item?',
  'checklists.prompts.delete-template-item':
    'Are you sure you want to delete this checklist template item?',
  'checklists.prompts.delete-template-item-title':
    'Delete checklist template item',
  'checklists.prompts.send-email': 'Give recipient email address',
  'checklists.prompts.send-email-title': 'Send checklist by email',
  'checklists.purpose': 'Purpose',
  'checklists.purpose-altered': 'Purpose altered',
  'checklists.purpose-placeholder':
    'Room, apartment or purpose of this checklist',
  'checklists.query-title-description-user': 'Title, description or user',
  'checklists.query-title-or-such': 'Title, purpose or creator',
  'checklists.ready-to-submit': 'Ready to submit',
  'checklists.removing-item': 'Removing item',
  'checklists.removing-item-confirm':
    'Are you sure you want to remove this checklist item?',
  'checklists.reopen-checklist': 'Reopen checklist',
  'checklists.search-for-templates': 'Search for checklist template',
  'checklists.select-template': 'Select template',
  'checklists.status-done': 'Done',
  'checklists.status-irrelevant': 'Irrelevant',
  'checklists.status-not-done': 'Not done',
  'checklists.submit-checklist': 'Submit checklist',
  'checklists.success.patch-purpose': 'Purpose updated',
  'checklists.success.send-email': 'The checklist was sent by email',
  'checklists.template-create-unique-error':
    'A checklist template with this title already exists',
  'checklists.template-created': 'The checklist template was created',
  'checklists.template-deleted': 'The checklist template was deleted',
  'checklists.template-does-not-exist-error':
    'Checklist template does not exist',
  'checklists.template-updated': 'The checklist template was updated',
  'checklists.toasts.checklist-close-error':
    'The checklist could not be closed',
  'checklists.toasts.checklist-delete-error':
    'The checklist could not be deleted',
  'checklists.toasts.checklist-deleted': 'The checklist was deleted',
  'checklists.toasts.checklist-item-updated': 'The checklist item was updated',
  'checklists.toasts.checklist-reopened': 'The checklist was reopened',
  'checklists.toasts.checklist-sent': 'The checklist was sent',
  'checklists.toasts.checklist-updated': 'The checklist was updated',
  'checklists.toasts.closed-but-no-cigar':
    'The checklist was closed, but the email could not be sent. You can try sending it again manually.',
  'checklists.toasts.could-not-delete': 'The checklist was not deleted',
  'checklists.toasts.delete-success': 'Checklist deleted',
  'checklists.use-template': 'Use template',
  'checklists.view-checklist': 'View checklist',
  'checklists.view-template': 'View template',
  'common.absence': 'Absence',
  'common.absences': 'Absences',
  'common.active': 'Active',
  'common.active-alt': 'Active',
  'common.activities': 'Activities',
  'common.add': 'Add',
  'common.add-a-comment': 'Add a comment...',
  'common.add-comment': 'Add comment',
  'common.add-emergency-contact': 'Add emergency contact',
  'common.address': 'Address',
  'common.address-not-found': 'Address not found',
  'common.administrator': 'Administrator',
  'common.all': 'All',
  'common.all-months': 'All months',
  'common.all-time': 'All time',
  'common.all-years': 'All years',
  'common.archive': 'Archive',
  'common.archived': 'Archived',
  'common.attachments': 'Attachments',
  'common.availability': 'Availability',
  'common.back': 'Back',
  'common.back-to-overview': 'Back to overview',
  'common.back-to-x': 'Back to {x}',
  'common.balance': 'Balance',
  'common.billed': 'Billed',
  'common.billing': 'Billing',
  'common.businessOverview': 'Business overview',
  'common.call': 'Call',
  'common.cancel': 'Cancel',
  'common.category': 'Category',
  'common.change': 'Change',
  'common.change-company': 'Change company',
  'common.change-password': 'Change password',
  'common.changes-saved': 'Changes saved',
  'common.checklist': 'Checklist',
  'common.checklist-files': 'Checklist files',
  'common.checklist-images': 'Checklist images',
  'common.checklists': 'Checklists',
  'common.choices': 'Choices',
  'common.choose': 'Choose',
  'common.choose-file': 'Choose file',
  'common.choose-files': 'Choose files',
  'common.choose-images': 'Choose images',
  'common.city': 'City',
  'common.click-to-edit': 'Click to edit',
  'common.close': 'Close',
  'common.closed': 'Closed',
  'common.codes': 'Codes',
  'common.color': 'Color',
  'common.comment': 'Comment',
  'common.comments': 'Comments',
  'common.companies': 'Companies',
  'common.company': 'Company',
  'common.company-name': 'Company name',
  'common.company-settings': 'Company settings',
  'common.contact': 'Contact',
  'common.content': 'Content',
  'common.create': 'Create',
  'common.create-entry': 'Create new entry',
  'common.create-folder': 'Create new folder',
  'common.created-at': 'Created at',
  'common.created-by': 'Created by',
  'common.created-by-who-at-when': 'Created by <a>{who}</a> at {when}',
  'common.created-t-alt': 'Created {t}',
  'common.ctrl-enter-to-save': 'Ctrl+Enter to save',
  'common.ctrl-enter-to-submit': 'Ctrl+Enter to submit',
  'common.custom': 'Custom',
  'common.customer': 'Customer',
  'common.customer-select-placeholder': 'Select customer',
  'common.customers': 'Customers',
  'common.dashboard': 'Dashboard',
  'common.date': 'Date',
  'common.day-end': 'Dag slutt',
  'common.day-start': 'Dag start',
  'common.default-discount': 'Default discount',
  'common.default-hourly-rate': 'Default hourly rate',
  'common.default-material-discount': 'Default material discount',
  'common.delete': 'Delete',
  'common.deleted': 'Deleted',
  'common.deleting-image': 'Deleting image',
  'common.deleting-image-prompt': 'Are you sure you want to delete this image?',
  'common.deleting-images': 'Deleting images',
  'common.deleting-images-prompt':
    'Are you sure you want to delete these images?',
  'common.description': 'Description',
  'common.detail': 'Detail',
  'common.details': 'Details',
  'common.deviation': 'Deviation',
  'common.deviation-categories': 'Deviation categories',
  'common.deviations': 'Deviations',
  'common.distance': 'Distanse',
  'common.documentation': 'Dokumentasjon',
  'common.documentationIssues': 'Documentation issues',
  'common.done': 'Done',
  'common.download': 'Download',
  'common.driving': 'Driving',
  'common.drop-file-here': 'Drag and drop a file here',
  'common.drop-files-here': 'Drag and drop files here',
  'common.drop-images-here': 'Drag and drop images here',
  'common.edit': 'Edit',
  'common.edit-emergency-contact': 'Edit emergency contact',
  'common.edit-profile': 'Edit profile',
  'common.edited': 'Edited',
  'common.email': 'Email',
  'common.emergency-contacts': 'Emergency contacts',
  'common.employee': 'Employee',
  'common.end': 'End',
  'common.entry': 'Entry',
  'common.error': 'An error has occurred',
  'common.estimate': 'Estimate',
  'common.eurovignette': 'Eurovignette',
  'common.eurovignettes': 'Eurovignettes',
  'common.existing-files': 'Existing files',
  'common.file': 'File',
  'common.files': 'Files',
  'common.filter': 'Filter',
  'common.first-name': 'First name',
  'common.firstName': 'First name',
  'common.folder': 'Folder',
  'common.folders': 'Folders',
  'common.forum': 'Forum',
  'common.from-date-alt': 'From',
  'common.general': 'General',
  'common.general-info': 'General info',
  'common.go-to-checklists': 'Go to checklists',
  'common.go-to-deviations': 'Go to deviations',
  'common.go-to-files': 'Go to files',
  'common.go-to-timesheets': 'Go to timesheets',
  'common.handbook': 'Handbook',
  'common.hide': 'Hide',
  'common.hide-images': 'Hide images',
  'common.home': 'Home',
  'common.hourlist': 'Hourlist',
  'common.hours': 'Hours',
  'common.hours-lowercase': 'hours',
  'common.hours-registered': 'Hours registered',
  'common.image-deleted': 'Image deleted',
  'common.image-selected': 'Image selected',
  'common.image-uploaded': 'Image uploaded',
  'common.images': 'Images',
  'common.images-deleted': 'Images deleted',
  'common.images-selected': 'Images selected',
  'common.imported': 'Imported',
  'common.inactive': 'Inactive',
  'common.info': 'Info',
  'common.information': 'Information',
  'common.internal': 'Internal',
  'common.invalid': 'Invalid',
  'common.issue': 'Issue',
  'common.issues': 'Issues',
  'common.item': 'Item',
  'common.km': 'km',
  'common.language': 'Language',
  'common.last-month': 'Last month',
  'common.last-name': 'Last name',
  'common.last-six-months': 'Last 6 months',
  'common.last-updated': 'Last updated',
  'common.last-week': 'Last week',
  'common.last-year': 'Last year',
  'common.lastName': 'Last name',
  'common.link-months': 'Link months',
  'common.load-more': 'Load more',
  'common.loading': 'Loading',
  'common.loading-project-name': 'Loading project name',
  'common.logout': 'Sign out',
  'common.modified': 'Last modified',
  'common.module': 'Module',
  'common.month': 'Month',
  'common.move': 'Move',
  'common.my-projects': 'My projects',
  'common.my-site': 'My page',
  'common.n-elements-selected':
    '{n, plural, one {# element selected} other {# elements selected}}',
  'common.n-hours': '{n, plural, one {1 hour} other {{n} hours}}',
  'common.n-hours-span':
    '{n, plural, one {1 <span>hour</span>} other {{n} <span>hours</span>}}',
  'common.n-images': '{n, plural, one {1 image} other {{n} images}}',
  'common.n-km': '{n} km',
  'common.n-results':
    '{n, plural, =0 {No results} one {1 result} other {{n} results}}',
  'common.n-rows': '{n, plural, one {1 row} other {{n} rows}}',
  'common.name': 'Name',
  'common.new-files': 'New files',
  'common.next': 'Next',
  'common.next-page': 'Next page',
  'common.no': 'No',
  'common.no-attachments': 'No attachments',
  'common.no-comments': 'No comments',
  'common.no-content': 'No content',
  'common.no-data': 'No data',
  'common.no-description': 'No description',
  'common.no-deviations': 'No deviations',
  'common.no-emergency-contacts': 'No emergency contacts registered',
  'common.no-files': 'No files',
  'common.no-images': 'No images',
  'common.no-images-selected': 'No images selected',
  'common.no-more-data': 'No more data',
  'common.no-more-options': 'No more options',
  'common.no-results': 'No results',
  'common.none': 'None',
  'common.none-selected': 'None selected',
  'common.not-implemented': 'Not implemented',
  'common.offers': 'Offers',
  'common.office': 'Office',
  'common.ok': 'OK',
  'common.open': 'Open',
  'common.open-alt': 'Open',
  'common.options': 'Options',
  'common.or': 'or',
  'common.or-drag-and-drop-images-here': 'or drag and drop images here',
  'common.other': 'Other',
  'common.other-work': 'Other work',
  'common.overtime': 'Overtime',
  'common.overview': 'Overview',
  'common.path': 'Path',
  'common.pause': 'Pause',
  'common.period': 'Period',
  'common.phone': 'Phone',
  'common.phone-number': 'Phone number',
  'common.picture': 'Picture',
  'common.playground': 'Playground',
  'common.postal-code': 'Postal code',
  'common.primary': 'Primary',
  'common.print': 'Print',
  'common.products': 'Products',
  'common.profile': 'Profile',
  'common.progress': 'Progress',
  'common.project': 'Project',
  'common.project-select-placeholder': 'Select project',
  'common.projects': 'Projects',
  'common.prompts.unsaved-changes-prompt':
    'You have unsaved changes. Are you sure you want to leave this page?',
  'common.prompts.unsaved-changes-title': 'Unsaved changes',
  'common.purpose': 'Purpose',
  'common.reason': 'Reason',
  'common.recent-files': 'Recent files',
  'common.reg-nr': 'Reg.nr.',
  'common.registered-by': 'Registered by',
  'common.relation': 'Relation',
  'common.remove': 'Remove',
  'common.remove-comment': 'Remove comment',
  'common.remove-image': 'Remove image',
  'common.required': 'Required',
  'common.required-fields-missing': 'Required fields missing',
  'common.required-fields-valid': 'Required fields are filled in',
  'common.rest': 'Rest',
  'common.role': 'Role',
  'common.root': 'Root',
  'common.save': 'Save',
  'common.save_changes': 'Save changes',
  'common.search': 'Search',
  'common.segment': 'Segment',
  'common.select-image': 'Select image',
  'common.select-placeholder': 'Select an option…',
  'common.select-placeholder-user-type': 'Select user type...',
  'common.select-placeholder-user-types': 'Select user types...',
  'common.selected': 'Selected',
  'common.selected-images': 'Selected images',
  'common.send': 'Send',
  'common.send-email': 'Send email',
  'common.send-to-email': 'Send to email',
  'common.set-primary': 'Set primary',
  'common.settings': 'Settings',
  'common.shortcuts': 'Shortcuts',
  'common.show': 'Show',
  'common.show-all': 'Show all',
  'common.show-images': 'Show images',
  'common.show-profile': 'Show profile',
  'common.sidebar-collapse': 'Collapse sidebar',
  'common.sidebar-expand': 'Expand sidebar',
  'common.something-went-wrong': 'Something went wrong',
  'common.sorting': 'Sorting',
  'common.start': 'Start',
  'common.status': 'Status',
  'common.sub-folders': 'Subfolders',
  'common.submit': 'Submit',
  'common.subtype': 'Subtype',
  'common.sum': 'Sum',
  'common.support': 'Support',
  'common.templates': 'Templates',
  'common.terminal': 'Terminal',
  'common.this-month': 'This month',
  'common.this-week': 'This week',
  'common.this-year': 'This year',
  'common.time-entry': 'Time entry',
  'common.time-period-all-time': 'All time',
  'common.time-unknown': 'Time unknown',
  'common.timesheets': 'Timesheets',
  'common.title': 'Title',
  'common.to-date-alt': 'To',
  'common.today': 'Today',
  'common.toggle-sidebar': 'Toggle sidebar',
  'common.total': 'Total',
  'common.total-alt': 'Total',
  'common.treated': 'Treated',
  'common.try-again': 'Try again',
  'common.type': 'Type',
  'common.unarchive': 'Unarchive',
  'common.under-treatment': 'Under treatment',
  'common.unfinished': 'Unfinished',
  'common.unlink-months': 'Unlink months',
  'common.untreated': 'Untreated',
  'common.update': 'Update',
  'common.update-entry': 'Update entry',
  'common.update-folder': 'Update folder',
  'common.updated': 'Updated',
  'common.updated-t-alt': 'Updated {t}',
  'common.updating': 'Updating',
  'common.upload': 'Upload',
  'common.upload-files': 'Upload files',
  'common.upload-images': 'Upload images',
  'common.uploaded-by': 'Uploaded by',
  'common.user': 'User',
  'common.user-guide': 'User guide',
  'common.user-profile': 'User profile',
  'common.user-settings': 'User settings',
  'common.user-type': 'User type',
  'common.user-types': 'User types',
  'common.userType': 'User type',
  'common.username': 'Username',
  'common.users': 'Users',
  'common.view': 'View',
  'common.work': 'Work',
  'common.written-by-x': 'Written by <a>{x}</a>',
  'common.written-t': 'Written {t}',
  'common.year': 'Year',
  'common.yes': 'Yes',
  'common.yesterday': 'Yesterday',
  'common.zip': 'Zip',
  'companies.add-folder': 'Add folder',
  'companies.affiliated-not': 'You are not affiliated with any companies',
  'companies.company-folder-settings': 'Company folder setting',
  'companies.company-information': 'Company information',
  'companies.company-name-error': 'Company must have a name',
  'companies.company-settings': 'Company settings',
  'companies.couldnt-load': "Couldn't load companies",
  'companies.deleting-folder': 'Deleting folder',
  'companies.deleting-folder-prompt':
    'Are you sure you want to delete this default folder? This will also delete all subfolders and files, and is not reversible.',
  'companies.errors.could-not-create-company': 'Could not create company',
  'companies.errors.email-address-already-in-use':
    'The user email is already in use',
  'companies.errors.short-name-is-already-in-use':
    'The short name is already in use',
  'companies.errors.short-name-taken': 'Name taken',
  'companies.folder-name-unique-error': 'Folder names must be unique',
  'companies.i-agree-to-digitechs': "I agree to Digitech's",
  'companies.loading': 'Loading your companies',
  'companies.manual-entry': 'Manual entry',
  'companies.manual-entry-attachment': 'Manual entry attachment',
  'companies.manual-folder': 'Manual folder',
  'companies.number-of-licences': 'Number of users/licences',
  'companies.only-admins-project-leader': 'Only admins can be project leader',
  'companies.privacy-policy': 'Privacy Policy',
  'companies.prompts.delete-folder-setting':
    'Are you sure you want to remove this folder? Existing project folders will not be deleted.',
  'companies.prompts.delete-folder-setting-title': 'Delete folder',
  'companies.select': 'Select company',
  'companies.short-name': 'Shortened name (max 16 characters)',
  'companies.terms-and-conditions': 'terms and conditions',
  'companies.terms-and-conditions-header': 'Terms & Conditions',
  'companies.toasts.company-was-edited': 'The company information was updated',
  'company.company-folders': 'Company folders',
  'company.company-folders-help-text':
    "When you create a new project, the folders defined here will be the root-folders of the project's folder-tree. You can also enable/disable folders by pressing the icon in the relevant folder's row. Disabled folders will not be added to new projects.",
  'company.companyLogo': 'Company logo',
  'company.enabled': 'Enabled',
  'company.folder-name': 'Folder name',
  'customers.add-customer': 'Add customer',
  'customers.address': 'Address',
  'customers.billing-email': 'Billing e-mail',
  'customers.billing-email-label': 'Billing email',
  'customers.billing-email-label-paren': 'Leave blank to use contact email',
  'customers.billing-settings': 'Billing',
  'customers.column-address': 'Address',
  'customers.column-contact': 'Contact',
  'customers.column-name': 'Name',
  'customers.column-type': 'Type',
  'customers.contact-person': 'Contact person',
  'customers.create-customer': 'Create customer',
  'customers.create-error': 'Error creating customer',
  'customers.create-success': 'Customer created',
  'customers.created': 'Created',
  'customers.customer-created-header': 'Customer created',
  'customers.customer-created-message':
    'The customer «{name}» was created. Taking you to customer info in {time}',
  'customers.customer-types.company': 'Company',
  'customers.customer-types.private': 'Private',
  'customers.delete-error': 'The customer could not be deleted.',
  'customers.delete-success': 'The customer was deleted.',
  'customers.deleting-customer-project-list':
    'These projects are attached to this customer, and will be removed from the project overview if the customer is deleted:',
  'customers.edit-customer': 'Edit customer',
  'customers.edit-success': 'Customer information changed',
  'customers.errors.could-not-find-organization':
    'Could not find the organization',
  'customers.errors.customer-does-not-exist': 'Customer does not exist',
  'customers.general-information': 'General information',
  'customers.information': 'Information',
  'customers.jump-to-page': 'jump to page',
  'customers.loading': 'Loading...',
  'customers.map': 'Map',
  'customers.no-customer': 'No customer',
  'customers.no-data': 'No data',
  'customers.of': 'of',
  'customers.org-nr': 'Org. nr.',
  'customers.organization-number': 'Organization number',
  'customers.page': 'Page',
  'customers.pagination-next': 'Next page',
  'customers.pagination-previous': 'Previous page',
  'customers.postal-area': 'Postal area',
  'customers.postal-code': 'Postal code',
  'customers.postal-info': 'Postal info',
  'customers.prompts.delete-customer':
    'Are you sure you want to delete this customer?',
  'customers.prompts.delete-customer-title': 'Delete customer',
  'customers.prompts.n-deleting-customer-project-list':
    '{n, plural, one {This project is attached to this customer, and will be removed from the overview if the customer is deleted:} other {These {n} projects are attached to this customer, and will be removed from the project overview if the customer is deleted:}}',
  'customers.retrieve': 'Retrieve',
  'customers.rows': 'rows',
  'customers.rows-per-page': 'rows per page',
  'customers.search': 'Search for customer',
  'customers.select-a-customer': 'Select a customer',
  'customers.select-customer': 'Select customer',
  'customers.show-customer': 'Show customer',
  'customers.type': 'Type',
  'dashboard.all-sub-modules-disabled': 'All submodules disabled',
  'dashboard.configure-modules': 'Configure modules',
  'dashboard.lastForumPosts': 'Last forum posts',
  'dashboard.page-description':
    'You are now on "My Page". Here, useful information will be visualized after the necessary data has been filled in. A project must have a customer, which can be created under "Customers" in the naviagtion on the left. If you just want to get started, you can create a project with the included test customer.',
  'dashboard.registerHours': 'Register hours',
  'dashboard.registered-elements': 'Registered elements',
  'dashboard.sub-modules.checklists': 'Checklists',
  'dashboard.sub-modules.companyStats': 'Company stats',
  'dashboard.sub-modules.deviations': 'Deviations',
  'dashboard.sub-modules.files': 'Files',
  'dashboard.sub-modules.keyFigures': 'Key figures',
  'dashboard.sub-modules.keyFigures.timesheets.no-data-for-period':
    'No registered hours for selected filter and period',
  'dashboard.sub-modules.keyFigures.users.user-type-distribution.description':
    'This chart displays the distribution of user types within this company.',
  'dashboard.sub-modules.projects': 'Projects',
  'dashboard.sub-modules.timesheets': 'Timesheets',
  'dashboard.sub-modules.userStats': 'User stats',
  'deviations.action': 'Action',
  'deviations.actualAction': 'Actual action',
  'deviations.actualActionPlaceholder': 'Enter actual action',
  'deviations.add-deviation': 'Add deviation',
  'deviations.add-deviation-category': 'Add deviation category',
  'deviations.add-performed-action': 'Add performed action',
  'deviations.archive': 'Archive',
  'deviations.categories': 'Categories',
  'deviations.category': 'Category',
  'deviations.close': 'Close',
  'deviations.create-deviation': 'Create deviation',
  'deviations.created-at-t': 'Created at {t}',
  'deviations.delete-category-prompt':
    'Are you sure you want to delete this category?',
  'deviations.delete-category-title': 'Delete category',
  'deviations.delete-deviation': 'Delete deviation',
  'deviations.delete-deviation-prompt':
    'Are you sure you want to delete this deviation? This action cannot be reversed.',
  'deviations.delete-deviation-success': 'The deviation was deleted',
  'deviations.deleted': 'The deviation was deleted',
  'deviations.deleting-deviation': 'Deleting deviation',
  'deviations.deleting-deviation-prompt':
    'Are you sure you want to delete this deviation?',
  'deviations.deviation-archive-error-toast':
    'The deviation could not be archived.',
  'deviations.deviation-archive-success-toast': 'The deviation was archived',
  'deviations.deviation-categories': 'Deviation categories',
  'deviations.deviation-create-error-toast':
    'The deviation could not be created.',
  'deviations.deviation-create-success-toast':
    'The deviation was created successfully.',
  'deviations.deviation-delete-error-toast':
    'The deviation could not be deleted.',
  'deviations.deviation-delete-success-toast': 'The deviation was deleted.',
  'deviations.deviation-edit-error-toast': 'The deviation could not be updated',
  'deviations.deviation-edit-success-toast': 'The deviation was updated',
  'deviations.deviation-image': 'Deviation image',
  'deviations.deviation-not-found-error':
    "Couldn't find the deviation you're looking for",
  'deviations.deviation-performed-action': 'Deviation performed action',
  'deviations.deviation-treated-error-toast':
    'The devation could not be treated.',
  'deviations.deviation-treated-success-toast': 'The deviation was closed.',
  'deviations.edit-deviation': 'Edit deviation',
  'deviations.error-could-not-create': 'Could not create deviation.',
  'deviations.errors.could-not-load-categories': 'Could not load categories',
  'deviations.errors.create-category-error':
    'Could not create deviation category',
  'deviations.errors.delete-category-error':
    'Could not delete deviation category',
  'deviations.errors.send-email':
    'Could not send the deviation by email - have you entered a valid email address?',
  'deviations.errors.update-category-error':
    'Could not alter deviation category',
  'deviations.images-of-deviation': 'Images of deviation',
  'deviations.internal-deviation': 'Internal deviation',
  'deviations.internal-deviations': 'Internal deviations',
  'deviations.modified-at-t': 'Modified at {t}',
  'deviations.n-categories':
    '{n, plural, one {1 category} other {{n} categories}}',
  'deviations.n-deviations': '{n} deviations',
  'deviations.no-categories': 'No categories',
  'deviations.no-deviations-found': 'No deviations found',
  'deviations.no-performed-action': 'No action performed',
  'deviations.open-deviation': 'Open deviation',
  'deviations.performed-action': 'Performed action',
  'deviations.performed-action-added': 'Performed action added',
  'deviations.performed-action-updated': 'Performed action updated',
  'deviations.performed-actions': 'Performed actions',
  'deviations.project-deviations': 'Project deviations',
  'deviations.prompt-remove-from-checklist-item':
    'Are you sure you want to remove this deviation from this checklist item? The deviation will not be deleted.',
  'deviations.prompts.close-deviation-prompt':
    'By closing the deviation you confirm that it has been taken care of and can no longer be edited. It will be marked as closed. Do you wish to close the deviation?',
  'deviations.prompts.close-deviation-title': 'Close deviation',
  'deviations.prompts.could-not-delete': 'Could not delete deviation',
  'deviations.prompts.delete-performed-action-prompt':
    'Are you sure you want to delete this performed action?',
  'deviations.prompts.delete-performed-action-title': 'Delete performed action',
  'deviations.prompts.send-email': 'Give recipient email address',
  'deviations.prompts.send-email-title': 'Send deviation by email',
  'deviations.provide-performed-action': 'Provide performed action',
  'deviations.provide-suggested-action': 'Provide suggested action',
  'deviations.query-title-or-such': 'Title or description',
  'deviations.register-deviation': 'Register deviation',
  'deviations.remove-deviation': 'Remove deviation',
  'deviations.reopen': 'Reopen',
  'deviations.restore': 'Restore',
  'deviations.search': 'Search in deviations',
  'deviations.show-suggested-actions': 'Show suggested actions',
  'deviations.success.send-email': 'The deviation was sent by email',
  'deviations.suggested-action': 'Suggested action',
  'deviations.suggested-action-updated': 'Suggested action updated',
  'deviations.supply-performed-action':
    'You must enter a performed action in the text box',
  'deviations.toasts.create-category-success':
    'The deviation category was created',
  'deviations.toasts.create-performed-action-error':
    'The performed action could not be created',
  'deviations.toasts.create-performed-action-success':
    'The performed action was created',
  'deviations.toasts.delete-category-success':
    'The deviation category was deleted',
  'deviations.toasts.delete-performed-action-error':
    'The performed action could not be deleted',
  'deviations.toasts.delete-performed-action-success':
    'The performed action was deleted',
  'deviations.toasts.edit-category-success':
    'The deviation category was updated',
  'deviations.toasts.edit-performed-action-error':
    'The performed action could not be edited',
  'deviations.toasts.edit-performed-action-success':
    'The performed action was edited',
  'deviations.treated': 'Treated',
  'deviations.treated-by-who-at-when': 'Treated by <a>{who}</a> at {when}',
  'deviations.under-treatment': 'Deviations under treatment',
  'deviations.warnings.deviation-archived': 'This deviation is archived',
  'deviations.warnings.deviation-closed': 'This deviation is closed',
  'deviations.what-action-was-performed': 'What action was performed?',
  'empty-pages.absences.button': 'Register',
  'empty-pages.absences.description':
    'Register your absence here. Once you have registered an absence, all your absences will be displayed in an overview.',
  'empty-pages.absences.header': 'Absences',
  'empty-pages.checklistTemplates.button': 'Create checklist template',
  'empty-pages.checklistTemplates.description':
    'Here you can create and have an overview of all your checklist templates. These are used during the creation of checklists.',
  'empty-pages.checklistTemplates.header': 'Checklist templates',
  'empty-pages.checklists.button': 'Create checklist',
  'empty-pages.checklists.description':
    'Here you can see an overview of your checklists and create new ones. To create a checklist, you need a project and a checklist template. Checklist templates can be found in the tab at the top of the overview.',
  'empty-pages.checklists.header': 'Checklists',
  'empty-pages.dashboard.button': 'Create project',
  'empty-pages.dashboard.description':
    'Welcome to Digitech, you are now on "My Page". Here, useful information will be presented once you have started using the program. Start by creating a project by pressing the button below using the included test customer. A project must have a customer, but you can create a customer by going to customers in the menu and then creating your own.',
  'empty-pages.dashboard.header': 'My page',
  'empty-pages.deviations.button': 'Create deviation',
  'empty-pages.deviations.description':
    'Get started by registering deviations by pressing the button below. To create deviations, you need a project. Then you will get an overview of all deviations that are registered in your company.',
  'empty-pages.deviations.header': 'Deviations',
  'empty-pages.handbook.button': 'Create folder',
  'empty-pages.handbook.description':
    'Press the button below to start creating your Handbook. Start by creating folders and then create entries.',
  'empty-pages.handbook.header': 'Handbook',
  'empty-pages.importedProducts.button': 'Import products',
  'empty-pages.importedProducts.description':
    'Here you can upload lists of items with a price information that you have received from your supplier. Press the button to begin and then complete the upload wizard. Once you have imported your first product list, all the products will be displayed in an overview.',
  'empty-pages.importedProducts.header': 'Imported products',
  'empty-pages.internalDeviations.button': 'Create internal deviation',
  'empty-pages.internalDeviations.description':
    'Get started creating an internal deviation by pressing the button below. Then you will get an overview of all internal deviations that are registered in your company.',
  'empty-pages.internalDeviations.header': 'Internal deviations',
  'empty-pages.products.button': 'Create product',
  'empty-pages.products.description':
    'Here you will see an overview of created products when products have been added to your company. Press the button below to create your first product. If you want to import products from a supplier, go to the "Imported" tab.',
  'empty-pages.products.header': 'Products',
  'empty-pages.projects.button': 'Create project',
  'empty-pages.projects.description':
    'Welcome to the project page. Here you will get an overview with useful information and functions associated with your projects. A project must have a customer, and this can be created under "Customers" in the navigation on the left. If you want to get started, you can create a project with the included test customer.',
  'empty-pages.projects.header': 'Projects',
  'empty-pages.timesheets.button': 'Register hours',
  'empty-pages.timesheets.description':
    'Register your working hours here by using the button below. You must have a project to be able to register hours. Once you have registered hours on a project, your hours will be displayed in an overview.',
  'empty-pages.timesheets.header': 'Timesheets',
  'errors.defaults.does-not-exist': 'This object does not exist',
  'errors.defaults.foreign-key-constraint':
    "The object if referencing something that doesn't exist",
  'errors.defaults.invalid-value': 'An invalid value was provided',
  'errors.defaults.not-permitted': 'Your user is not permitted to do this',
  'errors.defaults.unique-constraint': 'The object must be unique.',
  'errors.image-upload': 'The image could not be uploaded',
  'errors.invalid': 'Invalid',
  'errors.invalid-color': 'Invalid color',
  'errors.invalid-date': 'Invalid date',
  'errors.invalid-email-address': 'Invalid email address',
  'errors.invalid-number-must-be-positive': 'Must be a positive number',
  'errors.invalid-phone-number': 'Invalid phone number',
  'errors.name-exists': 'Name already exists',
  'errors.name-must-be-unique': 'The name must be unique',
  'errors.not-permitted-to-view': 'You are not permitted to view this',
  'errors.pages.401': 'You are not logged in',
  'errors.pages.403': 'You do not have permission to view this page',
  'errors.pages.404': 'Page not found',
  'errors.server-error': 'Server error',
  'errors.something-went-wrong': 'Something went wrong',
  'errors.title-must-be-unique': 'The title must be unique',
  'file-upload.error-message-no-file-input': 'No files selected.',
  'files.check-files-help': 'Click the file icons to select many files at once',
  'files.delete-file': 'Delete file',
  'files.delete-folder': 'Delete folder',
  'files.edit-file-name': 'Edit file name',
  'files.edit-folder': 'Edit folder',
  'files.errors.create-folder': 'Could not create folder',
  'files.errors.delete-file': 'Could not delete file',
  'files.errors.delete-folder': 'Could not delete folder',
  'files.errors.folder-does-not-exist': 'Folder does not exist',
  'files.errors.move-file': 'Could not move file',
  'files.errors.move-folder': 'Could not move folder',
  'files.errors.patch-file': 'Could not update file details',
  'files.errors.patch-folder': 'Could not edit folder',
  'files.errors.query-root-folders': 'Could not load root folders',
  'files.errors.upload-files': 'Could not upload files',
  'files.file-chosen-success': 'File: {fileName}',
  'files.file-size': 'File size',
  'files.file-type': 'File type',
  'files.file-types.archive': 'Archive',
  'files.file-types.audio': 'Audio',
  'files.file-types.csv': 'CSV',
  'files.file-types.excel': 'Excel Document',
  'files.file-types.image': 'Image',
  'files.file-types.other': 'Other',
  'files.file-types.pdf': 'PDF',
  'files.file-types.powerpoint': 'PowerPoint Presentation',
  'files.file-types.video': 'Video',
  'files.file-types.word': 'Word Document',
  'files.file-upload-error-toast': 'File "{fileName}" could not be uploaded.',
  'files.folder-empty': 'The folder is empty',
  'files.folder-name': 'Folder name',
  'files.loading-recent-files': 'Loading recent files',
  'files.loading-recent-files-error': "Couldn't load recent files",
  'files.move-file': 'Move file',
  'files.move-file-choose-folder':
    'Choose which folder you want to move "{name}" to',
  'files.move-folder': 'Move folder',
  'files.multiple-files-upload-error-toast': 'Files could not be uploaded.',
  'files.multiple-files-upload-success-toast': 'Files uploaded successfully.',
  'files.n-files': '{n, plural, one {1 file} other {{n} files}}',
  'files.n-files-selected':
    '{n, plural, one {# file selected} other {# files selected}}',
  'files.n-folders': '{n, plural, one {1 folder} other {{n} folders}}',
  'files.new-folder': 'New folder',
  'files.number-of-files': 'Number of files',
  'files.number-of-folders': 'Number of folders',
  'files.original-name': 'Original name',
  'files.prompts-delete-multiple-files-text':
    'Are you sure you wish to delete these files?',
  'files.prompts.create-folder': 'Give the folder a name',
  'files.prompts.create-folder-title': 'Create folder',
  'files.prompts.delete-file':
    'Are you sure you want to delete the file "{name}"?',
  'files.prompts.delete-file-title': 'Delete file',
  'files.prompts.delete-folder':
    'Are you sure you want to delete the folder "{name}"? All content will also be deleted.',
  'files.prompts.delete-folder-title': 'Delete folder',
  'files.prompts.delete-multiple-files-title': 'Delete files',
  'files.prompts.patch-folder': 'Give folder a new name',
  'files.prompts.patch-folder-title': 'Edit folder',
  'files.recent': 'Recent files',
  'files.recent-file-uploaded-at': 'Uploaded {when}',
  'files.recent-files': 'Recent files',
  'files.rename-folder': 'Rename folder',
  'files.search': 'Search in files',
  'files.search-for-files': 'Search for files...',
  'files.search-in-folder': 'Search in folder',
  'files.success.create-folder': 'Folder created',
  'files.success.delete-file': 'The file was deleted',
  'files.success.delete-folder': 'Folder deleted',
  'files.success.move-file': 'The file was moved',
  'files.success.move-folder': 'The folder was moved',
  'files.success.patch-file': 'File details updated',
  'files.success.patch-folder': 'Folder edited',
  'files.success.upload-files':
    '{n, plural, one {1 file uploaded} other {{n} files uploaded}}',
  'files.up-one-level': 'Up one level',
  'files.upload': 'Upload',
  'files.upload-file': 'Upload file',
  'files.upload-files': 'Upload files',
  'files.upload-files-modal-hint':
    'PS: You can also drop files directly onto the file table',
  'forgotten-password.back': 'Back to login',
  'forgotten-password.change-password-description':
    'You must change your password before logging in for security reasons.',
  'forgotten-password.email-error':
    'Could not send email. Contact your administrator to reset the password.',
  'forgotten-password.error': 'Could not find the username',
  'forgotten-password.incorrect-password': 'Incorrect password',
  'forgotten-password.password-reset': 'Password reset',
  'forgotten-password.reset': 'Reset password',
  'forgotten-password.success':
    'If there exists a user with this email, the password has been reset. Please check your inbox and follow the instructions.',
  'forgotten-password.title': 'Forgotten password',
  'forum.lastPosts': 'Last threads',
  'forum.lastThreads': 'Last posts',
  'forum.new': 'New thread',
  'handbook.create-entry': 'New entry',
  'handbook.create-folder': 'New folder',
  'handbook.create-sub-folder': 'Create subfolder',
  'handbook.edit-entry': 'Edit entry',
  'handbook.edit-folder': 'Edit folder',
  'handbook.edit-sub-folder': 'Edit subfolder',
  'handbook.entries': 'Entries',
  'handbook.errors.create-entry': 'Could not create entry',
  'handbook.errors.delete-attachment': 'Could not delete attachment',
  'handbook.errors.entry-not-found': 'Entry not found',
  'handbook.errors.folder-delete': 'Error deleting folder',
  'handbook.errors.folder-not-found': 'The folder was not found',
  'handbook.errors.folder-shift': 'Could not change order of folders',
  'handbook.errors.patch-entry': 'Could not update entry',
  'handbook.folders': 'Folders',
  'handbook.n-entries': '{n, plural, one {1 entry} other {{n} entries}}',
  'handbook.n-sub-folders':
    '{n, plural, one {1 subfolder} other {{n} subfolders}}',
  'handbook.new-entry': 'New entry',
  'handbook.no-content': 'No content',
  'handbook.no-folders': 'No folders',
  'handbook.prompts.delete-entry-attachment':
    'Are you sure you want to delete this attachment?',
  'handbook.prompts.delete-entry-attachment-title': 'Delete attachment',
  'handbook.prompts.delete-entry-prompt':
    'Are you quite sure you wish to delete this entry?',
  'handbook.prompts.delete-entry-title': 'Delete entry',
  'handbook.prompts.delete-file-prompt':
    'Are you quite sure you wish to delete the file "{fileName}"?',
  'handbook.prompts.delete-file-title': 'Delete file',
  'handbook.prompts.delete-folder-prompt':
    'Are you quite sure you wish to delete this folder? All content will also be deleted.',
  'handbook.prompts.delete-folder-title': 'Delete folder',
  'handbook.search-for-content': 'Search for content',
  'handbook.search-for-folders': 'Search for folders',
  'handbook.toasts.entry-created': 'The entry was created',
  'handbook.toasts.entry-deleted': 'The entry was deleted',
  'handbook.toasts.entry-updated': 'The entry was updated',
  'handbook.toasts.folder-created': 'The folder was created',
  'handbook.toasts.folder-deleted': 'The folder was deleted',
  'handbook.toasts.folder-updated': 'The folder was updated',
  'handbook.updated-when': 'Updated {when}',
  'issues.cost-estimation': 'Estimated cost',
  'issues.damage-description-long':
    'What is damaged — Provide both direct and indirect objects',
  'issues.issue-description-about': 'About the issue',
  'issues.solution-suggestion-about': 'Suggestion for solution',
  'issues.success-creation': 'Created new issue',
  'login.all-rights-reserved-part-1': 'Digitech is software delivered by ',
  'login.all-rights-reserved-part-2': ' All rights reserved.',
  'login.disclaimer':
    "This is a test-page. If you're looking for the offisial webpage, that's at <a>app.digitech.no</a>.",
  'login.forgot-your-password': 'Have you forgotten your password?',
  'login.logging-out': 'Signing out',
  'login.password': 'Password',
  'login.register-company-and-user': 'Register company and user',
  'login.stay-logged-in': 'Stay logged in',
  'login.submit': 'Sign in',
  'login.title': 'Log in',
  'login.username': 'Username',
  'login.username-email': 'Username/e-mail',
  'login.wrong-email-password': 'Wrong e-mail/password',
  'offers.contact-person-email-missing':
    'Contact person email is missing on the customer',
  'offers.contribution-margin': 'CM-materials',
  'offers.contribution-percentage': 'Contribution percentage',
  'offers.cost': 'Cost',
  'offers.create': 'Create offer',
  'offers.created-offers': 'Created offers',
  'offers.customer-email': 'Customer email',
  'offers.edit-offer': 'Edit offer',
  'offers.errors.could-not-send':
    'Could not send the offer by email - does the customer have a valid email address?',
  'offers.errors.send-offer': 'Could not send offer by email',
  'offers.excluding-vat': 'ex. VAT',
  'offers.installation-equipment-cost': 'Cost of installation equipment',
  'offers.installation-time': 'Hours of installation',
  'offers.offer': 'Offer',
  'offers.offer-sent': 'The offer was sent to',
  'offers.offers': 'Offers',
  'offers.prompts.delete-offer': 'Are you sure you want to delete this offer?',
  'offers.prompts.delete-offer-title': 'Delete offer',
  'offers.recipient': 'Recipient',
  'offers.save-and-set-status-to-created':
    'Save and set offer status to "created"',
  'offers.search': 'Search in offers',
  'offers.search-for-offer': 'Search for offer...',
  'offers.sent-and-created-offers': 'Sent and created offers',
  'offers.sent-offers': 'Sent offers',
  'offers.status.accepted': 'Accepted',
  'offers.status.created': 'Created',
  'offers.status.declined': 'Declined',
  'offers.status.finished': 'Finished',
  'offers.status.sent': 'Sent',
  'offers.success.offer-was-sent': 'The offer was sent by email',
  'offers.toasts.create-offer-success': 'The offer was created',
  'offers.toasts.delete-offer-success': 'The offer was deleted',
  'offers.toasts.edit-offer-success': 'The offer was updated',
  'offers.total-cost': 'Total cost of offer',
  'permissions.no-access-to-page':
    'Your user does not have access to this page',
  'products.article-number': 'Article number',
  'products.cost-price': 'Cost price',
  'products.create-product': 'Create product',
  'products.create-product-list': 'Create product list',
  'products.edit-product': 'Edit product',
  'products.errors.fields-missing':
    'Could not import product list since these columns are missing: {fields}',
  'products.errors.import-product-list': 'Could not import the product list',
  'products.import-products': 'Import products',
  'products.imported': 'Imported',
  'products.imported-products': 'Imported products',
  'products.manual': 'Manual',
  'products.manually-created-products': 'Manually created products',
  'products.merchant': 'Merchant',
  'products.name-of-merchant': 'Name of merchant',
  'products.name-of-product': 'Name of product',
  'products.price': 'Price',
  'products.product': 'Product',
  'products.product-image': 'Product image',
  'products.product-list-imported': 'The product list was imported',
  'products.prompts.delete-product':
    'Are you sure you want to delete this product?',
  'products.prompts.delete-product-title': 'Delete product',
  'products.search-for-products': 'Search for products...',
  'products.toasts.create-success': 'The product was created',
  'products.toasts.patch-success': 'The product was updated',
  'products.toasts.product-deleted-success': 'The product was deleted',
  'products.unit': 'Unit',
  'products.units.litre': 'Litre',
  'products.units.meter': 'Meter',
  'products.units.parcel': 'Parcel',
  'products.units.pcs': 'Piece',
  'products.units.set': 'Set',
  'products.units.square-meters': 'Square meters',
  'projects.address': 'Project address',
  'projects.billing-type': 'Billing type',
  'projects.billing-type.fixed': 'Fixed price',
  'projects.billing-type.hourly': 'Hourly',
  'projects.billing-type.internal': 'Internal',
  'projects.create': 'Create project',
  'projects.create-project': 'Create project',
  'projects.create-project-with-test-customer':
    'Create project with test customer',
  'projects.create-success': 'Created project',
  'projects.creation-of-projects': 'Creation of projects',
  'projects.delete-error': 'The project could not be deleted.',
  'projects.delete-success': 'The project was deleted.',
  'projects.deleting-project': 'Deleting project',
  'projects.edit-project': 'Edit project',
  'projects.edit-success': 'Project info was edited.',
  'projects.edit-title': 'Edit project',
  'projects.errors.edit': 'The project info could not be edited',
  'projects.estimated-completion-time': 'Estimated completion time',
  'projects.hourly-rate': 'Hourly rate',
  'projects.hours-spent': 'Hours spent',
  'projects.material-discount': 'Material discount',
  'projects.name': 'Project name',
  'projects.no-files': 'No files',
  'projects.no-folders': 'No folders',
  'projects.no-more-projects': 'No more projects',
  'projects.no-project': 'No project',
  'projects.no-projects': 'No projects',
  'projects.no-projects-matching-search-query':
    'No projects matching search query',
  'projects.page-description':
    'Welcometo the project page. Here you will get a table with useful information and functions associated with your projects. A project must have a customer, and this can be created under "Customers" in the navigation on the left. If you want to get started, you can create a project with the included test customer.',
  'projects.project-id': 'Project ID',
  'projects.project-leader': 'Project lead',
  'projects.prompts.delete':
    'Are you sure you want to delete this project? It can be restored later by an administrator.',
  'projects.prompts.delete-not-permitted':
    'You do not have access to delete this project',
  'projects.prompts.delete-title': 'Delete project',
  'projects.prompts.edit-not-permitted':
    'You do not have access to edit this project',
  'projects.search': 'Search for projects...',
  'projects.select-billing-type': 'Select billing type',
  'projects.select-project': 'Select project',
  'projects.stage-billing': 'Billing',
  'projects.stage-finished': 'Finished',
  'projects.stage-ongoing': 'Ongoing',
  'projects.stage-planning': 'Planning',
  'projects.status': 'Status',
  'projects.toasts.create-error': 'The project could not be created',
  'projects.toasts.create-success': 'The project was created',
  'projects.your-projects': 'Your projects',
  'select-company.select': 'Select company',
  'server.general-error-try-again-later':
    'Server error. Please try again later.',
  'server.general-error-update':
    'Server error. Please try refreshing the page.',
  'timesheets.absence': 'Absence',
  'timesheets.absence-illness': 'Illness',
  'timesheets.absence-paid-leave': 'Paid leave',
  'timesheets.absence-self-declaration': 'Self-certification of absence',
  'timesheets.absence-sick-leave': 'Sick leave',
  'timesheets.absence-timebank-withdrawal': 'Timebank withdrawal',
  'timesheets.absence-type': 'Absence type',
  'timesheets.absence-type-illness-self-declaraion': 'Self decleared illness',
  'timesheets.absence-type-illness-sick-leave': 'Sick leave',
  'timesheets.absence-type-paid-leave': 'Paid leave',
  'timesheets.absence-type-timebank-withdrawal': 'Timebank withdrawal',
  'timesheets.absence-type-unpaid-leave': 'Unpaid leave',
  'timesheets.absence-type-vacation': 'Vacation',
  'timesheets.absence-unpaid-leave': 'Unpaid leave',
  'timesheets.absence-vacation': 'Vacation',
  'timesheets.create-time-entry-success': 'Time entry has been registered',
  'timesheets.date-mode-month-year': 'Month/year',
  'timesheets.delete-absence-prompt-text':
    'Are you sure you want to delete this absence?',
  'timesheets.edit-absence': 'Edit absence',
  'timesheets.edit-absence-for-name': 'Edit absence for {name}',
  'timesheets.edit-time-entry': 'Edit time entry',
  'timesheets.errors.absence-does-not-exist': 'Absence does not exist',
  'timesheets.errors.delete-absence': 'Could not delete absence',
  'timesheets.errors.delete-time-entry': 'Could not delete time entry',
  'timesheets.errors.hours-range': 'Must be more than 0 and max 24',
  'timesheets.errors.send-email':
    'Could not send the timesheet by email - have you entered a valid email address?',
  'timesheets.errors.time-entry-does-not-exist': 'Time entry does not exist',
  'timesheets.hour': 'Hour',
  'timesheets.hours': 'Hours',
  'timesheets.hours-ordinary': 'Ordinary',
  'timesheets.hours-overtime-fifty': 'Overtime 50',
  'timesheets.hours-overtime-hundred': 'Overtime 100',
  'timesheets.hours-time-bank-fifty': 'Time bank 50',
  'timesheets.hours-time-bank-hundred': 'Time bank 100',
  'timesheets.hours-time-bank-ordinary': 'Time bank ordinary',
  'timesheets.kilometers-driven': 'Kilometers driven',
  'timesheets.kilometers-driven-alt': 'KM driven',
  'timesheets.no-entries': 'No entries',
  'timesheets.number-of-hours': 'Number of hours',
  'timesheets.open': 'Open time entry',
  'timesheets.ordinary-hours': 'Ordinary hours',
  'timesheets.prompts.delete-absence-title': 'Delete absence',
  'timesheets.prompts.delete-time-entry':
    'Are you sure you want to delete this time entry?',
  'timesheets.prompts.delete-time-entry-title': 'Delete time entry',
  'timesheets.prompts.include-absences': 'Do you want to include absences?',
  'timesheets.prompts.include-absences-title': 'Print timesheets',
  'timesheets.prompts.send-email': 'Give recipient email address',
  'timesheets.prompts.send-email-title': 'Send timesheet by email',
  'timesheets.register-absence': 'Register absence',
  'timesheets.register-absence-alt': '+ absence',
  'timesheets.register-absence-for-name': 'Register absence for {name}',
  'timesheets.register-hours': 'Register hours',
  'timesheets.register-time-entry': 'Register time entry',
  'timesheets.register-time-entry-alt': '+ time entry',
  'timesheets.registered-by': 'Registered by',
  'timesheets.registered-hours': 'Registered hours',
  'timesheets.retrieve-hours': 'Retrieve hours',
  'timesheets.self-declaration-types.personal-illness': 'Personal illness',
  'timesheets.self-declaration-types.second-party-illness':
    'Illness with child/nanny',
  'timesheets.show-absence': 'Show absence',
  'timesheets.show-spent-hours': 'Show spent hours',
  'timesheets.spent-hours': 'Spent hours',
  'timesheets.success.delete-absence': 'The absence was deleted',
  'timesheets.success.delete-time-entry': 'The time entry was deleted',
  'timesheets.success.send-email': 'The timesheet was sent by email',
  'timesheets.this-week': 'This week',
  'timesheets.time-bank-balance': 'Time bank balance',
  'timesheets.time-bank-ordinary-shorthand': 'Time bank ord.',
  'timesheets.time-entry-type': 'Time entry type',
  'timesheets.toasts.create-absence-error':
    'An unknown error occured while creating the absence',
  'timesheets.toasts.create-absence-success': 'The absence has been registered',
  'timesheets.toasts.edit-absence-error':
    'An unknown error occured while updating the absence',
  'timesheets.toasts.edit-absence-success': 'The absence was edited',
  'timesheets.toasts.edit-time-entry-success': 'The time entry was updated',
  'timesheets.toasts.no-delete-absence-permission':
    'You do not have permission to delete this absence',
  'timesheets.toasts.no-delete-time-entry-permission':
    'You do not have permission to delete this time entry',
  'timesheets.toasts.no-edit-absence-permission':
    'You do not have permission to edit this absence',
  'timesheets.toasts.no-edit-time-entry-permission':
    'You do not have permission to edit this time entry',
  'timesheets.total-n-hours':
    '{n, plural, one {<span>Total:</span> 1 hour} other {<span>Total:</span> {n} hours}}',
  'timesheets.visible-columns': 'Visible columns',
  'timesheets.withdrawn': 'Withdrawn',
  'timsheets.time-bank': 'Time bank',
  'toasts.error': 'Error',
  'toasts.info': 'Info',
  'toasts.success': 'Success',
  'toasts.warning': 'Warning',
  'user-guide.introduction': 'Introduction',
  'users.add-user': 'Add user',
  'users.add-user-type': 'Add user type',
  'users.administrator-warning':
    'This user type is an administrator, and as such has all permissions by default. Changes made here will not have any effect.',
  'users.all-permissions': 'All permissions',
  'users.all-permissions-help-text':
    'This user type  will be treated as an administrator and will be given all permissions regardless of what permissions are given in the user type overview',
  'users.change-password': 'Change password',
  'users.change-user-type': 'Change user type',
  'users.city': 'City',
  'users.create-success': 'Created user',
  'users.create-user': 'Create user',
  'users.create-user-type': 'Create user type',
  'users.current-password': 'Current password',
  'users.delete-error': 'The user could not be deleted.',
  'users.delete-success': 'The user was deleted.',
  'users.edit-name': 'Edit {firstName}',
  'users.edit-user': 'Edit user',
  'users.edit-user-profile': 'Edit user profile',
  'users.edit-user-type': 'Edit user type',
  'users.errors.change-password': 'Could not change password, plase try again',
  'users.errors.change-password-check-current':
    'The current password you provided was incorrect',
  'users.errors.change-password-weak': 'Password is too weak',
  'users.errors.create-user': 'Could not create user',
  'users.errors.edit-user': 'Could not edit user information, please try again',
  'users.errors.email-must-be-unique': 'The email address is already in use',
  'users.errors.email-taken': 'Email address taken',
  'users.errors.emergency-contact-name-exists':
    'You already have an emergency contact with this name.',
  'users.errors.not-your-user': 'You can only edit your own user',
  'users.is-active': 'Active',
  'users.is-staff': 'Staff',
  'users.is-superuser': 'Superuser',
  'users.last-login': 'Last login',
  'users.needs-password-change': 'Needs password change',
  'users.new': 'New user',
  'users.new-password': 'New password',
  'users.password': 'Password',
  'users.password-change-required':
    'Your password has been reset, please provide a new one.',
  'users.password-confirm': 'Confirm password',
  'users.password-incorrect': 'Incorrect password',
  'users.passwords-do-not-match': 'Passwords do not match',
  'users.postal-code': 'Postal code',
  'users.primary-contact': 'Primary contact',
  'users.prompt.delete-user':
    'Are you sure you want to delete this user? It can be restored later by an administrator.',
  'users.prompt.delete-user-title': 'Delete user',
  'users.prompts.delete-emergency-contact':
    'Are you sure you want to delete this emergency contact?',
  'users.prompts.delete-emergency-contact-title': 'Delete emergency contact',
  'users.prompts.remove-user-type': 'Remove user type',
  'users.prompts.remove-user-type-text':
    'Are you sure you want to remove the user type "{name}" from this user?',
  'users.register': 'Register',
  'users.register-message':
    'Please enter the following information to activate your user.',
  'users.register-user': 'Register user',
  'users.registered': 'Registered',
  'users.relations.child': 'Child',
  'users.relations.friend': 'Friend',
  'users.relations.other': 'Other',
  'users.relations.parent': 'Parent',
  'users.relations.partner': 'Partner',
  'users.relations.select-relation': 'Select relation...',
  'users.relations.sibling': 'Sibling',
  'users.relations.spouse': 'Spouse',
  'users.remember': 'Remember me',
  'users.search': 'Search for user',
  'users.select-a-user': 'Select a user',
  'users.select-a-user-type': 'Select a user type',
  'users.select-user': 'Select user',
  'users.select-user-type': 'Select user type',
  'users.select-user-types': 'Select user types',
  'users.settings.change-user-setting-error':
    'An error occured while updating the user setting',
  'users.special-permissions': 'Special permissions',
  'users.special-permissions.users.project_leader': 'Can be project leader',
  'users.to-my-profile': 'To my profile',
  'users.toasts.cannot-delete-emergency-contact':
    'You do not have access to delete this emergency contact',
  'users.toasts.cannot-edit-emergency-contact':
    'You do not have access to edit this emergency contact',
  'users.toasts.change-password-success': 'Password changed',
  'users.toasts.create-emergency-contact-error':
    'Could not create emergency contact',
  'users.toasts.create-emergency-contact-success':
    'The emergency contact was created',
  'users.toasts.create-user-success': 'User created',
  'users.toasts.delete-emergency-contact-error':
    'Could not delete emergency contact',
  'users.toasts.delete-emergency-contact-success':
    'The emergency contact was deleted',
  'users.toasts.edit-emergency-contact-error':
    'Could not edit emergency contact',
  'users.toasts.edit-emergency-contact-success':
    'The emergency contact was edited',
  'users.toasts.edit-user-success': 'User information edited',
  'users.toasts.error-changing-primary-contact':
    'The primary contact could not be changed',
  'users.toasts.primary-contact-changed': 'The primary contact was changed',
  'users.toasts.user-type-create-error': 'Could not create user type',
  'users.toasts.user-type-create-success': 'User type created',
  'users.toasts.user-type-update-error': 'Could not edit user type',
  'users.toasts.user-type-update-success': 'User type changed',
  'users.user': 'User',
  'users.user-not-registered': 'This user has not yet registered their profile',
  'users.user-type': 'User type',
  'users.user-type-administrator-help-text':
    'A user type with the administrator flag has all permissions implicitly.',
  'users.user-types': 'User types',
  'users.username': 'Username',
}
export default en

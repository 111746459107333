import { useTranslate } from '@ur/react-hooks'
import { images } from 'assets/images/userGuide'
import { useLanguage } from 'locale/TranslationProvider'
import { AllowedLanguage } from 'locale/util'
import { useMemo } from 'react'
import { UserGuideTab } from '.'

export interface UserGuideSection {
  id: UserGuideTab
  title: string
  content: JSX.Element
  subSections?: UserGuideSection[]
}

const content: Record<UserGuideTab, Record<AllowedLanguage, JSX.Element>> = {
  introduction: {
    en: (
      <p>
        Here you will find a review of the most important elements of Digitech.
        It can be a good reference if you are wondering where to find important
        functionality, or if you have any questions about the use of Digitech.
        If you do not find what you are looking for, just send us an email by
        pressing the support button in the main menu.
      </p>
    ),
    nb: (
      <p>
        Her finner du en gjennomgang av de viktigste elementene i Digitech. Det
        kan være et godt oppslagsverk hvis du lurer på hvor du finner viktig
        funksjonalitet, eller om du har noen spørsmål om bruken av Digitech.
        Hvis du ikke finner det du lurer på, send oss en mail ved å trykke på
        support-knappen i hovedmenyen.
      </p>
    ),
  },
  dashboard: {
    en: (
      <>
        <p>
          My page comes in two varieties. One for project managers and one for
          standard users.
        </p>

        <p>The project manager version has the following modules:</p>

        <ul>
          <li>
            Overview of registered items, this is the number of offers,
            checklists, deviations and new projects that are registered in the
            selected period.
          </li>

          <li>
            Sent and created offers: Here you see all offers that have been sent
            and created on your projects. You can change the status from the
            overview and by using the menu send and change the offer.
          </li>

          <li>
            Deviations under processing: All deviations that are being processed
            where you are the project manager.
          </li>

          <li>
            My projects: Projects where you are the project manager. Filter
            automatically to show only ongoing projects, tap the filter icon to
            see all.
          </li>
        </ul>

        <img
          src={images.DashboardProjectManager}
          alt="My page: project manager"
        />

        <p>Standard users have the following modules:</p>

        <ul>
          <li>Overview of registered hours per period.</li>

          <li>Ongoing checklists created by the user.</li>

          <li>Deviations registered by the user which are under processing.</li>

          <li>
            Projects where hours, checklists and deviations are registered by
            the user can be found in the project overview. This is filtered by
            status, with ongoing by default. Tap the filter icon to see more
            projects.
          </li>
        </ul>

        <img src={images.DashboardStandard} alt="My page: standard user" />
      </>
    ),
    nb: (
      <>
        <p>
          Min side finnes i to varianter. En for prosjektledere og en for
          standardbrukere.
        </p>

        <p>Prosjektleder-versjonen har følgende moduler:</p>

        <ul>
          <li>
            Oversikt over registrerte elementer, dette er antall tilbud,
            sjekklister, avvik og nye prosjekter som er registrert i den valgte
            perioden.
          </li>

          <li>
            Sendte og opprettede tilbud: Her ser du alle tilbud som er sendt og
            opprettet på dine prosjekter. Du kan endre status fra oversikten og
            ved å bruke menyen sende og endre tilbudet.
          </li>

          <li>
            Avvik under behandling: Alle avvik som er under behandling der du er
            prosjektleder.
          </li>

          <li>
            Mine prosjekter: Prosjekter hvor du er prosjektleder. Filtrers
            automatisk til å bare vise pågående prosjekter, trykk på
            filtreringsikonet for å se alle.
          </li>
        </ul>

        <img
          src={images.DashboardProjectManager}
          alt="Min side: prosjektleder"
        />

        <p>Standardbrukere har følgende moduler:</p>

        <ul>
          <li>Oversikt over registrerte timer per periode.</li>

          <li>Pågående sjekklister opprettet av brukeren.</li>

          <li>Avvik registrert av brukeren som er under behandling.</li>

          <li>
            Prosjekter hvor timer, sjekklister og avvik er registrert av
            brukeren finnes i prosjektoversikten. Denne filtreres på status, med
            pågående som standard.
          </li>
        </ul>

        <img src={images.DashboardStandard} alt="Min side: standardbruker" />
      </>
    ),
  },
  projects: {
    en: (
      <>
        <p>
          Under projects you will find an overview of all the projects in the
          company. Here you can sort by name, customer, date, hours spent and
          hourly estimate. In the status column, you can easily select which
          statuses you want to see and you can easily change the status directly
          from the overview screen by tapping the current status in the table.
        </p>

        <img src={images.ProjectTable} alt="Project table" />

        <p>
          If you click on a project, you get to the overview of the project,
          this consists of several tabs. You can go directly to these by
          pressing the ellipse menu from the project overview.
        </p>

        <img src={images.Project} alt="Project" />
      </>
    ),
    nb: (
      <>
        <p>
          Under prosjekter finner du oversikt over alle prosjektene i bedriften.
          Her kan du sortere på navn, kunde, dato, timeforbruk og timeestimat. I
          status kolonnen kan du enkelt velge hvilke statuser du ønsker å se og
          du kan enkelt endre status direkte fra oversiktsbildet ved å trykke på
          den aktuelle statusen i tabellen.
        </p>

        <img src={images.ProjectTable} alt="Prosjekttabell" />

        <p>
          Om du trykker på et prosjekt kommer du til oversikten over prosjektet,
          denne består av flere faner. Du kan gå direkte til disse ved å trykke
          på ellipsemenyen fra prosjektoversikten.
        </p>

        <img src={images.Project} alt="Prosjekt" />
      </>
    ),
  },
  createProject: {
    en: (
      <>
        <p>
          Create project by clicking on the blue button on the desktop or blue
          circle with + sign on mobile. Enter information about the project and
          save.
        </p>

        <img src={images.CreateProject} alt="Create project" />
      </>
    ),
    nb: (
      <>
        <p>
          Opprett prosjekt ved å klikke på den blå knappen på desktop eller blå
          sirkel med + tegn på mobil. Legg inn opplysninger om prosjektet og
          lagre.
        </p>

        <img src={images.CreateProject} alt="Opprett prosjekt" />
      </>
    ),
  },
  offers: {
    en: (
      <p>
        From a project, you can create offers for customers. This can easily be
        sent directly to the email found on the customer card. It is also
        possible to change the email to which the offer is sent before sending.
        The offer is created automatically and sent as a PDF with all the info
        you have entered in the offer module. The project manager will
        automatically receive an e-mail with a copy of the offer that has been
        sent. The status of the offer can easily be changed for a keeping a good
        overview.
      </p>
    ),
    nb: (
      <p>
        Under et prosjekt kan du opprette tilbud til kunde. Dette kan enkelt
        sendes direkte på epost som er lagt inn på kundekortet. Det er også
        mulig å endre eposten som tilbudet sendes til før sending. Tilbudet
        dannes automatisk og sendes som PDF med all info du har lagt inn i
        tilbudsmodulen. Prosjektleder vil automatisk få tilsendt en e-post med
        kopi av tilbudet som er sendt. Status på tilbudet kan enkelt endres for
        god oversikt.
      </p>
    ),
  },
  files: {
    en: (
      <>
        <p>
          Under the Files tab, you can create folders and upload files related
          to the project. These can be drawings, meeting minutes, FDV and other
          relevant info for the project. Here, the user will have access to
          up-to-date info out in the field. Under company settings, you can
          create a standard folder structure that is automatically created when
          creating a new project.
        </p>

        <p>
          It is possible to use "drag and drop" functionality for uploading
          files, just drag the files you want to upload into the table.
        </p>
      </>
    ),
    nb: (
      <>
        <p>
          Under fanen filer kan du opprette mapper og laste opp filer relatert
          til prosjektet. Det kan være tegninger, møtereferat, FDV og annen
          relevant info til prosjektet. Her vil montør ha tilgang til oppdatert
          info ute i felt. Under firmainnstillinger kan man opprette en standard
          mappestruktur som automatisk opprettes ved opprettelse av nytt
          prosjekt.
        </p>

        <p>
          Det er mulig å bruke «drag and drop» funksjonalitet for opplasting av
          filer, bare trekk filene du ønsker å laste opp inn i tabellen.
        </p>
      </>
    ),
  },
  customers: {
    en: (
      <>
        <p>
          "Customers" shows an overview of all customers created. Here it is
          easy to sort by name, contact person, email, telephone and whether it
          is a private or corporate customer. Create a new customer by clicking
          on the blue button «Create customer +» on desktop or blue circle with
          + on mobile.
        </p>

        <p>
          When creating a corporate customer, you can choose to enter the
          organization number or company name to get alternatives from the
          Brønnøysund register to have company information filled in
          automatically.
        </p>
      </>
    ),
    nb: (
      <>
        <p>
          «Kunder» viser en oversikt over alle kunder som er opprettet. Her er
          det enkel sortering på navn, kontaktperson, epost, telefon og om det
          er privat- eller bedriftskunde. Opprett ny kunde ved å klikke på den
          blå knappen «Opprett kunde +» på desktop eller blå sirkel med + på
          mobil.
        </p>

        <p>
          Ved opprettelse av bedriftskunde kan du velge å skrive inn
          organisasjonsnummer eller bedriftsnavn for å få opp alternativer fra
          Brønnøysundregisteret for å få automatisk utfylt selskapsinformasjon.
        </p>
      </>
    ),
  },
  users: {
    en: (
      <p>
        Get an overview of all employees, with contact information and
        relatives' information. Administrator can add new users by clicking on
        blue cap, easily make changes and change user types with different
        viewing permissions. Here, everyone will see contact information and
        relatives' information for all employees.
      </p>
    ),
    nb: (
      <p>
        Få oversikt over alle ansatte, med kontaktinformasjon og pårørendeinfo.
        Administrator kan legge inn nye brukere ved å klikke på blå kapp, gjør
        enkelt endringer og enkelt endre brukertyper med forskjellige
        rettigheter. Her vil alle se kontaktinformasjon og pårørendeinfo til
        alle ansatte.
      </p>
    ),
  },
  products: {
    en: (
      <p>
        Import product catalog from supplier or enter manual products. This
        allows employees to have an overview of which products can be used as a
        basis for calculating the price of offers. You can have a list of
        products per supplier. If you upload a new file to the same supplier,
        the old products will be replaced with the products in the new list.
      </p>
    ),
    nb: (
      <p>
        Importer produktkatalog fra leverandør eller legg inn manuelle
        produkter. Dette lar ansatte ha oversikt over hvilke produkter som kan
        benyttes som grunnlag for å regne ut pris til tilbud. Du kan ha en liste
        med produkter per leverandør. Hvis du laster opp en ny fil til samme
        leverandør vil de gamle produktene bli erstattet med produktene som
        finnes i den nye listen.
      </p>
    ),
  },
  timesheets: {
    en: (
      <p>
        Get an overview of all your hours and absences. Click on the blue button
        to register hours on the project. You can filter by time periods and
        projects to see the information that is important to you. An
        administrator will also have the opportunity to see everyone's hours,
        and will thus have the opportunity to filter on selected users.
      </p>
    ),
    nb: (
      <p>
        Få en oversikt over alle dine timer og fravær. Klikk på blå knapp for å
        registrere timer på prosjekt. Du kan filtrere på tidsperioder og
        prosjekter for å se den informasjonen som er viktig for deg. En
        administrator vil også ha mulighet til å se alles timer, og vil dermed
        ha mulighet til å filtrere på utvalgte brukere.
      </p>
    ),
  },
  checklists: {
    en: (
      <>
        <p>
          Get an overview of all checklists that are ongoing and completed. Here
          you can also easily create a new checklist on a project by choosing a
          template that suits the purpose and completing it.
        </p>

        <p>
          When the checklist has been completed, press «Submit checklist». The
          checklist is then sent as a copy by email to the project manager, who
          can further send this to the customer. The checklist is completed when
          all the checklist items have been processed, with either OK or IR
          (irrelevant).
        </p>

        <p>
          To create a checklist, you need a checklist template. If you can not
          create a checklist template yourself, ask your administrator if they
          can create one for you.
        </p>
      </>
    ),
    nb: (
      <>
        <p>
          Få en oversikt over alle sjekklister som er pågående og ferdigstilte.
          Her kan du også enkelt opprette en ny sjekkliste på et prosjekt ved å
          velge en mal som passer formålet og fullføre denne.
        </p>

        <p>
          Når sjekkliste er ferdig utfylt trykker man på «Send inn sjekkliste».
          Sjekklisten sendes da som kopi på epost til prosjektleder, som videre
          kan sende dette til kunde. Sjekklisten er ferdig utfylt når alle
          sjekklistepunktene er behandlet, med enten OK eller IR (irrelevant).
        </p>

        <p>
          For å opprette en sjekkliste trenger du en sjekklistemal. Hvis du ikke
          kan opprette en sjekklistemal selv, spør din administrator om de kan
          opprette en for deg.
        </p>
      </>
    ),
  },
  deviations: {
    en: (
      <p>
        Easy deviation handling on a project or internally. Register deviations
        on project by clicking on blue button. A registered deviation is sent as
        a copy by email to the project manager, who can further process the
        deviation.
      </p>
    ),
    nb: (
      <p>
        Enkel avvikshåndtering på et prosjekt eller internt. Registrer avvik på
        prosjekt ved å klikke på blå knapp. Et registrert avvik sendes som kopi
        på epost til prosjektleder, som videre kan behandle avviket mot bedrift
        eller internt.
      </p>
    ),
  },
  handbook: {
    en: (
      <>
        <p>
          The company's handbook where you can create a folder structure and
          posts with information that employees should have access to. Example:
          HSE Handbook, KS Handbook, Personnel Handbook etc.
        </p>

        <img src={images.Handbook} alt="Handbook" />
      </>
    ),
    nb: (
      <>
        <p>
          Bedriftens håndbok der man kan opprette mappestruktur og innlegg med
          informasjon som ansatte skal ha tilgang til. Eksempel: HMS-Håndbok,
          KS-håndbok, Personalhåndbok etc.
        </p>

        <img src={images.Handbook} alt="Håndbok" />
      </>
    ),
  },
}

export const useUserGuideSections = () => {
  const translations = useTranslate({
    introduction: 'user-guide.introduction',
    dashboard: 'common.my-site',
    projects: 'common.projects',
    creationOfProjects: 'projects.creation-of-projects',
    offers: 'common.offers',
    files: 'common.files',
    customers: 'common.customers',
    users: 'common.users',
    products: 'common.products',
    timesheets: 'common.timesheets',
    checklists: 'common.checklists',
    deviations: 'common.deviations',
    handbook: 'common.handbook',
  })

  const [language] = useLanguage()

  const sections = useMemo<UserGuideSection[]>(
    () => [
      {
        id: 'introduction',
        title: translations.introduction,
        content: content.introduction[language],
      },
      {
        id: 'dashboard',
        title: translations.dashboard,
        content: content.dashboard[language],
      },
      {
        id: 'projects',
        title: translations.projects,
        content: content.projects[language],
        subSections: [
          {
            id: 'createProject',
            title: translations.creationOfProjects,
            content: content.createProject[language],
          },
          {
            id: 'offers',
            title: translations.offers,
            content: content.offers[language],
          },
          {
            id: 'files',
            title: translations.files,
            content: content.files[language],
          },
        ],
      },
      {
        id: 'customers',
        title: translations.customers,
        content: content.customers[language],
      },
      {
        id: 'users',
        title: translations.users,
        content: content.users[language],
      },
      {
        id: 'products',
        title: translations.products,
        content: content.products[language],
      },
      {
        id: 'timesheets',
        title: translations.timesheets,
        content: content.timesheets[language],
      },
      {
        id: 'checklists',
        title: translations.checklists,
        content: content.checklists[language],
      },
      {
        id: 'deviations',
        title: translations.deviations,
        content: content.deviations[language],
      },
      {
        id: 'handbook',
        title: translations.handbook,
        content: content.handbook[language],
      },
    ],
    [translations, language]
  )

  return sections
}

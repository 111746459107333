import { Message } from './Message'
import { MessageError } from './MessageError'

type MessageWithVariantsType = {
  Error: typeof MessageError
} & typeof Message
const MessageWithVariants = Message as MessageWithVariantsType
MessageWithVariants.Error = MessageError

export { MessageWithVariants as Message, MessageError }

import React from 'react'
import styled, { css } from 'styled-components'
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from '@ur/react-components'

interface StyledCheckboxProps {
  disabled?: boolean
}
const StyledCheckbox = styled(BaseCheckbox)<StyledCheckboxProps>`
  .--checkbox-box {
    border-color: ${props => props.theme.colors.gray5};
  }
  &:focus {
    outline: none;

    .--checkbox-box {
      border-color: ${props => props.theme.colors.primary};
    }
  }
  ${props =>
    props.disabled &&
    css`
      .--checkbox-box {
        background-color: ${props => props.theme.colors.gray7};
      }
    `};
`

interface CheckboxProps extends BaseCheckboxProps {}

export const CheckBox: React.FC<CheckboxProps> = ({ ...props }) => {
  return <StyledCheckbox {...props} />
}

import React, { useCallback, useMemo } from 'react'
import { PromptOptions, PromptResolve, usePrompt } from '@ur/react-components'
import styled from 'styled-components'
import { useTranslate } from '@ur/react-hooks'
import { buttonUnset, overloadColor } from 'util/style'
import merge from 'lodash/merge'

interface StyledConfirmProps {
  hasTitle: boolean
}
const StyledConfirm = styled.aside<StyledConfirmProps>`
  max-width: 600px;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border: 1rem solid white;
  background: white;
  overflow: hidden;

  header {
    padding: 1rem 2rem;
    font-weight: 700;
  }
  p.content {
    margin: 0;
    padding: ${props => (props.hasTitle ? '0 2rem 2rem' : '2rem')};
  }
  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 0rem 2rem 1rem;
  }

  ${props => props.theme.media.mobile} {
    width: 96vw;
    max-width: 96vw;

    header {
      padding: 1rem;
    }
    p.content {
      padding: ${props => (props.hasTitle ? '0 1rem 1rem' : '1rem')};
    }
    div.buttons {
      padding: 0rem 1rem 1rem;
    }
  }
`
interface ConfirmButtonProps {
  color: string
  background: string
  hoverBackground: string
}
const ConfirmButton = styled.button<ConfirmButtonProps>`
  ${buttonUnset}

  width: 100%;
  padding: 1rem 0;

  background: inherit;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  color: ${props => overloadColor(props.color)};
  background: ${props => overloadColor(props.background)};

  &:hover {
    background: ${props => overloadColor(props.hoverBackground)};
  }
  &:focus {
    text-decoration: underline;
  }
`

interface ConfirmProps
  extends Omit<UseConfirmOptions, 'variant' | 'promptOptions'> {
  prompt: string | null
  title?: string

  onResolve: PromptResolve<boolean>
}
const Confirm: React.FC<ConfirmProps> = ({
  prompt,
  title,
  denyText,
  confirmText,

  denyColor = 'gray3',
  denyBackground = 'gray7',
  denyHoverBackground = 'gray8',
  confirmColor = 'white',
  confirmBackground = 'primary',
  confirmHoverBackground = 'primaryHover',

  onResolve,
}) => (
  <StyledConfirm hasTitle={typeof title !== 'undefined'}>
    {typeof title !== 'undefined' && <header>{title}</header>}

    {prompt !== null && <p className="content">{prompt}</p>}

    <div className="buttons">
      <ConfirmButton
        color={denyColor}
        background={denyBackground}
        hoverBackground={denyHoverBackground}
        onClick={() => onResolve(false)}
      >
        {denyText}
      </ConfirmButton>

      <ConfirmButton
        color={confirmColor}
        background={confirmBackground}
        hoverBackground={confirmHoverBackground}
        onClick={() => onResolve(true)}
      >
        {confirmText}
      </ConfirmButton>
    </div>
  </StyledConfirm>
)

type UseConfirmVariant = 'primary' | 'delete'

interface UseConfirmOptions {
  variant?: UseConfirmVariant

  denyText?: string
  confirmText?: string

  denyColor?: string
  denyBackground?: string
  denyHoverBackground?: string
  confirmColor?: string
  confirmBackground?: string
  confirmHoverBackground?: string

  promptOptions?: PromptOptions
}

export function useConfirm(defaultOptions: UseConfirmOptions = {}) {
  const translations = useTranslate({
    cancel: 'common.cancel',
    ok: 'common.ok',
  })

  const variantOptions = useMemo<Record<UseConfirmVariant, UseConfirmOptions>>(
    () => ({
      primary: {
        denyColor: 'gray3',
        denyBackground: 'gray7',
        denyHoverBackground: 'gray8',
        confirmColor: 'white',
        confirmBackground: 'primary',
        confirmHoverBackground: 'primaryHover',
      },
      delete: {
        denyColor: 'white',
        denyBackground: 'gray3',
        denyHoverBackground: 'gray4',
        confirmColor: 'white',
        confirmBackground: 'matteRed',
        confirmHoverBackground: 'logoutRed',
      },
    }),
    []
  )

  const addPrompt = usePrompt({
    modalProps: {
      placement: 'top-center',
      direction: 'from-top',
      offset: 32,
      shadeOpacity: 0.8,
    },
  })

  const confirm = useCallback(
    async (
      prompt: string | null,
      title?: string,
      options: UseConfirmOptions = {}
    ) => {
      const mergedOptions = merge(
        defaultOptions,
        variantOptions[options.variant ?? 'primary']
      )
      const { promptOptions, ...rest } = merge(mergedOptions, options)

      const { data } = await addPrompt(
        resolve => (
          <Confirm
            prompt={prompt}
            title={title}
            denyText={translations.cancel}
            confirmText={translations.ok}
            {...rest}
            onResolve={resolve}
          />
        ),
        promptOptions
      )

      return data
    },
    [
      addPrompt,
      variantOptions,
      defaultOptions,
      translations.cancel,
      translations.ok,
    ]
  )

  return confirm
}

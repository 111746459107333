import {
  BarCustomLayerProps,
  BarDatum,
  BarTooltipProps,
  ResponsiveBar,
} from '@nivo/bar'
import { useTranslate } from '@ur/react-hooks'
import {
  ProjectDashboardData,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { theme } from 'theme'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useTimeFilterOptions } from '../utils'
import {
  Card,
  BarWrapper,
  FooterButton,
  FooterSelect,
  CardHeader,
} from './components'
import { checklistsSvg, deviationSvg, offerSvg, projectSvg } from './icons'
import { DashboardCardProps } from './types'

interface ValueOutsideProps extends BarCustomLayerProps<BarDatum> {
  barData: BarDatum[]
}

const ValueOutside: React.FC<ValueOutsideProps> = ({ bars, barData }) => {
  const translations = useTranslate({
    projects: 'common.projects',
    deviations: 'common.deviations',
    offers: 'common.offers',
  })

  return (
    <>
      {bars.map((bar, index) => {
        const {
          key,
          width,
          height,
          x,
          y,
          data: { value },
        } = bar

        if (value === null) return null

        return (
          <g
            key={key}
            transform={`translate(${x + width + 20}, ${y + height / 2 - 8})`}
          >
            <text
              transform={`translate(${35}, ${15})`}
              textAnchor="middle"
              fontSize="18px"
              fontWeight="700"
              fill={String(barData[index]?.color) ?? 'black'}
            >
              {value}
            </text>
            {key === `value.${translations.projects}`
              ? projectSvg
              : key === `value.${translations.deviations}`
              ? deviationSvg
              : key === `value.${translations.offers}`
              ? offerSvg
              : checklistsSvg}
          </g>
        )
      })}
    </>
  )
}

const Tooltip: React.FC<BarTooltipProps<BarDatum>> = ({ data }) => {
  return (
    <div>
      {data.label}: {data.value}
    </div>
  )
}

export const RegisteredElementsCard: React.FC<
  DashboardCardProps<ProjectDashboardData>
> = ({ data, loading, timeFilter, onUpdateTimeFilter }) => {
  const translations = useTranslate({
    createProject: 'projects.create-project',
    checklists: 'common.checklists',
    projects: 'common.projects',
    deviations: 'common.deviations',
    offers: 'common.offers',

    registeredElements: 'dashboard.registered-elements',
  })

  const canCreateProject = usePermission(PERMISSIONS.projects.add.project)
  const history = useHistory()
  const timeFilterOptions = useTimeFilterOptions()

  const barData = useMemo<BarDatum[]>(
    () => [
      {
        label: translations.projects,
        value: data.numberOfProjects,
        color: theme.colors.lightPurple,
      },
      {
        label: translations.deviations,
        value: data.numberOfDeviations,
        color: theme.colors.lightPink,
      },
      {
        label: translations.checklists,
        value: data.numberOfChecklists,
        color: theme.colors.lightPurple,
      },
      {
        label: translations.offers,
        value: data.numberOfOffers,
        color: theme.colors.lightPink,
      },
    ],
    [
      data,
      translations.checklists,
      translations.deviations,
      translations.offers,
      translations.projects,
    ]
  )

  return (
    <Card hasFooterButton={canCreateProject}>
      <CardHeader text={translations.registeredElements} loading={loading} />

      <BarWrapper>
        <ResponsiveBar
          data={barData}
          indexBy="label"
          keys={['value', 'offers', 'projects']}
          layout={'horizontal'}
          colors={[theme.colors.lightPurple, theme.colors.lightPink]}
          colorBy="indexValue"
          padding={0.5}
          axisLeft={null}
          axisBottom={null}
          axisRight={null}
          axisTop={null}
          margin={{ top: 0, right: 100, bottom: 0, left: 0 }}
          enableGridY={false}
          enableGridX={false}
          tooltip={Tooltip}
          label=""
          layers={[
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            props => <ValueOutside {...props} barData={barData} />,
          ]}
        />
      </BarWrapper>

      <footer>
        <FooterSelect
          value={timeFilter}
          options={timeFilterOptions}
          borderBottomRightRadius="0px"
          borderTopRightRadius="0px"
          borderTopLeftRadius="0px"
          height="49px"
          border="none"
          fullWidth
          onChange={value =>
            !!value && onUpdateTimeFilter(value as TimeFilterType)
          }
        />

        {canCreateProject && (
          <FooterButton
            role="link"
            width="100%"
            height="100%"
            iconRightProps={{ icon: 'plus' }}
            background="primary"
            onClick={() =>
              history.push({ pathname: '/projects/', state: { create: true } })
            }
          >
            {translations.createProject}
          </FooterButton>
        )}
      </footer>
    </Card>
  )
}

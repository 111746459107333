import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { TimeEntryForm } from './components/TimeEntryForm'
import { PATCH_TIME_ENTRY_MUTATION } from './mutations'
import { TIME_ENTRY_QUERY } from './queries'
import {
  PatchTimeEntryMutation,
  PatchTimeEntryMutationVariables,
  TimeEntryQuery,
} from './types.graphql'
import { isPatchTimeEntryMutationVariables } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 0 1rem 2rem;
  }
`

interface EditTimeEntryProps {}

export const EditTimeEntry: React.FC<EditTimeEntryProps> = () => {
  const translations = useTranslate({
    editTimeEntry: 'timesheets.edit-time-entry',
    editSuccess: 'timesheets.toasts.edit-time-entry-success',
  })

  const { projectId, timeEntryId } =
    useParams<{ projectId: string; timeEntryId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const { loading, data } = useQuery<TimeEntryQuery, IdVariable>(
    TIME_ENTRY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: timeEntryId,
      },
      onCompleted(data) {
        setOverrides({
          [projectId]: !!data?.timeEntry.project
            ? data?.timeEntry.project.name
            : projectId,
          [timeEntryId]: !!data?.timeEntry.description
            ? data?.timeEntry.description
            : timeEntryId,
        })
      },
      onError: onErrorAuto(),
    }
  )

  const timeEntry = useMemo(() => data?.timeEntry, [data])

  const [patchTimeEntry, { loading: patchLoading }] = useMutation<
    PatchTimeEntryMutation,
    PatchTimeEntryMutationVariables
  >(PATCH_TIME_ENTRY_MUTATION, {
    onCompleted() {
      addToast('success', translations.editSuccess)
      history.push(`/projects/${projectId}/timesheets`)
      mixpanel.track('Edit Time Entry')
    },
    onError: onErrorAuto(),
  })

  const isLoading = loading || patchLoading

  return (
    <Wrapper>
      <PageHeader title={translations.editTimeEntry} loading={loading} />

      <TimeEntryForm
        timeEntry={timeEntry}
        loading={isLoading}
        onSubmit={variables =>
          isPatchTimeEntryMutationVariables(variables) &&
          patchTimeEntry({ variables })
        }
      />
    </Wrapper>
  )
}

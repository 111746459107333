import React, { useMemo } from 'react'
import { SelectOption } from '@ur/react-components'
import { Select, SelectProps } from 'components'
import { useTranslate } from '@ur/react-hooks'

interface UnitSelectProps
  extends Omit<SelectProps<string, string>, 'options'> {}

export const UnitSelect: React.FC<UnitSelectProps> = ({ ...props }) => {
  const translations = useTranslate({
    loadingCommon: 'common.loading',
    noOptions: 'common.no-more-options',

    units: {
      STK: 'products.units.pcs',
      M: 'products.units.meter',
      M2: 'products.units.square-meters',
      Sett: 'products.units.set',
      L: 'products.units.litre',
      Pakke: 'products.units.parcel',
    },
  })

  const customerOptions = useMemo<SelectOption<string, string>[]>(
    () =>
      (['STK', 'M', 'M2', 'Sett', 'L', 'Pakke'] as const).map(unit => ({
        value: unit,
        label: translations.units[unit].concat(
          unit !== 'Sett' && unit !== 'Pakke' ? ` (${unit})` : ''
        ),
      })),
    [translations.units]
  )

  return (
    <Select
      options={customerOptions}
      noOptionsText={translations.noOptions}
      {...props}
    />
  )
}

import { ApolloProvider } from '@apollo/client'
import {
  PromptOptions,
  PromptProvider,
  ToastOptions,
  ToastProvider,
  UrThemeProvider,
} from '@ur/react-components'
import { UseGlobalProvider } from '@ur/react-hooks'
import { TranslationProvider } from 'locale/TranslationProvider'
import { isMobileOnly } from 'react-device-detect'
import { store } from 'store'
import client from '../apollo'
import { theme } from '../theme'
import { Bootstrap } from './Bootstrap'

const SubRoot: React.FC = () => {
  const toastOptions: ToastOptions = {
    color: 'white',
    background: {
      success: '#3ca75c',
      info: '#3aa2c2',
      warning: '#cf6c23',
      error: '#c13e3e',
    },
    borderRadius: '8px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.17)',
    showCloseIcon: false,
    progressBarHeight: '7px',
    progressBarColor: {
      success: '#59da7f',
      info: '#3fbde4',
      warning: '#fe8730',
      error: '#fe3030',
    },
    height: 76,
    icons: null,
  }
  const promptOptions: PromptOptions = {
    modalProps: {
      placement: 'top-center',
      direction: isMobileOnly ? 'from-bottom' : 'from-top',
      offset: isMobileOnly ? 0 : 36,
      shadeOpacity: isMobileOnly ? 0 : 0.8,
    },
  }

  return (
    <ApolloProvider client={client}>
      <TranslationProvider>
        <UrThemeProvider theme={theme}>
          <ToastProvider options={toastOptions}>
            <PromptProvider options={promptOptions}>
              <Bootstrap />
            </PromptProvider>
          </ToastProvider>
        </UrThemeProvider>
      </TranslationProvider>
    </ApolloProvider>
  )
}

const Root: React.FC = () => {
  return (
    <UseGlobalProvider store={store}>
      <SubRoot />
    </UseGlobalProvider>
  )
}

export default Root

import { formatDistanceToNow as formatDistanceToNowBase } from 'date-fns'

export interface FormatDistanceToNowOptions {
  addSuffix?: boolean
  includeSeconds?: boolean
}
export function formatDistanceToNow(locale: Locale) {
  return (firstDate: Date, options: FormatDistanceToNowOptions = {}) =>
    formatDistanceToNowBase(firstDate, {
      ...options,
      locale,
    })
}

import React, { AriaRole, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ActionButton as BaseActionButton,
  ActionButtonProps as BaseActionButtonProps,
  IconProps,
} from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { overloadColor } from 'util/style'
import { useHistory } from 'react-router-dom'
import { useClickOutside } from '@ur/react-hooks'

const StyledActionButton = styled(BaseActionButton)`
  i {
    font-weight: 200;
    font-size: 24px;
    transform: translateY(2px);
  }
`
const Dropdown = styled.aside`
  position: absolute;
  z-index: 1;
  top: -8px;
  left: 100%;
  transform: translate(-100%, -100%);

  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;

  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};
`
interface ItemProps {
  color: string
}
const Item = styled.div.attrs({
  role: 'button',
})<ItemProps>`
  display: flex;
  min-width: 210px;
  padding: 0.8rem 1.8rem 0.8rem 1.8rem;

  font-weight: 600;
  color: ${props => overloadColor(props.color)};
  cursor: pointer;
  white-space: nowrap;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.gray6};
  }
`

export interface ActionButtonMenuItem {
  text: string
  role?: AriaRole
  href?: string

  hide?: boolean
  color?: string

  onClick?: (evt: React.MouseEvent) => void
}

export interface ActionButtonMenu {
  items: ActionButtonMenuItem[]

  openIcon?: FontAwesomeIcon | IconProps
  openBackground?: string
}

interface ActionButtonProps extends Omit<BaseActionButtonProps, 'onClick'> {
  menu?: ActionButtonMenu
  onClick?: (evt: React.MouseEvent) => void
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  menu,
  ...props
}) => {
  const mainRef = useRef<HTMLButtonElement>(null)

  const history = useHistory()

  const [menuOpen, setMenuOpen] = useState(false)

  function handleClick(evt: React.MouseEvent) {
    props.onClick?.(evt)
    if (evt.defaultPrevented) return

    !!menu && setMenuOpen(v => !v)
  }

  function handleItemClick(item: ActionButtonMenuItem) {
    return (evt: React.MouseEvent) => {
      item.onClick?.(evt)
      if (evt.defaultPrevented) return

      if (!!item.href) {
        if (item.href.startsWith('http')) window.open(item.href)
        else history.push(item.href)
      }
    }
  }

  const iconProps = useMemo<IconProps>(() => {
    const defaultIcon: IconProps = {
      icon: 'plus',
      fixedWidth: true,
      ...props.iconProps,
    }

    if (!!menu && menuOpen) {
      if (typeof menu.openIcon === 'string')
        return {
          ...defaultIcon,
          icon: menu.openIcon,
        }
      else
        return {
          ...defaultIcon,
          ...menu.openIcon,
        }
    }

    return defaultIcon
  }, [menu, menuOpen, props.iconProps])

  const background = useMemo(
    () =>
      !!menu && menuOpen
        ? menu.openBackground ?? props.background
        : props.background,
    [menu, menuOpen, props.background]
  )

  useClickOutside(mainRef, () => setMenuOpen(false), true)

  return (
    <StyledActionButton
      ref={mainRef}
      right="14px"
      bottom="28px"
      size="56px"
      {...props}
      iconProps={iconProps}
      background={background}
      onClick={handleClick}
    >
      {!!menu && menuOpen && (
        <Dropdown>
          {menu.items.map(item =>
            !!item.hide ? null : (
              <Item
                key={item.text}
                color={item.color ?? 'gray2'}
                onClick={handleItemClick(item)}
              >
                <span className="text">{item.text}</span>
              </Item>
            )
          )}
        </Dropdown>
      )}
    </StyledActionButton>
  )
}

import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  div.blob {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 6rem;
    padding: 2rem;

    h1 {
      margin: 0;

      color: #304ffe42;
      font-size: 8rem;
      font-weight: 700;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    }
    p {
      color: ${props => props.theme.colors.gray1};
      font-size: 1.2rem;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    }
  }
`

interface ErrorPageProps {
  code: '401' | '403' | '404'
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ code }) => {
  const translations = useTranslate({
    401: 'errors.pages.401',
    403: 'errors.pages.403',
    404: 'errors.pages.404',
  })

  return (
    <Wrapper>
      <div className="blob">
        <h1>{code}</h1>
        <p>{translations[code]}</p>
      </div>
    </Wrapper>
  )
}

import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery as useLazyQueryBase,
} from '@apollo/client'
import { useCallback, useEffect, useRef } from 'react'

type LazyQueryHookTuple<TData = any, TVariables = OperationVariables> = [
  LazyQueryExecute<TData, TVariables>,
  LazyQueryResult<TData, TVariables>
]

type LazyQueryExecute<TData = any, TVariables = OperationVariables> = (
  options?: QueryLazyOptions<TVariables>
) => Promise<LazyQueryResult<TData, TVariables>>

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
): LazyQueryHookTuple<TData, TVariables> {
  const [execute, result] = useLazyQueryBase<TData, TVariables>(query, options)

  const resolveRef =
    useRef<
      (
        value:
          | LazyQueryResult<TData, TVariables>
          | PromiseLike<LazyQueryResult<TData, TVariables>>
      ) => void
    >()

  useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result)
      resolveRef.current = undefined
    }
  }, [result])

  const runQuery: LazyQueryExecute<TData, TVariables> = useCallback(
    options => {
      execute(options)
      return new Promise<LazyQueryResult<TData, TVariables>>(resolve => {
        resolveRef.current = resolve
      })
    },
    [execute]
  )

  return [runQuery, result]
}

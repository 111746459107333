import { Icon, IconProps } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { useClickOutside } from '@ur/react-hooks'
import { CheckBox } from 'components/Checkbox'
import xor from 'lodash/xor'
import React, { useMemo, useRef, useState } from 'react'
import styled, { css, DefaultTheme, ThemedCssFunction } from 'styled-components'
import { overloadColor } from 'util/style'
import {
  TableFiltering,
  TableFilteringChecklistItem,
  TableFilteringOptions,
  TableFilteringSeparator,
} from './types'

interface WrapperProps {
  open: boolean
  noMobileIconStyling: boolean
  openStyle?: ReturnType<ThemedCssFunction<DefaultTheme>> | null
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;

  ${props => props.theme.media.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 34px;
    height: 34px;

    border-radius: 8px;

    ${props =>
      !props.noMobileIconStyling &&
      css`
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        background-color: ${!props.open
          ? props.theme.colors.gray4
          : props.theme.colors.primaryHover};
      `};

    div.icon {
      color: white;
    }
  }
  ${props => props.theme.media.desktop} {
    div.icon {
      &:hover i {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  ${props => props.open && props.openStyle}
`
const Dropdown = styled.aside`
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;

  background-color: white;
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};

  ${props => props.theme.media.mobile} {
    top: calc(100% + 4px);
    gap: 0;
    overflow-y: auto;
    max-width: 70vw;
    max-height: 320px;
  }
`
interface ItemProps {
  color: string
  hoverColor: string
}
const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  min-width: 210px;
  padding: 0.6rem 3rem 0.6rem 1.8rem;

  border-radius: 0 999px 999px 0;
  font-weight: 600;
  color: ${props => overloadColor(props.color)};
  text-transform: none;
  cursor: pointer;
  user-select: none;

  span.text {
    white-space: nowrap;
  }
  &:hover {
    color: ${props => overloadColor(props.hoverColor)};
    background-color: ${props => props.theme.colors.hover};
  }

  ${props => props.theme.media.mobile} {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;

    & + div {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }
    &:hover {
      border-radius: 0 999px 999px 0;
    }
  }
`

interface SeparatorProps {
  color: string
}
const Separator = styled.div<SeparatorProps>`
  border-top: 1px solid ${props => overloadColor(props.color)};
`

function isRegularItem(
  item: TableFilteringChecklistItem | TableFilteringSeparator
): item is TableFilteringChecklistItem {
  return !('separator' in item)
}

function isSeparator(
  item: TableFilteringChecklistItem | TableFilteringSeparator
): item is TableFilteringSeparator {
  return 'separator' in item && item.separator
}

interface FilteringProps {
  className?: string
  id: string
  config: TableFilteringOptions

  icon?: FontAwesomeIcon | Partial<IconProps>
  noMobileIconStyling?: boolean
  openStyle?: ReturnType<ThemedCssFunction<DefaultTheme>>

  onFilterChange: (value: TableFiltering) => void
}

export const Filtering: React.FC<FilteringProps> = ({
  className,
  id,
  config,

  icon,
  noMobileIconStyling = false,
  openStyle,

  onFilterChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const initialChecked = useMemo(() => {
    if (config.type !== 'checklist') return []

    const regularItems =
      config.items.filter<TableFilteringChecklistItem>(isRegularItem)

    if (config.exclusive) {
      const first =
        regularItems.find(item => !isSeparator(item) && !item.initUnchecked)
          ?.id ?? (!!config.required ? regularItems[0]?.id : null)
      return !!first ? [first] : []
    }

    let items = regularItems.reduce<string[]>(
      (acc, item) => (item.initUnchecked ? acc : [...acc, item.id]),
      []
    )
    if (config.required && !items.length && !!regularItems.length)
      items = [regularItems[0].id]

    return items
  }, [config.exclusive, config.required, config.items, config.type])

  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState<string[]>(initialChecked)

  useClickOutside(
    wrapperRef,
    () => setOpen(false),
    config.closeOnEscape !== false
  )

  function handleChecklistItemClick(item: TableFilteringChecklistItem) {
    return (evt: React.MouseEvent) => {
      evt.stopPropagation()

      let newChecked = !!config.exclusive
        ? checked[0] === item.id
          ? []
          : [item.id]
        : xor(checked, [item.id])

      if (!!config.required && !newChecked.length) newChecked = [item.id]

      setChecked(newChecked)
      onFilterChange({
        column: id,
        checked: newChecked,
      })
    }
  }

  function handleToggleOpen(evt: React.MouseEvent) {
    evt.stopPropagation()
    setOpen(v => !v)
  }

  const iconProps: IconProps = {
    icon: 'filter',
    type: 'solid',
    size: '0.8rem',
    cursor: 'pointer',
    ...(typeof icon === 'string' ? { icon } : icon),
  }

  return (
    <Wrapper
      ref={wrapperRef}
      className={className}
      id={id}
      open={open}
      noMobileIconStyling={noMobileIconStyling}
      openStyle={openStyle}
    >
      <div className="icon" onClick={handleToggleOpen}>
        <Icon {...iconProps} />
      </div>

      {open && (
        <Dropdown>
          {config.type === 'checklist'
            ? config.items.map((item, idx) =>
                item.hide ? null : isSeparator(item) ? (
                  <Separator key={idx} color={item.color ?? 'gray5'} />
                ) : (
                  <Item
                    key={item.id}
                    color={item.color ?? 'gray2'}
                    hoverColor={item.hoverColor ?? item.color ?? 'gray1'}
                    onClick={handleChecklistItemClick(item)}
                  >
                    <CheckBox
                      id={item.id}
                      checked={checked.includes(item.id)}
                      onChange={() => void 0}
                    />

                    {!!item.dotColor && (
                      <Icon
                        type="solid"
                        icon="circle"
                        size="8px"
                        color={item.dotColor}
                      />
                    )}

                    <span className="text">{item.text}</span>
                  </Item>
                )
              )
            : null}
        </Dropdown>
      )}
    </Wrapper>
  )
}

import { Icon, IconProps } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import { LocationDescriptor } from 'history'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'

const sharedCss = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  padding: 1.1rem 1.5rem;

  font-size: 1.2rem;
  font-weight: 600;

  i {
    margin-bottom: -2px;
  }
`

const Wrapper = styled.nav`
  position: relative;
  width: 100%;
`
interface ActiveItemProps {
  open: boolean
}
const ActiveItem = styled.div.attrs({
  role: 'button',
})<ActiveItemProps>`
  ${sharedCss}
  justify-content: space-between;

  color: white;
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  div.left {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  i.chevron {
    transition: transform 0.3s ease;
    transform: rotateX(${props => (props.open ? '180deg' : '0deg')});
    transform-origin: 0% 40%;
  }
`
const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  z-index: ${ZIndexRange.Dropdowns};
  width: 100%;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};

  a {
    ${sharedCss}
    background-color: white;

    & + a {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }
    &:first-of-type {
      border-top-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
      border-top-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }
    &:last-of-type {
      border-bottom-left-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
      border-bottom-right-radius: ${props =>
        props.theme.sizes.defaultBorderRadius};
    }
  }
`

export interface NavItem {
  id: string
  to: LocationDescriptor
  text: string
  icon?: FontAwesomeIcon | IconProps
}

interface DropdownNavProps {
  items: NavItem[]
}

export const DropdownNav: React.FC<DropdownNavProps> = ({ items }) => {
  if (items.length === 0)
    throw new Error('DropdownNav must have at least one item')

  const location = useLocation()

  const [open, setOpen] = useState(false)

  const getIconProps = useCallback(
    (icon: FontAwesomeIcon | IconProps) =>
      typeof icon === 'string'
        ? { icon, fixedWidth: true }
        : { fixedWidth: true, ...icon },
    []
  )

  const activeItem = useMemo(
    () =>
      items.find(item =>
        location.pathname.startsWith(
          typeof item.to === 'string' ? item.to : item.to.pathname ?? ''
        )
      ) ?? items[0],
    [items, location.pathname]
  )

  return (
    <Wrapper>
      <ActiveItem open={open} onClick={() => setOpen(v => !v)}>
        <div className="left">
          {!!activeItem.icon && <Icon {...getIconProps(activeItem.icon)} />}
          {activeItem.text}
        </div>

        <Icon
          className="chevron"
          icon="chevron-down"
          type="solid"
          size="1.4rem"
        />
      </ActiveItem>

      {open && (
        <Dropdown>
          {items.map(item =>
            item.id === activeItem.id ? null : (
              <Link key={item.id} to={item.to} onClick={() => setOpen(false)}>
                {!!item.icon && <Icon {...getIconProps(item.icon)} />}

                {item.text}
              </Link>
            )
          )}
        </Dropdown>
      )}
    </Wrapper>
  )
}

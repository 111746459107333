import {
  RadioGroup as BaseRadioGroup,
  RadioGroupProps as BaseRadioGroupProps,
} from '@ur/react-components'
import styled from 'styled-components'

const RadioGroupWrapper = styled.div`
  .--radio-wrapper {
    .--radio-group-checkbox {
      .--checkbox-box {
        position: relative;
        border: 1px solid ${props => props.theme.colors.gray4};

        &.--checkbox-box-checked {
          border: 3px solid ${props => props.theme.colors.primary};

          &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 75%;
            height: 75%;

            background: ${props => props.theme.colors.primary};
            border-radius: 999px;
          }
          & ~ label {
            color: ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
`

interface RadioGroupProps<V extends string | number, E = any>
  extends BaseRadioGroupProps<V, E> {}

export const RadioGroup = <V extends string | number, E = any>({
  ...props
}: RadioGroupProps<V, E>) => {
  return (
    <RadioGroupWrapper>
      <BaseRadioGroup
        {...props}
        checkboxProps={{
          boxBackground: 'white',
          boxColor: 'white',
          ...props.checkboxProps,
        }}
      />
    </RadioGroupWrapper>
  )
}

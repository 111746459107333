import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  ActionButton,
  Input,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import React from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ShallowChecklistTemplate } from '../types.graphql'
import { ChecklistTemplateCard } from './ChecklistTemplateCard'

const Wrapper = styled.div`
  div.no-checklists {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
  }
`
const Toolbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0.5rem 1rem 0;

  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

interface ChecklistTemplatesMobileProps {
  templates: ShallowChecklistTemplate[]
  search: string
  userFilter: TableFilteringChecklistOptions

  makeMenu: (template: ShallowChecklistTemplate) => TableMenu

  onCreateNewChecklistTemplate: () => void
  onSearchChange: (value: string) => void
  onFilterChange?: (value: TableFiltering) => void
}

export const ChecklistTemplatesMobile: React.FC<
  ChecklistTemplatesMobileProps
> = ({
  templates,
  search,
  userFilter,

  makeMenu,

  onCreateNewChecklistTemplate,
  onSearchChange,
  onFilterChange,
}) => {
  const translations = useTranslate({
    search: 'checklists.search-for-templates',
    noData: 'common.no-data',
    noResults: 'common.no-results',
  })

  const canCreateChecklistTemplate = usePermission(
    PERMISSIONS.checklists.add.checklisttemplate
  )

  const debouncedSearch = useDebounce(search)

  return (
    <Wrapper>
      {canCreateChecklistTemplate && (
        <ActionButton onClick={onCreateNewChecklistTemplate} />
      )}

      <Toolbar>
        <Input
          value={search}
          placeholder={translations.search}
          iconRightProps={{
            icon: 'search',
            type: 'solid',
            color: 'gray4',
            size: '18px',
            translateX: '-2px',
          }}
          height="56px"
          onChange={onSearchChange}
        />

        {!!userFilter && (
          <Filtering
            id="status"
            config={userFilter}
            icon={{
              size: '1rem',
            }}
            onFilterChange={value =>
              !!onFilterChange ? onFilterChange(value) : {}
            }
          />
        )}
      </Toolbar>

      {templates.map(template => (
        <ChecklistTemplateCard
          key={template.id}
          template={template}
          editDeleteMenu={makeMenu(template)}
        />
      ))}

      {!templates.length && (
        <div className="no-checklists">
          {!!debouncedSearch ? translations.noResults : translations.noData}
        </div>
      )}
    </Wrapper>
  )
}

import { useTranslate } from '@ur/react-hooks'
import { FetchMoreLoader, Message, PageHeader, Table } from 'components'
import { ProjectsMobile } from 'modules/projects'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useDashboardProjectsUtils } from './util/useDashboardProjectsUtils'

const Wrapper = styled.div``

const SmallPageHeader = styled(PageHeader)`
  h1 {
    font-size: 1.2rem;
    font-weight: 400;
    min-height: 1.5rem;
  }
`

const PAGE_SIZE = 25

interface DashboardProjectsTableProps {}

export const DashboardProjectsTable: React.FC<
  DashboardProjectsTableProps
> = () => {
  const translations = useTranslate({
    projects: 'common.my-projects',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const [sort, setSort] = useState('')
  const [selectedStages, setSelectedStages] = useState(['ongoing'])

  const {
    projects,
    columns,
    rows,
    tableOptions,

    statusFiltering,
    makeStageMenu,
    makeEditDeleteMenu,
    handleFetchMore,

    error,
    loading,
    fetchMoreLoading,
    hasMore,
  } = useDashboardProjectsUtils({
    queryVariables: {
      first: PAGE_SIZE,
      stages: selectedStages,
      orderBy: sort,
    },
  })

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {isMobileOnly ? (
        <ProjectsMobile
          projects={projects}
          filter={statusFiltering}
          loading={loading}
          makeStageMenu={makeStageMenu}
          makeEditDeleteMenu={makeEditDeleteMenu}
          onFilterChange={value => setSelectedStages(value.checked)}
        />
      ) : (
        <>
          <SmallPageHeader title={translations.projects} loading={loading} />

          <Table
            columns={columns}
            rows={rows}
            options={tableOptions}
            noRowsText={translations.noData}
            onFilterChange={value => setSelectedStages(value.checked)}
            onSortingChange={setSort}
          />
        </>
      )}

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </Wrapper>
  )
}

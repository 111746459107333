import React from 'react'
import { Route } from 'containers/Routing'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 1rem;

  background-color: white;
  border: solid ${props => props.theme.colors.gray6};
  border-width: 0 1px 1px;
  border-bottom-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
  border-bottom-right-radius: ${props => props.theme.sizes.defaultBorderRadius};

  ${props => props.theme.media.mobile} {
    padding-top: 0.5rem;

    background-color: inherit;
    border: 0;
    border-radius: 0;
  }
`

interface ProjectRouteProps {
  path: string
  exact?: boolean
}

export const ProjectRoute: React.CFC<ProjectRouteProps> = ({
  children,
  path,
  exact = false,
}) => {
  return (
    <Route path={path} exact={exact}>
      <Wrapper>{children}</Wrapper>
    </Route>
  )
}

import { ObservableQueryFields } from '@apollo/client'
import { useCallback, useState } from 'react'
import { RelayPageInfo } from 'types/graphql'
import {
  DashboardProjectsTableQuery,
  DashboardProjectsTableQueryVariables,
} from 'modules/projects/types.graphql'

export function useDashboardProjectsTablePagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreProjects: ObservableQueryFields<
    DashboardProjectsTableQuery,
    DashboardProjectsTableQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreProjects({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreProjects])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

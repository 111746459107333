import { PrivateRoute, PrivateRouteProvider } from '@ur/react-components'
import { useGlobal } from '@ur/react-hooks'
import { ContentScrollProvider, ScrollToTop } from 'containers'
import { Checklist, Checklists, ChecklistTemplate } from 'modules/checklists'
import {
  AddEditUserType,
  CompanySettings,
  CompanySettingsDeviationCategories,
  CompanySettingsUserTypes,
} from 'modules/companies'
import {
  CompanyManuals,
  CreateManualEntry,
  EditManualEntry,
  ManualEntry,
  ManualFoldersAndEntries,
} from 'modules/companymanuals'
import {
  CreateCustomer,
  Customer,
  Customers,
  EditCustomer,
} from 'modules/customers'
import { Dashboard } from 'modules/dashboard'
import { Playground } from 'modules/dashboard/Playground'
import {
  CreateDeviation,
  Deviation,
  Deviations,
  EditDeviation,
} from 'modules/deviations'
import { Header } from 'modules/header'
import { Logout } from 'modules/login'
import { CreateOffer } from 'modules/offers/CreateOffer'
import { EditOffer } from 'modules/offers/EditOffer'
import { Products } from 'modules/products'
import { CreateProduct } from 'modules/products/CreateProduct'
import { EditProduct } from 'modules/products/EditProduct'
import { Project, Projects } from 'modules/projects'
import { Sidebar } from 'modules/sidebar'
import {
  CreateAbsence,
  CreateTimeEntry,
  EditAbsence,
  EditTimeEntry,
  Timesheets,
} from 'modules/timesheets'
import { UserGuide } from 'modules/userguide'
import { EditUser, UserProfile, Users } from 'modules/users'
import React, { useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Redirect, Route, Switch } from 'containers/Routing'
import styled from 'styled-components'
import { STAGE } from 'util/env'
import { PERMISSIONS } from 'util/permissions'
import { ErrorPage } from './ErrorPage'
import { usePrivateRouteConfig } from './util'
import { SuperuserRoute } from './SuperUserRoute'
import AdminDashboard from '../modules/admin/AdminDashboard'
import AdminCreateCompany from '../modules/admin/AdminCreateCompany'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: ${props => props.theme.sizes.headerHeight} 1fr;

  grid-template-areas:
    'header header'
    'sidebar main';

  ${props => props.theme.media.mobile} {
    grid-template-columns: 100%;
    grid-template-rows: ${props => props.theme.sizes.headerHeightMobile} 1fr;

    grid-template-areas:
      'header'
      'main';
  }
`

interface ContentWrapperProps {
  hidden: boolean
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  grid-area: main;
  background-color: ${props => props.theme.colors.body};
  height: ${props => (props.hidden ? '0vh' : 'auto')};
  overflow: ${props => (props.hidden ? 'hidden' : 'visible')};
`
const HeaderWrapper = styled.header`
  grid-area: header;

  ${props => props.theme.media.mobile} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

interface SidebarWrapperProps {
  open: boolean
}

const SidebarWrapper = styled.div<SidebarWrapperProps>`
  grid-area: sidebar;

  ${props => props.theme.media.mobile} {
    grid-area: main;
  }

  width: ${props =>
    props.open
      ? props.theme.sizes.sidebarWidthExpanded
      : props.theme.sizes.sidebarWidth};
`

export const Routes: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null)

  const [open] = useGlobal('sidebar.open')

  const privateRouteConfig = usePrivateRouteConfig()

  const permissionsCompanySettings = [
    PERMISSIONS.companies.view.companysetting,
    PERMISSIONS.companies.change.companysetting,
  ]
  const permissionsCompanySettingsDeviationCategories =
    permissionsCompanySettings.concat([
      PERMISSIONS.deviations.view.deviationcategory,
      PERMISSIONS.deviations.add.deviationcategory,
      PERMISSIONS.deviations.change.deviationcategory,
      PERMISSIONS.deviations.delete.deviationcategory,
    ])
  const permissionsCompanySettingsUserTypes = permissionsCompanySettings.concat(
    [PERMISSIONS.users.view.usertype, PERMISSIONS.users.change.usertype]
  )

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>

      <SidebarWrapper open={open}>
        <Sidebar />
      </SidebarWrapper>

      <ContentWrapper ref={contentRef} hidden={open && isMobileOnly}>
        <ContentScrollProvider contentRef={contentRef}>
          <PrivateRouteProvider config={privateRouteConfig}>
            <ScrollToTop />

            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />

              {/* Users */}

              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/users/:id" component={UserProfile} />
              <PrivateRoute exact path="/users/:id/edit" component={EditUser} />

              {/* Customers */}

              <PrivateRoute exact path="/customers" component={Customers} />
              <PrivateRoute
                exact
                path="/customers/create"
                permissions={PERMISSIONS.customers.add.customer}
                component={CreateCustomer}
              />
              <PrivateRoute
                exact
                path="/customers/:id/edit"
                permissions={PERMISSIONS.customers.change.customer}
                component={EditCustomer}
              />
              <PrivateRoute exact path="/customers/:id" component={Customer} />

              {/* Projects */}

              <PrivateRoute exact path="/projects" component={Projects} />

              <PrivateRoute
                exact
                path="/projects/:projectId/offers/create"
                permissions={PERMISSIONS.offers.add.offer}
                component={CreateOffer}
              />
              <PrivateRoute
                exact
                path="/projects/:projectId/offers/:offerId/edit"
                permissions={PERMISSIONS.offers.change.offer}
                component={EditOffer}
              />

              <PrivateRoute
                exact
                path="/projects/:projectId/timesheets/create"
                permissions={PERMISSIONS.timesheets.add.timeentry}
                component={CreateTimeEntry}
              />
              <PrivateRoute
                exact
                path="/projects/:projectId/timesheets/:timeEntryId/edit"
                permissions={PERMISSIONS.timesheets.change.timeentry}
                component={EditTimeEntry}
              />

              <PrivateRoute
                exact
                path="/projects/:projectId/deviations/create"
                permissions={PERMISSIONS.deviations.add.deviation}
                component={CreateDeviation}
              />
              <PrivateRoute
                exact
                path="/projects/:projectId/deviations/:deviationId"
                component={Deviation}
              />
              <PrivateRoute
                exact
                path="/projects/:projectId/deviations/:deviationId/edit"
                permissions={PERMISSIONS.deviations.change.deviation}
                component={EditDeviation}
              />
              <PrivateRoute
                exact
                path="/projects/:projectId/checklists/:checklistId"
                component={Checklist}
              />
              <PrivateRoute path="/projects/:projectId" component={Project} />

              {/* Timesheets/absences */}
              <PrivateRoute exact path="/absences" component={Timesheets} />
              <PrivateRoute
                exact
                path="/absences/create"
                permissions={PERMISSIONS.timesheets.add.absence}
                component={CreateAbsence}
              />
              <PrivateRoute
                exact
                path="/absences/:absenceId/edit"
                permissions={PERMISSIONS.timesheets.change.absence}
                component={EditAbsence}
              />

              <PrivateRoute exact path="/timesheets" component={Timesheets} />

              {/* Products */}

              <PrivateRoute exact path="/products" component={Products} />
              <PrivateRoute
                exact
                path="/products/imported"
                component={Products}
              />
              <PrivateRoute
                exact
                path="/products/create"
                permissions={PERMISSIONS.products.add.product}
                component={CreateProduct}
              />
              <PrivateRoute
                exact
                path="/products/:productId/edit"
                permissions={PERMISSIONS.products.change.product}
                component={EditProduct}
              />

              {/* Checklists */}

              <PrivateRoute exact path="/checklists" component={Checklists} />
              <PrivateRoute
                exact
                path="/checklists/templates"
                component={Checklists}
              />
              <PrivateRoute
                exact
                path="/checklists/templates/create"
                permissions={PERMISSIONS.checklists.add.checklisttemplate}
                component={ChecklistTemplate}
              />
              <PrivateRoute
                exact
                path="/checklists/templates/:checklistTemplateId"
                component={ChecklistTemplate}
              />

              {/* Deviations */}

              <PrivateRoute exact path="/deviations" component={Deviations} />
              <PrivateRoute
                exact
                path="/deviations/internal"
                component={Deviations}
              />
              <PrivateRoute
                exact
                path="/deviations/internal/create"
                permissions={PERMISSIONS.deviations.add.deviation}
                component={CreateDeviation}
              />
              <PrivateRoute
                exact
                path="/deviations/internal/:deviationId"
                component={Deviation}
              />
              <PrivateRoute
                exact
                path="/deviations/internal/:deviationId/edit"
                component={EditDeviation}
              />

              {/* Handbook */}

              <PrivateRoute exact path="/handbook" component={CompanyManuals} />
              <PrivateRoute
                exact
                path="/handbook/:folderSlug/create-entry"
                permissions={PERMISSIONS.companymanuals.add.manualentry}
                component={CreateManualEntry}
              />
              <PrivateRoute
                exact
                path="/handbook/:folderSlug"
                component={ManualFoldersAndEntries}
              />
              <PrivateRoute
                exact
                path="/handbook/:folderSlug/:entrySlug/edit"
                permissions={PERMISSIONS.companymanuals.change.manualentry}
                component={EditManualEntry}
              />
              <PrivateRoute
                exact
                path="/handbook/:folderSlug/:entrySlug"
                component={ManualEntry}
              />

              {/* Company settings */}

              <PrivateRoute
                exact
                path={[
                  '/settings/company/user-types/create',
                  '/settings/company/user-types/:userTypeId/edit',
                ]}
                permissions={permissionsCompanySettingsUserTypes}
                component={AddEditUserType}
              />
              <PrivateRoute
                exact={!isMobileOnly}
                path={[
                  '/settings/company',
                  '/settings/company/information',
                  '/settings/company/folders',
                  '/settings/company/other',
                ].concat(
                  isMobileOnly
                    ? [
                        '/settings/company/user-types',
                        '/settings/company/deviation-categories',
                      ]
                    : []
                )}
                permissions={
                  !isMobileOnly
                    ? permissionsCompanySettings
                    : permissionsCompanySettings
                        .concat(permissionsCompanySettingsDeviationCategories)
                        .concat(permissionsCompanySettingsUserTypes)
                }
                component={CompanySettings}
              />
              <PrivateRoute
                exact
                path="/settings/company/user-types"
                permissions={permissionsCompanySettingsUserTypes}
                component={CompanySettingsUserTypes}
              />
              <PrivateRoute
                exact
                path="/settings/company/deviation-categories"
                permissions={permissionsCompanySettingsDeviationCategories}
                component={CompanySettingsDeviationCategories}
              />
              <PrivateRoute
                exact
                path="/settings/company/user-types/:userTypeId"
                permissions={permissionsCompanySettingsUserTypes}
                component={CompanySettingsUserTypes}
              />

              {/* Other */}

              <PrivateRoute exact path="/user-guide" component={UserGuide} />

              <PrivateRoute exact path="/logout" component={Logout} />

              {STAGE !== 'production' && (
                <PrivateRoute exact path="/playground" component={Playground} />
              )}

              <SuperuserRoute exact path="/admin" component={AdminDashboard} />
              <SuperuserRoute
                exact
                path="/admin/create-company"
                component={AdminCreateCompany}
              />

              <Redirect exact from="/" to="/dashboard" />

              <Route path="*">
                <ErrorPage code="404" />
              </Route>
            </Switch>
          </PrivateRouteProvider>
        </ContentScrollProvider>
      </ContentWrapper>
    </Wrapper>
  )
}

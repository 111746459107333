import { Icon } from '@ur/react-components'
import { DateRange, Side } from 'components'
import { addMonths, isSameDay, isSameMonth } from 'date-fns'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { useCalendar } from './util'

interface WrapperProps {
  vertical: boolean
}
const Wrapper = styled.section<WrapperProps>`
  padding: 2rem 1rem;

  ${props =>
    props.vertical &&
    css`
      margin: 0 auto;
      padding: 1rem;
    `};
`
const Header = styled.header`
  position: relative;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;

  span {
    font-weight: 600;
    text-transform: capitalize;
  }
`
interface ArrowProps {
  side: Side
}
const Arrow = styled.div.attrs({
  role: 'button',
})<ArrowProps>`
  position: absolute;
  left: ${props => props.side === 'left' && 0};
  right: ${props => props.side === 'right' && 0};

  padding: 2px 4px 4px;

  border: 1px solid ${props => props.theme.colors.gray1};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.primaryHover};
    background-color: ${props => props.theme.colors.primaryHover};

    i {
      color: white;
    }
  }
`
const Calendar = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(7, 1fr);
  overflow: hidden;
`
interface DayProps {
  isToday: boolean
  isCurrentMonth: boolean
  isSelected: boolean
  isInRange: boolean
  isStartOfRange: boolean
  isEndOfRange: boolean
  isSingleDayRange: boolean
}
const Day = styled.div<DayProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  margin: 4px;

  font-size: 0.8rem;
  font-weight: ${props => props.isToday && '600'};
  text-decoration: ${props => props.isToday && 'underline'};
  color: ${props =>
    props.isCurrentMonth ? props.theme.colors.gray2 : props.theme.colors.gray4};
  user-select: none;
  cursor: ${props => props.isCurrentMonth && 'pointer'};

  border-radius: 50%;
  color: ${props => props.isSelected && 'white'};
  background-color: ${props => props.isSelected && props.theme.colors.primary};

  ${props =>
    props.isCurrentMonth &&
    !props.isSelected &&
    css`
      &:hover {
        color: white;
        background-color: ${props.theme.colors.primaryHover};
        font-weight: 600;
      }
    `};

  ${props =>
    props.isInRange &&
    !props.isSingleDayRange &&
    css`
      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: ${props.isStartOfRange || props.isEndOfRange ? '68%' : '135%'};
        height: 100%;
        background-color: #e7ecff;

        left: ${props.isStartOfRange && '50%'};
        right: ${props.isEndOfRange && '50%'};
      }
    `};
`
const DayName = styled(Day).attrs({
  isToday: false,
  isCurrentMonth: false,
  isSelected: false,
  isInRange: false,
  isStartOfRange: false,
  isEndOfRange: false,
  isSingleDayRange: false,
})`
  font-weight: 600;
  color: ${props => props.theme.colors.gray2};
  text-transform: capitalize;
`

interface MonthProps {
  value: DateRange
  side: Side
  vertical: boolean
  singleMonth: boolean

  month: Date
  otherMonth: Date

  onDateClick: (date: Date) => void
  onUpdateMonth: (date: Date) => void
}

export const Month: React.FC<MonthProps> = ({
  value,
  side,
  vertical,
  singleMonth,

  month,
  otherMonth,

  onDateClick,
  onUpdateMonth,
}) => {
  const { format } = useDateFns()

  const calendar = useCalendar(month, value)

  const isSingleDayRange = useMemo(
    () => isSameDay(value.from, value.to),
    [value.from, value.to]
  )

  const showSecondArrow = useMemo(() => {
    if (singleMonth) return true

    if (side === 'left') {
      const next = addMonths(month, 1)
      return !isSameMonth(next, otherMonth)
    } else {
      const prev = addMonths(month, -1)
      return !isSameMonth(prev, otherMonth)
    }
  }, [month, otherMonth, side, singleMonth])

  function handleChangeMonth(direction: Side) {
    const newMonth =
      direction === 'left' ? addMonths(month, -1) : addMonths(month, 1)
    onUpdateMonth(newMonth)
  }

  return (
    <Wrapper vertical={vertical}>
      <Header>
        {(side === 'left' || showSecondArrow) && (
          <Arrow side="left" onClick={() => handleChangeMonth('left')}>
            <Icon
              icon="chevron-left"
              fixedWidth
              size="0.8rem"
              color="gray1"
              translateX="-1px"
            />
          </Arrow>
        )}

        <span>{format(month, 'MMMM y')}</span>

        {(side === 'right' || showSecondArrow) && (
          <Arrow side="right" onClick={() => handleChangeMonth('right')}>
            <Icon icon="chevron-right" fixedWidth size="0.8rem" color="gray1" />
          </Arrow>
        )}
      </Header>

      <Calendar>
        {calendar.dayNames.map(day => (
          <DayName key={day}>{day}</DayName>
        ))}

        {calendar.weeks.map(week => (
          <React.Fragment key={week.num}>
            {week.days.map(day => (
              <Day
                key={day.date.getTime()}
                isToday={day.isToday}
                isCurrentMonth={day.isCurrentMonth}
                isSelected={day.isSelected}
                isInRange={day.isInRange}
                isStartOfRange={day.isStartOfRange}
                isEndOfRange={day.isEndOfRange}
                isSingleDayRange={isSingleDayRange}
                onClick={() => day.isCurrentMonth && onDateClick(day.date)}
              >
                {day.date.getDate()}
              </Day>
            ))}
          </React.Fragment>
        ))}
      </Calendar>
    </Wrapper>
  )
}

import { formatDistance as formatDistanceBase } from 'date-fns'

interface FormatDistanceOptions {
  includeSeconds?: boolean
  addSuffix?: boolean
}

export function formatDistance(locale: Locale) {
  return (
    firstDate: Date,
    secondDate: Date,
    options: FormatDistanceOptions = {}
  ) =>
    formatDistanceBase(firstDate, secondDate, {
      locale,
      ...options,
    })
}

import { FormValues } from '@ur/react-hooks'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { validateHex } from './style'

export function validateEmail(errorMessage: string) {
  return (email: string) => (/^\S+@\S+\.\S+$/.test(email) ? null : errorMessage)
}

export function validateNonEmpty(errorMessage: string) {
  return (value: string | null | number | any[]) =>
    !!(Array.isArray(value) ? value.length : value) ? null : errorMessage
}

export function validateColor(errorMessage: string) {
  return (value: string) => (validateHex(value) ? null : errorMessage)
}

export function validateInRange(
  errorMessage: string,
  min: number,
  max: number
) {
  return (value: number) => (min <= value && value <= max ? null : errorMessage)
}

/**
 * Validate that number is positive
 * @param errorMessage Message to return on error
 * @param includeZero Whether to treat zero as a positive number, default false
 */
export function validatePositiveNumber(
  errorMessage: string,
  includeZero = false
) {
  return (value: number) =>
    (includeZero ? 0 <= value : 0 < value) ? null : errorMessage
}

/**
 * Validate that number is greater than given value
 * @param errorMessage Message to return on error
 * @param min Exclusive minimum value
 */
export function validateGreaterThan(errorMessage: string, min: number) {
  return (value: number) => (min < value ? null : errorMessage)
}

export function validatePassword<T extends FormValues>(
  errorMessage: string,
  checkEqual?: keyof T,
  unequalErrorMessage?: string
) {
  return (value: string, form?: T) => {
    const passwordValid =
      value.length >= 6 && /[0-9]/.test(value) && /[a-z]/i.test(value)
    const checkEquality = !!checkEqual && !!form
    const isEqual = checkEquality
      ? value === (form[checkEqual] as unknown as string)
      : true

    return passwordValid && isEqual
      ? null
      : checkEquality && !isEqual
      ? unequalErrorMessage ?? errorMessage
      : errorMessage
  }
}

export function validatePhoneNumber(errorMessage: string) {
  return (phoneNumber: string) =>
    parsePhoneNumberFromString(phoneNumber, 'NO')?.isValid()
      ? null
      : errorMessage
}

export function validateNonEmptyQuillDelta(errorMessage: string) {
  return (value: string) =>
    value !== '{"ops":[{"insert":"\\n"}]}' ? null : errorMessage
}

import { gql } from '@apollo/client'

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser(
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $address: String!
    $city: String!
    $postalCode: String!
  ) {
    registerUser(
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      address: $address
      city: $city
      postalCode: $postalCode
    ) {
      user {
        id
      }
    }
  }
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $userType: ID!
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      userType: $userType
    ) {
      user {
        id
      }
    }
  }
`

export const PATCH_USER_MUTATION = gql`
  mutation PatchUser($id: ID!, $input: PatchUserInput!) {
    patchUser(id: $id, input: $input) {
      user {
        id
        profilePicture
      }
    }
  }
`

export const CREATE_USER_EMERGENCY_CONTACT_MUTATION = gql`
  mutation CreateUserEmergencyContact(
    $input: CreateUserEmergencyContactInput!
  ) {
    createUserEmergencyContact(input: $input) {
      userEmergencyContact {
        id
      }
    }
  }
`

export const PATCH_USER_EMERGENCY_CONTACT_MUTATION = gql`
  mutation PatchUserEmergencyContact(
    $id: ID!
    $input: PatchUserEmergencyContactInput!
  ) {
    patchUserEmergencyContact(id: $id, input: $input) {
      userEmergencyContact {
        id
      }
    }
  }
`
export const DELETE_USER_EMERGENCY_CONTACT_MUTATION = gql`
  mutation DeleteUserEmergencyContact($id: ID!) {
    deleteUserEmergencyContact(id: $id) {
      found
      deletedId
    }
  }
`
export const SET_PRIMARY_USER_EMERGENCY_CONTACT_MUTATION = gql`
  mutation SetPrimaryEmergencyContact($id: ID!, $user: ID!) {
    patchUserEmergencyContact(id: $id, input: { user: $user, primary: true }) {
      userEmergencyContact {
        id
      }
    }
  }
`

export const SET_USER_SETTING_MUTATION = gql`
  mutation SetUserSetting($key: String!, $value: String!) {
    setUserSetting(key: $key, value: $value) {
      userSetting {
        id
      }
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($newPassword: String!, $oldPassword: String) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ok
      reason
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($username: String!) {
    resetPassword(username: $username) {
      ok
      reason
    }
  }
`

export const CREATE_USER_TYPE_MUTATION = gql`
  mutation CreateUserType($input: CreateUserTypeInput!) {
    createUserType(input: $input) {
      userType {
        id
      }
    }
  }
`

export const PATCH_USER_TYPE_MUTATION = gql`
  mutation PatchUserType($id: ID!, $input: PatchUserTypeInput!) {
    patchUserType(id: $id, input: $input) {
      userType {
        id
      }
    }
  }
`

export const TOGGLE_USER_TYPE_PERMISSION_MUTATION = gql`
  mutation ToggleUserTypePermission(
    $userType: ID!
    $permission: ID!
    $enabled: Boolean!
  ) {
    toggleUserTypePermission(
      userType: $userType
      permission: $permission
      enabled: $enabled
    ) {
      ok
    }
  }
`

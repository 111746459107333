import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { Card as BaseCard, PageHeader } from 'components'
import React, { useCallback, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { useCompany, useConfirm, useOnErrorAuto, useToast } from 'util/hooks'
import { CompanySettingsMobile, Folders, Information, Other } from '.'
import {
  CREATE_COMPANY_FOLDER_SETTING_MUTATION,
  DELETE_COMPANY_FOLDER_SETTING_MUTATION,
  PATCH_COMPANY_FOLDER_SETTING_MUTATION,
  PATCH_COMPANY_MUTATION,
} from '../mutations'
import {
  CreateCompanyFolderSettingMutation,
  CreateCompanyFolderSettingMutationVariables,
  DeleteCompanyFolderSettingMutation,
  PatchCompanyFolderSettingMutation,
  PatchCompanyFolderSettingMutationVariables,
  PatchCompanyMutation,
  PatchCompanyMutationVariables,
} from '../types.graphql'

export const COMPANY_SETTING_KEYS = {
  projectLeaderAdminOnly: 'project-leader-admin-only',
}

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
`

export const Card = styled(BaseCard)`
  position: relative;
  height: fit-content;

  color: ${props => props.theme.colors.gray2};
  line-height: 1.25rem;
  font-size: 0.8rem;

  &.other {
    padding-bottom: 0;
  }

  header {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.gray1};
  }

  div.divider {
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.gray6};
  }

  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem;
    border-radius: 0;
  }
`

export interface EditCompanyForm {
  fullName: string
  email: string
  phoneNumber: string
  address: string
  postalCode: string
  postalArea: string
  defaultHourlyRate: number
}

interface CompanySettingsProps {}

export const CompanySettings: React.FC<CompanySettingsProps> = () => {
  const wasDeletedRef = useRef(false)

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const confirm = useConfirm()
  const company = useCompany()

  const translations = useTranslate({
    companySettings: 'companies.company-settings',

    information: 'common.information',
    folders: 'company.company-folders',
    folderDescription: 'company.company-folders-help-text',

    other: 'common.other',

    prompt: {
      deleteFolder: 'companies.prompts.delete-folder-setting',
      deleteFolderTitle: 'companies.prompts.delete-folder-setting-title',
    },
    results: {
      patchSuccess: 'companies.toasts.company-was-edited',
      createFolderSuccess: 'handbook.toasts.folder-created',
      deleteFolderSuccess: 'handbook.toasts.folder-deleted',

      uniqueConstraint: 'errors.title-must-be-unique',
    },
  })

  const opts = {
    refetchQueries: ['Bootstrap'],
    onError: onErrorAuto(),
  }

  const [patchCompanyMutation, { loading: patchCompanyMutationLoading }] =
    useMutation<PatchCompanyMutation, PatchCompanyMutationVariables>(
      PATCH_COMPANY_MUTATION,
      {
        ...opts,
        onCompleted() {
          addToast('success', translations.results.patchSuccess)
        },
        onError: onErrorAuto(),
      }
    )

  const [
    createCompanyFolderSetting,
    { loading: createCompanyFolderSettingLoading },
  ] = useMutation<
    CreateCompanyFolderSettingMutation,
    CreateCompanyFolderSettingMutationVariables
  >(CREATE_COMPANY_FOLDER_SETTING_MUTATION, {
    ...opts,
    onCompleted() {
      addToast('success', translations.results.createFolderSuccess)
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueConstraint,
    }),
  })

  const [
    patchCompanyFolderSetting,
    { loading: patchCompanyFolderSettingLoading },
  ] = useMutation<
    PatchCompanyFolderSettingMutation,
    PatchCompanyFolderSettingMutationVariables
  >(PATCH_COMPANY_FOLDER_SETTING_MUTATION, {
    ...opts,
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueConstraint,
    }),
  })

  const [
    deleteCompanyFolderSetting,
    { loading: deleteCompanyFolderSettingLoading },
  ] = useMutation<DeleteCompanyFolderSettingMutation, IdVariable>(
    DELETE_COMPANY_FOLDER_SETTING_MUTATION,
    {
      ...opts,
      onCompleted() {
        addToast('success', translations.results.deleteFolderSuccess)
      },
      onError: onErrorAuto(),
    }
  )

  function handleUploadLogo(evt: React.ChangeEvent<HTMLInputElement> | null) {
    let file = null
    if (!!evt) file = Array.from(evt.target.files ?? [])[0]
    else wasDeletedRef.current = true

    patchCompanyMutation({
      variables: {
        id: company.id,
        input: {
          companyLogo: file,
        },
      },
    })

    if (!!evt) evt.target.value = ''
  }

  const handleAddFolder = useCallback(
    async (folderName: string) => {
      await createCompanyFolderSetting({
        variables: {
          input: {
            folderName,
            enabled: true,
          },
        },
      })
    },
    [createCompanyFolderSetting]
  )
  const handleEditFolder = useCallback(
    async (folderId: string, enabled: boolean, folderName?: string) => {
      await patchCompanyFolderSetting({
        variables: {
          id: folderId,
          input: {
            folderName,
            enabled,
          },
        },
      })
    },
    [patchCompanyFolderSetting]
  )
  const handleDeleteFolder = useCallback(
    async (id: string) => {
      const answer = await confirm(
        translations.prompt.deleteFolder,
        translations.prompt.deleteFolderTitle,
        {
          confirmBackground: 'matteRed',
          confirmHoverBackground: 'logoutRed',
          denyColor: 'white',
          denyBackground: 'gray3',
          denyHoverBackground: 'gray4',
        }
      )

      if (answer) {
        deleteCompanyFolderSetting({
          variables: {
            id,
          },
        })
      }
    },
    [
      confirm,
      deleteCompanyFolderSetting,
      translations.prompt.deleteFolder,
      translations.prompt.deleteFolderTitle,
    ]
  )

  async function handleEditCompany(values: EditCompanyForm) {
    if (!company) return

    const input = {
      ...values,
      defaultHourlyRate: values.defaultHourlyRate,
    }

    await patchCompanyMutation({
      variables: {
        id: company.id,
        input,
      },
    })
  }

  const loading =
    patchCompanyMutationLoading ||
    patchCompanyFolderSettingLoading ||
    createCompanyFolderSettingLoading ||
    deleteCompanyFolderSettingLoading

  return (
    <Wrapper>
      {isMobileOnly ? (
        <CompanySettingsMobile
          loading={loading}
          onUploadLogo={handleUploadLogo}
          onFormSubmit={handleEditCompany}
          onAddFolder={handleAddFolder}
          onEditFolder={handleEditFolder}
          onDeleteFolder={handleDeleteFolder}
        />
      ) : (
        <>
          <PageHeader
            title={translations.companySettings}
            loading={loading}
            largeMargin
          />

          <ContentWrapper>
            <Card>
              <header>{translations.information}</header>

              <Information
                onUploadLogo={handleUploadLogo}
                onFormSubmit={handleEditCompany}
              />
            </Card>

            <Card>
              <header>{translations.folders}</header>
              <p>{translations.folderDescription}</p>

              <div className="divider" />

              <Folders
                onAddFolder={handleAddFolder}
                onEditFolder={handleEditFolder}
                onDeleteFolder={handleDeleteFolder}
              />
            </Card>

            <Card className="other">
              <header>{translations.other}</header>

              <Other />
            </Card>
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  )
}

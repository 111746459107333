import { useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader as BasePageHeader,
  TableFiltering,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import xor from 'lodash/xor'
import { ProjectSelectModal } from 'modules/projects/components/ProjectSelectModal'
import { PROJECTS_SHALLOW_QUERY } from 'modules/projects/queries'
import {
  ProjectsShallowQuery,
  ProjectsShallowQueryVariables,
} from 'modules/projects/types.graphql'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useOnErrorAuto, usePermission } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { DeviationsTable, DeviationStatusType } from './components'
import { MobileDeviations } from './mobile/MobileDeviations'
import { ALL_CATEGORIES_QUERY, DEVIATIONS_SHALLOW_QUERY } from './queries'
import { DeviationPromptState } from './types'
import {
  AllCategoriesQuery,
  DeviationsShallowQuery,
  DeviationsShallowQueryVariables,
} from './types.graphql'
import {
  useCreateDeviation,
  useDeleteDeviation,
  useDeviationMenu,
  useDeviationsPagination,
  useEditDeviation,
} from './util'
import mixpanel from 'mixpanel-browser'

interface WrapperProps {
  isProjectView: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${props => props.isProjectView && '0 1rem 1rem'};

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

interface PageHeaderProps {
  isProjectView: boolean
}

const PageHeader = styled(BasePageHeader)<PageHeaderProps>`
  padding-top: 0;
  margin: ${props => props.isProjectView && '0'};
`

const PAGE_SIZE = 25

interface ProjectDeviationsProps {
  projectId?: string
}

export const ProjectDeviations: React.FC<ProjectDeviationsProps> = ({
  projectId,
}) => {
  const translations = useTranslate({
    createDeviation: 'deviations.create-deviation',
    delete: 'common.delete',
    edit: 'common.edit',
    search: 'deviations.search',
    openDeviation: 'deviations.open-deviation',

    prompt: {
      deleteDeviation: 'deviations.delete-deviation-prompt',
      deleteDeviationTitle: 'deviations.delete-deviation',
    },

    results: {
      deviationDeleted: 'deviations.delete-deviation-success',
      deviationDeleteError: 'deviations.prompts.could-not-delete',
      editSuccess: 'deviations.deviation-edit-success-toast',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const hasLaunchedModalByRouteStateRef = useRef(false)

  const isProjectView = !!projectId
  const canViewProjects = usePermission(PERMISSIONS.projects.view.project)

  const location = useLocation<{ create?: boolean } | undefined>()
  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt<DeviationPromptState>({
    closeOnResolve: false,
    initialState: {
      loading: false,
    },
  })

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [selectedProject, setSelectedProject] = useState<string | null>()
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<
    DeviationStatusType[]
  >(
    isProjectView
      ? ['underTreatment', 'closed', 'archived']
      : ['underTreatment']
  )

  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreDeviations,
  } = useQuery<DeviationsShallowQuery, DeviationsShallowQueryVariables>(
    DEVIATIONS_SHALLOW_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        first: PAGE_SIZE,
        q: debouncedSearch,
        project: projectId ?? selectedProject ?? undefined,
        categories: selectedCategories,
        orderBy: sort,
        status: selectedStatuses,
      },
      onCompleted() {
        setHasLoadedOnce(true)
      },
      onError: console.error,
    }
  )

  const {
    createDeviation: handleCreateDeviation,
    loading: createDeviationLoading,
  } = useCreateDeviation()
  const { patchDeviation: handleEditDeviation, loading: editDeviationLoading } =
    useEditDeviation()

  const deviations = useMemo(
    () => data?.allDeviations.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const hasDeviations = useMemo(() => !!data?.hasDeviations, [data])

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<ProjectsShallowQuery, ProjectsShallowQueryVariables>(
    PROJECTS_SHALLOW_QUERY,
    {
      skip: !!projectId || !canViewProjects,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        orderBy: 'name',
      },
      onError: onErrorAuto(),
    }
  )

  const projects = useMemo(
    () => projectsData?.allProjects.edges.map(({ node }) => node) ?? [],
    [projectsData]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useDeviationsPagination(data?.allDeviations.pageInfo, fetchMoreDeviations)

  useInfiniteScroll(handleFetchMore)

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery<AllCategoriesQuery>(ALL_CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const categories = useMemo(
    () =>
      categoriesData?.allDeviationCategories.edges.map(({ node }) => node) ??
      [],
    [categoriesData]
  )

  const {
    deleteDeviation: handleDeleteDeviation,
    loading: deleteDeviationLoading,
  } = useDeleteDeviation(['DeviationsShallow'])

  function handleFilterChange(value: TableFiltering) {
    const first = value.checked[0]

    value.column === 'projectName'
      ? setSelectedProject(first ?? null)
      : value.column === 'status'
      ? setSelectedStatuses(value.checked as DeviationStatusType[])
      : setSelectedCategories(xor(first, value.checked))
  }

  const makeMenu = useDeviationMenu(handleDeleteDeviation, {
    open: deviation =>
      `/projects/${deviation.project?.id}/deviations/${deviation.id}/`,
    edit: deviation =>
      isMobileOnly
        ? handleEditDeviation({
            id: deviation.id,
          })
        : `/projects/${deviation.project?.id}/deviations/${deviation.id}/edit/`,
  })

  const handleSelectProject = useCallback(async () => {
    const { data: id } = await addPrompt<string | null>(
      resolve => <ProjectSelectModal onSubmit={resolve} />,
      {
        closeOnResolve: true,
      }
    )
    if (!id) return

    mixpanel.track('Select Project', {
      Context: 'Create Deviation',
    })

    if (isMobileOnly) {
      handleCreateDeviation({
        projectId: id,
      })
    } else {
      history.push(`/projects/${id}/deviations/create`)
    }
  }, [addPrompt, handleCreateDeviation, history])

  useLayoutEffect(() => {
    if (hasLaunchedModalByRouteStateRef.current || !location.state) return
    hasLaunchedModalByRouteStateRef.current = true

    if (!!location.state.create) handleSelectProject()
  }, [handleSelectProject, location.state])

  if (error || projectsError || categoriesError)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading =
    loading ||
    fetchMoreLoading ||
    projectsLoading ||
    categoriesLoading ||
    createDeviationLoading ||
    editDeviationLoading ||
    deleteDeviationLoading

  return (
    <EmptyPage
      module="deviations"
      empty={!hasDeviations && !isProjectView}
      loading={!hasLoadedOnce}
      isTabView
      buttonPermissions={[
        PERMISSIONS.deviations.add.deviation,
        PERMISSIONS.projects.view.project,
      ]}
      onButtonClick={() => {
        !!projectId
          ? history.push(`/projects/${projectId}/deviations/create`)
          : handleSelectProject()
      }}
    >
      <Wrapper isProjectView={isProjectView}>
        {isMobileOnly ? (
          <MobileDeviations
            deviations={deviations}
            categories={categories}
            projects={!projectId ? projects : undefined}
            search={search}
            initialStatuses={selectedStatuses}
            makeMenu={makeMenu}
            hideProject={!!projectId}
            onCreateNewDeviation={() =>
              !!projectId ? handleCreateDeviation() : handleSelectProject()
            }
            onSearchChange={setSearch}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <>
            <PageHeader
              isProjectView={isProjectView}
              loading={isLoading}
              tabMargin={!isProjectView}
              search={{
                value: search,
                placeholder: translations.search,
                left: true,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.createDeviation,
                  icon: 'plus',
                  permissions: [
                    PERMISSIONS.deviations.add.deviation,
                    PERMISSIONS.projects.view.project,
                  ],
                  onClick: () => {
                    !!projectId
                      ? history.push(`/projects/${projectId}/deviations/create`)
                      : handleSelectProject()
                  },
                },
              ]}
            />

            <DeviationsTable
              deviations={deviations}
              projects={projects}
              categories={categories}
              search={debouncedSearch}
              excludeColumns={
                !!projectId ? ['projectName', 'customerName'] : []
              }
              maxCategories={!!projectId ? 3 : 1}
              initialStatuses={selectedStatuses}
              makeMenu={makeMenu}
              onFilterChange={handleFilterChange}
              onSortingChange={setSort}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader,
  Table,
  TableColumn,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { formatPhoneNumber } from 'util/formatting'
import {
  useCompany,
  useLazyQuery,
  useOnErrorAuto,
  usePermission,
  useToast,
} from 'util/hooks'
import { useConfirm } from 'util/hooks/useConfirm'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { CreateCustomerModal } from './CreateCustomerModal'
import { CustomersMobile } from './CustomersMobile'
import { EditCustomerModal } from './EditCustomerModal'
import { CREATE_CUSTOMER_MUTATION, PATCH_CUSTOMER_MUTATION } from './mutations'
import { CUSTOMERS_SHALLOW_QUERY, CUSTOMER_PROJECTS_QUERY } from './queries'
import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  CustomerProjectsQuery,
  CustomerProjectsQueryVariables,
  CustomersShallowQuery,
  CustomersShallowQueryVariables,
  PatchCustomerMutation,
  PatchCustomerMutationVariables,
  ShallowCustomer,
} from './types.graphql'
import { useCustomersPagination } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    text-decoration: none;
  }

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

export interface CustomerPromptState {
  loading: boolean
}

const PAGE_SIZE = 25

interface CustomersProps {}

export const Customers: React.FC<CustomersProps> = () => {
  const translations = useTranslate({
    contact: 'common.contact',
    createCustomer: 'customers.create-customer',
    customers: 'common.customers',
    delete: 'common.delete',
    edit: 'common.edit',
    email: 'common.email',
    name: 'common.name',
    phone: 'common.phone',
    search: 'customers.search',
    showCustomer: 'customers.show-customer',
    type: 'common.type',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    prompts: {
      deleteCustomerProjectList: [
        'customers.prompts.n-deleting-customer-project-list',
        { n: 0 },
      ],
      deleteCustomer: 'customers.prompts.delete-customer',
      deleteCustomerTitle: 'customers.prompts.delete-customer-title',
    },

    results: {
      createSuccess: 'customers.create-success',
      deleteError: 'customers.delete-error',
      deleteSuccess: 'customers.delete-success',
      editSuccess: 'customers.edit-success',
      queryError: 'server.general-error-try-again-later',
    },

    types: {
      company: 'customers.customer-types.company',
      private: 'customers.customer-types.private',
    },
  })

  const wasDeletedRef = useRef(false)
  const hasLaunchedModalByRouteStateRef = useRef(false)

  const { defaultHourlyRate } = useCompany()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const location = useLocation<
    { create?: boolean; edit?: string } | undefined
  >()
  const addToast = useToast()
  const addPrompt = usePrompt<CustomerPromptState>({
    closeOnResolve: false,
    initialState: {
      loading: false,
    },
  })
  const confirm = useConfirm()

  const [sort, setSort] = useState('name')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const canEditCustomer = usePermission(PERMISSIONS.customers.change.customer)
  const canDeleteCustomer = usePermission(PERMISSIONS.customers.delete.customer)

  const { data, loading, error, fetchMore } = useQuery<
    CustomersShallowQuery,
    CustomersShallowQueryVariables
  >(CUSTOMERS_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      orderBy: sort,
    },
    onError: onErrorAuto(),
  })

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useCustomersPagination(data?.allCustomers.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore)

  const [fetch] = useLazyQuery<
    CustomerProjectsQuery,
    CustomerProjectsQueryVariables
  >(CUSTOMER_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const [createCustomerMutation] = useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CREATE_CUSTOMER_MUTATION, {
    refetchQueries: ['Customers'],
    onCompleted(data) {
      addToast('success', translations.results.createSuccess)
      history.push(`/customers/${data.createCustomer.customer.id}`)
    },
    onError: onErrorAuto(),
  })

  const [patchCustomerMutation] = useMutation<
    PatchCustomerMutation,
    PatchCustomerMutationVariables
  >(PATCH_CUSTOMER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['CustomersShallow'],
    onCompleted(data) {
      if (wasDeletedRef.current === true) {
        addToast('success', translations.results.deleteSuccess)
        wasDeletedRef.current = false
        return
      }
      addToast('success', translations.results.editSuccess)
      history.push(`/customers/${data.patchCustomer.customer.id}`)
    },
    onError: onErrorAuto(
      wasDeletedRef.current
        ? translations.results.deleteError
        : translations.results.queryError
    ),
  })

  const customers = useMemo(
    () => data?.allCustomers.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const handleCreateCustomer = useCallback(async () => {
    const { data, close, update } =
      await addPrompt<CreateCustomerMutationVariables | null>(
        (resolve, _, { id }) => (
          <CreateCustomerModal
            id={id}
            defaultHourlyRate={defaultHourlyRate}
            onSubmit={resolve}
          />
        )
      )

    if (!data) {
      close()
      return
    }

    update({ loading: true })
    createCustomerMutation({
      variables: data,
    })
      .then(() => close())
      .finally(() => {
        mixpanel.track('Create Customer')
        return update({ loading: false })
      })
  }, [addPrompt, createCustomerMutation, defaultHourlyRate])

  const handleEditCustomer = useCallback(
    async (customerId: string) => {
      if (!isMobileOnly) {
        history.push(`/customers/${customerId}/edit`)
        return
      }

      const { data, close, update } =
        await addPrompt<CreateCustomerMutationVariables | null>(
          (resolve, _, { id }) => (
            <EditCustomerModal
              id={id}
              customerId={customerId}
              defaultHourlyRate={defaultHourlyRate}
              onSubmit={resolve}
            />
          )
        )
      if (!data) {
        close()
        return
      }

      update({ loading: true })
      patchCustomerMutation({
        variables: { ...data, id: customerId },
      })
        .then(() => close())
        .finally(() => update({ loading: false }))
    },
    [addPrompt, defaultHourlyRate, history, patchCustomerMutation]
  )

  const handleDeleteCustomer = useCallback(
    async (id: string) => {
      if (!canDeleteCustomer) return

      try {
        const { data, error } = await fetch({
          variables: { id },
        })

        if (error || !data) {
          addToast('error', translations.results.queryError)
          return
        }

        const numProjects = data.allProjects.edges.length
        const projectList = data.allProjects.edges
          .map(({ node: project }) => project.name)
          .join(', ')

        const prompt = !!numProjects
          ? `${translations.prompts.deleteCustomerProjectList({
              n: numProjects,
            })} ${projectList}`
          : translations.prompts.deleteCustomer

        const answer = await confirm(
          prompt,
          translations.prompts.deleteCustomerTitle
        )

        if (answer) {
          wasDeletedRef.current = true
          patchCustomerMutation({
            variables: {
              id,
              input: {
                deletedAt: new Date(),
              },
            },
          })
        }
      } catch (e) {
        console.error(e)
        addToast('error', translations.results.queryError)
      }
    },
    [
      canDeleteCustomer,
      fetch,
      translations.prompts,
      translations.results.queryError,
      confirm,
      addToast,
      patchCustomerMutation,
    ]
  )

  useLayoutEffect(() => {
    if (
      !isMobileOnly ||
      hasLaunchedModalByRouteStateRef.current ||
      !location.state
    )
      return
    hasLaunchedModalByRouteStateRef.current = true

    if (location.state.create === true) handleCreateCustomer()
    if (typeof location.state.edit === 'string')
      handleEditCustomer(location.state.edit)
  }, [location.state, handleCreateCustomer, handleEditCustomer])

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'name',
        label: translations.name,
        sortable: true,
      },
      {
        id: 'contactPersonName',
        label: translations.contact,
        sortable: true,
      },
      {
        id: 'contactPersonEmail',
        label: translations.email,
        sortable: true,
      },
      {
        id: 'contactPersonPhone',
        label: translations.phone,
        sortable: true,
      },
      {
        id: 'customerType',
        label: translations.type,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [translations]
  )

  const makeEditDeleteMenu = useCallback(
    (customer: ShallowCustomer): TableMenu => ({
      items: [
        {
          icon: 'suitcase',
          text: translations.showCustomer,
          onClick() {
            history.push(`/customers/${customer.id}`)
          },
        },
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditCustomer,
          onClick: () => handleEditCustomer(customer.id),
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hoverColor: '#e38080',
          hide: !canDeleteCustomer,
          onClick() {
            handleDeleteCustomer(customer.id)
          },
        },
      ],
    }),
    [
      canDeleteCustomer,
      canEditCustomer,
      handleDeleteCustomer,
      handleEditCustomer,
      history,
      translations.delete,
      translations.edit,
      translations.showCustomer,
    ]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      customers.map(customer => ({
        name: {
          content: (
            <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
          ),
        },
        contactPersonName: { content: customer.contactPersonName ?? '' },
        contactPersonEmail: {
          content: !!customer.contactPersonEmail && (
            <a href={`mailto:${customer.contactPersonEmail}`}>
              {customer.contactPersonEmail}
            </a>
          ),
        },
        contactPersonPhone: {
          content: !!customer.contactPersonPhone && (
            <a href={`tel:${customer.contactPersonPhone}`}>
              {formatPhoneNumber(customer.contactPersonPhone)}
            </a>
          ),
        },
        customerType: {
          content:
            customer.customerType === 'COMPANY'
              ? translations.types.company
              : translations.types.private,
        },
        menu: { content: '', menu: makeEditDeleteMenu(customer) },
      })) ?? [],
    [customers, makeEditDeleteMenu, translations]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          fontWeight: cell.columnId === 'name' ? 600 : 'inherit',
        },
      }),
    }),
    []
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  return (
    <Wrapper>
      {isMobileOnly ? (
        <CustomersMobile
          customers={customers}
          search={search}
          translations={translations}
          makeEditDeleteMenu={makeEditDeleteMenu}
          onSearchChange={setSearch}
          onCreate={handleCreateCustomer}
        />
      ) : (
        <>
          <PageHeader
            title={translations.customers}
            loading={loading}
            search={{
              value: search,
              placeholder: translations.search,
              onChange: setSearch,
            }}
            buttons={[
              {
                text: translations.createCustomer,
                icon: 'plus',
                permissions: PERMISSIONS.customers.add.customer,
                onClick: () => history.push('/customers/create'),
              },
            ]}
          />

          <Table
            columns={columns}
            rows={rows}
            options={tableOptions}
            initialSort={sort}
            onSortingChange={setSort}
          />
        </>
      )}

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </Wrapper>
  )
}

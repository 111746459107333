import { FontAwesomeIcon } from '@ur/react-components/build/types/css'

export function getFileExtension(name: string) {
  const match = name.match(/\.\w+$/)
  return !!match ? match[0].toLowerCase() : null
}

export function getFileType(name: string) {
  const ext = getFileExtension(name)
  if (!ext) return 'other'

  switch (ext) {
    // Images
    case '.jpeg':
    case '.jpg':
    case '.png':
    case '.gif':
    case '.bmp':
    case '.tiff':
    case '.svg':
      return 'image'
    // Audio
    case 'mp3':
    case 'wav':
    case 'pcm':
    case 'aiff':
    case 'aac':
    case 'ogg':
    case 'wma':
    case 'flac':
    case 'alac':
      return 'audio'
    // Video
    case 'mp4':
    case 'mov':
    case 'wmv':
    case 'flv':
    case 'avi':
    case 'avchd':
    case 'webm':
    case 'mkv':
      return 'video'
    // PDF
    case '.pdf':
      return 'pdf'
    // CSV
    case '.csv':
    case '.tsv':
      return 'csv'
    // Word
    case '.doc':
    case '.docm':
    case '.docx':
    case '.dot':
    case '.dotm':
    case '.dotx':
    case '.odt':
    case '.rtf':
      return 'word'
    // Excel
    case '.xls':
    case '.xlsx':
    case '.xlsb':
    case '.xlsm':
    case '.xltm':
    case '.xla':
    case '.xlam':
    case '.ods':
      return 'excel'
    // PowerPoint
    case '.ppt':
    case '.pptx':
    case '.pptm':
    case '.pot':
    case '.potx':
    case '.potm':
    case '.pps':
    case '.ppsm':
    case '.ppsx':
    case '.ppam':
    case '.odp':
      return 'powerpoint'
    // Archive
    case '.zip':
    case '.zipx':
    case '.rar':
    case '.tar':
    case '.gz':
    case '.lz':
    case '.7z':
    case '.zz':
    case '.arc':
    case '.ark':
      return 'archive'
    // Other
    default:
      return 'other'
  }
}

export function getFileIcon(name: string): FontAwesomeIcon {
  const type = getFileType(name)

  switch (type) {
    case 'image':
      return 'file-image'
    case 'audio':
      return 'file-audio'
    case 'video':
      return 'file-video'
    case 'pdf':
      return 'file-pdf'
    case 'csv':
      return 'file-csv'
    case 'word':
      return 'file-word'
    case 'excel':
      return 'file-excel'
    case 'powerpoint':
      return 'file-powerpoint'
    case 'archive':
      return 'file-archive'
    default:
      return 'file-alt'
  }
}

export function getFileIconColor(name: string) {
  const type = getFileType(name)

  switch (type) {
    case 'image':
      return 'primary'
    case 'audio':
      return 'forestgreen'
    case 'video':
      return 'orange'
    case 'pdf':
      return 'danger'
    case 'csv':
      return 'blueviolet'
    case 'word':
      return '#00a2ed'
    case 'excel':
      return '#1d6f42'
    case 'powerpoint':
      return '#d04423'
    case 'archive':
      return 'ilve'
    default:
      return '#333'
  }
}

export function getFileSizeString(size: number) {
  const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
  let order = 0
  while (size >= 1024 && order < suffixes.length - 1) {
    order++
    size /= 1024
  }

  const suffix = suffixes[order]
  let sizeStr = size.toFixed(1)
  sizeStr.endsWith('.0') && (sizeStr = sizeStr.substring(0, sizeStr.length - 2))
  return `${sizeStr} ${suffix}`
}

export const isGlobalAccept = (accept?: string) =>
  typeof accept === 'undefined' ||
  accept === '' ||
  accept === '*' ||
  accept === '*/*'

export function fileIsAllowed(type: string, accept?: string): boolean
export function fileIsAllowed(file: File, accept?: string): boolean
export function fileIsAllowed(file: string | File, accept?: string) {
  if (typeof accept === 'undefined' || isGlobalAccept(accept)) return true

  const filename = typeof file === 'string' ? null : file.name
  const type = typeof file === 'string' ? file : file.type
  const acceptMIMEs = accept.split(',').map(a => a.trim())

  for (const mime of acceptMIMEs) {
    if (isGlobalAccept(mime)) return true

    const re = new RegExp(mime.replace('*', '.+'))
    if (re.test(type)) return true
    if (!!filename && mime.charAt(0) === '.' && filename.endsWith(mime))
      return true
  }

  return false
}

export async function getFileUrl(file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = evt => {
      const result = evt.target?.result
      if (!result || typeof result !== 'string') resolve('')
      else resolve(result)
    }
    reader.readAsDataURL(file)
  })
}

export function checkIsImage(file: string | File) {
  if (typeof file === 'string') return getFileType(file) === 'image'
  return file.type.includes('image')
}

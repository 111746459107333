import mixpanel from 'mixpanel-browser'

const projectId =
  process.env.REACT_APP_STAGE === 'production'
    ? 'ea51fc4afb0390bfb13a50fcd79f94b6'
    : '785b55b715ae6fc995a22823475156d3'

mixpanel.init(projectId, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  ignore_dnt: true,
})

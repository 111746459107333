import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  Card,
  FormField,
  Input as BaseInput,
} from 'components'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { PATCH_CHECKLIST_MUTATION } from '../mutations'
import { Checklist } from '../types.graphql'
import {
  PatchChecklistMutation,
  PatchChecklistMutationVariables,
} from '../types.graphql'

const Wrapper = styled(Card)`
  grid-area: purpose;
  height: max-content;
  padding: 1rem;

  ${props => props.theme.media.mobile} {
    border-radius: 0;
    box-shadow: ${props => props.theme.layout.defaultCardShadow};
  }
`
const Inner = styled(FormField)`
  & > div {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`
const Input = styled(BaseInput)`
  input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const Button = styled(BaseButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

interface ChecklistPurposeProps {
  checklist: Checklist
}

export const ChecklistPurpose: React.FC<ChecklistPurposeProps> = ({
  checklist,
}) => {
  const translations = useTranslate({
    purpose: 'common.purpose',
    placeholder: 'checklists.purpose-placeholder',
    save: 'common.save',

    success: 'checklists.success.patch-purpose',
    error: 'checklists.errors.patch-purpose',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [purpose, setPurpose] = useState(checklist.purpose)

  const [patchChecklist, { loading }] = useMutation<
    PatchChecklistMutation,
    PatchChecklistMutationVariables
  >(PATCH_CHECKLIST_MUTATION, {
    refetchQueries: ['Checklist'],
    onCompleted() {
      addToast('success', translations.success)
    },
    onError: onErrorAuto(translations.error),
  })

  function handleSubmit() {
    patchChecklist({
      variables: {
        id: checklist.id,
        input: { purpose },
      },
    })
  }

  return (
    <Wrapper>
      <Inner>
        <label>{translations.purpose}</label>

        <div>
          <Input
            value={purpose}
            fullWidth
            placeholder={translations.placeholder}
            onKeyDown={evt => evt.key === 'Enter' && handleSubmit()}
            onChange={setPurpose}
          />

          <Button
            height={isMobileOnly ? '56px' : '40px'}
            disabled={purpose === checklist.purpose}
            loading={loading}
            onClick={handleSubmit}
          >
            {translations.save}
          </Button>
        </div>
      </Inner>
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { Icon, PromptResolve } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Input,
  RegularModal as BaseRegularModal,
} from 'components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useKeyPress, useOnErrorAuto } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { SELECT_CHECKLIST_TEMPLATE_QUERY } from '../queries'
import {
  ChecklistTemplateSelectQuery,
  ChecklistTemplateSelectQueryVariables,
  SelectChecklistTemplate,
} from '../types.graphql'
import { useChecklistTemplatePagination } from '../util'

const RegularModal = styled(BaseRegularModal)`
  ${props => props.theme.media.mobile} {
    .content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`

const Search = styled(Input)`
  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 0 1rem;
  }
`

const Templates = styled.div`
  max-height: 60vh;
  margin-top: 1rem;
  overflow-y: auto;

  ${props => props.theme.media.mobile} {
    max-height: 100%;
  }
`

const Template = styled.div`
  display: grid;

  grid-template-rows: 56px auto;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title chevron'
    'items items';
  column-gap: 1rem;
  align-items: center;

  &:nth-child(even) {
    background-color: ${props => props.theme.colors.gray8};
  }
  i.chevron {
    grid-area: chevron;
    cursor: pointer;

    padding-right: 1rem;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
  div.title {
    grid-area: title;
    padding-left: 1rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 600;

    span {
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  ${props => props.theme.media.desktop} {
    width: 100%;
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  }
`
const TemplateItems = styled.ul`
  grid-area: items;
  margin: 0 0 1rem 0;

  li {
    margin: 0.25rem 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child  {
      margin-bottom: 0;
    }
  }
`

const PAGE_SIZE = 25

interface ChecklistTemplateSelectModalProps {
  onSubmit: PromptResolve<SelectChecklistTemplate | null>
}
export const ChecklistTemplateSelectModal: React.FC<
  ChecklistTemplateSelectModalProps
> = ({ onSubmit }) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    filter: 'checklists.search-for-templates',
    none: 'common.no-results',
    selectTemplate: 'checklists.select-template',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const onErrorAuto = useOnErrorAuto()

  const [templateOpen, setTemplateOpen] = useState<string | null>(null)
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null
  )

  const { data, loading, fetchMore } = useQuery<
    ChecklistTemplateSelectQuery,
    ChecklistTemplateSelectQueryVariables
  >(SELECT_CHECKLIST_TEMPLATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      orderBy: 'title',
    },
    onError: onErrorAuto(),
  })

  const templates = useMemo(
    () => data?.allChecklistTemplates.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useChecklistTemplatePagination(
    data?.allChecklistTemplates.pageInfo,
    fetchMore
  )

  useInfiniteScroll(handleFetchMore, { element: scrollElement })

  const escape = useKeyPress('Escape')
  useEffect(() => {
    if (escape) onSubmit(null)
  }, [escape, onSubmit])

  return (
    <RegularModal
      title={translations.selectTemplate}
      loading={loading || fetchMoreLoading}
      width="50vw"
      background="white"
      onClose={() => onSubmit(null)}
    >
      <Search
        value={search}
        autoFocus
        fullWidth
        placeholder={translations.filter}
        iconRightProps={{ icon: 'search', color: 'gray4' }}
        onChange={setSearch}
      />

      <Templates ref={setScrollElement}>
        {templates.map(template => (
          <Template key={template.id}>
            <div className="title">
              <span onClick={() => onSubmit(template)}>{template.title}</span>
            </div>

            <Icon
              className="chevron"
              icon={
                templateOpen === template.id ? 'chevron-up' : 'chevron-down'
              }
              color={templateOpen === template.id ? 'primary' : 'gray3'}
              size="1.2rem"
              onClick={() =>
                setTemplateOpen(v => (v === template.id ? null : template.id))
              }
            />

            {templateOpen === template.id && (
              <TemplateItems>
                {template.items.edges.map(({ node }) => (
                  <li key={node.id}>{node.title}</li>
                ))}
              </TemplateItems>
            )}
          </Template>
        ))}
      </Templates>

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </RegularModal>
  )
}

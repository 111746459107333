import { useApolloClient, useQuery } from '@apollo/client'
import { Loader } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { Message } from 'components'
import { BrowserRouter } from 'containers/Routing'
import { CompanyContext, UserContext } from 'context'
import { BootstrapQuery, BOOTSTRAP_QUERY } from 'modules/authentication'
import { RegisterUser } from 'modules/users'
import {
  USER_SETTING_LANGUAGE_DEFAULT,
  USER_SETTING_LANGUAGE_KEY,
} from 'modules/users/consts'
import { NeedsPasswordChange } from 'modules/users/NeedsPasswordChange'
import React from 'react'
import styled from 'styled-components'
import { getPreviousCompany, setCompany } from 'util/auth'
import { isAllowedLanguage } from 'util/typecheck'
import { NoAuthRoutes } from './NoAuthRoutes'
import { NoCompanyRoutes } from './NoCompanyRoutes'
import { Routes } from './Routes'
import mixpanel from 'mixpanel-browser'
import { hash } from '../util/crypto'
import MixpanelTrackingProvider from '../modules/mixpanel/MixpanelTrackingProvider'

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
`

const companyBasePathRegex = /^\/c\/[\w-]+/

export const Bootstrap: React.FC = () => {
  const { errorText } = useTranslate({
    errorText: 'server.general-error-try-again-later',
  })

  const client = useApolloClient()
  const [, setLanguage] = useGlobal('settings.language')

  const { loading, data, error } = useQuery<BootstrapQuery>(BOOTSTRAP_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (!data?.me) return

      mixpanel.identify(hash(data.me.id))

      const langSetting = data.me.settings.edges.find(
        ({ node }) => node.key === USER_SETTING_LANGUAGE_KEY
      )
      if (!!langSetting) {
        const lang = langSetting.node.value
        setLanguage(
          isAllowedLanguage(lang) ? lang : USER_SETTING_LANGUAGE_DEFAULT
        )
      }

      !!data.currentCompany && setCompany(data.currentCompany.shortName)
    },
  })

  const match = window.location.pathname.match(companyBasePathRegex)
  if (!match) return <NoCompanyRoutes />

  const me = data?.me
  const currentCompany = data?.currentCompany

  if (loading || !data)
    return (
      <Centered>
        <Loader.Spinner size={68} thickness={6} />
      </Centered>
    )
  else if (!currentCompany) {
    client.cache.reset()

    const prevCompany = getPreviousCompany()
    if (
      !!prevCompany &&
      !!me?.companies.edges.some(({ node }) => node.shortName === prevCompany)
    )
      window.location.href = `/c/${prevCompany}`
    else window.location.href = '/login'

    return (
      <Centered>
        <h1>Redirecting</h1>
      </Centered>
    )
  } else if (!!error)
    return (
      <Centered>
        <Message.Error show text={errorText} fontSize="1.6rem" />
      </Centered>
    )

  return (
    <CompanyContext.Provider value={currentCompany}>
      <BrowserRouter basename={match[0]}>
        <MixpanelTrackingProvider>
          {!me ? (
            <NoAuthRoutes />
          ) : (
            <UserContext.Provider value={me}>
              {!me.hasRegistered ? (
                <RegisterUser />
              ) : me.needsPasswordChange ? (
                <NeedsPasswordChange />
              ) : (
                <Routes />
              )}
            </UserContext.Provider>
          )}
        </MixpanelTrackingProvider>
      </BrowserRouter>
    </CompanyContext.Provider>
  )
}

import {
  Loader,
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import throttle from 'lodash/throttle'
import { UIEvent, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

interface SelectWrapperProps {
  borderTopLeftRadius?: string
  borderTopRightRadius?: string
  borderBottomRightRadius?: string
  borderBottomLeftRadius?: string
  border?: string
  borderTop?: string
  color?: string
}
const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;

  .--select-display {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-top-left-radius: ${props => props.borderTopLeftRadius};
    border-top-right-radius: ${props => props.borderTopRightRadius};
    border-bottom-right-radius: ${props => props.borderBottomRightRadius};
    border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
    border: ${props => props.border};
    border-top: ${props => props.borderTop};
  }

  .--select-curtain {
    border: ${props => props.border};
  }
  .--select-placeholder {
    color: ${props => props.color ?? props.theme.colors.gray4};
  }
  .--select-curtain-option {
    padding: 0.5rem 1rem;
  }
`

const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 2.5rem;

  display: flex;
  align-items: center;

  height: 100%;
  padding-left: 1rem;
`

export interface SelectProps<V extends string | number, E = any>
  extends BaseSelectProps<V, E> {
  borderTopLeftRadius?: string
  borderTopRightRadius?: string
  borderBottomRightRadius?: string
  borderBottomLeftRadius?: string
  height?: string
  border?: string
  borderTop?: string

  loading?: boolean
  loadMoreThreshold?: number
  onLoadMore?: () => void
}

export const Select = <V extends string | number, E = any>({
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  border,
  borderTop,

  height,

  loading = false,
  loadMoreThreshold = 50,
  onLoadMore,

  ...props
}: SelectProps<V, E>) => {
  const { placeholder } = useTranslate({
    placeholder: 'common.select-placeholder',
  })

  const prevScrollTop = useRef(0)

  const throtthledCallback = throttle(
    !!onLoadMore ? onLoadMore : () => void 0,
    500,
    {
      leading: true,
      trailing: false,
    }
  )

  const getHandler = (element: EventTarget & Element) => () => {
    const scroller = element
    if (!scroller) return

    const { scrollTop } = scroller
    const prev = prevScrollTop.current
    prevScrollTop.current = scrollTop

    // Ignore if scrolling upwards
    if (scrollTop <= prev) {
      return
    }

    const limit = scroller.scrollHeight - scroller.clientHeight
    if (limit - scrollTop < loadMoreThreshold) throtthledCallback()
  }

  function handleCurtainScroll(evt: UIEvent) {
    const scrollHandler = getHandler(evt.currentTarget)
    evt.target.addEventListener('scroll', scrollHandler)
  }

  return (
    <SelectWrapper
      borderTopLeftRadius={borderTopLeftRadius}
      borderTopRightRadius={borderTopRightRadius}
      borderBottomRightRadius={borderBottomRightRadius}
      borderBottomLeftRadius={borderBottomLeftRadius}
      border={border}
      borderTop={borderTop}
    >
      {loading && (
        <Loading>
          <Loader.Spinner size={22} />
        </Loading>
      )}

      <BaseSelect
        height={height ? height : isMobileOnly ? '56px' : '40px'}
        placeholder={placeholder}
        onCurtainScroll={handleCurtainScroll}
        {...props}
        iconProps={{
          color: props.color ?? 'gray4',
          type: 'solid',
          size: '1.2rem !important',
          translateY: '1px',
          ...props.iconProps,
        }}
      />
    </SelectWrapper>
  )
}

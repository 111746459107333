import { gql } from '@apollo/client'

/* Deviations */

export const CREATE_DEVIATION_MUTATION = gql`
  mutation CreateDeviation($input: CreateDeviationInput!) {
    createDeviation(input: $input) {
      deviation {
        id
        project {
          id
        }
      }
    }
  }
`

export const PATCH_DEVIATION_MUTATION = gql`
  mutation PatchDeviation($id: ID!, $input: PatchDeviationInput!) {
    patchDeviation(id: $id, input: $input) {
      deviation {
        id
      }
    }
  }
`

export const DELETE_DEVIATION_MUTATION = gql`
  mutation DeleteDeviation($id: ID!) {
    deleteDeviation(id: $id) {
      found
      deletedId
    }
  }
`

export const SEND_DEVIATION_EMAIL_MUTATION = gql`
  mutation SendDeviationEmail($id: ID!, $recipient: String!, $tz: String) {
    sendDeviationEmail(id: $id, recipient: $recipient, tz: $tz) {
      emailSent
    }
  }
`

/* DeviationImages */

export const BATCH_CREATE_DEVIATION_IMAGE_MUTATION = gql`
  mutation BatchCreateDeviationImage(
    $input: [BatchCreateDeviationImageInput]!
  ) {
    batchCreateDeviationImage(input: $input) {
      deviationImages {
        id
      }
    }
  }
`

export const DELETE_DEVIATION_IMAGE_MUTATION = gql`
  mutation DeleteDeviationImage($id: ID!) {
    deleteDeviationImage(id: $id) {
      found
      deletedId
    }
  }
`

export const BATCH_DELETE_DEVIATION_IMAGE_MUTATION = gql`
  mutation BatchDeleteDeviationImage($input: BatchDeleteDeviationImageInput!) {
    batchDeleteDeviationImage(input: $input) {
      deletionCount
      deletedIds
    }
  }
`

/* DeviationCategories */

export const CREATE_DEVIATION_CATEGORY_MUTATION = gql`
  mutation CreateDeviationCategory($input: CreateDeviationCategoryInput!) {
    createDeviationCategory(input: $input) {
      deviationCategory {
        id
      }
    }
  }
`

export const PATCH_DEVIATION_CATEGORY_MUTATION = gql`
  mutation PatchDeviationCategory(
    $id: ID!
    $input: PatchDeviationCategoryInput!
  ) {
    patchDeviationCategory(id: $id, input: $input) {
      deviationCategory {
        id
      }
    }
  }
`

export const DELETE_DEVIATION_CATEGORY_MUTATION = gql`
  mutation DeleteDeviationCategory($id: ID!) {
    deleteDeviationCategory(id: $id) {
      found
      deletedId
    }
  }
`

/* DeviationPerformedActions */

export const CREATE_DEVIATION_PERFORMED_ACTION_MUTATION = gql`
  mutation CreateDeviationPerformedAction(
    $input: CreateDeviationPerformedActionInput!
  ) {
    createDeviationPerformedAction(input: $input) {
      deviationPerformedAction {
        id
      }
    }
  }
`

export const PATCH_DEVIATION_PERFORMED_ACTION_MUTATION = gql`
  mutation PatchDeviationPerformedAction(
    $id: ID!
    $input: PatchDeviationPerformedActionInput!
  ) {
    patchDeviationPerformedAction(id: $id, input: $input) {
      deviationPerformedAction {
        id
      }
    }
  }
`

export const DELETE_DEVIATION_PERFORMED_ACTION_MUTATION = gql`
  mutation DeleteDeviationPerformedAction($id: ID!) {
    deleteDeviationPerformedAction(id: $id) {
      found
      deletedId
    }
  }
`

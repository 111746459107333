import { formatRelative as formatRelativeBase } from 'date-fns'

interface formatRelativeOptions {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

export function formatRelative(locale: Locale) {
  return (
    firstDate: Date,
    secondDate: Date,
    options: formatRelativeOptions = {}
  ) =>
    formatRelativeBase(firstDate, secondDate, {
      ...options,
      locale,
    })
}

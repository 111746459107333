import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  TableColumn,
  TableFilteringChecklistOptions,
  TableMenu,
  TableRow,
} from 'components'
import { useCallback, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { IdVariable } from 'types/graphql'
import { toLocaleStringFixed } from 'util/formatting'
import { useConfirm, useOnErrorAuto, usePermission, useToast } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { CreateEditProductModal } from '../components/CreateEditProductModal'
import { ImportModal } from '../components/ImportModal'
import {
  CREATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
  IMPORT_PRODUCTS_MUTATION,
  PATCH_PRODUCT_MUTATION,
} from '../mutations'
import {
  ALL_PRODUCTS_QUERY,
  ALL_PRODUCT_MERCHANTS_QUERY,
  PRODUCT_QUERY,
} from '../queries'
import {
  AllProductMerchantsQuery,
  AllProductMerchantsQueryVariables,
  AllProductsQuery,
  AllProductsQueryVariables,
  CreateProductMutation,
  CreateProductMutationVariables,
  DeleteProductMutation,
  ImportProductsMutation,
  ImportProductsMutationVariables,
  PatchProductMutation,
  PatchProductMutationVariables,
  ProductQuery,
} from '../types.graphql'
import { useProductsPagination } from './useProductsPagination'
import { isCreateProductMutationVariables } from './util'

interface UseProductsUtilsOptions {
  queryVariables: AllProductsQueryVariables
  isImported: boolean
  infiniteScrollElement?: HTMLElement | null
  onQueryCompleted?: () => void
}

export function useProductsUtils({
  queryVariables,
  isImported,
  infiniteScrollElement,
  onQueryCompleted,
}: UseProductsUtilsOptions) {
  const translations = useTranslate({
    name: 'common.name',
    merchant: 'products.merchant',
    costPrice: 'products.cost-price',
    price: 'products.price',
    unit: 'products.unit',
    sku: 'products.article-number',

    edit: 'common.edit',
    delete: 'common.delete',

    prompts: {
      deleteProduct: 'products.prompts.delete-product',
      deleteProductTitle: 'products.prompts.delete-product-title',
    },

    results: {
      queryError: 'server.general-error-try-again-later',
      productDeleted: 'products.toasts.product-deleted-success',
      createSuccess: 'products.toasts.create-success',
      importSuccess: 'products.product-list-imported',
      patchSuccess: 'products.toasts.patch-success',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const history = useHistory()

  const canCreateProduct = usePermission(PERMISSIONS.products.add.product)
  const canEditProduct = usePermission(PERMISSIONS.products.change.product)
  const canDeleteProduct = usePermission(PERMISSIONS.products.delete.product)

  const { data, loading, error, fetchMore } = useQuery<
    AllProductsQuery,
    AllProductsQueryVariables
  >(ALL_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: queryVariables,
    onCompleted() {
      onQueryCompleted?.()
    },
    onError: onErrorAuto(),
  })

  const {
    data: merchantsData,
    loading: merchantsLoading,
    error: merchantsError,
  } = useQuery<AllProductMerchantsQuery, AllProductMerchantsQueryVariables>(
    ALL_PRODUCT_MERCHANTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { isImported: isImported },
      onError: onErrorAuto(),
    }
  )

  const [fetch] = useLazyQuery<ProductQuery, IdVariable>(PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: onErrorAuto(),
  })

  const [createProductMutation, { loading: createLoading }] = useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CREATE_PRODUCT_MUTATION, {
    refetchQueries: ['AllProductsQuery', 'AllProductMerchantsQuery'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.createSuccess)
    },
    onError: onErrorAuto(),
  })

  const [patchProductMutation, { loading: patchLoading }] = useMutation<
    PatchProductMutation,
    PatchProductMutationVariables
  >(PATCH_PRODUCT_MUTATION, {
    refetchQueries: ['AllProductsQuery', 'AllProductMerchantsQuery'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.patchSuccess)
    },
    onError: onErrorAuto(),
  })

  const [importProductsMutation, { loading: importLoading }] = useMutation<
    ImportProductsMutation,
    ImportProductsMutationVariables
  >(IMPORT_PRODUCTS_MUTATION, {
    refetchQueries: ['AllProductsQuery', 'AllProductMerchantsQuery'],
    awaitRefetchQueries: true,
    onCompleted(data) {
      data.importProducts.ok &&
        addToast('success', translations.results.importSuccess)
    },
    onError: onErrorAuto(),
  })

  const [deleteProductMutation, { loading: deleteLoading }] = useMutation<
    DeleteProductMutation,
    IdVariable
  >(DELETE_PRODUCT_MUTATION, {
    refetchQueries: ['AllProductsQuery', 'AllProductMerchantsQuery'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.productDeleted)
    },
    onError: onErrorAuto(),
  })

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useProductsPagination(data?.allProducts.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore, { element: infiniteScrollElement })

  const products = useMemo(
    () => data?.allProducts.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const merchants = useMemo(
    () => merchantsData?.allProductMerchants.map(merchant => merchant) ?? [],
    [merchantsData]
  )

  const merchantFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      items: merchants.map(merchant => ({
        id: merchant ?? '',
        text: merchant ?? '',
        initUnchecked: true,
      })),
    }),
    [merchants]
  )

  /* Utilities */

  async function handleCreateProduct() {
    if (!canCreateProduct) return
    if (!isMobileOnly) {
      history.push('/products/create')
      return
    }

    const { data } = await addPrompt<
      CreateProductMutationVariables | PatchProductMutationVariables | null
    >(resolve => <CreateEditProductModal onSubmit={resolve} />)

    if (!data || !isCreateProductMutationVariables(data)) return

    createProductMutation({
      variables: data,
    })
  }

  const handleEditProduct = useCallback(
    async (productId: string) => {
      if (!canEditProduct) return
      if (!isMobileOnly) {
        history.push(`/products/${productId}/edit`)
        return
      }

      const { data: productData, error: productError } = await fetch({
        variables: { id: productId },
      })

      if (productError || !productData) return

      const { data } = await addPrompt<
        CreateProductMutationVariables | PatchProductMutationVariables | null
      >(resolve => (
        <CreateEditProductModal
          product={productData.product}
          onSubmit={resolve}
        />
      ))
      if (!data) return

      patchProductMutation({
        variables: {
          id: productId,
          ...data,
        },
      })
    },
    [addPrompt, canEditProduct, fetch, history, patchProductMutation]
  )

  const handleImport = useCallback(async () => {
    const importProducts = async (
      imports: ImportProductsMutationVariables | null
    ) => {
      if (!canCreateProduct || !imports) return false

      const data = await importProductsMutation({
        variables: { ...imports },
      }).catch(() => false as const)

      if (data === false || !data.data) return false
      else if (!data.data.importProducts.ok)
        return data.data.importProducts.missingFieldMappings ?? false
      else return true
    }

    await addPrompt<null>(resolve => (
      <ImportModal
        onImport={(imports: ImportProductsMutationVariables) =>
          importProducts(imports)
        }
        onSubmit={resolve}
      />
    ))
  }, [addPrompt, canCreateProduct, importProductsMutation])

  const deleteProduct = useCallback(
    async (id: string) => {
      if (!canDeleteProduct || isImported) return

      const answer = await confirm(
        translations.prompts.deleteProduct,
        translations.prompts.deleteProductTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteProductMutation({
          variables: {
            id: id,
          },
        })
      }
    },
    [
      canDeleteProduct,
      confirm,
      deleteProductMutation,
      isImported,
      translations.prompts.deleteProduct,
      translations.prompts.deleteProductTitle,
    ]
  )

  const makeEditDeleteMenu = useCallback(
    (productId: string) => {
      if (isImported) return { items: [] }

      const items: TableMenu = {
        items: [
          {
            icon: 'edit',
            text: translations.edit,
            hide: !canEditProduct,
            onClick: () => handleEditProduct(productId),
          },
          {
            icon: 'trash',
            text: translations.delete,
            color: '#f39b9b',
            hide: !canDeleteProduct,
            hoverColor: '#e38080',
            onClick: () => deleteProduct(productId),
          },
        ],
      }

      return items
    },
    [
      canDeleteProduct,
      canEditProduct,
      deleteProduct,
      handleEditProduct,
      isImported,
      translations.delete,
      translations.edit,
    ]
  )

  /* Table config */

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'name',
        label: translations.name,
        sortable: true,
      },
      {
        id: 'merchant',
        label: translations.merchant,
        filtering: merchantFiltering,
        sortable: true,
      },
      {
        id: 'priceExcludingVat',
        label: translations.costPrice,
        sortable: true,
      },
      {
        id: 'grossPriceExcludingVat',
        label: translations.price,
        sortable: true,
      },
      {
        id: 'unit',
        label: translations.unit,
        sortable: true,
      },
      {
        id: 'sku',
        label: translations.sku,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        skip: isImported,
        shrink: true,
      },
    ],
    [
      translations.name,
      translations.merchant,
      translations.costPrice,
      translations.price,
      translations.unit,
      translations.sku,
      merchantFiltering,
      isImported,
    ]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      products.map(product => {
        return {
          name: {
            content: product.name,
          },
          merchant: { content: product.merchant ?? '' },
          priceExcludingVat: {
            content: toLocaleStringFixed(Number(product.priceExcludingVat)),
          },
          grossPriceExcludingVat: {
            content: toLocaleStringFixed(
              Number(product.grossPriceExcludingVat)
            ),
          },
          unit: {
            content: product.unit,
          },
          sku: {
            content: product.sku,
          },
          menu: { content: '', menu: makeEditDeleteMenu(product.id) },
        }
      }) ?? [],
    [products, makeEditDeleteMenu]
  )

  return {
    products,
    columns,
    rows,

    merchantFiltering,
    makeEditDeleteMenu,

    error: error || merchantsError,
    loading:
      loading ||
      merchantsLoading ||
      createLoading ||
      patchLoading ||
      importLoading ||
      deleteLoading,
    fetchMoreLoading,
    hasMore,

    handleCreateProduct,
    handleFetchMore,
    handleImport,
  }
}

import { Icon } from '@ur/react-components'
import { useClickOutside, useTranslate } from '@ur/react-hooks'
import { DateRange, Side } from 'components'
import {
  addMonths,
  compareAsc,
  endOfDay,
  isBefore,
  isSameMonth,
  startOfDay,
} from 'date-fns'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Month } from './Month'
import { QuickSelect } from './QuickSelect'
import { getInitialMonth } from './util'

interface WrapperProps {
  show: boolean
  top: string
  left: string
  vertical: boolean
  noBind: boolean
}
const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  z-index: 2;
  top: ${props => props.top};
  left: ${props => props.left};

  display: ${props =>
    !props.show ? 'none' : props.vertical ? 'flex' : 'grid'};
  grid-template-columns: ${props =>
    !props.vertical && (props.noBind ? 'auto 1fr 1fr' : 'auto 1fr auto 1fr')};
  flex-direction: ${props => props.vertical && 'column'};

  background-color: white;
  border: 1px solid ${props => props.theme.colors.gray6};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};
`
interface BinderProps {
  bound: boolean
}
const Binder = styled.div<BinderProps>`
  position: absolute;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;
  margin-top: 28px;

  border-radius: 4px;
  cursor: pointer;

  &:hover i {
    color: ${props => props.theme.colors.primaryHover};
  }
`
const Close = styled.div.attrs({
  role: 'button',
})`
  position: absolute;
  right: 1rem;
  bottom: 0.8rem;
`

interface DateRangePickerProps {
  className?: string
  value: DateRange

  show: boolean
  top?: string
  left?: string
  vertical?: boolean
  noBind?: boolean
  singleMonth?: boolean
  showClose?: boolean

  onClose: () => void
  onChange: (value: DateRange) => void
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  value,

  show,
  top = '0',
  left = '0',
  vertical = false,
  noBind = false,
  singleMonth = false,
  showClose = false,

  onClose,
  onChange,
}) => {
  if (value.from.getTime() - value.to.getTime() > 0)
    throw new Error('Date "from" must be before date "to"')

  const translations = useTranslate({
    linkMonths: 'common.link-months',
    unlinkMonths: 'common.unlink-months',
  })

  const wrapperRef = useRef<HTMLDivElement>(null)

  useClickOutside(wrapperRef, onClose, true)

  const [nextSide, setNextSide] = useState<Side>('left')
  const [leftMonth, setLeftMonth] = useState(
    getInitialMonth(value, singleMonth ? 'right' : 'left')
  )
  const [rightMonth, setRightMonth] = useState(getInitialMonth(value, 'right'))
  const [bound, setBound] = useState(!noBind)

  function handleQuickSelect(range: DateRange) {
    const left = getInitialMonth(range, 'left')
    const right = getInitialMonth(range, 'right')

    setLeftMonth(
      singleMonth && isSameMonth(range.from, range.to) ? right : left
    )
    setRightMonth(right)

    onChange({
      from: startOfDay(range.from),
      to: endOfDay(range.to),
    })
  }

  function handleUpdateMonth(side: Side) {
    return (month: Date) => {
      if (!bound) {
        side === 'left' ? setLeftMonth(month) : setRightMonth(month)
      } else {
        if (side === 'left') {
          setRightMonth(addMonths(rightMonth, compareAsc(month, leftMonth)))
          setLeftMonth(month)
        } else {
          setLeftMonth(addMonths(leftMonth, compareAsc(month, rightMonth)))
          setRightMonth(month)
        }
      }
    }
  }

  function handleDateClick(date: Date) {
    if (nextSide === 'right' && isBefore(date, value.from)) {
      onChange({
        from: startOfDay(date),
        to: endOfDay(date),
      })
      return
    }

    if (nextSide === 'left')
      onChange({
        from: startOfDay(date),
        to: endOfDay(date),
      })
    else {
      onChange({
        from: startOfDay(value.from),
        to: endOfDay(date),
      })
    }

    setNextSide(v => (v === 'left' ? 'right' : 'left'))
  }

  return (
    <Wrapper
      ref={wrapperRef}
      className={className}
      show={show}
      top={top}
      left={left}
      vertical={vertical}
      noBind={noBind}
    >
      <QuickSelect
        value={value}
        vertical={vertical}
        onChange={handleQuickSelect}
      />

      <Month
        value={value}
        side="left"
        vertical={vertical}
        singleMonth={singleMonth}
        month={leftMonth}
        otherMonth={rightMonth}
        onDateClick={handleDateClick}
        onUpdateMonth={handleUpdateMonth('left')}
      />

      {!noBind && (
        <div>
          <Binder
            bound={bound}
            title={bound ? translations.unlinkMonths : translations.linkMonths}
            onClick={() => setBound(v => !v)}
          >
            <Icon
              icon={bound ? 'link' : 'unlink'}
              color="gray3"
              translateY="1px"
            />
          </Binder>
        </div>
      )}

      {!singleMonth && (
        <Month
          value={value}
          side="right"
          vertical={vertical}
          singleMonth={singleMonth}
          month={rightMonth}
          otherMonth={leftMonth}
          onDateClick={handleDateClick}
          onUpdateMonth={handleUpdateMonth('right')}
        />
      )}

      {showClose && (
        <Close onClick={onClose}>
          <Icon
            icon="times"
            size="1.4rem"
            cursor="pointer"
            color="gray4"
            hoverColor="logoutRedHover"
          />
        </Close>
      )}
    </Wrapper>
  )
}

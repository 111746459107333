import React from 'react'

export const PrivacyPolicy: React.FC = React.memo(() => {
  return (
    <article>
      <h1>Privacy Policy</h1>

      <p>
        This Privacy policy for Digitech AS ("<b>we</b>", " <b>us</b>", "
        <b>our</b>"). We are committed to protecting your personal information
        and your right to privacy. If you have any questions or concerns about
        this privacy notice, or our practices with regards to your personal
        information, please contact us at&nbsp;
        <a href="mailto:post@digitech.no">post@digitech.no</a>.
      </p>

      <p>
        When you visit our website&nbsp;
        <a href="https://app.digitech.no/">app.digitech.no</a>&nbsp;(the "
        <b>Website</b>"), and more generally, use any of our services (the "
        <b>Services</b>", which include the Website), we appreciate that you are
        trusting us with your personal information. We take your privacy very
        seriously. In this privacy notice, we seek to explain to you in the
        clearest way possible what information we collect, how we use it and
        what rights you have in relation to it. We hope you take some time to
        read through it carefully, as it is important. If there are any terms in
        this privacy notice that you do not agree with, please discontinue use
        of our Services immediately.
      </p>

      <p>
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our Website), as well as,
        any related services.
      </p>

      <p>
        <b>
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </b>
      </p>

      <h2>1. What information do we collect?</h2>

      <h3>Personal information you disclose to us</h3>

      <p>
        <b>In Short:</b>&nbsp;
        <i>We collect personal information that you provide to us.</i>
      </p>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Website, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Website or otherwise when you contact us.
      </p>

      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Website, the choices you make and the
        products and features you use. The personal information we collect may
        include the following:
      </p>

      <ul>
        <li>
          <b>Personal Information Provided by You.</b> We may collect names;
          phone numbers; email addresses; mailing addresses; usernames;
          passwords; contact or authentication data; information you provide
          when using our products (e.g., business name, business email, business
          address, etc.); profile photo; and other similar information.
        </li>
      </ul>

      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h3>Information automatically collected</h3>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          Some information — such as your Internet Protocol (IP) address and/or
          browser and device characteristics — is collected automatically when
          you visit our Website.
        </i>
      </p>

      <p>
        We automatically collect certain information when you visit, use or
        navigate the Website. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Website and other technical information. This information is
        primarily needed to maintain the security and operation of our Website,
        and for our internal analytics and reporting purposes.
      </p>

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies. You can find out more about this in our Cookie
        Notice.
      </p>

      <p>The information we collect automatically includes:</p>

      <ul>
        <li>
          <i>Log and Usage Data.</i>&nbsp;Log and usage data is service-related,
          diagnostic, usage and performance information our servers
          automatically collect when you access or use our Website and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type and
          settings and information about your activity in the Website (such as
          the date/time stamps associated with your usage, pages and files
          viewed, searches and other actions you take such as which features you
          use), device event information (such as system activity, error reports
          (sometimes called 'crash dumps') and hardware settings).
        </li>

        <li>
          <i>Device Data.</i>&nbsp;We collect device data such as information
          about your computer, phone, tablet or other device you use to access
          the Website. Depending on the device used, this device data may
          include information such as your IP address (or proxy server), device
          and application identification numbers, location, browser type,
          hardware model, Internet service provider and/or mobile carrier,
          operating system and system configuration information.
        </li>
      </ul>

      <h2>2. How do we use your information?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </i>
      </p>

      <p>
        We use personal information collected via our Website for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>

      <p>We use the information we collect or receive:</p>

      <ul>
        <li>
          <b>To facilitate account creation and logon process.</b>
        </li>

        <li>
          <b>To manage user accounts.</b> We may use your information for the
          purposes of managing your account and keeping it in working order.
        </li>

        <li>
          <b>To request feedback.</b> We may use your information to request
          feedback and to contact you about your use of our Services.
        </li>

        <li>
          <b>To protect our Services.</b> We may use your information as part of
          our efforts to keep our Services safe and secure (for example, for
          fraud monitoring and prevention).
        </li>

        <li>
          <b>
            To enforce our terms, conditions and policies for business purposes,
            to comply with legal and regulatory requirements or in connection
            with our contract.
          </b>
        </li>

        <li>
          <b>To respond to legal requests and prevent harm.</b> If we receive a
          subpoena or other legal request, we may need to inspect the data we
          hold to determine how to respond.
        </li>

        <li>
          <b>To fulfill and manage your orders.</b> We may use your information
          to fulfill and manage your orders, payments, upgrades, or refunds made
          through the Website or otherwise through our Services.
        </li>

        <li>
          <b>To deliver and facilitate delivery of services to the user.</b> We
          may use your information to provide you with the requested service.
        </li>

        <li>
          <b>To respond to user inquiries/offer support to users.</b> We may use
          your information to respond to your inquiries and solve any potential
          issues you might have with the use of our Services.
        </li>

        <li>
          <b>To send administrative information to you</b>. We may use your
          personal information to send you product, service and new feature
          information and/or information about changes to our terms, conditions,
          and policies.
        </li>

        <li>
          <b>For other business purposes.</b> We may use your information for
          other business purposes, such as data analysis, identifying usage
          trends, determining the effectiveness of our promotional campaigns and
          to evaluate and improve our Website, Services, products, marketing and
          your experience. We may use and store this information in aggregated
          and anonymized form so that it is not associated with individual end
          users and does not include personal information. We will not use
          identifiable personal information without your consent.
        </li>
      </ul>

      <h2>3. Will your information be shared with anyone?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </i>
      </p>

      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>

      <ul>
        <li>
          <b>Consent:</b> We may process your data if you have given us specific
          consent to use your personal information for a specific purpose.
        </li>

        <li>
          <b>Legitimate Interests:</b> We may process your data when it is
          reasonably necessary to achieve our legitimate business interests.
        </li>

        <li>
          <b>Performance of a Contract:</b> Where we have entered into a
          contract with you, we may process your personal information to fulfill
          the terms of our contract.
        </li>

        <li>
          <b>Legal Obligations:</b> We may disclose your information where we
          are legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </li>

        <li>
          <b>Vital Interests:</b> We may disclose your information where we
          believe it is necessary to investigate, prevent, or take action
          regarding potential violations of our policies, suspected fraud,
          situations involving potential threats to the safety of any person and
          illegal activities, or as evidence in litigation in which we are
          involved.
        </li>
      </ul>

      <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
      </p>

      <ul>
        <li>
          <b>Business Transfers.</b> We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>

        <li>
          <b>Vendors, Consultants and Other Third-Party Service Providers.</b>
          We may share your data with third-party vendors, service providers,
          contractors or agents who perform services for us or on our behalf and
          require access to such information to do that work. Examples include:
          payment processing, data analysis, email delivery, hosting services,
          customer service and marketing efforts. We may allow selected third
          parties to use tracking technology on the Website, which will enable
          them to collect data on our behalf about how you interact with our
          Website over time. This information may be used to, among other
          things, analyze and track data, determine the popularity of certain
          content, pages or features, and better understand online activity.
          Unless described in this notice, we do not share, sell, rent or trade
          any of your information with third parties for their promotional
          purposes. We have contracts in place with our data processors, which
          are designed to help safeguard your personal information. This means
          that they cannot do anything with your personal information unless we
          have instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct.
        </li>

        <li>
          <b>Affiliates.</b> We may share your information with our affiliates,
          in which case we will require those affiliates to honor this privacy
          notice. Affiliates include our parent company and any subsidiaries,
          joint venture partners or other companies that we control or that are
          under common control with us.
        </li>

        <li>
          <b>Business Partners. </b>We may share your information with our
          business partners to offer you certain products, services or
          promotions.
        </li>
      </ul>

      <h2>4. Who will your information be shared with?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We only share information with the following categories of third
          parties.
        </i>
      </p>

      <p>
        We only share and disclose your information with the following
        categories of third parties. If we have processed your data based on
        your consent and you wish to revoke your consent, please contact us
        using the contact details provided in the section below titled "How can
        you contact us about this notice?".
      </p>

      <ul>
        <li>Cloud Computing Services</li>

        <li>Communication &amp; Collaboration Tools</li>

        <li>Data Analytics Services</li>

        <li>Data Storage Service Providers</li>

        <li>Finance &amp; Accounting Tools</li>

        <li>Performance Monitoring Tools</li>

        <li>Testing Tools</li>

        <li>User Account Registration &amp; Authentication Services</li>

        <li>Website Hosting Service Providers</li>
      </ul>

      <h2>5. Do we use cookies and other tracking technologies?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We may use cookies and other tracking technologies to collect and
          store your information.
        </i>
      </p>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>

      <h2>6. Is your information transferred internationally?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We may transfer, store, and process your information in countries
          other than your own.
        </i>
      </p>

      <h2>7. How long do we keep your information?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </i>
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements).
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <h2>8. How do we keep your information safe?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </i>
      </p>

      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our Website is at your own risk. You should only
        access the Website within a secure environment.
      </p>

      <h2>9. Do we collect information from minors?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          We do not knowingly collect data from or market to children under 18
          years of age.
        </i>
      </p>

      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Website, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Website. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        post@digitech.no.
      </p>

      <h2>10. What are your privacy rights?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          In some regions, such as the European Economic Area (EEA) and United
          Kingdom (UK), you have rights that allow you greater access to and
          control over your personal information. You may review, change, or
          terminate your account at any time.
        </i>
      </p>

      <p>
        In some regions (like the EEA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the contact details provided below. We will consider and act upon
        any request in accordance with applicable data protection laws.
      </p>

      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal, nor will it affect the processing of your
        personal information conducted in reliance on lawful processing grounds
        other than consent.
      </p>

      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details
        <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          here
        </a>
        .
      </p>

      <p>
        If you are located in Switzerland, the contact details for the data
        protection authorities are available
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>.
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at post@digitech.no
      </p>

      <h3>Account Information</h3>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <ul>
        <li>Contact us using the contact information provided.</li>

        <li>Log in to your account settings and update your user account.</li>
      </ul>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with applicable legal requirements.
      </p>

      <p>
        <b>
          <u>Cookies and similar technologies:</u>
        </b>
        &nbsp;Most Web browsers are set to accept cookies by default. If you
        prefer, you can usually choose to set your browser to remove cookies and
        to reject cookies. If you choose to remove cookies or reject cookies,
        this could affect certain features or services of our Website.
      </p>

      <h2>11. Controls for do-not-track features</h2>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <h2>12. Do we make updates to this notice?</h2>

      <p>
        <b>In Short:</b>&nbsp;
        <i>
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </i>
      </p>

      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <h2>13. How can you contact us about this notice?</h2>

      <p>
        If you have comments or questions about this privacy policy you can
        contact us on <a href="mailto:post@digitech.no">post@digitech.no</a>
      </p>

      <p>You can also contact us by posting to</p>

      <address>
        <p style={{ margin: '4px 0' }}>Tømmermannsvegen 7A,</p>
        <p style={{ margin: '4px 0' }}>7657, Verdal</p>
        <p style={{ margin: '4px 0' }}>Norway</p>
      </address>

      <h2>
        14. How can you review, update, or delete the data we collect from you?
      </h2>

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please do so by
        contacting us using the information listed above.
      </p>
    </article>
  )
})

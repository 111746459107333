import { useMutation, useQuery } from '@apollo/client'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Message,
  PageHeader,
  Table,
  TableColumn,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
  TableRow,
  UserThumbOrInitials,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import { USERS_SHALLOW_QUERY } from 'modules/users/queries'
import { UsersShallowQuery } from 'modules/users/types.graphql'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable, OrderByVariable } from 'types/graphql'
import {
  useConfirm,
  useDateFns,
  useOnErrorAuto,
  usePermission,
  usePermissionOr,
  useToast,
} from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { UserWrapper } from '../ChecklistsTable'
import { DELETE_CHECKLIST_TEMPLATE_MUTATION } from '../mutations'
import { CHECKLIST_TEMPLATES_SHALLOW_QUERY } from '../queries'
import {
  ChecklistTemplatesShallowQuery,
  ChecklistTemplatesShallowQueryVariables,
  ShallowChecklistTemplate,
} from '../types.graphql'
import { useChecklistTemplatePagination } from '../util'
import { ChecklistTemplatesMobile } from './ChecklistTemplatesMobile'

const Wrapper = styled.div`
  a {
    font-weight: 600;
  }
`

const PAGE_SIZE = 25

interface ChecklistTemplatesTabProps {}

export const ChecklistTemplatesTab: React.FC<
  ChecklistTemplatesTabProps
> = () => {
  const translations = useTranslate({
    checklistTemplates: 'checklists.checklist-templates',
    createChecklistTemplate: 'checklists.add-checklist-template',
    delete: 'common.delete',
    edit: 'common.edit',
    search: 'checklists.search-for-templates',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    columns: {
      title: 'common.title',
      description: 'common.description',
      user: 'common.user',
      date: 'common.date',
    },

    prompt: {
      deleteTemplate: 'checklists.deleting-template-prompt',
      deleteTemplateTitle: 'checklists.delete-checklist-template',
    },

    results: {
      deleteSuccess: 'checklists.template-deleted',
      queryError: 'server.general-error-try-again-later',
      noPermission: 'permissions.no-access-to-page',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const { format } = useDateFns()
  const confirm = useConfirm()
  const addToast = useToast()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('title')
  const [search, setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState<string | null>(null)

  const debouncedSearch = useDebounce(search)

  const canViewPage = usePermissionOr(
    PERMISSIONS.checklists.add.checklisttemplate,
    PERMISSIONS.checklists.change.checklisttemplate,
    PERMISSIONS.checklists.delete.checklisttemplate
  )
  const canEditTemplate = usePermission(
    PERMISSIONS.checklists.change.checklisttemplate
  )
  const canDeleteTemplate = usePermission(
    PERMISSIONS.checklists.delete.checklisttemplate
  )

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore: fetchMoreTemplates,
  } = useQuery<
    ChecklistTemplatesShallowQuery,
    ChecklistTemplatesShallowQueryVariables
  >(CHECKLIST_TEMPLATES_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      orderBy: sort,
      user: selectedUser,
    },
    onCompleted() {
      setHasLoadedOnce(true)
    },
    onError: onErrorAuto(),
  })

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery<UsersShallowQuery, OrderByVariable>(USERS_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { orderBy: 'name' },
    onError: onErrorAuto(),
  })

  const checklistTemplates = useMemo(
    () => data?.allChecklistTemplates.edges.map(({ node }) => node) ?? [],
    [data]
  )

  const users = useMemo(
    () => usersData?.allUsers.edges.map(({ node }) => node) ?? [],
    [usersData]
  )

  const userFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      exclusive: true,
      items: users.map(user => ({
        id: user.id,
        text: user.fullName ?? '',
        initUnchecked: true,
      })),
    }),
    [users]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useChecklistTemplatePagination(
    data?.allChecklistTemplates.pageInfo,
    fetchMoreTemplates
  )

  useInfiniteScroll(handleFetchMore)

  const [deleteChecklistTemplate, { loading: deleteLoading }] = useMutation<
    never,
    IdVariable
  >(DELETE_CHECKLIST_TEMPLATE_MUTATION, {
    refetchQueries: ['ChecklistTemplatesShallow'],
    awaitRefetchQueries: true,
    onCompleted() {
      addToast('success', translations.results.deleteSuccess)
    },
    onError: onErrorAuto(),
  })

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'title',
        label: translations.columns.title,
        sortable: true,
      },
      {
        id: 'description',
        label: translations.columns.description,
        sortable: true,
      },
      {
        id: 'user',
        label: translations.columns.user,
        sortable: true,
        filtering: userFiltering,
      },
      {
        id: 'createdAt',
        label: translations.columns.date,
        sortable: true,
        shrink: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [
      translations.columns.title,
      translations.columns.description,
      translations.columns.user,
      translations.columns.date,
      userFiltering,
    ]
  )

  const handleDeleteTemplate = useCallback(
    async (templateId: string) => {
      if (!canDeleteTemplate) return

      const answer = await confirm(
        translations.prompt.deleteTemplate,
        translations.prompt.deleteTemplateTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteChecklistTemplate({
          variables: {
            id: templateId,
          },
        })
      }
    },
    [
      canDeleteTemplate,
      confirm,
      deleteChecklistTemplate,
      translations.prompt.deleteTemplate,
      translations.prompt.deleteTemplateTitle,
    ]
  )

  const makeEditDeleteMenu = useCallback(
    (template: ShallowChecklistTemplate) => {
      const items: TableMenu = {
        items: [
          {
            icon: 'edit',
            text: translations.edit,
            hide: !canEditTemplate,
            onClick: () => history.push(`/checklists/templates/${template.id}`),
          },
          {
            icon: 'trash',
            text: translations.delete,
            color: '#f39b9b',
            hide: !canDeleteTemplate,
            hoverColor: '#e38080',
            onClick: () => handleDeleteTemplate(template.id),
          },
        ],
      }

      return items
    },
    [
      canDeleteTemplate,
      canEditTemplate,
      handleDeleteTemplate,
      history,
      translations.delete,
      translations.edit,
    ]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      checklistTemplates.map(template => {
        const date = format(new Date(template.createdAt), 'PPP')

        return {
          title: {
            content: (
              <Link to={`/checklists/templates/${template.id}`}>
                {template.title}
              </Link>
            ),
          },
          description: { content: template.description ?? '' },
          user: {
            content: !!template.createdBy ? (
              <UserWrapper
                role="link"
                onClick={() => history.push(`/users/${template.createdBy.id}`)}
              >
                <UserThumbOrInitials
                  user={template.createdBy}
                  cursor="pointer"
                />
                {template.createdBy.fullName}
              </UserWrapper>
            ) : (
              '-'
            ),
          },
          createdAt: {
            content: date,
          },
          menu: { content: '', menu: makeEditDeleteMenu(template) },
        }
      }),
    [checklistTemplates, format, history, makeEditDeleteMenu]
  )

  function handleFilterChange(value: TableFiltering) {
    const checked = value.checked[0]
    setSelectedUser(checked ?? null)
  }

  const loading = queryLoading || deleteLoading || usersLoading

  if (error || usersError)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (!canViewPage)
    return (
      <Message.Error show centered text={translations.results.noPermission} />
    )

  return (
    <EmptyPage
      module="checklistTemplates"
      empty={!checklistTemplates.length && !debouncedSearch && !loading}
      loading={!hasLoadedOnce}
      isTabView
      buttonPermissions={PERMISSIONS.checklists.add.checklisttemplate}
      onButtonClick={() => history.push('/checklists/templates/create')}
    >
      <Wrapper>
        {isMobileOnly ? (
          <ChecklistTemplatesMobile
            templates={checklistTemplates}
            search={search}
            userFilter={userFiltering}
            makeMenu={makeEditDeleteMenu}
            onCreateNewChecklistTemplate={() =>
              history.push('/checklists/templates/create')
            }
            onSearchChange={setSearch}
            onFilterChange={handleFilterChange}
          />
        ) : (
          <>
            <PageHeader
              loading={loading}
              tabMargin
              search={{
                value: search,
                placeholder: translations.search,
                left: true,
                onChange: setSearch,
              }}
              buttons={[
                {
                  text: translations.createChecklistTemplate,
                  icon: 'plus',
                  permissions: PERMISSIONS.checklists.add.checklisttemplate,
                  onClick: () => history.push('/checklists/templates/create'),
                },
              ]}
            />

            <Table
              columns={columns}
              rows={rows}
              noRowsText={
                !!debouncedSearch ? translations.noResults : translations.noData
              }
              options={{ style: { boxShadow: 'none' } }}
              initialSort={sort}
              onFilterChange={handleFilterChange}
              onSortingChange={setSort}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 3rem;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  background-color: white;
`

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

export const Card: React.FC<CardProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Wrapper className={className} {...props}>
      {children}
    </Wrapper>
  )
}

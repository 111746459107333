import { useMutation } from '@apollo/client'
import { useForm, useTranslate } from '@ur/react-hooks'
import { CenteredLoader } from 'components'
import React, { useEffect, useState } from 'react'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import styled from 'styled-components'
import { changeCompany, setLoginToken } from 'util/auth'
import { useOnErrorAuto, useToast } from 'util/hooks'
import {
  validateEmail,
  validateNonEmpty,
  validatePassword,
} from 'util/validation'
import { RegisterNewUser } from '.'
import { CREATE_NEW_COMPANY_MUTATION } from './mutations'
import { RegisterNewCompany } from './RegisterNewCompany'
import {
  CreateNewCompanyMutation,
  CreateNewCompanyMutationVariables,
  CreateNewCompanyVariables,
  CreateNewUserVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const RECAPTCHA_ACTION = 'digitech'

const Wrapper = styled.div`
  padding-bottom: 6rem;
`

export type RegisterCompanyForm = CreateNewCompanyVariables
export type RegisterUserForm = CreateNewUserVariables & {
  newPasswordAgain: string
}

const Register: React.FC = () => {
  const translations = useTranslate({
    createError: 'companies.errors.could-not-create-company',
    uniqueShortNameConstraint: 'companies.errors.short-name-is-already-in-use',
    uniqueEmailConstraint: 'companies.errors.email-address-already-in-use',

    validation: {
      required: 'common.required',
      invalidEmail: 'errors.invalid-email-address',
      invalidPhoneNumber: 'errors.invalid-phone-number',

      passwordsUnequal: 'users.passwords-do-not-match',
      weakPassword: 'users.errors.change-password-weak',
    },
  })

  const { executeRecaptcha } = useGoogleReCaptcha()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()

  const [progress, setProgress] = useState<1 | 2>(1)
  const [reCaptchaLoading, setReCaptchaLoading] = useState(false)

  const companyForm = useForm<RegisterCompanyForm>({
    values: {
      fullName: '',
      shortName: '',
      billingEmail: '',
      organizationNumber: '',
    },
    validators: {
      fullName: validateNonEmpty(translations.validation.required),
      shortName: validateNonEmpty(translations.validation.required),
      billingEmail: [
        validateNonEmpty(translations.validation.required),
        validateEmail(translations.validation.invalidEmail),
      ],
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['billingEmail'],
    },
  })

  const userForm = useForm<RegisterUserForm>({
    values: {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      newPasswordAgain: '',
    },
    validators: {
      firstName: validateNonEmpty(translations.validation.required),
      lastName: validateNonEmpty(translations.validation.required),
      username: [
        validateNonEmpty(translations.validation.required),
        validateEmail(translations.validation.invalidEmail),
      ],
      password: validatePassword(translations.validation.weakPassword),
      newPasswordAgain: validatePassword(
        translations.validation.weakPassword,
        'password',
        translations.validation.passwordsUnequal
      ),
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['username'],
    },
  })

  const [createNewCompanyMutation, { data: mutationData, loading }] =
    useMutation<CreateNewCompanyMutation, CreateNewCompanyMutationVariables>(
      CREATE_NEW_COMPANY_MUTATION,
      {
        onCompleted(data) {
          if (!data) throw new Error('Data not defined')

          const { ok, token, company } = data.createNewCompany

          if (!ok || !token) {
            mixpanel.track('Register Company Page Failed To Create Company')
            addToast('error', translations.createError)
            return
          }

          mixpanel.track('Register Company Page Success')

          setLoginToken(token, false)
          changeCompany(company.shortName)
        },
        onError: onErrorAuto({
          uniqueConstraint: message => {
            if (message.includes('companies_company.short_name')) {
              mixpanel.track('Company Short Name Validation Failed')
              setProgress(2)
              return translations.uniqueShortNameConstraint
            } else if (
              message.includes('users_user.email' || 'users_user_username_key')
            ) {
              mixpanel.track('User Email Validation Failed')
              setProgress(1)
              return translations.uniqueEmailConstraint
            }
            return message
          },
        }),
      }
    )

  async function handleRecaptcha() {
    if (!executeRecaptcha) return null

    return await executeRecaptcha(RECAPTCHA_ACTION)
  }

  async function handleSubmit() {
    if (!userForm.validateForm() || !companyForm.validateForm()) return

    setReCaptchaLoading(true)
    const reCaptcha = await handleRecaptcha().finally(() =>
      setReCaptchaLoading(false)
    )
    if (!reCaptcha) {
      mixpanel.track('Register Company Page Failed To Validate ReCaptcha')
      return
    }

    void createNewCompanyMutation({
      variables: {
        reCaptcha,
        ...userForm.formValues,
        ...companyForm.formValues,
      },
    })
  }

  const isLoading =
    mutationData?.createNewCompany.ok || loading || reCaptchaLoading

  useEffect(() => {
    mixpanel.track('Register Company Page Loaded')
  }, [])

  return (
    <Wrapper>
      {isLoading ? (
        <CenteredLoader page />
      ) : progress === 1 ? (
        <RegisterNewUser
          form={userForm.formValues}
          errors={userForm.formErrors}
          formValid={userForm.formValid}
          formValuesEdited={userForm.formValuesEdited}
          onEnableValidation={userForm.enableValidation}
          onUpdateForm={userForm.formChangeHandler}
          onNext={() => {
            mixpanel.track('Register Company Page Next Button Clicked')
            setProgress(2)
          }}
        />
      ) : (
        <RegisterNewCompany
          form={companyForm.formValues}
          errors={companyForm.formErrors}
          formValid={companyForm.formValid}
          formValuesEdited={companyForm.formValuesEdited}
          onEnableValidation={companyForm.enableValidation}
          onHandleFormChange={companyForm.formChangeHandler}
          onUpdateForm={companyForm.updateForm}
          onBack={() => {
            mixpanel.track('Register Company Page Back Button Clicked')
            setProgress(1)
          }}
          onSubmit={() => {
            mixpanel.track('Register Company Page Submit Button Clicked')
            void handleSubmit()
          }}
        />
      )}
    </Wrapper>
  )
}

export const RegisterCompany: React.FC = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfIBr0dAAAAAPi2cga0CZczMkwIpOieivBdTQn3"
      useEnterprise
    >
      <Register />
    </GoogleReCaptchaProvider>
  )
}

import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button as BaseButton,
  FormField as BaseFormField,
  Input,
  TextArea,
} from 'components'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { validateNonEmpty, validatePositiveNumber } from 'util/validation'
import {
  CreateProductMutationVariables,
  PatchProductMutationVariables,
  Product,
} from '../types.graphql'
import { UnitSelect } from './UnitSelect'

const Card = styled.div`
  width: 450px;

  ${props => props.theme.media.desktop} {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: white;

    padding: 1rem 2.5rem 2rem;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

const FormField = styled(BaseFormField)`
  margin-top: 1rem;
`
const Button = styled(BaseButton)`
  margin-top: 1rem;
`

interface ProductData {
  name: string
  sku: string
  unit: string
  grossPriceExcludingVat: string
  priceExcludingVat: string
  merchant: string
}

interface ProductFormProps {
  product?: Product
  loading: boolean

  onSubmit: (
    form: CreateProductMutationVariables | PatchProductMutationVariables
  ) => void
}

export const ProductForm: React.FC<ProductFormProps> = ({
  product,
  loading,

  onSubmit,
}) => {
  const translations = useTranslate({
    createProduct: 'products.create-product',
    editProduct: 'products.edit-product',

    name: 'products.name-of-product',
    sku: 'products.article-number',
    unit: 'products.unit',
    grossPriceExcludingVat: 'products.price',
    priceExcludingVat: 'products.cost-price',
    merchant: 'products.name-of-merchant',

    validation: {
      invalidNumber: 'errors.invalid-number-must-be-positive',
      required: 'common.required',
    },
  })

  const productInserted = useRef(false)

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler,
  } = useForm<ProductData>({
    values: {
      name: '',
      sku: '',
      unit: 'STK',
      grossPriceExcludingVat: '',
      priceExcludingVat: '',
      merchant: '',
    },
    validators: {
      name: validateNonEmpty(translations.validation.required),
      sku: val => validateNonEmpty(translations.validation.required)(val),
      grossPriceExcludingVat: val =>
        !val
          ? validateNonEmpty(translations.validation.required)(val)
          : validatePositiveNumber(translations.validation.invalidNumber)(
              parseFloat(val)
            ),
      priceExcludingVat: val =>
        !val
          ? validateNonEmpty(translations.validation.required)(val)
          : validatePositiveNumber(translations.validation.invalidNumber)(
              parseFloat(val)
            ),
      unit: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  useEffect(() => {
    if (!product || productInserted.current) return
    productInserted.current = true

    const values: typeof form = {
      name: product.name,
      sku: product.sku ?? '',
      unit: product.unit ?? 'stk',
      grossPriceExcludingVat: product.grossPriceExcludingVat,
      priceExcludingVat: product.priceExcludingVat,
      merchant: product.merchant ?? '',
    }

    updateForm(values)
    updateInitialValues(values)
  }, [product, updateForm, updateInitialValues])

  function handleSubmit(input: typeof form) {
    onSubmit({ input })
  }

  return (
    <Card>
      <FormField error={!!errors.name} required>
        <label>{translations.name}</label>

        <TextArea
          value={form.name}
          error={errors.name}
          disabled={loading}
          autoFocus
          fullWidth
          onChange={handler('name')}
        />
      </FormField>

      <FormField error={!!errors.merchant}>
        <label>{translations.merchant}</label>

        <Input
          name="merchant"
          value={form.merchant}
          error={errors.merchant}
          disabled={loading}
          fullWidth
          onChange={handler('merchant')}
        />
      </FormField>

      <FormField error={!!errors.sku} required>
        <label>{translations.sku}</label>

        <Input
          name="sku"
          value={form.sku}
          error={errors.sku}
          disabled={loading}
          fullWidth
          onChange={handler('sku')}
        />
      </FormField>

      <FormField required error={!!errors.priceExcludingVat}>
        <label>{translations.priceExcludingVat}</label>

        <Input
          name="priceExcludingVat"
          type="number"
          value={form.priceExcludingVat}
          error={errors.priceExcludingVat}
          disabled={loading}
          fullWidth
          onChange={handler('priceExcludingVat')}
        />
      </FormField>

      <FormField required error={!!errors.grossPriceExcludingVat}>
        <label>{translations.grossPriceExcludingVat}</label>

        <Input
          name="grossPriceExcludingVat"
          type="number"
          value={form.grossPriceExcludingVat}
          error={errors.grossPriceExcludingVat}
          disabled={loading}
          fullWidth
          onChange={handler('grossPriceExcludingVat')}
        />
      </FormField>

      <FormField error={!!errors.unit} required>
        <label>{translations.unit}</label>

        <UnitSelect
          value={form.unit}
          error={errors.unit}
          disabled={loading}
          fullWidth
          onChange={unit => !!unit && handler('unit')(unit)}
        />
      </FormField>

      <Button
        fullWidth
        disabled={!formValid || !formEdited || loading}
        onClick={submitHandler(handleSubmit)}
      >
        {product ? translations.editProduct : translations.createProduct}
      </Button>
    </Card>
  )
}

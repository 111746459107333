import React from 'react'
import styled from 'styled-components'
import {
  Breadcrumbs as BaseBreadcrumbs,
  BreadcrumbsProps as BaseBreadcrumbsProps,
} from '@ur/react-components'

const StyledBreadcrumbs = styled(BaseBreadcrumbs)`
  color: ${props => props.theme.colors.gray4};
`

interface BreadcrumbsProps extends BaseBreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ ...props }) => {
  return <StyledBreadcrumbs iconProps={{ size: '12px' }} {...props} />
}

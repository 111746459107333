import { Crumb, Icon } from '@ur/react-components'
import { useClickOutside, useGlobal, useTranslate } from '@ur/react-hooks'
import { RouteBreadcrumbs, UserThumbOrInitials } from 'components'
import capitalize from 'lodash/capitalize'
import React, { useMemo, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useIntl } from 'react-intl'
import { Link, NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useUser } from 'util/hooks'
import { overloadColor } from 'util/style'
import { Logo, UserOptions } from '.'

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  display: grid;
  width: 100%;

  grid-template-areas: 'logo breadcrumbs profile';
  grid-template-columns: 246px 1fr auto;

  height: ${props => props.theme.sizes.headerHeight};
  align-items: center;

  background: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
`
const LogoWrapper = styled(Link)`
  grid-area: logo;
  display: flex;
  align-items: center;
  padding: 0 24px;
`
const BreadcrumbWrapper = styled.div`
  grid-area: breadcrumbs;
`
const UserMenu = styled.div`
  grid-area: profile;
  position: relative;
`
const ProfileIcon = styled(UserThumbOrInitials)`
  margin-right: 32px;
`
const MobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: ${props => props.theme.sizes.headerHeightMobile};
  padding: 0 16px;

  background: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
`
const Title = styled.div`
  font-weight: 600;
  font-size: 20px;

  i {
    margin-left: 0.5rem;
  }
`
const MobileHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  font-weight: 600;
  height: ${props => props.theme.sizes.headerHeightMobile};
  color: ${props => props.theme.colors.gray2};
  background: white;
`
const TabsWrapper = styled(MobileWrapper)`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0;

  div.hamburger {
    padding: 0 1rem;
    border-left: 1px solid #fafafa;
  }
`
const Tabs = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: ${props => props.theme.sizes.headerHeightMobile};

  a {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    color: ${props => props.theme.colors.darkGrayText};
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;

    &.active {
      background-color: white;
      color: ${props => props.theme.colors.primary};
    }
    &:not(.active) {
      background-color: #fafafa;
    }
  }
`

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const userMenuRef = useRef<HTMLDivElement>(null)

  const [sidebarOpen, setSidebarOpen] = useGlobal('sidebar.open')
  const [userMenuOpen, setUserMenuOpen] = useGlobal('sidebar.userMenuOpen')
  const [overrides] = useGlobal('breadcrumbs.overrides')
  const [inserts] = useGlobal('breadcrumbs.inserts')
  const [icon] = useGlobal('header.icon')
  const [tabs] = useGlobal('header.tabs')

  const location = useLocation()
  const me = useUser()
  const intl = useIntl()
  const translations = useTranslate({
    home: 'common.home',
    userSettings: 'common.user-settings',

    internalDeviations: 'deviations.internal-deviations',
    projectDeviations: 'deviations.project-deviations',
  })

  function overrideCrumb(
    path: string,
    index: number,
    total: number
  ): Partial<Crumb> | null {
    const text = overrides.hasOwnProperty(path)
      ? overrides[path]
      : intl.formatMessage({
          id: `common.${path}`,
          defaultMessage: capitalize(path),
        })

    if (text === null) return null

    return {
      text,
      color: index === total - 1 ? overloadColor('primary') : undefined,
    }
  }

  const title = useMemo(() => {
    const pathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname
    const last = pathname.split('/').pop()

    if (!last) return
    if (overrides.hasOwnProperty(last)) return overrides[last]

    return intl.formatMessage({
      id: `common.${last}`,
      defaultMessage: capitalize(last),
    })
  }, [intl, location.pathname, overrides])

  useClickOutside(userMenuRef, () => setUserMenuOpen(false))

  if (isMobileOnly) {
    if (sidebarOpen) {
      if (userMenuOpen) {
        // Mobile user menu
        return (
          <MobileHeaderWrapper>
            <Icon
              icon="long-arrow-alt-left"
              size="24px"
              color="gray4"
              onClick={() => setUserMenuOpen(false)}
            />

            {translations.userSettings}

            <Icon
              icon="times"
              size="24px"
              color="gray4"
              type="light"
              onClick={() => {
                setUserMenuOpen(false)
                setSidebarOpen(false)
              }}
            />
          </MobileHeaderWrapper>
        )
      }

      // Mobile sidebar
      return (
        <MobileHeaderWrapper>
          <Logo logoHeight="32px" />

          <Icon
            icon="times"
            size="24px"
            color="gray4"
            type="light"
            onClick={() => setSidebarOpen(v => !v)}
          />
        </MobileHeaderWrapper>
      )
    }

    if (!!tabs.length) {
      // Mobile header tabs
      return (
        <TabsWrapper>
          <Tabs>
            {tabs.map(tab => (
              <NavLink key={tab.href} exact to={tab.href}>
                {tab.text}
              </NavLink>
            ))}
          </Tabs>

          <div className="hamburger">
            <Icon
              icon="bars"
              size="25px"
              color="gray4"
              onClick={() => setSidebarOpen(v => !v)}
            />
          </div>
        </TabsWrapper>
      )
    }

    // Mobile header
    return (
      <MobileWrapper>
        <Title>
          {title}
          {icon && <Icon type="solid" color="gray3" {...icon} />}
        </Title>

        <Icon
          icon="bars"
          size="25px"
          color="gray4"
          onClick={() => setSidebarOpen(v => !v)}
        />
      </MobileWrapper>
    )
  }

  // Desktop header
  return (
    <Wrapper>
      <LogoWrapper to="/">
        <Logo logoHeight="30px" />
      </LogoWrapper>

      <BreadcrumbWrapper>
        <RouteBreadcrumbs
          inserts={inserts}
          overrideCrumb={overrideCrumb}
          mainPageTitle={translations.home}
          iconProps={{ color: 'gray4', size: '9px' }}
        />
      </BreadcrumbWrapper>

      <UserMenu ref={userMenuRef}>
        <ProfileIcon
          user={me}
          cursor="pointer"
          onClick={() => setUserMenuOpen(v => !v)}
        />

        {userMenuOpen && <UserOptions onClose={() => setUserMenuOpen(false)} />}
      </UserMenu>
    </Wrapper>
  )
}

import React from 'react'

export const TermsAndConditions: React.FC = React.memo(() => {
  return (
    <article>
      <h1>Terms of Use</h1>

      <h2>1. Introduction</h2>

      <p>
        These terms and conditions regulate Digitech AS (“The Company”)
        provision of the service “Digitech” (“The Service”) and Company AS
        liabilities and responsibilities rendered under your use of The Service.
        Use of The Service includes but is not limited to: All direct
        interaction with The Service through provided user interfaces or APIs.
        By registering an account on and using The Service, you agree to these
        Terms of Use and our Privacy Policy. You agree that such consent
        constitutes a legal binding between yourself, your represented
        party/parties and The Company.
      </p>

      <h2>2. The Service</h2>

      <p>
        The Service primarily consists of a web application. The target audience
        is primarily professionals within the HVAC-industry as well as other
        similar industries. The primary purpose of the web application is to
        allow companies and their employees to perform tasks related to:
      </p>

      <ul>
        <li>Project management.</li>

        <li>Project documentation.</li>

        <li>Project issue reporting and handling.</li>

        <li>Time tracking.</li>

        <li>Customer proposal creation and calculations.</li>

        <li>Company handbook creation.</li>

        <li>And other relevant tasks.</li>
      </ul>

      <h2>3. Restrictions of use</h2>

      <p>
        The Service may only be used for the specific use-cases outlined by us
        and which is natural for a product such as The Service. The Service may
        not be used commercially for any activity not specifically approved by
        us.
      </p>

      <p>
        The Service shall not be used in any ill-intended or otherwise unlawful
        way. This includes but is not limited to:
      </p>

      <ul>
        <li>
          Mistreat, harass, mislead, defraud, disparage, tarnish, impersonate or
          otherwise cause harm to us or other users of The Service.
        </li>

        <li>
          Impersonate us or our intellectual property in any manner on The
          Service or in any other digital or printed media.
        </li>

        <li>
          Copy, download or otherwise illicitly attain source code, media,
          graphics, or other parts of The Service or other parts of our
          intellectual property.
        </li>

        <li>
          Attempting to circumvent, bypass or evade any of the security measures
          of The Service.
        </li>

        <li>
          In any other way use the system in a manner which harms us or any user
          of The Service.
        </li>
      </ul>

      <h2>4. Ownership</h2>

      <p>
        The Company owns all intellectual rights to The Service, content of or
        related to The Service, including logo, graphics, textual content, and
        any other comprising part of The Service, with the sole exception of
        “Uploaded data”.
      </p>

      <p>
        All data uploaded and entered by you (“Uploaded data”) as a user of The
        Service remains in your ownership. This includes all dynamic content
        entered or uploaded, related to you or your account.
      </p>

      <p>
        You are not allowed to enter, upload, or share other user's or legal
        entities' content without their explicit permission.
      </p>

      <h2>5. Licensing and payment terms</h2>

      <p>
        The Service is offered under a recurring subscription model. The pricing
        of The Service can be found at our website:&nbsp;
        <a href="https://digitech.no/pricing">https://digitech.no/pricing</a>.
      </p>

      <p>
        You agree to be subject to the licensing fees outlined there. Billing
        will be initiated either via our third-party payment providers or by the
        sending of an invoice. All invoices will be sent up front for the total
        license fee. Third-party payment providers may collect your license fees
        directly from your entered payment method.
      </p>

      <p>
        All pricing terms are subject to change at our discretion, of which you
        will be notified per e-mail or via other contact information we may
        have. You are responsible for keeping your contact information recorded
        in your user or company account up to date.
      </p>

      <p>
        On using The System and agreeing to these terms you agree to all charges
        sent to you, or your legal entity as derived from your usage of The
        Service in conjunction with the pricing terms. While the terms are in
        effect you are henceforth described as “Subscribing” to the service
        under “Your Subscription”.
      </p>

      <h2>6. Termination</h2>

      <p>
        These Terms of Use shall remain in effect while you or any member of
        your represented legal entity use The Service.
      </p>

      <p>
        You are entitled to cancel Your Subscription of The Service at any point
        in time. Under a cancellation you may continue to use The Service for
        the entire duration you have paid for.
      </p>

      <p>
        WITHOUT ANY OTHER LIMITATIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE
        DISCRETION, AND WITHOUT NOTICE OR LIABILITY DENY YOU OR YOUR LEGAL
        ENTITY ACCESS TO THE SERVICE, FOR ANY REASON.
      </p>

      <p>
        If we terminate your account for any reason, you are prohibited to
        create a new account in any circumstance. We also reserve the right to
        take legal (civil or criminal) action against you or your legal entity
        in the case of serious breach of these Terms.
      </p>

      <h2>7. Limitations of liability and indemnity</h2>

      <p>
        The Company shall in no case be liable for direct or indirect damage of
        any sort, caused to you or your legal entity, including but not limited
        to data loss, lost profits, lost revenue or any other damage arising
        from your use of The Service.
      </p>

      <p>
        Company AS shall not be liable for any direct or indirect damage of any
        sort caused to your legal entity as the result of inability to access,
        reduced capacity of, any other temporary deficiency of or any other
        reliance you or your legal entity may have on The Service.
      </p>

      <p>
        You agree to indemnify The Company and hold us harmless, including our
        subsidiaries, affiliates, representatives, employees and agents from any
        claims, losses, liabilities, fees or other expenses (including attorney
        fees), arising out of or in connection with The Service, including your
        or other user's use of The Service, including any other user's violation
        of any third party rights.
      </p>

      <h2>8. Disclaimer</h2>

      <p>
        THE SERVICE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE SERVICE WILL BE AT YOUR OWN RISK. WE ASSUME NO
        RESPONSIBILITY FOR YOUR USE OF THE SERVICE OR FOR THE COMPLETENESS OF
        THE SERVICE, HERUNDER ANY WRITTEN OR DIGITAL MATERIAL PRESENTED ON THE
        SERVICE.
      </p>

      <p>
        WE ARE IN NO WAY RESPONSIBLE AND CAN IN NO MANNER BE HELD LIABLE FOR ANY
        ERROR, INACCURACY, MISTAKE, PERSONAL OR PROPRIETAL INJURY OR ANY OTHER
        DAMAGE WHATSOEVER RELATED TO YOUR USE OF THE SYSTEM.
      </p>

      <p>
        WE DO NOT WARRANT OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT, SERVICE
        OR OTHER MATERIAL OFFERED OR ADVERTISED BY ANY OTHER THIRD PARTY THROUGH
        THE SERVICE.
      </p>

      <p>
        ANY OTHER WARRANTY INCLUDING WARRANT OF MERCHANTABILITY ARE EXCLUDED AND
        DISCLAIMED.
      </p>

      <h2>9. Updates to these Terms</h2>

      <p>
        The Company reserves the right to update or modify these Terms at any
        time without notice. Your continued use of The Service represents your
        agreement to any update or revision. Any significant update to the Terms
        will result in a notification being sent to your e-mail or any other
        contact information we may have. You are responsible for keeping your
        contact information recorded in your user or company account up to date.
      </p>

      <h2>10. Third party consent</h2>

      <p>
        By using The Service you also consent to being bound by Google's terms
        of service.
      </p>

      <h2>11. Governing law and jurisdiction</h2>

      <p>
        These Terms are governed by the laws of Norway. Disputes should first be
        sent to <a href="mailto:post@digitech.no">post@digitech.no</a>. Any such
        dispute should be sought settled amicably. If the parties are unable to
        settle the dispute it shall be subject to ordinary court proceedings.
        The legal venue shall be Oslo District court.
      </p>
    </article>
  )
})

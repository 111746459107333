import { useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import {
  FormErrors,
  FormOnChangeFn,
  FormValuesEdited,
  UpdateFormFn,
  useDebounce,
  useTranslate,
} from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import {
  Button as BaseButton,
  Card,
  CheckBox,
  FormField as BaseFormField,
  Input,
} from 'components'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { stringToSlug } from 'util/formatting'
import { RegisterCompanyForm } from '.'
import { SHORT_NAME_TAKEN_QUERY } from './queries'
import { TermsAndConditionsModal } from './terms/TermsAndConditionsModal'
import {
  ShortNameTakenQuery,
  ShortNameTakenQueryVariables,
} from './types.graphql'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  ${props => props.theme.media.desktop} {
    margin: 2rem 0;
  }

  ${props => props.theme.media.mobile} {
    height: auto;
  }
`
const Layout = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo      logo'
    'header    header'
    'fullName  fullName'
    'shortName shortName'
    'orgNo     orgNo'
    'email     email'
    'confirm   confirm'
    'back      submit';
  gap: 1.5rem;

  width: clamp(600px, 25%, 800px);

  background-color: ${props => props.theme.colors.white};

  ${props => props.theme.media.mobile} {
    gap: 0.5rem;

    width: 100%;
    padding: 1rem;

    box-shadow: none;
    background-color: ${props => props.theme.colors.body};
  }
`
const FormField = styled(BaseFormField)`
  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
  }
`
const AgreeField = styled.div`
  grid-area: confirm;

  display: flex;
  align-items: flex-start;

  span.terms {
    margin-left: 1ch;
    line-height: 125%;

    span {
      cursor: pointer;
      color: ${props => props.theme.colors.primary};

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }

  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
  }
`
const Logo = styled.img<{ area: string }>`
  grid-area: ${props => props.area};
  width: 55%;
  margin-top: 1rem;

  ${props => props.theme.media.mobile} {
    width: 45%;
  }
`
const Header = styled.h1<{ area: string }>`
  min-width: 100%;
  padding-top: 1rem;

  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;
  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 1rem;
  }
`
interface ButtonProps {
  area: string
}
const Button = styled(BaseButton)<ButtonProps>`
  grid-area: ${props => props.area};
  margin-top: 1rem;
`

interface RegisterNewCompanyProps {
  form: RegisterCompanyForm
  errors: FormErrors<RegisterCompanyForm>
  formValid: boolean
  formValuesEdited: FormValuesEdited<RegisterCompanyForm>

  onEnableValidation: (
    key: keyof RegisterCompanyForm,
    run?: boolean,
    enable?: boolean
  ) => void
  onHandleFormChange: FormOnChangeFn<RegisterCompanyForm>
  onUpdateForm: UpdateFormFn<RegisterCompanyForm>
  onBack: () => void
  onSubmit: () => void
}

export const RegisterNewCompany: React.FC<RegisterNewCompanyProps> = ({
  form,
  errors,
  formValid,
  formValuesEdited,

  onEnableValidation,
  onHandleFormChange,
  onUpdateForm,
  onBack,
  onSubmit,
}) => {
  const translations = useTranslate({
    companyInformation: 'companies.company-information',
    fullName: 'common.company-name',
    shortName: 'companies.short-name',
    email: 'customers.billing-email',
    phone: 'common.phone',
    post: 'common.postal-code',
    city: 'common.city',
    newPassword: 'users.new-password',
    confirmPassword: 'users.password-confirm',
    address: 'common.address',
    numberOfLicences: 'companies.number-of-licences',
    organizationNumber: 'customers.organization-number',
    defaultHourlyRate: 'common.default-hourly-rate',

    cancel: 'common.cancel',
    agreeTo: 'companies.i-agree-to-digitechs',
    termsAndConditions: 'companies.terms-and-conditions',

    back: 'common.back',
    submit: 'common.create',

    validation: {
      required: 'common.required',
      invalidEmail: 'errors.invalid-email-address',
      invalidPhoneNumber: 'errors.invalid-phone-number',
      shortNameTaken: 'companies.errors.short-name-taken',
    },
  })

  const shortNameChanged = useRef(false)
  const addPrompt = usePrompt()

  const [hasAgreed, setHasAgreed] = useState(false)
  const [shortNameTaken, setShortNameTaken] = useState(false)
  const debouncedShortName = useDebounce(form.shortName)

  const { loading: loadingShortNameTaken } = useQuery<
    ShortNameTakenQuery,
    ShortNameTakenQueryVariables
  >(SHORT_NAME_TAKEN_QUERY, {
    skip: !debouncedShortName,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      shortName: debouncedShortName,
    },
    onCompleted(data) {
      setShortNameTaken(data.shortNameTaken)
    },
  })

  const handleOpenModal = useCallback(async () => {
    await addPrompt<null>(resolve => (
      <TermsAndConditionsModal onClose={() => resolve(null)} />
    ))
  }, [addPrompt])

  function handleFullNameChange(fullName: string) {
    if (shortNameChanged.current) {
      onUpdateForm({ fullName })
      return
    }

    const shortName = stringToSlug(fullName).substring(0, 16)

    onUpdateForm({
      fullName,
      shortName,
    })
  }

  function handleShortNameChange(shortName: string) {
    shortNameChanged.current = true
    onUpdateForm({ shortName: stringToSlug(shortName) })
  }

  return (
    <Wrapper>
      <Layout>
        <Logo src={logo} area="logo" />

        <Header area="header">{translations.companyInformation}</Header>

        <FormField required area="fullName">
          <label>{translations.fullName}</label>
          <Input
            id="fullName"
            value={form.fullName}
            error={errors.fullName}
            fullWidth
            autoFocus
            onChange={handleFullNameChange}
          />
        </FormField>

        <FormField required area="shortName">
          <label>{translations.shortName}</label>
          <Input
            id="shortName"
            value={form.shortName}
            error={
              shortNameTaken
                ? translations.validation.shortNameTaken
                : errors.shortName
            }
            fullWidth
            maxLength={16}
            loading={loadingShortNameTaken}
            onChange={handleShortNameChange}
          />
        </FormField>

        <FormField area="orgNo">
          <label>{translations.organizationNumber}</label>
          <Input
            id="organizationNumber"
            value={form.organizationNumber ?? ''}
            error={errors.organizationNumber}
            fullWidth
            onChange={onHandleFormChange('organizationNumber')}
          />
        </FormField>

        <FormField required area="email">
          <label>{translations.email}</label>
          <Input
            id="email"
            value={form.billingEmail}
            error={errors.billingEmail}
            inputMode="email"
            fullWidth
            onBlur={() =>
              formValuesEdited.billingEmail &&
              onEnableValidation('billingEmail')
            }
            onChange={onHandleFormChange('billingEmail')}
          />
        </FormField>

        <AgreeField>
          <CheckBox checked={hasAgreed} color="gray1" onChange={setHasAgreed} />

          <span className="terms">
            {translations.agreeTo}

            <span role="button" onClick={handleOpenModal}>
              &nbsp;{translations.termsAndConditions}
            </span>
          </span>
        </AgreeField>

        <Button area="back" variant="cancel" fullWidth onClick={onBack}>
          {translations.back}
        </Button>

        <Button
          area="submit"
          variant="primary"
          fullWidth
          disabled={
            !formValid || shortNameTaken || loadingShortNameTaken || !hasAgreed
          }
          onClick={() => onSubmit()}
        >
          {translations.submit}
        </Button>
      </Layout>
    </Wrapper>
  )
}

export const theme = {
  colors: {
    primary: '#304efe',
    secondary: '#8930fe',

    primaryHover: '#45a7ff',
    secondaryHover: '#a562fc',

    body: '#f6f8ff',
    hover: '#eff1f5',
    current: '#d6dfff',
    primaryButtonHover: '#657bff',
    navBackground: '#e7ecff',

    gray1: '#333333',
    gray2: '#4f4f4f',
    gray3: '#828282',
    gray4: '#bdbdbd',
    gray5: '#e0e0e0',
    gray6: '#f2f2f2',
    gray7: '#f6f6f6',
    gray8: '#f9f9f9',

    success: '#59da7f',
    information: '#3fbde4',
    warning: '#ebef39',
    warning2: '#fe8730',
    danger: '#fe3030',

    dangerHover: '#f75252',

    white: '#ffffff',

    matteGreen: '#59da7f',
    matteGreen2: '#88C99B',
    matteTeal: '#3fbde4',
    matteYellow: '#ebef39',
    matteOrange: '#fe8730',
    matteRed: '#fe3030',
    matteLilac: '#9a62e3',

    lightGreen: '#e7ffee',
    lightCyan: '#ccf3ff',
    lightYellow: '#fcfdc3',
    lightOrange: '#ff6f0',
    lightRed: '#ffecec',
    lightPurple: '#B1BDFF',
    lightPink: '#DBBFFF',

    logoutRed: '#f39b9b',
    logoutRedHover: '#e38080',

    /* OLD BELOW */
    overlays: {
      lightblue: 'rgba(69, 167, 255, 0.05)',
      lightred: 'rgba(255, 34, 61, 0.1)',
      mediumblue: 'rgba(69, 167, 255, 0.15)',
    },

    projectStages: {
      planning: '#314eff',
      ongoing: '#ffda00',
      billing: '#ed65f7', // FIXME Not from design
      finished: '#1fdb70',
    },
    projectBillingType: '#99e',
    checklistItemStatus: {
      notDone: 'white',
      irrelevant: '#314eff',
      done: '#1fdb70',
    },

    background: '#F7F8FC',
    darkGrayText: '#676b80',
    grayText: '#9EA2B6',

    lightgreen: '#1fdb70',
    lightred: '#ff5757', // FIXME Not from design
    lightblue: '#f5fafe', // Same as overlays.lightblue, but no alpha
    yellow: '#ffda00',
    skyblue: '#0dddff',
    mediumgray: '#dfe1ec',

    // Colors below this point are not from design

    layout: {
      text: '#bbb',
      background: 'white',
    },

    dark: '#156BDB',
    dark1: '#333333',
    light: '#2ACEFD',
    accent: '#7F4FD0',

    darkgray: '#595a63',
    gray: '#83858e',
    lightgray: '#aeb2c2',
    verylightgray: '#e2e2e6',
    ridiculouslylightgray: '#f5f5fa',

    red: '#d11b1e',
    blue: '#2a68cc',
    error: '#d11b1e',
    info: '#45a7ff',

    save: '#4298f4',
    cancel: '#d8d2d2',
    disabled: '#ddd',

    link: '#5e757a',

    panel: {
      headerBackground: '#f4f8f9',
    },

    menu: {
      dark: '#2C3B50',
      light: '#374761',
      border: '#31415A',
      text: '#EEFAFF',
    },
  },
  media: {
    mobile: '@media screen and (max-width: 768px)',
    desktop: '@media screen and (min-width: 769px)',
  },
  sizes: {
    defaultBorderRadius: '8px',
    sidebarWidth: '82px',
    sidebarWidthExpanded: '208px',

    /* OLD BELOW */

    sidebarWidthOld: '82px',
    sidebarWidthExpandedOld: '82px',
    headerHeight: '86px',
    headerHeightMobile: '68px',
  },
  layout: {
    defaultShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    defaultCardShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
    defaultBorder: '1px solid #dadadd',

    defaultWrapper: `
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 8px;
      }
      @media screen and (min-width: 769px) {
        width: 100%;
        max-width: 2200px;
        padding: 2em;
      }
    `,
    defaultMobileWrapper: `
      width: 100%;
      padding: 8px;
    `,

    defaultGrayHeaderBoxShadowMobile: '0 2px 3px 0 rgba(0, 0, 0, 0.2)',

    whiteHeader: `
      box-sizing: border-box;
      padding: 2.5em 3em 1em;
      background-color: white;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);

      h1 {
        margin: 0;

        span {
          font-size: 1rem;
        }
      }
      p, span.label, span.gray {
        margin: 0;
        color: #9EA2B6;
      }
      a {
        color: #314eff;
      }

      @media screen and (max-width: 768px) {
        padding: 1rem;

        h1 {
          font-size: 1.2rem;

          span {
            font-size: 0.8rem;
          }
        }
        p, span, a {
          margin: 0;
          font-size: 0.6rem;
        }
        p {
          margin: 0;
        }
      }
    `,
    formH1: `
      margin: 0 0 0.5rem 0;
      padding: 0;
      font-size: 1.6rem;
    `,
    formH2: `
      margin: 0 0 0.5rem 0;
      padding: 0;
      font-size: 1.2rem;
    `,
  },
  patterns: {
    checkerboard(color1 = 'white', color2 = '#eee', size = 12) {
      return `
        background-color: ${color1};
        background-image:
          linear-gradient(45deg, ${color2} 25%, transparent 25%, transparent 75%, ${color2} 75%, ${color2}),
          linear-gradient(45deg, ${color2} 25%, transparent 25%, transparent 75%, ${color2} 75%, ${color2});
        background-size: ${size}px ${size}px;
        background-position: 0 0, ${size / 2}px ${size / 2}px;
      `
    },
  },
}

import { ActionButton } from 'components'
import React from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { FolderCard } from './components/FolderCard'
import { ShallowManualFolder } from './types.graphql'
import { useManualUtils } from './util'

const Wrapper = styled.div``

interface CompanyManualsMobileProps {
  folders: ShallowManualFolder[]

  root?: boolean
  noShift?: boolean
  showPath?: boolean

  onCreateClick: () => Promise<void>
  onResetSearch?: () => void
}

export const CompanyManualsMobile: React.FC<CompanyManualsMobileProps> = ({
  folders,

  root = false,
  noShift = false,
  showPath = false,

  onCreateClick,
  onResetSearch = () => void 0,
}) => {
  const { makeEditDeleteMenu, makeOrderArrows } = useManualUtils(folders, {
    refetchQueries: root ? ['AllRootManualFolders'] : ['ManualFolderBySlug'],
  })

  const canCreateFolder = usePermission(
    PERMISSIONS.companymanuals.add.manualfolder
  )

  return (
    <Wrapper>
      {canCreateFolder && <ActionButton onClick={() => onCreateClick()} />}

      {folders.map((folder, index) => (
        <FolderCard
          key={folder.id}
          folder={folder}
          editDeleteMenu={makeEditDeleteMenu(folder)}
          orderArrows={noShift ? null : makeOrderArrows(folder, index)}
          showPath={showPath}
          onResetSearch={onResetSearch}
        />
      ))}
    </Wrapper>
  )
}

import { Image } from '@ur/react-components'
import { ImageViewer } from 'components'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { DeviationImageNode } from '../types.graphql'

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  padding: 2rem;

  img {
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.1s ease;

    &:hover {
      transform: scale(105%);
    }
  }

  ${props => props.theme.media.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`

type DeviationImage = Pick<
  DeviationImageNode,
  'id' | 'title' | 'image' | 'imageCompressed'
>

interface DeviationImagesProps {
  images: DeviationImage[]
}

export const DeviationImages: React.FC<DeviationImagesProps> = ({ images }) => {
  const [initialImage, setInitialImage] = useState<string | null>(null)

  const viewerImages = useMemo<Image[]>(
    () =>
      images.map(image => ({
        id: image.id,
        file: image.image,
        name: image.title,
      })),
    [images]
  )

  return (
    <Wrapper>
      <ImageViewer
        popup
        open={initialImage !== null}
        images={viewerImages}
        initialImage={initialImage ?? 0}
        onClose={() => setInitialImage(null)}
      />

      {images.map(image => (
        <img
          key={image.id}
          src={image.imageCompressed ?? image.image}
          alt={image.title}
          onClick={() => setInitialImage(image.id)}
        />
      ))}
    </Wrapper>
  )
}

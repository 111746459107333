import { Icon } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  FormField as BaseFormField,
  Input,
  OrderArrows,
  TextArea,
} from 'components'
import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useConfirm } from 'util/hooks'
import { validateNonEmpty } from 'util/validation'
import {
  ChecklistTemplate,
  CreateChecklistTemplateInputAddChecklistTemplateItems,
  CreateChecklistTemplateItemMutationVariables,
  CreateChecklistTemplateMutationVariables,
  PatchChecklistTemplateItemMutationVariables,
  PatchChecklistTemplateMutationVariables,
  ShiftChecklistTemplateItemIndexMutationVariables,
} from '../types.graphql'
import { CreateEditTemplateItem } from './CreateEditTemplateItem'

const Wrapper = styled.div`
  ${props => props.theme.media.desktop} {
    width: calc(100% / 2.5);
    background-color: white;

    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    box-shadow: ${props => props.theme.layout.defaultCardShadow};

    section {
      padding: 2rem 3rem;

      & + section {
        border-top: 1px solid ${props => props.theme.colors.gray6};
      }
      h2 {
        margin: 0 0 1.5rem 0;

        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }
`
const FormField = styled(BaseFormField)`
  & + div {
    margin-top: 1rem;
  }
  label {
    font-size: 0.9rem;
  }
`
const Item = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  margin-bottom: 0.5rem;
`
interface ChecklistTemplateItemProps {
  hasDescription: boolean
}
const ChecklistTemplateItem = styled.div<ChecklistTemplateItemProps>`
  display: grid;
  grid-template-areas:
    'title        edit        chevron'
    'description  description description';
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 40px auto;
  align-items: center;

  width: 100%;
  margin: 0 1rem;
  padding: 0 1rem;

  background-color: ${props => props.theme.colors.gray6};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  cursor: ${props => props.hasDescription && 'pointer'};

  div.title {
    grid-area: title;
    font-weight: 600;
  }

  i {
    padding-left: 0.5rem;

    &.edit-icon {
      grid-area: edit;
      display: none;
    }
    &.description {
      grid-area: chevron;
    }
  }

  p {
    grid-area: description;
  }

  &:hover {
    i.edit-icon {
      display: block;
    }

    i.description {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    grid-template-rows: 56px auto;
    margin-left: 0;

    background-color: ${props => props.theme.colors.white};

    p {
      margin: 0;
      padding: 1rem 0;

      border-top: 1px solid ${props => props.theme.colors.gray5};
    }
  }
`
const MarginedButton = styled(Button)`
  margin: 1rem 0;
`

interface ChecklistTemplateItemType {
  id: string
  title: string
  description: string | null
  index: number
}

interface ChecklistTemplateData {
  title: string
  description?: string
}

interface ChecklistTemplateFormProps {
  template?: ChecklistTemplate
  loading: boolean

  onCreateItem: (
    variables: CreateChecklistTemplateItemMutationVariables
  ) => Promise<unknown>
  onPatchItem: (
    variables: PatchChecklistTemplateItemMutationVariables
  ) => Promise<unknown>
  onShiftIndex: (
    variables: ShiftChecklistTemplateItemIndexMutationVariables
  ) => Promise<unknown>
  onDeleteItem: (id: IdVariable) => Promise<unknown>
  onSubmit: (
    form:
      | CreateChecklistTemplateMutationVariables
      | PatchChecklistTemplateMutationVariables
  ) => Promise<unknown>
}

export const ChecklistTemplateForm: React.FC<ChecklistTemplateFormProps> = ({
  template,
  loading,

  onCreateItem,
  onPatchItem,
  onShiftIndex,
  onDeleteItem,
  onSubmit,
}) => {
  const translations = useTranslate({
    generalInfo: 'customers.general-information',
    items: 'checklists.items',

    title: 'common.title',
    description: 'common.description',

    save: 'common.save',
    add: 'checklists.create-checklist-template',

    createChecklistItem: 'checklists.add-checklist-item',

    prompt: {
      deleteItem: 'checklists.prompts.delete-template-item',
      deleteItemTitle: 'checklists.prompts.delete-template-item-title',
    },

    validation: {
      required: 'common.required',
    },
  })

  const isEdit = !!template

  const isPopulated = useRef<boolean>(false)
  const confirm = useConfirm()

  const [createOpen, setCreateOpen] = useState(false)
  const [patchOpen, setPatchOpen] = useState<string | null>(null)
  const [descriptionOpen, setDescriptionOpen] = useState<string | null>(null)
  const [createItems, setCreateItems] = useState<ChecklistTemplateItemType[]>(
    []
  )

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    formChangeHandler: handler,
    updateForm,
    updateInitialValues,
    submitHandler,
  } = useForm<ChecklistTemplateData>({
    values: {
      title: '',
      description: '',
    },
    validators: {
      title: validateNonEmpty(translations.validation.required),
    },
  })

  const items = useMemo(
    () =>
      !template ? createItems : template.items.edges.map(edge => edge.node),
    [template, createItems]
  )

  useEffect(() => {
    if (isEdit && !isPopulated.current) {
      const values = {
        title: template.title,
        description: template.description ?? undefined,
      }
      updateForm(values)
      updateInitialValues(values)
      isPopulated.current = true
    }
  }, [isEdit, template, updateForm, updateInitialValues])

  function handleSubmit(values: typeof form) {
    if (isEdit) {
      onSubmit({ id: template.id, input: values })
      return
    }

    const addItems: CreateChecklistTemplateInputAddChecklistTemplateItems[] =
      createItems.map((item, index) => ({
        title: item.title,
        description: item.description ?? undefined,
        index,
      }))

    onSubmit({ input: { ...values, itemsAdd: addItems } })
  }

  async function handleCreateItem(
    variables: CreateChecklistTemplateItemMutationVariables
  ) {
    if (isEdit) return onCreateItem(variables)

    const newItem: ChecklistTemplateItemType = {
      id: uniqueId('item'),
      title: variables.input.title,
      description: variables.input.description ?? null,
      index: items.length,
    }
    setCreateItems(createItems.concat(newItem))
  }

  async function handlePatchItem(
    variables: PatchChecklistTemplateItemMutationVariables
  ) {
    if (isEdit) return onPatchItem(variables)

    const newItems = createItems.map(item =>
      item.id === variables.id
        ? {
            id: variables.id,
            title: variables.input.title ?? item.title,
            description: variables.input.description ?? item.description,
            index: item.index,
          }
        : item
    )
    setCreateItems(newItems)
  }

  const handleDeleteItem = useCallback(
    async (itemId: string) => {
      if (isEdit) {
        const answer = await confirm(
          translations.prompt.deleteItem,
          translations.prompt.deleteItemTitle,
          {
            variant: 'delete',
          }
        )

        if (answer) onDeleteItem({ id: itemId })
        return
      }

      const newItems = createItems.filter(item => item.id !== itemId)
      setCreateItems(newItems)
    },
    [
      confirm,
      createItems,
      isEdit,
      onDeleteItem,
      translations.prompt.deleteItem,
      translations.prompt.deleteItemTitle,
    ]
  )

  const handleShiftIndex = useCallback(
    (item: ChecklistTemplateItemType, delta: number) => {
      if (isEdit) {
        onShiftIndex({
          id: item.id,
          delta,
        })
        return
      }

      const curIndex = createItems.findIndex(cur => cur.id === item.id)
      if (curIndex < 0) return

      const newIndex = curIndex + delta
      if (newIndex < 0 || newIndex > createItems.length - 1) return

      const newItems = [...createItems]
      ;[newItems[newIndex], newItems[curIndex]] = [
        newItems[curIndex],
        newItems[newIndex],
      ]

      setCreateItems(newItems)
    },
    [isEdit, createItems, onShiftIndex]
  )

  const makeOrderArrows = useCallback(
    (item: ChecklistTemplateItemType, idx: number) => (
      <OrderArrows
        isFirst={idx === 0}
        isLast={idx === items.length - 1}
        onClick={delta => handleShiftIndex(item, delta)}
      />
    ),
    [handleShiftIndex, items.length]
  )

  return (
    <Wrapper>
      <section>
        <h2>{translations.generalInfo}</h2>

        <FormField required error={!!errors.title}>
          <label>{translations.title}</label>

          <Input
            value={form.title}
            error={errors.title}
            fullWidth
            autoFocus
            onChange={handler('title')}
          />
        </FormField>

        <FormField error={!!errors.description}>
          <label>{translations.description}</label>

          <TextArea
            value={form.description ?? ''}
            error={errors.description}
            fullWidth
            onChange={handler('description')}
          />
        </FormField>
      </section>

      <section>
        <h2>{translations.items}</h2>

        {items.map((item, index) => (
          <div key={item.id}>
            {patchOpen === item.id ? (
              <CreateEditTemplateItem
                templateId={template?.id ?? ''}
                item={item}
                loading={loading}
                onPatch={variables => handlePatchItem(variables)}
                onClose={() => setPatchOpen(null)}
              />
            ) : (
              <Item>
                {!isMobileOnly && makeOrderArrows(item, index)}

                <ChecklistTemplateItem
                  hasDescription={!!item.description}
                  onClick={() =>
                    !!item.description &&
                    setDescriptionOpen(v => (v === item.id ? null : item.id))
                  }
                >
                  <div className="title">{item.title}</div>

                  <Icon
                    className="edit-icon"
                    icon="pencil"
                    type="solid"
                    color="gray4"
                    size={isMobileOnly ? '1.2rem' : '1rem'}
                    hoverColor="primaryHover"
                    cursor="pointer"
                    onClick={e => {
                      e.stopPropagation()
                      setDescriptionOpen(null)
                      setPatchOpen(item.id)
                    }}
                  />

                  {!!item.description && (
                    <Icon
                      className="description"
                      icon={descriptionOpen ? 'chevron-up' : 'chevron-down'}
                      type="solid"
                      size={isMobileOnly ? '1.5rem' : '1rem'}
                      color={descriptionOpen === item.id ? 'primary' : 'gray4'}
                      cursor="pointer"
                    />
                  )}

                  {descriptionOpen === item.id && <p>{item.description}</p>}
                </ChecklistTemplateItem>

                <Icon
                  icon="trash"
                  color="gray5"
                  type="solid"
                  size={isMobileOnly ? '1.4rem' : '1rem'}
                  onClick={() => handleDeleteItem(item.id)}
                  cursor="pointer"
                  hoverColor="logoutRed"
                />
              </Item>
            )}
          </div>
        ))}

        {!patchOpen &&
          (createOpen ? (
            <CreateEditTemplateItem
              loading={loading}
              templateId={template?.id ?? ''}
              onCreate={variables => handleCreateItem(variables)}
              onClose={() => setCreateOpen(false)}
            />
          ) : (
            <MarginedButton
              iconRightProps={{ icon: 'plus', color: 'gray3' }}
              color={isMobileOnly ? 'dark1' : 'gray3'}
              background={isMobileOnly ? 'gray5' : 'gray6'}
              onClick={() => setCreateOpen(true)}
            >
              {translations.createChecklistItem}
            </MarginedButton>
          ))}

        {!createOpen && !patchOpen && (
          <Button
            fullWidth
            disabled={
              !formEdited || !formValid || loading || createOpen || !!patchOpen
            }
            loading={loading}
            onClick={submitHandler(handleSubmit)}
          >
            {isEdit ? translations.save : translations.add}
          </Button>
        )}
      </section>
    </Wrapper>
  )
}

import { gql } from '@apollo/client'

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        id
      }
    }
  }
`

export const PATCH_PROJECT_MUTATION = gql`
  mutation PatchProject($id: ID!, $input: PatchProjectInput!) {
    patchProject(id: $id, input: $input) {
      project {
        id
      }
    }
  }
`

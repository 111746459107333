import { CrumbInsert } from '@ur/react-components'

export interface CrumbOverrides {
  [key: string]: string | null
}

export interface BreadcrumbsStore {
  overrides: CrumbOverrides
  inserts: CrumbInsert[]
}

export const breadcrumbsStore: BreadcrumbsStore = {
  overrides: {},
  inserts: [],
}

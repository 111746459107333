import { useTranslate } from '@ur/react-hooks'
import { RegularModal as RegularModalBase } from 'components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { PrivacyPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'

interface TabsWrapperProps {
  active: Tab
}
const TabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  gap: 0.5rem;

  div {
    padding: 4px 6px;

    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    font-size: 0.8rem;

    &:hover {
      text-decoration: underline;
    }

    ${props => `&.${props.active}`} {
      color: white;
      background-color: ${props => props.theme.colors.primary};
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    }
  }
`

type Tab = 'tc' | 'pp'
interface TabsProps {
  tab: Tab
  onChange: (tab: Tab) => void
}

const Tabs: React.FC<TabsProps> = ({ tab, onChange }) => {
  const translations = useTranslate({
    termsAndConditions: 'companies.terms-and-conditions-header',
    privacyPolicy: 'companies.privacy-policy',
  })

  return (
    <TabsWrapper active={tab}>
      <div role="button" className="tc" onClick={() => onChange('tc')}>
        {translations.termsAndConditions}
      </div>

      <div role="button" className="pp" onClick={() => onChange('pp')}>
        {translations.privacyPolicy}
      </div>
    </TabsWrapper>
  )
}

const RegularModal = styled(RegularModalBase)`
  header.modal-header {
    padding-bottom: 0;
  }
`

interface TermsAndConditionsProps {
  onClose: () => void
}
export const TermsAndConditionsModal: React.FC<TermsAndConditionsProps> = ({
  onClose,
}) => {
  const [tab, setTab] = useState<Tab>('tc')

  return (
    <RegularModal
      title={<Tabs tab={tab} onChange={setTab} />}
      width="min(800px, 96vw)"
      overflowY="auto"
      cancelOnClickOutside
      cancelOnEscape
      onClose={onClose}
    >
      {tab === 'tc' ? <TermsAndConditions /> : <PrivacyPolicy />}
    </RegularModal>
  )
}

import { useTranslate } from '@ur/react-hooks'
import { ProjectStage } from 'modules/projects/types.graphql'
import React from 'react'
import styled from 'styled-components'
import { formatPhoneNumber } from 'util/formatting'
import { CustomerElementArea, CustomerElementCard } from './CustomerElementCard'

const Fields = styled.div.attrs({
  role: 'list',
})`
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;

    color: ${props => props.theme.colors.gray1};
    font-size: 0.8rem;

    span:first-child {
      font-weight: 600;
    }
    a:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    & > div {
      padding: 1rem;
    }
  }
`

export interface CustomerInfoField {
  label: string
  text: string

  type?: 'tel' | 'email' | 'map' | 'brreg'
  hrefInput?: string
}

interface CustomerInfoCardProps {
  area: Exclude<CustomerElementArea, ProjectStage | 'map'>
  closed?: boolean

  fields: CustomerInfoField[]

  onToggleOpen?: () => void
}

export const CustomerInfoCard: React.FC<CustomerInfoCardProps> = ({
  area,
  closed = false,

  fields,

  onToggleOpen = () => void 0,
}) => {
  const translations = useTranslate({
    info: 'customers.general-information',
    billing: 'common.billing',
    contact: 'customers.contact-person',
  })

  function render(field: CustomerInfoField) {
    if (!field.text) return null
    if (!field.type) return <span>{field.text}</span>

    switch (field.type) {
      case 'tel':
        return (
          <a href={`tel:${field.text.replace(/\s/g, '')}`}>
            {formatPhoneNumber(field.text)}
          </a>
        )
      case 'email':
        return <a href={`mailto:${field.text}`}>{field.text}</a>
      case 'map':
        const url = encodeURI(
          `https://www.google.com/maps/search/?api=1&query=${`${
            field.hrefInput ?? field.text
          }`}`
        )
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {field.text}
          </a>
        )
      case 'brreg':
        return (
          <a
            href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${field.text}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {field.text}
          </a>
        )
    }
  }

  return (
    <CustomerElementCard
      area={area}
      title={translations[area]}
      closed={closed}
      onToggleOpen={onToggleOpen}
    >
      {!closed && (
        <Fields>
          {fields.map(field => (
            <div key={field.label}>
              <span>{field.label}</span>

              {render(field)}
            </div>
          ))}
        </Fields>
      )}
    </CustomerElementCard>
  )
}

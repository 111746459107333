import {
  BaseNode,
  DeleteMutationReturn,
  IdVariable,
  OrderByVariable,
  PaginationVariables,
  QueryVariable,
  RelayEdgesWithPageInfo,
} from 'types/graphql'
import { RequiredColumns } from './types'

export type ProductStatusType = 'ACTIVE' | 'INACTIVE'

export enum ImportProductsFileType {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export interface ProductNode extends BaseNode {
  __typename: 'ProductNode'
  name: string
  sku: string
  unit: string
  status: ProductStatusType
  grossPriceExcludingVat: string
  priceExcludingVat: string
  vatPercent: string
  description: string | null
  merchant: string | null
  isImported: boolean
  discount: string
  discountPercent: string
}

/* INTERMEDIARIES */

export type Product = Pick<
  ProductNode,
  | 'id'
  | 'merchant'
  | 'grossPriceExcludingVat'
  | 'priceExcludingVat'
  | 'name'
  | 'sku'
  | 'unit'
>

/* QUERIES */

export interface AllProductsQuery {
  allProducts: RelayEdgesWithPageInfo<Product>
}
export interface AllProductsQueryVariables
  extends QueryVariable,
    OrderByVariable,
    PaginationVariables {
  merchant: string[]
  isImported: boolean
}

export interface AllProductMerchantsQuery {
  allProductMerchants: string[]
}
export interface AllProductMerchantsQueryVariables {
  isImported: boolean
}

export interface ProductQuery {
  product: Product
}

/* MUTATIONS */
export interface CreateProductMutation {
  createProduct: {
    product: Pick<ProductNode, 'id'>
  }
}

export interface CreateProductMutationVariables {
  input: {
    name: string
    sku: string
    unit?: string
    grossPriceExcludingVat: string
    priceExcludingVat: string
    merchant?: string
  }
}

export interface PatchProductMutation {
  patchProduct: {
    product: Pick<ProductNode, 'id'>
  }
}

export interface PatchProductMutationVariables extends IdVariable {
  input: {
    name?: string
    sku?: string
    unit?: string
    grossPriceExcludingVat?: string
    priceExcludingVat?: string
    merchant?: string
  }
}

export interface ImportProductsMutation {
  importProducts: {
    ok: boolean
    missingFieldMappings: RequiredColumns[] | null
  }
}

export interface ImportProductsMutationVariables {
  file: File
  fileType: ImportProductsFileType
  merchant: string
  overwriteAllExistingFromMerchant: boolean
}

export interface DeleteProductMutation {
  deleteProduct: DeleteMutationReturn
}

export const PERMISSIONS = {
  admin: {
    add: {
      logentry: 'admin.add_logentry',
    },
    change: {
      logentry: 'admin.change_logentry',
    },
    delete: {
      logentry: 'admin.delete_logentry',
    },
    view: {
      logentry: 'admin.view_logentry',
    },
  },
  auth: {
    add: {
      group: 'auth.add_group',
      permission: 'auth.add_permission',
    },
    change: {
      group: 'auth.change_group',
      permission: 'auth.change_permission',
    },
    delete: {
      group: 'auth.delete_group',
      permission: 'auth.delete_permission',
    },
    view: {
      group: 'auth.view_group',
      permission: 'auth.view_permission',
    },
  },
  checklists: {
    add: {
      checklist: 'checklists.add_checklist',
      checklistitem: 'checklists.add_checklistitem',
      checklistitemcomment: 'checklists.add_checklistitemcomment',
      checklistitemimage: 'checklists.add_checklistitemimage',
      checklisttemplate: 'checklists.add_checklisttemplate',
      checklisttemplateitem: 'checklists.add_checklisttemplateitem',
    },
    change: {
      checklist: 'checklists.change_checklist',
      checklistitem: 'checklists.change_checklistitem',
      checklistitemcomment: 'checklists.change_checklistitemcomment',
      checklistitemimage: 'checklists.change_checklistitemimage',
      checklisttemplate: 'checklists.change_checklisttemplate',
      checklisttemplateitem: 'checklists.change_checklisttemplateitem',
    },
    delete: {
      checklist: 'checklists.delete_checklist',
      checklistitem: 'checklists.delete_checklistitem',
      checklistitemcomment: 'checklists.delete_checklistitemcomment',
      checklistitemimage: 'checklists.delete_checklistitemimage',
      checklisttemplate: 'checklists.delete_checklisttemplate',
      checklisttemplateitem: 'checklists.delete_checklisttemplateitem',
    },
    view: {
      checklist: 'checklists.view_checklist',
      checklistitem: 'checklists.view_checklistitem',
      checklistitemcomment: 'checklists.view_checklistitemcomment',
      checklistitemimage: 'checklists.view_checklistitemimage',
      checklisttemplate: 'checklists.view_checklisttemplate',
      checklisttemplateitem: 'checklists.view_checklisttemplateitem',
    },
  },
  companies: {
    add: {
      company: 'companies.add_company',
      companyfoldersetting: 'companies.add_companyfoldersetting',
      companysetting: 'companies.add_companysetting',
    },
    change: {
      company: 'companies.change_company',
      companyfoldersetting: 'companies.change_companyfoldersetting',
      companysetting: 'companies.change_companysetting',
    },
    delete: {
      company: 'companies.delete_company',
      companyfoldersetting: 'companies.delete_companyfoldersetting',
      companysetting: 'companies.delete_companysetting',
    },
    view: {
      company: 'companies.view_company',
      companyfoldersetting: 'companies.view_companyfoldersetting',
      companysetting: 'companies.view_companysetting',
    },
  },
  companymanuals: {
    add: {
      manualentry: 'companymanuals.add_manualentry',
      manualentryattachment: 'companymanuals.add_manualentryattachment',
      manualfolder: 'companymanuals.add_manualfolder',
    },
    change: {
      manualentry: 'companymanuals.change_manualentry',
      manualentryattachment: 'companymanuals.change_manualentryattachment',
      manualfolder: 'companymanuals.change_manualfolder',
    },
    delete: {
      manualentry: 'companymanuals.delete_manualentry',
      manualentryattachment: 'companymanuals.delete_manualentryattachment',
      manualfolder: 'companymanuals.delete_manualfolder',
    },
    view: {
      manualentry: 'companymanuals.view_manualentry',
      manualentryattachment: 'companymanuals.view_manualentryattachment',
      manualfolder: 'companymanuals.view_manualfolder',
    },
  },
  contenttypes: {
    add: {
      contenttype: 'contenttypes.add_contenttype',
    },
    change: {
      contenttype: 'contenttypes.change_contenttype',
    },
    delete: {
      contenttype: 'contenttypes.delete_contenttype',
    },
    view: {
      contenttype: 'contenttypes.view_contenttype',
    },
  },
  customers: {
    add: {
      customer: 'customers.add_customer',
    },
    change: {
      customer: 'customers.change_customer',
    },
    delete: {
      customer: 'customers.delete_customer',
    },
    view: {
      customer: 'customers.view_customer',
    },
  },
  deviations: {
    add: {
      deviation: 'deviations.add_deviation',
      deviationcategory: 'deviations.add_deviationcategory',
      deviationimage: 'deviations.add_deviationimage',
      deviationperformedaction: 'deviations.add_deviationperformedaction',
    },
    change: {
      deviation: 'deviations.change_deviation',
      deviationcategory: 'deviations.change_deviationcategory',
      deviationimage: 'deviations.change_deviationimage',
      deviationperformedaction: 'deviations.change_deviationperformedaction',
    },
    delete: {
      deviation: 'deviations.delete_deviation',
      deviationcategory: 'deviations.delete_deviationcategory',
      deviationimage: 'deviations.delete_deviationimage',
      deviationperformedaction: 'deviations.delete_deviationperformedaction',
    },
    view: {
      deviation: 'deviations.view_deviation',
      deviationcategory: 'deviations.view_deviationcategory',
      deviationimage: 'deviations.view_deviationimage',
      deviationperformedaction: 'deviations.view_deviationperformedaction',
    },
  },
  files: {
    add: {
      file: 'files.add_file',
      folder: 'files.add_folder',
    },
    change: {
      file: 'files.change_file',
      folder: 'files.change_folder',
    },
    delete: {
      file: 'files.delete_file',
      folder: 'files.delete_folder',
    },
    view: {
      file: 'files.view_file',
      folder: 'files.view_folder',
    },
  },
  offers: {
    add: {
      offer: 'offers.add_offer',
    },
    change: {
      offer: 'offers.change_offer',
    },
    delete: {
      offer: 'offers.delete_offer',
    },
    view: {
      offer: 'offers.view_offer',
    },
  },
  products: {
    add: {
      product: 'products.add_product',
      productimage: 'products.add_productimage',
    },
    change: {
      product: 'products.change_product',
      productimage: 'products.change_productimage',
    },
    delete: {
      product: 'products.delete_product',
      productimage: 'products.delete_productimage',
    },
    view: {
      product: 'products.view_product',
      productimage: 'products.view_productimage',
    },
  },
  projects: {
    add: {
      project: 'projects.add_project',
    },
    change: {
      project: 'projects.change_project',
    },
    delete: {
      project: 'projects.delete_project',
    },
    view: {
      project: 'projects.view_project',
    },
  },
  sessions: {
    add: {
      session: 'sessions.add_session',
    },
    change: {
      session: 'sessions.change_session',
    },
    delete: {
      session: 'sessions.delete_session',
    },
    view: {
      session: 'sessions.view_session',
    },
  },
  sms: {
    add: {
      smsrecord: 'sms.add_smsrecord',
    },
    change: {
      smsrecord: 'sms.change_smsrecord',
    },
    delete: {
      smsrecord: 'sms.delete_smsrecord',
    },
    view: {
      smsrecord: 'sms.view_smsrecord',
    },
  },
  timesheets: {
    add: {
      absence: 'timesheets.add_absence',
      timeentry: 'timesheets.add_timeentry',
    },
    change: {
      absence: 'timesheets.change_absence',
      timeentry: 'timesheets.change_timeentry',
    },
    delete: {
      absence: 'timesheets.delete_absence',
      timeentry: 'timesheets.delete_timeentry',
    },
    view: {
      absence: 'timesheets.view_absence',
      timeentry: 'timesheets.view_timeentry',
    },
  },
  users: {
    projectLeader: 'users.project_leader',
    add: {
      user: 'users.add_user',
      useremergencycontact: 'users.add_useremergencycontact',
      usersetting: 'users.add_usersetting',
      usertype: 'users.add_usertype',
    },
    change: {
      user: 'users.change_user',
      useremergencycontact: 'users.change_useremergencycontact',
      usersetting: 'users.change_usersetting',
      usertype: 'users.change_usertype',
      usertype_is_administrator: 'users.change_usertype_is_administrator',
    },
    delete: {
      user: 'users.delete_user',
      useremergencycontact: 'users.delete_useremergencycontact',
      usersetting: 'users.delete_usersetting',
      usertype: 'users.delete_usertype',
    },
    view: {
      user: 'users.view_user',
      useremergencycontact: 'users.view_useremergencycontact',
      usersetting: 'users.view_usersetting',
      usertype: 'users.view_usertype',
    },
  },
}

import { gql } from '@apollo/client'

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      product {
        id
      }
    }
  }
`

export const PATCH_PRODUCT_MUTATION = gql`
  mutation PatchProduct($id: ID!, $input: PatchProductInput!) {
    patchProduct(id: $id, input: $input) {
      product {
        id
      }
    }
  }
`

export const IMPORT_PRODUCTS_MUTATION = gql`
  mutation ImportProducts(
    $file: Upload!
    $fileType: ImportProductsFileTypeEnum!
    $merchant: String!
    $overwriteAllExistingFromMerchant: Boolean
  ) {
    importProducts(
      file: $file
      fileType: $fileType
      merchant: $merchant
      overwriteAllExistingFromMerchant: $overwriteAllExistingFromMerchant
    ) {
      ok
      missingFieldMappings
    }
  }
`

export const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      found
      deletedId
    }
  }
`

import gql from 'graphql-tag'

// Checklists

export const CHECKLIST_QUERY = gql`
  query Checklist($id: ID!) {
    checklist(id: $id) {
      id
      title
      progress
      modifiedDate
      purpose
      project {
        id
        name
      }
      closed
      items {
        edges {
          node {
            id
            title
            description
            status
            checkedBy {
              id
              fullName
            }
            checkedAt
            index
            comments {
              edges {
                node {
                  id
                  content
                  postedAt
                  postedBy {
                    id
                    fullName
                  }
                }
              }
            }
            images {
              edges {
                node {
                  id
                  image
                  imageCompressed
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`

export const CHECKLISTS_SHALLOW_QUERY = gql`
  query ChecklistsShallow(
    $project: ID
    $q: String
    $finished: Boolean
    $closed: Boolean
    $first: Int
    $after: String
    $orderBy: String
  ) {
    hasChecklists
    allChecklists(
      project: $project
      q: $q
      finished: $finished
      closed: $closed
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          title
          closed
          project {
            id
            name
            customer {
              id
              name
            }
          }
          purpose
          createdBy {
            id
            fullName
            initials
            profilePicture
          }
          progress
          modifiedDate
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

// ChecklistItems

export const CHECKLIST_ITEM_QUERY = gql`
  query ChecklistItem($id: ID!) {
    checklistItem(id: $id) {
      id
      title
      description
      checkedAt
      checkedBy {
        id
        fullName
      }
      comments {
        edges {
          node {
            id
            content

            postedAt
            postedBy {
              id
              fullName
            }
          }
        }
      }
      images {
        edges {
          node {
            id
            title
            image
            imageCompressed
          }
        }
      }
    }
  }
`

// ChecklistTemplates

export const CHECKLIST_TEMPLATE = gql`
  query ChecklistTemplate($id: ID!) {
    checklistTemplate(id: $id) {
      id
      title
      description
      items {
        edges {
          node {
            id
            title
            description
            index
          }
        }
      }
    }
  }
`

export const CHECKLIST_TEMPLATES_SHALLOW_QUERY = gql`
  query ChecklistTemplatesShallow(
    $q: String
    $orderBy: String
    $user: ID
    $first: Int
    $after: String
  ) {
    allChecklistTemplates(
      q: $q
      orderBy: $orderBy
      user: $user
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          description
          createdBy {
            id
            fullName
            initials
            profilePicture
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const SELECT_CHECKLIST_TEMPLATE_QUERY = gql`
  query ChecklistTemplateSelect(
    $q: String
    $orderBy: String
    $user: ID
    $first: Int
    $after: String
  ) {
    allChecklistTemplates(
      q: $q
      orderBy: $orderBy
      user: $user
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          items {
            edges {
              node {
                id
                title
                description
                index
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

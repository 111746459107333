import styled from 'styled-components'
import { Card, Margin } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import React, { useCallback } from 'react'
import { Button, FormField, Input } from '../../components'
import { validateNonEmpty } from '../../util/validation'
import { useMutation } from '@apollo/client'
import { ADMIN_CREATE_COMPANY_MUTATION } from './mutations'
import { useOnErrorAuto, useToast } from '../../util/hooks'
import Form from '../../components/Form/Form'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};
`

const CardWrapper = styled.div`
  max-width: 500px;

  ${props => props.theme.media.mobile} {
    width: 100%;
  }
`

interface CreateCompanyForm {
  name: string
}

export default function AdminCreateCompany() {
  const {
    formValues,
    formChangeHandler: handler,
    formErrors: errors,
    formValid,
    submitHandler,
    resetForm,
  } = useForm<CreateCompanyForm>({
    config: undefined,
    validators: {
      name: validateNonEmpty('Name is required'),
    },
    values: {
      name: '',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const translations = useTranslate({
    createCompany: 'admin.create-company',
    form: {
      name: 'common.name',
      submit: 'admin.create-company',
      toast: {
        success: ['admin.create-company.success', { name: '' }],
      },
    },
  })

  const [createCompany, { loading }] = useMutation(
    ADMIN_CREATE_COMPANY_MUTATION,
    {
      onCompleted(data) {
        const companyName = data.adminCreateCompany.company.fullName
        addToast(
          'success',
          translations.form.toast.success({ name: companyName })
        )
        resetForm()
      },
      onError: onErrorAuto(),
      refetchQueries: ['Bootstrap'],
    }
  )

  const onSubmit = useCallback(
    (data: CreateCompanyForm) => {
      void createCompany({ variables: { name: data.name } })
    },
    [createCompany]
  )

  return (
    <Wrapper>
      <h3>{translations.createCompany}</h3>
      <CardWrapper>
        <Card>
          <Form preventDefault onSubmit={submitHandler(onSubmit)}>
            <FormField>
              <label>{translations.form.name}</label>
              <Input
                name={'name'}
                width={'100%'}
                value={formValues.name}
                onChange={handler('name')}
                error={errors.name}
              />
            </FormField>
            <Margin top={'1rem'}>
              <Button disabled={loading || !formValid} type={'submit'}>
                {loading ? 'Loading...' : translations.form.submit}
              </Button>
            </Margin>
          </Form>
        </Card>
      </CardWrapper>
    </Wrapper>
  )
}

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Module, PermissionRow, Segment } from '../types'
import { useTranslatePermission } from '../util'

const Wrapper = styled.div`
  margin-top: 1rem;

  background-color: white;
  box-shadow: ${props => props.theme.layout.defaultCardShadow};
`
const Header = styled.div`
  display: flex;
  align-items: center;

  padding: 0 1rem;
  height: 64px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  span {
    text-transform: capitalize;

    &.module {
      color: ${props => props.theme.colors.gray3};
    }
    &.segment {
      margin-right: 0.5rem;
      font-weight: 600;
    }
  }
`
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;

  div {
    justify-self: center;

    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 14px;

    span.method {
      margin-right: 0.5rem;
    }
  }
`

interface UserTypePermissionCardProps {
  permission: PermissionRow
  translations: {
    view: string
    add: string
    edit: string
    delete: string
  }
}

export const UserTypePermissionCard: React.FC<UserTypePermissionCardProps> = ({
  permission,
  translations,
}) => {
  const { translateSegment, translateModule } = useTranslatePermission()

  function renderText(index: number) {
    switch (index) {
      case 0:
        return translations.view
      case 1:
        return translations.add
      case 2:
        return translations.edit
      default:
        return translations.delete
    }
  }

  const { segment, module } = useMemo(() => {
    const segment =
      translateSegment(
        permission.segment.replaceAll(' ', '').toLowerCase() as Segment
      ) ?? permission.segment
    const module =
      translateModule(permission.module as Module) ?? permission.module

    return { segment, module }
  }, [permission.module, permission.segment, translateModule, translateSegment])

  return (
    <Wrapper>
      <Header>
        <span className="segment">{segment}</span>

        <span className="module">({module})</span>
      </Header>

      <Content>
        {permission.permissions.map((perm, index) => (
          <div key={index}>
            {perm.key !== null && (
              <>
                <span className="method">{renderText(index)}</span>
                {perm}
              </>
            )}
          </div>
        ))}
      </Content>
    </Wrapper>
  )
}

import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField, Input, NumberInput } from 'components'
import { ProfilePicture } from 'modules/users/components'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'
import { usePostalCode } from 'util/hooks/usePostalCode'
import {
  validateEmail,
  validatePhoneNumber,
  validatePositiveNumber,
} from 'util/validation'
import { EditCompanyForm } from '..'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    'logo    logo'
    'name    name'
    'email   email'
    'phone   phone'
    'rate    rate'
    'address address'
    'zip     city'
    'save    save';
  gap: 1rem;
`
const ProfilePictureWrapper = styled(FormField).attrs({
  area: 'logo',
})`
  ${props => props.theme.media.mobile} {
    display: flex;
    justify-content: center;

    padding-top: 1rem;
  }
`
const SaveField = styled(FormField).attrs({
  area: 'save',
})`
  margin-top: 1rem;
`

interface InformationProps {
  onUploadLogo: (evt: React.ChangeEvent<HTMLInputElement> | null) => void
  onFormSubmit: (form: EditCompanyForm) => Promise<void>
}

export const Information: React.FC<InformationProps> = ({
  onUploadLogo,
  onFormSubmit,
}) => {
  const translations = useTranslate({
    save: 'common.save',

    form: {
      name: 'common.name',
      email: 'common.email',
      phoneNumber: 'common.phone-number',
      address: 'common.address',
      postalCode: 'users.postal-code',
      city: 'users.city',
      rate: 'common.default-hourly-rate',
    },

    validation: {
      invalidPhoneNumber: 'errors.invalid-phone-number',
      invalidEmail: 'errors.invalid-email-address',
      invalidNumber: 'errors.invalid-number-must-be-positive',
      required: 'common.required',
    },
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const company = useCompany()

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
    formEdited,
    enableValidation,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EditCompanyForm>({
    values: {
      fullName: company.fullName,
      email: company.email ?? '',
      phoneNumber: company.phoneNumber ?? '',
      address: company.address ?? '',
      postalCode: company.postalCode ?? '',
      postalArea: company.postalArea ?? '',
      defaultHourlyRate: company.defaultHourlyRate,
    },
    validators: {
      email: val =>
        !val ? null : validateEmail(translations.validation.invalidEmail)(val),
      phoneNumber: val =>
        !val
          ? null
          : validatePhoneNumber(translations.validation.invalidPhoneNumber)(
              val
            ),
      defaultHourlyRate: validatePositiveNumber(
        translations.validation.required
      ),
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['email', 'phoneNumber'],
    },
  })

  const { loading: cityLoading } = usePostalCode(form.postalCode, {
    onCityChange(postalArea) {
      updateForm({ postalArea: postalArea ?? '' })
    },
  })

  function handleSubmit(values: typeof form) {
    onFormSubmit(values).finally(() => updateInitialValues(values))
  }

  return (
    <Wrapper>
      <ProfilePictureWrapper>
        <ProfilePicture
          src={company.companyLogo}
          alt={company.fullName}
          full
          onRemove={() => onUploadLogo(null)}
          onRequestChange={() => fileInputRef.current?.click()}
        />

        <input
          ref={fileInputRef}
          hidden
          type="file"
          accept="image/*"
          onChange={onUploadLogo}
        />
      </ProfilePictureWrapper>

      <FormField area="name">
        <label>{translations.form.name}</label>
        <Input
          value={form.fullName}
          error={errors.fullName}
          fullWidth
          onChange={handler('fullName')}
        />
      </FormField>

      <FormField area="email">
        <label>{translations.form.email}</label>
        <Input
          value={form.email}
          error={errors.email}
          inputMode="email"
          fullWidth
          onBlur={() => enableValidation('email')}
          onChange={handler('email')}
        />
      </FormField>

      <FormField area="phone">
        <label>{translations.form.phoneNumber}</label>
        <Input
          value={form.phoneNumber}
          error={errors.phoneNumber}
          inputMode="tel"
          fullWidth
          onBlur={() => enableValidation('phoneNumber')}
          onChange={handler('phoneNumber')}
        />
      </FormField>

      <FormField area="rate">
        <label>{translations.form.rate}</label>
        <NumberInput
          value={form.defaultHourlyRate}
          error={errors.defaultHourlyRate}
          min={0}
          step={10}
          fullWidth
          onChange={handler('defaultHourlyRate')}
        />
      </FormField>

      <FormField area="address">
        <label>{translations.form.address}</label>
        <Input
          value={form.address}
          error={errors.address}
          fullWidth
          onChange={handler('address')}
        />
      </FormField>

      <FormField area="zip">
        <label>{translations.form.postalCode}</label>
        <Input
          value={form.postalCode}
          error={errors.postalCode}
          fullWidth
          onChange={handler('postalCode')}
        />
      </FormField>

      <FormField area="city">
        <label>{translations.form.city}</label>
        <Input
          value={form.postalArea}
          error={errors.postalArea}
          fullWidth
          loading={cityLoading}
          onChange={handler('postalArea')}
        />
      </FormField>

      <SaveField>
        <Button
          disabled={!formValid || !formEdited}
          fullWidth
          onClick={submit(handleSubmit)}
        >
          {translations.save}
        </Button>
      </SaveField>
    </Wrapper>
  )
}

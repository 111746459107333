import { GoogleMap, Marker } from '@react-google-maps/api'
import { IconProps } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { ActionButton } from 'components'
import isEqual from 'lodash/isEqual'
import xor from 'lodash/xor'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CustomerInfoFields, FullCustomer } from '.'
import { CustomerProjectCard } from './components'
import { CustomerElementArea } from './components/CustomerElementCard'
import { CustomerInfoCard } from './components/CustomerInfoCard'
import { ShallowCustomerProject } from './types.graphql'

const Tabs = styled.nav`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  font-weight: 600;
  color: ${props => props.theme.colors.gray3};
  background-color: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`
interface TabProps {
  active: boolean
}
const Tab = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  padding: 1.2rem 0;

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.primary};
      background-color: white;
    `};
`
const CustomerInformation = styled.div`
  display: grid;
  padding: 1rem 0;
  grid-template-areas:
    'info'
    'billing'
    'contact'
    'map';

  grid-template-rows: auto auto auto 200px;

  gap: 1rem;
`
const Project = styled.div`
  display: grid;
  padding: 1rem 0;
  grid-template-areas:
    'ONGOING'
    'PLANNING'
    'BILLING'
    'FINISHED';

  grid-template-rows: repeat(auto, 4);

  gap: 1rem;
`
const MapCard = styled.div`
  grid-area: map;

  height: 100%;
  width: 100%;

  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

type CustomerTab = 'info' | 'projects'
interface LatLng {
  lat: number
  lng: number
}

interface CustomerMobileProps {
  customer: FullCustomer
  projects: ShallowCustomerProject[]
  fields: CustomerInfoFields
  latlng: LatLng

  onCreateClick: () => void
}

export const CustomerMobile: React.FC<CustomerMobileProps> = ({
  customer,
  projects,
  fields,
  latlng,

  onCreateClick,
}) => {
  const translations = useTranslate({
    billing: 'common.billing',
    contactPerson: 'customers.contact-person',
    generalInformation: 'customers.general-information',
    information: 'common.information',

    address: 'common.address',
    organizationNumber: 'customers.org-nr',
    postalArea: 'customers.postal-area',

    defaultDiscount: 'common.default-discount',
    defaultHourlyRate: 'common.default-hourly-rate',
    email: 'common.email',

    name: 'common.name',
    phoneNumber: 'common.phone-number',

    projects: 'common.projects',
    createProject: 'projects.create',

    hide: 'common.hide',
    show: 'common.show',

    results: {
      queryError: 'server.general-error-try-again-later',
    },

    stages: {
      billing: 'projects.stage-billing',
      finished: 'projects.stage-finished',
      ongoing: 'projects.stage-ongoing',
      planning: 'projects.stage-planning',
    },
  })

  const prevHeaderIcon = useRef<IconProps | null>(null)

  const canEditCustomer = usePermission(PERMISSIONS.customers.change.customer)

  const history = useHistory()
  const [, setHeaderIcon] = useGlobal('header.icon')

  const [tab, setTab] = useState<CustomerTab>('info')
  const [cardsOpen, setCardsOpen] = useState<CustomerElementArea[]>([
    'info',
    'billing',
    'contact',
    'ONGOING',
  ])

  const newHeaderIcon = useMemo<IconProps>(
    () => ({
      icon: 'edit',
      onClick: () => history.push(`/customers/${customer.id}/edit`),
    }),
    [customer.id, history]
  )

  useEffect(() => {
    if (
      !canEditCustomer ||
      prevHeaderIcon.current !== null ||
      isEqual(prevHeaderIcon.current, newHeaderIcon)
    )
      return

    setHeaderIcon(newHeaderIcon)
    prevHeaderIcon.current = newHeaderIcon
  }, [canEditCustomer, newHeaderIcon, setHeaderIcon])

  useEffect(() => {
    const unregister = history.listen(loc => {
      if (loc.pathname.startsWith(`/customers/${customer.id}`)) return
      setHeaderIcon(null)
    })

    return unregister
  }, [customer.id, history, setHeaderIcon])

  function toggleCard(card: CustomerElementArea) {
    return () => setCardsOpen(v => xor(v, [card]))
  }

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  }

  return (
    <div>
      <Tabs>
        <Tab active={tab === 'info'} onClick={() => setTab('info')}>
          {translations.information}
        </Tab>

        <Tab active={tab === 'projects'} onClick={() => setTab('projects')}>
          {translations.projects}
        </Tab>
      </Tabs>

      {tab === 'info' ? (
        <CustomerInformation>
          <CustomerInfoCard
            area="info"
            closed={!cardsOpen.includes('info')}
            fields={fields.info}
            onToggleOpen={toggleCard('info')}
          />

          <MapCard>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={latlng}
              zoom={15}
            >
              {<Marker position={latlng} />}
            </GoogleMap>
          </MapCard>

          <CustomerInfoCard
            area="billing"
            closed={!cardsOpen.includes('billing')}
            fields={fields.billing}
            onToggleOpen={toggleCard('billing')}
          />

          <CustomerInfoCard
            area="contact"
            closed={!cardsOpen.includes('contact')}
            fields={fields.contact}
            onToggleOpen={toggleCard('contact')}
          />
        </CustomerInformation>
      ) : (
        <Project>
          <ActionButton onClick={onCreateClick} />

          <CustomerProjectCard
            stage="PLANNING"
            projects={projects}
            closed={!cardsOpen.includes('PLANNING')}
            onToggleOpen={toggleCard('PLANNING')}
          />
          <CustomerProjectCard
            stage="BILLING"
            projects={projects}
            closed={!cardsOpen.includes('BILLING')}
            onToggleOpen={toggleCard('BILLING')}
          />
          <CustomerProjectCard
            stage="ONGOING"
            projects={projects}
            closed={!cardsOpen.includes('ONGOING')}
            onToggleOpen={toggleCard('ONGOING')}
          />
          <CustomerProjectCard
            stage="FINISHED"
            projects={projects}
            closed={!cardsOpen.includes('FINISHED')}
            onToggleOpen={toggleCard('FINISHED')}
          />
        </Project>
      )}
    </div>
  )
}

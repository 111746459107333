import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import React from 'react'
import styled from 'styled-components'
import { useDateFns } from 'util/hooks'
import { OfferStatus } from './OfferStatus'
import { ShallowOffer } from '../types.graphql'

const Wrapper = styled.div`
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;

    &.menus {
      justify-content: space-between;
      padding: 0 0.5rem 0 1rem;
    }
  }
`
const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;

  header {
    font-size: 18px;
    font-weight: 600;
  }
  p {
    margin: 0;
  }
`

const Footer = styled.footer`
  display: flex;
  padding: 0.8rem 1rem;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 1px 0 0 0;
  color: ${props => props.theme.colors.gray3};
  white-space: nowrap;
`

interface OfferCardProps {
  offer: ShallowOffer
  statusMenu: TableMenu
  editDeleteMenu: TableMenu
  onTitleClick: (offerId: string) => Promise<void>
}

export const OfferCard: React.FC<OfferCardProps> = ({
  offer,
  statusMenu,
  editDeleteMenu,
  onTitleClick,
}) => {
  const { format } = useDateFns()

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(offer.createdAt), 'PPP')}</DateField>

        <div className="menus">
          <Menu icon={<OfferStatus offer={offer} />} config={statusMenu} />

          <Menu icon="ellipsis-h" config={editDeleteMenu} />
        </div>
      </Header>

      <Content>
        <header onClick={() => onTitleClick(offer.id)}>{offer.title}</header>
      </Content>

      <Footer>
        <strong>{'NOK: ' + offer.totalCost.toFixed(2)}</strong>
      </Footer>
    </Wrapper>
  )
}

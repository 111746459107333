import { CompanyNode } from './types.graphql'

const edges = { __typename: '', edges: [] }

export const COMPANY_PLACEHOLDER: CompanyNode = {
  __typename: 'CompanyNode',
  id: '',
  shortName: '',
  fullName: '',

  companyLogo: null,
  createdAt: '',
  updatedAt: '',

  email: '',
  phoneNumber: '',
  address: '',
  postalCode: '',
  postalArea: '',

  defaultHourlyRate: 0,

  settings: [],
  userTypes: [],
  users: edges,
  absences: edges,
  deviations: edges,
  checklistTemplates: edges,
  customers: edges,
  deviationCategories: edges,
  folderSettings: edges,
  manualFolders: edges,
  projects: edges,
}

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { ellipsis } from 'util/formatting'
import { useOnErrorAuto, useToast } from 'util/hooks'
import { AbsenceForm } from './components/AbsenceForm'
import { PATCH_ABSENCE_MUTATION } from './mutations'
import { ABSENCE_QUERY } from './queries'
import {
  AbsenceQuery,
  PatchAbsenceMutation,
  PatchAbsenceMutationVariables,
} from './types.graphql'
import { isPatchAbsenceMutationVariables } from './util'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding: 1rem 1rem 2rem;
  }
`

interface EditAbsenceProps {}

export const EditAbsence: React.FC<EditAbsenceProps> = () => {
  const translations = useTranslate({
    editAbsenceFor: ['timesheets.edit-absence-for-name', { name: '' }],
    editSuccess: 'timesheets.toasts.edit-absence-success',
    editAbsence: 'timesheets.edit-absence',
  })

  const { absenceId } = useParams<{ absenceId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const { loading, data } = useQuery<AbsenceQuery, IdVariable>(ABSENCE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: absenceId,
    },
    onCompleted(data) {
      setOverrides({
        [absenceId]: ellipsis(data?.absence.reason ?? absenceId),
        edit: translations.editAbsence,
      })
    },
    onError: onErrorAuto(),
  })

  const absence = useMemo(() => data?.absence, [data])

  const [patchAbsence, { loading: patchLoading }] = useMutation<
    PatchAbsenceMutation,
    PatchAbsenceMutationVariables
  >(PATCH_ABSENCE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['Bootstrap', 'AbsenceQuery'],
    onCompleted() {
      addToast('success', translations.editSuccess)
      history.push(`/absences/`)
      mixpanel.track('Edit Absence')
    },
    onError: onErrorAuto(),
  })

  const isLoading = loading || patchLoading

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader
          title={translations.editAbsenceFor({
            name: absence?.employee.fullName,
          })}
          loading={loading}
        />
      )}

      <AbsenceForm
        absence={absence}
        loading={isLoading}
        onSubmit={variables =>
          isPatchAbsenceMutationVariables(variables) &&
          patchAbsence({ variables })
        }
      />
    </Wrapper>
  )
}

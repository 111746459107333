import { useMutation } from '@apollo/client'
import { Icon, SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { formatPhoneNumber } from 'util/formatting'
import {
  useConfirm,
  useOnErrorAuto,
  usePermissions,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { CreateEditEmergencyContact, ProfileCard } from '.'
import {
  CreateUserEmergencyContactMutation,
  CreateUserEmergencyContactMutationVariables,
  DeleteEmergencyContactMutation,
  PatchUserEmergencyContactMutation,
  PatchUserEmergencyContactMutationVariables,
  SetPrimaryEmergencyContactMutationVariables,
  ShallowUser,
  UserEmergencyContactRelation,
} from '..'
import {
  CREATE_USER_EMERGENCY_CONTACT_MUTATION,
  DELETE_USER_EMERGENCY_CONTACT_MUTATION,
  PATCH_USER_EMERGENCY_CONTACT_MUTATION,
  SET_PRIMARY_USER_EMERGENCY_CONTACT_MUTATION,
} from '../mutations'
import { USER_SHALLOW_QUERY } from '../queries'

const EmergencyContact = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'name         edit'
    'phoneNumber  phoneNumber'
    'primary      primary'
    'relation     relation';
  row-gap: 0.3rem;
  padding: 1rem 1.5rem 1rem 2.75rem;

  border-bottom: 1px solid ${props => props.theme.colors.gray6};

  h3 {
    grid-area: name;
    margin: 0;
    font-size: 16px;

    color: ${props => props.theme.colors.gray1};
    font-weight: 600;
  }
  div.menu {
    grid-area: edit;
  }
  a {
    grid-area: phoneNumber;
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
    padding-right: 0.5rem;
  }
`
const ContactType = styled.div<{ area: 'primary' | 'relation' }>`
  grid-area: ${props => props.area};
  font-size: 12px;
  color: ${props => props.theme.colors.gray3};
`
const CreateContactButton = styled(Button)`
  padding-left: 2.75rem;
  color: ${props => props.theme.colors.gray3};
  background-color: white;

  div.--button-content {
    justify-content: flex-start;
    padding-left: 0;
  }

  &:hover {
    color: white;

    i {
      color: white;
    }
  }

  ${props => props.theme.media.desktop} {
    border-radius: 0 0 8px 8px;
  }
  ${props => props.theme.media.mobile} {
    padding-left: 1rem;
  }
`

interface EmergencyContactsProps {
  user: ShallowUser
  loading: boolean
}

export const EmergencyContacts: React.FC<EmergencyContactsProps> = ({
  user,
  loading,
}) => {
  const translations = useTranslate({
    emergencyContacts: 'common.emergency-contacts',
    addEmergencyContacts: 'common.add-emergency-contact',
    primary: 'users.primary-contact',

    prompt: {
      deleteEmergencyContact: 'users.prompts.delete-emergency-contact',
      deleteEmergencyContactTitle:
        'users.prompts.delete-emergency-contact-title',
    },

    menu: {
      edit: 'common.edit',
      delete: 'common.delete',
      setPrimary: 'common.set-primary',
    },

    relations: {
      parent: 'users.relations.parent',
      child: 'users.relations.child',
      sibling: 'users.relations.sibling',
      spouse: 'users.relations.spouse',
      partner: 'users.relations.partner',
      friend: 'users.relations.friend',
      other: 'users.relations.other',

      select: 'users.relations.select-relation',
    },

    results: {
      createSuccess: 'users.toasts.create-emergency-contact-success',
      createError: 'users.toasts.create-emergency-contact-error',

      patchSuccess: 'users.toasts.edit-emergency-contact-success',
      patchError: 'users.toasts.edit-emergency-contact-error',

      deleteSuccess: 'users.toasts.delete-emergency-contact-success',
      deleteError: 'users.toasts.delete-emergency-contact-error',

      primaryContactError: 'users.toasts.error-changing-primary-contact',
      primaryContactSuccess: 'users.toasts.primary-contact-changed',

      uniqueConstraint: 'errors.name-must-be-unique',
    },
  })

  const wasDeletedRef = useRef(false)

  const addToast = useToast()
  const confirm = useConfirm()
  const onErrorAuto = useOnErrorAuto()
  const { hasPermissionsOrMe } = usePermissions()

  const canAddContact = useMemo(
    () => hasPermissionsOrMe(user, PERMISSIONS.users.add.useremergencycontact),
    [hasPermissionsOrMe, user]
  )

  const [open, setOpen] = useState(false)
  const [patchOpen, setPatchOpen] = useState<string | null>(null)

  const [createEmergencyContact] = useMutation<
    CreateUserEmergencyContactMutation,
    CreateUserEmergencyContactMutationVariables
  >(CREATE_USER_EMERGENCY_CONTACT_MUTATION, {
    refetchQueries: [{ query: USER_SHALLOW_QUERY, variables: { id: user.id } }],
    onCompleted() {
      addToast('success', translations.results.createSuccess)
      setOpen(false)
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueConstraint,
    }),
  })

  const [patchEmergencyContact, { loading: patchLoading }] = useMutation<
    PatchUserEmergencyContactMutation,
    PatchUserEmergencyContactMutationVariables
  >(PATCH_USER_EMERGENCY_CONTACT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: USER_SHALLOW_QUERY, variables: { id: user.id } }],
    onCompleted() {
      addToast('success', translations.results.patchSuccess)
    },
    onError: onErrorAuto({
      uniqueConstraint: translations.results.uniqueConstraint,
    }),
  })

  const [deleteEmergencyContact] = useMutation<
    DeleteEmergencyContactMutation,
    IdVariable
  >(DELETE_USER_EMERGENCY_CONTACT_MUTATION, {
    refetchQueries: [{ query: USER_SHALLOW_QUERY, variables: { id: user.id } }],
    onCompleted() {
      addToast('success', translations.results.deleteSuccess)
    },
    onError: onErrorAuto(translations.results.deleteError),
  })

  const [setPrimaryEmergencyContact] = useMutation<
    PatchUserEmergencyContactMutation,
    SetPrimaryEmergencyContactMutationVariables
  >(SET_PRIMARY_USER_EMERGENCY_CONTACT_MUTATION, {
    refetchQueries: [{ query: USER_SHALLOW_QUERY, variables: { id: user.id } }],
    onCompleted() {
      addToast('success', translations.results.primaryContactSuccess)
    },
    onError: onErrorAuto(translations.results.primaryContactError),
  })

  const handleDeleteEmergencyContact = useCallback(
    async (id: string) => {
      const answer = await confirm(
        translations.prompt.deleteEmergencyContact,
        translations.prompt.deleteEmergencyContactTitle,
        { variant: 'delete' }
      )

      if (answer) {
        wasDeletedRef.current = true
        deleteEmergencyContact({
          variables: {
            id: id,
          },
        })
      }
    },
    [
      confirm,
      deleteEmergencyContact,
      translations.prompt.deleteEmergencyContact,
      translations.prompt.deleteEmergencyContactTitle,
    ]
  )

  const contactMenu = useCallback(
    (contactId: string): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.menu.edit,
          hide: !hasPermissionsOrMe(
            user,
            PERMISSIONS.users.change.useremergencycontact
          ),
          onClick() {
            setPatchOpen(contactId)
          },
        },
        {
          icon: 'heart',
          text: translations.menu.setPrimary,
          hide: !hasPermissionsOrMe(
            user,
            PERMISSIONS.users.change.useremergencycontact
          ),
          onClick() {
            setPrimaryEmergencyContact({
              variables: { id: contactId, user: user.id },
            })
          },
        },
        {
          icon: 'trash',
          text: translations.menu.delete,
          hide: !hasPermissionsOrMe(
            user,
            PERMISSIONS.users.delete.useremergencycontact
          ),
          color: '#f39b9b',
          hoverColor: '#e38080',
          onClick() {
            handleDeleteEmergencyContact(contactId)
          },
        },
      ],
    }),
    [
      handleDeleteEmergencyContact,
      hasPermissionsOrMe,
      setPrimaryEmergencyContact,
      translations.menu.delete,
      translations.menu.edit,
      translations.menu.setPrimary,
      user,
    ]
  )

  const emergencyContacts = useMemo(
    () =>
      user.emergencyContacts.edges
        .map(edge => edge.node)
        .sort(a => (a.primary ? -1 : 0)) ?? [],
    [user]
  )

  const emergencyContactRelations = useMemo<
    SelectOption<UserEmergencyContactRelation>[]
  >(
    () => [
      { label: translations.relations.parent, value: 'PARENT' },
      { label: translations.relations.child, value: 'CHILD' },
      { label: translations.relations.sibling, value: 'SIBLING' },
      { label: translations.relations.spouse, value: 'SPOUSE' },
      { label: translations.relations.partner, value: 'PARTNER' },
      { label: translations.relations.friend, value: 'FRIEND' },
      { label: translations.relations.other, value: 'OTHER' },
    ],
    [
      translations.relations.child,
      translations.relations.friend,
      translations.relations.other,
      translations.relations.parent,
      translations.relations.partner,
      translations.relations.sibling,
      translations.relations.spouse,
    ]
  )

  return (
    <ProfileCard area="contact">
      <h2>{translations.emergencyContacts}</h2>

      {emergencyContacts.map(contact => (
        <div key={contact.id}>
          {patchOpen === contact.id ? (
            <CreateEditEmergencyContact
              loading={loading || patchLoading}
              userId={user.id}
              contact={contact}
              relations={emergencyContactRelations}
              onPatch={patchEmergencyContact}
              onClose={() => setPatchOpen(null)}
            />
          ) : (
            <EmergencyContact>
              <h3>{contact.name}</h3>

              <div className="menu">
                <Menu icon="ellipsis-h" config={contactMenu(contact.id)} />
              </div>

              <a href={`tel:${contact.phoneNumber}`}>
                {formatPhoneNumber(contact.phoneNumber)}
              </a>

              {contact.primary && (
                <ContactType area="primary">{translations.primary}</ContactType>
              )}

              <ContactType area="relation">
                {
                  emergencyContactRelations.find(
                    rel => rel.value === contact.relation
                  )?.label
                }
              </ContactType>
            </EmergencyContact>
          )}
        </div>
      ))}

      {!canAddContact ? null : open ? (
        <CreateEditEmergencyContact
          loading={loading || patchLoading}
          userId={user.id}
          relations={emergencyContactRelations}
          onCreate={createEmergencyContact}
          onClose={() => setOpen(v => !v)}
        />
      ) : (
        <CreateContactButton onClick={() => setOpen(v => !v)}>
          {translations.addEmergencyContacts}

          <Icon
            icon="plus"
            color="gray3"
            margin="0 0 0 0.8em"
            translateY="2px"
          />
        </CreateContactButton>
      )}
    </ProfileCard>
  )
}

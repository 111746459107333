import { gql } from '@apollo/client'

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`

export const PATCH_CUSTOMER_MUTATION = gql`
  mutation PatchCustomer($id: ID!, $input: PatchCustomerInput!) {
    patchCustomer(id: $id, input: $input) {
      customer {
        id
      }
    }
  }
`

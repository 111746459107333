import { ObservableQueryFields } from '@apollo/client'
import { useCallback, useState } from 'react'
import { RelayPageInfo } from 'types/graphql'
import {
  SelectUserQuery,
  SelectUserQueryVariables,
  UsersTableQuery,
  UsersTableQueryVariables,
} from '.'

export function useUsersPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreUsers: ObservableQueryFields<
    UsersTableQuery,
    UsersTableQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreUsers({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreUsers])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useSelectUserPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreUsers: ObservableQueryFields<
    SelectUserQuery,
    SelectUserQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreUsers({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreUsers])

  return {
    fetchMore,
    loading,
  }
}

import { Login, ForgottenPassword } from 'modules/login'
import { RegisterCompany } from 'modules/registercompany'
import React from 'react'
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'containers/Routing'

export const NoAuthRoutes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/register-company" component={RegisterCompany} />
        <Redirect from="" to="/login" />
      </Switch>
    </Router>
  )
}

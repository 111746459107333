import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import {
  CenteredLoader,
  IconButton,
  Message,
  PageHeader,
  PageHeaderButton,
} from 'components'
import { PageFooter } from 'components/PageFooter/PageFooter'
import React, { useCallback, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { openPrint } from 'util/api'
import {
  useDateFns,
  useOnErrorAuto,
  usePermission,
  usePromptString,
  useToast,
  useUser,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { getTimeZone } from 'util/time'
import { validateEmail } from 'util/validation'
import {
  ChecklistItem,
  ChecklistItems,
  ChecklistPurpose,
  ChecklistStatus,
} from './components'
import {
  CLOSE_CHECKLIST_MUTATION,
  PATCH_CHECKLIST_MUTATION,
  SEND_CHECKLIST_EMAIL_MUTATION,
} from './mutations'
import { CHECKLIST_QUERY } from './queries'
import {
  ChecklistQuery,
  CloseChecklistMutation,
  CloseChecklistMutationVariables,
  ListChecklistItem,
  PatchChecklistMutation,
  PatchChecklistMutationVariables,
  SendChecklistEmailMutation,
  SendChecklistEmailMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }

  ${props => props.theme.media.mobile} {
    margin-bottom: 6rem;
    padding-left: 0;
    padding-right: 0;
  }
`

const ModifyDateWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.colors.gray3};
  flex-shrink: 1;

  div.title {
    font-weight: 600;
  }

  ${props => props.theme.media.mobile} {
    font-size: 14px;
  }
`

const MobileSubHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 2rem;
  align-items: center;

  margin: 1rem;
`

interface ProgressProps {
  finished: boolean
}

const Progress = styled.div<ProgressProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 1.8rem;

  font-weight: 600;
  color: ${props =>
    props.finished
      ? props.theme.colors.matteGreen
      : props.theme.colors.matteOrange};
`

const ContentWrapper = styled.div`
  ${props => props.theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'purpose  checklist-item'
      'items    checklist-item';

    grid-gap: 2rem;
    margin-top: 1rem;
  }

  ${props => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`

interface ChecklistProps {}

export const Checklist: React.FC<ChecklistProps> = () => {
  const translations = useTranslate({
    lastChanged: 'common.modified',
    send: 'common.send',
    invalid: 'common.invalid',

    ongoing: 'projects.stage-ongoing',
    finished: 'projects.stage-finished',

    buttons: {
      save: 'checklists.submit-checklist',
      download: 'checklists.download-pdf',
      send: 'common.send-to-email',
      reopen: 'checklists.reopen-checklist',
    },
    prompts: {
      sendEmail: 'checklists.prompts.send-email',
      sendEmailTitle: 'checklists.prompts.send-email-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',
      sentSuccess: 'checklists.toasts.checklist-sent',
      reopenedSuccess: 'checklists.toasts.checklist-reopened',

      sendEmailSuccess: 'checklists.success.send-email',
      sendEmailError: 'checklists.errors.send-email',
    },
  })

  const { checklistId, projectId } =
    useParams<{ checklistId: string; projectId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const { format } = useDateFns()
  const me = useUser()
  const addToast = useToast()
  const promptEmail = usePromptString({
    confirmText: translations.send,
    validate: validateEmail(translations.invalid),
  })

  const [item, setItem] = useState<ListChecklistItem | null>(null)

  const canEditChecklist = usePermission(
    PERMISSIONS.checklists.change.checklist
  )

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery<ChecklistQuery, IdVariable>(CHECKLIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id: checklistId },
    onCompleted(data) {
      setOverrides(v => ({
        [projectId]: data?.checklist.project.name,
        [checklistId]: data?.checklist.title,
        ...v,
      }))
    },
    onError: onErrorAuto(),
  })
  const checklist = useMemo(() => data?.checklist ?? null, [data])

  const [reopenChecklist, { loading: reopenLoading }] = useMutation<
    PatchChecklistMutation,
    PatchChecklistMutationVariables
  >(PATCH_CHECKLIST_MUTATION, {
    refetchQueries: ['Checklist'],
    onCompleted() {
      addToast('success', translations.results.reopenedSuccess)
      mixpanel.track('Checklist Reopened')
    },
    onError: onErrorAuto(),
  })

  const [closeChecklist, { loading: closeLoading }] = useMutation<
    CloseChecklistMutation,
    CloseChecklistMutationVariables
  >(CLOSE_CHECKLIST_MUTATION, {
    refetchQueries: ['Checklist'],
    onCompleted() {
      addToast('success', translations.results.sentSuccess)
      mixpanel.track('Checklist Closed')
    },
    onError: onErrorAuto(),
  })

  const [sendEmail, { loading: sendEmailLoading }] = useMutation<
    SendChecklistEmailMutation,
    SendChecklistEmailMutationVariables
  >(SEND_CHECKLIST_EMAIL_MUTATION, {
    onCompleted({ sendChecklistEmail: { emailSent } }) {
      if (!emailSent) {
        addToast('error', translations.results.sendEmailError)
      } else {
        addToast('success', translations.results.sendEmailSuccess)
        mixpanel.track('Checklist Sent To Email')
      }
    },
    onError: onErrorAuto(translations.results.sendEmailError),
  })

  const handleToggleChecklist = useCallback(() => {
    if (!checklist) return

    if (!checklist.closed) {
      closeChecklist({
        variables: {
          id: checklistId,
          sendEmail: true,
        },
      })
    } else {
      reopenChecklist({
        variables: {
          id: checklistId,
          input: {
            closedAt: null,
            closedBy: null,
          },
        },
      })
    }
  }, [checklist, checklistId, closeChecklist, reopenChecklist])

  const handlePrint = useCallback(() => {
    mixpanel.track('Checklist Printed')
    return openPrint(
      `/checklists/print-checklist?checklist_id=${checklistId}&creator_id=${me.id}`
    )
  }, [checklistId, me.id])

  const handleSendEmail = useCallback(async () => {
    const recipient = await promptEmail(
      translations.prompts.sendEmail,
      translations.prompts.sendEmailTitle
    )
    if (!recipient) return

    void sendEmail({
      variables: {
        id: checklistId,
        recipient,
        tz: getTimeZone(),
      },
    })
  }, [
    checklistId,
    promptEmail,
    sendEmail,
    translations.prompts.sendEmail,
    translations.prompts.sendEmailTitle,
  ])

  const isLoading = queryLoading || reopenLoading || closeLoading

  const toggleButton = useMemo<PageHeaderButton | null>(
    () =>
      !checklist
        ? null
        : {
            text: checklist.closed
              ? translations.buttons.reopen
              : translations.buttons.save,
            hide: !canEditChecklist,
            side: 'left',
            disabled:
              (!checklist.closed &&
                checklist.progress[0] !== checklist.progress[1]) ||
              isLoading,
            background: 'primary',
            onClick: handleToggleChecklist,
          },
    [
      canEditChecklist,
      checklist,
      handleToggleChecklist,
      isLoading,
      translations.buttons.reopen,
      translations.buttons.save,
    ]
  )

  const headerButtons = useMemo<PageHeaderButton[]>(
    () =>
      !checklist || !toggleButton
        ? []
        : [
            toggleButton,
            {
              text: translations.buttons.download,
              icon: { icon: 'download', type: 'solid', color: 'white' },
              role: 'link',
              onClick: handlePrint,
            },
            {
              text: translations.buttons.send,
              icon: { icon: 'paper-plane', type: 'solid', color: 'white' },
              loading: sendEmailLoading,
              onClick: handleSendEmail,
            },
          ],
    [
      checklist,
      handlePrint,
      handleSendEmail,
      sendEmailLoading,
      toggleButton,
      translations.buttons.download,
      translations.buttons.send,
    ]
  )

  const modifiedAt = useMemo(
    () => (
      <ModifyDateWrapper>
        <span className="title">{translations.lastChanged + ': '}&nbsp;</span>

        <span>
          {!!checklist && format(new Date(checklist.modifiedDate), 'PPP')}
        </span>
      </ModifyDateWrapper>
    ),
    [checklist, format, translations.lastChanged]
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (!checklist) return <CenteredLoader />

  return (
    <Wrapper>
      {!isMobileOnly ? (
        <>
          <PageHeader
            title={
              <>
                {checklist.title}

                <ChecklistStatus
                  progress={checklist.progress}
                  closed={checklist.closed}
                  smallText
                />
              </>
            }
            loading={isLoading}
            noBottomMargin
            buttons={headerButtons}
          />

          {modifiedAt}
        </>
      ) : (
        <MobileSubHeader>
          <Progress finished={checklist.closed}>
            {checklist.closed ? translations.finished : translations.ongoing}

            <ChecklistStatus
              progress={checklist.progress}
              closed={checklist.closed}
              smallText
            />
          </Progress>

          <IconButton icon="download" role="link" onClick={handlePrint} />

          <IconButton icon="paper-plane" onClick={handleSendEmail} />
        </MobileSubHeader>
      )}

      <ContentWrapper>
        <ChecklistPurpose checklist={checklist} />

        <ChecklistItems
          checklist={checklist}
          selectedItem={item}
          onItemClick={it =>
            !isMobileOnly
              ? setItem(it)
              : it.id === item?.id
              ? setItem(null)
              : setItem(it)
          }
        />

        {!isMobileOnly && !!item && <ChecklistItem key={item.id} item={item} />}
      </ContentWrapper>

      {isMobileOnly && toggleButton && (
        <PageFooter modifiedAt={modifiedAt} buttons={[toggleButton]} />
      )}
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Icon, IconProps, usePrompt } from '@ur/react-components'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { DropdownNav, Message, NavItem, PageHeader } from 'components'
import isEqual from 'lodash/isEqual'
import { ChecklistsTab } from 'modules/checklists/ChecklistsTab'
import { ProjectDeviations } from 'modules/deviations'
import { ProjectFiles } from 'modules/files'
import { Offers } from 'modules/offers'
import { TimesheetTab } from 'modules/timesheets'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Redirect, Switch } from 'containers/Routing'
import { CrumbOverrides } from 'store'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { shortenNumber } from 'util/formatting'
import {
  useBreadcrumbOverrides,
  useCompany,
  useOnErrorAuto,
  usePermission,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { ProjectRoute } from './components'
import {
  CreateEditProjectModal,
  CreateEditProjectModalResolve,
} from './CreateEditProjectModal'
import { PATCH_PROJECT_MUTATION } from './mutations'
import { PROJECT_INFO_QUERY, PROJECT_NAME_AND_AMOUNT_QUERY } from './queries'
import { ProjectInfo } from './tabs'
import {
  PatchProjectMutation,
  PatchProjectMutationVariables,
  ProjectNameAndAmountQuery,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
`
const Tabs = styled.nav`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;

  a {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 42px;
    padding: 0 2rem;

    color: ${props => props.theme.colors.darkGrayText};
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrape;

    &:first-child {
      box-shadow: 4px -1 15px rgba(0, 0, 0, 0.05);
      border-radius: 8px 0 0 0;
    }
    &:last-child {
      border-radius: 0 8px 0 0;
    }
    &.active {
      background-color: white;
      color: ${props => props.theme.colors.primary};
    }
    &:not(.active) {
      border: 1px solid ${props => props.theme.colors.gray6};
      border-bottom: 0px;
      background-color: #fafafa;
      box-shadow: inset 0 -4px 4px -4px rgba(0, 0, 0, 0.05);
    }

    i {
      margin-right: 0.5rem;
    }
  }
  ${props => props.theme.media.mobile} {
    grid-template-columns: 1fr 1fr;
    a {
      height: 56px;
      border-radius: 0 0 0 0;
    }
  }
`

interface ProjectProps {}

export const Project: React.FC<ProjectProps> = () => {
  const translations = useTranslate({
    info: 'common.information',
    offers: 'offers.offers',
    timeSpent: 'timesheets.spent-hours',
    deviations: 'common.deviations',
    checklists: 'common.checklists',
    files: 'common.files',

    nHours: ['common.n-hours', { n: '0' }],

    results: {
      queryError: 'server.general-error-try-again-later',

      editError: 'projects.errors.edit',
      editSuccess: 'projects.edit-success',
    },
  })

  const prevHeaderIcon = useRef<IconProps | null>(null)

  const onErrorAuto = useOnErrorAuto()
  const canEditProject = usePermission(PERMISSIONS.projects.change.project)
  const history = useHistory()
  const companyDefaultHourlyRate = useCompany()?.defaultHourlyRate
  const { projectId } = useParams<{ projectId: string; folderId?: string }>()

  const addPrompt = usePrompt()
  const addToast = useToast()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const [, setHeaderIcon] = useGlobal('header.icon')

  const [infoLoading, setInfoLoading] = useState(false)
  const [extraBreadcrumbOverrides, setExtraBreadcrumbOverrides] = useState<
    string[]
  >([])

  const { data, loading, error } = useQuery<
    ProjectNameAndAmountQuery,
    IdVariable
  >(PROJECT_NAME_AND_AMOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: projectId,
    },
    onCompleted(data) {
      setOverrides(v => ({
        [projectId]: data.project.name,
        ...v,
      }))
    },
    onError: onErrorAuto(),
  })

  const [patchProjectMutation, { loading: patchLoading }] = useMutation<
    PatchProjectMutation,
    PatchProjectMutationVariables
  >(PATCH_PROJECT_MUTATION, {
    refetchQueries: [
      { query: PROJECT_INFO_QUERY, variables: { id: projectId } },
    ],
    onCompleted() {
      addToast('success', translations.results.editSuccess)
      mixpanel.track('Edit Project', {
        Context: 'Project',
      })
    },
    onError: onErrorAuto(translations.results.editError),
  })

  const handleEditProject = useCallback(
    async (projectId: string) => {
      if (!canEditProject) return

      const { data } = await addPrompt<CreateEditProjectModalResolve | null>(
        resolve => (
          <CreateEditProjectModal
            projectId={projectId}
            companyDefaultHourlyRate={companyDefaultHourlyRate}
            onSubmit={resolve}
          />
        )
      )

      if (!data) return

      patchProjectMutation({
        variables: { id: projectId, input: data },
      })
    },
    [addPrompt, canEditProject, companyDefaultHourlyRate, patchProjectMutation]
  )

  const newHeaderIcon = useMemo<IconProps>(
    () => ({
      icon: 'edit',
      onClick: () => handleEditProject(projectId),
    }),
    [handleEditProject, projectId]
  )

  useEffect(() => {
    if (
      !canEditProject ||
      prevHeaderIcon.current !== null ||
      isEqual(prevHeaderIcon.current, newHeaderIcon)
    )
      return
    prevHeaderIcon.current = newHeaderIcon

    setHeaderIcon(newHeaderIcon)
  }, [canEditProject, newHeaderIcon, setHeaderIcon])

  useEffect(() => {
    const unregister = history.listen(loc => {
      if (loc.pathname.startsWith(`/projects/${projectId}`)) return
      setHeaderIcon(null)
    })

    return unregister
  }, [projectId, history, setHeaderIcon])

  const navMenuOptions = useMemo<NavItem[]>(
    () => [
      {
        id: 'info',
        text: translations.info,
        icon: 'info-circle',
        to: `/projects/${projectId}/info`,
      },
      {
        id: 'offers',
        text: translations.offers + ` (${data?.project.numOffers ?? 0})`,
        icon: { icon: 'receipt', type: 'solid' },
        to: `/projects/${projectId}/offers`,
      },
      {
        id: 'timesheets',
        text: translations.timeSpent + ` (${data?.project.totalHours ?? 0})`,
        icon: 'business-time',
        to: `/projects/${projectId}/timesheets`,
      },
      {
        id: 'deviations',
        text:
          translations.deviations + ` (${data?.project.numDeviations ?? 0})`,
        icon: 'exclamation-triangle',
        to: `/projects/${projectId}/deviations`,
      },
      {
        id: 'checklists',
        text:
          translations.checklists + ` (${data?.project.numChecklists ?? 0})`,
        icon: 'clipboard-list',
        to: `/projects/${projectId}/checklists`,
      },
      {
        id: 'files',
        text: translations.files + ` (${data?.project.totalFiles ?? 0})`,
        icon: 'file',
        to: `/projects/${projectId}/files`,
      },
    ],
    [
      data,
      projectId,
      translations.checklists,
      translations.deviations,
      translations.files,
      translations.info,
      translations.offers,
      translations.timeSpent,
    ]
  )

  const breadcrumbOverrides = useMemo(
    () => ({
      ...navMenuOptions.reduce<CrumbOverrides>(
        (acc, cur) => ({
          [data?.project.id ?? cur.id]: null,
          ...acc,
          [cur.id]: data?.project.name ?? '',
        }),
        {}
      ),
      ...extraBreadcrumbOverrides.reduce<CrumbOverrides>(
        (acc, cur) => ({
          ...acc,
          [cur]: (isMobileOnly ? data?.project.name : null) ?? null,
        }),
        {}
      ),
    }),
    [
      data?.project.id,
      data?.project.name,
      extraBreadcrumbOverrides,
      navMenuOptions,
    ]
  )
  useBreadcrumbOverrides(breadcrumbOverrides, `/projects/${projectId}`)

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading = loading || infoLoading || patchLoading

  return (
    <Wrapper>
      {isMobileOnly ? (
        <div style={{ padding: '0 1rem' }}>
          <DropdownNav items={navMenuOptions} />
        </div>
      ) : (
        <>
          <PageHeader
            title={data?.project.name ?? ''}
            loading={isLoading}
            editPermissions={PERMISSIONS.projects.change.project}
            onEdit={() => handleEditProject(projectId)}
          />

          <Tabs>
            <NavLink exact to={`/projects/${projectId}/info`}>
              <Icon icon="info-circle" type="solid" />
              {translations.info}
            </NavLink>

            <NavLink exact to={`/projects/${projectId}/offers`}>
              <Icon icon="receipt" type="solid" />
              {translations.offers} ({data?.project.numOffers ?? 0})
            </NavLink>

            <NavLink exact to={`/projects/${projectId}/timesheets`}>
              <Icon icon="business-time" type="solid" />
              {translations.timeSpent} (
              {translations.nHours({
                n: shortenNumber(data?.project.totalHours ?? 0),
              })}
              )
            </NavLink>

            <NavLink exact to={`/projects/${projectId}/deviations`}>
              <Icon icon="exclamation-triangle" type="solid" />
              {translations.deviations} ({data?.project.numDeviations ?? 0})
            </NavLink>

            <NavLink exact to={`/projects/${projectId}/checklists`}>
              <Icon icon="clipboard-list" type="solid" />
              {translations.checklists} ({data?.project.numChecklists ?? 0})
            </NavLink>

            <NavLink to={`/projects/${projectId}/files`}>
              <Icon icon="file" type="solid" />
              {translations.files} ({data?.project.totalFiles ?? 0})
            </NavLink>
          </Tabs>
        </>
      )}

      <Switch>
        <ProjectRoute path="/projects/:projectId/info">
          <ProjectInfo onLoading={setInfoLoading} />
        </ProjectRoute>

        <ProjectRoute path="/projects/:projectId/offers">
          <Offers />
        </ProjectRoute>

        <ProjectRoute path="/projects/:projectId/timesheets">
          <TimesheetTab projectId={projectId} />
        </ProjectRoute>

        <ProjectRoute path="/projects/:projectId/checklists">
          <ChecklistsTab projectId={projectId} />
        </ProjectRoute>

        <ProjectRoute exact path="/projects/:projectId/files/checklists">
          <ProjectFiles
            projectId={projectId}
            isChecklistFolders
            onOverrideBreadcrumbs={setExtraBreadcrumbOverrides}
          />
        </ProjectRoute>

        <ProjectRoute
          exact
          path="/projects/:projectId/files/checklists/:folderId"
        >
          <ProjectFiles
            projectId={projectId}
            isChecklistImages
            onOverrideBreadcrumbs={setExtraBreadcrumbOverrides}
          />
        </ProjectRoute>

        <ProjectRoute exact path="/projects/:projectId/files">
          <ProjectFiles projectId={projectId} />
        </ProjectRoute>

        <ProjectRoute exact path="/projects/:projectId/files/:folderId">
          <ProjectFiles
            projectId={projectId}
            onOverrideBreadcrumbs={setExtraBreadcrumbOverrides}
          />
        </ProjectRoute>

        <ProjectRoute path="/projects/:projectId/deviations">
          <ProjectDeviations projectId={projectId} />
        </ProjectRoute>

        <Redirect from="/projects/:projectId/" to="/projects/:projectId/info" />
      </Switch>
    </Wrapper>
  )
}

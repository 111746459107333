import { Icon } from '@ur/react-components'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ZIndexRange } from 'types/style'
import { UserGuideSection } from './sections'

const sharedCss = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  font-size: 1.2rem;
  font-weight: 600;

  i {
    margin-bottom: -2px;
  }
  span.title {
    padding: 1.1rem 1.5rem;
    white-space: nowrap;
  }
`

const Wrapper = styled.nav`
  position: relative;
  width: 100%;
`
interface ActiveItemProps {
  open: boolean
}
const ActiveItem = styled.div.attrs({
  role: 'button',
})<ActiveItemProps>`
  ${sharedCss}
  justify-content: space-between;

  color: white;
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.sizes.defaultBorderRadius};

  span.left {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  i.chevron {
    margin-right: 1rem;
    transition: transform 0.3s ease;
    transform: rotateX(${props => (props.open ? '180deg' : '0deg')});
    transform-origin: 0% 40%;
  }
`
const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  z-index: ${ZIndexRange.Dropdowns};
  width: 100%;

  border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  box-shadow: ${props => props.theme.layout.defaultShadow};
`
interface DropdownItemProps {
  indent: number
}
const DropdownItem = styled.div<DropdownItemProps>`
  ${sharedCss}
  flex-direction: column;
  align-items: flex-start;
  gap: 0;

  background-color: white;

  & > span.title {
    margin-left: ${props => props.indent * 1}rem;
  }

  border-top: 1px solid ${props => props.theme.colors.gray6};

  &:first-of-type {
    border-top-left-radius: ${props => props.theme.sizes.defaultBorderRadius};
    border-top-right-radius: ${props => props.theme.sizes.defaultBorderRadius};
  }
  &:last-of-type {
    border-bottom-left-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
    border-bottom-right-radius: ${props =>
      props.theme.sizes.defaultBorderRadius};
  }
`

interface ItemProps {
  section: UserGuideSection
  chapter: string
  indent: number

  onClick: (section: UserGuideSection, chapter: string) => void
}
const Item: React.FC<ItemProps> = ({
  section,
  chapter,
  indent,

  onClick,
}) => {
  return (
    <DropdownItem
      key={section.id}
      className="item"
      indent={indent}
      onClick={() => onClick(section, chapter)}
    >
      <span className="title">{chapter + '. ' + section.title}</span>

      {section.subSections?.map((sub, idx) => (
        <Item
          key={sub.id}
          section={sub}
          chapter={`${chapter}.${idx + 1}`}
          indent={indent + 1}
          onClick={onClick}
        />
      ))}
    </DropdownItem>
  )
}

interface MobileDropdownProps {
  sections: UserGuideSection[]

  activeSection: UserGuideSection
  activeChapter: string

  onChangeActiveSection: (section: UserGuideSection, chapter: string) => void
}

export const MobileDropdown: React.FC<MobileDropdownProps> = ({
  sections,

  activeSection,
  activeChapter,

  onChangeActiveSection,
}) => {
  if (sections.length === 0)
    throw new Error('DropdownNav must have at least one item')

  const [open, setOpen] = useState(false)

  function handleClick(section: UserGuideSection, chapter: string) {
    onChangeActiveSection(section, chapter)
    setOpen(false)
  }

  return (
    <Wrapper>
      <ActiveItem open={open} onClick={() => setOpen(v => !v)}>
        <span className="left title">
          {activeChapter + '. ' + activeSection.title}
        </span>

        <Icon
          className="chevron"
          icon="chevron-down"
          type="solid"
          size="1.4rem"
        />
      </ActiveItem>

      {open && (
        <Dropdown>
          {sections.map((section, idx) =>
            section.id === activeSection.id ? null : (
              <Item
                key={section.id}
                section={section}
                chapter={'' + (idx + 1)}
                indent={0}
                onClick={handleClick}
              />
            )
          )}
        </Dropdown>
      )}
    </Wrapper>
  )
}

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useLanguage } from 'locale/TranslationProvider'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { changeCompany } from 'util/auth'
import { useCompany, useUser } from 'util/hooks'
import { renderLanguage } from './util'

const Wrapper = styled.aside`
  position: absolute;
  top: calc(100% + 24px);
  right: 0;

  width: 250px;

  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  a {
    text-decoration: none;
    color: inherit;
  }
`

interface UserProfileNavItemProps {
  open?: boolean
  logout?: boolean
}
const UserProfileNavItem = styled.div<UserProfileNavItemProps>`
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-columns: 40px 1fr auto;
  margin: 8px 0;
  padding: 10px 20px;

  font-weight: 600;
  color: ${props =>
    props.logout ? props.theme.colors.logoutRed : props.theme.colors.gray2};
  cursor: pointer;

  &:hover {
    color: ${props =>
      props.logout
        ? props.theme.colors.error
        : props.theme.colors.primaryHover};
  }

  ${props =>
    props.open &&
    css`
      color: ${props.theme.colors.primary};
      background-color: ${props.theme.colors.navBackground};
      border-radius: 0 18px 18px 0;
    `}
`
interface SubMenuItemProps {
  active: boolean
}
const SubMenuItem = styled(UserProfileNavItem)<SubMenuItemProps>`
  margin: 0;
  padding-left: 60px;

  border: solid ${props => props.theme.colors.gray6};
  border-width: 0 0 1px 0;
  white-space: nowrap;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  &:first-of-type {
    margin-top: -8px;
  }

  ${props =>
    props.active &&
    css`
      &::before {
        content: '\f111';
        position: absolute;
        left: 54px;
        top: 50%;
        transform: translate(-100%, -50%);

        font-family: 'Font Awesome 5 Pro';
        font-size: 0.4rem;
        font-weight: 900;
      }
    `};
`
interface UserOptionsProps {
  onClose: () => void
}

export const UserOptions: React.FC<UserOptionsProps> = ({ onClose }) => {
  const translations = useTranslate({
    editProfile: 'common.edit-profile',
    language: 'common.language',
    changeCompany: 'common.change-company',
    logOut: 'common.logout',
    showProfile: 'common.show-profile',
  })

  const user = useUser()
  const company = useCompany()
  const companies = useMemo(
    () => user.companies.edges.map(edge => edge.node),
    [user]
  )

  const [language, setLanguage] = useLanguage()
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false)
  const [companyMenuOpen, setCompanyMenuOpen] = useState(false)

  return (
    <Wrapper>
      <Link to={`/users/${user.id}`} onClick={onClose}>
        <UserProfileNavItem>
          <Icon icon="external-link-alt" type="solid" />
          {translations.showProfile}
        </UserProfileNavItem>
      </Link>

      <Link to={`/users/${user.id}/edit`} onClick={onClose}>
        <UserProfileNavItem>
          <Icon icon="user-cog" type="solid" />
          {translations.editProfile}
        </UserProfileNavItem>
      </Link>

      <UserProfileNavItem
        open={languageMenuOpen}
        onClick={() => setLanguageMenuOpen(v => !v)}
      >
        <Icon icon="globe" />

        {translations.language + ': ' + renderLanguage(language)}

        <Icon icon={languageMenuOpen ? 'chevron-up' : 'chevron-down'} />
      </UserProfileNavItem>

      {languageMenuOpen && (
        <div>
          <SubMenuItem
            active={language === 'en'}
            onClick={() => setLanguage('en')}
          >
            {renderLanguage('en')}
          </SubMenuItem>

          <SubMenuItem
            active={language === 'nb'}
            onClick={() => setLanguage('nb')}
          >
            {renderLanguage('nb')}
          </SubMenuItem>
        </div>
      )}

      {companies.length > 1 && (
        <UserProfileNavItem
          open={companyMenuOpen}
          onClick={() => setCompanyMenuOpen(v => !v)}
        >
          <Icon icon="building" />

          {translations.changeCompany}

          <Icon icon={companyMenuOpen ? 'chevron-up' : 'chevron-down'} />
        </UserProfileNavItem>
      )}

      {companyMenuOpen && (
        <div>
          {companies.map(comp => (
            <SubMenuItem
              key={comp.id}
              active={comp.id === company.id}
              onClick={() => changeCompany(comp.shortName)}
            >
              {comp.fullName}
            </SubMenuItem>
          ))}
        </div>
      )}
      {user.isSuperuser && (
        <Link to="/admin" onClick={onClose}>
          <UserProfileNavItem>
            <Icon icon="tools" type="solid" />
            Admin
          </UserProfileNavItem>
        </Link>
      )}

      <Link to="/logout">
        <UserProfileNavItem logout>
          <Icon icon="sign-out" type="solid" />
          {translations.logOut}
        </UserProfileNavItem>
      </Link>
    </Wrapper>
  )
}

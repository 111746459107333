import { createRoot } from 'react-dom/client'

import './index.css'
import './normalize.css'
import 'assets/style/digitechcustomicons/style.css'
import 'modules/mixpanel/init'
import Root from './containers/Root'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { isLocal, STAGE } from 'util/env'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<Root />)

if (!isLocal()) {
  Sentry.init({
    dsn: 'https://82962803b33b4cae87c6ce69cd72241c@sentry.io/1880672',
    environment: STAGE,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}

if (module.hot) {
  module.hot.accept('./containers/Root', () => {
    const NextRoot = require('./containers/Root').default
    root.render(<NextRoot />)
  })
}

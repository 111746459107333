import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface StatusProp {
  status: DeviationStatusType
}

const Wrapper = styled.div<StatusProp>`
  display: flex;
  align-items: center;

  white-space: nowrap;
  color: ${props =>
    props.status === 'closed'
      ? props.theme.colors.matteTeal
      : props.status === 'underTreatment'
      ? props.theme.colors.matteOrange
      : props.status === 'archived'
      ? props.theme.colors.matteGreen
      : props.theme.colors.gray2};

  font-size: 1rem;
  font-weight: 400;

  ${props => props.theme.media.mobile} {
    font-size: 0.9rem;

    span {
      margin-bottom: -1px;
    }
  }
`

export type DeviationStatusType = 'underTreatment' | 'closed' | 'archived'

interface DeviationStatusProps {
  isActive: boolean
  isTreated: boolean
}

export const DeviationStatus: React.FC<DeviationStatusProps> = ({
  isActive,
  isTreated,
}) => {
  const translations = useTranslate({
    archived: 'common.archived',
    closed: 'common.closed',
    underTreatment: 'common.under-treatment',
  })

  const status: DeviationStatusType = !isActive
    ? 'archived'
    : isTreated
    ? 'closed'
    : 'underTreatment'

  const translatedStatus = useMemo(
    () => translations[status],
    [status, translations]
  )

  return (
    <Wrapper status={status}>
      <Icon
        icon="circle"
        type="solid"
        size="8px"
        margin="0 6px 0 0"
        translateY="2px"
      />

      <span>{translatedStatus}</span>
    </Wrapper>
  )
}

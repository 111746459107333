export const LOGIN_TOKEN_KEY = 'login-token'
export const PREVIOUS_COMPANY_KEY = 'prev-company'

export function setLoginToken(token: string, remember: boolean) {
  ;(remember ? localStorage : sessionStorage).setItem(LOGIN_TOKEN_KEY, token)
}

export function hasSavedLoginToken() {
  return !!localStorage.getItem(LOGIN_TOKEN_KEY)
}

export function getLoginToken() {
  return (
    sessionStorage.getItem(LOGIN_TOKEN_KEY) ??
    localStorage.getItem(LOGIN_TOKEN_KEY)
  )
}

export function removeLoginToken() {
  sessionStorage.removeItem(LOGIN_TOKEN_KEY)
  localStorage.removeItem(LOGIN_TOKEN_KEY)
}

export function setCompany(shortName: string) {
  localStorage.setItem(PREVIOUS_COMPANY_KEY, shortName)
}

export function changeCompany(shortName: string) {
  setCompany(shortName)
  window.location.href = `/c/${shortName}`
}

export function getPreviousCompany() {
  return localStorage.getItem(PREVIOUS_COMPANY_KEY)
}

import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  DateRange,
  DateRangePicker,
  FetchMoreLoader,
  Message,
  PageHeader as BasePageHeader,
  PageHeaderIconButton,
  TableFiltering,
  useDateRangeQuickie,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import { endOfDay } from 'date-fns'
import { ProjectSelectModal } from 'modules/projects/components/ProjectSelectModal'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { openPrint } from 'util/api'
import {
  useCompany,
  useConfirm,
  useDateFns,
  useOnErrorAuto,
  usePromptString,
  useToast,
  useUser,
} from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { getTimeZone } from 'util/time'
import { validateEmail } from 'util/validation'
import { SEND_TIMESHEET_EMAIL_MUTATION } from './mutations'
import { TIME_ENTRIES_QUERY } from './queries'
import { TimesheetMobile } from './TimesheetMobile'
import { TimesheetTable } from './TimesheetTable'
import {
  SendTimesheetEmailMutation,
  SendTimesheetEmailMutationVariables,
  TimesheetEntriesQuery,
  TimesheetEntriesQueryVariables,
} from './types.graphql'
import {
  useDeleteTimesheet,
  useTimesheetFiltering,
  useTimesheetMenu,
  useTimesheetsPagination,
} from './util'
import mixpanel from 'mixpanel-browser'

interface WrapperProps {
  isProjectView: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${props => (props.isProjectView ? '0 1rem 1rem' : '0')};

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

interface PageHeaderProps {
  isProjectView: boolean
}

const PageHeader = styled(BasePageHeader)<PageHeaderProps>`
  padding-top: 0;
  margin: ${props => props.isProjectView && '0'};
`

const PAGE_SIZE = 25

interface TimesheetTabProps {
  projectId?: string
}

export const TimesheetTab: React.FC<TimesheetTabProps> = ({ projectId }) => {
  const translations = useTranslate({
    registerTime: 'dashboard.registerHours',
    search: 'deviations.search',

    print: 'common.print',
    sendToEmail: 'common.send-to-email',
    send: 'common.send',
    invalid: 'common.invalid',

    dateRange: {
      today: 'common.today',
      yesterday: 'common.yesterday',
      thisWeek: 'common.this-week',
      lastWeek: 'common.last-week',
      thisMonth: 'common.this-month',
      lastMonth: 'common.last-month',
      thisYear: 'common.this-year',
      lastYear: 'common.last-year',
      allTime: 'common.all-time',
    },
    prompts: {
      includeAbsences: 'timesheets.prompts.include-absences',
      includeAbsencesTitle: 'timesheets.prompts.include-absences-title',

      no: 'common.no',
      yes: 'common.yes',

      sendEmail: 'timesheets.prompts.send-email',
      sendEmailTitle: 'timesheets.prompts.send-email-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',

      sendEmailSuccess: 'timesheets.success.send-email',
      sendEmailError: 'timesheets.errors.send-email',
    },
  })

  const isProjectView = !!projectId
  const hasLaunchedModalByRouteStateRef = useRef(false)

  const location = useLocation<{ create?: boolean } | undefined>()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const { startOfWeek, endOfWeek, format } = useDateFns()
  const company = useCompany()
  const me = useUser()
  const addPrompt = usePrompt()
  const promptEmail = usePromptString({
    confirmText: translations.send,
    validate: validateEmail(translations.invalid),
  })
  const confirm = useConfirm()
  const addToast = useToast()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('')
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>(
    !isProjectView
      ? {
          from: startOfWeek(new Date()),
          to: endOfWeek(new Date()),
        }
      : {
          from: new Date(1970, 0, 1),
          to: endOfDay(new Date()),
        }
  )
  const [selectedProject, setSelectedProject] = useState<string | null>(null)
  const [selectedUser, setSelectedUser] = useState<string | null>(null)

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore: fetchMoreEntries,
  } = useQuery<TimesheetEntriesQuery, TimesheetEntriesQueryVariables>(
    TIME_ENTRIES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        first: PAGE_SIZE,
        orderBy: sort,
        project: projectId ?? selectedProject,
        userId: selectedUser,
        dateGte: format(dateRange.from, 'yyyy-MM-dd'),
        dateLte: format(dateRange.to, 'yyyy-MM-dd'),
      },
      onCompleted() {
        setHasLoadedOnce(true)
      },
      onError: onErrorAuto(),
    }
  )

  const [sendEmail, { loading: sendEmailLoading }] = useMutation<
    SendTimesheetEmailMutation,
    SendTimesheetEmailMutationVariables
  >(SEND_TIMESHEET_EMAIL_MUTATION, {
    onCompleted({ sendTimesheetEmail: { emailSent } }) {
      if (emailSent) addToast('success', translations.results.sendEmailSuccess)
      else addToast('error', translations.results.sendEmailError)
    },
    onError: onErrorAuto(translations.results.sendEmailError),
  })

  const [, , dateText] = useDateRangeQuickie(dateRange)

  const { projectFilter, userFilter } = useTimesheetFiltering()

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useTimesheetsPagination(data?.allTimeEntries.pageInfo, fetchMoreEntries)

  const { deleteTimesheet: handleDeleteTimesheet, loading: deleteLoading } =
    useDeleteTimesheet()

  const makeMenu = useTimesheetMenu(handleDeleteTimesheet, {
    edit: timesheet =>
      `/projects/${timesheet.project.id}/timesheets/${timesheet.id}/edit/`,
  })

  useInfiniteScroll(handleFetchMore)

  const entries = useMemo(
    () => data?.allTimeEntries.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const hasEntries = useMemo(() => !!data?.hasTimeEntries, [data])

  const getPdfArguments = useCallback(async () => {
    const includeAbsences = await confirm(
      translations.prompts.includeAbsences,
      translations.prompts.includeAbsencesTitle,
      {
        confirmText: translations.prompts.yes,
        denyText: translations.prompts.no,
      }
    )

    const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

    return {
      project: projectId ?? selectedProject,
      user: selectedUser,
      dateFrom: formatDate(dateRange.from),
      dateTo: formatDate(dateRange.to),
      includeAbsences,
    }
  }, [
    confirm,
    dateRange.from,
    dateRange.to,
    format,
    projectId,
    selectedProject,
    selectedUser,
    translations.prompts.includeAbsences,
    translations.prompts.includeAbsencesTitle,
    translations.prompts.no,
    translations.prompts.yes,
  ])

  const handlePrint = useCallback(async () => {
    if (!company) return

    const args = await getPdfArguments()
    let url = `/timesheets/print-timesheet?company=${company.shortName}&creator_id=${me.id}`

    if (args.project) url += `&project_id=${args.project}`
    if (args.user) url += `&user_id=${selectedUser}`
    url += `&date_from=${args.dateFrom}`
    url += `&date_to=${args.dateTo}`
    if (args.includeAbsences) url += `&include_absences=1`

    openPrint(url)
  }, [company, getPdfArguments, me.id, selectedUser])

  const handleSendEmail = useCallback(async () => {
    const recipient = await promptEmail(
      translations.prompts.sendEmail,
      translations.prompts.sendEmailTitle
    )
    if (!recipient) return

    const args = await getPdfArguments()
    sendEmail({
      variables: {
        recipient,
        projectId: args.project,
        userId: args.user,
        dateFrom: args.dateFrom,
        dateTo: args.dateTo,
        includeAbsences: args.includeAbsences,
        tz: getTimeZone(),
      },
    })
  }, [
    getPdfArguments,
    promptEmail,
    sendEmail,
    translations.prompts.sendEmail,
    translations.prompts.sendEmailTitle,
  ])

  const iconButtons = useMemo<PageHeaderIconButton[]>(
    () => [
      {
        side: 'left',
        icon: 'calendar-alt',
        text: dateText,
        children: (
          <DateRangePicker
            value={dateRange}
            show={datePickerOpen}
            top="calc(100% + 1rem)"
            left="-6rem"
            onClose={() => setDatePickerOpen(false)}
            onChange={setDateRange}
          />
        ),
        onClick: () => setDatePickerOpen(true),
      },
      {
        side: 'right',
        icon: 'print',
        text: translations.print,
        onClick: handlePrint,
      },
      {
        side: 'right',
        icon: 'envelope',
        text: translations.sendToEmail,
        loading: sendEmailLoading,
        onClick: handleSendEmail,
      },
    ],
    [
      dateText,
      dateRange,
      datePickerOpen,
      translations.print,
      translations.sendToEmail,
      handlePrint,
      sendEmailLoading,
      handleSendEmail,
    ]
  )

  const handleCreateTimeEntry = useCallback(async () => {
    let project = projectId
    if (!project) {
      const { data: id } = await addPrompt<string | null>(resolve => (
        <ProjectSelectModal onSubmit={resolve} />
      ))

      mixpanel.track('Select Project', {
        Context: 'Create time entry',
      })
      if (!id) return
      project = id
    }

    history.push(`/projects/${project}/timesheets/create`)
  }, [addPrompt, history, projectId])

  useLayoutEffect(() => {
    if (hasLaunchedModalByRouteStateRef.current || !location.state) return
    hasLaunchedModalByRouteStateRef.current = true

    if (!!location.state.create) handleCreateTimeEntry()
  }, [handleCreateTimeEntry, location.state])

  function handleFilterChange(value: TableFiltering) {
    const checked = value.checked[0]
    if (!checked) {
      value.column === 'projectName'
        ? setSelectedProject(null)
        : setSelectedUser(null)
      return
    }

    value.column === 'projectName'
      ? setSelectedProject(checked)
      : setSelectedProject(null)

    value.column === 'user' ? setSelectedUser(checked) : setSelectedUser(null)
  }

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading = queryLoading || deleteLoading || fetchMoreLoading

  return (
    <EmptyPage
      module="timesheets"
      empty={!hasEntries && !isProjectView}
      loading={!hasLoadedOnce}
      isTabView
      buttonPermissions={PERMISSIONS.timesheets.add.timeentry}
      onButtonClick={() => handleCreateTimeEntry()}
    >
      <Wrapper isProjectView={isProjectView}>
        {isMobileOnly ? (
          <TimesheetMobile
            entries={entries}
            dateRange={dateRange}
            projectFilter={projectFilter}
            userFilter={userFilter}
            makeMenu={makeMenu}
            loading={isLoading}
            hideProject={isProjectView}
            onCreateTimeEntry={() => handleCreateTimeEntry()}
            onFilterChange={handleFilterChange}
            onUpdateDateRange={setDateRange}
          />
        ) : (
          <>
            <PageHeader
              isProjectView={isProjectView}
              loading={isLoading}
              buttons={[
                {
                  text: translations.registerTime,
                  icon: 'plus',
                  permissions: PERMISSIONS.timesheets.add.timeentry,
                  onClick: () => handleCreateTimeEntry(),
                },
              ]}
              iconButtons={iconButtons}
              tabMargin={!isProjectView}
            />

            <TimesheetTable
              entries={entries}
              projectFilter={projectFilter}
              userFilter={userFilter}
              makeMenu={makeMenu}
              excludeColumns={
                !isProjectView ? [] : ['projectName', 'customerName']
              }
              onSortingChange={setSort}
              onFilterChange={handleFilterChange}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

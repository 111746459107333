import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { DeviationForm } from '../components/DeviationForm'
import { DeviationPromptState } from '../types'
import { CreateDeviationMutationVariables } from '../types.graphql'
import { isCreateDeviationMutationVariables } from '../util'

interface CreateNewDeviationModalProps {
  id: string
  projectId?: string
  onSubmit: PromptResolve<CreateDeviationMutationVariables | null>
}
export const CreateDeviationModal: React.FC<CreateNewDeviationModalProps> = ({
  id,
  projectId,
  onSubmit,
}) => {
  const translations = useTranslate({
    createDeviation: 'deviations.create-deviation',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const state = usePromptState<DeviationPromptState>(id)

  function handleSubmit(values: CreateDeviationMutationVariables) {
    onSubmit(values)
  }

  return (
    <RegularModal
      title={translations.createDeviation}
      width="100%"
      onClose={() => onSubmit(null)}
    >
      <DeviationForm
        projectId={projectId}
        loading={state.loading}
        noCard
        onSubmit={variables =>
          isCreateDeviationMutationVariables(variables) &&
          handleSubmit(variables)
        }
      />
    </RegularModal>
  )
}

import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField, Input } from 'components'
import React from 'react'
import styled from 'styled-components'
import { validateNonEmpty, validatePassword } from 'util/validation'
import { ChangePasswordMutation } from '.'
import { InfoCard } from './components'

const Wrapper = styled(InfoCard)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${props => props.theme.media.desktop} {
    min-width: 400px;
  }
`

export interface ChangePasswordForm {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

interface ChangePasswordProps {
  loading: boolean

  onSubmit: (data: ChangePasswordForm) => Promise<ChangePasswordMutation | null>
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  loading,

  onSubmit,
}) => {
  const translations = useTranslate({
    changePassword: 'common.change-password',

    currentPassword: 'users.current-password',
    newPassword: 'users.new-password',
    confirmPassword: 'users.password-confirm',

    required: 'common.required',
    passwordUnequal: 'users.passwords-do-not-match',
    weakPassword: 'users.errors.change-password-weak',

    save: 'common.save',
  })

  const {
    formValues: form,
    formErrors: errors,
    formChangeHandler: handler,
    formValid,
    enableValidation,
    resetForm,
    submitHandler,
  } = useForm<ChangePasswordForm>({
    values: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validators: {
      currentPassword: validateNonEmpty(translations.required),
      newPassword: validatePassword(translations.weakPassword),
      confirmPassword: validatePassword(
        translations.weakPassword,
        'newPassword',
        translations.passwordUnequal
      ),
    },
    config: {
      initAsInvalid: true,
      onlyValidateAfterFirstUpdate: true,
      disableValidationInitially: ['newPassword', 'confirmPassword'],
    },
  })

  async function handleSubmit(values: typeof form) {
    if (values.newPassword !== values.confirmPassword) return

    await onSubmit(values)
    resetForm()
  }

  return (
    <Wrapper title={translations.changePassword} loading={loading}>
      <FormField>
        <label>{translations.currentPassword}</label>
        <Input
          value={form.currentPassword}
          error={errors.currentPassword}
          type="password"
          fullWidth
          onChange={handler('currentPassword')}
        />
      </FormField>

      <FormField>
        <label>{translations.newPassword}</label>
        <Input
          value={form.newPassword}
          error={errors.newPassword}
          type="password"
          fullWidth
          onBlur={() => enableValidation('newPassword')}
          onChange={handler('newPassword')}
        />
      </FormField>

      <FormField>
        <label>{translations.confirmPassword}</label>
        <Input
          value={form.confirmPassword}
          error={errors.confirmPassword}
          type="password"
          fullWidth
          onBlur={() => enableValidation('confirmPassword')}
          onChange={handler('confirmPassword')}
        />
      </FormField>

      <Button
        className="submit"
        disabled={!formValid}
        onClick={submitHandler(handleSubmit)}
      >
        {translations.save}
      </Button>
    </Wrapper>
  )
}

import { SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField as BaseFormField, Input, Select } from 'components'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { validateNonEmpty, validatePhoneNumber } from 'util/validation'
import {
  CreateUserEmergencyContactMutationVariables,
  PatchUserEmergencyContactMutationVariables,
  ShallowUserEmergencyContact,
  UserEmergencyContactRelation,
} from '../types.graphql'

const Wrapper = styled.div``

const AddEmergencyContact = styled.div<{ isEdit?: boolean }>`
  padding: 1rem 3rem 0;

  ${props =>
    props.isEdit &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.gray6};
    `}

  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding-bottom: 1rem;
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;
  }
`

const FormField = styled(BaseFormField)`
  & + div {
    margin-top: 1.5rem;

    ${props => props.theme.media.mobile} {
      margin-top: 1rem;
    }
  }
`
interface EmergencyContactForm {
  name: string
  relation: UserEmergencyContactRelation | null
  phoneNumber: string
}

interface CreateEditEmergencyContactProps {
  loading: boolean
  userId: string
  contact?: ShallowUserEmergencyContact
  relations: SelectOption<UserEmergencyContactRelation>[]

  onCreate?: (variables: {
    variables: CreateUserEmergencyContactMutationVariables
  }) => void
  onPatch?: (variables: {
    variables: PatchUserEmergencyContactMutationVariables
  }) => void
  onClose: () => void
}

export const CreateEditEmergencyContact: React.FC<CreateEditEmergencyContactProps> =
  ({ loading, userId, contact, relations, onCreate, onPatch, onClose }) => {
    const contactInserted = useRef(false)

    const translations = useTranslate({
      add: 'common.add',
      cancel: 'common.cancel',
      save: 'common.save',

      form: {
        name: 'common.name',
        phoneNumber: 'common.phone-number',
        relation: 'common.relation',
        selectRelation: 'users.relations.select-relation',
      },

      validation: {
        invalidPhoneNumber: 'errors.invalid-phone-number',
        required: 'common.required',
      },
    })

    const {
      formValues: form,
      formErrors: errors,
      formEdited,
      formValid,
      updateForm,
      updateInitialValues,
      enableValidation,
      formChangeHandler: handler,
      submitHandler,
      resetForm,
    } = useForm<EmergencyContactForm>({
      values: {
        name: '',
        relation: null,
        phoneNumber: '',
      },
      validators: {
        name: validateNonEmpty(translations.validation.required),
        relation: validateNonEmpty(translations.validation.required),
        phoneNumber: [
          validateNonEmpty(translations.validation.required),
          validatePhoneNumber(translations.validation.invalidPhoneNumber),
        ],
      },
      config: {
        initAsInvalid: true,
        disableValidationInitially: ['phoneNumber'],
      },
    })

    useEffect(() => {
      if (!contact || contactInserted.current) return
      contactInserted.current = true

      const values: typeof form = {
        name: contact.name,
        relation: contact.relation,
        phoneNumber: contact.phoneNumber,
      }

      updateForm(values)
      updateInitialValues(values)
    }, [contact, updateForm, updateInitialValues])

    function handleSubmit(values: EmergencyContactForm) {
      if (!values.relation) return

      if (contactInserted.current && !!contact && !!onPatch)
        onPatch({
          variables: {
            id: contact.id,
            input: {
              user: userId,
              relation: values.relation,
              name: values.name,
              phoneNumber: values.phoneNumber,
            },
          },
        })
      else if (!!onCreate)
        onCreate({
          variables: {
            input: {
              user: userId,
              relation: values.relation,
              name: values.name,
              phoneNumber: values.phoneNumber,
            },
          },
        })
      onClose()
    }

    function handleSubmitError(err: typeof errors) {
      console.error(err)
    }

    return (
      <Wrapper>
        <AddEmergencyContact isEdit={!!contact}>
          <FormField required error={!!errors.name}>
            <label>{translations.form.name}</label>
            <Input
              name="name"
              value={form.name}
              error={errors.name}
              disabled={loading}
              autoFocus
              fullWidth
              onChange={handler('name')}
            />
          </FormField>

          <FormField required error={!!errors.relation}>
            <label>{translations.form.relation}</label>
            <Select
              value={form.relation}
              placeholder={translations.form.selectRelation}
              options={relations}
              fullWidth
              onChange={value => {
                if (!value) return
                updateForm(
                  {
                    ...form,
                    relation: value,
                  },
                  { runValidation: false }
                )
              }}
            />
          </FormField>

          <FormField required error={!!errors.phoneNumber}>
            <label>{translations.form.phoneNumber}</label>
            <Input
              name="phoneNumber"
              value={form.phoneNumber}
              error={errors.phoneNumber}
              inputMode="tel"
              disabled={loading}
              fullWidth
              onBlur={() => enableValidation('phoneNumber')}
              onChange={handler('phoneNumber')}
            />
          </FormField>

          <div className="buttons">
            <Button
              background="gray3"
              onClick={() => {
                onClose()
                resetForm()
              }}
            >
              {translations.cancel}
            </Button>

            <Button
              disabled={loading || !formEdited || !formValid}
              onClick={submitHandler(handleSubmit, handleSubmitError)}
            >
              {!!contact ? translations.save : translations.add}
            </Button>
          </div>
        </AddEmergencyContact>
      </Wrapper>
    )
  }

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  Button,
  TableMenu,
  FormField as BaseFormField,
  Input,
} from 'components'
import { Menu } from 'components/Table/TableMenu'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useCompany } from 'util/hooks'

const Wrapper = styled.div``

const FormField = styled(BaseFormField)`
  margin-top: 1rem;

  label {
    font-size: 0.8rem;
  }
`

interface FolderProps {
  isInactive?: boolean
}
const Folder = styled.div<FolderProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  font-weight: 600;

  padding: 0.8rem 0;
  gap: 0.5rem;

  width: calc(100% + 2rem);

  color: ${props => props.isInactive && props.theme.colors.gray4};

  i {
    width: 1rem;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
  ${props => props.theme.media.mobile} {
    width: 100%;
    height: 4.5rem;

    padding: 0 1rem;
    background-color: white;

    &:not(:first-of-type) {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }
  }
`
const AddFolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${props => props.theme.media.mobile} {
    padding: 0 1rem 1rem;

    &:not(:first-of-type) {
      border-top: 1px solid ${props => props.theme.colors.gray6};
    }
  }
`
const AddFolderButton = styled.div`
  padding-top: 1rem;
  ${props => props.theme.media.mobile} {
    padding: 0 1rem 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  }
`

const SubmitWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

interface SubmitProps {
  onCancel: () => void
  onSubmit: () => void
}

const Submit: React.FC<SubmitProps> = ({ onCancel, onSubmit }) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    save: 'common.save',
  })

  return (
    <SubmitWrapper>
      <Button fullWidth variant="cancel" onClick={onCancel}>
        {translations.cancel}
      </Button>
      <Button fullWidth onClick={onSubmit}>
        {translations.save}
      </Button>
    </SubmitWrapper>
  )
}

interface CompanyFolder {
  id: string
  name: string
  enabled: boolean
  novel: boolean
  changed: boolean
}

interface FoldersProps {
  onAddFolder: (name: string) => Promise<void>
  onEditFolder: (
    id: string,
    enabled: boolean,
    newName?: string
  ) => Promise<void>
  onDeleteFolder: (id: string) => void
}

export const Folders: React.FC<FoldersProps> = ({
  onAddFolder,
  onEditFolder,
  onDeleteFolder,
}) => {
  const translations = useTranslate({
    addFolder: 'companies.add-folder',
    newFolder: 'files.new-folder',
    newFolderTitle: 'files.folder-name',
    editFolder: 'files.edit-folder',

    active: 'common.active',
    inactive: 'common.inactive',
    edit: 'common.edit',
    delete: 'common.delete',
  })

  const company = useCompany()

  const [addFolder, setAddFolder] = useState(false)
  const [editFolder, setEditFolder] = useState<string | null>(null)
  const [newFolderName, setNewFolderName] = useState('')

  const originalFolders = useMemo<CompanyFolder[]>(
    () =>
      company.folderSettings.edges.map(({ node }) => ({
        id: node.id,
        name: node.folderName,
        enabled: node.enabled,
        novel: false,
        changed: false,
      })) ?? [],
    [company]
  )

  const handleCancel = useCallback(() => {
    setEditFolder(null)
    setAddFolder(false)
    setNewFolderName('')
  }, [])

  const handleAddFolder = useCallback(async () => {
    await onAddFolder(newFolderName)
    handleCancel()
  }, [handleCancel, newFolderName, onAddFolder])

  const handleEditFolder = useCallback(
    async (id: string, enabled: boolean, newName?: string) => {
      await onEditFolder(id, enabled, newName)
      handleCancel()
    },
    [handleCancel, onEditFolder]
  )

  const makeMenu = useCallback(
    (folder: CompanyFolder): TableMenu => ({
      items: [
        {
          icon: folder.enabled
            ? { icon: 'toggle-on', type: 'duotone' }
            : { icon: 'toggle-off', type: 'duotone' },
          text: folder.enabled ? translations.active : translations.inactive,
          color: '#657bff',
          hoverColor: '#304efe',
          closeOnClick: false,
          onClick: () => handleEditFolder(folder.id, !folder.enabled),
        },
        {
          icon: 'edit',
          text: translations.edit,
          onClick: () => {
            setNewFolderName(folder.name)
            setEditFolder(folder.id)
          },
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hoverColor: '#e38080',
          onClick: () => onDeleteFolder(folder.id),
        },
      ],
    }),
    [
      onDeleteFolder,
      handleEditFolder,
      translations.active,
      translations.delete,
      translations.edit,
      translations.inactive,
    ]
  )

  return (
    <Wrapper>
      {originalFolders.map(folder =>
        editFolder === folder.id ? (
          <AddFolder key={folder.id}>
            <FormField>
              <label>{translations.editFolder}</label>

              <Input
                value={newFolderName}
                placeholder={folder.name}
                fullWidth
                autoFocus
                onKeyDown={evt =>
                  evt.key === 'Enter' &&
                  !!newFolderName &&
                  handleEditFolder(folder.id, folder.enabled, newFolderName)
                }
                onChange={setNewFolderName}
              />
            </FormField>

            <Submit
              onCancel={handleCancel}
              onSubmit={() =>
                handleEditFolder(folder.id, folder.enabled, newFolderName)
              }
            />
          </AddFolder>
        ) : (
          <Folder key={folder.id} isInactive={!folder.enabled}>
            {folder.name}

            <Menu
              icon={
                <Icon
                  icon="ellipsis-v"
                  type="regular"
                  size="1.2rem"
                  color="gray2"
                />
              }
              config={makeMenu(folder)}
            />
          </Folder>
        )
      )}

      {addFolder ? (
        <AddFolder>
          <FormField>
            <label>{translations.newFolder}</label>

            <Input
              value={newFolderName}
              placeholder={translations.newFolderTitle}
              fullWidth
              autoFocus
              onKeyDown={evt =>
                evt.key === 'Enter' && !!newFolderName && handleAddFolder()
              }
              onChange={setNewFolderName}
            />
          </FormField>

          <Submit onCancel={handleCancel} onSubmit={handleAddFolder} />
        </AddFolder>
      ) : (
        <AddFolderButton>
          <Button
            className="submit"
            fullWidth
            onClick={() => setAddFolder(true)}
          >
            {translations.addFolder}
          </Button>
        </AddFolderButton>
      )}
    </Wrapper>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { Button, Input } from 'components'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import {
  useConfirm,
  useDateFns,
  useOnErrorAuto,
  usePermission,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import {
  CREATE_DEVIATION_PERFORMED_ACTION_MUTATION,
  DELETE_DEVIATION_PERFORMED_ACTION_MUTATION,
  PATCH_DEVIATION_PERFORMED_ACTION_MUTATION,
} from '../mutations'
import { DEVIATION_PERFORMED_ACTIONS_QUERY } from '../queries'
import {
  CreateDeviationPerformedActionMutation,
  CreateDeviationPerformedActionMutationVariables,
  DeviationPerformedActionsQuery,
  PatchDeviationPerformedActionMutation,
  PatchDeviationPerformedActionMutationVariables,
} from '../types.graphql'

const Wrapper = styled.div``

interface PerformedActionProps {
  editing: boolean
}

const PerformedAction = styled.div<PerformedActionProps>`
  display: grid;
  grid-template-columns: ${props => (props.editing ? '1ft' : '1fr auto')};
  min-height: 2.5rem;

  div.action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    padding: 0 1.25rem;

    background-color: ${props => props.theme.colors.gray8};
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.hover};

      i {
        color: ${props => props.theme.colors.gray2};
      }
    }
  }
  i {
    display: flex;
    justify-self: center;
    align-self: center;
  }
`

const EditButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

const CreatePerformedActionButton = styled(Button)`
  padding-left: 1rem;
  background-color: ${props => props.theme.colors.gray8};
  color: ${props => props.theme.colors.gray3};

  div.--button-content {
    padding-left: 0;
  }
  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
    color: white;

    i {
      color: white;
    }
  }

  ${props => props.theme.media.desktop} {
    border-radius: ${props => props.theme.sizes.defaultBorderRadius};
  }
  ${props => props.theme.media.mobile} {
    padding-left: 1rem;
  }
`

const DateWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0 1.5rem;
  color: ${props => props.theme.colors.gray3};
  font-size: 14px;

  a {
    color: ${props => props.theme.colors.primary};
  }
`

interface PerformedActionContentProps {
  deviationId: string
}

const PerformedActionContent: React.FC<PerformedActionContentProps> = ({
  deviationId,
}) => {
  const translations = useTranslate({
    add: 'common.add',
    save: 'common.save',
    cancel: 'common.cancel',
    placeholder: 'deviations.what-action-was-performed',

    addPerformedAction: 'deviations.add-performed-action',
    createdBy: 'common.created-by',

    deleteAction: 'deviations.prompts.delete-performed-action-prompt',
    deleteActionTitle: 'deviations.prompts.delete-performed-action-title',

    results: {
      queryError: 'server.general-error-try-again-later',

      createError: 'deviations.toasts.create-performed-action-error',
      createSuccess: 'deviations.toasts.create-performed-action-success',

      deleteError: 'deviations.toasts.delete-performed-action-error',
      deleteSuccess: 'deviations.toasts.delete-performed-action-success',

      editError: 'deviations.toasts.edit-performed-action-error',
      editSuccess: 'deviations.toasts.edit-performed-action-success',
    },
  })

  const addToast = useToast()
  const onErrorAuto = useOnErrorAuto()
  const confirm = useConfirm()
  const { format } = useDateFns()

  const canDeleteDeviationPerformedAction = usePermission(
    PERMISSIONS.deviations.delete.deviationperformedaction
  )

  const [editAction, setEditAction] = useState<string | null>(null)
  const [description, setDescription] = useState('')

  const { data } = useQuery<DeviationPerformedActionsQuery, IdVariable>(
    DEVIATION_PERFORMED_ACTIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: deviationId,
      },
      onError: onErrorAuto(),
    }
  )

  const actions = useMemo(
    () =>
      data?.deviation.performedActions.edges
        .map(edge => edge.node)
        .concat(
          editAction === 'new'
            ? [
                {
                  id: editAction,
                  description,
                  createdBy: { id: '', fullName: '' },
                  createdAt: new Date().toString(),
                },
              ]
            : []
        ) ?? [],
    [data, description, editAction]
  )

  const [createDeviationPerformedAction] = useMutation<
    CreateDeviationPerformedActionMutation,
    CreateDeviationPerformedActionMutationVariables
  >(CREATE_DEVIATION_PERFORMED_ACTION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeviationPerformedActions'],
    onCompleted(data) {
      addToast('success', translations.results.createSuccess)
      setEditAction(null)
      setDescription('')
    },
    onError: onErrorAuto(translations.results.queryError),
  })

  const [patchDeviationPerformedAction] = useMutation<
    PatchDeviationPerformedActionMutation,
    PatchDeviationPerformedActionMutationVariables
  >(PATCH_DEVIATION_PERFORMED_ACTION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['DeviationPerformedActions'],
    onCompleted() {
      addToast('success', translations.results.editSuccess)
      setEditAction(null)
      setDescription('')
    },
    onError: onErrorAuto(translations.results.editError),
  })

  const [deleteDeviationPerformedAction] = useMutation(
    DELETE_DEVIATION_PERFORMED_ACTION_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['DeviationPerformedActions'],
      onCompleted() {
        addToast('success', translations.results.deleteSuccess)
      },
      onError: onErrorAuto(translations.results.deleteError),
    }
  )

  function handleSave() {
    if (!editAction) return

    const currentAction = actions.find(
      action => action.id !== 'new' && action.id === editAction
    )
    if (!description || currentAction?.description === description) {
      setEditAction(null)
      setDescription('')
      return
    }

    if (editAction === 'new')
      createDeviationPerformedAction({
        variables: {
          input: {
            deviation: deviationId,
            description,
          },
        },
      })
    else
      patchDeviationPerformedAction({
        variables: {
          id: editAction,
          input: {
            description,
          },
        },
      })
  }

  function handleCancel() {
    setEditAction(null)
    setDescription('')
  }

  const handleDeleteAction = useCallback(
    async (actionId: string) => {
      if (!canDeleteDeviationPerformedAction) return

      const answer = await confirm(
        translations.deleteAction,
        translations.deleteActionTitle,
        {
          variant: 'delete',
        }
      )

      if (answer) {
        deleteDeviationPerformedAction({
          variables: {
            id: actionId,
          },
        })
      }
    },
    [
      canDeleteDeviationPerformedAction,
      confirm,
      deleteDeviationPerformedAction,
      translations.deleteAction,
      translations.deleteActionTitle,
    ]
  )

  function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
    switch (evt.key) {
      case 'Enter':
        handleSave()
        break
      case 'Escape':
        handleCancel()
        break
    }
  }

  return (
    <Wrapper>
      {actions.map(action => (
        <>
          <PerformedAction key={action.id} editing={editAction === action.id}>
            <div>
              {editAction === action.id ? (
                <Input
                  value={description}
                  autoFocus
                  fullWidth
                  placeholder={translations.placeholder}
                  onKeyDown={handleKeyDown}
                  onChange={v => setDescription(v)}
                />
              ) : (
                <div
                  className="action"
                  onClick={() => {
                    setDescription(action.description)
                    setEditAction(action.id)
                  }}
                >
                  <span>{action.description}</span>

                  <Icon icon="pencil-alt" type="solid" />
                </div>
              )}
            </div>

            <Icon
              icon="trash"
              type="solid"
              margin="0 0 0 1rem"
              color={!editAction ? 'gray5' : 'rgba(0,0,0,0)'}
              hoverColor={!editAction ? 'logoutRed' : 'rgba(0,0,0,0)'}
              cursor={!editAction ? 'pointer' : 'default'}
              onClick={() => !editAction && handleDeleteAction(action.id)}
            />
          </PerformedAction>

          {action.id !== 'new' && !editAction && (
            <DateWrapper>
              {translations.createdBy + ':'}&nbsp;
              <Link to={`/users/${action.createdBy.id}`}>
                {action.createdBy?.fullName}
              </Link>
              &nbsp;{format(new Date(action.createdAt), 'PPp')}
            </DateWrapper>
          )}
        </>
      ))}

      {!!editAction ? (
        <EditButtons>
          <Button variant="cancel" onClick={handleCancel}>
            {translations.cancel}
          </Button>

          <Button onClick={handleSave}>{translations.save}</Button>
        </EditButtons>
      ) : (
        <CreatePerformedActionButton onClick={() => setEditAction('new')}>
          {translations.addPerformedAction}

          <Icon
            icon="plus"
            color="gray3"
            margin="0 0 0 0.8em"
            translateY="1px"
          />
        </CreatePerformedActionButton>
      )}
    </Wrapper>
  )
}

export default PerformedActionContent

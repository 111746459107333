import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import { LogoIconOnly } from 'assets/images/LogoIconOnly'
import {
  Button as BaseButton,
  CenteredLoader,
  CheckBox,
  FormField as BaseFormField,
  Input,
} from 'components'
import { CompanyNode } from 'modules/companies/types.graphql'
import { TermsAndConditionsModal } from 'modules/registercompany/terms/TermsAndConditionsModal'
import React, { useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { changeCompany, getPreviousCompany, setLoginToken } from 'util/auth'
import { STAGE } from 'util/env'
import { useOnErrorAuto } from 'util/hooks'
import { validateNonEmpty } from 'util/validation'
import { LOGIN_MUTATION } from './mutations'
import { IS_LOGGED_IN_QUERY } from './queries'
import {
  IsLoggedInQuery,
  LoginMutation,
  LoginMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  height: 100%;
`
const FormField = styled(BaseFormField)`
  & + div {
    margin-top: 1.5rem;

    ${props => props.theme.media.mobile} {
      margin-top: 2rem;
    }
  }
`
const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 1rem;
  margin-bottom: 3rem;

  ${props => props.theme.media.mobile} {
    align-items: center;
    margin-bottom: 2rem;
    font-size: 0.8rem;

    label {
      white-space: nowrap;
    }

    a {
      text-align: right;
    }
  }
`
const Layout = styled.div`
  display: grid;
  grid-template-columns: 640px 1fr;
  height: 100%;
  min-height: 600px;

  ${props => props.theme.media.mobile} {
    display: block;
  }
`

interface LeftPanelProps {
  error: boolean
}

const LeftPanel = styled.div<LeftPanelProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5.5rem;

  h1 {
    margin: 3rem 0 0;

    font-size: 24px;
    font-weight: 600;
    color: ${props => props.theme.colors.gray1};
  }

  div.form {
    margin-top: ${props => (props.error ? '0rem' : '50px')};
  }

  p.wrong-email-password {
    color: ${props => props.theme.colors.error};
  }

  ${props => props.theme.media.mobile} {
    padding: 1.1rem;
    padding-top: 2.2rem;

    h1 {
      margin-bottom: 2rem;
    }
  }
`
const Logo = styled.img`
  width: 60%;
`
const Disclaimer = styled.p`
  margin: 3rem 0 0;
`
const RightPanel = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
`
const bgPan = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const LogoWrapper = styled.div`
  animation: ${bgPan} 420s linear infinite;
  position: absolute;
  left: -1800px;
  top: -1900px;

  svg {
    width: 3500px;
  }
`
const Button = styled(BaseButton)`
  margin-top: 1.5rem;
`
const RegisterCompanyButton = styled(Link)`
  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: 1.5rem;

  color: ${props => props.theme.colors.primary};
  font-weight: 600;

  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`
const Terms = styled.aside.attrs({
  role: 'button',
})`
  position: absolute;
  z-index: 1;
  right: 2rem;
  bottom: 2rem;

  padding: 8px 16px;

  cursor: pointer;
  user-select: none;
  color: ${props => props.theme.colors.primary};
  background-color: white;
  border-radius: 999px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);

  &:hover {
    text-decoration: underline;
  }

  ${props => props.theme.media.mobile} {
    bottom: 0.5rem;
    right: 50%;
    transform: translateX(50%);

    background-color: inherit;
    box-shadow: none;
  }
`

export const Login: React.FC = () => {
  const translations = useTranslate({
    required: 'common.required',
    wrongEmailPassword: 'login.wrong-email-password',
    disclaimer: [
      'login.disclaimer',
      { a: () => <a href="https://app.digitech.no">app.digitech.no</a> },
    ],

    email: 'common.email',
    password: 'users.password',

    changePassword: 'login.forgot-your-password',
    submit: 'login.submit',
    remember: 'login.stay-logged-in',

    register: 'login.register-company-and-user',
    termsAndConditions: 'companies.terms-and-conditions-header',
  })

  const emailRef = useRef<HTMLInputElement | null>(null)

  const client = useApolloClient()
  const onErrorAuto = useOnErrorAuto()
  const addPrompt = usePrompt()

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    updateForm,
    formChangeHandler: handler,
    submitHandler,
  } = useForm({
    values: {
      username: '',
      password: '',
      rememberMe: false,
      wrongEmailPassword: false,
    },
    validators: {
      username: validateNonEmpty(translations.required),
      password: validateNonEmpty(translations.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const { data: isLoggedInData, loading: isLoggedInLoading } = useQuery<
    IsLoggedInQuery,
    never
  >(IS_LOGGED_IN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted({ isLoggedIn, me }) {
      if (isLoggedIn && !!me)
        handleContinue(me.companies.edges.map(edge => edge.node))
    },
  })

  const [login, { loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN_MUTATION, {
    variables: {
      username: form.username,
      password: form.password,
    },
    onCompleted(data) {
      if (!data) throw new Error('Data not defined')

      const { ok, token } = data.login

      if (!ok || !token) {
        updateForm({ wrongEmailPassword: true })
        emailRef.current?.select()
        return
      }

      mixpanel.track('Login')
      setLoginToken(token, form.rememberMe)
      handleContinue(data.login.user.companies.edges.map(edge => edge.node))
    },
    onError: onErrorAuto('Login failed'),
  })

  function handleContinue(companies: Pick<CompanyNode, 'id' | 'shortName'>[]) {
    client.cache.reset()

    if (companies.length === 0) {
      window.location.href = '/no-companies'
      return
    }

    let prevCompany = getPreviousCompany()
    if (
      prevCompany === null ||
      !companies.some(comp => comp.shortName === prevCompany)
    )
      prevCompany = companies[0].shortName

    changeCompany(prevCompany)
  }

  function handleKeyDown({ key }: React.KeyboardEvent) {
    if (key === 'Enter') submitHandler(handleSubmit)()
  }

  function handleShowTerms() {
    addPrompt<null>(resolve => (
      <TermsAndConditionsModal onClose={() => resolve(null)} />
    ))
  }

  function handleSubmit() {
    login()
  }

  if (isLoggedInLoading || !!isLoggedInData?.isLoggedIn)
    return <CenteredLoader page />

  return (
    <Wrapper>
      <Layout>
        <LeftPanel error={form.wrongEmailPassword}>
          <Logo src={logo} />

          {STAGE !== 'production' && (
            <Disclaimer>{translations.disclaimer()}</Disclaimer>
          )}

          <h1>{translations.submit}</h1>

          {form.wrongEmailPassword && (
            <p className="wrong-email-password">
              {translations.wrongEmailPassword}
            </p>
          )}

          <div className="form">
            <FormField error={!!errors.username}>
              <label>{translations.email}</label>
              <Input
                ref={emailRef}
                name="username"
                value={form.username}
                error={errors.username}
                disabled={loading}
                fullWidth
                autoFocus
                onKeyDown={handleKeyDown}
                onChange={handler('username')}
              />
            </FormField>

            <FormField error={!!errors.password}>
              <label>{translations.password}</label>
              <Input
                type="password"
                name="password"
                value={form.password}
                error={errors.password}
                disabled={loading}
                fullWidth
                onKeyDown={handleKeyDown}
                onChange={handler('password')}
              />
            </FormField>

            <FormFooter>
              <CheckBox
                checked={form.rememberMe}
                label={translations.remember}
                disabled={loading}
                onChange={handler('rememberMe')}
              />
              <Link to="/forgotten-password">
                {translations.changePassword}
              </Link>
            </FormFooter>

            <Button
              fullWidth
              disabled={!formValid}
              loading={loading}
              onClick={submitHandler(handleSubmit)}
            >
              {translations.submit}
            </Button>

            <RegisterCompanyButton to="/register-company">
              {translations.register}
            </RegisterCompanyButton>
          </div>

          {isMobileOnly && (
            <Terms onClick={handleShowTerms}>
              {translations.termsAndConditions}
            </Terms>
          )}
        </LeftPanel>

        {!isMobileOnly && (
          <RightPanel>
            <LogoWrapper>
              <LogoIconOnly />
            </LogoWrapper>

            <Terms onClick={handleShowTerms}>
              {translations.termsAndConditions}
            </Terms>
          </RightPanel>
        )}
      </Layout>
    </Wrapper>
  )
}

export default Login

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { ProjectStage } from 'modules/projects/types.graphql'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { overloadColor } from 'util/style'

export type CustomerElementArea =
  | 'info'
  | 'billing'
  | 'contact'
  | 'map'
  | ProjectStage

interface CardProps {
  area: CustomerElementArea
  color: string
  closed: boolean
}

const Wrapper = styled.div<CardProps>`
  grid-area: ${props => props.area};

  height: 100%;
  width: 100%;

  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  header {
    padding: 1rem 2rem;

    border-bottom: ${props =>
      props.closed ? '0' : `1px solid ${props.theme.colors.gray4}`};
    color: ${props => overloadColor(props.color)};

    font-weight: 600;

    ${props => props.theme.media.mobile} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        color: ${props => props.theme.colors.gray3};
        font-weight: 400;

        i {
          margin-left: 4px;
        }
      }
    }
  }

  ${props => props.theme.media.mobile} {
    border-radius: 0px;

    header {
      padding: 1rem;
    }
  }
`

interface CustomerElementCardProps {
  area: CustomerElementArea
  title: string
  closed: boolean

  headerColor?: string

  onToggleOpen: () => void
}

export const CustomerElementCard: React.CFC<CustomerElementCardProps> = ({
  children,

  area,
  title,
  headerColor,
  closed = false,

  onToggleOpen,
}) => {
  const translations = useTranslate({
    show: 'common.show',
    hide: 'common.hide',
  })

  return (
    <Wrapper area={area} color={headerColor ?? 'gray1'} closed={closed}>
      <header>
        {title}
        {isMobileOnly && (
          <div>
            {!closed ? translations.hide : translations.show}
            <Icon
              icon={!closed ? 'eye-slash' : 'eye'}
              type="solid"
              onClick={onToggleOpen}
            />
          </div>
        )}
      </header>
      {!closed && children}
    </Wrapper>
  )
}

import nb from './translations/nb'
import en from './translations/en'

const messages: { [lang: string]: { [id: string]: string } } = {
  nb,
  en,
  'nb-NO': nb,
  'nn-NO': nb,
  'en-GB': en,
  'en-US': en,
}

export default messages

import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { EmptyPage } from 'components/EmptyPage'
import { HAS_PROJECTS_QUERY } from 'modules/projects/queries'
import { HasProjectsQuery } from 'modules/projects/types.graphql'
import { useProjectsUtils } from 'modules/projects/util/useProjectsUtils'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { useBreadcrumbOverrides } from 'util/hooks'
import { DashboardCards } from './DashboardCards'
import { DashboardProjectsTable } from './DashboardProjectsTable'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  a {
    color: ${props => props.theme.colors.gray1};
    text-decoration: none;
  }
  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  const translations = useTranslate({
    myPage: 'common.my-site',
    pageDescription: 'dashboard.page-description',
    createProject: 'projects.create-project-with-test-customer',
  })

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)

  useBreadcrumbOverrides({
    dashboard: translations.myPage,
  })

  const { data } = useQuery<HasProjectsQuery, never>(HAS_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted() {
      setHasLoadedOnce(true)
    },
  })

  const { handleCreateProject } = useProjectsUtils({
    skipQuery: true,
  })

  return (
    <EmptyPage
      module="dashboard"
      empty={!data?.hasProjects}
      loading={!hasLoadedOnce}
      onButtonClick={handleCreateProject}
    >
      <Wrapper>
        {!isMobileOnly && <PageHeader title={translations.myPage} />}

        <DashboardCards />

        <DashboardProjectsTable />
      </Wrapper>
    </EmptyPage>
  )
}

import { gql } from '@apollo/client'

export const CREATE_TIME_ENTRY_MUTATION = gql`
  mutation CreateTimeEntry($input: CreateTimeEntryInput!) {
    createTimeEntry(input: $input) {
      timeEntry {
        id
        project {
          id
        }
      }
    }
  }
`

export const PATCH_TIME_ENTRY_MUTATION = gql`
  mutation PatchTimeEntry($id: ID!, $input: PatchTimeEntryInput!) {
    patchTimeEntry(id: $id, input: $input) {
      timeEntry {
        id
      }
    }
  }
`

export const DELETE_TIMESHEET_MUTATION = gql`
  mutation DeleteTimeEntry($id: ID!) {
    deleteTimeEntry(id: $id) {
      found
      deletedId
    }
  }
`

export const SEND_TIMESHEET_EMAIL_MUTATION = gql`
  mutation SendTimesheetEmail(
    $recipient: String!
    $projectId: ID
    $userId: ID
    $dateFrom: Date
    $dateTo: Date
    $includeAbsences: Boolean
    $tz: String
  ) {
    sendTimesheetEmail(
      recipient: $recipient
      projectId: $projectId
      userId: $userId
      dateFrom: $dateFrom
      dateTo: $dateTo
      includeAbsences: $includeAbsences
      tz: $tz
    ) {
      emailSent
    }
  }
`

export const CREATE_ABSENCE_MUTATION = gql`
  mutation CreateAbsence($input: CreateAbsenceInput!) {
    createAbsence(input: $input) {
      absence {
        id
      }
    }
  }
`

export const PATCH_ABSENCE_MUTATION = gql`
  mutation PatchAbsence($id: ID!, $input: PatchAbsenceInput!) {
    patchAbsence(id: $id, input: $input) {
      absence {
        id
      }
    }
  }
`

export const DELETE_ABSENCE_MUTATION = gql`
  mutation DeleteAbsence($id: ID!) {
    deleteAbsence(id: $id) {
      found
      deletedId
    }
  }
`

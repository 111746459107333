import { useForm, useTranslate } from '@ur/react-hooks'
import {
  Button,
  FilePicker as BaseFilePicker,
  FilePickerFile,
  FilePickerOnChangeValue,
  FormField,
  Input,
  Quill,
  TextArea,
} from 'components'
import { DeltaStatic } from 'quill'
import Delta from 'quill-delta'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { safeParseJson } from 'util/helpers'
import { validateNonEmpty, validateNonEmptyQuillDelta } from 'util/validation'
import { ManualEntryAttachmentNode } from '../types.graphql'

const Wrapper = styled.div`
  & > div + div {
    margin-top: 1rem;
  }
`
const FilePicker = styled(BaseFilePicker)`
  .--file-picker-files {
    margin-top: 1rem;
  }
`
const Submit = styled(FormField)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  padding-top: 1rem;
`

export interface EntryFormInitialData {
  id: string
  name: string
  description: string
  richtext: string
  attachments: Pick<ManualEntryAttachmentNode, 'id' | 'name' | 'file'>[]
}
export interface EntryFormSubmitData {
  name: string
  description: string
  richtext: string
  attachmentsAdd: {
    name: string
    file: File
  }[]
  attachmentsRemove: string[]
}

interface EntryFormProps {
  folderId: string
  loading: boolean

  initialData?: EntryFormInitialData

  onCancel: () => void
  onSubmit: (input: EntryFormSubmitData) => void
}

export const EntryForm: React.FC<EntryFormProps> = ({
  folderId,
  loading,

  initialData,

  onCancel,
  onSubmit,
}) => {
  const translations = useTranslate({
    title: 'common.title',
    description: 'common.description',
    content: 'common.content',
    uploadFiles: 'common.upload-files',

    cancel: 'common.cancel',
    save: 'common.save',

    required: 'common.required',
  })

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateForm,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<EntryFormSubmitData>({
    values: {
      name: initialData?.name ?? '',
      description: initialData?.description ?? '',
      richtext: initialData?.richtext ?? '{"ops":[{"insert":"\\n"}]}',
      attachmentsAdd: [],
      attachmentsRemove: [],
    },
    validators: {
      name: validateNonEmpty(translations.required),
      richtext: validateNonEmptyQuillDelta(translations.required),
    },
  })

  const initialRichtext = useMemo<DeltaStatic>(() => {
    if (!initialData) return new Delta() as unknown as DeltaStatic

    const parsed = safeParseJson<Delta>(initialData.richtext, new Delta())
    return new Delta(parsed.ops) as unknown as DeltaStatic
  }, [initialData])

  const initialFiles = useMemo<FilePickerFile[]>(
    () =>
      initialData?.attachments.map(att => ({
        id: att.id,
        name: att.name,
        file: att.file,
      })) ?? [],
    [initialData]
  )

  function handleQuillChange(delta: DeltaStatic) {
    const richtext = JSON.stringify(delta)
    updateForm({ richtext })
  }

  function handleFilePickerChange(value: FilePickerOnChangeValue) {
    updateForm({
      attachmentsAdd: value.new.map(file => ({
        name: file.name,
        file: file.file,
      })),
      attachmentsRemove: value.deleted.reduce<string[]>(
        (acc, cur) => (!cur.id ? acc : [...acc, cur.id]),
        []
      ),
    })
  }

  const submitDisabled = loading || !formValid || !formEdited

  return (
    <Wrapper>
      <FormField required error={!!errors.name}>
        <label htmlFor="title">{translations.title}</label>

        <Input
          value={form.name}
          id="title"
          error={errors.name}
          fullWidth
          autoFocus
          onChange={handler('name')}
        />
      </FormField>

      <FormField>
        <label htmlFor="description">{translations.description}</label>

        <TextArea
          value={form.description}
          id="description"
          fullWidth
          onChange={handler('description')}
        />
      </FormField>

      <FormField required error={!!errors.richtext}>
        <label htmlFor="content">{translations.content}</label>

        <Quill defaultValue={initialRichtext} onChange={handleQuillChange} />
      </FormField>

      <FormField>
        <label>{translations.uploadFiles}</label>

        <FilePicker
          multiple
          initialFiles={initialFiles}
          onChange={handleFilePickerChange}
        />
      </FormField>

      <Submit>
        <Button disabled={loading} variant="cancel" onClick={onCancel}>
          {translations.cancel}
        </Button>

        <Button
          disabled={submitDisabled}
          loading={loading}
          onClick={submit(onSubmit)}
        >
          {translations.save}
        </Button>
      </Submit>
    </Wrapper>
  )
}

import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useDateFns } from 'util/hooks'
import { ShallowDeviation } from '../types.graphql'
import { DeviationCategories, DeviationStatus } from '../components'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.section`
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 1px 0;

  & > div {
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`
const DateField = styled.div`
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  border-right: 1px solid ${props => props.theme.colors.gray6};
`
interface ContentProps {
  hideProject: boolean
}
const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => (props.hideProject ? '1rem' : '0.5rem')};
  padding: 1rem;

  a {
    font-size: 18px;
    font-weight: 600;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0rem 1rem;

  border-top: 2px solid ${props => props.theme.colors.gray6};
  color: ${props => props.theme.colors.gray3};
  font-size: 14px;

  a {
    color: ${props => props.theme.colors.gray3};
  }
`
interface ProjectProps {
  empty: boolean
}
const Project = styled.span<ProjectProps>`
  margin-bottom: 0.4rem;

  ${props =>
    props.empty &&
    css`
      font-style: italic;
      color: ${props => props.theme.colors.gray4};
    `};
`

interface DeviationCardProps {
  deviation: ShallowDeviation
  isInternal: boolean
  editDeleteMenu: TableMenu
  hideProject?: boolean
}

export const DeviationCard: React.FC<DeviationCardProps> = ({
  deviation,
  isInternal,
  editDeleteMenu,
  hideProject = false,
}) => {
  const translations = useTranslate({
    customer: 'common.customer',

    noCustomer: 'customers.no-customer',
    noProject: 'projects.no-project',
    noDescription: 'common.no-description',
  })

  const { format } = useDateFns()

  const categories = useMemo(
    () => deviation.categories.edges.map(edge => edge.node) ?? [],
    [deviation]
  )

  return (
    <Wrapper>
      <Header>
        <DateField>
          {deviation?.date ? format(new Date(deviation?.date), 'PP') : ''}
        </DateField>

        <DeviationStatus
          isActive={deviation.active}
          isTreated={deviation.treated}
        />

        <Menu className="menu" icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      <Content hideProject={hideProject}>
        <Link
          to={
            isInternal
              ? `/deviations/internal/${deviation.id}`
              : `/projects/${deviation?.project?.id}/deviations/${deviation?.id}`
          }
        >
          {deviation?.title}
        </Link>

        {!hideProject && (
          <Project
            empty={isInternal ? !deviation.description : !deviation.project}
          >
            {isInternal
              ? deviation.description || translations.noDescription
              : deviation.project?.name ?? translations.noProject}
          </Project>
        )}

        <DeviationCategories categories={categories} />
      </Content>

      {!isInternal && (
        <Footer>
          <strong>{translations.customer + ':'}&nbsp;</strong>
          {!!deviation.project?.customer.id ? (
            <Link to={`/customers/${deviation.project?.customer.id}`}>
              {deviation.project.customer.name}
            </Link>
          ) : (
            <span>{translations.noCustomer}</span>
          )}
        </Footer>
      )}
    </Wrapper>
  )
}

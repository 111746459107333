import { ObservableQueryFields, useMutation, useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { TableFilteringChecklistOptions, TableMenu } from 'components'
import uniqBy from 'lodash/uniqBy'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IdVariable, RelayPageInfo } from 'types/graphql/common'
import {
  useAdmin,
  useConfirm,
  useOnErrorAuto,
  usePermission,
  useToast,
} from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { Entries } from './components/TimeEntryForm'
import { DELETE_ABSENCE_MUTATION, DELETE_TIMESHEET_MUTATION } from './mutations'
import { ABSENCE_FILTERS_QUERY, TIME_ENTRY_FILTERS_QUERY } from './queries'
import {
  AbsencesFiltersQuery,
  AbsencesQuery,
  AbsencesQueryVariables,
  CreateTimeEntryMutationVariables,
  PatchTimeEntryMutationVariables,
  TableAbsences,
  TableTimeEntry,
  TimeEntryFiltersQuery,
  TimeEntryOption,
  TimesheetEntriesQuery,
  TimesheetEntriesQueryVariables,
  CreateAbsenceMutationVariables,
  PatchAbsenceMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

export function useTimesheetFiltering() {
  const admin = useAdmin()
  const onErrorAuto = useOnErrorAuto()

  const { data } = useQuery<TimeEntryFiltersQuery, never>(
    TIME_ENTRY_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: onErrorAuto(),
    }
  )

  const projects = useMemo(() => data?.timeEntryFilters.projects ?? [], [data])
  const users = useMemo(() => data?.timeEntryFilters.users ?? [], [data])

  const projectFilter = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      exclusive: true,
      items: uniqBy(
        projects.map(project => ({
          id: project.id,
          text: project.name,
          initUnchecked: true,
        })),
        'id'
      ),
    }),
    [projects]
  )

  const userFilter = useMemo<TableFilteringChecklistOptions | null>(
    () =>
      !admin
        ? null
        : {
            type: 'checklist',
            exclusive: true,
            items: uniqBy(
              users.map(user => ({
                id: user.id,
                text: user.fullName,
                initUnchecked: true,
              })),
              'id'
            ),
          },
    [admin, users]
  )

  return {
    projectFilter,
    userFilter,
  }
}

export function useAbsenceFiltering() {
  const onErrorAuto = useOnErrorAuto()

  const { data } = useQuery<AbsencesFiltersQuery, never>(
    ABSENCE_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: onErrorAuto(),
    }
  )

  const users = useMemo(() => data?.absenceFilters?.users ?? [], [data])

  const userFilter = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      exclusive: true,
      items: uniqBy(
        users.map(user => ({
          id: user.id,
          text: user.fullName,
          initUnchecked: true,
        })),
        'id'
      ),
    }),
    [users]
  )

  return {
    userFilter,
  }
}

export function useTimesheetsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreEntries: ObservableQueryFields<
    TimesheetEntriesQuery,
    TimesheetEntriesQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreEntries({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreEntries])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useAbsencesPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreAbsences: ObservableQueryFields<
    AbsencesQuery,
    AbsencesQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreAbsences({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreAbsences])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

export function useDeleteAbsence() {
  const translations = useTranslate({
    absenceDeleted: 'timesheets.success.delete-absence',
    absenceDeleteError: 'timesheets.errors.delete-absence',

    deleteAbsence: 'timesheets.delete-absence-prompt-text',
    deleteAbsenceTitle: 'timesheets.prompts.delete-absence-title',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const confirm = useConfirm()

  const canDeleteAbsence = usePermission(PERMISSIONS.timesheets.delete.absence)

  const [deleteAbsenceMutation, { loading }] = useMutation<never, IdVariable>(
    DELETE_ABSENCE_MUTATION,
    {
      refetchQueries: ['Absences'],
      awaitRefetchQueries: true,
      onCompleted() {
        addToast('success', translations.absenceDeleted)
        mixpanel.track('Delete Absence')
      },
      onError: onErrorAuto(translations.absenceDeleteError),
    }
  )
  const deleteAbsence = useCallback(
    async (absenceId: string) => {
      if (!canDeleteAbsence) return

      const answer = await confirm(
        translations.deleteAbsence,
        translations.deleteAbsenceTitle,
        {
          variant: 'delete',
        }
      )
      answer &&
        deleteAbsenceMutation({
          variables: {
            id: absenceId,
          },
        })
    },
    [
      canDeleteAbsence,
      confirm,
      deleteAbsenceMutation,
      translations.deleteAbsence,
      translations.deleteAbsenceTitle,
    ]
  )
  return {
    loading,
    deleteAbsence,
  }
}

export function useDeleteTimesheet() {
  const translations = useTranslate({
    timeEntryDeleted: 'timesheets.success.delete-time-entry',
    timeEntryDeleteError: 'timesheets.errors.delete-time-entry',

    deleteTimeEntry: 'timesheets.prompts.delete-time-entry',
    deleteTimeEntryTitle: 'timesheets.prompts.delete-time-entry-title',
  })

  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const confirm = useConfirm()
  const canDeleteTimesheet = usePermission(
    PERMISSIONS.timesheets.delete.timeentry
  )

  const [deleteTimesheetMutation, { loading }] = useMutation<never, IdVariable>(
    DELETE_TIMESHEET_MUTATION,
    {
      refetchQueries: ['TimeEntries', 'TimeEntriesFilters'],
      awaitRefetchQueries: true,
      onCompleted() {
        addToast('success', translations.timeEntryDeleted)
        mixpanel.track('Delete Time Entry')
      },
      onError: onErrorAuto(translations.timeEntryDeleteError),
    }
  )

  const deleteTimesheet = useCallback(
    async (timesheetId: string) => {
      if (!canDeleteTimesheet) return

      const answer = await confirm(
        translations.deleteTimeEntry,
        translations.deleteTimeEntryTitle,
        {
          variant: 'delete',
        }
      )
      answer &&
        deleteTimesheetMutation({
          variables: {
            id: timesheetId,
          },
        })
    },
    [
      canDeleteTimesheet,
      confirm,
      deleteTimesheetMutation,
      translations.deleteTimeEntry,
      translations.deleteTimeEntryTitle,
    ]
  )
  return {
    deleteTimesheet,
    loading,
  }
}

export function useAbsenceMenu(
  deleteAbsence: (id: string) => void,
  hrefs: {
    edit: (absence: TableAbsences) => string
  }
) {
  const translations = useTranslate({
    delete: 'common.delete',
    edit: 'common.edit',
  })

  const history = useHistory()
  const canEditAbsence = usePermission(PERMISSIONS.timesheets.change.absence)
  const canDeleteAbsence = usePermission(PERMISSIONS.timesheets.delete.absence)

  const makeMenu = useCallback(
    (absence: TableAbsences): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditAbsence,
          onClick: () => {
            history.push(hrefs.edit(absence))
          },
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hide: !canDeleteAbsence,
          hoverColor: '#e38080',
          onClick: () => deleteAbsence(absence.id),
        },
      ],
    }),
    [
      canDeleteAbsence,
      canEditAbsence,
      deleteAbsence,
      history,
      hrefs,
      translations,
    ]
  )
  return makeMenu
}

export function useTimesheetMenu(
  deleteTimesheet: (id: string) => void,
  hrefs: {
    edit: (timesheet: TableTimeEntry) => string
  }
) {
  const translations = useTranslate({
    delete: 'common.delete',
    edit: 'common.edit',
  })

  const history = useHistory()
  const canEditTimesheet = usePermission(
    PERMISSIONS.timesheets.change.timeentry
  )
  const canDeleteTimesheet = usePermission(
    PERMISSIONS.timesheets.delete.timeentry
  )

  const makeMenu = useCallback(
    (timesheet: TableTimeEntry): TableMenu => ({
      items: [
        {
          icon: 'edit',
          text: translations.edit,
          hide: !canEditTimesheet,
          onClick: () => {
            history.push(hrefs.edit(timesheet))
          },
        },
        {
          icon: 'trash',
          text: translations.delete,
          color: '#f39b9b',
          hide: !canDeleteTimesheet,
          hoverColor: '#e38080',
          onClick: () => deleteTimesheet(timesheet.id),
        },
      ],
    }),
    [
      canDeleteTimesheet,
      canEditTimesheet,
      deleteTimesheet,
      history,
      hrefs,
      translations.delete,
      translations.edit,
    ]
  )
  return makeMenu
}

export function updateTimeEntryType(
  entryType: TimeEntryOption,
  hours: number,
  entries: Entries
): Entries {
  switch (entryType) {
    case 'hoursOvertimeFifty':
      return { ...entries, hoursOvertimeFifty: hours }
    case 'hoursOvertimeHundred':
      return { ...entries, hoursOvertimeHundred: hours }
    case 'hoursTimeBankOrdinary':
      return { ...entries, hoursTimeBankOrdinary: hours }
    case 'hoursTimeBankFifty':
      return { ...entries, hoursTimeBankFifty: hours }
    case 'hoursTimeBankHundred':
      return { ...entries, hoursTimeBankHundred: hours }
    default:
      return { ...entries, hoursOrdinary: hours }
  }
}

export function getTimeEntrySum(entries: Entries): number {
  return (
    entries.hoursOrdinary +
    entries.hoursOvertimeFifty +
    entries.hoursOvertimeHundred +
    entries.hoursTimeBankFifty +
    entries.hoursTimeBankHundred +
    entries.hoursTimeBankOrdinary
  )
}

export function isCreateTimeEntryMutationVariables(
  arg: CreateTimeEntryMutationVariables | PatchTimeEntryMutationVariables
): arg is CreateTimeEntryMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchTimeEntryMutationVariables(
  arg: CreateTimeEntryMutationVariables | PatchTimeEntryMutationVariables
): arg is PatchTimeEntryMutationVariables {
  return arg.hasOwnProperty('id')
}

export function isCreateAbsenceMutationVariables(
  arg: CreateAbsenceMutationVariables | PatchAbsenceMutationVariables
): arg is CreateAbsenceMutationVariables {
  return !arg.hasOwnProperty('id')
}

export function isPatchAbsenceMutationVariables(
  arg: CreateAbsenceMutationVariables | PatchAbsenceMutationVariables
): arg is PatchAbsenceMutationVariables {
  return arg.hasOwnProperty('id')
}

import { useQuery } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import {
  DateRange,
  DateRangePicker,
  FetchMoreLoader,
  Message,
  PageHeader,
  PageHeaderIconButton,
  TableFiltering,
  useDateRangeQuickie,
} from 'components'
import { EmptyPage } from 'components/EmptyPage'
import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useDateFns, useOnErrorAuto } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { AbsencesMobile } from './AbsencesMobile'
import { AbsenceTable } from './AbsenceTable'
import { ABSENCES_QUERY } from './queries'
import { AbsencesQuery, AbsencesQueryVariables } from './types.graphql'
import {
  useAbsenceFiltering,
  useAbsenceMenu,
  useAbsencesPagination,
  useDeleteAbsence,
} from './util'

const Wrapper = styled.div``

const PAGE_SIZE = 25

interface AbsencesTabProps {}

export const AbsencesTab: React.FC<AbsencesTabProps> = () => {
  const translations = useTranslate({
    absences: 'common.absences',
    registerAbsence: 'timesheets.register-absence',

    print: 'common.print',
    sendToEmail: 'common.send-to-email',
    user: 'common.user',

    dateRange: {
      today: 'common.today',
      yesterday: 'common.yesterday',
      thisWeek: 'common.this-week',
      lastWeek: 'common.last-week',
      thisMonth: 'common.this-month',
      lastMonth: 'common.last-month',
      thisYear: 'common.this-year',
      lastYear: 'common.last-year',
      allTime: 'common.all-time',
    },

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const { startOfWeek, endOfWeek, format } = useDateFns()

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const [sort, setSort] = useState('-date')
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfWeek(new Date()),
    to: endOfWeek(new Date()),
  })

  const [selectedUser, setSelectedUser] = useState<string | null>(null)

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore: fetchMoreAbsences,
  } = useQuery<AbsencesQuery, AbsencesQueryVariables>(ABSENCES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      orderBy: sort,
      userId: selectedUser,
      dateGte: format(dateRange.from, 'yyyy-MM-dd'),
      dateLte: format(dateRange.to, 'yyyy-MM-dd'),
    },
    onCompleted() {
      setHasLoadedOnce(true)
    },
    onError: onErrorAuto(),
  })

  const [, , dateText] = useDateRangeQuickie(dateRange)

  const { userFilter } = useAbsenceFiltering()

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useAbsencesPagination(data?.allAbsences.pageInfo, fetchMoreAbsences)

  const { deleteAbsence: handleDeleteAbsence, loading: deleteLoading } =
    useDeleteAbsence()

  const makeMenu = useAbsenceMenu(handleDeleteAbsence, {
    edit: absence => `/absences/${absence.id}/edit/`,
  })

  useInfiniteScroll(handleFetchMore)

  const absences = useMemo(
    () => data?.allAbsences.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const hasAbsences = useMemo(() => data?.hasAbsences, [data])

  function handleFilterChange(value: TableFiltering) {
    const checked = value.checked[0]

    value.column === 'user' ? setSelectedUser(checked) : setSelectedUser(null)
  }

  const iconButtons = useMemo<PageHeaderIconButton[]>(
    () => [
      {
        side: 'left',
        icon: 'calendar-alt',
        text: dateText,
        children: (
          <DateRangePicker
            value={dateRange}
            show={datePickerOpen}
            top="calc(100% + 1rem)"
            left="-6rem"
            onClose={() => setDatePickerOpen(false)}
            onChange={setDateRange}
          />
        ),
        onClick: () => setDatePickerOpen(true),
      },
    ],
    [datePickerOpen, dateRange, dateText]
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  const isLoading = queryLoading || deleteLoading || fetchMoreLoading

  return (
    <EmptyPage
      module="absences"
      empty={!hasAbsences}
      loading={!hasLoadedOnce}
      isTabView
      onButtonClick={() => history.push(`/absences/create`)}
    >
      <Wrapper>
        {isMobileOnly ? (
          <AbsencesMobile
            absences={absences}
            dateRange={dateRange}
            makeMenu={makeMenu}
            loading={isLoading}
            userFilter={userFilter}
            onFilterChange={handleFilterChange}
            onUpdateDateRange={setDateRange}
          />
        ) : (
          <>
            <PageHeader
              loading={isLoading}
              buttons={[
                {
                  text: translations.registerAbsence,
                  icon: 'plus',
                  onClick: () => history.push(`/absences/create`),
                },
              ]}
              iconButtons={iconButtons}
              tabMargin
            />

            <AbsenceTable
              absences={absences}
              makeMenu={makeMenu}
              initialSort={sort}
              userFilter={userFilter}
              onSortingChange={setSort}
              onFilterChange={handleFilterChange}
            />
          </>
        )}

        <FetchMoreLoader
          loading={fetchMoreLoading}
          hide={!hasMore}
          onFetchMore={handleFetchMore}
        />
      </Wrapper>
    </EmptyPage>
  )
}

import { Icon, IconProps } from '@ur/react-components'
import { FontAwesomeIcon } from '@ur/react-components/build/types/css'
import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { RequiredSome } from 'types/util'
import { overloadColor } from 'util/style'
import { SlideMenuItem } from '.'
import { MenuItem } from './MenuItem'
import { motion, Variants } from 'framer-motion'
import { useClickOutside } from '@ur/react-hooks'

const Wrapper = styled(motion.div)`
  display: flex;
  height: 100%;
  background-color: white;
`

interface OpenButtonProps {
  width: string
  hoverColor: string
}
const OpenButton = styled.div.attrs({
  role: 'button',
})<OpenButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  cursor: pointer;

  &:hover i {
    color: ${props => overloadColor(props.hoverColor)};
  }
`

const Slider = styled.div`
  display: flex;
`

type MenuItemWithId = SlideMenuItem & { id: string }

interface SlideMenuProps {
  items: SlideMenuItem[]

  openIcon?: FontAwesomeIcon | IconProps
  closeIcon?: FontAwesomeIcon | IconProps
  iconWidth?: string

  noCloseOnClickOutside?: boolean

  onOpen?: () => boolean | void
  onClose?: () => boolean | void
}

export const SlideMenu: React.FC<SlideMenuProps> = ({
  items,

  openIcon,
  closeIcon,
  iconWidth = '40px',

  noCloseOnClickOutside = false,

  onOpen,
  onClose,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const buildIconProps = useCallback(
    (
      icon?: FontAwesomeIcon | IconProps,
      defaultIcon?: FontAwesomeIcon
    ): RequiredSome<IconProps, 'hoverColor'> => {
      let defaults = {
        hoverColor: 'primaryHover',
        size: '1.6rem',
      }

      if (!icon)
        return {
          ...defaults,
          icon: defaultIcon ?? 'ellipsis-v',
        }

      if (typeof icon === 'string')
        return {
          ...defaults,
          icon,
        }

      return {
        ...defaults,
        ...icon,
      }
    },
    []
  )

  const openIconProps = useMemo<RequiredSome<IconProps, 'hoverColor'>>(
    () => buildIconProps(openIcon, 'ellipsis-v'),
    [buildIconProps, openIcon]
  )
  const closeIconProps = useMemo<RequiredSome<IconProps, 'hoverColor'>>(
    () => buildIconProps(closeIcon ?? openIcon, 'chevron-right'),
    [buildIconProps, closeIcon, openIcon]
  )

  const itemsWithIds = useMemo<MenuItemWithId[]>(
    () =>
      items.reduce<MenuItemWithId[]>(
        (acc, item) =>
          !!item.hide
            ? acc
            : acc.concat({
                ...item,
                id: uniqueId('slideMenuItem'),
              }),
        []
      ),
    [items]
  )

  const variants = useMemo<Variants>(
    () => ({
      open: {
        x: `calc(-100% + ${iconWidth})`,
        transition: { bounce: 0 },
      },
      closed: {
        x: '0%',
        transition: { bounce: 0 },
      },
    }),
    [iconWidth]
  )

  function toggleOpen(mode?: boolean) {
    const proceed = mode ?? open ? onClose?.() : onOpen?.()
    if (proceed === false) return

    setOpen(v => mode ?? !v)
  }

  useClickOutside(wrapperRef, () => {
    if (noCloseOnClickOutside) return
    toggleOpen(false)
  })

  if (!itemsWithIds.length) return null

  return (
    <Wrapper
      ref={wrapperRef}
      animate={open ? 'open' : 'closed'}
      variants={variants}
      initial="closed"
    >
      <OpenButton
        width={iconWidth}
        hoverColor={(open ? closeIconProps : openIconProps).hoverColor}
        onClick={() => toggleOpen()}
      >
        <Icon {...(open ? closeIconProps : openIconProps)} />
      </OpenButton>

      <Slider>
        {itemsWithIds.map(item => (
          <MenuItem
            key={item.id}
            item={item}
            onClick={() => item.closeOnClick && toggleOpen(false)}
          />
        ))}
      </Slider>
    </Wrapper>
  )
}

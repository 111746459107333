import { useQuery } from '@apollo/client'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  CenteredLoader,
  FetchMoreLoader,
  Message,
  PageHeader as BasePageHeader,
  Table,
  TableColumn,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { OfferStatus } from './components/OfferStatus'
import { OffersMobile } from './OffersMobile'
import { OFFERS_SHALLOW_QUERY } from './queries'
import {
  OffersShallowQuery,
  OffersShallowQueryVariables,
  ShallowOffer,
} from './types.graphql'
import { useOffersPagination, useOfferUtils } from './util'

const Wrapper = styled.div`
  padding: 0 1rem 1rem;

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`

const PageHeader = styled(BasePageHeader)`
  padding-top: 0;
  margin: 0;
`
interface OfferTitleProps {
  disabled: boolean
}
const OfferTitle = styled.span<OfferTitleProps>`
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    `};
`

const PAGE_SIZE = 25

interface OffersProps {}

export const Offers: React.FC<OffersProps> = () => {
  const translations = useTranslate({
    title: 'common.title',
    cost: 'offers.cost',
    status: 'common.status',
    date: 'common.date',

    noData: 'common.no-data',
    noResults: 'common.no-results',

    search: 'offers.search',
    createOffer: 'offers.create',

    edit: 'common.edit',
    delete: 'common.delete',
    download: 'checklists.download-pdf',
    send: 'common.send-to-email',

    results: {
      queryError: 'server.general-error-try-again-later',
    },
  })

  const hasLaunchedModalByRouteStateRef = useRef(false)

  const { projectId } = useParams<{ projectId: string }>()
  const { format } = useDateFns()
  const theme = useTheme()
  const history = useHistory()

  const location = useLocation<{ openModal?: string } | undefined>()

  const [sort, setSort] = useState('-createdAt')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])

  const { data, loading, error, fetchMore } = useQuery<
    OffersShallowQuery,
    OffersShallowQueryVariables
  >(OFFERS_SHALLOW_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
      project: projectId,
      status: selectedStatuses,
      orderBy: sort,
    },
    onError: console.error,
  })

  const offers = useMemo(
    () => data?.allOffers.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const {
    patchLoading,
    sendEmailLoading,
    deleteLoading,

    canCreateOffer,
    canEditOffer,
    canDeleteOffer,

    statusFiltering,
    makeStatusMenu,

    handlePrint,
    handleSendOffer: handleSend,
    handleDeleteOffer,
  } = useOfferUtils({
    selectedStatuses,
  })

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useOffersPagination(data?.allOffers.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore)

  const handleSendOffer = useCallback(
    (offerId: string) => {
      if (!canEditOffer) return Promise.resolve()
      return handleSend(offerId, projectId)
    },
    [canEditOffer, handleSend, projectId]
  )

  useLayoutEffect(() => {
    if (hasLaunchedModalByRouteStateRef.current || !location.state) return
    hasLaunchedModalByRouteStateRef.current = true

    if (!!location.state.openModal) handleSendOffer(location.state.openModal)
  }, [handleSendOffer, location.state])

  const makeEditDeleteMenu = useCallback(
    (offer: ShallowOffer) => {
      const items: TableMenu = {
        items: [
          {
            icon: 'paper-plane',
            text: translations.send,
            hide: !canEditOffer,
            onClick: () => handleSendOffer(offer.id),
          },
          {
            icon: 'download',
            text: translations.download,
            onClick: () => handlePrint(offer.id),
          },
          {
            icon: 'edit',
            text: translations.edit,
            hide: !canEditOffer,
            onClick: () =>
              history.push(`/projects/${projectId}/offers/${offer.id}/edit`),
          },
          {
            icon: 'trash',
            text: translations.delete,
            color: '#f39b9b',
            hide: !canDeleteOffer,
            hoverColor: '#e38080',
            onClick: () => handleDeleteOffer(offer),
          },
        ],
      }

      return items
    },
    [
      canDeleteOffer,
      canEditOffer,
      handleDeleteOffer,
      handleSendOffer,
      history,
      projectId,
      translations.delete,
      translations.download,
      translations.edit,
      translations.send,
      handlePrint,
    ]
  )

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'title',
        label: translations.title,
        sortable: true,
      },
      {
        id: 'cost',
        label: translations.cost,
        sortable: true,
      },
      {
        id: 'status',
        label: translations.status,
        filtering: statusFiltering,
      },
      {
        id: 'createdAt',
        label: translations.date,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [translations, statusFiltering]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      offers.map(offer => {
        const date = format(new Date(offer.createdAt), 'PPP')

        return {
          title: {
            content: (
              <OfferTitle
                disabled={!canEditOffer}
                onClick={() => handleSendOffer(offer.id)}
              >
                {offer.title}
              </OfferTitle>
            ),
          },
          cost: {
            content: offer.totalCost.toFixed(2).toString(),
          },
          status: {
            content: <OfferStatus offer={offer} />,
            menu: makeStatusMenu(offer),
          },
          createdAt: { content: date },
          menu: { content: '', menu: makeEditDeleteMenu(offer) },
        }
      }),
    [
      offers,
      format,
      canEditOffer,
      makeStatusMenu,
      makeEditDeleteMenu,
      handleSendOffer,
    ]
  )

  const options = useMemo<TableOptions>(
    () => ({
      fillHorizontal: true,
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color:
            cell.columnId === 'createdAt' ? theme.colors.gray3 : 'currentColor',
          fontWeight: cell.columnId === 'title' ? 600 : 'inherit',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  const isLoading = useMemo(
    () => loading || patchLoading || deleteLoading || sendEmailLoading,
    [deleteLoading, loading, patchLoading, sendEmailLoading]
  )

  if (error)
    return (
      <Message.Error show centered text={translations.results.queryError} />
    )

  if (!offers) return <CenteredLoader />

  return (
    <Wrapper>
      {isMobileOnly ? (
        <OffersMobile
          offers={offers}
          search={search}
          makeEditDeleteMenu={makeEditDeleteMenu}
          makeStatusMenu={makeStatusMenu}
          statusFilter={statusFiltering}
          onCreateNewOffer={() =>
            history.push(`/projects/${projectId}/offers/create`)
          }
          onSearchChange={setSearch}
          onTitleClick={handleSendOffer}
          onFilterChange={value => setSelectedStatuses(value.checked)}
        />
      ) : (
        <>
          <PageHeader
            loading={isLoading}
            tabMargin={false}
            search={{
              value: search,
              placeholder: translations.search,
              left: true,
              onChange: setSearch,
            }}
            buttons={
              canCreateOffer
                ? [
                    {
                      text: translations.createOffer,
                      icon: 'plus',
                      onClick: () =>
                        history.push(`/projects/${projectId}/offers/create`),
                    },
                  ]
                : []
            }
          />

          <Table
            columns={columns}
            rows={rows}
            options={options}
            noRowsText={
              !!debouncedSearch ? translations.noResults : translations.noData
            }
            initialSort={sort}
            onFilterChange={value => setSelectedStatuses(value.checked)}
            onSortingChange={setSort}
          />
        </>
      )}

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </Wrapper>
  )
}

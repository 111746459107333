import gql from 'graphql-tag'

export const CREATE_OFFER_MUTATION = gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
      offer {
        id
      }
    }
  }
`

export const PATCH_OFFER_MUTATION = gql`
  mutation PatchOffer($id: ID!, $input: PatchOfferInput!) {
    patchOffer(id: $id, input: $input) {
      offer {
        id
      }
    }
  }
`

export const DELETE_OFFER_MUTATION = gql`
  mutation DeleteOffer($id: ID!) {
    deleteOffer(id: $id) {
      found
      deletedId
    }
  }
`

export const SEND_OFFER_EMAIL_MUTATION = gql`
  mutation SendOfferEmail(
    $recipient: String!
    $offerId: ID!
    $projectId: ID
    $tz: String
  ) {
    sendOfferEmail(
      recipient: $recipient
      offerId: $offerId
      projectId: $projectId
      tz: $tz
    ) {
      emailSent
    }
  }
`

import {
  Toggle as BaseToggle,
  ToggleProps as BaseToggleProps,
} from '@ur/react-components'
import styled from 'styled-components'

const StyledToggle = styled(BaseToggle)``

interface ToggleProps extends BaseToggleProps {}

export const Toggle: React.FC<ToggleProps> = ({ ...props }) => {
  return <StyledToggle {...props} />
}

import { useMutation, useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React, { useLayoutEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { useBreadcrumbOverrides, useOnErrorAuto, useToast } from 'util/hooks'
import { OfferForm } from './components/OfferForm'
import { PATCH_OFFER_MUTATION } from './mutations'
import { OFFER_QUERY } from './queries'
import {
  OfferQuery,
  PatchOfferMutation,
  PatchOfferMutationVariables,
} from './types.graphql'
import { isPatchOfferMutationVariables } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const EditOffer: React.FC = () => {
  const translations = useTranslate({
    editOffer: 'offers.edit-offer',
    editSuccess: 'offers.toasts.edit-offer-success',
  })

  const { projectId, offerId } =
    useParams<{ projectId: string; offerId: string }>()
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()
  const addToast = useToast()
  const [, setHeaderIcon] = useGlobal('header.icon')

  useLayoutEffect(() => setHeaderIcon(null), [setHeaderIcon])

  const { loading, data } = useQuery<OfferQuery, IdVariable>(OFFER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: offerId,
    },
    onError: onErrorAuto(),
  })

  const offer = useMemo(() => data?.offer, [data])

  useBreadcrumbOverrides({
    [projectId]: data?.offer.project.name ?? projectId,
    [offerId]: offer?.title ?? offerId,
    edit: translations.editOffer,
  })

  const [patchOffer, { loading: patchLoading }] = useMutation<
    PatchOfferMutation,
    PatchOfferMutationVariables
  >(PATCH_OFFER_MUTATION, {
    refetchQueries: ['Offer'],
    onCompleted() {
      addToast('success', translations.editSuccess)
      history.push(`/projects/${projectId}/offers`)
    },
  })

  const isLoading = useMemo(
    () => loading || patchLoading,
    [loading, patchLoading]
  )

  return (
    <Wrapper>
      {!isMobileOnly && (
        <PageHeader title={translations.editOffer} loading={isLoading} />
      )}

      <OfferForm
        offer={offer}
        projectId={projectId}
        defaultHourlyRate={offer?.project.hourlyRate ?? 0}
        loading={isLoading}
        onSubmit={variables =>
          isPatchOfferMutationVariables(variables) && patchOffer({ variables })
        }
      />
    </Wrapper>
  )
}

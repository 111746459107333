import { useForm, useTranslate } from '@ur/react-hooks'
import { Button, FormField as BaseFormField, Input, TextArea } from 'components'
import React from 'react'
import styled from 'styled-components'
import { validateNonEmpty } from 'util/validation'
import {
  CreateChecklistTemplateItemMutationVariables,
  PatchChecklistTemplateItemMutationVariables,
} from '../types.graphql'

const Wrapper = styled.div`
  width: 100%;

  div.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding-bottom: 1rem;
  }
`
const FormField = styled(BaseFormField)`
  label {
    font-size: 0.9rem;
  }

  & + div {
    margin-top: 1.5rem;

    ${props => props.theme.media.mobile} {
      margin-top: 1rem;
    }
  }
`
interface TemplateItemForm {
  title: string
  description: string
}

interface TemplateItem {
  id: string
  title: string
  description: string | null
}

interface CreateEditTemplateItemProps {
  loading: boolean
  templateId: string
  item?: TemplateItem

  onCreate?: (
    variables: CreateChecklistTemplateItemMutationVariables
  ) => Promise<unknown>
  onPatch?: (
    variables: PatchChecklistTemplateItemMutationVariables
  ) => Promise<unknown>
  onClose: () => void
}

export const CreateEditTemplateItem: React.FC<CreateEditTemplateItemProps> = ({
  loading,
  templateId,
  item,

  onCreate,
  onPatch,
  onClose,
}) => {
  const translations = useTranslate({
    add: 'common.add',
    cancel: 'common.cancel',
    save: 'common.save',

    form: {
      title: 'common.title',
      description: 'common.description',
    },

    validation: {
      required: 'common.required',
    },
  })

  const {
    formValues: form,
    formErrors: errors,
    formEdited,
    formValid,
    formChangeHandler: handler,
    submitHandler,
    resetForm,
  } = useForm<TemplateItemForm>({
    values: {
      title: item?.title ?? '',
      description: item?.description ?? '',
    },
    validators: {
      title: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  async function handleSubmit(values: TemplateItemForm) {
    if (!!item && !!onPatch)
      await onPatch({
        id: item.id,
        input: {
          title: values.title,
          description: values.description,
        },
      })
    else if (!!onCreate)
      await onCreate({
        input: {
          checklistTemplate: templateId,
          title: values.title,
          description: values.description,
        },
      })

    handleClose()
  }

  function handleClose() {
    onClose()
    resetForm()
  }

  return (
    <Wrapper>
      <FormField required error={!!errors.title}>
        <label>{translations.form.title}</label>

        <Input
          value={form.title}
          error={errors.title}
          disabled={loading}
          autoFocus
          fullWidth
          onChange={handler('title')}
        />
      </FormField>

      <FormField error={!!errors.description}>
        <label>{translations.form.description}</label>

        <TextArea
          value={form.description}
          error={errors.description}
          disabled={loading}
          fullWidth
          onChange={handler('description')}
        />
      </FormField>

      <div className="buttons">
        <Button background="gray3" disabled={loading} onClick={handleClose}>
          {translations.cancel}
        </Button>

        <Button
          disabled={loading || !formEdited || !formValid}
          loading={loading}
          onClick={submitHandler(handleSubmit)}
        >
          {!!item ? translations.save : translations.add}
        </Button>
      </div>
    </Wrapper>
  )
}

import { gql } from '@apollo/client'

export const DEVIATION_QUERY = gql`
  query Deviation($id: ID!) {
    deviation(id: $id) {
      id
      title
      date
      active
      treated
      createdBy {
        id
        fullName
      }
      createdAt
      description
      suggestedAction
      categories {
        edges {
          node {
            id
            title
            color
          }
        }
      }
      project {
        id
        name
      }
      images {
        edges {
          node {
            id
            title
            image
            imageCompressed
          }
        }
      }
    }
  }
`

export const DEVIATION_PERFORMED_ACTIONS_QUERY = gql`
  query DeviationPerformedActions($id: ID!) {
    deviation(id: $id) {
      id
      performedActions {
        edges {
          node {
            id
            description
            createdBy {
              id
              fullName
            }
            createdAt
          }
        }
      }
    }
  }
`

export const DEVIATIONS_SHALLOW_QUERY = gql`
  query DeviationsShallow(
    $q: String
    $orderBy: String
    $project: ID
    $categories: [ID]
    $active: Boolean
    $treated: Boolean
    $first: Int
    $after: String
    $status: [String]
  ) {
    hasDeviations
    allDeviations(
      q: $q
      orderBy: $orderBy
      project: $project
      categories: $categories
      active: $active
      treated: $treated
      first: $first
      after: $after
      status: $status
    ) {
      edges {
        node {
          id
          title
          project {
            id
            name
            customer {
              id
              name
            }
          }
          treated
          active
          date
          categories {
            edges {
              node {
                id
                title
                color
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const INTERNAL_DEVIATIONS_SHALLOW_QUERY = gql`
  query InternalDeviationsShallow(
    $q: String
    $categories: [ID]
    $active: Boolean
    $treated: Boolean
    $orderBy: String
    $first: Int
    $after: String
    $status: [String]
  ) {
    hasInternalDeviations
    allInternalDeviations(
      q: $q
      active: $active
      categories: $categories
      treated: $treated
      orderBy: $orderBy
      first: $first
      after: $after
      status: $status
    ) {
      edges {
        node {
          id
          title
          description
          active
          treated
          date
          categories {
            edges {
              node {
                id
                title
                color
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const ALL_CATEGORIES_QUERY = gql`
  query AllCategoriesQuery {
    allDeviationCategories {
      edges {
        node {
          id
          color
          title
        }
      }
    }
  }
`

import gql from 'graphql-tag'

export const OFFER_QUERY = gql`
  query Offer($id: ID!) {
    offer(id: $id) {
      id
      title
      description
      hourlyRate
      installationTime
      installationEquipmentCost
      contributionPercentage
      project {
        id
        name
        customer {
          id
          contactPersonEmail
        }
        hourlyRate
      }
      contributionMargin
      totalCost
    }
  }
`

export const OFFERS_SHALLOW_QUERY = gql`
  query OffersShallow(
    $q: String
    $status: [String]
    $orderBy: String
    $project: ID
    $first: Int
    $after: String
  ) {
    allOffers(
      q: $q
      status: $status
      orderBy: $orderBy
      project: $project
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          description
          totalCost
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

import { TableSorting } from './types'

interface TableSortingAction {
  sort: TableSorting
  id: string
  singleSort: boolean
}

export function tableSortingReducer(eventListener?: (value: string) => void) {
  return (state: string[], action: TableSortingAction) => {
    let sorting = state.filter(
      sort => sort !== action.id && sort !== `-${action.id}`
    )

    switch (action.sort) {
      case 'desc':
        sorting = action.singleSort ? [action.id] : [action.id, ...sorting]
        break
      case 'asc':
        sorting = action.singleSort
          ? [`-${action.id}`]
          : [`-${action.id}`, ...sorting]
        break
    }

    eventListener?.(sorting.join(','))
    return sorting
  }
}

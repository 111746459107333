import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ok
      token
      user {
        id
        companies {
          edges {
            node {
              id
              shortName
            }
          }
        }
      }
    }
  }
`

import { gql } from '@apollo/client'

export const EMAIL_TAKEN_QUERY = gql`
  query EmailTaken($email: String!) {
    emailTaken(email: $email)
  }
`

export const SHORT_NAME_TAKEN_QUERY = gql`
  query ShortNameTaken($shortName: String!) {
    shortNameTaken(shortName: $shortName)
  }
`

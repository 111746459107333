import {
  CreateProductMutationVariables,
  PatchProductMutationVariables,
} from '../types.graphql'

export function isCreateProductMutationVariables(
  arg: CreateProductMutationVariables | PatchProductMutationVariables
): arg is CreateProductMutationVariables {
  return !arg.hasOwnProperty('id')
}

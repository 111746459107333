import { useTranslate } from '@ur/react-hooks'
import { Card as BaseCard, PageHeader as BasePageHeader } from 'components'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { UserGuideContent } from './UserGuideContent'
import { UserGuideSection, useUserGuideSections } from './sections'
import { MobileDropdown } from './MobileDropdown'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'header  header'
      'sidebar  content';

    column-gap: 4rem;

    a {
      color: ${props => props.theme.colors.gray1};
      text-decoration: none;
    }
  }
  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`
const PageHeader = styled(BasePageHeader)`
  grid-area: header;
`

const Card = styled(BaseCard)`
  height: fit-content;

  ${props => props.theme.media.mobile} {
    padding: 0;
    background-color: inherit;
  }
`
const SidebarWrapper = styled(Card)`
  grid-area: sidebar;

  position: sticky;
  top: 100px;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 1.5rem 1.5rem 0;
`

interface SidebarItemProps {
  active: boolean
  indent: number
}
const SidebarItem = styled.div<SidebarItemProps>`
  display: flex;
  flex-direction: column;

  & > div.title {
    padding: 12px 0;
    padding-left: ${props => props.indent * 1.5 + 1.5}rem;

    color: ${props => props.active && props.theme.colors.primary};
    border-radius: 0 999px 999px 0;
    background: ${props => props.active && props.theme.colors.navBackground};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
    }
  }
`

export type UserGuideTab =
  | 'introduction'
  | 'dashboard'
  | 'projects'
  | 'createProject'
  | 'offers'
  | 'files'
  | 'customers'
  | 'users'
  | 'products'
  | 'timesheets'
  | 'checklists'
  | 'deviations'
  | 'handbook'

interface ItemProps {
  section: UserGuideSection
  activeSection: UserGuideSection
  chapter: string
  indent: number

  onClick: (section: UserGuideSection, chapter: string) => void
}
const Item: React.FC<ItemProps> = ({
  section,
  activeSection,
  chapter,
  indent,

  onClick,
}) => {
  function handleClick(evt: React.MouseEvent) {
    evt.stopPropagation()
    onClick(section, chapter)
  }

  return (
    <SidebarItem
      key={section.id}
      indent={indent}
      active={section.id === activeSection.id}
      onClick={handleClick}
    >
      <div className="title">{chapter + '. ' + section.title}</div>

      {section.subSections?.map((sub, idx) => (
        <Item
          key={sub.id}
          section={sub}
          activeSection={activeSection}
          chapter={`${chapter}.${idx + 1}`}
          indent={indent + 1}
          onClick={onClick}
        />
      ))}
    </SidebarItem>
  )
}

export const UserGuide: React.FC = () => {
  const translations = useTranslate({
    userGuide: 'common.user-guide',
  })

  const sections = useUserGuideSections()

  const [activeSection, setActiveSection] = useState<UserGuideSection>(
    sections[0]
  )
  const [activeChapter, setActiveChapter] = useState('1')

  function handleUpdateActiveSection(
    section: UserGuideSection,
    chapter: string
  ) {
    setActiveSection(section)
    setActiveChapter(chapter)
  }

  return (
    <Wrapper>
      {isMobileOnly ? (
        <div style={{ padding: '0 1rem' }}>
          <MobileDropdown
            sections={sections}
            activeSection={activeSection}
            activeChapter={activeChapter}
            onChangeActiveSection={handleUpdateActiveSection}
          />
        </div>
      ) : (
        <>
          <PageHeader title={translations.userGuide} />

          <SidebarWrapper>
            {sections.map((section, idx) => (
              <Item
                key={section.id}
                section={section}
                activeSection={activeSection}
                chapter={'' + (idx + 1)}
                indent={0}
                onClick={handleUpdateActiveSection}
              />
            ))}
          </SidebarWrapper>
        </>
      )}

      <Card>
        <UserGuideContent scrollToItem={activeSection.id} />
      </Card>
    </Wrapper>
  )
}

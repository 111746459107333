import { css, DefaultTheme } from 'styled-components'
import { theme } from 'theme'

/**
 * This function returns props.theme.colors[props.color] if it exists, otherwise
 * props.color.
 *
 * @param {Object} props The props passed to a component.
 */
export function overloadColorProp<
  T extends { color?: string; theme: DefaultTheme }
>(props: T) {
  if (!props.color) return props.color

  return (
    (props.theme.colors[
      props.color as keyof typeof props.theme.colors
    ] as unknown as string) ?? props.color
  )
}
export function overloadColor(color: string) {
  return overloadColorProp({ theme, color })
}

export function validateHex(hex: string) {
  if (!hex) return null
  if (hex.charAt(0) === '#') hex = hex.substring(1)
  if (
    hex.length !== 3 &&
    hex.length !== 4 &&
    hex.length !== 6 &&
    hex.length !== 8
  ) {
    return null
  }

  if (hex.length < 6)
    hex = hex.split('').reduce((acc, cur) => acc + cur + cur, '')

  if (!/^([a-f0-9]{2}){3,4}$/i.test(hex)) {
    return null
  }

  return hex
}

export function hexWithAlpha(hex: string, alpha: number) {
  const validated = validateHex(hex)
  if (!validated) return null

  const red = parseInt(validated.substring(0, 2), 16)
  const green = parseInt(validated.substring(2, 4), 16)
  const blue = parseInt(validated.substring(4, 6), 16)

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export function rgbFromHex(hex: string) {
  const validated = validateHex(hex)
  if (!validated) return null

  const red = parseInt(validated.substring(0, 2), 16)
  const green = parseInt(validated.substring(2, 4), 16)
  const blue = parseInt(validated.substring(4, 6), 16)

  return [red, green, blue]
}

export function randomHexColor(withHash = true) {
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)

  const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')
  return `${withHash ? '#' : ''}${hex}`
}

export function contrastColor(
  hex: string,
  lightColor = 'white',
  darkColor = 'text',
  fallback = 'text'
) {
  if (!hex) return overloadColor(fallback)

  const rgb = rgbFromHex(hex)
  if (!rgb) return hex

  const [r, g, b] = rgb
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  if (luminance > 0.5) return overloadColor(darkColor)
  else return overloadColor(lightColor)
}

/**
 * Change luminence of color
 * @param hex Color in hexadecimal format
 * @param amount Amount with which to lighten or darken color
 * @see https://css-tricks.com/snippets/javascript/lighten-darken-color/
 */
export function changeLuminence(hex: string, amount: number) {
  if (!validateHex(hex)) return hex

  let usePound = false
  if (hex[0] === '#') {
    hex = hex.slice(1)
    usePound = true
  }

  const num = parseInt(hex, 16)

  let r = (num >> 16) + amount
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amount
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amount
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (
    (usePound ? '#' : '') +
    (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
  )
}

export function contrastColorNicely(hex: string) {
  if (!hex) return overloadColor(hex) ?? hex

  const rgb = rgbFromHex(hex)
  if (!rgb) return hex

  const [r, g, b] = rgb
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return changeLuminence(hex, luminance < 0.5 ? 130 : -130)
}

export const buttonUnset = css`
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: inherit;
  appearance: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
`

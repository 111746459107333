import { useTranslate } from '@ur/react-hooks'
import {
  Table,
  TableColumn,
  TableFiltering,
  TableFilteringChecklistOptions,
  TableMenu,
  TableOptions,
  TableRow,
  UserThumbOrInitials,
} from 'components'
import React, { useCallback, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { AbsenceType, TableAbsences } from './types.graphql'

const Reason = styled.div`
  white-space: nowrap;
  overflow: hidden;

  span.none {
    font-style: italic;
    color: ${props => props.theme.colors.gray3};
  }
`

interface AbsenceTableProps {
  absences: TableAbsences[]
  userFilter: TableFilteringChecklistOptions
  initialSort?: string

  makeMenu: (absences: TableAbsences) => TableMenu
  onSortingChange: (value: string) => void
  onFilterChange: (value: TableFiltering) => void
}

export const AbsenceTable: React.FC<AbsenceTableProps> = ({
  absences,
  userFilter,
  initialSort,

  makeMenu,
  onFilterChange,
  onSortingChange,
}) => {
  const translations = useTranslate({
    reason: 'common.reason',
    type: 'common.type',
    user: 'common.user',
    date: 'common.date',
    hours: 'common.hours',

    absenceTypes: {
      absence_vacation: 'timesheets.absence-type-vacation',
      absence_illness_self_declaration:
        'timesheets.absence-type-illness-self-declaraion',
      absence_illness_sick_leave: 'timesheets.absence-type-illness-sick-leave',
      absence_paid_leave: 'timesheets.absence-type-paid-leave',
      absence_timebank_withdrawal:
        'timesheets.absence-type-timebank-withdrawal',
      absence_unpaid_leave: 'timesheets.absence-unpaid-leave',
    },

    none: 'common.none',
    nHours: ['common.n-hours', { n: 0 }],
    total: 'common.total-alt',
  })

  const { format } = useDateFns()
  const theme = useTheme()

  const totalHours = useMemo(
    () => absences.reduce((acc, cur) => acc + parseFloat(cur.hours ?? '0'), 0),
    [absences]
  )

  const translatedType = useCallback(
    (absenceType: AbsenceType) => {
      return translations.absenceTypes[
        absenceType.toLowerCase() as Lowercase<AbsenceType>
      ]
    },
    [translations]
  )

  const renderReason = useCallback(
    (reas: string | null) => {
      if (!reas) return <span className="none">{translations.none}</span>
      if (reas.length <= 36) return reas
      return reas.substring(0, 33) + '...'
    },
    [translations.none]
  )

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'reason',
        label: translations.reason,
      },
      {
        id: 'type',
        label: translations.type,
        sortable: true,
      },
      {
        id: 'user',
        label: translations.user,
        sortable: true,
        filtering: userFilter ?? undefined,
      },
      {
        id: 'date',
        label: translations.date,
        sortable: true,
      },
      {
        id: 'hours',
        label: translations.hours,
        sortable: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [
      translations.date,
      translations.hours,
      translations.reason,
      translations.type,
      translations.user,
      userFilter,
    ]
  )

  const rows = useMemo<TableRow[]>(() => {
    const rows: TableRow[] = absences.map(absence => ({
      reason: {
        content: <Reason>{renderReason(absence.reason)}</Reason>,
      },
      type: {
        content: (
          <div>{!!!absence.type ? '' : translatedType(absence.type)}</div>
        ),
      },
      user: {
        content: (
          <UserThumbOrInitials user={absence.employee} cursor="pointer" link />
        ),
      },
      date: {
        content: format(new Date(absence.date), 'PPP'),
      },
      hours: {
        content: translations.nHours({ n: parseFloat(absence.hours ?? '0') }),
      },
      menu: {
        content: '',
        menu: makeMenu(absence),
      },
    }))

    rows.push({
      reason: { content: translations.total },
      type: { content: '' },
      user: { content: '' },
      date: { content: '' },
      hours: { content: translations.nHours({ n: totalHours }) },
      menu: { content: '' },
    })

    return rows
  }, [
    absences,
    format,
    makeMenu,
    renderReason,
    totalHours,
    translatedType,
    translations,
  ])

  const options = useMemo<TableOptions>(
    () => ({
      fillHorizontal: true,
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color: ['date', 'timeSum', 'distance'].includes(cell.columnId)
            ? theme.colors.gray3
            : 'currentColor',
          fontWeight: cell.isLastRow ? 600 : 'inherit',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      options={options}
      initialSort={initialSort}
      onSortingChange={onSortingChange}
      onFilterChange={onFilterChange}
    />
  )
}

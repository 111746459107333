import { useTranslate } from '@ur/react-hooks'
import {
  Table,
  TableColumn,
  TableFiltering,
  TableMenu,
  TableOptions,
  TableRow,
  UserThumbOrInitials,
} from 'components'
import omit from 'lodash/omit'
import { getProjectsFiltering } from 'modules/deviations/util'
import { ShallowProject } from 'modules/projects/types.graphql'
import { ShallowUser } from 'modules/users'
import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useDateFns } from 'util/hooks'
import { ChecklistStatus } from './components/ChecklistStatus'
import { ChecklistStatusType } from './types'
import { ShallowChecklist } from './types.graphql'
import { getChecklistStatusFiltering } from './util'

const Wrapper = styled.div``

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryHover};
  }
`

type ChecklistsTableColumn =
  | 'title'
  | 'projectName'
  | 'customerName'
  | 'purpose'
  | 'user'
  | 'status'
  | 'modifiedDate'

interface ChecklistsTableProps {
  checklists: ShallowChecklist[]
  projects?: ShallowProject[]
  users?: ShallowUser[]

  excludeColumns?: ChecklistsTableColumn[]
  search: string
  selectedStatuses: ChecklistStatusType[]
  makeMenu: (deviation: ShallowChecklist) => TableMenu

  onFilterChange: (value: TableFiltering) => void
  onSortingChange: (value: string) => void
}

export const ChecklistsTable: React.FC<ChecklistsTableProps> = ({
  checklists,
  projects,

  excludeColumns = [],
  search,
  selectedStatuses,
  makeMenu,

  onFilterChange,
  onSortingChange,
}) => {
  const { format } = useDateFns()
  const theme = useTheme()
  const history = useHistory()

  const translations = useTranslate({
    title: 'common.title',
    project: 'common.project',
    customer: 'common.customer',
    purpose: 'common.purpose',
    user: 'common.user',
    status: 'common.status',
    date: 'common.date', // todo endre

    noData: 'common.no-data',
    noResults: 'common.no-results',

    statuses: {
      ongoing: 'projects.stage-ongoing',
      finished: 'projects.stage-finished',
    },
  })

  const projectsFilter = useMemo(
    () => (!!projects ? getProjectsFiltering(projects) : null),
    [projects]
  )
  const statusFilter = useMemo(
    () => getChecklistStatusFiltering(translations.statuses, selectedStatuses),
    [selectedStatuses, translations.statuses]
  )

  const columns = useMemo<TableColumn[]>(() => {
    const columns: TableColumn[] = [
      {
        id: 'title',
        label: translations.title,
        sortable: true,
      },
      {
        id: 'projectName',
        label: translations.project,
        sortable: true,
        filtering: projectsFilter ?? undefined,
      },
      {
        id: 'customerName',
        label: translations.customer,
        sortable: true,
      },
      {
        id: 'purpose',
        label: translations.purpose,
        sortable: true,
      },
      {
        id: 'user',
        label: translations.user,
        sortable: true,
      },
      {
        id: 'modifiedDate',
        label: translations.date,
        sortable: true,
      },
      {
        id: 'status',
        label: translations.status,
        filtering: statusFilter ?? undefined,
        shrink: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ]

    return columns.filter(
      ({ id }) => !excludeColumns.includes(id as ChecklistsTableColumn)
    )
  }, [
    excludeColumns,
    projectsFilter,
    statusFilter,
    translations.customer,
    translations.date,
    translations.project,
    translations.purpose,
    translations.status,
    translations.title,
    translations.user,
  ])

  const rows = useMemo<TableRow[]>(
    () =>
      checklists.map(checklist => {
        const date = format(new Date(checklist.modifiedDate), 'PPP')
        const project = checklist.project
        const customer = project.customer

        const row: TableRow = {
          title: {
            content: (
              <Link to={`/projects/${project.id}/checklists/${checklist.id}`}>
                {checklist.title}
              </Link>
            ),
          },
          projectName: {
            content: <Link to={`/projects/${project.id}`}>{project.name}</Link>,
          },
          customerName: {
            content: !!customer ? (
              <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
            ) : (
              ''
            ),
          },
          purpose: {
            content: checklist.purpose,
          },
          user: {
            content: !!checklist.createdBy ? (
              <UserWrapper
                onClick={() =>
                  history.push(`/users/${checklist.createdBy?.id}`)
                }
              >
                <UserThumbOrInitials user={checklist.createdBy} />
                {checklist.createdBy.fullName}
              </UserWrapper>
            ) : (
              ''
            ),
          },
          status: {
            content: (
              <ChecklistStatus
                progress={checklist.progress}
                closed={checklist.closed}
              />
            ),
          },
          modifiedDate: { content: date },
          menu: { content: '', menu: makeMenu(checklist) },
        }

        return omit(row, excludeColumns)
      }),
    [checklists, excludeColumns, format, history, makeMenu]
  )

  const options = useMemo<TableOptions>(
    () => ({
      fillHorizontal: true,
      style: {
        boxShadow: 'none',
      },
      cell: cell => ({
        style: {
          color: cell.columnId === 'date' ? theme.colors.gray3 : 'currentColor',
          fontWeight: cell.columnId === 'title' ? 600 : 'inherit',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Wrapper>
      <Table
        columns={columns}
        rows={rows}
        options={options}
        noRowsText={!!search ? translations.noResults : translations.noData}
        onFilterChange={onFilterChange}
        onSortingChange={onSortingChange}
      />
    </Wrapper>
  )
}

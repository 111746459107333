import { useMutation } from '@apollo/client'
import { useGlobal, UseTranslateProvider } from '@ur/react-hooks'
import messages from 'locale/messages'
import { AllowedLanguage } from 'locale/util'
import {
  SetUserSettingMutation,
  SetUserSettingMutationVariables,
} from 'modules/users'
import {
  USER_SETTING_LANGUAGE_DEFAULT,
  USER_SETTING_LANGUAGE_KEY,
} from 'modules/users/consts'
import { SET_USER_SETTING_MUTATION } from 'modules/users/mutations'
import React, { useContext, useMemo } from 'react'
import { IntlProvider } from 'react-intl'

type LanguageContextType = [
  AllowedLanguage,
  (newLanguage: AllowedLanguage) => void
]

const LanguageContext = React.createContext<LanguageContextType>([
  USER_SETTING_LANGUAGE_DEFAULT,
  () => void 0,
])

export const useLanguage = () => useContext(LanguageContext)

interface TranslationProviderProps {}

export const TranslationProvider: React.CFC<TranslationProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useGlobal('settings.language')
  const finalMessages = useMemo(
    () => messages[language ?? USER_SETTING_LANGUAGE_DEFAULT],
    [language]
  )

  const [setUserSetting] = useMutation<
    SetUserSettingMutation,
    SetUserSettingMutationVariables
  >(SET_USER_SETTING_MUTATION)

  function handleSetLanguage(lang: AllowedLanguage) {
    if (lang === language) return
    setLanguage(lang)

    // This should fail silently
    try {
      setUserSetting({
        variables: {
          key: USER_SETTING_LANGUAGE_KEY,
          value: lang,
        },
      })
    } catch {}
  }

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      <UseTranslateProvider locale={language} messages={finalMessages}>
        <IntlProvider locale={language} messages={finalMessages}>
          {children}
        </IntlProvider>
      </UseTranslateProvider>
    </LanguageContext.Provider>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import {
  TableColumn,
  TableFilteringChecklistOptions,
  TableMenu,
  TableOptions,
  TableRow,
} from 'components'
import React, { useCallback, useMemo, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  useCompany,
  useDateFns,
  useOnErrorAuto,
  usePermission,
  useToast,
} from 'util/hooks'
import { useConfirm } from 'util/hooks/useConfirm'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PERMISSIONS } from 'util/permissions'
import { ProjectStage } from '..'
import {
  CreateEditProjectModal,
  CreateEditProjectModalResolve,
} from '../CreateEditProjectModal'
import { CREATE_PROJECT_MUTATION, PATCH_PROJECT_MUTATION } from '../mutations'
import { PROJECTS_SHALLOW_QUERY } from '../queries'
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  PatchProjectMutation,
  PatchProjectMutationVariables,
  ProjectsShallowQuery,
  ProjectsShallowQueryVariables,
  ProjectStage as Stage,
  ShallowProject,
} from '../types.graphql'
import { useProjectsPagination } from './useProjectsPagination'
import mixpanel from 'mixpanel-browser'

interface UseProjectsUtilsOptions {
  queryVariables?: ProjectsShallowQueryVariables
  skipQuery?: boolean
  infiniteScrollElement?: HTMLElement | null

  onQueryCompleted?: () => void
}

export function useProjectsUtils({
  queryVariables,
  skipQuery = false,
  infiniteScrollElement = null,

  onQueryCompleted,
}: UseProjectsUtilsOptions) {
  const translations = useTranslate({
    title: 'common.title',
    customer: 'common.customer',
    date: 'common.date',
    hoursSpent: 'projects.hours-spent',
    estimate: 'common.estimate',
    status: 'common.status',

    offers: 'common.offers',
    timeSpent: 'timesheets.spent-hours',
    checklists: 'common.checklists',
    deviations: 'common.deviations',
    files: 'common.files',

    hours: ['common.n-hours', { n: 0 }],

    edit: 'common.edit',
    delete: 'common.delete',

    stage: {
      billing: 'projects.stage-billing',
      finished: 'projects.stage-finished',
      ongoing: 'projects.stage-ongoing',
      planning: 'projects.stage-planning',
    },
    prompt: {
      deleteProject: 'projects.prompts.delete',
      deleteProjectTitle: 'projects.prompts.delete-title',
    },
    results: {
      queryError: 'server.general-error-try-again-later',

      createError: 'projects.toasts.create-error',
      createSuccess: 'projects.toasts.create-success',
      deleteError: 'projects.delete-error',
      deleteSuccess: 'projects.delete-success',
      editError: 'projects.errors.edit',
      editSuccess: 'projects.edit-success',
    },
  })

  const wasDeletedRef = useRef(false)
  const wasEditedRef = useRef(false)

  const history = useHistory()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const addPrompt = usePrompt()
  const confirm = useConfirm()
  const { defaultHourlyRate } = useCompany()
  const { format } = useDateFns()

  const canCreateProject = usePermission(PERMISSIONS.projects.add.project)
  const canEditProject = usePermission(PERMISSIONS.projects.change.project)
  const canDeleteProject = usePermission(PERMISSIONS.projects.delete.project)

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
  } = useQuery<ProjectsShallowQuery, ProjectsShallowQueryVariables>(
    PROJECTS_SHALLOW_QUERY,
    {
      skip: skipQuery,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: queryVariables,
      onCompleted: onQueryCompleted,
      onError: onErrorAuto(),
    }
  )

  const data = useMemo(
    () => currentData ?? previousData,
    [currentData, previousData]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useProjectsPagination(data?.allProjects.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore, { element: infiniteScrollElement })

  const [createProjectMutation, { loading: createLoading }] = useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION, {
    refetchQueries: ['ProjectsShallow'],
    onCompleted(data) {
      addToast('success', translations.results.createSuccess)
      history.push(`/projects/${data.createProject.project.id}/info`)
      mixpanel.track('Create Project', {
        Context: 'useProjectUtils',
      })
    },
    onError: onErrorAuto(translations.results.createError),
  })

  const [patchProjectMutation, { loading: patchLoading }] = useMutation<
    PatchProjectMutation,
    PatchProjectMutationVariables
  >(PATCH_PROJECT_MUTATION, {
    refetchQueries: ['ProjectsShallow'],
    onCompleted({ patchProject }) {
      mixpanel.track('Edit Project', {
        Context: 'useProjectUtils',
      })
      if (wasDeletedRef.current === true) {
        addToast('success', translations.results.deleteSuccess)
        wasDeletedRef.current = false
      } else {
        addToast('success', translations.results.editSuccess)
        if (wasEditedRef.current === true) {
          wasEditedRef.current = false
          history.push(`/projects/${patchProject.project.id}/info`)
        }
      }
    },
    onError: onErrorAuto(
      wasDeletedRef.current
        ? translations.results.deleteError
        : translations.results.editError,
      {
        callback() {
          wasEditedRef.current = false
          wasDeletedRef.current = false
        },
      }
    ),
  })

  const projects = useMemo(
    () => data?.allProjects.edges.map(edge => edge.node) ?? [],
    [data]
  )

  const hasProjects = useMemo(() => !!data?.hasProjects, [data])

  const statusFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      items: (
        [
          ['planning', 'information'],
          ['ongoing', 'warning2'],
          ['billing', 'success'],
          ['finished', 'gray2'],
        ] as const
      ).map(([stage, dotColor]) => ({
        id: stage,
        text: translations.stage[stage],
        dotColor,
        initUnchecked: !(queryVariables?.stages ?? []).includes(stage),
      })),
    }),
    [queryVariables?.stages, translations.stage]
  )

  const handleChangeStage = useCallback(
    (project: ShallowProject, newStage: Stage) => {
      if (!canEditProject) return

      patchProjectMutation({
        variables: {
          id: project.id,
          input: {
            stage: newStage,
          },
        },
      })
    },
    [canEditProject, patchProjectMutation]
  )

  /* Handlers */

  async function handleCreateProject() {
    if (!canCreateProject) return

    const { data } = await addPrompt<CreateEditProjectModalResolve | null>(
      resolve => (
        <CreateEditProjectModal
          companyDefaultHourlyRate={defaultHourlyRate}
          onSubmit={resolve}
        />
      )
    )

    if (!data) return

    createProjectMutation({
      variables: { input: data },
    })
  }

  const handleEditProject = useCallback(
    async (projectId: string) => {
      if (!canEditProject) return

      const { data } = await addPrompt<CreateEditProjectModalResolve | null>(
        resolve => (
          <CreateEditProjectModal
            projectId={projectId}
            companyDefaultHourlyRate={defaultHourlyRate}
            onSubmit={resolve}
          />
        )
      )
      if (!data) return

      wasEditedRef.current = true
      patchProjectMutation({
        variables: { id: projectId, input: data },
      })
    },
    [addPrompt, canEditProject, defaultHourlyRate, patchProjectMutation]
  )

  const handleDeleteProject = useCallback(
    async (project: ShallowProject) => {
      if (!canDeleteProject) return

      const answer = await confirm(
        translations.prompt.deleteProject,
        translations.prompt.deleteProjectTitle,
        {
          variant: 'delete',
        }
      )
      if (!answer) return

      wasDeletedRef.current = true
      patchProjectMutation({
        variables: {
          id: project.id,
          input: {
            deletedAt: new Date(),
          },
        },
      })
    },
    [
      canDeleteProject,
      confirm,
      translations.prompt.deleteProject,
      translations.prompt.deleteProjectTitle,
      patchProjectMutation,
    ]
  )

  /* Utilities */

  const makeEditDeleteMenu = useCallback(
    (project: ShallowProject) => {
      const items: TableMenu = {
        items: [
          {
            icon: 'edit',
            text: translations.edit,
            hide: !canEditProject,
            onClick: () => handleEditProject(project.id),
          },
          {
            icon: 'trash',
            text: translations.delete,
            color: '#f39b9b',
            hide: !canDeleteProject,
            hoverColor: '#e38080',
            onClick: () => handleDeleteProject(project),
          },
          {
            separator: true,
          },
          {
            icon: { icon: 'receipt', type: 'solid', fixedWidth: true },
            text: translations.offers + ` (${project.numOffers})`,
            onClick: () => history.push(`/projects/${project.id}/offers`),
          },
          {
            icon: 'business-time',
            text: translations.timeSpent + ` (${project.totalHours})`,
            onClick: () => history.push(`/projects/${project.id}/timesheets`),
          },
          {
            icon: 'exclamation-triangle',
            text: translations.deviations + ` (${project.numDeviations})`,
            onClick: () => history.push(`/projects/${project.id}/deviations`),
          },
          {
            icon: 'clipboard-list',
            text: translations.checklists + ` (${project.numChecklists})`,
            onClick: () => history.push(`/projects/${project.id}/checklists`),
          },
          {
            icon: 'file',
            text: translations.files + ` (${project.totalFiles})`,
            onClick: () => history.push(`/projects/${project.id}/files`),
          },
        ],
      }

      return items
    },
    [
      canDeleteProject,
      canEditProject,
      handleDeleteProject,
      handleEditProject,
      history,
      translations.checklists,
      translations.delete,
      translations.deviations,
      translations.edit,
      translations.files,
      translations.offers,
      translations.timeSpent,
    ]
  )

  const makeStageMenu = useCallback(
    (project: ShallowProject): TableMenu => ({
      items: (
        [
          ['planning', 'matteTeal'],
          ['ongoing', 'matteOrange'],
          ['billing', 'matteGreen'],
          ['finished', 'inherit'],
        ] as const
      ).map(([stage, color]) => ({
        icon:
          project.stage === stage.toUpperCase()
            ? { icon: 'check-square', type: 'solid' }
            : { icon: 'square', type: 'light' },
        text: translations.stage[stage],
        color,
        onClick: () => handleChangeStage(project, stage.toUpperCase() as Stage),
      })),
    }),
    [handleChangeStage, translations.stage]
  )

  /* Table config */

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'name',
        label: translations.title,
        sortable: true,
      },
      {
        id: 'customerName',
        label: translations.customer,
        sortable: true,
      },
      {
        id: 'createdAt',
        label: translations.date,
        sortable: true,
      },
      {
        id: 'timeEntriesSum',
        label: translations.hoursSpent,
        sortable: true,
      },
      {
        id: 'estimatedCompletionTime',
        label: translations.estimate,
        sortable: true,
      },
      {
        id: 'status',
        label: translations.status,
        filtering: statusFiltering,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
    ],
    [translations, statusFiltering]
  )

  const rows = useMemo<TableRow[]>(
    () =>
      projects.map(project => {
        const date = format(new Date(project.createdAt), 'PPP')

        return {
          name: {
            content: <Link to={`/projects/${project.id}`}>{project.name}</Link>,
          },
          customerName: { content: project.customer?.name ?? '' },
          createdAt: { content: date },
          timeEntriesSum: {
            content: translations.hours({ n: project.totalHours }),
          },
          estimatedCompletionTime: {
            content: translations.hours({
              n: project.estimatedCompletionTime ?? 0,
            }),
          },
          status: {
            content: <ProjectStage project={project} />,
            menu: makeStageMenu(project),
          },
          menu: { content: '', menu: makeEditDeleteMenu(project) },
        }
      }) ?? [],
    [projects, format, translations, makeStageMenu, makeEditDeleteMenu]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          fontWeight: cell.columnId === 'name' ? 600 : 'inherit',
        },
      }),
    }),
    []
  )

  return {
    hasProjects,
    projects,
    columns,
    rows,
    tableOptions,

    canCreateProject,
    handleCreateProject,
    handleFetchMore,

    statusFiltering,
    makeStageMenu,
    makeEditDeleteMenu,

    error,
    loading: loading || createLoading || patchLoading,
    fetchMoreLoading,
    hasMore,
  }
}

import { useTranslate } from '@ur/react-hooks'
import { Permission, UserType } from 'modules/users'
import { useCallback, useMemo } from 'react'
import { PERMISSIONS } from 'util/permissions'
import {
  Module,
  PermissionObject,
  PermissionStatus,
  PermissionStatuses,
  PermissionType,
  Segment,
} from './types'

export function useTranslatePermission() {
  const translations = useTranslate({
    modules: {
      checklists: 'common.checklists',
      companies: 'common.companies',
      companymanuals: 'common.handbook',
      customers: 'common.customers',
      deviations: 'common.deviations',
      files: 'common.files',
      projects: 'common.projects',
      offers: 'common.offers',
      products: 'common.products',
      timesheets: 'common.timesheets',
      users: 'common.users',
    },

    segments: {
      checklist: 'common.checklist',
      checklistitem: 'checklists.checklist-item',
      checklistitemcomment: 'checklists.checklist-item-comment',
      checklistitemimage: 'checklists.checklist-item-image',
      checklisttemplate: 'checklists.checklist-template',
      checklisttemplateitem: 'checklists.checklist-template-item',
      company: 'common.company',
      companyfoldersetting: 'companies.company-folder-settings',
      companysetting: 'companies.company-settings',
      manualentry: 'handbook.entries',
      manualentryattachment: 'common.attachments',
      manualfolder: 'handbook.folders',
      customer: 'common.customer',
      deviation: 'common.deviation',
      deviationcategory: 'common.deviation-categories',
      deviationimage: 'deviations.deviation-image',
      deviationperformedaction: 'deviations.deviation-performed-action',
      file: 'common.file',
      folder: 'common.folder',
      project: 'common.project',
      product: 'products.product',
      productimage: 'products.product-image',
      offer: 'offers.offer',
      absence: 'common.absence',
      timeentry: 'common.time-entry',
      user: 'users.user',
      useremergencycontact: 'common.emergency-contacts',
      usersetting: 'common.user-settings',
      usertype: 'users.user-type',
    },
  })

  const translateModule = useCallback(
    (name: Module) => translations.modules[name],
    [translations.modules]
  )
  const translateSegment = useCallback(
    (name: Segment) => translations.segments[name],
    [translations.segments]
  )

  return {
    translateModule,
    translateSegment,
  }
}

export function useFilterPermissions(selectedUserType: UserType | null) {
  const { excludeApps, excludePermissions, indexes } = useMemo(
    () => ({
      /** Exclude these apps from the result */
      excludeApps: [
        'admin',
        'auth',
        'contenttypes',
        'sessions',
        'permissions',
        'sms',
      ],
      /** Exclude these specific permissions */
      excludePermissions: [
        // Companies
        PERMISSIONS.companies.view.company,
        PERMISSIONS.companies.add.company,
        PERMISSIONS.companies.delete.company,

        // Checklists
        PERMISSIONS.checklists.view.checklistitem,
        PERMISSIONS.checklists.add.checklistitem,
        PERMISSIONS.checklists.change.checklistitem,
        PERMISSIONS.checklists.delete.checklistitem,

        PERMISSIONS.checklists.view.checklistitemimage,

        PERMISSIONS.checklists.view.checklistitemcomment,

        PERMISSIONS.checklists.view.checklisttemplateitem,
        PERMISSIONS.checklists.add.checklisttemplateitem,
        PERMISSIONS.checklists.change.checklisttemplateitem,
        PERMISSIONS.checklists.delete.checklisttemplateitem,

        // Deviations
        PERMISSIONS.deviations.view.deviationimage,
        PERMISSIONS.deviations.add.deviationimage,
        PERMISSIONS.deviations.change.deviationimage,
        PERMISSIONS.deviations.delete.deviationimage,

        PERMISSIONS.deviations.view.deviationperformedaction,

        // Handbook
        PERMISSIONS.companymanuals.view.manualentryattachment,
        PERMISSIONS.companymanuals.add.manualentryattachment,
        PERMISSIONS.companymanuals.change.manualentryattachment,
        PERMISSIONS.companymanuals.delete.manualentryattachment,

        // Products
        PERMISSIONS.products.view.productimage,

        // Users
        PERMISSIONS.users.projectLeader,
        PERMISSIONS.users.view.useremergencycontact,
      ],
      indexes: {
        view: 0,
        add: 1,
        change: 2,
        delete: 3,
      },
    }),
    []
  )

  const extractName = useCallback((name: string) => {
    const spliced = name.split(' ').splice(2)
    return spliced.reduce((acc: string[], cur) => [...acc, cur], []).join(' ')
  }, [])

  const filterPermissions = useCallback(
    (permissions: Permission[]): PermissionStatuses => {
      if (!selectedUserType) return {}

      const mapped: PermissionObject[] = permissions.map(perm => {
        const id = perm.id
        const [app, , type, , ,] = perm.fullName.split(/(\.|_)/g)
        const name = extractName(perm.name)

        return {
          id,
          app,
          type: type as PermissionType,
          name,
          fullName: perm.fullName,
        }
      })

      const userTypePermissions = selectedUserType.permissions
      if (!userTypePermissions) return {}

      return mapped.reduce<PermissionStatuses>((acc, cur: PermissionObject) => {
        if (
          cur.name.toLowerCase().includes('is_administrator') ||
          excludeApps.includes(cur.app) ||
          excludePermissions.includes(cur.fullName)
        )
          return acc

        if (!(cur.app in acc)) acc[cur.app] = {}

        if (!(cur.name in acc[cur.app]))
          acc[cur.app][cur.name] = Array(4).fill(null) as [
            PermissionStatus,
            PermissionStatus,
            PermissionStatus,
            PermissionStatus
          ]

        acc[cur.app][cur.name][indexes[cur.type]] = {
          id: cur.id,
          checked: userTypePermissions.includes(cur.fullName),
          fullName: cur.fullName,
        }
        return acc
      }, {})
    },
    [excludeApps, excludePermissions, extractName, indexes, selectedUserType]
  )

  return filterPermissions
}

export function useSpecialPermissions(
  userType: UserType,
  allPermissions: Permission[]
) {
  const special = useMemo<string[]>(() => ['users.project_leader'], [])
  const specialPermissions = useMemo(
    () => allPermissions.filter(perm => special.includes(perm.fullName)),
    [allPermissions, special]
  )

  return useMemo<PermissionStatus[]>(
    () =>
      specialPermissions.map(perm => ({
        id: perm.id,
        fullName: perm.fullName,
        checked: userType.permissions.includes(perm.fullName),
      })),
    [specialPermissions, userType.permissions]
  )
}

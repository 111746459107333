import { useQuery } from '@apollo/client'
import {
  FormErrors,
  FormOnChangeFn,
  FormValuesEdited,
  useDebounce,
  useTranslate,
} from '@ur/react-hooks'
import logo from 'assets/images/logo.svg'
import {
  Button as BaseButton,
  Card,
  FormField as BaseFormField,
  Input,
} from 'components'
import { useState } from 'react'
import styled from 'styled-components'
import { validateEmail } from 'util/validation'
import { RegisterUserForm } from '.'
import { EMAIL_TAKEN_QUERY } from './queries'
import { EmailTakenQuery, EmailTakenQueryVariables } from './types.graphql'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  ${props => props.theme.media.desktop} {
    margin: 2rem 0;
  }

  ${props => props.theme.media.mobile} {
    height: auto;
  }
`
const Layout = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo      logo'
    'header    header'
    'firstName lastName'
    'email     email'
    'password  confirm'
    'back      next';
  gap: 1.5rem;

  width: clamp(600px, 25%, 800px);

  background-color: ${props => props.theme.colors.white};

  ${props => props.theme.media.mobile} {
    grid-template-areas:
      'logo       logo'
      'header     header'
      'firstName  firstName'
      'lastName   lastName'
      'email      email'
      'password   password'
      'confirm    confirm'
      'back       next';
    gap: 0.5rem;

    width: 100%;
    padding: 1rem;

    box-shadow: none;
    background-color: ${props => props.theme.colors.body};
  }
`
const FormField = styled(BaseFormField)`
  ${props => props.theme.media.mobile} {
    margin-top: 1rem;
  }
`
const Logo = styled.img<{ area: string }>`
  grid-area: ${props => props.area};
  width: 55%;
  margin-top: 1rem;

  ${props => props.theme.media.mobile} {
    width: 45%;
  }
`
const Header = styled.h1<{ area: string }>`
  min-width: 100%;
  padding-top: 1rem;

  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;
  color: ${props => props.theme.colors.gray1};

  ${props => props.theme.media.mobile} {
    margin-bottom: 1rem;
  }
`
interface ButtonProps {
  area: string
}
const Button = styled(BaseButton)<ButtonProps>`
  grid-area: ${props => props.area};
  margin-top: 1rem;
`

interface RegisterNewUserProps {
  form: RegisterUserForm
  errors: FormErrors<RegisterUserForm>
  formValid: boolean
  formValuesEdited: FormValuesEdited<RegisterUserForm>

  onEnableValidation: (
    key: keyof RegisterUserForm,
    run?: boolean,
    enable?: boolean
  ) => void
  onUpdateForm: FormOnChangeFn<RegisterUserForm>
  onNext: () => void
}

export const RegisterNewUser: React.FC<RegisterNewUserProps> = ({
  form,
  errors,
  formValid,
  formValuesEdited,

  onEnableValidation,
  onUpdateForm,
  onNext,
}) => {
  const translations = useTranslate({
    registerUser: 'users.register-user',
    firstName: 'common.first-name',
    lastName: 'common.last-name',
    email: 'common.email',
    phone: 'common.phone',
    post: 'common.postal-code',
    city: 'common.city',
    newPassword: 'users.new-password',
    confirmPassword: 'users.password-confirm',
    address: 'common.address',

    back: 'common.back',
    next: 'common.next',

    validation: {
      required: 'common.required',
      passwordsUnequal: 'users.passwords-do-not-match',
      weakPassword: 'users.errors.change-password-weak',
      invalidEmail: 'errors.invalid-email-address',
      invalidPhoneNumber: 'errors.invalid-phone-number',
      emailTaken: 'users.errors.email-taken',
    },
  })

  const [emailTaken, setEmailTaken] = useState(false)
  const debouncedEmail = useDebounce(form.username)

  const { loading: loadingEmailTaken } = useQuery<
    EmailTakenQuery,
    EmailTakenQueryVariables
  >(EMAIL_TAKEN_QUERY, {
    skip: validateEmail('')(debouncedEmail) !== null,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      email: debouncedEmail,
    },
    onCompleted(data) {
      setEmailTaken(data.emailTaken)
    },
  })

  return (
    <Wrapper>
      <Layout>
        <Logo src={logo} area="logo" />

        <Header area="header">{translations.registerUser}</Header>

        <FormField required area="firstName">
          <label>{translations.firstName}</label>
          <Input
            id="firstName"
            value={form.firstName}
            error={errors.firstName}
            fullWidth
            autoFocus
            onChange={onUpdateForm('firstName')}
          />
        </FormField>

        <FormField required area="lastName">
          <label>{translations.lastName}</label>
          <Input
            id="lastName"
            value={form.lastName}
            error={errors.lastName}
            fullWidth
            onChange={onUpdateForm('lastName')}
          />
        </FormField>

        <FormField required area="email">
          <label>{translations.email}</label>
          <Input
            id="email"
            value={form.username}
            error={
              emailTaken ? translations.validation.emailTaken : errors.username
            }
            inputMode="email"
            fullWidth
            loading={loadingEmailTaken}
            onBlur={() =>
              formValuesEdited.username &&
              onEnableValidation('username', errors.username === null)
            }
            onChange={onUpdateForm('username')}
          />
        </FormField>

        <FormField area="password" required>
          <label>{translations.newPassword}</label>
          <Input
            id="newPassword"
            type="password"
            value={form.password}
            error={errors.password}
            fullWidth
            onChange={onUpdateForm('password')}
          />
        </FormField>

        <FormField area="confirm" required>
          <label>{translations.confirmPassword}</label>
          <Input
            id="newPasswordAgain"
            type="password"
            value={form.newPasswordAgain}
            error={errors.newPasswordAgain}
            fullWidth
            onChange={onUpdateForm('newPasswordAgain')}
          />
        </FormField>

        <Button
          area="back"
          variant="cancel"
          fullWidth
          onClick={() => {
            window.location.href = '/login'
          }}
        >
          {translations.back}
        </Button>

        <Button
          area="next"
          variant="primary"
          fullWidth
          disabled={!formValid || emailTaken || loadingEmailTaken}
          onClick={onNext}
        >
          {translations.next}
        </Button>
      </Layout>
    </Wrapper>
  )
}

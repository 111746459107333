import { PromptResolve } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import { FormField, Input, RegularModal, UserTypeSelect } from 'components'
import React from 'react'
import styled from 'styled-components'
import {
  validateEmail,
  validateNonEmpty,
  validatePhoneNumber,
} from 'util/validation'

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export interface CreateUserModalResolve {
  userType: string
  phoneNumber: string
  email: string
  firstName: string
  lastName: string
}
interface CreateUserForm {
  userType: string | null
  phoneNumber: string
  email: string
  firstName: string
  lastName: string
}

interface CreateNewUserModalProps {
  onSubmit: PromptResolve<CreateUserModalResolve | null>
}
export const CreateUserModal: React.FC<CreateNewUserModalProps> = ({
  onSubmit,
}) => {
  const translations = useTranslate({
    createUser: 'users.create-user',

    userType: 'common.user-type',
    email: 'common.email',
    phone: 'common.phone',
    firstName: 'common.firstName',
    lastName: 'common.lastName',

    validation: {
      required: 'common.required',
      invalidPhoneNumber: 'errors.invalid-phone-number',
      invalidEmail: 'errors.invalid-email-address',
    },
  })

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    enableValidation,
    formChangeHandler: handler,
    submitHandler: submit,
  } = useForm<CreateUserForm>({
    values: {
      userType: null,
      phoneNumber: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    validators: {
      userType: validateNonEmpty(translations.validation.required),
      phoneNumber: [
        validateNonEmpty(translations.validation.required),
        validatePhoneNumber(translations.validation.invalidPhoneNumber),
      ],
      email: [
        validateNonEmpty(translations.validation.required),
        validateEmail(translations.validation.invalidEmail),
      ],
      firstName: validateNonEmpty(translations.validation.required),
      lastName: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
      disableValidationInitially: ['email', 'phoneNumber'],
    },
  })

  function handleSubmit(values: typeof form) {
    if (!values.userType) return
    onSubmit({
      ...values,
      userType: values.userType,
    })
  }

  return (
    <RegularModal
      title={translations.createUser}
      width="480px"
      submitDisabled={!formValid}
      submitText={translations.createUser}
      onSubmit={submit(handleSubmit)}
      onClose={() => onSubmit(null)}
    >
      <Form>
        <FormField error={!!errors.userType} required>
          <label>{translations.userType}</label>

          <UserTypeSelect
            value={form.userType}
            fullWidth
            onChange={handler('userType')}
          />
        </FormField>

        <FormField error={!!errors.phoneNumber} required>
          <label>{translations.phone}</label>

          <Input
            value={form.phoneNumber}
            error={errors.phoneNumber}
            inputMode="tel"
            fullWidth
            onBlur={() => enableValidation('phoneNumber')}
            onChange={handler('phoneNumber')}
          />
        </FormField>

        <FormField required error={!!errors.email}>
          <label>{translations.email}</label>

          <Input
            value={form.email}
            error={errors.email}
            inputMode="email"
            fullWidth
            onBlur={() => enableValidation('email')}
            onChange={handler('email')}
          />
        </FormField>

        <FormField required error={!!errors.firstName}>
          <label>{translations.firstName}</label>

          <Input
            value={form.firstName}
            error={errors.firstName}
            fullWidth
            onChange={handler('firstName')}
          />
        </FormField>

        <FormField required error={!!errors.lastName}>
          <label>{translations.lastName}</label>

          <Input
            value={form.lastName}
            error={errors.lastName}
            fullWidth
            onChange={handler('lastName')}
          />
        </FormField>
      </Form>
    </RegularModal>
  )
}

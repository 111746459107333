import merge from 'lodash/merge'
import throttle from 'lodash/throttle'
import { useLayoutEffect, useMemo, useRef } from 'react'

export interface UseInfiniteScrollOptions {
  threshold?: number
  element?: HTMLElement | null
}

function isDocument(arg: Document | HTMLElement): arg is Document {
  return 'scrollingElement' in arg
}

export function useInfiniteScroll(
  callback: () => void,
  options: UseInfiniteScrollOptions = {}
) {
  const prevScrollTop = useRef(0)

  const defaultOptions = useMemo<Required<UseInfiniteScrollOptions>>(
    () => ({
      threshold: 100,
      element: null,
    }),
    []
  )

  const mergedOptions = useMemo(
    () => merge(defaultOptions, options),
    [defaultOptions, options]
  )

  const throtthledCallback = throttle(callback, 500, {
    leading: true,
    trailing: false,
  })

  useLayoutEffect(() => {
    const getHandler = (element: Document | HTMLElement) => () => {
      const scroller = isDocument(element) ? element.scrollingElement : element
      if (!scroller) return

      const { scrollTop } = scroller
      const prev = prevScrollTop.current
      prevScrollTop.current = scrollTop

      // Ignore if scrolling upwards
      if (scrollTop <= prev) {
        return
      }

      const limit = scroller.scrollHeight - scroller.clientHeight
      if (limit - scrollTop < mergedOptions.threshold) throtthledCallback()
    }

    const element = options.element ?? window.document
    const handler = getHandler(element)
    element.addEventListener('scroll', handler)

    return () => element.removeEventListener('scroll', handler)
  }, [throtthledCallback, mergedOptions.threshold, options.element])
}

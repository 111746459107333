import { useTranslate } from '@ur/react-hooks'
import React, { useLayoutEffect, useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'
import { UserGuideTab } from '.'
import { UserGuideSection, useUserGuideSections } from './sections'

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    font-weight: 600;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    div.content {
      display: flex;
      flex-direction: column;
      align-items: center;

      p + p {
        margin-top: 0;
      }
      ul {
        margin-top: 0;

        li + li {
          margin-top: 6px;
        }
      }
    }
  }

  ${props => props.theme.media.desktop} {
    h1 {
      width: clamp(60%, 600px, 100%);
      margin-bottom: 1rem;
    }

    * :not(.content) {
      width: clamp(60%, 600px, 100%);
    }
    img {
      cursor: pointer;
      transition: transform 0.1s ease-out;

      &:hover {
        transform: scale(102.5%);
      }
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 1rem;

    * {
      width: 100%;
    }

    h2,
    h3 {
      align-self: start;
      margin-bottom: 0;
    }
  }
`

interface SectionProps {
  section: UserGuideSection
  chapter: string
}
const Section: React.FC<SectionProps> = ({ section, chapter }) => {
  return (
    <>
      <section key={section.id} id={section.id}>
        {!chapter.includes('.') ? (
          <h2>{chapter + '. ' + section.title}</h2>
        ) : (
          <h3>{chapter + '. ' + section.title}</h3>
        )}

        <div className="content">{section.content}</div>
      </section>

      {section.subSections?.map((sub, idx) => (
        <Section key={sub.id} section={sub} chapter={`${chapter}.${idx + 1}`} />
      ))}
    </>
  )
}

interface UserGuideContentProps {
  scrollToItem: UserGuideTab
}

export const UserGuideContent: React.FC<UserGuideContentProps> = ({
  scrollToItem,
}) => {
  const translations = useTranslate({
    userGuide: 'common.user-guide',
  })

  const isFirstRender = useRef(true)

  const sections = useUserGuideSections()

  useLayoutEffect(() => {
    if (isFirstRender.current && scrollToItem === 'introduction') {
      isFirstRender.current = false
      return
    }

    const yOffset = !isMobileOnly ? -80 : -60
    const scrollTo = document.getElementById(scrollToItem)
    const y = !!scrollTo
      ? scrollTo.getBoundingClientRect().top + window.pageYOffset + yOffset
      : 0

    window.scrollTo({ top: y, behavior: 'smooth' })
  }, [scrollToItem])

  useLayoutEffect(() => {
    const imgs = document.querySelectorAll('.user-guide-content img')
    const imgArray = Array.from(imgs)

    const handler = (evt: MouseEvent) => {
      const el = evt.target as HTMLImageElement
      if (!el) return

      window.open(el.src, '_blank')
    }

    for (const img of imgArray) {
      if (!('src' in img)) continue
      ;(img as HTMLImageElement).addEventListener('click', handler)
    }

    return () => {
      for (const img of imgArray) {
        if (!('src' in img)) continue
        ;(img as HTMLImageElement).addEventListener('click', handler)
      }
    }
  }, [])

  return (
    <Wrapper className="user-guide-content">
      {!isMobileOnly && <h1>{translations.userGuide}</h1>}

      {sections.map((section, idx) => (
        <Section key={section.id} section={section} chapter={'' + (idx + 1)} />
      ))}
    </Wrapper>
  )
}

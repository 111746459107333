import { PromptResolve, RadioItem, SelectOption } from '@ur/react-components'
import { useForm, useTranslate } from '@ur/react-hooks'
import {
  CustomerSelect,
  FormField,
  Input,
  NumberInput,
  RadioGroup,
  RegularModal,
  TextArea,
  UserSelect,
} from 'components'
import { SelectCustomer } from 'modules/customers/types.graphql'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql'
import { useLazyQuery, useOnErrorAuto } from 'util/hooks'
import { validateNonEmpty } from 'util/validation'
import { EDIT_PROJECT_QUERY } from './queries'
import { EditProjectQuery, ProjectBillingType } from './types.graphql'

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export interface CreateEditProjectModalResolve {
  name: string
  billingType: ProjectBillingType
  customer?: string
  projectLeader?: string
  description: string
  address?: string
  hourlyRate?: number
  materialDiscount: number
  estimatedCompletionTime?: number
}

type CreateEditProjectForm = CreateEditProjectModalResolve & {
  address: string
}

const BillingTypeField = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: 0.5rem 0;

  label {
    font-weight: 600;
    color: ${props => props.theme.colors.gray2};
  }
`

interface CreateEditProjectModalProps {
  projectId?: string
  companyDefaultHourlyRate: number
  customerId?: string

  onSubmit: PromptResolve<CreateEditProjectModalResolve | null>
}
export const CreateEditProjectModal: React.FC<CreateEditProjectModalProps> = ({
  projectId,
  companyDefaultHourlyRate,
  customerId,

  onSubmit,
}) => {
  const isEdit = !!projectId
  const populateForm = useRef(!!projectId)

  const onErrorAuto = useOnErrorAuto()

  const translations = useTranslate({
    createProject: 'projects.create-project',
    editProject: 'projects.edit-project',
    save: 'common.save',

    name: 'common.name',
    projectName: 'projects.name',
    billingType: 'projects.billing-type',
    customer: 'common.customer',
    projectLeader: 'projects.project-leader',
    description: 'common.description',
    address: 'common.address',
    hourlyRate: 'projects.hourly-rate',
    materialDiscount: 'projects.material-discount',
    estimatedCompletionTime: 'projects.estimated-completion-time',

    billingTypes: {
      fixed: 'projects.billing-type.fixed',
      hourly: 'projects.billing-type.hourly',
      interal: 'projects.billing-type.internal',
    },

    validation: {
      required: 'common.required',
      invalidPhoneNumber: 'errors.invalid-phone-number',
      invalidEmail: 'errors.invalid-email-address',
    },
  })

  const {
    formValues: form,
    formErrors: errors,
    formValid,
    formEdited,
    updateInitialValues,
    formChangeHandler: handler,
    submitHandler: submit,
    updateForm,
  } = useForm<CreateEditProjectForm>({
    values: {
      name: '',
      billingType: 'HOURLY',
      customer: !!customerId ? customerId : undefined,
      projectLeader: undefined,
      description: '',
      address: '',
      hourlyRate: undefined,
      materialDiscount: 0,
      estimatedCompletionTime: undefined,
    },
    validators: {
      name: validateNonEmpty(translations.validation.required),
      billingType: validateNonEmpty(translations.validation.required),
      customer: val =>
        validateNonEmpty(translations.validation.required)(val ?? ''),
      projectLeader: val =>
        validateNonEmpty(translations.validation.required)(val ?? ''),
      description: validateNonEmpty(translations.validation.required),
    },
    config: {
      initAsInvalid: true,
    },
  })

  const [fetch] = useLazyQuery<EditProjectQuery, IdVariable>(
    EDIT_PROJECT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onCompleted({ project }) {
        const newForm = {
          name: project.name,
          billingType: project.billingType,
          customer: project.customer?.id ?? undefined,
          projectLeader: project.projectLeader?.id ?? undefined,
          description: project.description,
          address: project.address ?? undefined,
          hourlyRate: project.hourlyRate ?? undefined,
          materialDiscount: project.materialDiscount,
          estimatedCompletionTime: project.estimatedCompletionTime ?? undefined,
        }
        updateForm(newForm)
        updateInitialValues(newForm)
      },
      onError: onErrorAuto(),
    }
  )

  useEffect(() => {
    if (populateForm.current && isEdit) {
      populateForm.current = false

      fetch({
        variables: { id: projectId },
      })
    }
  }, [fetch, isEdit, projectId])

  function handleSubmit(values: typeof form) {
    if (!formValid) return
    onSubmit({
      ...values,
    })
  }

  const billingTypeRadios: RadioItem<ProjectBillingType>[] = [
    {
      value: 'HOURLY',
      label: translations.billingTypes.hourly,
    },
    {
      value: 'FIXED',
      label: translations.billingTypes.fixed,
    },
    {
      value: 'INTERNAL',
      label: translations.billingTypes.interal,
    },
  ]

  function handleUpdateCustomer(
    customer: SelectOption<string, SelectCustomer>
  ) {
    // Auto-populate the projects hourly rate based on the customer's (or company's) default
    if (typeof customer.extra?.defaultHourlyRate === 'undefined')
      updateForm({
        customer: customer.value,
        hourlyRate: companyDefaultHourlyRate,
      })
    else
      updateForm({
        customer: customer.value,
        hourlyRate: customer.extra.defaultHourlyRate,
      })
  }

  return (
    <RegularModal
      title={isEdit ? translations.editProject : translations.createProject}
      width="480px"
      submitDisabled={!formValid || !formEdited}
      submitText={isEdit ? translations.save : translations.createProject}
      overflowY="auto"
      onSubmit={submit(handleSubmit)}
      onClose={() => onSubmit(null)}
    >
      <Form>
        <FormField required>
          <label>{translations.name}</label>

          <Input
            value={form.name}
            error={errors.name}
            autoFocus
            fullWidth
            onChange={handler('name')}
          />
        </FormField>

        <BillingTypeField>
          <RadioGroup
            value={form.billingType}
            radios={billingTypeRadios}
            layout={{ direction: 'horizontal', type: 'flex', gap: '2rem' }}
            checkboxProps={{
              labelPosition: 'left',
            }}
            onChange={handler('billingType')}
          />
        </BillingTypeField>

        <FormField required>
          <label>{translations.customer}</label>

          <CustomerSelect
            value={form.customer ?? null}
            error={errors.customer}
            fullWidth
            onChange={(_, option) => !!option && handleUpdateCustomer(option)}
          />
        </FormField>

        <FormField required>
          <label>{translations.projectLeader}</label>

          <UserSelect
            value={form.projectLeader ?? null}
            error={errors.projectLeader}
            onlyProjectLeaders
            fullWidth
            onChange={value =>
              updateForm({ projectLeader: value ?? undefined })
            }
          />
        </FormField>

        <FormField required>
          <label>{translations.description}</label>

          <TextArea
            value={form.description ?? ''}
            error={errors.description}
            fullWidth
            height="100px"
            onChange={handler('description')}
          />
        </FormField>

        <FormField>
          <label>{translations.address}</label>

          <Input
            value={form.address}
            error={errors.address}
            fullWidth
            onChange={handler('address')}
          />
        </FormField>

        <FormField>
          <label>{translations.hourlyRate}</label>

          <NumberInput
            value={form.hourlyRate ?? 0}
            error={errors.hourlyRate}
            fullWidth
            min={0}
            onChange={handler('hourlyRate')}
          />
        </FormField>

        <FormField>
          <label>{translations.materialDiscount}</label>

          <NumberInput
            value={form.materialDiscount}
            error={errors.materialDiscount}
            fullWidth
            min={0}
            onChange={handler('materialDiscount')}
          />
        </FormField>

        {form.billingType === 'FIXED' && (
          <FormField>
            <label>{translations.estimatedCompletionTime}</label>

            <NumberInput
              value={form.estimatedCompletionTime ?? 0}
              error={errors.estimatedCompletionTime}
              fullWidth
              min={0}
              onChange={handler('estimatedCompletionTime')}
            />
          </FormField>
        )}
      </Form>
    </RegularModal>
  )
}

import React, { FormEventHandler } from 'react'

interface FormProps {
  children?: React.ReactNode
  preventDefault?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
}

const Form: React.FC<FormProps> = ({ children, preventDefault, onSubmit }) => {
  return (
    <form
      onSubmit={e => {
        if (preventDefault) {
          e.preventDefault()
        }

        if (onSubmit) {
          onSubmit(e)
        }
      }}
    >
      {children}
    </form>
  )
}
export default Form

import { ShallowManualFolder } from './types.graphql'
import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Table, TableColumn, TableOptions, TableRow } from 'components'
import { PERMISSIONS } from 'util/permissions'
import { useDateFns, usePermission } from 'util/hooks'
import { Link } from 'react-router-dom'
import { Icon } from '@ur/react-components'
import { useManualUtils } from './util'
import { FolderPath } from './components/FolderPath'

const None = styled.span`
  font-style: italic;
  color: ${props => props.theme.colors.gray3};
`

interface ManualFoldersTableProps {
  folders: ShallowManualFolder[]

  search?: string
  root?: boolean
  noShift?: boolean
  showPath?: boolean

  onResetSearch?: () => void
}

export const ManualFoldersTable: React.FC<ManualFoldersTableProps> = ({
  folders,

  search,
  root = false,
  noShift = false,
  showPath = false,

  onResetSearch = () => void 0,
}) => {
  const translations = useTranslate({
    title: 'common.title',
    path: 'common.path',
    description: 'common.description',
    lastUpdated: 'common.last-updated',
    numEntries: 'handbook.entries',
    numFolders: 'handbook.folders',

    none: 'common.none',
    noData: 'common.no-data',
    noResults: 'common.no-results',
  })
  const { makeEditDeleteMenu, makeOrderArrows } = useManualUtils(folders, {
    refetchQueries: root ? ['AllRootManualFolders'] : ['ManualFolderBySlug'],
    root,
  })
  const theme = useTheme()
  const { format } = useDateFns()
  const canShiftManualFolder = usePermission(
    PERMISSIONS.companymanuals.change.manualfolder
  )

  const columns = useMemo<TableColumn[]>(() => {
    let columns = [
      {
        id: 'name',
        label: translations.title,
        shrink: true,
      },
      {
        id: 'path',
        label: translations.path,
        shrink: true,
        skip: !showPath,
      },
      {
        id: 'description',
        label: translations.description,
      },
      {
        id: 'updatedAt',
        label: translations.lastUpdated,
        shrink: true,
      },
      {
        id: 'numSubFolders',
        label: translations.numFolders,
        shrink: true,
      },
      {
        id: 'numEntries',
        label: translations.numEntries,
        shrink: true,
      },
      {
        id: 'menu',
        label: '',
        shrink: true,
      },
      {
        id: 'index',
        label: '',
        shrink: true,
        skip: noShift || !canShiftManualFolder,
      },
    ]

    return columns
  }, [
    translations.title,
    translations.path,
    translations.description,
    translations.lastUpdated,
    translations.numFolders,
    translations.numEntries,
    showPath,
    noShift,
    canShiftManualFolder,
  ])

  const rows = useMemo<TableRow[]>(
    () =>
      folders.map((folder, index) => {
        const date = format(new Date(folder.updatedAt), 'PPP')

        const row: TableRow = {
          name: {
            content: (
              <Link to={`/handbook/${folder.slug}`}>
                <Icon icon="folder-open" type="solid" margin="0 0.5rem 0 0" />

                {folder.name}
              </Link>
            ),
          },
          path: {
            content: (
              <FolderPath
                folders={folder.path ?? []}
                onGoToRoot={onResetSearch}
              />
            ),
          },
          description: {
            content: folder.description || <None>{translations.none}</None>,
          },
          numSubFolders: {
            content: folder.numSubFolders.toString(),
            alignHorizontal: 'center',
          },
          numEntries: {
            content: folder.numEntries.toString(),
            alignHorizontal: 'center',
          },
          updatedAt: { content: date },
          menu: {
            content: '',
            menu: makeEditDeleteMenu(folder),
          },
          index: {
            content: makeOrderArrows(folder, index),
          },
        }

        return row
      }),
    [
      folders,
      format,
      makeEditDeleteMenu,
      makeOrderArrows,
      onResetSearch,
      translations.none,
    ]
  )

  const tableOptions = useMemo<TableOptions>(
    () => ({
      cell: cell => ({
        style: {
          whiteSpace: 'nowrap',
          textOverflow: cell.columnId === 'description' ? 'ellipsis' : 'clip',
          overflow: cell.columnId === 'description' ? 'hidden' : 'visible',
          color:
            cell.columnId === 'updatedAt' ? theme.colors.gray3 : 'currentColor',
          padding: cell.columnId === 'index' ? '4px 1rem' : undefined,
        },
        labelStyle: {
          overflow: cell.columnId === 'description' ? 'hidden' : 'visible',
          textOverflow: cell.columnId === 'description' ? 'ellipsis' : 'clip',
        },
      }),
    }),
    [theme.colors.gray3]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      options={tableOptions}
      noRowsText={!!search ? translations.noResults : translations.noData}
    />
  )
}

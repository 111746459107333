import { CrumbInsert } from '@ur/react-components'
import { useGlobal } from '@ur/react-hooks'
import { Location } from 'history'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function useBreadcrumbInserts(
  inserts: CrumbInsert[],
  ignoreLocationChange?: string | ((location: Location) => boolean)
) {
  const history = useHistory()
  const [insert, setInserts] = useGlobal('breadcrumbs.inserts')

  useEffect(() => {
    if (isEqual(inserts, insert)) return
    setInserts(inserts)
  }, [insert, inserts, setInserts])

  useEffect(() => {
    const unregister = history.listen(loc => {
      const ignore =
        typeof ignoreLocationChange === 'undefined'
          ? false
          : typeof ignoreLocationChange === 'function'
          ? ignoreLocationChange(loc)
          : loc.pathname.startsWith(ignoreLocationChange)
      if (ignore) return

      setInserts([])
    })

    return unregister
  }, [history, ignoreLocationChange, setInserts])
}

import { PrivateRouteProviderConfig } from '@ur/react-components'
import { useCallback, useMemo } from 'react'
import { useAdmin, useUser } from 'util/hooks'
import { ErrorPage } from './ErrorPage'

export function usePrivateRouteConfig() {
  const user = useUser()
  const admin = useAdmin()

  const getAllPermissions = useCallback(
    () => user.permissions,
    [user.permissions]
  )

  const overrideAuthorization = useCallback(
    () => (admin ? true : null),
    [admin]
  )

  const notAuthenticatedComponent = useCallback(
    () => <ErrorPage code="401" />,
    []
  )

  const notAuthorizedComponent = useCallback(() => <ErrorPage code="403" />, [])

  const config = useMemo<PrivateRouteProviderConfig>(
    () => ({
      isAuthenticated: !!user.id,
      getAllPermissions,
      overrideAuthorization,
      notAuthenticatedComponent,
      notAuthorizedComponent,
    }),
    [
      getAllPermissions,
      notAuthenticatedComponent,
      notAuthorizedComponent,
      overrideAuthorization,
      user.id,
    ]
  )

  return config
}

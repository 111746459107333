import {
  Badge as BaseBadge,
  BadgeProps as BaseBadgeProps,
} from '@ur/react-components'
import styled from 'styled-components'

const StyledBadge = styled(BaseBadge)``

interface BadgeProps extends BaseBadgeProps {}

export const Badge: React.FC<BadgeProps> = ({ ...props }) => {
  return <StyledBadge {...props} />
}

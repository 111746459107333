import { ObservableQueryFields } from '@apollo/client'
import { useCallback, useState } from 'react'
import { RelayPageInfo } from 'types/graphql'
import {
  ProjectsShallowQuery,
  ProjectsShallowQueryVariables,
} from '../types.graphql'

export function useProjectsPagination(
  pageInfo: RelayPageInfo | undefined,
  fetchMoreProjects: ObservableQueryFields<
    ProjectsShallowQuery,
    ProjectsShallowQueryVariables
  >['fetchMore']
) {
  const [loading, setLoading] = useState(false)

  const fetchMore = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return
    setLoading(true)

    if (!!pageInfo.endCursor) {
      fetchMoreProjects({
        variables: {
          after: pageInfo.endCursor,
        },
      }).finally(() => setLoading(false))
    }
  }, [pageInfo, fetchMoreProjects])

  return {
    fetchMore,
    loading,
    hasMore: !!pageInfo?.hasNextPage,
  }
}

import React from 'react'
import styled, { css } from 'styled-components'
import { TableMenu } from 'components'
import { Menu } from 'components/Table/TableMenu'
import { Link } from 'react-router-dom'
import { useDateFns } from 'util/hooks'
import { ShallowChecklist } from '../types.graphql'
import { ChecklistStatus as BaseChecklistStatus } from '../components/ChecklistStatus'
import { useTranslate } from '@ur/react-hooks'

const Wrapper = styled.section`
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`
const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  height: 40px;

  font-size: 14px;
  color: ${props => props.theme.colors.gray1};

  border: ${props => props.theme.colors.gray6} solid;
  border-width: 0 0 2px 0;

  a {
    display: flex;
    padding-left: 1rem;
  }
  .menu {
    padding-right: 0.5rem;

    .icon {
      margin-bottom: -2px;
    }
  }
`
const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'purpose purpose'
    'customer status';
  row-gap: 1rem;
  padding: 1rem;

  border-top: 2px solid ${props => props.theme.colors.gray6};
`
const ChecklistStatus = styled(BaseChecklistStatus)`
  grid-template-areas: 'progress';
`
interface FooterElementProps {
  area: 'purpose' | 'customer'
}
const FooterElement = styled.div<FooterElementProps>`
  display: flex;
  grid-area: ${props => props.area};
  gap: 0.3rem;

  font-size: 14px;
  color: ${props => props.theme.colors.gray3};
`
const DateField = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  color: ${props => props.theme.colors.gray3};
  font-weight: 600;
  white-space: nowrap;
  border-right: 2px solid ${props => props.theme.colors.gray6};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 1rem 1.2rem;

  a {
    font-size: 18px;
    font-weight: 600;
  }
`
interface MaybeProps {
  empty: boolean
}
const Maybe = styled.span<MaybeProps>`
  ${props =>
    props.empty &&
    css`
      color: ${props.theme.colors.gray3};
      font-style: italic;
    `};
`

interface ChecklistCardProps {
  checklist: ShallowChecklist
  editDeleteMenu: TableMenu
}

export const ChecklistCard: React.FC<ChecklistCardProps> = ({
  checklist,
  editDeleteMenu,
}) => {
  const { format } = useDateFns()

  const translations = useTranslate({
    purpose: 'common.purpose',
    customer: 'common.customer',
    none: 'common.none',
  })

  return (
    <Wrapper>
      <Header>
        <DateField>{format(new Date(checklist.modifiedDate), 'PPP')}</DateField>

        <Link to={`/users/${checklist.createdBy?.id}`}>
          {checklist.createdBy?.fullName ?? ''}
        </Link>

        <Menu className="menu" icon="ellipsis-h" config={editDeleteMenu} />
      </Header>

      <Content>
        <Link
          to={`/projects/${checklist.project.id}/checklists/${checklist.id}`}
        >
          {checklist.title}
        </Link>

        <span>{checklist.project.name}</span>
      </Content>

      <Footer>
        <FooterElement area="purpose">
          <strong>{translations.purpose + ':'}&nbsp;</strong>
          <Maybe empty={!checklist.purpose}>
            {checklist.purpose || translations.none}
          </Maybe>
        </FooterElement>

        <FooterElement area="customer">
          <strong>{translations.customer + ':'}&nbsp;</strong>
          <Maybe empty={!checklist.project.customer}>
            {checklist.project.customer?.name ?? translations.none}
          </Maybe>
        </FooterElement>

        <ChecklistStatus
          progress={checklist.progress}
          closed={checklist.closed}
        />
      </Footer>
    </Wrapper>
  )
}

import { Icon } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ManualFolderNode } from '../types.graphql'

const Wrapper = styled.div`
  a.root:not(:hover) {
    color: ${props => props.theme.colors.gray3};
  }
`

interface FolderPathProps {
  folders: Pick<ManualFolderNode, 'id' | 'slug' | 'name'>[]
  onGoToRoot: () => void
}

export const FolderPath: React.FC<FolderPathProps> = ({
  folders,
  onGoToRoot,
}) => {
  const { root } = useTranslate({
    root: 'common.root',
  })

  const [relevantFolders, shortened] = useMemo(() => {
    if (folders.length <= 4) return [folders, false]
    return [folders.slice(0, 1).concat(folders.slice(-2)), true]
  }, [folders])

  return (
    <Wrapper>
      <Link className="root" to="/handbook" onClick={onGoToRoot}>
        {root}
      </Link>

      {relevantFolders.map((folder, idx) => (
        <React.Fragment key={folder.id}>
          <Icon
            type="solid"
            icon="chevron-right"
            size="0.6rem"
            color="gray4"
            margin="0 0.5rem"
            translateY="-2px"
          />

          <Link to={`/handbook/${folder.slug}`}>{folder.name}</Link>

          {shortened && idx === 0 && (
            <>
              <Icon
                type="solid"
                icon="chevron-right"
                size="0.6rem"
                color="gray4"
                margin="0 0.5rem"
                translateY="-2px"
              />

              <Icon icon="ellipsis-h" color="gray4" />
            </>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

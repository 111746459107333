import { Icon, usePrompt } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { OfferNode, OfferStatusType as Status } from '../types.graphql'
import { ChangeOfferModal } from './ChangeOfferPrompt'

interface WrapperProps {
  status: Status
  circle: boolean
  pointer: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  user-select: none;
  cursor: ${props => props.pointer && 'pointer'};
  font-weight: 400;
  color: ${props =>
    props.status === 'SENT'
      ? props.theme.colors.matteTeal
      : props.status === 'DECLINED'
      ? props.theme.colors.matteOrange
      : props.status === 'APPROVED'
      ? props.theme.colors.matteGreen
      : props.status === 'CREATED'
      ? props.theme.colors.matteLilac
      : props.theme.colors.gray2};

  ${props => props.theme.media.mobile} {
    font-size: 14px;

    i {
      margin-right: 6px;
    }
    .icon-right {
      margin: 0 0 0 6px;
      color: ${props => props.theme.colors.gray3};
    }
  }
  ${props => props.theme.media.desktop} {
    i {
      ${props =>
        !props.circle
          ? css`
              margin-left: 10px;
              color: ${props.theme.colors.gray3};
            `
          : css`
              margin-right: 6px;
            `}
    }
  }
`

interface OfferStatusProps {
  className?: string

  offer: Pick<OfferNode, 'id' | 'title' | 'status'>

  /** Show dot */
  circle?: boolean
  /** Show offer title instead of stage */
  named?: boolean
  /** Show a popup where user can change status on click */
  changePopup?: boolean

  refetchQueries?: string[]
}
export const OfferStatus: React.FC<OfferStatusProps> = ({
  className,

  offer,

  circle = false,
  named = false,
  changePopup = false,

  refetchQueries,
}) => {
  const translations = useTranslate({
    CREATED: 'offers.status.created',
    SENT: 'offers.status.sent',
    APPROVED: 'offers.status.accepted',
    DECLINED: 'offers.status.declined',
    FINISHED: 'offers.status.finished',
  })

  const addPrompt = usePrompt({
    modalProps: {
      shadeOpacity: 0.8,
      offset: 32,
      placement: 'center-center',
      direction: 'from-top',
    },
  })

  function handleClick() {
    if (!changePopup) return

    addPrompt<boolean>(resolve => (
      <ChangeOfferModal
        offer={offer}
        refetchQueries={refetchQueries}
        onResolve={resolve}
      />
    ))
  }

  return (
    <Wrapper
      className={className}
      status={offer.status}
      circle={circle}
      pointer={changePopup}
      onClick={handleClick}
    >
      {(isMobileOnly || circle) && (
        <Icon icon="circle" size="6px" type="solid" translateY="1px" />
      )}

      <span>{named ? offer.title : translations[offer.status]}</span>

      {!circle && (
        <Icon className="icon-right" icon="chevron-down" size="10px" />
      )}
    </Wrapper>
  )
}

import { breadcrumbsStore } from './breadcrumbs'
import { settingsStore } from './settings'
import { sidebarStore } from './sidebar'
import { headerStore } from './header'

export * from './breadcrumbs'
export * from './settings'
export * from './sidebar'
export * from './header'

export const store = {
  header: headerStore,
  sidebar: sidebarStore,
  settings: settingsStore,
  breadcrumbs: breadcrumbsStore,
}

import { useMutation } from '@apollo/client'
import { useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import { Redirect } from 'containers/Routing'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useCompany, useOnErrorAuto, useToast } from 'util/hooks'
import { CustomerForm } from './CustomerForm'
import { CREATE_CUSTOMER_MUTATION } from './mutations'
import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
} from './types.graphql'
import mixpanel from 'mixpanel-browser'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

interface CreateCustomerProps {}

export const CreateCustomer: React.FC<CreateCustomerProps> = () => {
  const translations = useTranslate({
    createCustomer: 'customers.create-customer',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const { defaultHourlyRate } = useCompany()
  const onErrorAuto = useOnErrorAuto()
  const addToast = useToast()
  const history = useHistory()

  const [createCustomerMutation, { loading }] = useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CREATE_CUSTOMER_MUTATION, {
    refetchQueries: ['Customers'],
    onCompleted(data) {
      addToast('success', translations.results.success)
      history.push(`/customers/${data.createCustomer.customer.id}`)
      mixpanel.track('Create Customer')
    },
    onError: onErrorAuto(),
  })

  function handleSubmit(form: CreateCustomerMutationVariables) {
    createCustomerMutation({
      variables: {
        ...form,
      },
    })
  }

  if (isMobileOnly)
    return (
      <Redirect
        to={{
          pathname: '/customers',
          state: {
            create: true,
          },
        }}
      />
    )

  return (
    <Wrapper>
      <PageHeader title={translations.createCustomer} loading={loading} />
      <CustomerForm
        loading={loading}
        defaultHourlyRate={defaultHourlyRate}
        onSubmit={handleSubmit}
      />
    </Wrapper>
  )
}

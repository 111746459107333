import { AllowedLanguage } from 'locale/util'

export function renderLanguage(lang: AllowedLanguage) {
  switch (lang) {
    case 'nb':
      return 'Norsk'
    default:
      return 'English'
  }
}

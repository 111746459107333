import React from 'react'
import styled from 'styled-components'

interface WrapperProps {
  closed: boolean
  smallText?: boolean
}
const Wrapper = styled.div<WrapperProps>`
  height: 100%;
  padding: ${props => (props.smallText ? '0.3rem 1rem' : '0 0.7rem')};

  border-radius: ${props =>
    props.smallText ? props.theme.sizes.defaultBorderRadius : '999px'};

  background-color: ${props => (props.closed ? '#e7ffee' : '#fff6f0')};
  font-size: ${props => props.smallText && '14px'};
  color: ${props =>
    props.closed
      ? props.theme.colors.matteGreen
      : props.theme.colors.matteOrange};
`

interface ChecklistStatusProps {
  progress: [number, number]
  closed: boolean
  smallText?: boolean
}

export const ChecklistStatus: React.FC<ChecklistStatusProps> = ({
  progress,
  closed,
  smallText = false,
}) => {
  const status = progress[0] + '/' + progress[1]

  return (
    <Wrapper closed={closed} smallText={smallText}>
      {status}
    </Wrapper>
  )
}

import { useQuery } from '@apollo/client'
import { PromptResolve } from '@ur/react-components'
import { useDebounce, useTranslate } from '@ur/react-hooks'
import {
  FetchMoreLoader,
  Input,
  RegularModal as BaseRegularModal,
  Table,
  TableColumn,
  TableFilteringChecklistOptions,
  TableRow,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'
import { useDateFns, useKeyPress, useOnErrorAuto } from 'util/hooks'
import { useInfiniteScroll } from 'util/hooks/useInfiniteScroll'
import { PROJECT_SELECT_QUERY } from '../queries'
import {
  ProjectSelectQuery,
  ProjectSelectQueryVariables,
} from '../types.graphql'
import { useProjectsPagination } from '../util/useProjectsPagination'

const RegularModal = styled(BaseRegularModal)`
  div.filtering {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;
  }

  ${props => props.theme.media.mobile} {
    .stages {
      transform: translateX(-1rem);
    }

    .content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`

interface CellWrapperProps {
  bold?: boolean
  clickable?: boolean
}
const CellWrapper = styled.div<CellWrapperProps>`
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${props.theme.colors.primary};
      }
    `};

  font-weight: ${props => props.bold && '600'};
`
const Search = styled(Input)`
  input {
    border-right-width: 0px;
  }
  .--input-icon-right {
    transition: border-color 0.1s linear;
  }
  &:focus-within {
    .--input-icon-right {
      border-color: ${props => props.theme.colors.primary};
    }
  }

  ${props => props.theme.media.mobile} {
    padding: 0 1rem;
  }
`
const Content = styled.div`
  max-height: 70vh;
  margin-top: 0.5rem;
  overflow-y: auto;
`
const MobileTable = styled.div`
  margin-top: 1rem;
`
const MobileRow = styled.div`
  display: flex;

  height: 56px;
  padding: 0 1rem;

  justify-content: space-between;
  align-items: center;

  div:first-child {
    font-weight: 600;
  }

  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.gray8};
  }
`

const PAGE_SIZE = 25

interface ProjectSelectModalProps {
  onSubmit: PromptResolve<string | null>
}
export const ProjectSelectModal: React.FC<ProjectSelectModalProps> = ({
  onSubmit,
}) => {
  const translations = useTranslate({
    cancel: 'common.cancel',
    filter: 'projects.search',
    none: 'common.no-results',
    selectProject: 'projects.select-project',

    stage: {
      planning: 'projects.stage-planning',
      ongoing: 'projects.stage-ongoing',
      billing: 'projects.stage-billing',
      finished: 'projects.stage-finished',
    },
    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const onErrorAuto = useOnErrorAuto()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [stages, setStages] = useState<string[]>(['planning', 'ongoing'])
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null
  )

  const {
    data: projectsData,
    loading,
    fetchMore,
  } = useQuery<ProjectSelectQuery, ProjectSelectQueryVariables>(
    PROJECT_SELECT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        first: PAGE_SIZE,
        q: debouncedSearch,
        stages,
      },
      onError: onErrorAuto(),
    }
  )

  const projects = useMemo(
    () => projectsData?.allProjectsSelect.edges.map(({ node }) => node) ?? [],
    [projectsData]
  )

  const {
    fetchMore: handleFetchMore,
    loading: fetchMoreLoading,
    hasMore,
  } = useProjectsPagination(projectsData?.allProjectsSelect.pageInfo, fetchMore)

  useInfiniteScroll(handleFetchMore, { element: scrollElement })

  const { format } = useDateFns()

  const statusFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      items: (
        [
          ['planning', 'information'],
          ['ongoing', 'warning2'],
          ['billing', 'success'],
          ['finished', 'gray2'],
        ] as const
      ).map(([stage, dotColor]) => ({
        id: stage,
        text: translations.stage[stage],
        dotColor,
        initUnchecked: ['billing', 'finished'].includes(stage),
      })),
    }),
    [translations.stage]
  )

  const escape = useKeyPress('Escape')
  useEffect(() => {
    if (escape) onSubmit(null)
  }, [escape, onSubmit])

  const columns = useMemo<TableColumn[]>(
    () => [
      { id: 'name', label: 'Name', width: '1fr' },
      { id: 'customer', label: 'Customer' },
      { id: 'createdAt', label: 'Created at' },
    ],
    []
  )

  const rows = useMemo<TableRow[]>(
    () =>
      projects.map(project => ({
        name: {
          content: (
            <CellWrapper clickable onClick={() => onSubmit(project.id)} bold>
              {project.name}
            </CellWrapper>
          ),
        },
        customer: {
          content: <CellWrapper>{project.customer.name}</CellWrapper>,
        },
        createdAt: {
          content: (
            <CellWrapper>
              {format(new Date(project.createdAt), 'PPP')}
            </CellWrapper>
          ),
        },
      })),
    [format, onSubmit, projects]
  )

  return (
    <RegularModal
      title={translations.selectProject}
      loading={loading || fetchMoreLoading}
      width="50vw"
      background="white"
      onClose={() => onSubmit(null)}
    >
      <div className="filtering">
        <Search
          value={search}
          autoFocus
          fullWidth
          placeholder={translations.filter}
          iconRightProps={{ icon: 'search', color: 'gray4' }}
          onChange={setSearch}
        />

        <Filtering
          id="stages"
          className="stages"
          config={statusFiltering}
          icon={{
            icon: 'filter',
            size: '1.2rem',
            color: 'gray4',
          }}
          noMobileIconStyling={isMobileOnly}
          onFilterChange={value => setStages(value.checked)}
        />
      </div>

      {isMobileOnly ? (
        <MobileTable>
          {projects.map(project => (
            <MobileRow key={project.id} onClick={() => onSubmit(project.id)}>
              <div>{project.name}</div>
              <div>{project.customer.name}</div>
            </MobileRow>
          ))}
        </MobileTable>
      ) : (
        <Content ref={setScrollElement}>
          <Table
            columns={columns}
            rows={rows}
            noRowsText={translations.none}
            noHeader
            options={{
              style: { marginTop: '1rem', boxShadow: 'none' },
            }}
          />
        </Content>
      )}

      <FetchMoreLoader
        loading={fetchMoreLoading}
        hide={!hasMore}
        onFetchMore={handleFetchMore}
      />
    </RegularModal>
  )
}

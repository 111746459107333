import { useQuery } from '@apollo/client'
import { Loader, Message } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { useMemo } from 'react'
import styled from 'styled-components'
import { SELECT_COMPANIES_QUERY } from '../queries'
import { SelectCompaniesQuery } from '../types.graphql'
import { NoCompanies } from './NoCompanies'
import { SelectCompanyTile } from './SelectCompanyTile'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const CompaniesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;

  max-width: 90vw;
  margin-top: 6rem;

  ${props => props.theme.media.mobile} {
    gap: 1rem;
    max-width: 98vw;
  }
`
const CenteredLoading = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 15%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelectCompany: React.FC = () => {
  const translations = useTranslate({
    couldntLoad: 'companies.couldnt-load',
  })

  const { data, loading, error } = useQuery<SelectCompaniesQuery>(
    SELECT_COMPANIES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: console.error,
    }
  )

  const companies = useMemo(
    () => data?.me?.companies.edges.map(edge => edge.node) ?? [],
    [data]
  )

  // Redirect here automatically
  if (companies.length === 1) {
    const company = companies[0]
    window.location.href = `/c/${company.shortName}`
    return null
  }

  return !!companies.length ? (
    <Wrapper>
      {loading ? (
        <CenteredLoading>
          <Loader.Spinner type="stroke" />
        </CenteredLoading>
      ) : !!error ? (
        <Message.Error show text={translations.couldntLoad} />
      ) : (
        <CompaniesContainer>
          {companies.map(company => (
            <SelectCompanyTile key={company.id} company={company} />
          ))}
        </CompaniesContainer>
      )}
    </Wrapper>
  ) : (
    <NoCompanies />
  )
}

import { useTranslate } from '@ur/react-hooks'
import {
  SlideMenu,
  SlideMenuItem,
  TableFilteringChecklistOptions,
} from 'components'
import { Filtering } from 'components/Table/TableFiltering'
import { OfferStatus as BaseOfferStatus } from 'modules/offers/components/OfferStatus'
import { OfferNode } from 'modules/offers/types.graphql'
import { useOfferUtils } from 'modules/offers/util'
import {
  DashboardOfferStatusType,
  ProjectDashboardData,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { usePermission } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useTimeFilterOptions } from '../utils'
import {
  Card,
  CardHeader,
  CardItem,
  Content,
  ContentWrapper,
  FooterSelect,
  NoData,
  Project,
  Title,
} from './components'
import { DashboardCardProps } from './types'

const OfferStatus = styled(BaseOfferStatus)`
  display: flex;
  justify-content: flex-end;

  min-width: 100px;
  width: 100px;
  max-width: 100px;

  font-size: 0.8rem;
`

interface OffersCardProps
  extends DashboardCardProps<ProjectDashboardData['offers']> {
  statusFilter?: DashboardOfferStatusType

  onUpdateStatusFilter: Dispatch<
    SetStateAction<DashboardOfferStatusType | undefined>
  >
}

export const OffersCard: React.FC<OffersCardProps> = ({
  data,
  loading,
  timeFilter,
  statusFilter,
  onUpdateTimeFilter,
  onUpdateStatusFilter,
}) => {
  const translations = useTranslate({
    edit: 'common.edit',
    view: 'common.view',
    download: 'common.download',

    sentOffers: 'offers.sent-offers',
    createdOffers: 'offers.created-offers',
    sentAndCreated: 'offers.sent-and-created-offers',

    statuses: {
      SENT: 'offers.status.sent',
      CREATED: 'offers.status.created',
    },
  })

  const canEditOffer = usePermission(PERMISSIONS.offers.change.offer)
  const timeFilterOptions = useTimeFilterOptions()

  const { sendEmailLoading, handlePrint, handleSendOffer } = useOfferUtils({})

  const createMenuItems = useCallback(
    (offer: Pick<OfferNode, 'id' | 'project'>): SlideMenuItem[] => {
      const projectId = offer.project?.id
      const offerId = offer.id

      if (!projectId) return []

      return [
        {
          text: translations.download,
          color: 'white',
          background: 'danger',
          hoverBackground: 'dangerHover',
          onClick: () => handlePrint(offerId),
        },
        {
          text: translations.view,
          color: 'white',
          background: 'primary',
          hoverBackground: 'primaryHover',
          onClick: () => handleSendOffer(offerId, projectId),
        },
        {
          text: translations.edit,
          href: `/projects/${projectId}/offers/${offerId}/edit`,
          color: 'white',
          background: 'secondary',
          hoverBackground: 'secondaryHover',
          hide: !canEditOffer,
        },
      ]
    },
    [
      canEditOffer,
      handlePrint,
      handleSendOffer,
      translations.download,
      translations.edit,
      translations.view,
    ]
  )

  const statusFiltering = useMemo<TableFilteringChecklistOptions>(
    () => ({
      type: 'checklist',
      exclusive: true,
      items: (
        [
          ['CREATED', 'matteLilac'],
          ['SENT', 'information'],
        ] as const
      ).map(([status, dotColor]) => ({
        id: status,
        text: translations.statuses[status],
        dotColor,
        initUnchecked: statusFilter !== status,
      })),
    }),
    [statusFilter, translations.statuses]
  )

  return (
    <Card>
      <CardHeader
        text={
          statusFilter === 'SENT'
            ? translations.sentOffers
            : statusFilter === 'CREATED'
            ? translations.createdOffers
            : translations.sentAndCreated
        }
        loading={loading || sendEmailLoading}
        filter={
          <Filtering
            className="filter"
            id="status"
            config={statusFiltering}
            icon={{
              icon: 'filter',
              size: '1.2rem',
              color: 'gray4',
            }}
            noMobileIconStyling
            onFilterChange={value =>
              onUpdateStatusFilter(value.checked[0] as DashboardOfferStatusType)
            }
          />
        }
      />

      <ContentWrapper>
        {data.map(offer => (
          <CardItem key={offer.id}>
            <Content hasStatus>
              <Title>{offer.title}</Title>

              <Project>
                {offer.project.name}
                <span className="customer">
                  ({offer.project.customer.name})
                </span>
              </Project>
            </Content>

            <OfferStatus
              offer={offer}
              circle
              changePopup
              refetchQueries={['ProjectDashboard']}
            />

            <SlideMenu items={createMenuItems(offer)} />
          </CardItem>
        ))}

        {!data.length && <NoData />}
      </ContentWrapper>

      <footer>
        <FooterSelect
          value={timeFilter}
          options={timeFilterOptions}
          borderTopRightRadius="0px"
          borderTopLeftRadius="0px"
          height="49px"
          border="none"
          fullWidth
          onChange={value =>
            !!value && onUpdateTimeFilter(value as TimeFilterType)
          }
        />
      </footer>
    </Card>
  )
}

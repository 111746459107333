import { useTranslate } from '@ur/react-hooks'
import { SlideMenu, SlideMenuItem } from 'components'
import { ChecklistNode } from 'modules/checklists/types.graphql'
import { useCreateChecklist, useDeleteChecklist } from 'modules/checklists/util'
import {
  ProjectDashboardData,
  TimeFilterType,
} from 'modules/projects/types.graphql'
import React, { useCallback, useMemo } from 'react'
import { usePermissions } from 'util/hooks'
import { PERMISSIONS } from 'util/permissions'
import { useTimeFilterOptions } from '../utils'
import {
  Card,
  CardHeader,
  CardItem,
  Content,
  ContentWrapper,
  FooterButton,
  FooterSelect,
  NoData,
  Project,
  Title,
} from './components'
import { DashboardCardProps } from './types'
import mixpanel from 'mixpanel-browser'

export const ChecklistsCard: React.FC<
  DashboardCardProps<ProjectDashboardData['ongoingChecklists']>
> = ({ data, loading, timeFilter, onUpdateTimeFilter }) => {
  const translations = useTranslate({
    ongoingChecklists: 'checklists.ongoing-checklists',
    createChecklist: 'checklists.create-checklist',

    edit: 'common.edit',
    delete: 'common.delete',
    open: 'common.open',
  })

  const { hasPermissions } = usePermissions(true)
  const canCreateChecklist = useMemo(
    () => hasPermissions(PERMISSIONS.checklists.add.checklist),
    [hasPermissions]
  )

  const timeFilterOptions = useTimeFilterOptions()

  const {
    createChecklist: handleCreateChecklist,
    loading: createChecklistLoading,
  } = useCreateChecklist()

  const { deleteChecklist: handleDeleteChecklist } = useDeleteChecklist()

  const createMenuItems = useCallback(
    (checklist: Pick<ChecklistNode, 'id' | 'project'>): SlideMenuItem[] => {
      const projectId = checklist.project.id
      const checklistId = checklist.id

      return [
        {
          text: translations.delete,
          color: 'white',
          background: 'danger',
          hoverBackground: 'dangerHover',
          hide: !hasPermissions(PERMISSIONS.checklists.delete.checklist),
          onClick: () => handleDeleteChecklist(checklistId),
        },
        {
          text: translations.edit,
          href: `/projects/${projectId}/checklists/${checklistId}`,
          color: 'white',
          background: 'secondary',
          hoverBackground: 'secondaryHover',
          hide: !hasPermissions(PERMISSIONS.checklists.change.checklist),
        },
        {
          text: translations.open,
          href: `/projects/${projectId}/checklists/${checklistId}`,
          color: 'white',
          background: 'primary',
          hoverBackground: 'primaryHover',
          hide: !hasPermissions(PERMISSIONS.checklists.view.checklist),
        },
      ]
    },
    [
      handleDeleteChecklist,
      hasPermissions,
      translations.delete,
      translations.edit,
      translations.open,
    ]
  )

  return (
    <Card hasFooterButton={canCreateChecklist}>
      <CardHeader
        text={translations.ongoingChecklists}
        loading={loading || createChecklistLoading}
      />

      <ContentWrapper>
        {data.map(checklist => (
          <CardItem key={checklist.id}>
            <Content>
              <Title>{checklist.title}</Title>

              <Project>{checklist.project?.name}</Project>
            </Content>

            <SlideMenu items={createMenuItems(checklist)} />
          </CardItem>
        ))}

        {!data.length && <NoData />}
      </ContentWrapper>

      <footer>
        <FooterSelect
          value={timeFilter}
          options={timeFilterOptions}
          borderTopRightRadius="0px"
          borderTopLeftRadius="0px"
          height="49px"
          border="none"
          fullWidth
          onChange={value =>
            !!value && onUpdateTimeFilter(value as TimeFilterType)
          }
        />

        {canCreateChecklist && (
          <FooterButton
            role="link"
            width="100%"
            height="100%"
            iconRightProps={{ icon: 'plus' }}
            background="primary"
            onClick={() => {
              mixpanel.track('Create Checklist From Dashboard')
              handleCreateChecklist()
            }}
          >
            {translations.createChecklist}
          </FooterButton>
        )}
      </footer>
    </Card>
  )
}

import {
  Input as BaseInput,
  InputProps as BaseInputProps,
} from '@ur/react-components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css, useTheme } from 'styled-components'

const StyledInput = styled(BaseInput)`
  input {
    height: 100%;
    padding: 0 10px;

    &:focus {
      border-color: ${props => props.theme.colors.dark};
    }
    &:disabled {
      background-color: ${props => props.theme.colors.gray7};
    }
    &::placeholder {
      color: ${props => props.theme.colors.gray4};
    }
  }

  ${props =>
    !!props.error &&
    css`
      input {
        border-color: ${props.theme.colors.error};
        color: ${props.theme.colors.error} !important;

        &:focus {
          border-color: ${props.theme.colors.error} !important;
        }
      }
    `}
`

interface InputProps extends BaseInputProps {}
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    const theme = useTheme()

    return (
      <StyledInput
        ref={ref}
        border="1px solid"
        borderColor="gray5"
        height={isMobileOnly ? '56px' : '40px'}
        borderRadius={props.borderRadius ?? theme.sizes.defaultBorderRadius}
        autoComplete="nope"
        {...props}
      />
    )
  }
)

import { useQuery } from '@apollo/client'
import { PromptResolve, usePromptState } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { RegularModal } from 'components'
import React from 'react'
import { IdVariable } from 'types/graphql'
import { useOnErrorAuto } from 'util/hooks'
import { CustomerPromptState } from '.'

import { CustomerForm } from './CustomerForm'
import { CUSTOMER_EDIT_QUERY } from './queries'
import {
  CreateCustomerMutationVariables,
  CustomerEditQuery,
} from './types.graphql'

interface EditCustomerModalProps {
  id: string
  customerId: string
  defaultHourlyRate: number

  onSubmit: PromptResolve<CreateCustomerMutationVariables | null>
}
export const EditCustomerModal: React.FC<EditCustomerModalProps> = ({
  id,
  customerId,
  defaultHourlyRate,

  onSubmit,
}) => {
  const onErrorAuto = useOnErrorAuto()

  const translations = useTranslate({
    editCustomer: 'customers.edit-customer',

    results: {
      error: 'customers.create-error',
      success: 'customers.create-success',
      queryError: 'server.general-error-try-again-later',
    },
  })

  const state = usePromptState<CustomerPromptState>(id)

  const { data, loading: queryLoading } = useQuery<
    CustomerEditQuery,
    IdVariable
  >(CUSTOMER_EDIT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: customerId },
    onCompleted() {},
    onError: onErrorAuto(translations.results.queryError),
  })

  function handleSubmit(values: CreateCustomerMutationVariables) {
    onSubmit({
      ...values,
    })
  }

  return (
    <RegularModal
      title={translations.editCustomer}
      width="100%"
      onClose={() => onSubmit(null)}
    >
      <CustomerForm
        customer={data?.customer}
        loading={state.loading || queryLoading}
        defaultHourlyRate={defaultHourlyRate}
        onSubmit={handleSubmit}
      />
    </RegularModal>
  )
}

import { SelectOption } from '@ur/react-components'
import { useTranslate } from '@ur/react-hooks'
import { TimeFilterType } from 'modules/projects/types.graphql'

export function useTimeFilterOptions() {
  const translations = useTranslate({
    lastWeek: 'common.last-week',
    lastMonth: 'common.last-month',
    allTime: 'common.all-time',
    lastSixMonths: 'common.last-six-months',
    thisWeek: 'common.this-week',
    thisMonth: 'common.this-month',
    thisYear: 'common.this-year',
  })

  return [
    {
      value: 'THIS_WEEK',
      label: translations.thisWeek,
    },
    {
      value: 'LAST_WEEK',
      label: translations.lastWeek,
    },
    {
      value: 'THIS_MONTH',
      label: translations.thisMonth,
    },
    {
      value: 'LAST_MONTH',
      label: translations.lastMonth,
    },
    {
      value: 'LAST_SIX_MONTHS',
      label: translations.lastSixMonths,
    },
    {
      value: 'THIS_YEAR',
      label: translations.thisYear,
    },
    {
      value: 'ALL',
      label: translations.allTime,
    },
  ] as SelectOption<TimeFilterType, string>[]
}

export function getOvertime(expectedHours: number, totalHours: number) {
  return expectedHours < totalHours ? totalHours - expectedHours : 0
}

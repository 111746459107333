import {
  BrowserRouter as BaseBrowserRouter,
  BrowserRouterProps,
  Redirect as BaseRedirect,
  RedirectProps,
  Route as BaseRoute,
  RouteProps,
  Switch as BaseSwitch,
  SwitchProps,
} from 'react-router-dom'

export const Route = BaseRoute as unknown as React.CFC<RouteProps>
export const Switch = BaseSwitch as unknown as React.CFC<SwitchProps>
export const Redirect = BaseRedirect as unknown as React.CFC<RedirectProps>
export const BrowserRouter =
  BaseBrowserRouter as unknown as React.CFC<BrowserRouterProps>

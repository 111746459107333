import {
  RouteBreadcrumbs as BaseRouteBreadcrumbs,
  RouteBreadcrumbsProps as BaseRouteBreadcrumbsProps,
} from '@ur/react-components'
import styled from 'styled-components'

const StyledRouteBreadcrumbs = styled(BaseRouteBreadcrumbs)`
  color: ${props => props.theme.colors.gray4};
  font-weight: 600;
`

interface RouteBreadcrumbsProps extends BaseRouteBreadcrumbsProps {}

export const RouteBreadcrumbs: React.FC<RouteBreadcrumbsProps> = ({
  ...props
}) => {
  return <StyledRouteBreadcrumbs {...props} />
}

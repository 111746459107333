import { useQuery } from '@apollo/client'
import { useGlobal, useTranslate } from '@ur/react-hooks'
import { PageHeader } from 'components'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import { Redirect } from 'containers/Routing'
import styled from 'styled-components'
import { IdVariable } from 'types/graphql/common'
import { useOnErrorAuto } from 'util/hooks'
import { DeviationForm } from './components/DeviationForm'
import { DEVIATION_QUERY } from './queries'
import { DeviationQuery } from './types.graphql'
import { isPatchDeviationMutationVariables, useEditDeviation } from './util'

const Wrapper = styled.div`
  ${props => props.theme.layout.defaultWrapper};

  ${props => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const EditDeviation: React.FC = () => {
  const translations = useTranslate({
    editDeviation: 'deviations.edit-deviation',
    editSuccess: 'deviations.deviation-edit-success-toast',
  })

  const { projectId, deviationId } =
    useParams<{ projectId: string; deviationId: string }>()
  const [, setOverrides] = useGlobal('breadcrumbs.overrides')
  const onErrorAuto = useOnErrorAuto()
  const history = useHistory()

  const { loading, data } = useQuery<DeviationQuery, IdVariable>(
    DEVIATION_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: deviationId,
      },
      onCompleted(data) {
        setOverrides(v => ({
          ...v,
          [projectId]: !!data?.deviation.project
            ? data?.deviation.project.name
            : projectId,
          [deviationId]: data?.deviation.title,
        }))
      },
      onError: onErrorAuto(),
    }
  )

  const { patchDeviation: handleEditDeviation, loading: patchLoading } =
    useEditDeviation({
      id: deviationId,
      afterCompleted: () => {
        return history.push(
          !projectId
            ? `/deviations/internal/${deviationId}`
            : `/projects/${projectId}/deviations/${deviationId}`
        )
      },
    })

  if (isMobileOnly)
    return (
      <Redirect
        to={{
          pathname: `/projects/${projectId}/deviations`,
          state: {
            edit: deviationId,
          },
        }}
      />
    )

  const isLoading = loading || patchLoading

  return (
    <Wrapper>
      <PageHeader title={translations.editDeviation} loading={loading} />

      <DeviationForm
        deviation={data?.deviation}
        projectId={projectId}
        loading={isLoading}
        onSubmit={variables =>
          isPatchDeviationMutationVariables(variables) &&
          handleEditDeviation({ id: projectId, variables })
        }
      />
    </Wrapper>
  )
}
